/* eslint-disable */
import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { ListaPontos } from '../../api/Types/agendarViagensType';
import axios from '../../config/axiosMaquina';
import { Modal, Button, Form } from 'react-bootstrap';
import { getCookieSessionData } from '../../services/cookieService';
import {
  isHourLessThanBaseHour,
  isDateLessThanBaseDate,
  soNumeros,
} from '../../util/Validacoes/Validacoes';
import './styles.css';
import { Dialog } from 'primereact/dialog';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  onPress: () => void;
  idPonto: any;
  setIdPonto: (pontoId: any) => void;
  remessa: string;
  setRemessa: (remessa: string) => void;
  dataPonto: string;
  setDataPonto: (data: string) => void;
  horaPonto: string;
  setHoraPonto: (hora: string) => void;
  setTipoPonto: (tipo: string) => void;
  setNomePonto: (ponto: string) => void;
  tipoPonto: string;
  nomePonto: string;
  fimDataViagem: string;
  fimHoraViagem: string;
  inicioDataViagem: string;
  inicioHoraViagem: string;
};

const ModalAddPtoInterm: FC<Props> = ({
  inicioHoraViagem,
  inicioDataViagem,
  fimDataViagem,
  fimHoraViagem,
  setNomePonto,
  show,
  setShow,
  onPress,
  idPonto,
  setIdPonto,
  remessa,
  setRemessa,
  dataPonto,
  setDataPonto,
  setTipoPonto,
  tipoPonto,
  horaPonto,
  setHoraPonto,
}) => {
  const [erroData, setErroData] = useState(false);
  const [erroData2, setErroData2] = useState(false);
  const [erroHora, setErroHora] = useState(false);
  const [dialogErro, setDialogErro] = useState(false);

  const [erroDataFinal, setErroDataFinal] = useState(false);
  const [erroHora2, setErroHora2] = useState(false);

  const [pontosCliente, setPontosCliente] = useState<ListaPontos[]>([]);

  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  useEffect(() => {
    axios
      .get(`/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${clienteId}`)
      .then((response: any) => {
        response.data = response.data.filter((ponto: ListaPontos) => ponto.ativo !== false);
        setPontosCliente(response.data);
      });
  }, []);

  const resetForm = () => {
    setErroDataFinal(false);
    setErroData(false);
    setIdPonto('');
    setRemessa('');
    setDataPonto('');
    setHoraPonto('');
  };

  const handleSubmit = () => {
    if (erroData || erroData2 || erroHora || erroHora2) {
      setDialogErro(true);
    } else {
      onPress();
      resetForm();
      setShow(false);
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        resetForm();
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Inclusão de pontos</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Dialog
          header="Verifique os dados preenchidos."
          footer={<Button onClick={() => setDialogErro(false)}>Ok</Button>}
          visible={dialogErro}
          style={{ width: '50vw' }}
          modal
          onHide={() => setDialogErro(false)}
        />
        <Form>
          <Form.Group id="pontoOrigemContainer">
            <Form.Label>
              <span className="txt-obrigatorio">*</span>Ponto
            </Form.Label>
            <Form.Control
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setIdPonto(parseInt(e.target.value));
                pontosCliente.map((ponto) => {
                  if (parseInt(e.target.value) === ponto.idPonto) {
                    setNomePonto(ponto.nomePonto);
                  }
                });

                setTipoPonto('I');
              }}
              value={idPonto}
              as="select"
              id="ponto"
              name="ponto"
              placeholder="Ponto de Origem"
              required
            >
              <option></option>
              {pontosCliente.map((ponto, index) => (
                <option key={index} value={ponto.idPonto}>
                  {ponto.nomePonto}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Nº Remessa</Form.Label>
            <Form.Control
              value={remessa}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setRemessa(soNumeros(e.target.value))}
              id="numRemessa"
              name="numRemessa"
              maxLength={10}
              type="text"
              placeholder=""
            />
          </Form.Group>

          <Form.Row>
            <Form.Group>
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Data
              </Form.Label>

              <Form.Control
                value={dataPonto.toString()}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  // onChangeDataPonto(e.target.value, '')
                  setDataPonto(e.target.value);
                  const validation = isDateLessThanBaseDate({
                    baseDate: inicioDataViagem,
                    date: e.target.value,
                  });
                  setErroData(validation);
                  const validation2 = isDateLessThanBaseDate({
                    baseDate: e.target.value,
                    date: fimDataViagem,
                  });
                  setErroData2(validation2);
                  const validation3 = isHourLessThanBaseHour({
                    baseDate: e.target.value,
                    baseHour: horaPonto,
                    date: fimDataViagem,
                    hour: fimHoraViagem,
                  });
                  setErroHora2(validation3 && !validation2);
                }}
                id="dataInicio"
                name="dataInicio"
                type="date"
                placeholder=""
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo Início da viagem não pode ser nulo.
              </Form.Control.Feedback>
              {erroData === true && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Data escolhida é menor que a data inicial da viagem.
                </p>
              )}
              {erroData2 === true && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Data escolhida é maior que a data final da viagem.
                </p>
              )}
            </Form.Group>
            <Form.Group id="container">
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Hora
              </Form.Label>
              <Form.Control
                value={horaPonto}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  // onChangeDataPonto(dataPonto, e.target.value);
                  setHoraPonto(e.target.value);
                  const validation = isHourLessThanBaseHour({
                    baseDate: inicioDataViagem,
                    baseHour: inicioHoraViagem,
                    date: dataPonto,
                    hour: e.target.value,
                  });
                  setErroHora(validation);
                  console.log('Horario é menor que horario inicial da viagem ? ', validation);

                  const validation2 = isHourLessThanBaseHour({
                    baseDate: dataPonto,
                    baseHour: e.target.value,
                    date: fimDataViagem,
                    hour: fimHoraViagem,
                  });
                  setErroHora2(validation2);
                  console.log('Horario é maior que horario final da viagem ? ', validation2);
                }}
                id="horaInicio"
                name="horaInicio"
                type="time"
                placeholder=""
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo Início da viagem não pode ser nulo.
              </Form.Control.Feedback>
              {erroHora === true && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Horário escolhido é menor que o horário inicial da viagem.
                </p>
              )}
              {erroHora2 === true && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Horário escolhido é maior que o horário final da viagem.
                </p>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          // disabled={
          //   erroData || erroHora || erroDataFinal
          //     ? true
          //     : false || dataPonto.toString().length <= 1 || horaPonto.toString().length <= 1
          // }
          onClick={() => {
            handleSubmit();
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddPtoInterm;
