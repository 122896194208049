/* eslint-disable */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Container, Form, Col, Button, Navbar, InputGroup, Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { validaSenha, noArroba } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData } from '../../services/cookieService';
import { Spinner } from 'react-bootstrap';
import { MenuPerfilType } from '../../Types/MenuPerfil';
import { Tela } from '../../components/Tela';
import orderBy from 'lodash/orderBy';
import { dataConverter } from '../../util/date';

type Props = {
  canDelete?: boolean;
  canEdit?: boolean;
};

function EditarUsuarioCliente({ canDelete, canEdit }: Props) {
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogExclusaoOK, setIsDialogCExclusaoOK] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const { id }: any = useParams();
  const { cliente }: any = useParams();
  const { clienteId, role } = getCookieSessionData().usuarioVO;
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [ativo, setAtivo] = useState(false);
  const [senhaValida, setSenhaValida] = useState(true);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidNome, setIsValidNome] = useState(true);
  const [isValidSenha, setIsValidSenha] = useState(true);
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [nome, setNome] = useState('');
  const [dataCadastro, setDataCadastro] = useState('');
  const [usuarioIdKeycloak, setUsuarioIdKeycloak] = useState('');
  const [loading, setLoading] = useState(false);
  const [listaMenuPerfil, setListaMenuPerfil] = useState<MenuPerfilType[]>([]);
  const [selectedMenuPerfil, setSelectedMenuPerfil] = useState<number>();
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [senhaAdmin, setSenhaAdmin] = useState();

  const location: {
    state: string;
  } = useLocation();
  const { state } = location;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };
  console.log(id);
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/usuario/cliente/${id}`);
      setSenhaAdmin(response.data.senha);
      setSelectedMenuPerfil(parseInt(response.data.idMenuPerfil, 10));
      setLogin(response.data.login);
      setSenha(response.data.senha);
      setNovaSenha(response.data.senha);
      setNome(response.data.nome);
      setAtivo(response.data.ativo);
      setDataCadastro(response.data.usuDtHrCadastro);
      setUsuarioIdKeycloak(response.data.usuarioIdKeycloak);
      if (response.data === '') {
        try {
          const response = await axios.get(`/usuario/reutilizar-usuario-cliente?login=${state}`);
          console.log(response.data);
          setLogin(response.data.login);
          setSenha(response.data.senha);
          setNovaSenha(response.data.senha);
          setNome(response.data.nome);
          setAtivo(true);
          setDataCadastro(response.data.usuDtHrCadastro);
          setUsuarioIdKeycloak(response.data.usuarioIdKeycloak);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };

    fetchData();
  }, [id]);

  const buscaListaMenuPerfis = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/menu-perfil/list?idCliente=${cliente}`);
      console.log(response.data);
      setListaMenuPerfil(orderBy(response.data, 'nomeMenu'));
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    buscaListaMenuPerfis();
  }, []);

  const handleEdit = async (event: any) => {
    event.preventDefault();

    if (
      !selectedMenuPerfil ||
      !senha ||
      !nome ||
      !login ||
      !novaSenha ||
      !isValidLogin ||
      !isValidNome ||
      !isValidSenha
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const data = {
      login: login.toLowerCase(),
      senha: novaSenha,
      nome: nome,
      ativo: ativo,
      usuResponsavelCadastro: idUsuario,
      usuDtHrUltimaAlteracao: dataConverter(new Date().toISOString()),
      usuDtHrCadastro: dataConverter(dataCadastro),
      clienteId: +cliente,
      roleUser: 'user',
      usuarioIdKeycloak: usuarioIdKeycloak,
      usuCdId: id,
      idMenuPerfil: selectedMenuPerfil,
    };
    console.log(data);
    try {
      setLoading(true);
      if (senhaValida == true) {
        await axios.put(`/usuario/cliente/${id}`, data);
        console.log(data);
        setIsDialogCadOK(true);
        setLoading(false);
      }
    } catch (err: any) {
      addToast({
        title: 'Erro',
        description: 'Erro ao atualizar!',
        type: 'error',
      });
      console.log(err.response);
      setLoading(false);
      return;
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.put(`/usuario/delete-logico?idUsuario=${id}`);
      setIsDialogCExclusaoOK(true);
    } catch {
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir!',
        type: 'error',
      });
      return;
    }
    handleClose();
  };

  const gerenciarSenha = (event: any) => {
    if (senha != null) {
      setSenha(event.target.value);
      setNovaSenha('');
      if (validaSenha(event.target.value)) {
        setIsValidSenha(true);
      } else {
        setIsValidSenha(false);
      }
    }
  };

  return (
    <Tela
      nome="Usuário"
      caminho="Alterar > Usuário Cliente"
      loading={loading}
      setLoading={setLoading}
      canDelete={canDelete}
      onClickDelete={() => {
        handleShow();
      }}
    >
      <Dialog
        header="Usuário alterado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-clientes/1');
        }}
      />

      <Dialog
        header="Usuário excluído com sucesso!"
        footer={<Button onClick={() => setIsDialogCExclusaoOK(false)}>Ok</Button>}
        visible={isDialogExclusaoOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-clientes/1');
        }}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${nome}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(id)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <Form noValidate validated={isFormInvalid} onSubmit={handleEdit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label>Login</Form.Label>
            <Form.Control
              value={login}
              onChange={(event) => {
                event.preventDefault();
                setLogin(noArroba(event.target.value));
                if (login.length < 2) {
                  setIsValidLogin(false);
                } else {
                  setIsValidLogin(true);
                }
              }}
              disabled
              minLength={3}
            />
            {!isValidLogin && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Nome</Form.Label>

            <Form.Control
              value={nome}
              onChange={(event) => {
                event.preventDefault();
                setNome(event.target.value);
                if (nome.length < 2) {
                  setIsValidNome(false);
                }
                if (nome.length >= 2 || nome.length === 0) {
                  setIsValidNome(true);
                }
              }}
              required
              minLength={3}
            />
            {!isValidNome && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Perfil</Form.Label>
            <Form.Control
              value={selectedMenuPerfil}
              onChange={(event) => {
                setSelectedMenuPerfil(parseInt(event.target.value, 10));
              }}
              required
              as="select"
            >
              <option value="">Selecione uma opção</option>
              {listaMenuPerfil &&
                listaMenuPerfil.map((each: MenuPerfilType) => (
                  <option value={each.id}>{each.nomeMenu}</option>
                ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={senha}
                onChange={(event) => gerenciarSenha(event)}
                required
                type={passwordShown ? 'text' : 'password'}
                maxLength={8}
                minLength={8}
                style={{ borderRightColor: 'white' }}
                isValid={senhaAdmin === senha || validaSenha(senha)}
                isInvalid={!validaSenha(senha) && senhaAdmin !== senha}
                pattern={senha === senhaAdmin ? undefined : '^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$'}
                title="A senha deve conter 8 caracteres e pelo menos uma letra "
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {!isValidSenha && senha !== senhaAdmin && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Senha inválida, deve conter 8 dígitos e pelo menos uma letra
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Confirme a senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={novaSenha}
                onChange={(event: any) => {
                  setNovaSenha(event.target.value);
                  event.preventDefault();
                  if (event.target.value === senha) {
                    setSenhaValida(true);
                  } else {
                    setSenhaValida(false);
                  }
                }}
                required
                type={passwordShown2 ? 'text' : 'password'}
                maxLength={8}
                minLength={8}
                style={{ borderRightColor: 'white' }}
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown2 ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {senhaValida == false && senha.length === 8 && (
              <p style={{ fontSize: '12px', color: 'red' }}>Senhas diferentes</p>
            )}
          </Form.Group>
        </Form.Row>
        {loading ? (
          <div style={{ marginTop: 30 }}>
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
            <p style={{ textAlign: 'center', marginTop: '-40px' }}>
              Aguarde enquanto o cadastro está sendo atualizado...
            </p>
            <ButtonsForm />
          </div>
        ) : (
          <div style={{ marginTop: 30 }}>
            <ButtonsForm canEdit={canEdit} />
          </div>
        )}
      </Form>
    </Tela>
  );
}

export default EditarUsuarioCliente;
