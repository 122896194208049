/* eslint-disable */
import { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import ReactExport from 'react-data-export';
import './styles.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Search from '../../components/Search/Search';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { isDateLessThanBaseDate } from '../../util/Validacoes/Validacoes';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { handleResultado } from '../../util/validaResultadoPesquisa';
import { validaVigencia } from '../../util/validaVigenciaPesquisa';
import { SecundaryButton } from '../../components/ButtonsForm/styles';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { obterData, obterDataHora, obterHora } from '../../util/date';

export type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  idCliente?: number;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    validadePesquisaEntidade: string;
    statusPesquisa?: string;
    statusPesquisaCondutorCnh?: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  validadeAnaliseCondutorAutonomo: number;
  validadeAnaliseNaoMotoristaAgregado: number;
  validadeAnaliseNaoMotoristaAutonomo: number;
  validadeAnaliseNaoMotoristaFuncionario: number;
  validadeAnaliseVeiculoTerceiro: number;
};

type PropsType = {
  data: ListagemHistoricoPesquisa[];
  canEdit?: boolean;
  canDelete?: boolean;
};

const TablePesquisa = ({ data, canEdit, canDelete }: PropsType) => {
  const history = useHistory();
  const [filteredData, setFilteredData] = useState<ListagemHistoricoPesquisa[]>();
  const [isAdmin, setIsAdmin] = useState(true);
  const { addToast } = useToast();
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page, request }: any = useParams();
  const indexOfLastPost = page * parseInt(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage);
  const [isDialogVisibleConfirmarExclusao, setIsDialogVisibleConfirmarExclusao] = useState(false);
  const [isDialogVisibleExclusaoEfetuada, setIsDialogVisibleExclusaoEfetuada] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [numLiberacao, setNumLiberacao] = useState<number | undefined>();
  const [idPesquisa, setIdPesquisa] = useState<number | undefined>();
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const borders = {
    top: { style: 'medium' },
    bottom: { style: 'medium' },
    left: { style: 'medium' },
    right: { style: 'medium' },
  };

  const alignment = {
    horizontal: 'center',
    wrapText: true,
  };

  // style linhas
  const borders2 = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const arrayCompletoDados: any = [];
  if (data) {
    const arrayDatasLancamento = data.map((each, index) => each.dataLancamento?.split('T')[0]);

    var aux = arrayDatasLancamento.filter(function (elemento, i) {
      return arrayDatasLancamento.indexOf(elemento) == i;
    });

    const arrayItens = data.map((each: any) => each.itens);

    const arrayItensPesquisados: any = [];
    const item = arrayItens.map((each: any) => each.map((value: any) => value.itemPesquisado));

    const loadDados = () => {
      data.forEach((pesquisa: any, i: any) => {
        const arrayListaItens = pesquisa.itens;

        if (arrayListaItens.length > 0) {
          let tableDados = arrayListaItens.map((each: any, idx: any) => [
            { value: pesquisa.empresa ? pesquisa.empresa : '', style: { border: borders2 } },
            {
              value: pesquisa.numLiberacao ? pesquisa.numLiberacao : '',
              style: { border: borders2 },
            },
            {
              value: each.itemPesquisado ? each.itemPesquisado : '',
              style: { border: borders2 },
            },
            {
              value: each.descricao ? each.descricao : '',
              style: { border: borders2 },
            },
            { value: each.tipo ? each.tipo : '', style: { border: borders2 } },
            {
              value: pesquisa.operacao.includes('Integrada')
                ? `${pesquisa.operacao}(${idx + 1})`
                : pesquisa.operacao,
              style: { border: borders2 },
            },
            { value: pesquisa.usuario ? pesquisa.usuario : '', style: { border: borders2 } },
            {
              value: pesquisa.dataLancamento ? obterDataHora(pesquisa.dataLancamento, true) : '',
              style: { border: borders2 },
            },
            {
              value:
                handleResultado(pesquisa.itens) != 'Em Andamento'
                  ? obterDataHora(each.dataRetorno, true)
                  : '',
              style: { border: borders2 },
            },
            {
              value:
                handleResultado(pesquisa.itens) === 'Em Acordo' ? validaVigencia(pesquisa) : '',
              style: { border: borders2 },
            },
            {
              value: pesquisa.resultado ? handleResultado(pesquisa.itens) : '',
              style: { border: borders2 },
            },
            {
              value: data[i + 1]
                ? data[i + 1].dataLancamento?.split('T')[0] ===
                  data[i].dataLancamento?.split('T')[0]
                  ? 'X'
                  : ''
                : '',
              style: { border: borders2 },
            }, // repetição
          ]);
          arrayCompletoDados.push(...tableDados);
        }
      });
    };
    loadDados();
  }
  const excel: ExcelGeneration[] = [
    {
      xSteps: 3,
      ySteps: 0,
      columns: [
        {
          title: 'Pesquisas Lançadas',
          widthPx: 3000,
          style: {
            alignment: alignment,
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title:
            request && request.length
              ? JSON.parse(request)[0].dataIni && JSON.parse(request)[0].dataFim
                ? `Período ${obterData(JSON.parse(request)[0].dataIni)} a ${obterData(
                    JSON.parse(request)[0].dataFim
                  )}`
                : 'Período:'
              : 'Período:',
          width: { wch: 15 },
          style: { font: { sz: '12' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Empresa',
          width: { wch: 30 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Nº Liberação',
          width: { wch: 15 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Item Pesquisado',
          width: { wch: 20 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Descrição',
          width: { wch: 30 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Tipo',
          width: { wch: 20 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Operação',
          width: { wch: 40 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Usuário',
          width: { wch: 20 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Lançamento',
          width: { wch: 30 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Retorno',
          width: { wch: 30 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Validade',
          width: { wch: 15 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Resultado',
          width: { wch: 30 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Repetição',
          width: { wch: 15 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
      ],
      data: [...arrayCompletoDados],
    },
  ];

  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>(excel);

  const paginate = (pageNumber: number) => {
    history.push({
      pathname: `/consultar-pesquisas-lancadas/${request}/${pageNumber}`,
    });
    // setCurrentPage(pageNumber);
  };
  const validacaoDataValidade = (value: string) => {
    const validationDate = isDateLessThanBaseDate({
      date: value,
    });
    return validationDate;
  };

  // useEffect(() => {
  //   setData(state.state);
  // }, []);

  const handleGoToDetails = (numLib: number) => {
    const getByNumLib = data?.find((fil) => fil.numLiberacao === numLib);

    history.push({
      pathname: `/detalhe-pesquisa`,
      state: { ...getByNumLib, isAdmin },
    });
  };

  const handleGoToEditarPesquisa = (numLib: number) => {
    const getByNumLib = data?.find((fil) => fil.numLiberacao === numLib);
    history.push({
      pathname: '/analise-perfil/editar',
      state: { state: getByNumLib },
    });
  };

  const handleDelete = async () => {
    try {
      const response = await axios.put(`/pesquisa/delete-logico-pesquisa/${numLiberacao}`);
      setIsDialogVisibleExclusaoEfetuada(true);
    } catch (err) {
      setIsDialogVisibleConfirmarExclusao(false);
      addToast({
        title: 'Erro!',
        description: 'Erro ao excluir!',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    if (searchValue) {
      setFilteredData(
        data.filter(
          (each) =>
            each.empresa?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.numLiberacao?.toString()?.includes(searchValue) ||
            each.itens[0]?.itemPesquisado?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[1]?.itemPesquisado?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[2]?.itemPesquisado?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[3]?.itemPesquisado?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[0]?.descricao?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[1]?.descricao?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[2]?.descricao?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[3]?.descricao?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[0]?.tipo?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[1]?.tipo?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[2]?.tipo?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.itens[3]?.tipo?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            each.usuario?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            obterData(each.dataLancamento?.split('T')[0])?.includes(searchValue) ||
            obterHora(each.dataLancamento?.split('T')[1])?.includes(searchValue) ||
            obterData(each.itens[0].dataRetorno?.split('T')[0])?.includes(searchValue) ||
            obterHora(each.itens[0].dataRetorno?.split('T')[1])?.includes(searchValue) ||
            each.itens[0]?.validade?.includes(searchValue) ||
            handleResultado(each.itens)?.toLowerCase()?.includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [searchValue, data]);

  const currentPesquisas =
    filteredData != undefined
      ? filteredData.slice(indexOfFirstPost, indexOfLastPost)
      : filteredData;

  return (
    <div>
      <Dialog
        header={`Deseja excluir a pesquisa de nº ${numLiberacao}?`}
        footer={
          <>
            <Button label="Sim" onClick={() => handleDelete()} />
            <Button label="Não" onClick={() => setIsDialogVisibleConfirmarExclusao(false)} />
          </>
        }
        visible={isDialogVisibleConfirmarExclusao}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleConfirmarExclusao(false)}
      />

      <Dialog
        header="Exclusão efetuada com sucesso!"
        footer={
          <>
            <Button
              label="OK"
              onClick={() => {
                setIsDialogVisibleConfirmarExclusao(false);
                setIsDialogVisibleExclusaoEfetuada(false);
              }}
            />
          </>
        }
        visible={isDialogVisibleExclusaoEfetuada}
        style={{ width: '50vw' }}
        modal
        onHide={() => window.location.reload()}
      />

      <div className="content-container">
        <div className="painel">
          <Search
            disabled={!data}
            setLoading={setLoading}
            setSearch={setSearch}
            setSearchValue={setSearchValue}
            search={search}
          />
          <div className="table-responsive" style={{ flex: 1 }}>
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Empresa</th>
                  <th>Nº Liberação</th>
                  <th>Item Pesquisado</th>
                  <th>Descrição</th>
                  <th>Tipo</th>
                  <th>Usuário</th>
                  <th>Lançamento</th>
                  <th>Retorno</th>
                  <th>Validade</th>
                  <th>Resultado</th>
                  <th style={{ width: 130 }}></th>
                </tr>
              </thead>
              <tbody>
                {currentPesquisas &&
                  currentPesquisas.map((each) => (
                    // each.itens.map((item) => (
                    <tr
                      className={`${
                        handleResultado(each.itens) === 'Análise Vencida'
                          ? 'alert-row'
                          : handleResultado(each.itens) === 'Pendente de Documentação'
                          ? 'alert-row'
                          : handleResultado(each.itens) === 'Em Andamento'
                          ? ''
                          : handleResultado(each.itens) === 'Em Acordo'
                          ? 'success-row'
                          : ''
                      }`}
                    >
                      <td>{each.empresa}</td>
                      <td>{each.numLiberacao}</td>
                      {each.operacao.includes('Integrada') ? (
                        <td
                          style={{
                            wordWrap: 'break-word',
                            fontSize: each.itens[3]
                              ? '10px'
                              : each.itens[2]
                              ? '11px'
                              : each.itens[1]
                              ? '12px'
                              : '',
                          }}
                        >
                          {each.itens[0] ? (
                            <>
                              {each.itens[0].itemPesquisado}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {each.itens[1] ? (
                            <>
                              {each.itens[1].itemPesquisado}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {each.itens[2] ? (
                            <>
                              {each.itens[2].itemPesquisado}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {each.itens[3] ? (
                            <>
                              {each.itens[3].itemPesquisado}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                        </td>
                      ) : (
                        <td>{each.itens[0].itemPesquisado}</td>
                      )}
                      {each.operacao.includes('Integrada') ? (
                        <td
                          style={{
                            wordWrap: 'break-word',
                            fontSize: each.itens[3]
                              ? '9px'
                              : each.itens[2]
                              ? '9px'
                              : each.itens[1]
                              ? '10px'
                              : '',
                          }}
                        >
                          {each.itens[0] ? (
                            <>
                              {each.itens[0].descricao}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {each.itens[1] ? (
                            <>
                              {each.itens[1].descricao}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {each.itens[2] ? (
                            <>
                              {each.itens[2].descricao}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {each.itens[3] ? (
                            <>
                              {each.itens[3].descricao}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                        </td>
                      ) : (
                        <td>{each.itens[0].descricao}</td>
                      )}
                      <td>{each.operacao}</td>
                      <td>{each.usuario}</td>
                      <td>
                        {each.dataLancamento === null
                          ? ''
                          : obterDataHora(each.dataLancamento, true)}
                      </td>

                      {handleResultado(each.itens) != 'Em Andamento' ? (
                        <td>{obterDataHora(each.itens[0].dataRetorno, true)}</td>
                      ) : (
                        <td />
                      )}
                      <td>
                        {handleResultado(each.itens) === 'Em Acordo' ? validaVigencia(each) : ''}
                      </td>
                      <td>{handleResultado(each.itens)}</td>
                      <td id="icons-container">
                        <ButtonTooltip
                          onClick={() => handleGoToDetails(each.numLiberacao)}
                          type="Visualizar"
                        />
                        <ButtonTooltip
                          onClick={() => {
                            if (canEdit) {
                              handleGoToEditarPesquisa(each.numLiberacao);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                          type="Editar"
                        />
                        <ButtonTooltip
                          onClick={() => {
                            if (canDelete) {
                              setIdPesquisa(each.itens[0].idPesquisaEntidade);
                              setNumLiberacao(each.numLiberacao);
                              setIsDialogVisibleConfirmarExclusao(true);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                          type="Cancelar"
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div id="gerar-xls">
            <SecundaryButton
              size="sm"
              onClick={() => history.push('/consultar-pesquisas-lancadas/')}
            >
              Voltar
            </SecundaryButton>

            <DropdownButton
              id="dropdown-item-button"
              variant="#FFDA53"
              style={{ background: '#FFDA53', borderRadius: '4px' }}
              title="Exportar"
            >
              <ExcelFile
                element={
                  <Dropdown.Item disabled={!excelGeneration} eventKey="1">
                    XLS
                  </Dropdown.Item>
                }
                filename="PesquisasLancadas"
              >
                <ExcelSheet dataSet={excelGeneration} name="PesquisasLancadas" />
              </ExcelFile>
            </DropdownButton>
          </div>
          <Pagination
            totalPosts={filteredData != undefined ? filteredData.length : 0}
            paginate={paginate}
            currentPage={page.toString()}
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TablePesquisa;
