import React, { useState } from 'react';
import { Button } from 'primereact/button';
import ReactExport from 'react-data-export';
import { MainButton } from '../../components/Buttons/mainButton';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Search from '../../components/Search/Search';
import { ExportButton } from '../../components/Buttons/exportButton';
import { Tela } from '../../components/Tela';
import { Tabela } from '../../components/Tabela';
import { GridEditavelComponent } from '../../components/GridEditavelComponent';

type ExcelGeneration = {
  ySteps?: number;
  xSteps: number;
  columns: { title: string; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined }[][];
};

export const PaginaTeste = () => {
  const [loading, setLoading] = useState(false);
  const [newLine, setNewLine] = useState(false);
  const [search, setSearch] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();

  const [data, setData] = useState([
    [1, 'brayan', '20', 'petropolis'],
    [2, 'yan', '54', 'petropolis'],
    [3, 'thaiara', '18', 'petropolis'],
  ]);
  const titles = ['id', 'Nome', 'idade', 'cidade'];
  const disabledColumns = [0];
  const requiredColumns = [1, 2, 3];

  const editar = async (object: any) => {
    console.log('editando', object);
  };

  const criar = async (object: any) => {
    console.log('criando', object);
  };

  const excluir = async (object: any) => {
    console.log('excluindo', object);
  };

  return (
    <Tela nome="Pagina Teste" caminho="Home > Pagina Teste" loading={loading}>
      <Search
        setLoading={setLoading}
        setSearch={setSearch}
        setSearchValue={setSearchValue}
        search={search}
        onClick={() => setNewLine(!newLine)}
      />
      <GridEditavelComponent
        titles={titles}
        data={data}
        setData={setData}
        excluir={excluir}
        editar={editar}
        criar={criar}
        disabledColumns={disabledColumns}
        requiredColumns={requiredColumns}
        novaLinha={newLine}
        setNovaLinha={setNewLine}
      />
      {/* <Tabela titles={titles} data={data} /> */}
    </Tela>
    // <div style={{ display: 'flex', justifyContent: 'row' }}>
    //   <MenuLateral />
    //   <div className="card">
    //     <div className="titulo-container">
    //       <h2 className="titulo">Página Teste</h2>
    //     </div>
    //     <Search
    //       setLoading={setLoading}
    //       setSearch={setSearch}
    //       setSearchValue={setSearchValue}
    //       search={search}
    //       // linkToAdd é opcional, sem ele não terá o icon de adicionar novo registro
    //       linkToAdd="/pagina-teste"
    //     />
    //     <div style={{ display: 'flex' }}>
    //       <MainButton label="Confirmar" color="yellow" />
    //       <MainButton label="Cancelar" color="white" />
    //       {/* Tamanho dos botões: M (medio), B (Grande. Default) */}
    //       <MainButton label="Confirmar" color="yellow" size="M" />
    //       <MainButton label="Cancelar" color="white" size="M" />
    //       <ExportButton
    //         label="Exportar"
    //         fileName="exportteste"
    //         name="name"
    //         dataSet={excelGeneration}
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};
