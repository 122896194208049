/* eslint-disable */
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Form, Col, Spinner, Button } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';
import './styles.css';
import { IoCloseSharp } from 'react-icons/io5';
import { Dropdown } from 'primereact/dropdown';
import ModalAddPtoInterm from './ModalAddPtoInterm';
import { Transportadores, Pontos, ListaPontos } from '../../api/Types/agendarViagensType';
import { getCookieSessionData } from '../../services/cookieService';
import {
  isHourLessThanBaseHour,
  isDateLessThanBaseDate,
  maskCpnj,
  soNumeros,
} from '../../util/Validacoes/Validacoes';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { obterData, obterDataHora, toZoned } from '../../util/date';

const AgendarViagens = () => {
  const [transportadores, setTrasportadores] = useState<Transportadores[]>([]);
  const [idCliente, setIdCliente] = useState();
  const [idEmbarcador, setIdEmbarcador] = useState();
  const [inicioDataViagem, setInicioDataViagem] = useState('');
  const [inicioHoraViagem, setInicioHoraViagem] = useState('');
  const [fimDataViagem, setfimDataViagem] = useState('');
  const [fimHoraViagem, setFimHoraViagem] = useState('');

  const [loading, setLoading] = useState(false);

  const [pontosCliente, setPontosCliente] = useState<ListaPontos[]>([]);
  const [pontosIntermediarios, setPontosIntermediarios] = useState<Pontos[]>([]);
  const [idPonto, setIdPonto] = useState(0);
  const [remessa, setRemessa] = useState('');
  const [dataPonto, setDataPonto] = useState('');
  const [horaPonto, setHoraPonto] = useState('');

  const [dataInicialPreenchida, setDataInicialPreenchida] = useState(false);
  const [dataFinalPreenchida, setDataFinalPreenchida] = useState(false);
  const [preenchePd, setPreenchePd] = useState({ disabled: false, opacity: 0.5 });
  const [preenchePo, setPreenchePo] = useState(false);

  const [nomePonto, setNomePonto] = useState('');
  const [erroHoraInicial, setErroHoraInicial] = useState(false);
  const [erroHoraFinal, setErroHoraFinal] = useState(false);
  const [erroDataInicial, setErroDataInicial] = useState(false);
  const [erroDataInicial2, setErroDataInicial2] = useState(false);
  const [erroDataFinal, setErroDataFinal] = useState(false);
  const [erroHoraMenorQueAtual, setErroHoraMenorQueAtual] = useState(false);

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tipoPonto, setTipoPonto] = useState('');
  const [cnpjCliente, setCnpjCliente] = useState('');

  const [pontosCadastrados, setPontosCadastrados] = useState<Pontos[]>([]);
  const { addToast } = useToast();
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const cliente = getCookieSessionData().usuarioVO.clienteId;
  const razaoSocialEmbarcador = getCookieSessionData().usuarioVO.clienteRazaoSocial;
  const cnpj = getCookieSessionData().usuarioVO.cnpj;
  const history = useHistory();
  const [idSmEnviada, setIdSmEnviada] = useState<any>();

  const getEmbarcadorId = async () => {
    const response = await axios.get(
      `/embarcador/search-embarcador?razaoSocial=${razaoSocialEmbarcador}`
    );
    const res = await axios.get(`/embarcador/embarcador-rel-cliente-por-cnpj?cnpj=${cnpj}`);
    setIdEmbarcador(res.data[0]?.idEmbarcador);
    setTrasportadores(res.data);
  };

  const handleCliente = async () => {
    if (idCliente !== null || typeof idCliente !== undefined) {
      const res = await axios.get(`/cliente/${idCliente}`);
      setCnpjCliente(res.data.cnpj);
    } else {
      setCnpjCliente('');
    }
  };

  useEffect(() => {
    if (idCliente) {
      handleCliente();
    }
  }, [idCliente]);

  useEffect(() => {
    if (cliente) {
      getEmbarcadorId();
      axios
        .get(`/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${cliente}`)
        .then((response: any) => {
          response.data = response.data.filter((ponto: ListaPontos) => ponto.ativo !== false);
          setPontosCliente(response.data);
        });
    }
  }, [cliente]);

  const onChangeTerminoViagem = (value: string) => {
    if (value.length > 10) {
      return;
    }
    // Data inicio não pode ser menor que data atual
    const validationDate = isDateLessThanBaseDate({
      date: inicioDataViagem,
    });
    console.log(validationDate);
    setErroDataInicial(validationDate);
    // Data fim não pode ser menor que data inicio
    const validationDate2 = isDateLessThanBaseDate({
      baseDate: inicioDataViagem,
      date: value,
    });
    setErroDataInicial2(validationDate2);
  };

  const onChangeInicioViagem = (value: any) => {
    if (value.length > 10) {
      return;
    }
    // Data inicio não pode ser menor que data atual
    const validationDate = isDateLessThanBaseDate({
      date: value,
    });
    console.log(validationDate);
    setErroDataInicial(validationDate);
    // Data fim não pode ser menor que data inicio
    const validationDate2 = isDateLessThanBaseDate({
      baseDate: value,
      date: fimDataViagem,
    });
    setErroDataInicial2(validationDate2 && !!fimDataViagem);
  };

  const excluirPontoIntermediario = (position: number, ponto: Pontos) => {
    let tempSequence: Pontos[] = pontosIntermediarios;

    tempSequence = tempSequence.filter((each, index) => {
      return index !== position;
    });

    let tempCadastrados: Pontos[] = pontosCadastrados.filter((each) => {
      return ponto.sequencia !== each.sequencia;
    });

    tempCadastrados = tempCadastrados.map((each, index) => {
      each.sequencia = index + 1;
      return each;
    });

    setPontosCadastrados(tempCadastrados);

    tempSequence = tempSequence.map((each, index) => {
      each.sequencia = index + 2;
      return each;
    });

    setPontosIntermediarios(tempSequence);
  };

  const handleAddPonto = () => {
    const temp: Pontos[] = [...pontosCadastrados];
    const novoPonto: any = {
      idPonto: idPonto,
      remessa: remessa,
      dataPonto: toZoned(`${dataPonto} ${horaPonto}`),
      tipoPonto: tipoPonto,
      idUsuario: idUsuario,
      idSm: null,
      idSmPv: null,
      sequencia: temp.length,
      nomePonto: nomePonto,
    };

    temp.splice(temp.length - 1, 0, novoPonto);

    temp[temp.length - 1].sequencia = temp.length;

    setPontosIntermediarios((prevState: any) => [...prevState, novoPonto]);

    setPontosCadastrados(temp);
  };

  const lancarAgendamentoViagem = async (event: any) => {
    event.preventDefault();
    if (
      !fimDataViagem ||
      !inicioDataViagem ||
      !fimHoraViagem ||
      !inicioHoraViagem ||
      erroDataFinal ||
      erroDataInicial ||
      erroDataInicial2 ||
      (erroHoraFinal && inicioDataViagem === fimDataViagem)
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    if (!idCliente) {
      addToast({
        title: 'Erro!',
        description: 'Preenchimento do campo "Transportador" é obrigatório.',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    const data = {
      fimHoraViagem: toZoned(`${fimDataViagem} ${fimHoraViagem}`),
      inicioDataViagem: toZoned(`${inicioDataViagem}, ${inicioHoraViagem}`),
      fimDataViagem: toZoned(`${fimDataViagem}, ${fimHoraViagem}`),
      inicioHoraViagem: toZoned(`${inicioDataViagem}, ${inicioHoraViagem}`),
      idCliente,
      idEmbarcador,
      solicitacaoMonitoramentoPlanoViagemVO: pontosCadastrados,
    };

    console.log('dados cadastrados: ', data);
    setLoading(true);
    try {
      const response = await axios.post('/solicitacao-monitoramento/cadastrar-pre-sm', data);
      setIdSmEnviada(soNumeros(response.data));
      setIsDialogVisible(true);
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <ModalAddPtoInterm
        inicioDataViagem={inicioDataViagem}
        inicioHoraViagem={inicioHoraViagem}
        fimHoraViagem={fimHoraViagem}
        fimDataViagem={fimDataViagem}
        nomePonto={nomePonto}
        setNomePonto={setNomePonto}
        setShow={setShowModal}
        show={showModal}
        onPress={() => handleAddPonto()}
        idPonto={idPonto}
        setTipoPonto={setTipoPonto}
        tipoPonto={tipoPonto}
        setIdPonto={setIdPonto}
        remessa={remessa}
        setRemessa={setRemessa}
        dataPonto={dataPonto}
        setDataPonto={setDataPonto}
        horaPonto={horaPonto}
        setHoraPonto={setHoraPonto}
      />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Agendar Viagens</h2>
          <h6 className="subtitulo">
            {'Operacional > Solicitações > Pré-SM > Pré Agendamento de SM'}
          </h6>
        </div>
        <Dialog
          header="Seu Agendamento foi criado com sucesso!"
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Número SM: {idSmEnviada}</p>
              <Button onClick={() => history.push('/pre-agendamento-viagens/1')}>OK</Button>
            </div>
          }
          visible={isDialogVisible}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisible(false)}
        />
        <Form
          noValidate
          validated={isFormInvalid}
          onSubmit={lancarAgendamentoViagem}
          className="form-sm"
        >
          <h6 style={{ color: 'red', fontSize: 12 }}>* Campos Obrigatórios</h6>
          <Form.Row>
            <Form.Group id="container">
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Transportador
              </Form.Label>
              <Dropdown
                style={{ width: '100.4%' }}
                value={idCliente}
                options={transportadores}
                placeholder="Selecione"
                onChange={(e: any) => setIdCliente(e.target.value)}
                optionLabel="razaoSocial"
                optionValue="idCliente"
                showClear
                filter
                className="multiselect-custom"
                id="transportador"
                required
              />
            </Form.Group>
            <Form.Group as={Col} sm={3}>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={idCliente ? maskCpnj(cnpjCliente) : ''}
                id="cnpj"
                name="cnpj"
                disabled
              />
            </Form.Group>
          </Form.Row>
          <hr />
          <Form.Row>
            <Form.Group>
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Início da Viagem
              </Form.Label>

              <Form.Control
                value={inicioDataViagem.toString()}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInicioDataViagem(e.target.value);
                  onChangeInicioViagem(obterData(e.target.value));
                  setDataInicialPreenchida(true);
                }}
                id="dataInicio"
                name="dataInicio"
                type="date"
                placeholder=""
                required
              />
              {erroDataInicial && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Data inicial não pode ser menor do que data atual.
                </p>
              )}
              {erroDataInicial2 && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Data inicial não pode ser maior do que data final.
                </p>
              )}
              <Form.Control.Feedback type="invalid">
                Campo Início da Viagem não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="container">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={inicioHoraViagem}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInicioHoraViagem(e.target.value);
                  const validation = isHourLessThanBaseHour({
                    date: inicioDataViagem,
                    hour: e.target.value,
                  });
                  setErroHoraMenorQueAtual(validation);
                  console.log(e.target.value);
                  console.log('Hora inicio é menor que a atual? ', validation);

                  // const validation = isHourLessThanBaseHour({
                  //   date: inicioDataViagem,
                  //   hour: e.target.value,
                  // });
                  // if (
                  //   validation === true ||
                  //   (fimHoraViagem === e.target.value && inicioDataViagem === fimDataViagem)
                  // ) {
                  //   setErroHoraFinal(true);
                  //   console.log('aa');
                  // } else {
                  //   setErroHoraFinal(false);
                  // }
                }}
                id="horaInicio"
                name="horaInicio"
                type="time"
                placeholder=""
                required
              />
              {erroHoraMenorQueAtual && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5, marginLeft: 5 }}>
                  Horário inicial da viagem não pode ser menor que o horário atual.
                </p>
              )}
            </Form.Group>
            <Form.Group id="pontoOrigemContainer">
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Ponto Origem
              </Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const temp: Pontos[] = [...pontosCadastrados];
                  temp[0] = {
                    idPonto: parseInt(e.target.value),
                    tipoPonto: 'O',
                    dataPonto: toZoned(`${inicioDataViagem} ${inicioHoraViagem}`),
                    idUsuario: idUsuario,
                    idSm: null,
                    idSmPv: null,
                    sequencia: 1,
                  };
                  setPontosCadastrados(temp);
                  setPreenchePo(true);
                }}
                disabled={dataInicialPreenchida === true ? false : true}
                as="select"
                id="idPonto"
                name="idPonto"
                required
              >
                <option></option>
                {pontosCliente.map((pontos, index) => (
                  <option key={index} value={pontos.idPonto}>
                    {pontos.nomePonto}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group>
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Término da Viagem
              </Form.Label>

              <Form.Control
                value={fimDataViagem.toString()}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setfimDataViagem(e.target.value);
                  onChangeTerminoViagem(e.target.value);
                  setDataFinalPreenchida(true);
                }}
                disabled={preenchePo === true ? false : true}
                id="dataInicio"
                name="dataInicio"
                type="date"
                placeholder=""
                required
              />
              {erroDataInicial2 && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Data final não pode ser menor do que a data inicial.
                </p>
              )}
              {/* {erroDataInicial2 && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                  Data final não pode ser menor do que data final.
                </p>
              )} */}
              <Form.Control.Feedback type="invalid">
                Campo Término da viagem não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="container">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={fimHoraViagem}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFimHoraViagem(e.target.value);
                  const validation = isHourLessThanBaseHour({
                    baseDate: inicioDataViagem,
                    baseHour: inicioHoraViagem,
                    date: fimDataViagem,
                    hour: e.target.value,
                  });
                  if (validation === true || inicioHoraViagem === e.target.value) {
                    setErroHoraFinal(true);
                    console.log('aa');
                  } else {
                    setErroHoraFinal(false);
                  }
                }}
                disabled={preenchePo === true ? false : true}
                id="horaInicio"
                name="horaInicio"
                type="time"
                placeholder=""
                required
              />
              {erroHoraFinal && fimDataViagem === inicioDataViagem && (
                <p style={{ fontSize: 12, color: 'red', marginTop: 5, marginLeft: 5 }}>
                  Horário final da viagem não pode ser menor ou igual que ao horário do início da
                  viagem.
                </p>
              )}
            </Form.Group>
            <Form.Group id="pontoOrigemContainer">
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Ponto Término
              </Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const temp: Pontos[] = [...pontosCadastrados];

                  const novoPonto = {
                    idPonto: parseInt(e.target.value),
                    tipoPonto: 'D',
                    dataPonto: toZoned(`${fimDataViagem} ${fimHoraViagem}`),
                    idUsuario: idUsuario,
                    idSm: null,
                    idSmPv: null,
                    sequencia: temp.length,
                  };
                  if (temp[temp.length - 1].tipoPonto === 'D') temp[temp.length - 1] = novoPonto;
                  else {
                    novoPonto.sequencia++;
                    temp.push(novoPonto);
                  }
                  setPontosCadastrados(temp);
                  setPreenchePd({ disabled: true, opacity: 1 });
                }}
                disabled={dataFinalPreenchida === true ? false : true}
                as="select"
                id="idPonto"
                name="idPonto"
                required
              >
                <option></option>
                {pontosCliente.map((pontos, index) => (
                  <option key={index} value={pontos.idPonto}>
                    {pontos.nomePonto}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <hr />
          <Form.Label>
            <span></span>Pontos Intermediários
          </Form.Label>
          <div className="table-container-agendar-viagens">
            <div className="table-responsive" style={{ flex: 1 }}>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th id="row-index-header">#</th>
                    <th>Ponto</th>
                    <th>Nº Remessa</th>
                    <th>Data</th>
                    <th style={{ width: 50 }}>
                      <button
                        disabled={preenchePd.disabled === true ? false : true}
                        style={{ opacity: preenchePd.opacity }}
                        className="btn-create-agendar-viagens"
                        onClick={() => setShowModal(true)}
                        type="button"
                      >
                        <i
                          style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                          className="pi pi-plus"
                        ></i>
                      </button>
                    </th>
                  </tr>
                </thead>
                {!pontosIntermediarios ? null : (
                  <tbody>
                    {pontosIntermediarios.map((each, index) => (
                      <tr key={index}>
                        <td>{index + 2}</td>
                        <td>{each.nomePonto}</td>
                        <td>{each.remessa}</td>
                        <td>{obterDataHora(each.dataPonto, true)}</td>
                        <td>
                          <Button
                            title="excluir Ponto"
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                            onClick={() => excluirPontoIntermediario(index, each)}
                          >
                            <IoCloseSharp size={22} color="#e71616" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          {loading ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <ButtonsForm disabled={erroDataInicial || erroDataFinal ? true : false} />
          )}
        </Form>
      </div>
    </div>
  );
};

export default AgendarViagens;
