/* eslint-disable */
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './styles.css';

type TecnologiaType = {
  idTecnologia: number;
  nomeTecnologia: string;
};

type ClienteType = {
  idCliente: number;
  razaoSocial: string;
};

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  pisoMinimo: number;
  setPisoMinimo: (piso: number) => void;
  tetoMaximo: number;
  setTetoMaximo: (teto: number) => void;
  onPress: () => void;
  tecnologiasArr: TecnologiaType[] | undefined;
  clientesArr: ClienteType[] | undefined;
  setChosenCliente: (cliente: ClienteType) => void;
  setChosenTecnologia: (tecnologia: TecnologiaType) => void;
  chosenCliente: ClienteType;
  chosenTecnologia: TecnologiaType;
};

const ModalAssocia: FC<Props> = ({
  show,
  setShow,
  pisoMinimo,
  setPisoMinimo,
  tetoMaximo,
  setTetoMaximo,
  onPress,
  tecnologiasArr,
  clientesArr,
  setChosenCliente,
  chosenCliente,
  setChosenTecnologia,
  chosenTecnologia,
}) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="associar-modal-text">
          <span>Deseja fazer esta associação?</span>
        </div>
        <div className="header-associar-container">
          <div className="header-associar-field">
            <span>Cliente</span>
            <Dropdown
              value={chosenCliente}
              options={clientesArr}
              onChange={(e) => setChosenCliente(e.value)}
              optionLabel="razaoSocial"
              filter
              filterBy="razaoSocial"
              className="p-dropdown"
            />
          </div>
          <div className="header-associar-field">
            <span>Tecnologia</span>
            <Dropdown
              value={chosenTecnologia}
              options={tecnologiasArr}
              onChange={(e) => setChosenTecnologia(e.value)}
              optionLabel="nomeTecnologia"
              filter
              filterBy="nomeTecnologia"
              placeholder="Tecnologia"
              className="p-dropdown"
            />
          </div>
        </div>
        <div className="header-associar-container">
          <div className="header-associar-field">
            <span>Piso Mínimo</span>
            <InputNumber
              value={pisoMinimo}
              onValueChange={(e) => setPisoMinimo(e.value)}
              mode="decimal"
              currency="BRL"
              currencyDisplay="code"
              locale="pt-BR"
              className="input-number-associar"
              minFractionDigits={2}
              maxFractionDigits={2}
            />
          </div>
          <div className="header-associar-field">
            <span>Teto Máximo</span>
            <InputNumber
              value={tetoMaximo}
              onValueChange={(e) => setTetoMaximo(e.value)}
              mode="decimal"
              currency="BRL"
              currencyDisplay="code"
              locale="pt-BR"
              className="input-number-associar"
              minFractionDigits={2}
              maxFractionDigits={2}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={() => {
            onPress();
            setShow(false);
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAssocia;
