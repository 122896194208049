export const TIPOS_CONDUTOR_AJUDANTE = [
  {
    id: 1,
    displayName: 'Agregado',
    name: 'AGREGADO',
  },
  {
    id: 2,
    displayName: 'Autônomo',
    name: 'AUTONOMO',
  },
  {
    id: 3,
    displayName: 'Funcionário',
    name: 'FUNCIONARIO',
  },
];

export const CATEGORIA_CNH = [
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
  { id: 'AB', name: 'AB' },
  { id: 'AC', name: 'AC' },
  { id: 'AD', name: 'AD' },
  { id: 'AE', name: 'AE' },
];
