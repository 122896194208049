import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';

type DispositivoType = {
  id: number;
  idTipoDispositivo: {
    dptTxNome: string;
  };
  nome: string;
  descricao: string;
  ativo?: boolean;
};

type Props = {
  canInsert?: boolean;
};

const DispositivoList: React.FC<Props> = ({ canInsert }) => {
  const history = useHistory();
  const { page }: any = useParams();
  const [data, setData] = useState<DispositivoType[]>([]);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const titles = ['+id', 'Tipo de dispositivo', 'Nome', 'Descrição'];
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchValue.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/listar/dispositivos/${pageNumber}`);
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Dispositivos',
          widthPx: 1060,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      columns: [],
      data: [],
    },
    {
      columns: [
        { title: 'Tipo de dispositivo', width: { wch: 25 } },
        { title: 'Nome', width: { wch: 25 } },
        { title: 'Status', width: { wch: 15 } },
        { title: 'Descrição', width: { wch: 40 } },
      ],
      data: data?.map((each) => [
        { value: each.idTipoDispositivo?.dptTxNome ? each.idTipoDispositivo?.dptTxNome : '' },
        { value: each.nome ? each.nome : '' },
        { value: each.ativo ? 'ATIVO' : 'INATIVO' },
        { value: each.descricao ? each.descricao : '' },
      ]),
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/dispositivo`);
      setData(response.data.filter((fil: any) => fil.ativo === true));
      const array = response.data
        .filter((fil: any) => fil.ativo === true)
        .map((each: DispositivoType) => {
          return [each.id, each.idTipoDispositivo.dptTxNome, each.nome, each.descricao];
        });
      setDataTabela(array);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar/dispositivos/1');
      setDataSearch(pesquisar(dataTabela, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Dispositivos"
      caminho="Cadastro > Rastreadores > Dispositivos"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchValue}
      search={search}
      linkToAdd="/cadastrar-dispositivo"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchValue.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={page}
      createDisabled={!canInsert}
      excelGeneration={multiDataSet}
      filename="Dispositivos"
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/editar-dispositivo/${id}`)}
        />
      )}
    </Tela>
  );
};

export default DispositivoList;
