import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import axios from '../../../config/axiosMaquina';

import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';

import Pagination from '../../../components/Pagination/Pagination';
import { getCookieSessionData } from '../../../services/cookieService';
import ModalTemperaturaVeiculo from './modal';
import { useToast } from '../../../hooks/Toast';

type Props = {
  canEdit: boolean;
};

const TemperaturaPorVeiculo: React.FC<Props> = ({ canEdit }) => {
  const { clienteId } = getCookieSessionData().usuarioVO;
  const { addToast } = useToast();
  const [veiculos, setVeiculos] = useState<VeiculoTypeGrid[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<VeiculoTypeGrid[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const currentVeiculos = (searchTerm.trim() !== '' ? searchResults : veiculos).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const loadVeiculos = async () => {
    setLoading(true);
    if (!clienteId) {
      return;
    }
    try {
      const response = await axios.get(`/veiculo/listarVeiculosPorCliente?cliente=${clienteId}`);
      setVeiculos(
        response.data.filter(
          (each: VeiculoTypeGrid) =>
            each.ativo && each.veiculoTipoNome.toUpperCase().includes('REFRIGERADO')
        )
      );
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clienteId) {
      loadVeiculos();
    }
  }, [clienteId]);

  useEffect(() => {
    const search = async () => {
      if (searchTerm.trim() !== '') {
        const filter = veiculos.filter((each: VeiculoTypeGrid) =>
          each.placa?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filter);
      }
    };
    search();
  }, [searchTerm]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Controle de Temperatura</h2>
          <h6 className="subtitulo">{'Controle Logístico > Controle de Temperatura'}</h6>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="search-create mb-4" style={{ width: '100%', marginTop: 25 }}>
            <span className="p-input-icon-left">
              <input
                type="text"
                placeholder="Pesquise"
                className="p-inputtext p-component"
                id="search-input"
                style={{ width: '240px' }}
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
              />
              <i className="pi pi-search" />
            </span>
          </div>
        </div>

        <div className="content-container" style={{ width: '100%' }}>
          <div className="painel">
            <div className="table-responsive" style={{ flex: 1 }}>
              {loading ? (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              ) : (
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th>Placa</th>
                      <th>Marca</th>
                      <th>Modelo</th>
                      <th>Tipo</th>
                      <th>Temperatura Mínima</th>
                      <th>Temperatura Máxima</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentVeiculos &&
                      currentVeiculos.map((veiculo: VeiculoTypeGrid) => {
                        return (
                          <tr key={veiculo.id}>
                            <td>{veiculo.placa === null ? '' : veiculo.placa}</td>
                            <td>{veiculo.veiculoMarcaNome}</td>
                            <td>{veiculo.modelo}</td>
                            <td>{veiculo.veiculoTipoNome}</td>
                            <td align="center">
                              {veiculo.temperaturaMinimaVeiculo &&
                                `${veiculo.temperaturaMinimaVeiculo}ºC`}
                            </td>
                            <td align="center">
                              {veiculo.temperaturaMaximaVeiculo &&
                                `${veiculo.temperaturaMaximaVeiculo}ºC`}
                            </td>

                            <td>
                              <div className="icons">
                                <ModalTemperaturaVeiculo
                                  veiculo={veiculo}
                                  loadVeiculos={loadVeiculos}
                                  clienteId={clienteId}
                                  canEdit={canEdit}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
              {!currentVeiculos.length && !loading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <strong>Não existem veículos refrigerados para serem listados</strong>
                </div>
              )}
            </div>
          </div>
          <Pagination
            totalPosts={searchTerm.trim() !== '' ? searchResults.length : veiculos.length}
            paginate={paginate}
            currentPage={currentPage.toString()}
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TemperaturaPorVeiculo;
