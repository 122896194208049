import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { orderBy, uniqBy } from 'lodash';
import { Dialog } from 'primereact/dialog';
import api from '../../../config/axiosMaquina';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import Search from '../../../components/Search/Search';
import { Tela } from '../../../components/Tela';
import { P, Ul } from '../styles';
import { ModalDelete } from '../../../components/ModalDelete';
import { removerSpecials } from '../../../util/format';
import { Spinner } from '../../../components/Spinner';
import { obterData } from '../../../util/date';

type PropsType = {
  canInsert: boolean;
  canEdit: boolean;
  canDelete: boolean;
};

export const ValidacaoCnh = ({ canInsert, canEdit, canDelete }: PropsType) => {
  const [data, setData] = useState<any[]>([]);
  const [model, setModel] = useState<any>();
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const [idDelete, setIdDelete] = useState(0);
  const { page }: any = useParams();
  const history = useHistory();
  const [header, setHeader] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = (searchValue.trim().length ? dataSearch : data)?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber: number) => {
    history.push(`/admin/listar/validacao-cnh/${pageNumber}`);
  };

  const fetchValidacaoCnh = async () => {
    setLoading(true);
    try {
      const response = await api.get('/validacaoCNH/listarValidacao');
      const listUf = uniqBy(response.data, 'idUf').map((each: any) => {
        return { idUf: each.idUf, uf: each.uf, ufSigla: each.ufSigla };
      });
      const newArray: any[] = [];
      for (let i = 0; i < listUf.length; i += 1) {
        newArray.push({
          idUf: listUf[i].idUf,
          uf: listUf[i].uf,
          ufSigla: listUf[i].ufSigla,
          situacoes: response.data
            .filter((e: any) => e.idUf === listUf[i].idUf)
            .map((f: any) => {
              return {
                id: f.id,
                dataInicio: f.dataInicio,
                dataFim: f.dataFim,
                dataRenovacao: f.dataRenovacao,
              };
            }),
        });
      }
      setData(orderBy(newArray, 'ufSigla'));
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchValidacaoCnh();
  }, []);

  useEffect(() => {
    if (searchValue.trim() !== '') {
      console.log(data);
      const searchValueLowerCase = removerSpecials(searchValue).toLowerCase();

      const filtered = data.filter(
        (each: any) =>
          removerSpecials(each.uf).toLowerCase().includes(searchValueLowerCase) ||
          `${each.ufSigla} - ${removerSpecials(each.uf)}`
            .toLowerCase()
            .includes(searchValueLowerCase) ||
          each.ufSigla.toLowerCase().includes(searchValueLowerCase)
      );
      console.log(filtered);
      setDataSearch(filtered);
    }
  }, [searchValue]);

  const deleteValidacaoCnh = async () => {
    try {
      await api.put(`/validacaoCNH/deletarValidacao/${idDelete}`);
      setShowModalDelete(false);
      setHeader('Excluído com sucesso!');
      fetchValidacaoCnh();
      setShowDialog(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Tela
      nome="Validação CNH"
      caminho="Gerenciamento de Clientes > Validação CNH"
      loading={loading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchValue.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      <Dialog
        header={header}
        footer={<Button onClick={() => setShowDialog(false)}>Ok</Button>}
        visible={showDialog}
        style={{ width: '50vw' }}
        modal
        onHide={() => setShowDialog(false)}
      />
      <ModalDelete
        show={showModalDelete}
        onHide={() => setShowModalDelete(false)}
        handleDelete={() => {
          deleteValidacaoCnh();
        }}
        handleClose={() => setShowModalDelete(false)}
        message="Deseja excluir o registro?"
      />
      <Modal
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{model?.uf}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Id</th>
                <th>Data Início</th>
                <th>Data Fim</th>
                <th>Data Renovação</th>
                <th> </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {model &&
                model?.situacoes.map((each: any) => (
                  <tr>
                    <td>{each.id}</td>
                    <td>{obterData(each.dataInicio)}</td>
                    <td>{obterData(each.dataFim)}</td>
                    <td>{obterData(each.dataRenovacao)}</td>
                    <td>
                      <ButtonTooltip
                        type="Editar"
                        onClick={() => {
                          if (canEdit) {
                            history.push(`/admin/editar/validacao-cnh/${each.id}`);
                          } else {
                            history.push('/acessonegado');
                          }
                        }}
                      />
                    </td>
                    <td>
                      <ButtonTooltip
                        type="Excluir"
                        onClick={() => {
                          if (canDelete) {
                            setIdDelete(each.id);
                            setShowModal(false);
                            setShowModalDelete(true);
                          } else {
                            history.push('/acessonegado');
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button> */}
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => setShowModal(false)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Search
        setLoading={setLoadingSearch}
        setSearch={setSearchValue}
        setSearchValue={setSearch}
        search={searchValue}
        linkToAdd="/admin/cadastrar/validacao-cnh"
        createDisabled={!canInsert}
      />
      <div className="table-responsive">
        {loadingSearch ? (
          <Spinner />
        ) : (
          <table className="table">
            <thead className="thead">
              <tr>
                <th>UF Emissora</th>
                <th>Situação</th>
                <th> </th>
                {/* <th> </th> */}
              </tr>
            </thead>
            <tbody>
              {data &&
                currentData.map((each: any) => (
                  <tr key={each.idUf}>
                    <td>
                      {each.ufSigla} - {each.uf}
                    </td>
                    {each.situacoes.length > 0 ? (
                      <td>
                        <Ul>
                          {each.situacoes
                            .filter((i: string) => i)
                            .map((e: any) => (
                              <li>
                                Validade: {obterData(e.dataInicio)} a {obterData(e.dataFim)},
                                renovação até {obterData(e.dataRenovacao)}.
                              </li>
                            ))}
                        </Ul>
                      </td>
                    ) : (
                      <td title="Sem prorrogação definida">
                        <P>Sem prorrogação definida</P>
                      </td>
                    )}
                    <td>
                      <ButtonTooltip
                        type="Excluir"
                        onClick={() => {
                          setModel(each);
                          setShowModal(true);
                        }}
                      />
                    </td>
                    {/* <td>
                    <ButtonTooltip
                      type="Editar"
                      onClick={() => {
                        setModel(each);
                        setShowModal(true);
                      }}
                    />
                  </td> */}
                    {/* <td>
                    <ButtonTooltip
                      type="Excluir"
                      onClick={() => {
                        setModel(each);
                        setShowModal(true);
                      }}
                    />
                  </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </Tela>
  );
};
