/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { toDecimal, toCurrency } from '../../../util/Validacoes/Validacoes';
import { getCookieSessionData } from '../../../services/cookieService';

type RankingAjudanteFuncionarioType = {
  fraCdId: number | null;
  quantidadeViagemRealizadaInicio: number | string;
  quantidadeViagemRealizadaFim: number | string;
  valorMedioPermitidoTransporteInicio: number | string;
  valorMedioPermitidoTransporteFim: number | string;
  valorPermitidoTransporte: number | string;
  idCliente: number;
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const RankingAjudanteFuncionarioForm: React.FC<Props> = ({ canEdit, canDelete }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();
  const { clienteId } = getCookieSessionData().usuarioVO;

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [model, setModel] = useState<RankingAjudanteFuncionarioType>({
    fraCdId: id || null,
    quantidadeViagemRealizadaInicio: '',
    quantidadeViagemRealizadaFim: '',
    valorMedioPermitidoTransporteInicio: '',
    valorMedioPermitidoTransporteFim: '',
    valorPermitidoTransporte: '',
    idCliente: clienteId,
  });
  const [error, setError] = useState({
    valorPermitidoTransporte: false,
    valorMedioPermitidoTransporteInicio: false,
    valorMedioPermitidoTransporteFim: false,
  });
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    quantidadeViagemRealizadaInicio,
    quantidadeViagemRealizadaFim,
    valorMedioPermitidoTransporteInicio,
    valorMedioPermitidoTransporteFim,
    valorPermitidoTransporte,
    idCliente,
  } = model;

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`/faixa-ranking-ajudante-funcionario/find/${id}`);
        setModel(response.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      !quantidadeViagemRealizadaInicio ||
      !quantidadeViagemRealizadaFim ||
      !valorMedioPermitidoTransporteInicio ||
      !valorMedioPermitidoTransporteFim ||
      !valorPermitidoTransporte ||
      !idCliente
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    const data = {
      ...model,
      valorMedioPermitidoTransporteInicio: +valorMedioPermitidoTransporteInicio,
      valorMedioPermitidoTransporteFim: +valorMedioPermitidoTransporteFim,
      valorPermitidoTransporte: +valorPermitidoTransporte,
    };

    if (id) {
      setLoading(true);
      try {
        await axios.put(`/faixa-ranking-ajudante-funcionario/atualizar?idFaf=${id}`, data);
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        await axios.post('/faixa-ranking-ajudante-funcionario/save', data);
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`/faixa-ranking-ajudante-funcionario/delete/${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      history.push('/ranking/ajudante-funcionario/listar/1');
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Ranking de Ajudante/Funcionário ${id ? 'alterado' : 'cadastrado'} com sucesso!`}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/ranking/ajudante-funcionario/listar/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Ranking de Ajudante/Funcionário excluído com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Ranking de Ajudante/Funcionário</h2>
          <h6 className="subtitulo">{`Cadastro > Ranking de Ajudante/Funcionário > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="painel">
              <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
              <Form
                className="form-sm"
                noValidate
                validated={isFormInvalid}
                onSubmit={handleSubmit}
              >
                <Form.Group>
                  <Form.Label className="requiredField">
                    Qtde. Viagens Realizadas - valor Inicial
                  </Form.Label>
                  <Form.Control
                    value={quantidadeViagemRealizadaInicio}
                    onChange={(e) => {
                      setModel({ ...model, quantidadeViagemRealizadaInicio: +e.target.value });
                    }}
                    maxLength={15}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="requiredField">
                    Qtde. Viagens Realizadas - Valor Final
                  </Form.Label>
                  <Form.Control
                    value={quantidadeViagemRealizadaFim}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        quantidadeViagemRealizadaFim: +e.target.value,
                      });
                    }}
                    maxLength={15}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="requiredField">
                    Valor Médio Transportado - Valor Inicial
                  </Form.Label>
                  <Form.Control
                    value={toCurrency(valorMedioPermitidoTransporteInicio)}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        valorMedioPermitidoTransporteInicio: toDecimal(e.target.value.trim()),
                      });
                    }}
                    onBlur={() => {
                      if (!(valorMedioPermitidoTransporteInicio > 0.0)) {
                        setError({ ...error, valorMedioPermitidoTransporteInicio: true });
                      } else {
                        setError({ ...error, valorMedioPermitidoTransporteInicio: false });
                      }
                    }}
                    isInvalid={error.valorMedioPermitidoTransporteInicio}
                    type="text"
                    maxLength={23}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Preencha o campo corretamente
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="requiredField">
                    Valor Médio Transportado - Valor Final
                  </Form.Label>
                  <Form.Control
                    value={toCurrency(valorMedioPermitidoTransporteFim)}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        valorMedioPermitidoTransporteFim: toDecimal(e.target.value.trim()),
                      });
                    }}
                    onBlur={() => {
                      if (!(valorMedioPermitidoTransporteFim > 0.0)) {
                        setError({ ...error, valorMedioPermitidoTransporteFim: true });
                      } else {
                        setError({ ...error, valorMedioPermitidoTransporteFim: false });
                      }
                    }}
                    isInvalid={error.valorMedioPermitidoTransporteFim}
                    type="text"
                    maxLength={23}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Preencha o campo corretamente
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="requiredField">Valor Permitido para Transporte</Form.Label>
                  <Form.Control
                    value={toCurrency(valorPermitidoTransporte)}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        valorPermitidoTransporte: toDecimal(e.target.value.trim()),
                      });
                    }}
                    onBlur={() => {
                      if (!(valorPermitidoTransporte > 0.0)) {
                        setError({ ...error, valorPermitidoTransporte: true });
                      } else {
                        setError({ ...error, valorPermitidoTransporte: false });
                      }
                    }}
                    isInvalid={error.valorPermitidoTransporte}
                    type="text"
                    maxLength={23}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Preencha o campo corretamente
                  </Form.Control.Feedback>
                </Form.Group>
                <ButtonsForm canEdit={canEdit} />
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RankingAjudanteFuncionarioForm;
