import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  display: flex;
  margin-left: 10px;
  font-size: 14px;
  margin-left: 80px;
  margin-top: 5px;
  color: #a4a4a4;
`;

type BreadcrumbsProps = {
  disabled?: boolean;
};

export const Breadcrumb = styled.a<BreadcrumbsProps>`
  color: #a4a4a4;
  cursor: ${(props: BreadcrumbsProps) => (!props.disabled ? 'pointer' : 'normal')};
  margin: 5px;
  font-weight: 500;

  &:hover {
    text-decoration: ${(props: BreadcrumbsProps) =>
      !props.disabled ? 'underline #333333' : 'none'};
    color: ${(props: BreadcrumbsProps) => (!props.disabled ? '#333333' : '#a4a4a4')};
  }
`;

export const Separator = styled.p`
  color: #a4a4a4;
  margin: 5px;
  font-weight: 500;
`;
