import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { orderBy } from 'lodash';
import api from '../../../config/axiosMaquina';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import Search from '../../../components/Search/Search';
import { Tela } from '../../../components/Tela';
import { maskTelefone } from '../../../util/masks';
import { useToast } from '../../../hooks/Toast';
import { ModalDelete } from '../../../components/ModalDelete';
import { GerenteType } from '../../../Types/Gerente';

type PropsType = {
  canDelete: boolean;
  canEdit: boolean;
  canInsert: boolean;
};

export const Gerentes = ({ canDelete, canEdit, canInsert }: PropsType) => {
  const defaultModel = { id: 0, ativo: true, nome: '', email: '', telefone: '' };
  const [data, setData] = useState<GerenteType[]>([]);
  const [dataSearch, setDataSearch] = useState<GerenteType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const [gerente, setGerente] = useState<GerenteType>(defaultModel);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [header, setHeader] = useState('');
  const { page }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchValue.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/admin/listar/gerentes/${pageNumber}`);
  };

  const fetchGerentes = async () => {
    try {
      const response = await api.get('/gerente/listarGerentes');
      setData(orderBy(response.data, 'nome'));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGerentes();
  }, []);

  const deleteGerente = async (idGer: number) => {
    try {
      await api.put(`/gerente/delete-logico/${idGer}`);
      setHeader('Gerente excluído com sucesso!');
      setDialog(true);
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir gerente.',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (searchValue.trim() !== '') {
      const searchLowerCase = searchValue.toLowerCase();
      const filteredData = data.filter(
        (each: GerenteType) =>
          each.nome.toLowerCase().includes(searchLowerCase) ||
          each.email.toLowerCase().includes(searchLowerCase) ||
          maskTelefone(each.telefone).includes(searchLowerCase) ||
          each.telefone.toLowerCase().includes(searchLowerCase)
      );
      setDataSearch(filteredData);
    }
  }, [searchValue]);
  console.log(canInsert);
  return (
    <Tela
      nome="Gerentes"
      caminho="Cadastro > Gerentes"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchValue.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      <Dialog
        header={header}
        footer={
          <Button
            label="OK"
            onClick={() => {
              fetchGerentes();
              setDialog(false);
            }}
          />
        }
        visible={dialog}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          fetchGerentes();
          setDialog(false);
        }}
      />
      <ModalDelete
        message={`Deseja excluir o gerente ${gerente.nome}?`}
        show={showModalDelete}
        onHide={() => setShowModalDelete(false)}
        handleDelete={() => {
          setShowModalDelete(false);
          deleteGerente(gerente.id);
        }}
        handleClose={() => setShowModalDelete(false)}
      />
      <Search
        setLoading={setLoadingSearch}
        setSearch={setSearch}
        setSearchValue={setSearchValue}
        search={search}
        linkToAdd="/admin/cadastrar/gerentes"
        createDisabled={!canInsert}
      />
      <div className="table-responsive">
        <table className="table">
          <thead className="thead">
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>E-mail</th>
              <th> </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              currentData.map((each: any) => (
                <tr key={each.id}>
                  <td>{each.nome}</td>
                  <td>{maskTelefone(each.telefone)}</td>
                  <td>{each.email}</td>
                  <td>
                    <ButtonTooltip
                      type="Editar"
                      onClick={() => {
                        if (canEdit) {
                          history.push(`/admin/editar/gerentes/${each.id}`);
                        } else {
                          history.push('/acessonegado');
                        }
                      }}
                    />
                  </td>
                  <td>
                    <ButtonTooltip
                      type="Excluir"
                      onClick={() => {
                        if (canDelete) {
                          setGerente(each);
                          setShowModalDelete(true);
                        } else {
                          history.push('/acessonegado');
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Tela>
  );
};
