/* eslint-disable */

import { FC, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { ListagemHistoricoPesquisa, Item } from '.';
import Input from '../../../components/Input';
import { ResumoProcessual } from '../../../components/ResumoProcessual';
import { TIPOS_CONDUTOR_AJUDANTE } from '../../../constants/Condutor';
import getUfEndereco from '../../../util/getUfEndereco';
import { SituacaoVigenciaContainer } from './styles';
import { getCookieSessionData } from '../../../services/cookieService';
import { CnhPesquisa } from '../../../Types/PesquisaCongeladaType';
import { obterData } from '../../../util/date';

type Props = {
  dadosCnh: CnhPesquisa | undefined;
  solicitacao: ListagemHistoricoPesquisa;
};

const DadosPesquisaCnh: FC<Props> = ({ dadosCnh, solicitacao }) => {
  const { role } = getCookieSessionData().usuarioVO;
  const [item, setItem] = useState<Item | undefined>();

  useEffect(() => {
    setItem(solicitacao.itens.find((each: Item) => each.itemPesquisado === dadosCnh?.cpf));
  });

  return (
    <>
      <Form.Label className="SMLancadasTitle">Dados da Pequisa CNH</Form.Label>
      <Form.Row>
        <Input
          required
          value={dadosCnh?.cpf}
          onChange={() => false}
          id="cpf"
          name="cpf"
          textInputTitle="CPF:"
          disabled
        />
        <Input
          required
          value={
            TIPOS_CONDUTOR_AJUDANTE.find((each) => each.id.toString() === dadosCnh?.tipo)
              ?.displayName
          }
          onChange={() => false}
          id="tipo"
          name="tipo"
          textInputTitle="Tipo:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={dadosCnh?.nome}
          onChange={() => false}
          id="nome"
          name="nome"
          textInputTitle="Nome:"
          disabled
        />
        <Input
          required
          value={dadosCnh?.dataNascimento ? obterData(dadosCnh?.dataNascimento) : ''}
          onChange={() => false}
          id="nascimento"
          name="nascimento"
          textInputTitle="Nascimento:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={dadosCnh?.cnhNumero}
          onChange={() => false}
          id="cnh"
          name="cnh"
          textInputTitle="CNH Registro nº:"
          disabled
        />
        <Input
          required
          value={
            isNaN(dadosCnh?.ufCnh as any)
              ? dadosCnh?.ufCnh
              : getUfEndereco(parseInt(dadosCnh?.ufCnh as any))
          }
          onChange={() => false}
          id="idUfEmissorCnh"
          name="idUfEmissorCnh"
          textInputTitle="UF:"
          disabled
        />
        <Input
          required
          value={dadosCnh?.categoria}
          onChange={() => false}
          id="categoria"
          name="categoria"
          textInputTitle="Categoria:"
          disabled
        />
        <Input
          required
          value={dadosCnh?.validade ? obterData(dadosCnh?.validade) : ''}
          onChange={() => false}
          id="validade"
          name="validade"
          textInputTitle="Validade:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={dadosCnh?.cnhNumSeguranca}
          onChange={() => false}
          id="cnhNumSeguranca"
          name="cnhNumSeguranca"
          textInputTitle="CNH Segurança nº:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={dadosCnh?.nomePai}
          onChange={() => false}
          id="nomePai"
          name="nomePai"
          textInputTitle="Pai:"
          disabled
        />
        <Input
          required
          value={dadosCnh?.nomeMae}
          onChange={() => false}
          id="nomeMae"
          name="nomeMae"
          textInputTitle="Mãe:"
          disabled
        />
      </Form.Row>

      <SituacaoVigenciaContainer>
        <div>
          <span>
            Situação:{' '}
            <span
              style={{
                color: item?.statusPesquisaCondutorCnh === 'PENDENTE_DE_DOCUMENTACAO' ? 'red' : '',
              }}
            >
              {item?.statusPesquisaCondutorCnh === 'EM_ACORDO'
                ? 'Em Acordo'
                : item?.statusPesquisaCondutorCnh === 'EM_ANDAMENTO'
                ? 'Em Andamento'
                : item?.statusPesquisaCondutorCnh === 'PENDENTE_DE_DOCUMENTACAO'
                ? 'Pendente de Documentação'
                : null}
            </span>
          </span>
        </div>
        {item?.statusPesquisaCondutorCnh != 'EM_ACORDO' ? null : (
          <div>
            <span>
              Vigência:{' '}
              <span>
                {item?.validade != 'No Embarque' ? obterData(item?.validade) : item?.validade}
              </span>
            </span>
          </div>
        )}
      </SituacaoVigenciaContainer>

      {role === 'admin' && item?.statusPesquisaCondutorCnh === 'PENDENTE_DE_DOCUMENTACAO' ? (
        <>
          <Form.Row className="mt-4">
            <Form.Label>Resumo Processual</Form.Label>
          </Form.Row>
          <ResumoProcessual
            numLiberacao={solicitacao.numLiberacao}
            cpf={dadosCnh?.cpf}
            tipo="CNH"
          />
        </>
      ) : null}
    </>
  );
};

export default DadosPesquisaCnh;
