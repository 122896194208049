export const CONSULTAR_PICKER_DATA = [
  { id: 1, name: 'CONDUTORES', displayName: 'Somente Condutores' },
  { id: 2, name: 'AJUDANTES', displayName: 'Somente Ajudantes/Funcionários' },
  { id: 3, name: 'VEICULOS', displayName: 'Somente Veículos' },
  { id: 4, name: 'TODOS', displayName: 'Todas Análises' },
];

export const TIPO_BUSCA = [
  { id: 0, name: 'SEM_PARAMETROS', displayName: 'Todos' },
  { id: 1, name: 'COD_LIBERACAO', displayName: 'Por Pesquisa' },
  { id: 2, name: 'PERIODO', displayName: 'Por Período' },
  { id: 3, name: 'VEICULO', displayName: 'Por Veículo' },
  { id: 4, name: 'CONDUTOR', displayName: 'Por Condutor' },
  { id: 5, name: 'AJUDANTE', displayName: 'Por Ajudante' },
];
