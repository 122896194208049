/* eslint-disable */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from '../../assets/logo_golden.jpg';
import uniqBy from 'lodash/uniqBy';
import { obterData, obterDataHora, obterHora } from '../../util/date';

const GeneratePDF = (
  dadosTabela: any,
  remessa: any,
  periodo: string,
  clienteRazaoSocial: string | undefined
) => {
  const doc = new jsPDF('l', 'mm', 'a4');

  const tableColumn = [
    'S.M.',
    'Ponto',
    'Nº Remessa',
    'Agendamento',
    'Previsão',
    'Chegada',
    'Saída',
    'Status',
    'Transportador',
  ];

  const tableRows: any = [];

  const renderDateTime = (date: string, timeOrDate?: string) => {
    if (!date) {
      return '';
    }
    if (timeOrDate === 'time') {
      return obterHora(date);
    }
    if (timeOrDate === 'date') {
      return obterData(date);
    }
    return obterDataHora(date);
  };

  const renderPonto = (ponto: string, seq: number) => {
    if (!ponto && !seq) {
      return '';
    }
    return `${seq} - ${ponto}`;
  };

  let end: boolean = false;

  function hexToRgb(hex: string) {
    let bigint = parseInt(hex.replace('#', ''), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return [r, g, b];
  }

  function intercalateColorsByGroup(array: any, color: string) {
    const result = [
      {
        color: color,
        text: array[0],
      },
    ];
    console.log('array', array);

    let lastColor = color;
    for (let i = 1; i < array.length; i++) {
      let last = array[i - 1].idSm;
      if (array[i].idSm !== last) {
        lastColor = lastColor === '#f5f5f5' ? '#ffffff' : '#f5f5f5';
      }
      result.push({
        color: lastColor,
        text: array[i],
      });
      last = array[i];
    }
    return result;
  }

  dadosTabela.forEach((value: any, index: any) => {
    const uniq = uniqBy(value, 'idSm');
    intercalateColorsByGroup(value, '#f5f5f5').forEach((v: any, i: number) => {
      const { color, text } = v;
      const tableData = [
        text.idSm,
        renderPonto(text.ponto, text.seqPonto),
        text.nuRemessa,
        renderDateTime(text.dtHrAgendamento),
        renderDateTime(text.dtHrPrevisaoPistema),
        renderDateTime(text.dtHrChegada),
        renderDateTime(text.dtHrSaida),
        text.statusPonto,
        text.transportador,
        color,
      ];
      tableRows.push(tableData);
      console.log('tableRows', tableRows);
    });

    if (dadosTabela.length === index + 1) end = true;
    page();
    tableRows.length = 0;
  });

  function page() {
    // @ts-ignore
    doc.autoTable(tableColumn, tableRows, {
      startY: 50,
      styles: {
        overflow: 'linebreak',
        fontSize: 10,
        valign: 'middle',
        halign: 'left',
      },
      columnStyles: {
        0: { valign: 'top', halign: 'left' },
      },
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [204, 204, 204],
      },
      headerStyles: {
        lineWidth: 0.2,
        lineColor: [204, 204, 204],
      },
      theme: 'plain',
      didParseCell: function (cell: any, data: any) {
        console.log('cell', cell);
        console.log('data', cell.row.raw.color);
        if (cell.row.raw[9]) {
          cell.cell.styles.fillColor = hexToRgb(cell.row.raw[9]);
        }
      },
      // drawRow: function (row: any, data: any) {
      //   data.doc.line(
      //     data.cursor.x - data.table.width,
      //     data.cursor.y + row.height,
      //     data.cursor.x,
      //     data.cursor.y + row.height
      //   );
      //   data.doc.line(
      //     data.cursor.x - data.table.width,
      //     data.cursor.y + row.height,
      //     data.cursor.x,
      //     data.cursor.y + row.height
      //   );
      // },
    });

    doc.addImage(Logo, 'jpg', 10, 10, 80, 20);

    doc.setFontSize(12);
    doc.text(`Página ${doc.internal.getNumberOfPages()}/${dadosTabela.length}`, 260, 30);
    doc.text(`${new Date().toLocaleDateString('pt-BR')}`, 260, 35);
    doc.line(14, 38, 283, 38);
    doc.setFontSize(15);
    doc.text('ACOMPANHAMENTO DE REMESSAS', 115, 35);

    doc.setFontSize(10);
    doc.line(14, 38, 283, 38);

    doc.text(`Embarcador: ${clienteRazaoSocial}`, 15, 45);
    doc.setFontSize(10);
    doc.text(periodo, 229, 45);

    if (!end) doc.addPage();
  }
  const date = new Date().toLocaleDateString('pt-BR').split(' ');
  const dateStr = date[0];

  doc.save(`acompanhamento_remessas_${dateStr}.pdf`);
};

export default GeneratePDF;
