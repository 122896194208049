import React, { FC, useEffect, useState } from 'react';
import { Form, Col, ProgressBar } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Switch from 'react-bootstrap/esm/Switch';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { CriticasType, ModelSmType, ServicosContratadosType } from '../../Types/SmType';
import { AddButton } from '../AddButton';
import { Tela } from '../Tela';
import api from '../../config/axiosMaquina';
import { confirmUser, getCookieSessionData } from '../../services/cookieService';
import { VeiculoIdPlaca } from '../../api/model/VeiculoIdPlacaType';
import { TipoOperacoesCliente } from '../../api/model/TipoOperacoesClienteType';
import { CarretaIdPlaca } from '../../api/model/CarretaIdPlacaType';
import { LinhaForm } from '../LinhaForm';
import { Invalid, renderCriticaChecklist, renderCriticaSat } from './components';
import {
  PontosSm,
  SolicitacaoMonitoramentoType,
} from '../../api/model/SolicitacaoMonitoramentoType';
import { VeiculoDadosSM } from '../../api/model/VeiculoDadosSMType';
import { CarretaDadosSM } from '../../api/model/CarretaDadosSMType';
import { CondutorIdNome, CondutorSmType } from '../../api/model/CondutorIdNomeType';
import { AjudanteSmType } from '../../api/model/AjudanteDadosSMType';
import {
  defaultAjudante,
  defaultBau,
  defaultBloqueiosPorPontos,
  defaultCarreta,
  defaultCondutor,
  defaultCriticas,
  defaultLogin,
  defaultModel,
  defaultParticularidades,
  defaultRotaPonto,
  defaultServicosContratados,
  defaultVeiculo,
} from './default';
import { BauType, BloqueadoPorPontos, ModelSm } from '../../Types/SolicitacaoMonitoramentoTypes';
import { TipoComunicacaoIdNome } from '../../api/model/TipoComunicacaoIdNomeType';
import { TecnologiaRastreamentoIdNome } from '../../api/model/TecnologiaRastreamentoIdNomeType';
import ButtonsForm from '../ButtonsForm/ButtonsForm';
import { useToast } from '../../hooks/Toast';
import {
  FormataStringData2,
  isHourLessThanBaseHour,
  soNumeros,
  toCurrency,
} from '../../util/Validacoes/Validacoes';
import { ClienteParticularidadesDTO } from '../../Types/ParticularidadesType';
import { dateNow, getFormatedData, getFormatedData2 } from '../../util/format';
import getUfEndereco from '../../util/getUfEndereco';
import { AjudanteIdNome } from '../../api/model/AjudanteIdNomeType';
import { LoginUser } from '../../Types/SmTypes';

export const Sm: FC = () => {
  const { clienteId, usuarioId, role, login } = getCookieSessionData().usuarioVO;
  const { id }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(100);
  const [progressMessage, setProgressMessage] = useState('');
  const [model, setModel] = useState<ModelSm>(defaultModel);
  const [usuarioLogado, setUsuarioLogado] = useState<LoginUser>(defaultLogin);

  const [veiculo, setVeiculo] = useState<VeiculoDadosSM>(defaultVeiculo);
  const [carreta1, setCarreta1] = useState<VeiculoDadosSM>(defaultVeiculo);
  const [carreta2, setCarreta2] = useState<VeiculoDadosSM>(defaultVeiculo);
  const [bau, setBau] = useState<BauType>(defaultBau);
  const [ajudante1, setAjudante1] = useState<AjudanteSmType>(defaultAjudante);
  const [ajudante2, setAjudante2] = useState<AjudanteSmType>(defaultAjudante);
  const [condutor1, setCondutor1] = useState<CondutorSmType>(defaultCondutor);
  const [condutor2, setCondutor2] = useState<CondutorSmType>(defaultCondutor);

  const [veiculos, setVeiculos] = useState<VeiculoIdPlaca[]>([]);
  const [carretas, setCarretas] = useState<CarretaIdPlaca[]>([]);
  const [baus, setBaus] = useState<any[]>([]);
  const [embarcadores, setEmbarcadores] = useState<any[]>([]);
  const [condutores, setCondutores] = useState<CondutorIdNome[]>([]);
  const [ajudantes, setAjudantes] = useState<AjudanteIdNome[]>([]);
  const [operacoes, setOperacoes] = useState<TipoOperacoesCliente[]>([]);
  const [tecnologias, setTecnologias] = useState<TecnologiaRastreamentoIdNome[]>([]);
  const [tiposComunicacoes, setTiposComunicacoes] = useState<TipoComunicacaoIdNome[]>([]);

  const [rotas, setRotas] = useState<any[]>([]);
  const [rotaOrigem, setRotaOrigem] = useState('');
  const [rotaDestino, setRotaDestino] = useState('');
  const [pontos, setPontos] = useState<any[]>([]);
  const [pontosEmbarcador, setPontosEmbarcador] = useState<any[]>([]);
  const [pontosError, setPontosError] = useState<string[]>([]);

  const [parametroOperacional, setParametroOperacional] = useState<any[]>();
  const [parametrosEmbarcador, setParametrosEmbarcador] = useState<any[]>();
  const [servicosContratados, setServicosContratados] = useState<ServicosContratadosType>(
    defaultServicosContratados
  );
  const [particularidades, setParticularidades] =
    useState<ClienteParticularidadesDTO>(defaultParticularidades);

  const [formInvalid, setFormInvalid] = useState(false);
  const [desabilitaForm, setDesabilitaForm] = useState(false);
  const [mostrarCamposLogin, setMostrarCamposLogin] = useState(false);
  const [mudancasFeitas, setMudancasFeitas] = useState(true);

  const [dialogSmExistenteNaData, setDialogSmExistenteNaData] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [headerDialog, setHeaderDialog] = useState('');

  const [criticaChecklistVeiculo, setCriticaChecklistVeiculo] = useState(false);
  const [criticaChecklistCarreta1, setCriticaChecklistCarreta1] = useState(false);
  const [criticaChecklistCarreta2, setCriticaChecklistCarreta2] = useState(false);
  const [criticaSatVeiculo, setCriticaSatVeiculo] = useState(false);
  const [criticaSatCarreta1, setCriticaSatCarreta1] = useState(false);
  const [criticaSatCarreta2, setCriticaSatCarreta2] = useState(false);

  const [criticas, setCriticas] = useState<CriticasType>(defaultCriticas);

  const [bloqueiosPorPontos, setBloqueiosPorPontos] =
    useState<BloqueadoPorPontos>(defaultBloqueiosPorPontos);

  const [itensLancamento, setItensLancamento] = useState('');

  const [tipoCriticaChecklist, setTipoCriticaChecklist] = useState('');
  const [confirmacaoPreSm, setConfirmacaoPreSm] = useState(false);
  const [rotaPorEmbarcador, setRotaPorEmbarcador] = useState(false);
  const [veiculoRefrigerado, setVeiculoRefrigerado] = useState(false);
  const [permiteCarreta, setPermiteCarreta] = useState(false);
  const [tipoRota, setTipoRota] = useState<number>(0);
  const styleAddButton = { fontSize: '1.2em', marginLeft: 5, marginRight: 20, cursor: 'pointer' };
  const history = useHistory();
  const { addToast } = useToast();

  const updateModel = (e: any) => {
    if (!model) {
      return;
    }
    // setMudancasFeitas(true);
    setModel({
      ...model,
      [e.target.name]: e.target.value,
    });
  };

  const fetchVeiculos = async () => {
    try {
      const response = await api.get(
        `/veiculo/listarIdEPlaca?cliente=${clienteId}&grupoMacroVe%C3%ADculo=1`
      );
      setVeiculos(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchCarretas = async () => {
    try {
      const response = await api.get(
        `/veiculo/listarIdEPlaca?cliente=${clienteId}&grupoMacroVe%C3%ADculo=2`
      );
      setCarretas(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchBaus = async () => {
    try {
      const response = await api.get(`/bau-intercambiavel/listaIdENumero?cliente=${clienteId}`);
      setBaus(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchEmbarcadores = async () => {
    try {
      const response = await api.get(
        `/embarcador/listarIdRazaoSocialEmbarcador?cliente=${clienteId}`
      );
      setEmbarcadores(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchCondutores = async () => {
    try {
      const response = await api.get(`/condutor/listarCondutorNome?cliente=${clienteId}`);
      setCondutores(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchAjudantes = async () => {
    try {
      const response = await api.get(
        `/ajudante-funcionario/listarAjudanteNome?cliente=${clienteId}`
      );
      setAjudantes(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchOperacoes = async () => {
    try {
      const response = await api.get(`/tipo-operacao-sm/listarTipoOperacaoSM`);
      setOperacoes(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchTecnologias = async () => {
    try {
      const response = await api.get(`/tecnologia-rastreamento/listarIdNome`);
      setTecnologias(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchRotas = async () => {
    try {
      const response = await api.get(`/rota/listarIdNome?cliente=${clienteId}`);
      setRotas(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const convertePontos = (d: any[]) => {
    const pts: PontosSm[] = d.map((each: any, index: number) => {
      return {
        dataPonto: `${each.agendamento?.slice(0, 16)}:00.196Z`,
        idPonto: each.idPonto,
        idSm: parseInt(id, 10),
        idSmPv: null,
        idUsuario: usuarioId,
        remessa: each.remessa,
        sequencia: each.sequencia,
        tipoPonto: index === 0 ? 'O' : index === d.length - 1 ? 'D' : 'I',
      };
    });
    return pts;
  };

  const fetchPontos = async () => {
    try {
      const response = await api.get(
        `/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${clienteId}`
      );
      setPontos(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchParametroOperacional = async () => {
    try {
      const response = await api.get(`/parametro-operacional`);
      setParametroOperacional(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchServicosContratados = async () => {
    try {
      const response = await api.get(`/cliente/getServicosContratadosCliente?cliente=${clienteId}`);
      setServicosContratados(response.data);
      setModel({
        ...model,
        obsPesquisa:
          response.data.cscNuCadastroConsulta === 1
            ? ''
            : `Análise de Perfil não realizada pela GS. Favor verificar se este(s) condutor(es), ajudante(s), veículo e/ou carreta possui liberação com outra Gerenciadora dentro da validade da viagem atual.`,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchParticularidades = async () => {
    try {
      const response = await api.get(`/cliente/getParticularidadesCliente`, {
        params: {
          cliente: clienteId,
        },
      });
      setParticularidades(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchDadosEmbarcador = async (idEmb: any) => {
    try {
      const responseEmb = await api.get(
        `/embarcador/listarDadosEmbarcadorParaSM?embarcador=${idEmb}`
      );
      setParametrosEmbarcador(responseEmb.data);
      if (responseEmb.data.isClienteEmbarcador && rotaPorEmbarcador) {
        const response = await api.get(
          `/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${responseEmb.data.idCliente}`
        );
        setPontosEmbarcador(convertePontos(response.data));
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchPreSm = async () => {
    if (id !== '0') {
      const response = await api.get(
        `/solicitacao-monitoramento-plano-viagem/listarPontosPorPreSM/${id}`
      );
      if (!response) {
        setConfirmacaoPreSm(false);
      } else {
        const responsePreSm = await api.get(`/solicitacao-monitoramento/${id}`);
        console.log('Pontos Pre-SM', convertePontos(response.data));
        console.log('Response Pre-SM', responsePreSm.data);
        setModel({
          ...model,
          // idEmbarcador: response.data.idEmbarcador,
          solicitacaoMonitoramentoPlanoViagemVO: convertePontos(response.data),
        } as ModelSm);
        setConfirmacaoPreSm(true);
        setRotaPorEmbarcador(true);
        setTipoRota(2);
      }
    }
  };

  const fetchTipoOperacao = (e: any) => {
    setModel({ ...model, [e.target.name]: e.target.value, valorCarga: 0 });
  };

  const verificaLancamento = async () => {
    if (!model.dataInicio || !model.horaInicio) {
      return;
    }
    const arrayItens = [];
    const dataInicio = `${model.dataInicio} ${model.horaInicio}`;

    if (model.idVeiculo) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idVeiculo: model.idVeiculo,
        },
      });
      if (response.data) {
        arrayItens.push('Veículo');
      }
    }
    if (model.idCondutor) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idCondutor: model.idCondutor,
        },
      });
      if (response.data) {
        arrayItens.push('Condutor(1)');
      }
    }
    if (model.idCondutor2) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idCondutor2: model.idCondutor2,
        },
      });
      if (response.data) {
        arrayItens.push('Condutor(2)');
      }
    }
    if (model.idAjudante) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idAjudante: model.idAjudante,
        },
      });
      if (response.data) {
        arrayItens.push('Ajudante');
      }
    }
    if (model.idAjudante2) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idAjudante2: model.idAjudante2,
        },
      });
      if (response.data) {
        arrayItens.push('Ajudante(2)');
      }
    }
    if (model.idBau) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idBau: model.idBau,
        },
      });
      if (response.data) {
        arrayItens.push('Baú');
      }
    }
    if (model.idCarreta) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idCarreta: model.idCarreta,
        },
      });
      if (response.data) {
        arrayItens.push('Carreta(1)');
      }
    }
    if (model.idCarreta2) {
      const response = await api.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio,
          idCarreta2: model.idCarreta2,
        },
      });
      if (response.data) {
        arrayItens.push('Carreta(2)');
      }
    }
    if (arrayItens.length) {
      setHeaderDialog(
        `Já existe uma SM para ${arrayItens.join(', ')} ${`na data  ${FormataStringData2(
          model.dataInicio
        )} às ${model.horaInicio}.`}`
      );
      setShowDialog(true);
    }
  };

  const verificaPontosVeiculos = async (
    name: string,
    idItem: number,
    type: string,
    idEmb: number
  ) => {
    if (!idEmb) {
      return;
    }

    try {
      const response = await api.get('/solicitacao-monitoramento/verificar-vigencia-veiculo-sm', {
        params: {
          idCliente: clienteId,
          idVeiculo: idItem,
          idEmbarcador: idEmb,
        },
      });
      if (response.data) {
        addToast({
          title: 'Erro',
          description: `${name} com mais de 30 pontos de não conformidade!`,
          type: 'error',
        });
        setBloqueiosPorPontos({ ...bloqueiosPorPontos, [type]: true });
      } else {
        setBloqueiosPorPontos({ ...bloqueiosPorPontos, [type]: false });
      }
    } catch (error) {
      setBloqueiosPorPontos({ ...bloqueiosPorPontos, [type]: false });
    }
  };

  const verificaPontosVeiculosEmbarcador = () => {
    if (!model.idEmbarcador) {
      return;
    }
    if (model.idCarreta) {
      verificaPontosVeiculos('Carreta (1)', model.idCarreta2, 'carreta1', model.idEmbarcador);
    }
    if (model.idCarreta2) {
      verificaPontosVeiculos('Carreta (2)', model.idCarreta2, 'carreta2', model.idEmbarcador);
    }
    if (model.idVeiculo) {
      verificaPontosVeiculos('Veículo', model.idVeiculo, 'veiculo', model.idEmbarcador);
    }
    if (model.idCondutor) {
      verificaPontosVeiculos('Condutor (1)', model.idCondutor, 'condutor1', model.idEmbarcador);
    }
    if (model.idCondutor2) {
      verificaPontosVeiculos('Condutor (2)', model.idCondutor2, 'condutor2', model.idEmbarcador);
    }
  };

  const verificaPontosCondutor = async (name: string, idItem: number, type: string) => {
    try {
      const response = await api.get('/solicitacao-monitoramento/verificar-vigencia-condutor-sm', {
        params: {
          idCliente: clienteId,
          idCondutor: idItem,
          idEmbarcador: model.idEmbarcador,
        },
      });
      if (response.data) {
        addToast({
          title: 'Erro',
          description: `${name} com mais de 30 pontos de não conformidade!`,
          type: 'error',
        });
        setBloqueiosPorPontos({ ...bloqueiosPorPontos, [type]: false });
      } else {
        setBloqueiosPorPontos({ ...bloqueiosPorPontos, [type]: false });
      }
    } catch (error: any) {
      setBloqueiosPorPontos({ ...bloqueiosPorPontos, [type]: false });
    }
  };

  const verificaValorCarga = (e: any) => {
    // VERIFICAÇÃO DO VALOR DE CARGA DE ACORDO COM OPERAÇÃO E LIMITES DE TRANSPORTE DO EMBARCADOR
    // setModel({ ...model, valorCarga: e.target.value });
  };

  const updateCritica = (
    item:
      | 'condutor1'
      | 'condutor2'
      | 'ajudante1'
      | 'ajudante2'
      | 'veiculo'
      | 'carreta1'
      | 'carreta2',
    key: string,
    value: string
  ) => {
    setCriticas({
      ...criticas,
      [item]: { ...criticas[item], [key]: value },
    });
  };

  const verificaPesquisa = async (
    idItem: number,
    tipo: string,
    name: string,
    nameObj:
      | 'veiculo'
      | 'carreta1'
      | 'carreta2'
      | 'condutor1'
      | 'condutor2'
      | 'ajudante1'
      | 'ajudante2'
  ) => {
    if (!idItem) {
      return;
    }
    updateCritica(nameObj, 'pesquisa', '');
    let statusPesquisa = '';

    console.log(tipo);
    // Não tem serviço analise de perfil contratado?
    if (servicosContratados.cscNuCadastroConsulta !== 1) {
      return;
    }

    // Não esta habilitado a critica pra esse tipo especifico de veiculo?
    if (nameObj === 'veiculo' || nameObj.includes('carreta')) {
      if (
        !(
          (particularidades.flagCritiarAnaliseFrotaPropriaNaSM && tipo === '1') ||
          (particularidades.flagCriticarAnaliseAgregadoNaSM && tipo === '2') ||
          (particularidades.flagCriticarAnaliseTerceiroNaSM && tipo === '3')
        )
      ) {
        return;
      }
    }

    if (nameObj.includes('ajudante')) {
      if (
        !(
          (particularidades.flagCriticarAnaliseNaoMotoristaAgregadoNaSM && tipo === '1') ||
          (particularidades.flagCriticarAnaliseNaoMotoristaAutonomoNaSM && tipo === '2') ||
          (particularidades.flagCriticarAnaliseNaoMotoristaNaSM && tipo === '3')
        )
      ) {
        return;
      }
    }

    if (nameObj.includes('condutor')) {
      if (
        !(
          (particularidades.flagCriticarAnaliseCondutorAgregadoNaSM && tipo === '1') ||
          (particularidades.flagCriticarAnaliseCondutorAutonomoNaSM && tipo === '2') ||
          (particularidades.flagCriticarAnaliseCondutorFuncionarioNaSM && tipo === '3')
        )
      ) {
        return;
      }
    }
    let tipoParam: any = {};
    let endpoint = '';
    if (nameObj.includes('condutor')) {
      tipoParam = { idCondutor: idItem };
      endpoint = 'condutor';
    }
    if (nameObj.includes('ajudante')) {
      tipoParam = { idAjudante: idItem };
      endpoint = 'ajudante';
    }
    if (nameObj.includes('carreta')) {
      tipoParam = { idCarreta: idItem };
      endpoint = 'carreta';
    }

    if (nameObj === 'veiculo') {
      tipoParam = { idVeiculo: idItem };
      endpoint = 'veiculo';
    }

    try {
      const response = await api.get(`/pesquisa/verificar-vigencia-${endpoint}`, {
        params: {
          dataInicio:
            model.dataInicio && model.horaInicio
              ? `${getFormatedData(model.dataInicio)} ${model.horaInicio}`
              : getFormatedData2(dateNow()),
          idCliente: clienteId,
          ...tipoParam,
        },
      });
      if (response.data === 'Pesquisa em acordo') {
        statusPesquisa = '';
      }
      if (response.data.includes('possui pesquisa vencida')) {
        statusPesquisa = `${name} está com pesquisa vencida`;
      }
      if (response.data.includes('não possui pesquisa ou pesquisa em acordo')) {
        statusPesquisa = `${name} não possui pesquisa.`;
      }
    } catch {
      statusPesquisa = '';
    }
    console.log(statusPesquisa);
    updateCritica(nameObj, 'pesquisa', statusPesquisa);
  };

  const findVeiculo = async (idVei: number, tipo: string) => {
    // RESET VEICULOS
    if (!idVei) {
      switch (tipo) {
        case 'veiculo':
          setVeiculo(defaultVeiculo);
          setCriticaChecklistVeiculo(false);
          updateCritica('veiculo', 'pesquisa', '');
          setBloqueiosPorPontos({ ...bloqueiosPorPontos, veiculo: false });
          setPermiteCarreta(false);
          return;
        case 'carreta1':
          setCarreta1(defaultVeiculo);
          setCriticaChecklistCarreta1(false);
          updateCritica('carreta1', 'pesquisa', '');
          setBloqueiosPorPontos({ ...bloqueiosPorPontos, carreta1: false });
          setCarreta2(defaultVeiculo);
          setBloqueiosPorPontos({ ...bloqueiosPorPontos, carreta2: false });
          return;
        case 'carreta2':
          setCarreta2(defaultVeiculo);
          updateCritica('carreta2', 'pesquisa', '');
          setCriticaChecklistCarreta2(false);
          setBloqueiosPorPontos({ ...bloqueiosPorPontos, carreta2: false });
          return;
        default:
          return;
      }
    }
    try {
      const response = await api.get(
        `/veiculo/listarDadosVeiculoParaSM?idCliente=${clienteId}&veiculo=${idVei}`
      );
      switch (tipo) {
        case 'veiculo':
          setVeiculo(response.data);
          setCriticaChecklistVeiculo(response.data.statusValidadeChecklist === 'SEM CHECKLIST');
          if (
            response.data.tipoCarreta?.toUpperCase().includes('CAVALO MECANICO') ||
            response.data.tipoCarreta?.toUpperCase().includes('ROMEU')
          ) {
            if (!carretas.length) {
              fetchCarretas();
            }
            setPermiteCarreta(true);
          }
          verificaPontosVeiculos('Veículo', idVei, 'veiculo', model.idEmbarcador);
          verificaPesquisa(idVei, response.data.tipoFrota, 'Veículo', 'veiculo');
          return;
        case 'carreta1':
          setCarreta1(response.data);
          setCriticaChecklistCarreta1(response.data.statusValidadeChecklist === 'SEM CHECKLIST');
          verificaPontosVeiculos('Carreta (1)', idVei, 'carreta1', model.idEmbarcador);
          verificaPesquisa(idVei, response.data.tipoFrota, 'Carreta (1)', 'carreta1');
          return;
        case 'carreta2':
          setCarreta2(response.data);
          setCriticaChecklistCarreta2(response.data.statusValidadeChecklist === 'SEM CHECKLIST');
          verificaPontosVeiculos('Carreta (2)', idVei, 'carreta2', model.idEmbarcador);
          verificaPesquisa(idVei, response.data.tipoFrota, 'Carreta (2)', 'carreta2');
          return;
        default:
          return;
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const findCondutor = async (idCond: number, isSecond: boolean) => {
    if (!idCond) {
      switch (isSecond) {
        case true:
          setBloqueiosPorPontos({ ...bloqueiosPorPontos, condutor2: false });
          setCondutor2(defaultCondutor);
          return;
        case false:
          setBloqueiosPorPontos({ ...bloqueiosPorPontos, condutor1: false });
          setCondutor1(defaultCondutor);
          return;
        default:
          return;
      }
    }
    try {
      const response = await api.get(`/condutor/listarDadosCondutorParaSM?condutor=${idCond}`);
      switch (isSecond) {
        case true:
          verificaPontosCondutor('Condutor (2)', idCond, 'idCondutor2');
          setCondutor2(response.data);
          verificaPesquisa(idCond, response.data.tipo, 'Condutor (2)', 'condutor2');
          return;
        case false:
          verificaPontosCondutor('Condutor (1)', idCond, 'idCondutor1');
          setCondutor1(response.data);
          verificaPesquisa(idCond, response.data.tipo, 'Condutor (1)', 'condutor1');
          return;
        default:
          return;
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const findAjudante = async (idAju: number, isSecond: boolean) => {
    if (!idAju) {
      switch (isSecond) {
        case true:
          // setBloqueiosPorPontos({ ...bloqueiosPorPontos, condutor2: false });
          setAjudante2(defaultAjudante);
          return;
        case false:
          // setBloqueiosPorPontos({ ...bloqueiosPorPontos, ajudante: false });
          setAjudante1(defaultAjudante);
          return;
        default:
          return;
      }
    }
    try {
      const response = await api.get(
        `/ajudante-funcionario/listarDadosAjudanteFuncionarioParaSM?ajudante=${idAju}`
      );
      switch (isSecond) {
        case true:
          // verificaPontosCondutor('Condutor (2)', idCond, 'idCondutor2');
          setAjudante2(response.data);
          verificaPesquisa(idAju, response.data.tipo, 'Ajudante (2)', 'ajudante2');
          return;
        case false:
          // verificaPontosCondutor('Condutor (1)', idCond, 'idCondutor1');
          setAjudante1(response.data);
          verificaPesquisa(idAju, response.data.tipo, 'Ajudante (1)', 'ajudante1');
          return;
        default:
          return;
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const findBau = async (idBau: number) => {
    if (!idBau) {
      setBau(defaultBau);
    }
    try {
      const response = await api.get(`/bau-intercambiavel/listarDadosBauParaSM?bau=${idBau}`);
      setBau(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const findTiposComunicacoes = async (idTec: number) => {
    if (!idTec) {
      setTiposComunicacoes([]);
    }
    try {
      const response = await api.get(
        `/tipo-comunicacao/listarIdNomePorTecnologia?tecnologia=${idTec}`
      );
      setTiposComunicacoes(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };
  console.log('model', model);

  const findRota = (idRota: number) => {
    const rotaSelecionada = rotas.find((e: any) => e.id === +idRota);
    if (!rotaSelecionada) {
      setRotaOrigem('');
      setRotaDestino('');
      return;
    }
    setRotaOrigem(
      `${rotaSelecionada.logradouroOrigem}${rotaSelecionada.complementoOrigem ? ', ' : ' '}${
        rotaSelecionada.complementoOrigem
      } - ${rotaSelecionada.bairroOrigem}. ${rotaSelecionada.cidadeOrigem}, ${getUfEndereco(
        rotaSelecionada.idUFOrigem
      )} - CEP: ${rotaSelecionada.cepOrigem}`
    );
    setRotaDestino(
      `${rotaSelecionada.logradouroDestino}${rotaSelecionada.complementoDestino ? ', ' : ' '}${
        rotaSelecionada.complementoDestino
      } - ${rotaSelecionada.bairroDestino}. ${rotaSelecionada.cidadeDestino}, ${getUfEndereco(
        rotaSelecionada.idUFDestino
      )} - CEP: ${rotaSelecionada.cepDestino}`
    );
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    // validaCampos()
    // validaPesquisas()

    const dataPreSm = {};
    const dataRota = {};
    const dataPontos = {};
    const data = {};

    try {
      const response = await api.post('/solicitacao-monitoramento/save', data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const renderLogin = () => {
    return (
      <>
        <Form.Row className="justify-content-md-center">
          <Form.Group controlId="formGridAlertaChecklist">
            <Form.Label className="txt_alerta">
              Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
            </Form.Label>
          </Form.Group>
        </Form.Row>

        <Form.Row className="justify-content-md-center">
          <Col md={2}>
            <Form.Control
              as="select"
              id="options"
              value={mostrarCamposLogin ? 'Sim' : 'Não'}
              onChange={(e: any) => setMostrarCamposLogin(e.target.value === 'Sim')}
              disabled={desabilitaForm}
            >
              <option value="Não">Não</option>
              <option value="Sim">Sim</option>
            </Form.Control>
          </Col>
        </Form.Row>
      </>
    );
  };

  const handleConfirmLogin = async (
    usuario: string,
    loginPreenchido: LoginUser,
    roleLogado: string,
    codigo: number,
    setUsuario: React.Dispatch<React.SetStateAction<LoginUser>>
  ) => {
    // setLoading(true);
    if (loginPreenchido.usuario === login) {
      try {
        confirmUser(loginPreenchido.usuario, loginPreenchido.senha, roleLogado, codigo).then(() => {
          setUsuario((prevState) => ({ ...prevState, isLogado: true }));
          // confirmacaoLoginUsuario(usuario);
        });
      } catch (error: any) {
        // confirmacaoErroLoginUsuario(usuario);
        console.log(error);
        setLoading(false);
      }
    } else {
      // confirmacaoErroLoginUsuario(usuario);
      setLoading(false);
    }
  };

  const doLogin = () => {
    return (
      <>
        <Form.Row className="justify-content-md-center mt-4">
          <Col md={3}>
            <Form.Control
              value={usuarioLogado.usuario}
              onChange={(e) => {
                setUsuarioLogado({ ...usuarioLogado, usuario: e.target.value });
              }}
              id="usuarioLogado"
              name="usuarioLogado"
              placeholder="Usuário"
              maxLength={100}
            />
          </Col>
        </Form.Row>
        <Form.Row className="justify-content-md-center mt-3" style={{ marginLeft: '2%' }}>
          <Col md={3}>
            <Form.Control
              value={usuarioLogado.senha}
              onChange={(e) => {
                setUsuarioLogado({ ...usuarioLogado, senha: e.target.value });
              }}
              id="senhaUsuarioLogado"
              name="senhaUsuarioLogado"
              placeholder="Senha"
              type="password"
              style={{ width: '102%' }}
              maxLength={8}
            />
          </Col>
          <AddButton
            onClick={() => {
              handleConfirmLogin('usuario1', usuarioLogado, role, clienteId, setUsuarioLogado);
            }}
            check
          />
        </Form.Row>

        <LinhaForm />
      </>
    );
  };

  const adicionarPontos = () => {
    const { solicitacaoMonitoramentoPlanoViagemVO } = model;
    const { idPonto, onlyDataPonto, onlyTimePonto } =
      solicitacaoMonitoramentoPlanoViagemVO[solicitacaoMonitoramentoPlanoViagemVO.length - 1];
    if (idPonto && onlyDataPonto && onlyTimePonto) {
      // if (flagSolicitarNumeroRemessaNaSM && model.remessa) {
      //   setAdicionaPontosIntermediarios(true);
      // }
      setModel((prevState) => ({
        ...prevState,
        solicitacaoMonitoramentoPlanoViagemVO: [
          ...solicitacaoMonitoramentoPlanoViagemVO,
          defaultRotaPonto,
        ],
      }));
    }
  };

  console.log(`pontosError: ${pontosError}`);

  const isPontoDateInvalid = (index: number, _model?: ModelSm, hour?: string, date?: string) => {
    const { solicitacaoMonitoramentoPlanoViagemVO } = _model || model;
    const hora = hour || solicitacaoMonitoramentoPlanoViagemVO[index]?.onlyTimePonto;
    const data = date || solicitacaoMonitoramentoPlanoViagemVO[index]?.onlyDataPonto;
    if (!hora && !data) {
      return;
    }
    const baseDate =
      index === 0
        ? new Date().toISOString().split('T')[0]
        : solicitacaoMonitoramentoPlanoViagemVO[index - 1].onlyDataPonto;
    const baseHour =
      index === 0
        ? `${new Date().getHours()}:${new Date().getMinutes()}`
        : solicitacaoMonitoramentoPlanoViagemVO[index - 1].onlyTimePonto;

    const arr: string[] = pontosError;

    const throwError = (minDate: string, minHour: string) => {
      const hourDate = new Date(`${hora} ${data}`).getTime();
      const minDateDate = new Date(`${minDate} ${minHour}`).getTime();

      return hourDate < minDateDate;
    };

    solicitacaoMonitoramentoPlanoViagemVO.forEach((ponto) => {
      if (!data || !hora) {
        arr[index] = '';
      }
      if (index === 0) {
        const error = throwError(baseDate, baseHour);
        arr[index] = error ? 'Data/Hora menor que a data atual.' : '';
      } else if (index === 1) {
        const error = throwError(
          solicitacaoMonitoramentoPlanoViagemVO[0].onlyDataPonto,
          solicitacaoMonitoramentoPlanoViagemVO[0].onlyTimePonto
        );
        arr[index] = error ? 'Data/Hora menor que Ponto Origem.' : '';
      } else {
        const error = throwError(
          solicitacaoMonitoramentoPlanoViagemVO[index - 1].onlyDataPonto,
          solicitacaoMonitoramentoPlanoViagemVO[index - 1].onlyTimePonto
        );
        arr[index] = error ? `Data/Hora menor que Ponto(${index}).` : '';
      }
    });
    setPontosError(arr);

    //   if (index === 0) {
    //     setPontosError([
    //       ...pontosError,
    //       {
    //         index,
    //         message: 'Data/Hora menor que a data atual.',
    //       },
    //     ]);
    //   } else if (index === 1) {
    //     setPontosError([
    //       ...pontosError,
    //       {
    //         index,
    //         message: `Data/Hora menor que Ponto Origem`,
    //       },
    //     ]);
    //   } else if (index === solicitacaoMonitoramentoPlanoViagemVO.length - 1) {
    //     setPontosError([
    //       ...pontosError,
    //       {
    //         index,
    //         message: `Data/Hora menor que Ponto (${index})`,
    //       },
    //     ]);
    //   }
    //   return true;
    // }
  };

  useEffect(() => {
    fetchVeiculos();
    fetchCondutores();
    fetchAjudantes();
    fetchBaus();
    fetchEmbarcadores();
    fetchOperacoes();
    fetchTecnologias();
    fetchRotas();
    fetchPontos();
    fetchParametroOperacional();
    fetchServicosContratados();
    fetchParticularidades();
    fetchPreSm();
  }, []);

  // const acrescentaPonto = (ponto: any) => {};

  const atualizaPonto = (value: any, index: number, name: string) => {
    const novosPontos = model.solicitacaoMonitoramentoPlanoViagemVO.map((each: any, i: number) => {
      return { ...each, [name]: i === index ? value : each[name] };
    });
    setModel({ ...model, solicitacaoMonitoramentoPlanoViagemVO: novosPontos });
  };

  const removePonto = (index: number) => {
    isPontoDateInvalid(index);
    const novosPontos = model.solicitacaoMonitoramentoPlanoViagemVO.filter(
      (each: any, i: number) => {
        return i !== index;
      }
    );
    isPontoDateInvalid(index, { ...model, solicitacaoMonitoramentoPlanoViagemVO: novosPontos });
    setModel({ ...model, solicitacaoMonitoramentoPlanoViagemVO: novosPontos });
  };

  const resetDialog = () => {
    setShowDialog(false);
    setHeaderDialog('');
  };

  return (
    <Tela
      nome="Solicitação de Monitoramento"
      caminho="Operacional > Solicitações > Solicitação de Monitoramento"
      loading={loading}
      setLoading={setLoading}
    >
      {/* <Prompt
        when={mudancasFeitas}
        message="You have unsaved changes, are you sure you want to leave?"
      /> */}
      {/* DIALOG GENÉRICO, SETE HEADERDIALOG PARA COMUNICAR COM USUARIO */}
      <Dialog
        header={headerDialog}
        footer={<Button onClick={resetDialog}>OK</Button>}
        visible={showDialog}
        style={{ width: '50vw' }}
        modal
        onHide={resetDialog}
      />
      {progress < 99 ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <p>{`${progressMessage}`}</p>
          </div>

          <ProgressBar variant="warning" animated now={progress} label={`${progress}%`} />
        </>
      ) : (
        <Form noValidate validated={formInvalid} onSubmit={onSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="required">Veículo</Form.Label>
              <Form.Control
                as="select"
                size="sm"
                type="text"
                name="idVeiculo"
                value={model.idVeiculo}
                onChange={(e: any) => {
                  updateModel(e);
                  findVeiculo(e.target.value, 'veiculo');
                }}
                onBlur={() => {
                  verificaLancamento();
                }}
                disabled={desabilitaForm}
                required
              >
                <option value="">Selecione</option>
                {veiculos &&
                  veiculos.map((each: VeiculoIdPlaca) => (
                    <option key={each.id} value={each.id}>
                      {each.placa}
                    </option>
                  ))}
              </Form.Control>
              <Invalid message={criticas.veiculo} />
            </Form.Group>
            <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
            <Form.Group as={Col}>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control value={veiculo?.modelo} size="sm" placeholder="" disabled />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control value={veiculo?.tecnologia} size="sm" placeholder="" disabled />
            </Form.Group>
          </Form.Row>

          {criticaChecklistVeiculo && renderCriticaChecklist('Veículo')}
          {criticaSatVeiculo && renderCriticaSat('Veículo')}
          {(criticaChecklistVeiculo || criticaSatVeiculo) && renderLogin()}
          {mostrarCamposLogin && doLogin()}

          {permiteCarreta ? (
            <>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">Carreta</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    type="text"
                    name="idCarreta"
                    value={model.idCarreta}
                    onChange={(e: any) => {
                      updateModel(e);
                      findVeiculo(e.target.value, 'carreta1');
                    }}
                    onBlur={() => {
                      verificaLancamento();
                    }}
                    disabled={desabilitaForm}
                    required
                  >
                    <option value="">Selecione</option>
                    {carretas &&
                      carretas
                        .filter((each: CarretaIdPlaca) => each.id !== carreta2.id)
                        .map((each: CarretaIdPlaca) => (
                          <option key={each.id} value={each.id}>
                            {each.placa}
                          </option>
                        ))}
                  </Form.Control>
                  <Invalid message={criticas.carreta1} />
                </Form.Group>
                <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
                <Form.Group as={Col}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control value={carreta1.modelo} size="sm" placeholder="" disabled />
                </Form.Group>
              </Form.Row>
              {criticaChecklistCarreta1 && renderCriticaChecklist('Carreta')}
              {criticaSatCarreta1 && renderCriticaSat('Carreta')}
              {(criticaChecklistCarreta1 || criticaSatCarreta1) && renderLogin()}
            </>
          ) : null}

          {permiteCarreta && model.idCarreta ? (
            <>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">Carreta(2)</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    type="text"
                    name="idCarreta2"
                    value={model.idCarreta2}
                    onChange={(e: any) => {
                      updateModel(e);
                      findVeiculo(e.target.value, 'carreta2');
                    }}
                    onBlur={() => {
                      verificaLancamento();
                    }}
                    disabled={desabilitaForm}
                    required
                  >
                    <option value="">Selecione</option>
                    {carretas &&
                      carretas
                        .filter((each: CarretaIdPlaca) => each.id !== carreta1.id)
                        .map((each: CarretaIdPlaca) => (
                          <option key={each.id} value={each.id}>
                            {each.placa}
                          </option>
                        ))}
                  </Form.Control>
                  <Invalid message={criticas.carreta2} />
                </Form.Group>
                <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
                <Form.Group as={Col}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control value={carreta2.modelo} size="sm" placeholder="" disabled />
                </Form.Group>
              </Form.Row>
              {criticaChecklistCarreta2 && renderCriticaChecklist('Carreta')}
              {criticaSatCarreta2 && renderCriticaSat('Carreta')}
              {(criticaChecklistCarreta2 || criticaSatCarreta2) && renderLogin()}
            </>
          ) : null}

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Baú Intercambiável</Form.Label>
              <Form.Control
                value={model.idBau}
                onChange={(e: any) => {
                  updateModel(e);
                  findBau(e.target.value);
                }}
                onBlur={verificaLancamento}
                size="sm"
                as="select"
                id="idBau"
                name="idBau"
                placeholder=""
                disabled={desabilitaForm}
              >
                <option value="">Selecione</option>
                {baus &&
                  baus.map((each: any) => (
                    <option key={each.id} value={each.id}>
                      {each.numero}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
            <Form.Group as={Col}>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={bau.modelo}
                size="sm"
                id="numeroBau"
                name="numeroBau"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
          <LinhaForm />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="required">Início da viagem</Form.Label>
              <Form.Control
                onChange={updateModel}
                value={model.dataInicio}
                onBlur={verificaLancamento}
                name="dataInicio"
                size="sm"
                disabled={desabilitaForm}
                maxLength={10}
                type="date"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="required">Hora de ínicio da viagem</Form.Label>
              <Form.Control
                onChange={updateModel}
                value={model.dataFim}
                onBlur={verificaLancamento}
                name="dataFim"
                size="sm"
                disabled={desabilitaForm}
                maxLength={10}
                type="date"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="required">Termino da viagem</Form.Label>
              <Form.Control size="sm" disabled={desabilitaForm} maxLength={10} type="date" />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="required">Hora de término da viagem</Form.Label>
              <Form.Control size="sm" disabled={desabilitaForm} maxLength={10} type="date" />
            </Form.Group>
          </Form.Row>
          <LinhaForm />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="required">Regras apólice / ddr / embarcador</Form.Label>
              <Form.Control
                size="sm"
                as="select"
                placeholder="Veículo"
                required
                name="idEmbarcador"
                value={model.idEmbarcador}
                disabled={confirmacaoPreSm || desabilitaForm}
                onChange={(e: any) => {
                  updateModel(e);
                  fetchDadosEmbarcador(e.target.value);
                }}
                onBlur={verificaPontosVeiculosEmbarcador}
              >
                <option value="">Selecione</option>
                {embarcadores &&
                  embarcadores.map((each: any) => (
                    <option key={each.idEmbarcador} value={each.idEmbarcador}>
                      {each.razaoSocial}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo Veículo não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <LinhaForm />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="required">Condutor</Form.Label>
              <Form.Control
                value={model.idCondutor}
                onChange={(e: any) => {
                  updateModel(e);
                  findCondutor(e.target.value, false);
                }}
                onBlur={() => {
                  verificaLancamento();
                }}
                name="idCondutor"
                as="select"
                size="sm"
                type="number"
                disabled={desabilitaForm}
                required
              >
                <option value="">Selecione</option>
                {condutores &&
                  condutores
                    .filter((each: CondutorIdNome) => each.id !== condutor2.id)
                    .map((each: CondutorIdNome) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
              </Form.Control>
              <Invalid message={criticas.condutor1} />
            </Form.Group>
            <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
            {model.idCondutor ? (
              <>
                <Form.Group as={Col}>
                  <Form.Label>Condutor(2)</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e: any) => {
                      updateModel(e);
                      findCondutor(e.target.value, true);
                    }}
                    value={model.idCondutor2}
                    name="idCondutor2"
                    onBlur={() => {
                      verificaLancamento();
                    }}
                    disabled={desabilitaForm}
                    size="sm"
                    type="number"
                  >
                    <option value="">Selecione</option>
                    {condutores &&
                      condutores
                        .filter((each: CondutorIdNome) => each.id !== condutor1.id)
                        .map((each: CondutorIdNome) => (
                          <option key={each.id} value={each.id}>
                            {each.nome}
                          </option>
                        ))}
                  </Form.Control>
                  <Invalid message={criticas.condutor2} />
                </Form.Group>
                <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
              </>
            ) : null}
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Ajudante</Form.Label>
              <Form.Control
                value={model.idAjudante}
                onChange={(e: any) => {
                  updateModel(e);
                  findAjudante(e.target.value, false);
                }}
                onBlur={() => {
                  verificaLancamento();
                }}
                name="idAjudante"
                as="select"
                size="sm"
                type="text"
                disabled={desabilitaForm}
                required
              >
                <option value="">Selecione</option>
                {ajudantes &&
                  ajudantes
                    .filter((each: AjudanteIdNome) => each.id !== model.idAjudante2)
                    .map((each: AjudanteIdNome) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
              </Form.Control>
              <Invalid message={criticas.ajudante1} />
            </Form.Group>
            {model.idAjudante ? (
              <>
                <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
                <Form.Group as={Col}>
                  <Form.Label>Ajudante (2)</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e: any) => {
                      updateModel(e);
                      findAjudante(e.target.value, true);
                    }}
                    value={model.idAjudante2}
                    name="idAjudante2"
                    onBlur={() => {
                      verificaLancamento();
                    }}
                    disabled={desabilitaForm}
                    size="sm"
                    type="text"
                  >
                    <option value="">Selecione</option>
                    {ajudantes &&
                      ajudantes
                        .filter((each: AjudanteIdNome) => each.id !== model.idAjudante)
                        .map((each: AjudanteIdNome) => (
                          <option key={each.id} value={each.id}>
                            {each.nome}
                          </option>
                        ))}
                  </Form.Control>
                  <Invalid message={criticas.ajudante2} />
                </Form.Group>
              </>
            ) : null}
            <i style={styleAddButton} className="pi pi-plus-circle icone_adicionar" />
          </Form.Row>
          <LinhaForm />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="required">Operação</Form.Label>
              <Form.Control
                onChange={fetchTipoOperacao}
                value={model.tipoOperacao}
                name="tipoOperacao"
                type="number"
                disabled={desabilitaForm}
                size="sm"
                as="select"
                placeholder="Veículo"
                required
              >
                <option value="">Selecione</option>
                {operacoes &&
                  operacoes.map((each: any) => (
                    <option key={each.id} value={each.id}>
                      {each.descricao}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo Veículo não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="required">Valor da carga</Form.Label>
              <Form.Control
                value={toCurrency(model.valorCarga)}
                onChange={updateModel}
                onBlur={verificaValorCarga}
                size="sm"
                type="number"
                name="valorCarga"
                maxLength={21}
                disabled={desabilitaForm}
                required
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="required">Descrição da carga</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                maxLength={256}
                disabled={desabilitaForm}
                required
              />
            </Form.Group>
          </Form.Row>

          <LinhaForm />

          <Form.Row>
            <Form.Group as={Col} controlId="flagEscolta">
              <Switch
                value={Number(model.flagEscolta)}
                checked={model.flagEscolta}
                onChange={(e: any) => setModel({ ...model, flagEscolta: !model.flagEscolta })}
                name="flagEscolta"
                type="switch"
                label="Adicionar Escolta?"
                disabled={desabilitaForm}
              />
            </Form.Group>
          </Form.Row>

          {model.flagEscolta && (
            <>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">Veículo escolta</Form.Label>
                  <Form.Control size="sm" type="text" required />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Monitorado pela GS?</Form.Label>
                  <Form.Control
                    onChange={(e: any) =>
                      setModel({ ...model, flagMonitoramentoEscoltaGs: e.target.value === '0' })
                    }
                    as="select"
                    size="sm"
                    type="text"
                    required
                    disabled={desabilitaForm}
                  >
                    <option value="">Selecione</option>
                    <option value="0">Sim</option>
                    <option value="1">Não</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Tecnologia escolta</Form.Label>
                  <Form.Control
                    onChange={(e: any) => {
                      updateModel(e);
                      findTiposComunicacoes(e.target.value);
                    }}
                    as="select"
                    size="sm"
                    type="text"
                    required
                    disabled={desabilitaForm}
                  >
                    <option value="">Selecione</option>
                    {tecnologias.map((each: any) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Comunicação escolta</Form.Label>
                  <Form.Control
                    onChange={updateModel}
                    as="select"
                    name="idTipoComunicacaoEscolta"
                    size="sm"
                    type="text"
                    required
                    disabled={desabilitaForm}
                  >
                    <option value="">Selecione</option>
                    {tiposComunicacoes.map((each: any) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="required">N° equipamento escolta</Form.Label>
                  <Form.Control size="sm" type="text" required disabled={desabilitaForm} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">Empresa escolta</Form.Label>
                  <Form.Control size="sm" type="text" required disabled={desabilitaForm} />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="required">Telefone contato</Form.Label>
                  <Form.Control size="sm" type="text" required disabled={desabilitaForm} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">Agente escolta</Form.Label>
                  <Form.Control
                    onChange={updateModel}
                    name="agenteEscolta"
                    size="sm"
                    type="text"
                    required
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="required">Telefone contato agente</Form.Label>
                  <Form.Control size="sm" type="text" required disabled={desabilitaForm} />
                </Form.Group>
              </Form.Row>
              {model.agenteEscolta && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="required">Agente escolta (2)</Form.Label>
                    <Form.Control size="sm" type="text" required disabled={desabilitaForm} />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="required">Telefone contato agente (2)</Form.Label>
                    <Form.Control size="sm" type="text" required disabled={desabilitaForm} />
                  </Form.Group>
                </Form.Row>
              )}
            </>
          )}
          <LinhaForm />

          <Form.Row>
            <Form.Group as={Col} controlId="flagIsca">
              <Switch
                value={Number(model.flagIsca)}
                checked={model.flagIsca}
                onChange={(e: any) => setModel({ ...model, flagIsca: !model.flagIsca })}
                name="flagIsca"
                type="switch"
                label="Adicionar Isca?"
                disabled={desabilitaForm}
              />
            </Form.Group>
          </Form.Row>

          {model.flagIsca && (
            <>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Marca isca</Form.Label>
                  <Form.Control
                    onChange={updateModel}
                    value={model.idTecnologiaIsca}
                    name="idTecnologiaIsca"
                    as="select"
                    size="sm"
                    type="text"
                    required
                    disabled={desabilitaForm}
                  >
                    <option value="">Selecione</option>
                    {tecnologias.map((each: any) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Comunicação isca</Form.Label>
                  <Form.Control
                    onChange={(e: any) =>
                      setModel({ ...model, flagMonitoramentoEscoltaGs: e.target.value === '0' })
                    }
                    as="select"
                    size="sm"
                    type="text"
                    required
                    disabled={desabilitaForm}
                  >
                    <option value="">Selecione</option>
                    <option value="0">Sim</option>
                    <option value="1">Não</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="required">N° equipamento isca</Form.Label>
                  <Form.Control size="sm" type="text" disabled={desabilitaForm} required />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">Site</Form.Label>
                  <Form.Control size="sm" type="text" disabled={desabilitaForm} required />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="required">Login</Form.Label>
                  <Form.Control size="sm" type="text" disabled={desabilitaForm} required />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="required">Senha</Form.Label>
                  <Form.Control size="sm" type="text" disabled={desabilitaForm} required />
                </Form.Group>
              </Form.Row>
            </>
          )}
          <LinhaForm />

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="required">Tipo de rota</Form.Label>
              <Form.Control
                as="select"
                size="sm"
                required
                onChange={(e: any) => {
                  setTipoRota(parseInt(e.target.value, 10));
                }}
                title="Selecione uma opção"
                disabled={desabilitaForm}
              >
                <option>Selecione</option>
                {!rotaPorEmbarcador && <option value={1}>Pré-cadastradas</option>}
                <option value={2}>Por pontos</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Utilizar rota do embarcador?</Form.Label>
              <Form.Control
                onChange={(e: any) => setRotaPorEmbarcador(e.target.value === '0')}
                as="select"
                size="sm"
                type="text"
                disabled={!model.idEmbarcador || desabilitaForm}
                title={
                  model.idEmbarcador
                    ? 'Selecione uma opção'
                    : 'Para escolher esta opção, selecione um embarcador'
                }
              >
                <option value="">Selecione</option>
                <option value="0">Sim</option>
                <option value="1">Não</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {tipoRota === 1 && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="required">Rota</Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  type="number"
                  required
                  name="idRota"
                  onChange={(e: any) => {
                    updateModel(e);
                    findRota(e.target.value);
                  }}
                  disabled={desabilitaForm}
                >
                  <option value="">Selecione</option>
                  {rotas &&
                    rotas.map((each: any) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Rota origem</Form.Label>
                <Form.Control value={rotaOrigem} disabled size="sm" type="text" required />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Rota destino</Form.Label>
                <Form.Control value={rotaDestino} disabled size="sm" type="text" required />
              </Form.Group>
            </Form.Row>
          )}
          {/* {tipoRota === 2 &&
            model &&
            model.solicitacaoMonitoramentoPlanoViagemVO.map((ponto: PontosSm, index: number) => (
              <Form.Row key={ponto.idPonto}>
                <Form.Group as={Col}>
                  <Form.Label className="required">
                    Ponto
                    {index === 0
                      ? ' Origem'
                      : index > 1 &&
                        index + 1 === model.solicitacaoMonitoramentoPlanoViagemVO.length
                      ? ' Destino'
                      : ` (${index + 1})`}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    type="number"
                    required
                    value={ponto.idPonto ? ponto.idPonto : ''}
                    onChange={(e: any) => {
                      atualizaPonto(parseInt(e.target.value, 10), index, 'idPonto');
                    }}
                    disabled={confirmacaoPreSm || desabilitaForm}
                  >
                    <option value="">Selecione</option>
                    {(rotaPorEmbarcador ? pontosEmbarcador : pontos).map((pt: any) => (
                      <option key={pt.idPonto} value={pt.idPonto}>
                        {pt.nomePonto}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>N° remessa</Form.Label>
                  <Form.Control
                    size="sm"
                    type="number"
                    required
                    value={ponto.remessa ? ponto.remessa : ''}
                    disabled={confirmacaoPreSm || desabilitaForm}
                    onChange={(e: any) => {
                      atualizaPonto(e.target.value, index, 'remessa');
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Data</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    value={ponto.onlyDataPonto ? ponto.onlyDataPonto : ''}
                    required
                    min={
                      index === 0
                        ? new Date().toISOString().split('T')[0]
                        : model.solicitacaoMonitoramentoPlanoViagemVO[index - 1].onlyDataPonto
                    }
                    isInvalid={!!pontosError[index]}
                    disabled={confirmacaoPreSm || desabilitaForm}
                    onChange={(e: any) => {
                      atualizaPonto(e.target.value, index, 'onlyDataPonto');
                      isPontoDateInvalid(
                        index,
                        undefined,
                        ponto.onlyTimePonto || undefined,
                        e.target.value
                      );
                    }}
                  />
                  <Form.Control.Feedback style={{ fontSize: 12 }} type="invalid">
                    {pontosError[index]}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Hora</Form.Label>
                  <Form.Control
                    size="sm"
                    type="time"
                    value={ponto.onlyTimePonto ? ponto.onlyTimePonto : ''}
                    required
                    disabled={confirmacaoPreSm || desabilitaForm}
                    isInvalid={!!pontosError[index]}
                    onChange={(e: any) => {
                      atualizaPonto(e.target.value, index, 'onlyTimePonto');
                      isPontoDateInvalid(
                        index,
                        undefined,
                        e.target.value,
                        ponto.onlyDataPonto || undefined
                      );
                    }}
                  />
                  <Form.Control.Feedback style={{ fontSize: 12 }} type="invalid">
                    {pontosError[index]}
                  </Form.Control.Feedback>
                </Form.Group>
                {index > 1 ? (
                  <AddButton
                    onClick={() => {
                      removePonto(index);
                    }}
                    removerPonto
                  />
                ) : null}
              </Form.Row>
            ))} */}
          {tipoRota === 2 && (
            <Form.Row>
              <AddButton
                onClick={() => {
                  adicionarPontos();
                }}
                title="Adicionar Pontos ?"
                adicionarPonto
              />
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Observações</Form.Label>
              <Form.Control size="sm" type="text" disabled={desabilitaForm} required />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Romaneio</Form.Label>
              <Form.Control
                as="textarea"
                size="sm"
                type="text"
                disabled={desabilitaForm}
                required
              />
            </Form.Group>
          </Form.Row>

          {servicosContratados.cscNuCadastroConsulta !== 1 ? (
            <>
              <LinhaForm />
              <Form.Row className="justify-content-md-center">
                <Form.Group controlId="formGridAlertaChecklist">
                  <Form.Label className="txt_alerta">{model?.obsPesquisa}</Form.Label>
                </Form.Group>
              </Form.Row>
            </>
          ) : null}

          <ButtonsForm />
        </Form>
      )}
    </Tela>
  );
};
