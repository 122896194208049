import React from 'react';

export type MenuContext = {
  show: boolean;
  setShow: (show: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const DEFAULT_VALUE = { show: true, setShow: () => {} };

export const menuContext = React.createContext<MenuContext>(DEFAULT_VALUE);
