import moment from 'moment';

export const mainTableColumn = [
  { label: 'Data', value: [0, 1, 2] },
  { label: 'Placa', value: [0, 1, 2] },
  { label: 'Temperatura', value: [0, 2] },
  { label: 'Temp. (min/max)', value: [0, 2] },
  { label: 'Velocidade', value: [1, 2] },
  { label: 'Velocidade Máx.', value: [1, 2] },
  { label: 'Localização', value: [0, 1, 2] },
  { label: 'Violação', value: [0, 1, 2] },
];

export const opcoesControles = [
  { name: 'Temperatura', id: 0 },
  { name: 'Velocidade', id: 1 },
  { name: 'Ambos', id: 2 },
];

export const tiposControles = (_controle: number) => {
  switch (_controle) {
    case 0:
      return [{ name: 'Temperatura', id: 0 }];
    case 1:
      return [{ name: 'Velocidade', id: 1 }];
    case 2:
      return [
        { name: 'Temperatura', id: 0 },
        { name: 'Velocidade', id: 1 },
        { name: 'Ambos', id: 2 },
      ];
    default:
      return [];
  }
};

export const initialCenter = {
  lat: -14.749,
  lng: -43.4382,
};

export const renderScreenName = (controlesContratados: number) => {
  if (controlesContratados === 0) {
    return 'Controle de Temperatura';
  }
  if (controlesContratados === 1) {
    return 'Controle de Velocidade';
  }
  return 'Prevenção de Acidentes';
};
