import React, { useState, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { getCookieSessionData } from '../../services/cookieService';

type NaoConformidadeType = {
  id?: number;
  causaRaiz: string;
  naturezaEvento: string;
  processoMacro: string;
  ativo?: boolean;
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const NaoConformidadeForm: React.FC<Props> = ({ canEdit, canDelete }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();
  const [loading, setLoading] = useState(false);
  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const [show, setShow] = useState(false);
  const [model, setModel] = useState<NaoConformidadeType>({
    causaRaiz: '',
    naturezaEvento: '',
    processoMacro: '',
    ativo: true,
  });
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { causaRaiz, naturezaEvento, processoMacro } = model;

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`/nao-conformidade/${id}`);
        setModel(response.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log(idCliente);
    fetchData();
  }, [id]);

  const handleDelete = async () => {
    handleClose();
    setLoading(true);
    try {
      await axios.put(`/nao-conformidade/${id}`, { ativo: false });
      setMensagem('Excluído com sucesso!');
      setIsDialogCadOK(true);
    } catch {
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!naturezaEvento) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    if (id) {
      setLoading(true);
      try {
        await axios.put(`/nao-conformidade/${id}`, model);
        setMensagem('Editado com sucesso!');
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        await axios.post('/nao-conformidade', model);
        setMensagem('Cadastrado com sucesso!');
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={mensagem}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/nao-conformidades/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Não conformidade excluída com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={handleDelete}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Não conformidades</h2>
          <h6 className="subtitulo">{`Cadastro > Não conformidades > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="painel">
              <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
              <Form
                className="form-sm"
                noValidate
                validated={isFormInvalid}
                onSubmit={handleSubmit}
              >
                <Form.Group>
                  <Form.Label>
                    Natureza do evento<span className="txt-obrigatorio"> *</span>
                  </Form.Label>
                  <Form.Control
                    value={naturezaEvento}
                    onChange={(e) => {
                      setModel({ ...model, naturezaEvento: e.target.value });
                    }}
                    type="text"
                    required
                    maxLength={64}
                    minLength={1}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Causa Raiz</Form.Label>
                  <Form.Control
                    value={causaRaiz}
                    onChange={(e) => {
                      setModel({ ...model, causaRaiz: e.target.value });
                    }}
                    type="text"
                    required
                    maxLength={64}
                    minLength={1}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Processo Macro</Form.Label>
                  <Form.Control
                    value={processoMacro}
                    onChange={(e) => {
                      setModel({ ...model, processoMacro: e.target.value });
                    }}
                    type="text"
                    required
                    maxLength={32}
                    minLength={1}
                  />
                </Form.Group>
                <ButtonsForm canEdit={id ? canEdit : true} />
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(NaoConformidadeForm);
