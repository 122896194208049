/* eslint-disable */
import jwtDecode from 'jwt-decode';
import history from '../services/history';
import { AuthResponse } from '../api/model/AuthResponse';
import { confirmLoginUser, postLoginUser } from '../api/authAPI';
import Cookies from 'universal-cookie';
import { getCookiePermissoesData } from './permissionamento';

export type Role_Realm_Access =
  | 'offline_access'
  | 'app-admin'
  | 'uma_authorization'
  | 'app-user'
  | 'app-corretora'
  | 'app-seguradora';
export type Role = 'admin' | 'user' | 'corretora' | 'seguradora';
export type Roles_Account = 'manage-account' | 'manage-account-links' | 'view-profile';

type AccessToken = {
  exp: number;
  sub: string;
  realm_access: {
    roles: Role_Realm_Access[];
  };
  resource_access: {
    goldenservice: {
      roles: Role[];
    };
  };
  account: {
    roles: Roles_Account[];
  };
  name: string;
};

const cookies = new Cookies();

export const loginUser = async (
  login: string,
  senha: string,
  role: string,
  codigo: number | null
) => {
  const response = await postLoginUser(login, senha, role, codigo);
  return response;
};

export const confirmUser = async (
  login: string,
  senha: string,
  role: string,
  codigo: number | null
) => {
  const response = await confirmLoginUser(login, senha, role, codigo);
  return response;
};

export const saveCookieSessionData = (authresponse: AuthResponse) => {
  cookies.set(
    'userSession',
    {
      access_token: authresponse.access_token,
      expires_in: authresponse.expires_in,
      refresh_expires_in: authresponse.refresh_expires_in,
      refresh_token: authresponse.refresh_token,
      scope: authresponse.scope,
      session_state: authresponse.session_state,
      token_type: authresponse.token_type,
      usuarioVO: {
        ativo: authresponse.usuarioVO.ativo,
        clienteId: authresponse.usuarioVO.clienteId,
        cnpj: authresponse.usuarioVO.cnpj,
        clienteRazaoSocial: authresponse.usuarioVO.clienteRazaoSocial,
        isEmbarcador: authresponse.usuarioVO.isEmbarcador,
        corretoraId: authresponse.usuarioVO.corretoraId,
        corretoraNome: authresponse.usuarioVO.corretoraNome,
        dataHoraCadastro: authresponse.usuarioVO.dataHoraCadastro,
        email: authresponse.usuarioVO.email,
        login: authresponse.usuarioVO.login,
        nome: authresponse.usuarioVO.nome,
        perfil: authresponse.usuarioVO.perfil,
        role: authresponse.usuarioVO.role,
        seguradoraId: authresponse.usuarioVO.seguradoraId,
        seguradoraNome: authresponse.usuarioVO.seguradoraNome,
        senha: authresponse.usuarioVO.senha,
        usuarioId: authresponse.usuarioVO.usuarioId,
        usuarioIdKeycloak: authresponse.usuarioVO.usuarioIdKeycloak,
      },
    },
    { path: '/' }
  );
  console.log('console log do teste cookie: ', cookies.get('userSession'));
};

export const getCookieSessionData = (): AuthResponse => {
  const sessionCookieData = cookies.get('userSession') ?? '{}';
  // const parsedSessionData: AuthResponse = JSON.parse(sessionCookieData);
  //  return parsedSessionData;
  return sessionCookieData;
};

export const getAccessTokenDecodedByCookie = (): AccessToken => {
  const sessionCookieData = getCookieSessionData();
  //console.log('session cookie data: ', sessionCookieData);
  const tokenDecoded: any = jwtDecode(sessionCookieData.access_token);
  const formatedTokenDecoded = {
    exp: tokenDecoded.exp,
    sub: tokenDecoded.sub,
    realm_access: tokenDecoded.roles,
    resource_access: {
      goldenservice: tokenDecoded.resource_access['goldenservice-api'],
    },
    account: tokenDecoded.account,
    name: tokenDecoded.name,
  };
  //console.log('Console log do token do cookie decoded: ', formatedTokenDecoded);
  return formatedTokenDecoded;
};

export const isTokenValid = () => {
  const { exp } = getAccessTokenDecodedByCookie();
  if (Date.now() <= exp * 1000) {
    //console.log('deu true no isTokenValid');
    return true;
  }
  //console.log('deu false no isTokenValid');
  return false;
};

export const isAuthenticated = () => {
  const sessionCookieData = getCookieSessionData();
  //console.log('console isauth', sessionCookieData.access_token && isTokenValid());
  return sessionCookieData.access_token && isTokenValid();
};

export const isAllowedByRole = (routeRoles: Role[] = []) => {
  const userToken = getAccessTokenDecodedByCookie();
  const userRoles = userToken.resource_access.goldenservice.roles;
  //   console.log('entrei no is allowed');
  //   console.log('console log 01 allowedByRole: ', userRoles);
  //   console.log(
  //     'console log 02 allowedByRole: ',
  //     routeRoles.some((role) => userRoles.includes(role))
  //   );
  return routeRoles.some((role) => userRoles.includes(role));
};

export const isAllowedByPermission = (permission: number[], type: string) => {
  const permissions = getCookiePermissoesData();
  // console.log('permissions ==>', permissions);
  if (permissions?.null?.idMenuGrupo === null) {
    return true;
  }
  return permission.some((p: number) => {
    if (permissions[p] && permissions[p][type] === true) {
      return true;
    } else {
      return false;
    }
  });
};

export const logout = () => {
  // history.push('/logout');
  // localStorage.removeItem('authData');
  // cookies.remove('authUser');

  if (
    getCookieSessionData().usuarioVO.role == 'corretora' ||
    getCookieSessionData().usuarioVO.role == 'seguradora'
  ) {
    cookies.remove('userSession');
    history.replace('/parceiros');
  } else {
    cookies.remove('userSession');
    history.replace('/');
  }
};

export const doLogin = () => {
  history.push('/carregando-permissoes');
};

export const other = () => {};
