/* eslint-disable */
import { ListagemHistoricoPesquisa } from '../pages/Pesquisa/HistoricoPesquisas/index';
import { obterData } from './date';

export const validaVigencia = (each: ListagemHistoricoPesquisa) => {
  var arrayDatas: any[] = [];
  arrayDatas = each.itens.map((e: any) => {
    if (e.validade != 'No Embarque') {
      return obterData(e.validade);
    }
  });
  if (arrayDatas.length > 0) {
    var menorData = arrayDatas?.reduce((a: any, b: any) => (a > b ? a : b));
    return menorData;
  } else {
    return obterData(arrayDatas[0]);
  }
};
