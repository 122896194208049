/* eslint-disable */
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { Col, Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Pagination from '../../components/Pagination/Pagination';
import axios from '../../config/axiosMaquina';

//@ts-ignore
import ReactExport from 'react-data-export';

import './styles.css';
import { FormataStringData2 } from '../../util/Validacoes/Validacoes';
import { obterData, obterDataHora } from '../../util/date';

type DataType = {
  idSm: number;
  acumulado: number;
  dataRequisicao: string;
  embarcador: string;
  transportador: string;
};

const RequisicaoAPI = () => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const { page }: any = useParams();
  const [data, setData] = useState<DataType[]>([]);

  const [currentPage, setCurrentPage] = useState('1');
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = Number(currentPage) * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const currentReq = data.slice(indexOfFirstPost, indexOfLastPost);
  const [choosenStartDate, setChoosenStartDate] = useState<string | undefined>();
  const [choosenEndDate, setChoosenEndDate] = useState<string | undefined>();

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/tecnologia-rastreamento/relatorio-requisicao-api`, {
        params: {
          dataInicio: `${choosenStartDate} 00:00:00`,
          dataFim: `${choosenEndDate} 23:59:59`,
        },
      });
      setData(response.data);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    fetchData();
  };

  const paginate = (pageNumber: any) => {
    currentReq && setCurrentPage(pageNumber);
  };

  const borders = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Consulta Requisições à API',
          widthPx: 800,
          style: {
            font: { sz: '24', bold: true },
            alignment: { horizontal: 'center' },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title:
            choosenStartDate && choosenEndDate
              ? `Período: ${obterData(choosenStartDate)} à ${obterData(choosenEndDate)}`
              : 'Período: ',
          widthPx: 800,
          style: {
            font: { sz: '16', bold: true },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title: 'Viagem',
          width: { wch: 40 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              vertical: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Transportador',
          width: { wch: 20 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Cliente Embarcador',
          width: { wch: 20 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Acumulado',
          width: { wch: 20 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'top',
              wrapText: false,
            },
            border: borders,
          },
        },
        {
          title: 'Última Requisição',
          width: { wch: 20 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
      ],
      data: data?.map((each) => [
        { value: `${each.idSm}` },
        {
          value: each.transportador,
        },
        { value: each.embarcador },
        { value: `${each.acumulado}` },
        {
          value: `${FormataStringData2(each.dataRequisicao.split('T')[0])} ${each.dataRequisicao
            .split('T')[1]
            .slice(0, -13)}`,
        },
      ]),
    },
  ];

  const dateMax = () => {
    const dateMax = new Date('1987-01-01 GMT-0300');
    return (
      dateMax.getFullYear().toString() +
      '-' +
      (dateMax.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      dateMax.getDate().toString().padStart(2, '0')
    );
  };

  const dateEnd = () => {
    if (choosenStartDate != undefined) {
      const dateEnd = new Date(choosenStartDate);
      return (
        dateEnd.getFullYear().toString() +
        '-' +
        (dateEnd.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        (dateEnd.getDate() + 1).toString().padStart(2, '0')
      );
    }
  };

  const dateNow = () => {
    const now = new Date();
    const dateNow = new Date(now.getTime());
    return (
      dateNow.getFullYear().toString() +
      '-' +
      (dateNow.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      dateNow.getDate().toString().padStart(2, '0')
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Requisição API</h2>
          <h6 className="subtitulo">{'Consultas/Relatórios > Rastreamento > Requisição API'}</h6>
        </div>

        <div className="content-container">
          <div className="painel">
            <div className="header-associar-container">
              <div className="header-associar-field">
                <Form onSubmit={(e: any) => onSubmit(e)}>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label className="requiredField">Data Início</Form.Label>
                      <Form.Control
                        onChange={(event: any) => setChoosenStartDate(event.target.value)}
                        name="startDate"
                        type="date"
                        min={dateMax()}
                        max={dateNow()}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label className="requiredField">Data Término</Form.Label>
                      <Form.Control
                        onChange={(event: any) => setChoosenEndDate(event.target.value)}
                        name="endDate"
                        type="date"
                        disabled={!choosenStartDate}
                        min={dateEnd()}
                        // max={dateNow()}
                      />
                    </Form.Group>

                    <div
                      className="header-associar-field button-field"
                      style={{ paddingTop: '6.4%' }}
                    >
                      <Form.Group as={Col} md={2}>
                        <Button className="btn-enviar" style={{ height: '87.5%' }} type="submit">
                          Filtrar
                        </Button>
                      </Form.Group>
                    </div>
                  </Form.Row>
                </Form>
              </div>
            </div>
            <div className="table-responsive" style={{ flex: 1 }}>
              {loading ? (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              ) : (
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th>Nº S.M.</th>
                      <th>Transportador</th>
                      <th>Cliente Embarcador</th>
                      <th>Acumulado</th>
                      <th>Última Requisição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentReq &&
                      currentReq.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.idSm}</td>
                            <td>{data.transportador}</td>
                            <td>{data.embarcador}</td>
                            <td>{data.acumulado}</td>
                            <td>{obterDataHora(data.dataRequisicao)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {multiDataSet.length && (
            <>
              <div id="gerar-xls">
                <ExcelFile
                  element={<Button disabled={!currentReq.length}>Exportar para XLS</Button>}
                  filename="RequisicaoAPI"
                >
                  <ExcelSheet dataSet={multiDataSet} name="Requisicao">
                    <ExcelColumn label="Viagem" value="idSm" />
                    <ExcelColumn label="Transportador" value="transportador" />
                    <ExcelColumn label="Cliente Embarcador" value="embarcador" />
                    <ExcelColumn label="Acumulado" value="acumulado" />
                    <ExcelColumn label="Última Requisição" value="dataRequisicao" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
              {currentReq && (
                <Pagination
                  totalPosts={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  itensPerPage={itensPerPage}
                  setItensPerPage={setItensPerPage}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequisicaoAPI;
