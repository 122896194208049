/* eslint-disable */
import React from 'react';
import MenuLateral from '../../components/MenuLateral/MenuLateral';

const CadCondutor = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <iframe
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        src="http://170.80.70.50:8090/homologacao_gs/grid_editavel_public_faixa_ranking_condutor/?glo_cli_cd_id=23" height="1080" width="100%" title="Testando"></iframe>
    </div >
  );
}

export default CadCondutor;

//  src="http://170.80.70.50:8090/scriptcase/app/Golden_Service_V2/grid_editavel_public_faixa_ranking_condutor/?glo_cli_cd_id=2"
