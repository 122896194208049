/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { SMEmAndamentoType } from '../../api/model/Veiculos/SMEmAndamentoType';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import SolicitacaoMonitoramento from '../../components/SolicitacaoMonitoramento';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { UsuarioType } from '../../types';
import axios from '../../config/axiosMaquina';
import { BauDTO, DispositivoDTO } from '../../Types/AssistenciaTecnica';
import { PreCancelamentoType } from '../../pages/IniciarSMPendente/index';

const VisualizarSMCancelada = () => {
  const [usuario, setUsuario] = useState<UsuarioType | undefined>();

  useEffect(() => {
    const usuarioData = getCookieSessionData().usuarioVO;
    setUsuario(usuarioData);
  }, []);

  const history = useHistory();
  const { id }: any = useParams();
  const [smData, setSmData] = useState<SMEmAndamentoType | undefined>();
  const [dadosCancelamento, setDadosCancelamento] = useState<PreCancelamentoType>({
    idUsuario: 0,
    motivoCancelamento: 0,
    idSm: 0,
    dataCancelamento: '',
    nomeUsuario: '',
    solicitanteCancelamento: '',
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/solicitacao-monitoramento/listardadossmandamento?solicitacaoMonitoramento=${id}`
        );
        setSmData(response.data);
        console.log('SM', response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.get(`/solicitacao-monitoramento/getDadosCancelamento/${id}`);
        setDadosCancelamento(response.data);
        console.log('Cancelamento', response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData2();
  }, [id]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação de Monitoramento</h2>
          <h6 className="subtitulo">{'Cadastro > SM > Visualização'}</h6>
        </div>

        <div className="content-container"></div>
        <div className="painel">
          {smData && dadosCancelamento.motivoCancelamento != 0 ? (
            <SolicitacaoMonitoramento
              smData={smData}
              isAdmin={isAllowedByRole(['admin'])}
              usuarioLogado={usuario}
              loading={loading}
              dadosCancelamento={dadosCancelamento}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VisualizarSMCancelada;
