import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { TecnologiaRastreamento } from '../../../api/model/Veiculos/TecnologiaRastreamentoType';
import { Tela } from '../../../components/Tela';
import { Tabela } from '../../../components/Tabela';
import { Spinner } from '../../../components/Spinner';
import { pesquisar } from '../../../util/format';

type Props = {
  canInsert?: boolean;
};

const ListagemTecnologiaRastreamento: React.FC<Props> = ({ canInsert }) => {
  const history = useHistory();
  const { page }: any = useParams();
  const [fullData, setFullData] = useState<TecnologiaRastreamento[]>([]);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchValue.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = ['+id', 'Nome'];
  const paginate = (pageNumber: number) => {
    history.push(`/listar/tecnologias-de-rastreamento/${pageNumber}`);
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Tecnologias de rastreamento',
          widthPx: 1200,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      columns: [],
      data: [],
    },
    {
      columns: [
        { title: 'Nome', width: { wch: 30 } },
        { title: 'Descrição', width: { wch: 25 } },
        { title: 'Status', width: { wch: 15 } },
      ],
      data: fullData?.map((each) => [
        { value: each.terTxNome ? each.terTxNome : '' },
        { value: each.terTxDescricao ? each.terTxDescricao : '' },
        { value: each.terBlAtivo ? 'ATIVO' : 'INATIVO' },
      ]),
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/tecnologia-rastreamento/grid`);
      setFullData(response.data);
      const array = response.data.map((each: TecnologiaRastreamento) => {
        return [each.terCdId, each.terTxNome];
      });
      setDataTabela(array);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar/tecnologias-de-rastreamento/1');
      setDataSearch(pesquisar(dataTabela, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Tecnologia de rastreamento"
      caminho="Cadastro > Rastreadores > Tecnologia"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchValue}
      search={search}
      linkToAdd="/admin/cadastrar-tecnologia-de-rastreamento"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchValue.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={page}
      excelGeneration={multiDataSet}
      createDisabled={!canInsert}
      filename="Tecnologia de rastreamento"
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) =>
            history.push(`/admin/editar-tecnologia-de-rastreamento/${id}`)
          }
        />
      )}
    </Tela>
  );
};

export default ListagemTecnologiaRastreamento;
