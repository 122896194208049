/* eslint-disable */
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import ModalCancelamentoMonitoramento from './ModalCancelamentoMonitoramento';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { PendentesObj } from '../../types';
import { Spinner } from 'react-bootstrap';
import { Tela } from '../../components/Tela';
import { obterDataHora } from '../../util/date';

type Props = {
  canDelete?: boolean;
};

const CancelamentoMonitoramento = ({ canDelete }: Props) => {
  const [loading, setLoading] = useState(false);
  const [totalPosts, setTotalPosts] = useState(0);
  const [searchData, setSearchData] = useState('');
  const [searchResults, setSearchResults] = useState<PendentesObj[] | undefined>();
  const [data, setData] = useState<PendentesObj[] | undefined>();
  const [selectedItem, setSelectedItem] = useState<PendentesObj | undefined>();

  const [show, setShow] = useState(false);
  const [motivo, setMotivo] = useState('');

  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();

  const history = useHistory();
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const clienteNome = getCookieSessionData().usuarioVO.nome;

  const paginate = (pageNumber: number) => {
    history.push(`/cancelamento-monitoramento/${pageNumber}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await instanceMaquina.get('/solicitacao-monitoramento/bytype', {
          params: {
            idUsuario: usuarioId,
            type: 'PENDENTE',
            pagina: page - 1,
            qtdRegistros: itensPerPage,
          },
        });
        setData(response.data);
        setSearchResults(response.data);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [usuarioId, page, itensPerPage]);

  useEffect(() => {
    const fetchQtdPosts = async () => {
      const response = await instanceMaquina.get(
        `/solicitacao-monitoramento/contarsolicitacoesporcliente/${clienteId}`
      );
      setTotalPosts(response.data.countPendente);
    };
    fetchQtdPosts();
  }, []);

  useEffect(() => {
    const results = data?.filter(
      (data) =>
        data.id.toString().includes(searchData) ||
        data.placa.toLowerCase().includes(searchData.toLowerCase()) ||
        data.condutor.toLowerCase().includes(searchData.toLowerCase()) ||
        data.embarcador.toLowerCase().includes(searchData.toLowerCase())
    );

    setSearchResults(results);
  }, [searchData]);

  const handleSearch = (event: any) => {
    event.preventDefault();
    setSearchData(event.target.value);
  };

  const handleCancelar = () => {
    if (!selectedItem) {
      return;
    }

    try {
      instanceMaquina.put(
        `/solicitacao-monitoramento/cancelarSM-cliente?solicitacaoMonitoramento=${selectedItem.id}&idUsuCancelamento=${usuarioId}&motivoCancelamento=${motivo}&solicitante=${clienteNome}`
      );

      setTimeout(function () {
        history.push('/');
        history.push('/cancelamento-monitoramento/1');
      }, 1500);
      setShow(false);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Tela
      nome="Cancelamento de Monitoramento"
      caminho="Operacional > Solicitações > Cancelamento de Monitoramento"
    >
      <ModalCancelamentoMonitoramento
        show={show}
        setShow={setShow}
        onPress={handleCancelar}
        motivo={motivo}
        setMotivo={setMotivo}
        selectedItem={selectedItem}
      />

      <div className="search-create mb-4">
        <span className="p-input-icon-left">
          <input
            type="text"
            placeholder="Pesquise"
            className="p-inputtext p-component"
            id="search-input"
            style={{ width: '240px' }}
            value={searchData}
            onChange={handleSearch}
          />
          <i className="pi pi-search"></i>
        </span>
      </div>
      <div className="table-responsive" style={{ flex: 1 }}>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Nº SM</th>
                <th>Placa do Veículo</th>
                <th>Embarcador</th>
                <th>Condutor</th>
                <th>Data</th>
                <th style={{ width: 80 }}></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                searchResults?.map((each, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{each.id}</td>
                        <td>{each.placa}</td>
                        <td>{each.embarcador}</td>
                        <td>{each.condutor}</td>
                        <td>{obterDataHora(each.dataInicio, true)}</td>
                        <td>
                          <Button
                            tooltip="Cancelar SM"
                            tooltipOptions={{ position: 'bottom' }}
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                            onClick={() => {
                              if (canDelete) {
                                setShow(true);
                                setSelectedItem(each);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          >
                            <IoCloseSharp size={22} color="#9F9F9F" />
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
      <Pagination
        itensPerPage={itensPerPage}
        setItensPerPage={setItensPerPage}
        totalPosts={totalPosts}
        paginate={paginate}
        currentPage={page}
      />
    </Tela>
  );
};

export default CancelamentoMonitoramento;
