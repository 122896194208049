/* eslint-disable */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, Col, Row, Button, Navbar, Tabs, Tab, Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import './styles.css';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Paradas from '../Paradas/index';
import { maskCep, validaNumero } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData } from '../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import {
  ContainerButtons,
  PrimaryButton,
  SecundaryButton,
} from '../../components/ButtonsForm/styles';

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const EditarRotas = ({ canEdit, canDelete }: Props) => {
  const history = useHistory();
  const { addToast } = useToast();
  const [nome, setNome] = useState('');
  const [descricaoOrigem, setDescricaoOrigem] = useState('');
  const [cepOrigem, setCepOrigem] = useState('');
  const [logradouroOrigem, setLogradouroOrigem] = useState('');
  const [numeroOrigem, setNumeroOrigem] = useState('');
  const [complementoOrigem, setComplementoOrigem] = useState('');
  const [bairroOrigem, setBairroOrigem] = useState('');
  const [cidadeOrigem, setCidadeOrigem] = useState('');
  const [idUFOrigem, setidUFOrigem] = useState('');
  const [descricaoDestino, setDescricaoDestino] = useState('');
  const [cepDestino, setCepDestino] = useState('');
  const [logradouroDestino, setLogradouroDestino] = useState('');
  const [numeroDestino, setNumeroDestino] = useState('');
  const [complementoDestino, setComplementoDestino] = useState('');
  const [bairroDestino, setBairroDestino] = useState('');
  const [idUFDestino, setidUFDestino] = useState('');
  const [nomeUFOrigem, setNomeUFOrigem] = useState('');
  const [nomeUFDestino, setNomeUFDestino] = useState('');
  const [cidadeDestino, setCidadeDestino] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [execRotaInternacional, setExecRotaInternacional] = useState(false);
  const [paisOrigem, setPaisOrigem] = useState(' ');
  const [paisDestino, setPaisDestino] = useState(' ');
  const [ufInternacionalOrigem, setUfInternacionalOrigem] = useState(' ');
  const [cidadeInternacionalOrigem, setCidadeInternacionalOrigem] = useState(' ');
  const [ufInternacionalDestino, setUfInternacionalDestino] = useState(' ');
  const [cidadeInternacionalDestino, setCidadeInternacionalDestino] = useState(' ');
  const [viaCepUfOrigem, setViaCepUfOrigem] = useState('');
  const [viaCepUfDestino, setViaCepUfDestino] = useState('');
  const [viaCepCidadeOrigem, setViaCepCidadeOrigem] = useState('');
  const [viaCepIbgeCodOrigem, setViaCepIbgeCodOrigem] = useState(0);
  const [viaCepCidadeDestino, setViaCepCidadeDestino] = useState('');
  const [idMun, setViaCepIdMun] = useState('');
  const [viaCepIbgeCodDestino, setViaCepIbgeCodDestino] = useState(0);
  const [idRota, setIdRota] = useState<number>();
  const [errorOrigem, setErrorOrigem] = useState(false);
  const [errorDestino, setErrorDestino] = useState(false);
  const [isFormInvalid1, setIsFormInvalid1] = useState(false);
  const [isFormInvalid2, setIsFormInvalid2] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const { id }: any = useParams();
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipiosOrigem, setMunicipiosOrigem] = useState<MunicipiosType[]>([]);
  const [municipiosDestino, setMunicipiosDestino] = useState<MunicipiosType[]>([]);
  const [show, setShow] = useState(false);

  const [flagDadosEnderecoObrigatorio, setFlagDadosEnderecoObrigatorio] = useState(false);

  const getParticularidades = async () => {
    try {
      console.log('try useeffect ' + clienteId);
      const response = await axios.get(`/cliente/getParticularidadesCliente?cliente=${clienteId}`);
      setFlagDadosEnderecoObrigatorio(response.data.flagDadosEnderecoObrigatorio);
      console.log(response.data);
    } catch (err: any) {
      console.log('erro useeffect');
      console.log(err);
    }
  };

  useEffect(() => {
    getParticularidades();
  }, [clienteId]);

  const fetchData = async () => {
    if (id) {
      const response = await axios.get(`rota/${id}`);
      console.log(response.data);
      setNome(response.data.nome);
      setDescricaoOrigem(response.data.descricaoOrigem);
      setCepOrigem(response.data.cepOrigem);
      setLogradouroOrigem(response.data.logradouroOrigem);
      setNumeroOrigem(response.data.numeroOrigem);
      setComplementoOrigem(response.data.complementoOrigem);
      setBairroOrigem(response.data.bairroOrigem);
      setCidadeOrigem(response.data.cidadeOrigem);
      setidUFOrigem(response.data.idUFOrigem);
      setDescricaoDestino(response.data.descricaoDestino);
      setCepDestino(response.data.cepDestino);
      setLogradouroDestino(response.data.logradouroDestino);
      setNumeroDestino(response.data.numeroDestino);
      setComplementoDestino(response.data.complementoDestino);
      setBairroDestino(response.data.bairroDestino);
      setidUFDestino(response.data.idUFDestino);
      setCidadeDestino(response.data.cidadeDestino);
      setAtivo(response.data.ativo);
      setExecRotaInternacional(response.data.execRotaInternacional);
      setPaisOrigem(response.data.paisOrigem);
      setPaisDestino(response.data.paisDestino);
      setUfInternacionalOrigem(response.data.ufInternacionalOrigem);
      setCidadeInternacionalOrigem(response.data.cidadeInternacionalOrigem);
      setUfInternacionalDestino(response.data.ufInternacionalDestino);
      setCidadeInternacionalDestino(response.data.cidadeInternacionalDestino);
      setNomeUFOrigem(response.data.nomeUFOrigem);
      setNomeUFDestino(response.data.nomeUFDestino);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const loadEstadosPorPais = async () => {
    try {
      const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/1`);
      setUfs(response.data);
      console.log(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    loadEstadosPorPais();
  }, []);

  const loadMunicipiosPorEstadoOrigem = async (estado: any) => {
    console.log(estado);
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosOrigem(response.data);
      console.log(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadMunicipiosPorEstadoDestino = async (estado: any) => {
    console.log(estado);
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosDestino(response.data);
      console.log(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    if (idUFOrigem) {
      loadMunicipiosPorEstadoOrigem(idUFOrigem);
    }
    if (idUFDestino) {
      loadMunicipiosPorEstadoDestino(idUFDestino);
    }
  }, [idUFDestino, idUFOrigem]);

  const buscarCepOrigem = (_cep: string) => {
    if (_cep.length < 9) {
      setCidadeOrigem('');
      setLogradouroOrigem('');
      setBairroOrigem('');
      setidUFOrigem('');
      // setViaCepUfOrigem('');
      return;
    }
    setErrorOrigem(false);
    fetch(`https://viacep.com.br/ws/${_cep}/json`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          if (_cep.split('-')[1] === '000') {
            setErrorOrigem(false);
            return;
          }
          setErrorOrigem(true);
          return;
        }
        console.log(data);
        setLogradouroOrigem(data.logradouro);
        setBairroOrigem(data.bairro);
        // setViaCepCidadeOrigem(data.ibge);
        //   setViaCepUfOrigem(data.uf);
        const ufId = ufs
          .filter((uf) => uf.sigla === data.uf)
          .map((filteredUfId) => filteredUfId.id);
        setidUFOrigem(ufId.toString());
        const codIbgeFloat = data.ibge / 100000;
        const codIbgeFormatado = Math.trunc(codIbgeFloat);
        // setViaCepIbgeCodOrigem(codIbgeFormatado);
        setCidadeOrigem(data.localidade);
      })
      .catch((erro) => {
        console.log('error', erro);
        setErrorOrigem(true);
      });
  };

  const buscarCepDestino = (_cep: string) => {
    if (_cep.length < 9) {
      setCidadeDestino('');
      setLogradouroDestino('');
      setBairroDestino('');
      setidUFDestino('');
      return;
    }
    setErrorDestino(false);
    fetch(`https://viacep.com.br/ws/${_cep}/json`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          if (_cep.split('-')[1] === '000') {
            setErrorDestino(false);
            return;
          }
          setErrorDestino(true);
          return;
        }
        setLogradouroDestino(data.logradouro);
        setBairroDestino(data.bairro);
        // setViaCepCidadeDestino(data.ibge);
        // setViaCepUfDestino(data.uf);
        const ufId = ufs
          .filter((uf) => uf.sigla === data.uf)
          .map((filteredUfId) => filteredUfId.id);
        setidUFDestino(ufId.toString());
        const codIbgeFloat = data.ibge / 100000;
        const codIbgeFormatado = Math.trunc(codIbgeFloat);
        // setViaCepIbgeCodDestino(codIbgeFormatado);
        setCidadeDestino(data.localidade);
      })
      .catch((erro) => {
        console.log('error', erro);
        setErrorDestino(true);
      });
  };

  const handleRegisterNacional = async (event: any) => {
    event.preventDefault();
    if (flagDadosEnderecoObrigatorio === false) {
      if (
        !nome ||
        !cidadeOrigem ||
        !idUFOrigem ||
        !idUFDestino ||
        !cidadeDestino ||
        numeroOrigem === null ||
        numeroDestino === null
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid2(true);
        event.stopPropagation();
        return;
      }
    } else {
      if (
        !nome ||
        !descricaoOrigem ||
        !cepOrigem ||
        !logradouroOrigem ||
        numeroOrigem === null ||
        !bairroOrigem ||
        !cidadeOrigem ||
        !idUFOrigem ||
        !descricaoDestino ||
        !cepDestino ||
        !logradouroDestino ||
        numeroDestino === null ||
        !bairroDestino ||
        !idUFDestino ||
        !cidadeDestino
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid2(true);
        event.stopPropagation();
        return;
      }
    }

    const data = {
      nome: nome,
      descricaoOrigem: descricaoOrigem,
      cepOrigem: cepOrigem,
      logradouroOrigem: logradouroOrigem,
      numeroOrigem: numeroOrigem,
      complementoOrigem: complementoOrigem,
      bairroOrigem: bairroOrigem,
      cidadeOrigem: cidadeOrigem,
      idUFOrigem: parseInt(idUFOrigem, 10),
      descricaoDestino: descricaoDestino,
      cepDestino: cepDestino,
      logradouroDestino: logradouroDestino,
      numeroDestino: numeroDestino,
      complementoDestino: complementoDestino,
      bairroDestino: bairroDestino,
      idUFDestino: parseInt(idUFDestino, 10),
      cidadeDestino: cidadeDestino,
      cliente: clienteId,
      ativo: ativo,
      paisOrigem: paisOrigem,
      paisDestino: paisDestino,
      execRotaInternacional: execRotaInternacional,
      ufInternacionalOrigem: ufInternacionalOrigem,
      cidadeInternacionalOrigem: cidadeInternacionalOrigem,
      ufInternacionalDestino: ufInternacionalDestino,
      cidadeInternacionalDestino: cidadeInternacionalDestino,
    };
    console.log(data);
    try {
      const response = await axios.post('/rota', data);
      console.log(data);
      setIdRota(response.data);
      setIsDialogVisibleCadEfetuado(true);
      setMensagem('Rota cadastrada com sucesso!');
    } catch {
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });
      return;
    }
  };

  const handleEditNacional = async (event: any) => {
    event.preventDefault();

    if (flagDadosEnderecoObrigatorio === false) {
      if (
        !nome ||
        !cidadeOrigem ||
        !idUFOrigem ||
        !idUFDestino ||
        !cidadeDestino ||
        numeroOrigem === null ||
        numeroDestino === null
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid2(true);
        event.stopPropagation();
        return;
      }
    } else {
      if (
        !nome ||
        !descricaoOrigem ||
        !cepOrigem ||
        !logradouroOrigem ||
        numeroOrigem === null ||
        !bairroOrigem ||
        !cidadeOrigem ||
        !idUFOrigem ||
        !descricaoDestino ||
        !cepDestino ||
        !logradouroDestino ||
        numeroDestino === null ||
        !bairroDestino ||
        !idUFDestino ||
        !cidadeDestino
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid2(true);
        event.stopPropagation();
        return;
      }
    }
    const data = {
      nome: nome,
      descricaoOrigem: descricaoOrigem,
      cepOrigem: cepOrigem,
      logradouroOrigem: logradouroOrigem,
      numeroOrigem: numeroOrigem,
      complementoOrigem: complementoOrigem,
      bairroOrigem: bairroOrigem,
      cidadeOrigem: cidadeOrigem,
      idUFOrigem: idUFOrigem,
      descricaoDestino: descricaoDestino,
      cepDestino: cepDestino,
      logradouroDestino: logradouroDestino,
      numeroDestino: numeroDestino,
      complementoDestino: complementoDestino,
      bairroDestino: bairroDestino,
      idUFDestino: idUFDestino,
      cidadeDestino: cidadeDestino,
      cliente: clienteId,
      ativo: ativo,
      paisOrigem: paisOrigem,
      paisDestino: paisDestino,
      execRotaInternacional: execRotaInternacional,
      ufInternacionalOrigem: ufInternacionalOrigem,
      cidadeInternacionalOrigem: cidadeInternacionalOrigem,
      ufInternacionalDestino: ufInternacionalDestino,
      cidadeInternacionalDestino: cidadeInternacionalDestino,
    };
    console.log(data);
    try {
      await axios.put(`rota/${id}`, data);
      console.log(data);
      setMensagem('Rota foi alterada com sucesso!');
      setIsDialogVisibleCadEfetuado(true);
    } catch {
      addToast({
        title: 'Erro!',
        description: 'Erro ao editar.',
        type: 'error',
      });
      return;
      console.log(data);
    }
  };

  const handleEditInternacional = async (event: any) => {
    event.preventDefault();
    if (
      !paisOrigem ||
      !paisDestino ||
      !ufInternacionalOrigem ||
      !cidadeInternacionalOrigem ||
      !ufInternacionalDestino ||
      !cidadeInternacionalDestino ||
      !nome ||
      !descricaoDestino ||
      !descricaoOrigem ||
      numeroOrigem === null ||
      numeroDestino === null
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid1(true);
      event.stopPropagation();
      return;
    }
    const data = {
      nome: nome,
      descricaoOrigem: descricaoOrigem,
      cepOrigem: ' ',
      logradouroOrigem: ' ',
      numeroOrigem: 0,
      complementoOrigem: ' ',
      bairroOrigem: '',
      cidadeOrigem: ' ',
      idUFOrigem: idUFOrigem == '' ? null : idUFOrigem,
      descricaoDestino: descricaoDestino,
      cepDestino: ' ',
      logradouroDestino: ' ',
      numeroDestino: 0,
      complementoDestino: ' ',
      bairroDestino: ' ',
      idUFDestino: idUFDestino == '' ? null : idUFDestino,
      cidadeDestino: ' ',
      cliente: clienteId,
      ativo: ativo,
      paisOrigem: paisOrigem,
      paisDestino: paisDestino,
      execRotaInternacional: execRotaInternacional,
      ufInternacionalOrigem: ufInternacionalOrigem,
      cidadeInternacionalOrigem: cidadeInternacionalOrigem,
      ufInternacionalDestino: ufInternacionalDestino,
      cidadeInternacionalDestino: cidadeInternacionalDestino,
    };
    console.log(data);
    try {
      await axios.put(`rota/${id}`, data);
      console.log(data);
      setIsDialogVisibleCadEfetuado(true);
      setMensagem('Rota foi alterada com sucesso!');
    } catch {
      addToast({
        title: 'Erro!',
        description: 'Erro ao editar.',
        type: 'error',
      });
      return;
      console.log(data);
    }
  };

  const handleDelete = async (event: any) => {
    // event.preventDefault();
    const dataAtivo = {
      nome: nome,
      descricaoOrigem: descricaoOrigem,
      cepOrigem: cepOrigem,
      logradouroOrigem: logradouroOrigem,
      numeroOrigem: numeroOrigem,
      complementoOrigem: complementoOrigem,
      bairroOrigem: bairroOrigem,
      cidadeOrigem: cidadeOrigem,
      idUFOrigem: idUFOrigem,
      descricaoDestino: descricaoDestino,
      cepDestino: cepDestino,
      logradouroDestino: logradouroDestino,
      numeroDestino: numeroDestino,
      complementoDestino: complementoDestino,
      bairroDestino: bairroDestino,
      idUFDestino: idUFDestino,
      cidadeDestino: cidadeDestino,
      cliente: clienteId,
      ativo: false,
      paisOrigem: 'BR',
      paisDestino: 'BR',
      execRotaInternacional: false,
      ufInternacionalOrigem: 'null',
      cidadeInternacionalOrigem: 'null',
      ufInternacionalDestino: 'null',
      cidadeInternacionalDestino: 'null',
    };
    try {
      await axios.put(`rota/${id}`, dataAtivo);
      addToast({
        title: 'Sucesso!',
        description: 'Exclusão efetuada com sucesso.',
        type: 'success',
      });
      setTimeout(function () {
        history.push('/rota/1');
      }, 1500);
    } catch {
      addToast({
        title: 'Erro!',
        description: 'Erro ao excluir.',
        type: 'error',
      });
      return;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header={mensagem}
        footer={
          <>
            <Button onClick={() => history.push('/rota/1')}>OK</Button>
          </>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleCadEfetuado(false)}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${nome}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setShow(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(id)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card" style={{ backgroundColor: '#f2f2f2', borderColor: '#f2f2f2' }}>
        <Container fluid style={{ marginTop: '0px' }}>
          <div className="titulo-container">
            <h2 className="titulo" style={{ marginTop: '10px', width: '100%' }}>
              Rotas
            </h2>
            <h6 className="subtitulo" style={{ width: '80%' }}>
              {'Cadastro > Rotas'}
            </h6>
            {id && (
              <button
                className="btn-desativar"
                style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                onClick={() => {
                  if (canDelete) {
                    setShow(true);
                  } else {
                    history.push('/acessonegado');
                  }
                }}
              >
                <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
              </button>
            )}
          </div>

          <Tabs defaultActiveKey="editarrota" id="uncontrolled-tab-example" className="mt-4">
            <Tab eventKey="editarrota" title="Dados da Rota">
              {execRotaInternacional ? (
                <Form noValidate validated={isFormInvalid1} onSubmit={handleEditInternacional}>
                  <Form.Group>
                    <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                      <Form.Label className="required">Nome da Rota:</Form.Label>

                      <Form.Control
                        value={nome}
                        onChange={(event) => {
                          setNome(event.target.value);
                        }}
                        id="nome"
                        name="nome"
                        placeholder=""
                        required
                        maxLength={64}
                      />
                    </Form.Group>
                    <Form.Text>Dados da Origem</Form.Text>

                    <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                      <Form.Label className="required">Descrição:</Form.Label>

                      <Form.Control
                        value={descricaoOrigem}
                        onChange={(event) => {
                          setDescricaoOrigem(event.target.value);
                        }}
                        id="descricaoOrigem"
                        name="descricaoOrigem"
                        placeholder=""
                        required
                        maxLength={64}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                      <Form.Label className="required">País:</Form.Label>

                      <Form.Control
                        value={paisOrigem}
                        onChange={(event) => {
                          setPaisOrigem(event.target.value);
                        }}
                        id="paisOrigem"
                        name="paisOrigem"
                        placeholder=""
                        required
                      />
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridAddress1" className="mt-2 ml-3">
                        <Form.Label className="required">UF:</Form.Label>

                        <Form.Control
                          value={ufInternacionalOrigem}
                          onChange={(event: any) => {
                            setUfInternacionalOrigem(event.target.value);
                          }}
                          id="ufInternacionalOrigem"
                          name="ufInternacionalOrigem"
                          required
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridAddress1" className="mt-2">
                        <Form.Label className="required">Cidade:</Form.Label>

                        <Form.Control
                          value={cidadeInternacionalOrigem}
                          onChange={(event) => {
                            setCidadeInternacionalOrigem(event.target.value);
                          }}
                          id="cidadeInternacionalOrigem"
                          name="cidadeInternacionalOrigem"
                          placeholder=""
                          required
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Text>Dados do Destino</Form.Text>

                    <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                      <Form.Label className="required">Descrição:</Form.Label>

                      <Form.Control
                        value={descricaoDestino}
                        onChange={(event) => {
                          setDescricaoDestino(event.target.value);
                        }}
                        id="descricaoDestino"
                        name="descricaoDestino"
                        placeholder=""
                        required
                        maxLength={64}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                      <Form.Label className="required">País:</Form.Label>

                      <Form.Control
                        value={paisDestino}
                        onChange={(event) => {
                          setPaisDestino(event.target.value);
                        }}
                        id="paisDestino"
                        name="paisDestino"
                        placeholder=""
                        required
                      />
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridAddress1" className="mt-2 ml-3">
                        <Form.Label className="required">UF:</Form.Label>

                        <Form.Control
                          value={ufInternacionalDestino}
                          onChange={(event: any) => {
                            setUfInternacionalDestino(event.target.value);
                          }}
                          id="ufInternacionalDestino"
                          name="ufInternacionalDestino"
                          required
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridAddress1" className="mt-2">
                        <Form.Label className="required">Cidade:</Form.Label>

                        <Form.Control
                          value={cidadeInternacionalDestino}
                          onChange={(event) => {
                            setCidadeInternacionalDestino(event.target.value);
                          }}
                          id="cidadeInternacionalDestino"
                          name="cidadeInternacionalDestino"
                          placeholder=""
                          required
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                  <Form.Row className="container-buttons justify-content-end">
                    <Form.Group as={Col} md={2}>
                      <Button
                        className="btn-cancelar"
                        onClick={() => {
                          history.replace('/rota/1');
                        }}
                      >
                        Cancelar
                      </Button>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      {canEdit ? (
                        <Button className="btn-enviar" type="submit">
                          Confirmar
                        </Button>
                      ) : (
                        <Button
                          className="btn-enviar"
                          type="button"
                          onClick={() => history.push('/acessonegado')}
                        >
                          Confirmar
                        </Button>
                      )}
                    </Form.Group>
                  </Form.Row>
                </Form>
              ) : (
                <Form
                  noValidate
                  validated={isFormInvalid2}
                  onSubmit={(e: any) => {
                    if (id) {
                      handleEditNacional(e);
                    } else {
                      handleRegisterNacional(e);
                    }
                  }}
                >
                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className="required">Nome da Rota:</Form.Label>

                    <Form.Control
                      value={nome}
                      onChange={(event) => {
                        setNome(event.target.value);
                      }}
                      id="nome"
                      name="nome"
                      placeholder=""
                      required
                      maxLength={64}
                    />
                  </Form.Group>
                  <Form.Group as={Col} style={{ marginTop: 1, marginBottom: 1 }}>
                    <hr />
                  </Form.Group>
                  <Form.Text>Dados da Origem</Form.Text>
                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Descrição:
                    </Form.Label>

                    <Form.Control
                      value={descricaoOrigem}
                      onChange={(event) => {
                        setDescricaoOrigem(event.target.value);
                      }}
                      id="descricaoOrigem"
                      name="descricaoOrigem"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      CEP:
                    </Form.Label>

                    <Form.Control
                      value={cepOrigem}
                      onChange={(event) => {
                        buscarCepOrigem(event.target.value);
                        setCepOrigem(maskCep(event.target.value));
                      }}
                      id="cepOrigem"
                      name="cepOrigem"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={9}
                      minLength={9}
                    />
                    {errorOrigem && (
                      <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Logradouro:
                    </Form.Label>

                    <Form.Control
                      value={logradouroOrigem}
                      onChange={(event) => {
                        setLogradouroOrigem(event.target.value);
                      }}
                      id="logradouroOrigem"
                      name="logradouroOrigem"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className=" ml-3">
                      <Form.Label className={'required'}>Numero:</Form.Label>

                      <Form.Control
                        value={numeroOrigem}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (validaNumero(e.target.value) || e.target.value.length === 0) {
                            setNumeroOrigem(e.target.value);
                          } else {
                            return;
                          }
                        }}
                        id="numeroOrigem"
                        name="numeroOrigem"
                        placeholder=""
                        required
                        maxLength={8}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label>Complemento:</Form.Label>

                      <Form.Control
                        value={complementoOrigem}
                        onChange={(event) => {
                          setComplementoOrigem(event.target.value);
                        }}
                        id="complementoOrigem"
                        name="complementoOrigem"
                        placeholder=""
                        style={{ width: '97%' }}
                        maxLength={64}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Bairro:
                    </Form.Label>

                    <Form.Control
                      value={bairroOrigem}
                      onChange={(event) => {
                        setBairroOrigem(event.target.value);
                      }}
                      id="bairroOrigem"
                      name="bairroOrigem"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      style={{ width: '50%' }}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className=" ml-3">
                      <Form.Label className="required">UF:</Form.Label>

                      <Form.Control
                        value={idUFOrigem}
                        onChange={(event: any) => {
                          setidUFOrigem(event.target.value);
                          loadMunicipiosPorEstadoOrigem(parseInt(event.target.value));
                        }}
                        id="idUFOrigem"
                        name="idUFOrigem"
                        required
                        as="select"
                        defaultValue=""
                      >
                        {ufs.map((uf) => (
                          <option value={uf.id} key={uf.id}>
                            {uf.sigla}
                          </option>
                        ))}
                        {/* <option value={idUFOrigem} key={idUFOrigem}>
                          {viaCepUfOrigem}
                        </option> */}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className="required">Município:</Form.Label>

                      <Form.Control
                        value={cidadeOrigem}
                        onChange={(event) => {
                          setCidadeOrigem(event.target.value);
                        }}
                        placeholder=""
                        required
                        type="text"
                        as="select"
                        disabled={!idUFOrigem}
                        defaultValue=""
                        style={{ width: '97%' }}
                      >
                        {municipiosOrigem.map((municipio, index) => (
                          <option key={index} value={municipio.nomeMunicipio}>
                            {municipio.nomeMunicipio}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Group as={Col} style={{ marginTop: 1, marginBottom: 1 }}>
                    <hr />
                  </Form.Group>
                  <Form.Text>Dados do Destino</Form.Text>

                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Descrição:
                    </Form.Label>

                    <Form.Control
                      value={descricaoDestino}
                      onChange={(event) => {
                        setDescricaoDestino(event.target.value);
                      }}
                      id="descricaoDestino"
                      name="descricaoDestino"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      CEP:
                    </Form.Label>

                    <Form.Control
                      value={cepDestino}
                      onChange={(event) => {
                        buscarCepDestino(event.target.value);
                        setCepDestino(maskCep(event.target.value));
                      }}
                      id="cepDestino"
                      name="cepDestino"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={9}
                      minLength={9}
                    />
                    {errorDestino && (
                      <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Logradouro:
                    </Form.Label>

                    <Form.Control
                      value={logradouroDestino}
                      onChange={(event) => {
                        setLogradouroDestino(event.target.value);
                      }}
                      id="logradouroDestino"
                      name="logradouroDestino"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className=" ml-3">
                      <Form.Label className={'required'}>Numero:</Form.Label>

                      <Form.Control
                        value={numeroDestino}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (validaNumero(e.target.value) || e.target.value.length === 0) {
                            setNumeroDestino(e.target.value);
                          } else {
                            return;
                          }
                        }}
                        id="numeroDestino"
                        name="numeroDestino"
                        placeholder=""
                        required
                        maxLength={8}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label>Complemento:</Form.Label>

                      <Form.Control
                        value={complementoDestino}
                        onChange={(event) => {
                          setComplementoDestino(event.target.value);
                        }}
                        id="complementoDestino"
                        name="complementoDestino"
                        placeholder=""
                        style={{ width: '97%' }}
                        maxLength={64}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Bairro:
                    </Form.Label>

                    <Form.Control
                      value={bairroDestino}
                      onChange={(event) => {
                        setBairroDestino(event.target.value);
                      }}
                      id="bairroDestino"
                      name="bairroDestino"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      style={{ width: '50%' }}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className=" ml-3">
                      <Form.Label className="required">UF:</Form.Label>

                      <Form.Control
                        value={idUFDestino}
                        onChange={(event: any) => {
                          setidUFDestino(event.target.value);
                          loadMunicipiosPorEstadoDestino(parseInt(event.target.value));
                        }}
                        id="idUFDestino"
                        name="idUFDestino"
                        required
                        as="select"
                        defaultValue=""
                      >
                        {ufs.map((uf) => (
                          <option value={uf.id} key={uf.id}>
                            {uf.sigla}
                          </option>
                        ))}
                        <option value={idUFDestino} key={idUFDestino}>
                          {viaCepUfDestino}
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className="required">Município:</Form.Label>

                      <Form.Control
                        value={cidadeDestino}
                        onChange={(event) => {
                          setCidadeDestino(event.target.value);
                        }}
                        placeholder=""
                        disabled={!idUFDestino}
                        required
                        as="select"
                        defaultValue=""
                        style={{ width: '97%' }}
                      >
                        {municipiosDestino.map((municipio, index) => (
                          <option key={index} value={municipio.nomeMunicipio}>
                            {municipio.nomeMunicipio}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <ContainerButtons>
                    <SecundaryButton
                      type="button"
                      onClick={() => {
                        history.replace('/rota/1');
                      }}
                    >
                      Cancelar
                    </SecundaryButton>
                    <PrimaryButton type="submit">Confirmar</PrimaryButton>
                  </ContainerButtons>
                </Form>
              )}
            </Tab>
            <Tab
              eventKey="parada"
              title="Paradas"
              // ref={`/rotaeditarparada/${id}`}
              disabled={!id}
              onEnter={() => history.push(`/rotaeditarparada/${id}`)}
            >
              {/* <Paradas /> */}
            </Tab>
          </Tabs>
        </Container>
      </div>
    </div>
  );
};

export default EditarRotas;
