/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Form, Row, Button, FormControl, Modal, Col } from 'react-bootstrap';
import { getCookieSessionData } from '../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import api from '../../config/axiosMaquina';
import { ComentariosType } from './modalTratativas';

type ModalProps = {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  idlsc: number;
  idmotivoatraso: number;
  motivoId: number;
  setComentarios: (comentarios: any[]) => void;
  loadTratativa: () => void;
  comentarios: any[];
};

const ModalComentario: FC<ModalProps> = ({
  showModal,
  setShowModal,
  idlsc,
  idmotivoatraso,
  motivoId,
  setComentarios,
  loadTratativa,
  comentarios,
}) => {
  const dataNow = new Date();
  const dataAtual = new Date();
  const [descricao, setDescricao] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [mensagemErro, setMensagemErro] = useState('');
  const [comentario, setComentario] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadOK2, setIsDialogCadOK2] = useState(false);
  const [showComentario, setShowComentario] = useState(false);
  const { usuarioId, nome } = getCookieSessionData().usuarioVO;

  const putComentario = async () => {
    if (comentario.trim() != '') {
      try {
        await api.put(
          `/solicitacao-monitoramento/lsc-tratamento-comentarios-atualizar?idLsc=${idlsc}&idMotivo=${motivoId}`,
          [
            {
              ativo: true,
              dataCadastro: dataNow.toISOString(),
              comentario: comentario,
              idUsuario: usuarioId,
              idLsc: idlsc,
              idUsuarioUltimaAlteracao: usuarioId,
              dataCadastroUltimaAlteracao: dataNow.toISOString(),
            },
          ]
        );
        setMensagem('Comentário adicionado com sucesso!');
        setIsDialogCadOK(true);
        loadTratativa();
      } catch (error) {}
    } else {
      setMensagemErro('Verifique os campos obrigatório!');
      setIsDialogCadOK2(true);
    }
  };

  // const postComentario = () => {
  //   let arrayComentarios: any[] = comentarios;
  //   arrayComentarios.push({
  //     ativo: true,
  //     dataCadastro: dataNow.toISOString(),
  //     comentario: comentario,
  //     nomeUsuario: nome,
  //     idUsuario: usuarioId,
  //     idLsc: idlsc,
  //     idUsuarioUltimaAlteracao: usuarioId,
  //     dataCadastroUltimaAlteracao: dataNow.toISOString(),
  //   });
  //   setComentarios(arrayComentarios);
  //   setShowModal(false);
  // };

  useEffect(() => {
    if (showModal) {
      setComentario('');
    }
  }, [showModal]);

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Adicionar Comentário</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Dialog
          header={mensagem}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK(false);
                setShowModal(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK(false);
            setShowModal(false);
          }}
        />
        <Dialog
          header={mensagemErro}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK2(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK2}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK2(false);
          }}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'row' }}> */}
        <Form>
          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Data</Form.Label>
              <Form.Control
                value={dataNow.toLocaleString()}
                type="text"
                placeholder=""
                maxLength={128}
                required
                disabled
                title="Campo Obrigatório"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Usuário</Form.Label>
              <Form.Control
                value={nome}
                type="text"
                placeholder=""
                maxLength={128}
                required
                disabled
                title="Campo Obrigatório"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Row}>
              <Form.Label column sm="3" className="requiredField">
                Comentário
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  as="textarea"
                  style={{ resize: 'none', height: '10rem', width: '69rem', marginLeft: '4x' }}
                  rows={3}
                  value={comentario}
                  onChange={(e: any) => {
                    setComentario(e.target.value);
                  }}
                  id="emailAvisoServicoInoperante"
                  name="emailAvisoServicoInoperante"
                />
              </Col>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Form.Row className="container-buttons justify-content-end">
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-cancelar"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-enviar"
            onClick={() => {
              putComentario();
            }}
          >
            Salvar Comentário
          </Button>
        </Form.Group>
      </Form.Row>
    </Modal>
  );
};
export default ModalComentario;
