/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable */
import axios from 'axios';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import Input from '../../../components/Input';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import Select from '../../../components/Select';
import { CONSULTAR_PICKER_DATA, TIPO_BUSCA } from '../../../constants/HistoricoPesquisa';
import { cpfMask, soNumeros, validarPlaca } from '../../../util/Validacoes/Validacoes';
import api from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import pesquisaFieldsValidation from './validateFields';
import { useToast } from '../../../hooks/Toast';
import ListagemHistoricoPesquisas from '../ListagemHistoricoPesquisas';
import { toZoned } from '../../../util/date';

export type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  idCliente?: number;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    validadePesquisaEntidade: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  validadeAnaliseCondutorAutonomo: number;
  validadeAnaliseNaoMotoristaAgregado: number;
  validadeAnaliseNaoMotoristaAutonomo: number;
  validadeAnaliseNaoMotoristaFuncionario: number;
  validadeAnaliseVeiculoTerceiro: number;
};

export type Pesquisa = {
  condutorCPF: string;
  dataFim: string;
  dataIni: string;
  idCliente: number;
  nomeCondutor: string;
  numeroLiberacao: number;
  placaVeiculo: string;
  tipo: string;
  tipoBusca: string;
  ajudanteCPF?: string;
  nomeAjudante?: string;
};

type InputErrors = {
  selectedTipo: string;
  dataInicio: string;
  dataFim: string;
  consultar: string;
  cpf: string;
  nome: string;
  condutorId: number;
  placa: string;
  numLiberacao: string;
  tipoBusca: string;
};

export const HistoricoPesquisas: FC = () => {
  const [model, setModel] = useState<Pesquisa>({} as Pesquisa);
  const [tipoPesquisa, setTipoPesquisa] = useState<string>('0');
  const [listVeiculos, setListVeiculos] = useState<{ id: number; placa: string }[]>([]);
  const [listCarretas, setListCarretas] = useState<{ id: number; placa: string }[]>([]);
  const [listCondutores, setListCondutores] = useState<{ id: number; nome: string }[]>([]);
  const [listAjudantes, setListAjudantes] = useState<{ id: number; nome: string }[]>([]);
  const [inputErrors, setInputErrors] = useState<InputErrors>({} as InputErrors);
  const [condutorId, setCondutorId] = useState();
  const [ajudanteId, setAjudanteId] = useState();
  const [veiculoId, setVeiculoId] = useState(0);
  const { addToast } = useToast();
  const [dataResults, setDataResults] = useState<ListagemHistoricoPesquisa[]>();
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAjuCond, setLoadingAjuCond] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ListagemHistoricoPesquisa[]>();
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();
  const { clienteId } = getCookieSessionData().usuarioVO;

  function updateModel(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!model) {
      return;
    }
    const { value } = e.target;

    if (['dataInicio', 'dataFim'].includes(e.target.name) && e.target.value.length > 10) return;
    if (e.target.name === 'placa' && !validarPlaca(e.target.value)) return;

    setModel({
      ...model,
      [e.target.name]: value,
    });
  }
  console.log(currentPage);
  useEffect(() => {
    if (searchTerm && searchTerm.trim() != '' && dataResults != undefined) {
      setCurrentPage(1);
      const results = dataResults.filter(
        (each: ListagemHistoricoPesquisa) =>
          each.itens[0]?.itemPesquisado?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[1]?.itemPesquisado?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[2]?.itemPesquisado?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[3]?.itemPesquisado?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.empresa?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.operacao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[0]?.descricao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[1]?.descricao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[2]?.descricao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[3]?.descricao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.numLiberacao?.toString().includes(searchTerm) ||
          each.dataLancamento?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.usuario?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[0]?.validade?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[1]?.validade?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[2]?.validade?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.itens[3]?.validade?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.resultado?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
      console.log(results);
    } else {
      setSearchResults(dataResults);
    }
  }, [searchTerm]);

  const buscar = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setInputErrors({ ...inputErrors, pesquisa: '' } as InputErrors);
    const selectedTipo = TIPO_BUSCA.find((each) => each.name.toString() === tipoPesquisa)?.name;

    const isValid = pesquisaFieldsValidation({
      pesquisa: model,
      setInputErrors,
      selectedTipo: !selectedTipo ? '' : selectedTipo,
    });

    if (isValid) {
      const dataTodos = {
        idCliente: clienteId,
        tipoBusca: model.tipoBusca,
      };

      const dataVeiculo = {
        tipoBusca: model.tipoBusca,
        placaVeiculo: model.placaVeiculo,
        idCliente: clienteId,
      };
      const dataVeiculoPeriodo = {
        tipoBusca: model.tipoBusca,
        placaVeiculo: model.placaVeiculo,
        idCliente: clienteId,
        dataIni: toZoned(model.dataIni),
        dataFim: toZoned(model.dataFim, false),
      };
      const dataPeriodo = {
        tipoBusca: model.tipoBusca,
        tipo: model.tipo,
        dataIni: toZoned(model.dataIni),
        dataFim: toZoned(model.dataFim, false),
        idCliente: clienteId,
      };
      const dataCondutor = {
        idCliente: clienteId,
        tipoBusca: model.tipoBusca,
        nomeCondutor: model.nomeCondutor,
        condutorCPF: model.condutorCPF,
      };
      const dataCondutorPeriodo = {
        idCliente: clienteId,
        tipoBusca: model.tipoBusca,
        nomeCondutor: model.nomeCondutor,
        condutorCPF: model.condutorCPF,
        dataIni: toZoned(model.dataIni),
        dataFim: toZoned(model.dataFim, false),
      };
      const dataAjudante = {
        idCliente: clienteId,
        tipoBusca: model.tipoBusca,
        nomeAjudante: model.nomeAjudante,
        cpfAjudante: model.ajudanteCPF,
      };
      const dataAjudantePeriodo = {
        idCliente: clienteId,
        tipoBusca: model.tipoBusca,
        nomeAjudante: model.nomeAjudante,
        cpfAjudante: model.ajudanteCPF,
        dataIni: toZoned(model.dataIni),
        dataFim: toZoned(model.dataFim, false),
      };
      const dataNumLib = {
        tipoBusca: model.tipoBusca,
        numeroLiberacao: model.numeroLiberacao,
        idCliente: clienteId,
      };

      if (tipoPesquisa === 'SEM_PARAMETROS') {
        console.log(dataTodos);
        try {
          const response = await api.post('/pesquisa/historico', dataTodos);
          setLoading(false);
          console.log(response);
          setLoading(false);
          setCadastroRealizado(true);
          setDataResults(response.data);
        } catch (error: any) {
          setLoading(false);
          console.log(error);
          addToast({
            title: 'Erro',
            description: 'Erro na comunicação com o servidor.',
            type: 'error',
          });
          event.stopPropagation();
        }
      }

      if (tipoPesquisa === 'COD_LIBERACAO') {
        console.log(dataNumLib);
        try {
          const response = await api.post('/pesquisa/historico', dataNumLib);
          setLoading(false);
          console.log(response);
          if (response.status === 200) {
            if (response.data.length === 0) {
              setLoading(false);
              addToast({
                title: 'Erro',
                description: 'Não existe pesquisa para esse Código Liberação.',
                type: 'error',
              });
              event.stopPropagation();
            } else {
              setLoading(false);
              setCadastroRealizado(true);
              setDataResults(response.data);
            }
          }
        } catch (error: any) {
          console.log(error);
          addToast({
            title: 'Erro',
            description: 'Erro na comunicação com o servidor.',
            type: 'error',
          });
          event.stopPropagation();
        }
      }

      if (tipoPesquisa === 'PERIODO') {
        setLoading(true);
        console.log(dataPeriodo);
        try {
          const response = await api.post('/pesquisa/historico', dataPeriodo);
          console.log(response);
          if (response.status === 200) {
            if (response.data.length === 0) {
              setLoading(false);
              addToast({
                title: 'Erro',
                description: 'Não existe pesquisa para esse período.',
                type: 'error',
              });
              event.stopPropagation();
            } else {
              setCadastroRealizado(true);
              setLoading(false);
              setDataResults(response.data);
            }
          }
        } catch (error: any) {
          console.log(error);
          addToast({
            title: 'Erro',
            description: 'Erro na comunicação com o servidor.',
            type: 'error',
          });
          event.stopPropagation();
        } finally {
          setLoading(false);
        }
      }

      if (tipoPesquisa === 'VEICULO' && !model.dataFim && !model.dataIni) {
        console.log(dataVeiculo);
        try {
          const response = await api.post('/pesquisa/historico', dataVeiculo);
          setLoading(false);
          console.log(response);
          if (response.status === 200) {
            setCadastroRealizado(true);
            setDataResults(response.data);
          }
        } catch (err: any) {
          setLoading(false);
          console.log(err.response);
          if (err.response.data.message) {
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para esse Veiculo.',
              type: 'error',
            });
            event.stopPropagation();
          }
        }
      }

      if (tipoPesquisa === 'VEICULO' && model.dataFim && model.dataIni) {
        console.log(dataVeiculoPeriodo);
        try {
          const response = await api.post('/pesquisa/historico', dataVeiculoPeriodo);
          setLoading(false);
          console.log(response);
          if (response.status === 200) {
            setCadastroRealizado(true);
            setDataResults(response.data);
          }
        } catch (err: any) {
          setLoading(false);
          console.log(err.response);
          if (err.response.data.message) {
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para esse Veiculo.',
              type: 'error',
            });
            event.stopPropagation();
          }
        }
      }

      if (tipoPesquisa === 'CONDUTOR' && !model.dataIni && !model.dataFim) {
        console.log(dataCondutor);
        try {
          const response = await api.post('/pesquisa/historico', dataCondutor);
          setLoading(false);
          console.log(response);
          if (response.status === 200) {
            setCadastroRealizado(true);
            setDataResults(response.data);
          }
        } catch (err: any) {
          setLoading(false);
          console.log(err.response);
          if (err.response.data?.message.includes('CONDUTOR INEXISTENTE PARA O CPF')) {
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para esse Condutor.',
              type: 'error',
            });
            event.stopPropagation();
          }
        }
      }
      if (tipoPesquisa === 'CONDUTOR' && model.dataIni && model.dataFim) {
        console.log(dataCondutor);
        try {
          console.log('dataCondutorPeriodo')
          console.log(dataCondutorPeriodo)
          const response = await api.post('/pesquisa/historico', dataCondutorPeriodo);
          setLoading(false);
          console.log(response);
          if (response.status === 200) {
            setCadastroRealizado(true);
            setDataResults(response.data);
          }
        } catch (err: any) {
          setLoading(false);
          console.log(err.response);
          if (err.response.data.message.includes('CONDUTOR INEXISTENTE PARA O CPF')) {
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para esse Condutor.',
              type: 'error',
            });
            event.stopPropagation();
          }
        }
      }
      if (tipoPesquisa === 'AJUDANTE' && !model.dataIni && !model.dataFim) {
        console.log(dataAjudante);
        try {
          const response = await api.post('/pesquisa/historico', dataAjudante);
          console.log(response);
          if (response.status === 200) {
            setCadastroRealizado(true);
            setDataResults(response.data);
            setLoading(false);
          }
        } catch (err: any) {
          setLoading(false);
          console.log(err.response);
          if (err.response.data.message.includes('AJUDANTE INEXISTENTE PARA O CPF')) {
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para esse Ajudante.',
              type: 'error',
            });
            event.stopPropagation();
          }
        }
      }
      if (tipoPesquisa === 'AJUDANTE' && model.dataIni && model.dataFim) {
        console.log(dataAjudantePeriodo);
        try {
          const response = await api.post('/pesquisa/historico', dataAjudantePeriodo);
          console.log(response);
          if (response.status === 200) {
            setCadastroRealizado(true);
            setDataResults(response.data);
            setLoading(false);
          }
        } catch (err: any) {
          setLoading(false);
          console.log(err.response);
          if (err.response.data.message.includes('AJUDANTE INEXISTENTE PARA O CPF')) {
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para esse Ajudante.',
              type: 'error',
            });
            event.stopPropagation();
          }
        }
      }
    } else {
      setLoading(false);
      addToast({
        title: 'Erro',
        description: 'Erro ao pesquisar.',
        type: 'error',
      });
      event.stopPropagation();
    }
  };

  useEffect(() => {
    axios
      .all([
        api.get('/veiculo/listarIdEPlaca', {
          params: {
            cliente: clienteId,
            grupoMacroVeículo: 1,
          },
        }),
        api.get('/condutor/listarCondutorNome', {
          params: {
            cliente: clienteId,
          },
        }),
        api.get('/ajudante-funcionario/listarAjudanteNome', {
          params: {
            cliente: clienteId,
          },
        }),
        api.get('/veiculo/listarIdEPlaca', {
          params: {
            cliente: clienteId,
            grupoMacroVeículo: 2,
          },
        }),
      ])
      .then(
        axios.spread((veiculosResponse, ufSiglasResponse, ajudanteResponse, carretasResponse) => {
          setListVeiculos(veiculosResponse.data);
          setListCondutores(ufSiglasResponse.data);
          setListAjudantes(ajudanteResponse.data);
          setListCarretas(carretasResponse.data);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, [clienteId]);

  const loadDadosCondutor = async (idCondutor: number) => {
    if (!idCondutor) {
      setModel({
        ...model,
        condutorCPF: '',
        nomeCondutor: '',
      });
      return;
    }
    try {
      setLoadingAjuCond(true);
      const response = await api.get(`/condutor/${idCondutor}`);
      console.log(response.data);
      setModel({
        ...model,
        condutorCPF: response.data.cpf,
        nomeCondutor: response.data.nome,
      });
      console.log(response.data);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoadingAjuCond(false);
    }
  };

  const loadDadosAjudante = async (idAjudante: number) => {
    if (!idAjudante) {
      setModel({
        ...model,
        condutorCPF: '',
        nomeCondutor: '',
      });
      return;
    }
    try {
      setLoadingAjuCond(true);
      const response = await api.get(`/ajudante-funcionario/${idAjudante}`);
      setModel({
        ...model,
        ajudanteCPF: soNumeros(response.data.cpf),
        nomeAjudante: response.data.nome,
      });
      console.log(response.data);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoadingAjuCond(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Histórico de pesquisas lançadas</h2>
          <h6 className="subtitulo">{'Análise de Perfil > Histórico de Pesquisas'}</h6>
        </div>

        {cadastroRealizado && dataResults && !loading ? (
          <>
            <div className="search-create mb-4" style={{ width: '100%' }}>
              <span className="p-input-icon-left">
                <input
                  type="text"
                  placeholder="Pesquise"
                  className="p-inputtext p-component"
                  id="search-input"
                  style={{ width: '240px' }}
                  value={searchTerm}
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                />
                <i className="pi pi-search"></i>
              </span>
            </div>
            <ListagemHistoricoPesquisas
              dataInicio={model.dataIni}
              dataFim={model.dataFim}
              dataResults={searchResults ? searchResults : dataResults}
              setDataResults={setSearchResults}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="painel">
              <Form noValidate onSubmit={buscar}>
                <Form.Row>
                  <Select
                    required
                    value={model.tipoBusca ? model.tipoBusca : tipoPesquisa}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      if (e.target.value === 'SEM_PARAMETROS') {
                        setModel({
                          ...model,
                          tipoBusca: e.target.value,
                          idCliente: clienteId,
                        });
                      } else {
                        setModel({
                          ...model,
                          condutorCPF: '',
                          nomeCondutor: '',
                          tipoBusca: e.target.value,
                        } as Pesquisa);
                      }
                      setInputErrors({} as InputErrors);
                      setTipoPesquisa(e.target.value);
                    }}
                    id="tipoBusca"
                    name="tipoBusca"
                    error={inputErrors.tipoBusca}
                    textInputTitle="Selecione o tipo de consulta:"
                    style={
                      TIPO_BUSCA.find((each) => each.name.toString() === tipoPesquisa)?.name ===
                      'CONDUTOR'
                        ? { width: '99%' }
                        : { width: '100%' }
                    }
                  >
                    <option value={undefined} key="Selecione" />
                    Selecione
                    {TIPO_BUSCA.map((each) => (
                      <option key={each.name} value={each.name}>
                        {each.displayName}
                      </option>
                    ))}
                  </Select>
                </Form.Row>
                {TIPO_BUSCA.find((each) => each.name.toString() === tipoPesquisa)?.name ===
                'PERIODO' ? (
                  <>
                    <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
                    <Form.Row>
                      <Input
                        required
                        value={model.dataIni}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataIni: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataInicioPesquisa"
                        name="dataInicio"
                        textInputTitle="Data Inicial:"
                        error={inputErrors.dataInicio}
                      />
                      <Input
                        required
                        value={model.dataFim}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataFim: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataFim"
                        name="dataFim"
                        textInputTitle="Data Final:"
                        error={inputErrors.dataFim}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Select
                        value={model.tipo}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          setModel({
                            ...model,
                            tipo: e.target.value,
                          });
                        }}
                        id="tipo"
                        name="tipo"
                        textInputTitle="Consultar:"
                        error={inputErrors.consultar}
                      >
                        <option value={undefined} key="Selecione" />
                        {CONSULTAR_PICKER_DATA.map((each) => (
                          <option key={each.name} value={each.name}>
                            {each.displayName}
                          </option>
                        ))}
                      </Select>
                    </Form.Row>
                  </>
                ) : null}
                {TIPO_BUSCA.find((each) => each.name.toString() === tipoPesquisa)?.name ===
                'CONDUTOR' ? (
                  <>
                    <Form.Label className="SMLancadasTitle">Busca por condutor</Form.Label>
                    <Form.Row style={{ width: '99.8%' }}>
                      <Input
                        value={model.dataIni}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataIni: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataInicioPesquisa"
                        name="dataInicio"
                        textInputTitle="Data Inicial:"
                        error={inputErrors.dataInicio}
                      />
                      <Input
                        value={model.dataFim}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataFim: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataFim"
                        name="dataFim"
                        textInputTitle="Data Final:"
                        error={inputErrors.dataFim}
                      />
                    </Form.Row>
                    <Form.Row style={{ marginLeft: '1px' }}>
                      <Form.Label>Condutor:</Form.Label>
                      <Dropdown
                        value={condutorId}
                        options={listCondutores}
                        onChange={(e) => {
                          setLoadingAjuCond(true);
                          loadDadosCondutor(parseInt(e.target.value, 10));
                          setCondutorId(e.target.value);
                        }}
                        optionLabel="nome"
                        optionValue="id"
                        filter
                        filterBy="nome"
                        placeholder="Selecione"
                        className="p-dropdown"
                        showClear
                        style={{
                          width: '100%',
                          borderColor: inputErrors.nome ? '#ff0000' : '#ced4da',
                        }}
                      />
                    </Form.Row>
                    {loadingAjuCond ? (
                      <Spinner
                        animation="border"
                        variant="warning"
                        style={{
                          display: 'flex',
                          marginLeft: '47.5%',
                          marginTop: '5%',
                          marginBottom: '5%',
                        }}
                      />
                    ) : (
                      <Form.Row>
                        <Input
                          required
                          disabled
                          value={model && model.condutorCPF ? cpfMask(model.condutorCPF) : ''}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateModel(e);
                          }}
                          id="cpf"
                          name="cpf"
                          textInputTitle="CPF:"
                          error={inputErrors.cpf}
                          style={{ width: '99%' }}
                        />
                      </Form.Row>
                    )}
                  </>
                ) : null}
                {TIPO_BUSCA.find((each) => each.name.toString() === tipoPesquisa)?.name ===
                'AJUDANTE' ? (
                  <>
                    <Form.Label className="SMLancadasTitle">Busca por ajudante</Form.Label>
                    <Form.Row style={{ width: '99.8%' }}>
                      <Input
                        value={model.dataIni}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataIni: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataInicioPesquisa"
                        name="dataInicio"
                        textInputTitle="Data Inicial:"
                        error={inputErrors.dataInicio}
                      />
                      <Input
                        value={model.dataFim}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataFim: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataFim"
                        name="dataFim"
                        textInputTitle="Data Final:"
                        error={inputErrors.dataFim}
                      />
                    </Form.Row>
                    <Form.Row style={{ marginLeft: '1px' }}>
                      <Form.Label>Ajudante:</Form.Label>
                      <Dropdown
                        value={ajudanteId}
                        options={listAjudantes}
                        onChange={(e) => {
                          loadDadosAjudante(parseInt(e.target.value, 10));
                          setAjudanteId(e.target.value);
                        }}
                        optionLabel="nome"
                        optionValue="id"
                        filter
                        filterBy="nome"
                        placeholder="Selecione"
                        className="p-dropdown"
                        showClear
                        style={{
                          width: '100%',
                          borderColor: inputErrors.nome ? '#ff0000' : '#ced4da',
                        }}
                      />
                    </Form.Row>
                    {loadingAjuCond ? (
                      <Spinner
                        animation="border"
                        variant="warning"
                        style={{
                          display: 'flex',
                          marginLeft: '47.5%',
                          marginTop: '5%',
                          marginBottom: '5%',
                        }}
                      />
                    ) : (
                      <Form.Row>
                        <Input
                          required
                          disabled
                          value={model && model.ajudanteCPF ? cpfMask(model.ajudanteCPF) : ''}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setModel({
                              ...model,
                              ajudanteCPF: e.target.value,
                            });
                          }}
                          id="cpf"
                          name="cpf"
                          textInputTitle="CPF:"
                          error={inputErrors.cpf}
                          style={{ width: '99%' }}
                        />
                      </Form.Row>
                    )}
                  </>
                ) : null}
                {TIPO_BUSCA.find((each) => each.name.toString() === tipoPesquisa)?.name ===
                'VEICULO' ? (
                  <>
                    <Form.Label className="SMLancadasTitle">Busca por veículo</Form.Label>
                    <Form.Row>
                      <Input
                        value={model.dataIni}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataIni: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataInicioPesquisa"
                        name="dataInicio"
                        textInputTitle="Data Inicial:"
                        error={inputErrors.dataInicio}
                      />
                      <Input
                        value={model.dataFim}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            dataFim: e.target.value,
                          });
                        }}
                        type="date"
                        id="dataFim"
                        name="dataFim"
                        textInputTitle="Data Final:"
                        error={inputErrors.dataFim}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Select
                        required
                        value={model.placaVeiculo}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          setModel({
                            ...model,
                            placaVeiculo: e.target.value,
                          });
                        }}
                        id="placa"
                        name="placa"
                        textInputTitle="Placa nº:"
                        error={inputErrors.placa}
                      >
                        <option value={undefined} key="Selecione" />
                        {listVeiculos.map((each) => (
                          <option key={each.placa} value={each.placa}>
                            {each.placa}
                          </option>
                        ))}
                        {listCarretas.map((each) => (
                          <option key={each.placa} value={each.placa}>
                            {each.placa}
                          </option>
                        ))}
                      </Select>
                    </Form.Row>
                  </>
                ) : null}
                {TIPO_BUSCA.find((each) => each.name.toString() === tipoPesquisa)?.name ===
                'COD_LIBERACAO' ? (
                  <>
                    <Form.Label className="SMLancadasTitle">Busca por pesquisa</Form.Label>
                    <Form.Row>
                      <Input
                        required
                        type="number"
                        value={model.numeroLiberacao || ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            numeroLiberacao: +e.target.value,
                          });
                        }}
                        id="numLiberacao"
                        name="numLiberacao"
                        textInputTitle="Nº Liberação:"
                        error={inputErrors.numLiberacao}
                      />
                    </Form.Row>
                  </>
                ) : null}
                {!loadingAjuCond && <ButtonsForm />}
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
