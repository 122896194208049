import { useEffect, useState } from 'react';
import './styles.css';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { BinResponse } from '../../api/model/BauIntercambiavelType';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { filterItems2 } from '../../util/format';
import getTipoBau from '../../util/Validacoes/getTipoBau';
import Table from '../../components/TableComponent';

type Props = {
  canInsert: boolean;
};

const BauIntercambiavel = ({ canInsert }: Props) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { clienteId } = getCookieSessionData().usuarioVO;
  const { page }: any = useParams();
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/bau-intercambiavel/${pageNumber}`);
  };

  const headers = {
    id: 'id',
    numeroBau: 'Número Baú',
    modelo: 'Modelo',
    tipoBau: 'Tipo Baú',
    capacidadeCarga: 'Capacidade de carga',
    cubagem: 'Cubagem',
    qtdeViagens: 'Quantidade de Viagens',
    valorMedioTransportado: 'Valor Médio Transportado',
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get('/bau-intercambiavel/cliente', {
        params: { clienteId },
      });
      const array = response.data.map((each: BinResponse) => {
        return {
          id: each.id,
          numeroBau: each.numeroBau,
          modelo: each.modelo,
          tipoBau: getTipoBau(each.tipoBau),
          capacidadeCarga: each.capacidadeCarga,
          cubagem: each.cubagem,
          qtdeViagens: each.qtdeViagens,
          valorMedioTransportado: each.valorMedioTransportado,
        };
      });
      setData(array);
      setLoading(false);
    };
    fetchData();
  }, [clienteId]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/bau-intercambiavel/1');
      setDataSearch(filterItems2(data, searchValue));
    }
  }, [data, history, searchValue]);

  return (
    <Tela
      nome="Baú Intercambiável"
      caminho="Cadastro > Baú Intercambiável"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd="/bau-intercambiavel-add"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Table
          items={currentData}
          headers={headers}
          handleEdit={(id: any) => history.push(`/editar/bau-intercambiavel/${id}`)}
          hasId
        />
      )}
    </Tela>
  );
};

export default BauIntercambiavel;
