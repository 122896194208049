import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AvisosOperacionaisTypes } from '../../../api/Types/Types';
import axios from '../../../config/axiosMaquina';
import { Tela } from '../../../components/Tela';
import { Tabela } from '../../../components/Tabela';
import { Spinner } from '../../../components/Spinner';
import { getFullDateWithHourAndMinutes, pesquisar } from '../../../util/format';

type Props = {
  canInsert: boolean;
  canEdit: boolean;
};

const ListagemAvisos = ({ canInsert, canEdit }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const [loading, setLoading] = useState(false);
  const titles = ['+id', 'Mensagem', 'Prioridade', 'Data Cadastro', 'Dias Permanência'];
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const history = useHistory();

  function dataFinal(aviso: any) {
    const dc = new Date(aviso.disponivel);
    const permanenciaMs = 86400000 * aviso.permanencia;
    const expiracaoMs = permanenciaMs + dc.getTime();
    const dayToDate = new Date(expiracaoMs);
    return dayToDate;
  }

  function avisoExpirado(aviso: any) {
    const current = new Date();
    if (current.getTime() > dataFinal(aviso).getTime()) {
      return axios.delete(`/aviso-cliente/deletar/${aviso.id}`);
    }
    return aviso.diasPermanencia;
  }

  function dataFormatada(aviso: any) {
    const date = new Date(aviso);
    return date.toLocaleDateString();
  }

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    const loadData = () => {
      setLoading(true);
      axios.get('/aviso-operacional').then((response: any) => {
        const array = response.data.map((each: AvisosOperacionaisTypes) => {
          return [
            each.id,
            each.mensagem,
            each.prioridade,
            getFullDateWithHourAndMinutes(each.dataCadastro, true)?.split(' ')[0],
            avisoExpirado(each),
          ];
        });
        setData(array);
        console.log('avisos', response.data);
        setLoading(false);
      });
    };
    loadData();
  }, []);

  return (
    <Tela
      nome="Avisos Operacionais"
      caminho="Avisos > Listagem"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/operacionais/novo"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={currentPage}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => {
            if (canEdit) {
              history.push(`/operacionais/atualizar/${id}`);
            }
          }}
          avisos
        />
      )}
    </Tela>
  );
};

export default ListagemAvisos;
