/* eslint-disable */
import React, { ChangeEvent, FC, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button, Form, Modal } from 'react-bootstrap';
import { PendentesObj } from '../../types';
import { FormataStringData } from '../../util/Validacoes/Validacoes';
import './styles.css';
import { useToast } from '../../hooks/Toast';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  onPress: () => void;
  motivo: string;
  setMotivo: (motivo: string) => void;
  selectedItem: PendentesObj | undefined;
};

const ModalCancelamentoMonitoramento: FC<Props> = ({
  show,
  setShow,
  onPress,
  selectedItem,
  setMotivo,
  motivo,
}) => {
  const { addToast } = useToast();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  if (!selectedItem) {
    return null;
  }
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Dialog
        header="Solicitação de Monitoramento foi cancelada com sucesso!"
        footer={<Button onClick={() => onPress()}>Ok</Button>}
        visible={isDialogVisible}
        style={{ width: '50vw' }}
        modal
        onHide={() => onPress()}
      />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cancelar Monitoramento</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Nº SM</Form.Label>
            <Form.Control
              value={selectedItem.id}
              id="numSM"
              name="numSM"
              type="number"
              placeholder=""
              disabled
            />
          </Form.Group>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Embarcador</Form.Label>
              <Form.Control
                value={selectedItem.numEmbarcador}
                id="numEmbarcador"
                name="numEmbarcador"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={selectedItem.embarcador}
                id="embarcador"
                name="embarcador"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Início da Viagem</Form.Label>
              <Form.Control
                value={
                  FormataStringData(
                    selectedItem.dataInicio.toString().split('T')[0].replaceAll('-', '/')
                  ) +
                  ' ' +
                  selectedItem.dataTermino.toString().split(/[T,.]/)[1]
                }
                id="inicioViagem"
                name="inicioViagem"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>Término da viagem</Form.Label>
              <Form.Control
                value={
                  FormataStringData(
                    selectedItem.dataTermino.toString().split('T')[0].replaceAll('-', '/')
                  ) +
                  ' ' +
                  selectedItem.dataTermino.toString().split(/[T,.]/)[1]
                }
                id="terminoViagem"
                name="terminoViagem"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Rota</Form.Label>
            <Form.Control
              value={selectedItem.rota === null ? selectedItem.origem : selectedItem.rota}
              id="rota"
              name="rota"
              placeholder=""
              disabled
            />
          </Form.Group>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Veículo</Form.Label>
              <Form.Control
                value={selectedItem.placaVeiculo}
                id="veiculoPlaca"
                name="veiculoPlaca"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={selectedItem.veiculo}
                id="veiculoMarca"
                name="veiculoMarca"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Condutor</Form.Label>
              <Form.Control
                value={selectedItem.condutor}
                id="condutorNome"
                name="condutorNome"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group className="row-modal">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={selectedItem.condutorCpf}
                id="condutorCpf"
                name="condutorCpf"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Valor da Carga</Form.Label>
            <Form.Control
              value={
                selectedItem.valorCarga
                  ? selectedItem.valorCarga.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : ''
              }
              id="valorCarga"
              name="valorCarga"
              placeholder=""
              disabled
            />
          </Form.Group>

          <hr />

          <Form.Group id="pontoOrigemContainer">
            <Form.Label>Motivo</Form.Label>
            <Form.Control
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setMotivo(e.target.value);
              }}
              value={motivo}
              as="select"
              id="ponto"
              name="ponto"
              placeholder="Ponto de Origem"
              required
            >
              <option></option>
              {MOTIVOS.map((motivo: { id: number; motivoName: string }, index: number) => (
                <option key={index} value={motivo.id}>
                  {motivo.motivoName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={() => {
            if (!motivo) {
              // alert('Selecione um motivo para realizar o cancelamento');
              addToast({
                title: 'Info',
                description: 'Selecione um motivo para realizar o cancelamento.',
                type: 'info',
              });
              return;
            }
            setIsDialogVisible(true);
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MOTIVOS = [
  { id: 1, motivoName: 'Checklist reprovado' },
  { id: 2, motivoName: 'Dado(s) Incorreto(s)' },
  { id: 3, motivoName: 'Equipamento incompatível para viagem' },
  { id: 4, motivoName: 'Veículo não espelhado para GS' },
];

export default ModalCancelamentoMonitoramento;
