/* eslint-disable */
import React, { useState } from 'react';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';
import { authContext } from './context/AuthContext';
import { AuthResponse } from './api/model/AuthResponse';
import AppProvider from './hooks';
import { menuContext } from './context/MenuContext';

function App() {
  const [authResponse, setAuthResponse] = useState<AuthResponse>();
  const [show, setShow] = useState(true);

  return (
    <menuContext.Provider value={{ show, setShow }}>
      <authContext.Provider value={{ auth: authResponse, setAuth: setAuthResponse }}>
        <AppProvider>
          <Routes />
        </AppProvider>
      </authContext.Provider>
    </menuContext.Provider>
  );
}

export default App;
