/* eslint-disable */
export const itemsAdmin = [
  {
    label: 'Home',
    command: () => {
      window.location.href = '/home';
    },
  },

  {
    label: 'Cadastro',
    items: [
      {
        label: 'Clientes',
        items: [
          {
            label: 'Consulta / Cadastro',
            command: () => {
              // window.open(`${process.env.REACT_APP_SC_URL}/tabs_form_cadastro/`, '_scriptCase');
              window.location.href = '/clientes/listar/1';
            },
          },
        ],
      },

      {
        label: 'Rastreadores',
        items: [
          {
            label: 'Tipos de Comunicação',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_tecnologia_tipo_comunicacao/`,
              //   '_scriptCase'
              // );
              window.location.href = '/listar/tipos-de-comunicacao/1';
            },
          },
          {
            label: 'Tecnologia',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_tecnologia_rastreamento/`,
              //   '_scriptCase'
              // );
              window.location.href = '/listar/tecnologias-de-rastreamento/1';
            },
          },
          {
            label: 'Dispositivos',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_dispositivo/`,
              //   '_scriptCase'
              // );
              window.location.href = '/listar/dispositivos/1';
            },
          },
        ],
      },

      {
        label: 'Veículos',
        items: [
          {
            label: 'Grupo Macro de Veículos',
            command: () => {
              window.location.href = '/listar/grupo-macro-de-veiculos';
            },
          },
          {
            label: 'Grupos de Veículos',
            command: () => {
              window.location.href = '/listar/grupos-de-veiculos/1';
            },
          },
          {
            label: 'Tipos de Veículos',
            command: () => {
              window.location.href = '/listar/tipos-de-veiculo/1';
            },
          },

          {
            label: 'Cor',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_editavel_public_cor_veiculo/`,
              //   '_scriptCase'
              // );
              window.location.href = '/listar/cor/1';
            },
          },
          {
            label: 'Marca',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_editavel_public_marca_veiculo/`,
              //   '_scriptCase'
              // );
              window.location.href = '/listar/marcas/1';
            },
          },
          {
            label: 'Rastreamento',
            command: () => {
              window.location.href = '/listar/rastreamento';
            },
          },
        ],
      },
      // {
      //   label: 'Macros Jornada',
      //   command: () => {
      //     window.location.href = '/listar/macros-jornada/1';
      //   },
      // },
      {
        label: 'Gerenciamento de Clientes',
        items: [
          {
            label: 'Ajudante Funcionário (Não Motorista)',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_ajudante_funcionario_gerenciador/`,
              //   '_scriptCase'
              // );
              window.location.href = '/admin/filtrar/ajudantes';
            },
          },
          {
            label: 'Carreta',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_carreta_gerenciador/`,
              //   '_scriptCase'
              // );
              window.location.href = '/admin/filtrar/carretas';
            },
          },
          {
            label: 'Condutor',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_condutor_gerenciador/?nmgp_outra_jan=true&nmgp_start=SC&script_case_session=tv66g39q41epoo3cqd1qruqro0&7925`,
              //   '_scriptCase'
              // );
              window.location.href = '/admin/filtrar/condutores';
            },
          },

          // {
          //   label: 'Regras de Apólice',
          //   command: () => {
          //     window.open(
          //       `${process.env.REACT_APP_SC_URL}/grid_public_embarcador_gerenciador/`,
          //       '_scriptCase'
          //     );
          //   },
          // },
          {
            label: 'Embarcador (Regras de Apólice)',
            command: () => {
              window.location.href = '/admin/listar/embarcadores/1';
            },
          },
          {
            label: 'Proprietário',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_proprietario_gerenciador/`,
              //   '_scriptCase'
              // );
              window.location.href = '/admin/filtrar/proprietarios';
            },
          },
          {
            label: 'Veículo',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_veiculo_gerenciador/`,
              //   '_scriptCase'
              // );
              window.location.href = '/admin/filtrar/veiculos';
            },
          },
          {
            label: 'BlackList Seguradora',
            command: () => {
              window.location.href = '/admin/listar/blacklist-seguradora/1';
            },
          },
          {
            label: 'Validação CNH',
            command: () => {
              window.location.href = '/admin/listar/validacao-cnh/1';
            },
          },
        ],
      },
      {
        label: 'Gerentes',
        command: () => {
          window.location.href = '/admin/listar/gerentes/1';
        },
      },
      {
        label: 'Motivos',
        command: () => {
          window.location.href = '/listar/motivos/1';
        },
      },
      {
        label: 'Não Conformidades',
        command: () => {
          // window.open(
          //   `${process.env.REACT_APP_SC_URL}/grid_public_nao_conformidade/`,
          //   '_scriptCase'
          // );
          window.location.href = '/listar/nao-conformidades/1';
        },
      },

      {
        label: 'Analistas de Perfil',
        command: () => {
          window.location.href = '/listar/analistas-de-perfil/1';
        },
      },

      {
        label: 'Corretora',
        command: () => {
          // window.open(`${process.env.REACT_APP_SC_URL}/grid_public_corretora/`, '_scriptCase');
          window.location.href = '/listar/corretoras';
        },
      },

      {
        label: 'Seguradora',
        command: () => {
          // window.open(`${process.env.REACT_APP_SC_URL}/grid_public_seguradora/`, '_scriptCase');
          window.location.href = '/listar/seguradoras';
        },
      },
    ],
  },

  {
    label: 'Operacional',
    items: [
      {
        label: 'Solicitações',
        items: [
          {
            label: 'Análises de Perfil',
            items: [
              {
                label: 'Lançamento',
                command: () => {
                  // window.open(
                  //   `http://170.80.70.50:8090/qa_gs/form_public_pesquisa_ajudante_condutor_veiculo_1/?glo_sgu_cd_id=1`,
                  //   '_scriptCase'
                  // );
                  window.location.href = '/analise-perfil/grid';
                },
              },
              {
                label: 'Painel Análise Perfil',
                command: () => {
                  window.location.href = '/analise-perfil/painel/1';
                },
              },
            ],
          },
          {
            label: 'Assistência Técnica',
            command: () => {
              window.location.href = '/admin/listagem-sat/1';
            },
          },
          {
            label: 'Checklist Veículos',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_checklist_veiculo/?glo_sgu_cd_id=1`,
              //   '_scriptCase'
              // );
              window.location.href = '/cliente/operacional/listar-checklist/1';
            },
          },
        ],
      },
      {
        label: 'Painel Operacional',
        command: () => {
          window.location.href = '/admin/painel-operacional/pendentes/1';
        },
      },
      {
        label: 'Tecnologias',
        command: () => {
          window.location.href = '/listar/historico-tecnologias/1';
        },
      },
      {
        label: 'Faturamento/Frota',
        command: () => {
          window.location.href = '/listar/faturamento-frota/1';
        },
      },
      // {
      //   label: 'Controle de Velocidade',
      //   command: () => {
      //     window.location.href = '/controle-logistico';
      //   },
      // },
      {
        label: 'Painel LSC',
        command: () => {
          window.location.href = '/painel-lsc/1';
        },
      },
    ],
  },

  {
    label: 'Avisos',
    items: [
      {
        label: 'Aviso Clientes',
        command: () => {
          window.location.href = '/listar/avisos-clientes';
        },
      },
      {
        label: 'Aviso Operacional',
        command: () => {
          window.location.href = '/listar/avisos-operacionais';
        },
      },
    ],
  },

  {
    label: 'Consultas / Relatórios',
    items: [
      {
        label: 'Análise Perfil',
        command: () => {
          window.location.href = '/consultar-pesquisas-lancadas/';
        },
      },
      {
        label: 'Checklist',
        command: () => {
          window.location.href = '/checklist/filtro-checklist';
        },
      },
      // {
      //   label: 'Jornada de trabalho',
      //   command: () => {
      //     window.location.href = '/listar/jornada-de-trabalho/1';
      //   },
      // },
      {
        label: 'Rastreamento',
        items: [
          {
            label: 'Histórico de Posições',
            command: () => {
              window.location.href = '/rastreamento/historico-posicoes/1';
            },
          },
          {
            label: 'Requisições API',
            command: () => {
              window.location.href = '/rastreamento/requisicao-api';
            },
          },
          {
            label: 'Tempo Parado',
            command: () => {
              window.location.href = '/rastreamento/tempo-parado';
            },
          },
        ],
      },
      {
        label: 'Assistência Técnica',
        items: [
          {
            label: 'Resumo SAT',
            command: () => {
              // window.open(
              //   `${process.env.REACT_APP_SC_URL}/grid_public_solicitacao_assistencia_tecnica_resumo/`,
              //   '_scriptCase'
              // );
              window.location.href = '/consultar-sat';
            },
          },
        ],
      },
      {
        label: 'SM',
        items: [
          {
            label: 'SM Lançadas',
            command: () => {
              window.location.href = '/consultar-sm-lancadas';
            },
          },
          {
            label: 'Não Conformidades',
            command: () => {
              window.location.href = '/nao-conformidade/consulta';
            },
          },
        ],
      },
    ],
  },

  {
    label: 'Parâmetros',
    items: [
      {
        label: 'E-mail',
        command: () => {
          window.location.href = '/parametros/servicoSmpt';
        },
      },
      {
        label: 'Integração',
        command: () => {
          window.location.href = '/parametros/integracao';
        },
      },
      {
        label: 'Sistema',
        command: () => {
          window.location.href = '/parametros/sistema';
        },
      },
      {
        label: 'Automatização',
        command: () => {
          window.location.href = '/parametros/automatizacao';
        },
      },
      // {
      //   label: 'LOGs',
      //   items: [
      //     {
      //       label: 'Integração Dminer',
      //       command: () => {
      //         window.location.href = '/parametros/logs/integracao-dminer/1';
      //       },
      //     },
      //     {
      //       label: 'WebService Pamcary',
      //       command: () => {
      //         window.location.href = '/parametros/logs/webservice-pamcary/1';
      //       },
      //     },
      //     {
      //       label: 'WebService S.M',
      //       command: () => {
      //         window.location.href = '/parametros/logs/webservice-sm/1';
      //       },
      //     },
      //   ],
      // },
      // {
      //   label: 'Jornada de Trabalho',
      //   command: () => {
      //     window.location.href = '/parametros/jornada-de-trabalho';
      //   },
      // },
    ],
  },

  {
    label: 'Usuários',
    items: [
      {
        label: 'Usuários Gerenciadores',
        command: () => {
          window.location.href = '/listar-usuarios-gerenciadores/1';
        },
      },
      {
        label: 'Usuários Corretora',
        command: () => {
          window.location.href = '/listar-usuarios-corretoras/1';
        },
      },
      {
        label: 'Usuários Seguradora',
        command: () => {
          window.location.href = '/listar-usuarios-seguradoras/1';
        },
      },
      {
        label: 'Associar Clientes',
        command: () => {
          window.location.href = '/associar-clientes';
        },
      },
      {
        label: 'Usuários Clientes',
        command: () => {
          window.location.href = '/listar-usuarios-clientes/1';
        },
      },
      {
        label: 'Perfis de Usuários',
        command: () => {
          window.location.href = '/perfis-usuarios/listar/1';
        },
      },
    ],
  },

  {
    label: 'Fazer Logout',
    icon: 'pi pi-fw pi-user-minus',
    command: () => {
      window.location.href = '/logout';
    },
  },
];
