/* eslint-disable */
import React from 'react';
import { Progressao } from '../../pages/ProgressaoViagens/ProgressaoViagens';
import orderBy from 'lodash/orderBy';

export const choosePontoBackgroundColor = (statusPonto: string) => {
  if (statusPonto === 'B') return '#bfffbd';
  if (statusPonto === 'A') return '#ffabb4';
  if (statusPonto === 'D') return '#ffdaa6';
  return '#FFFFFF';
};

export const chooseLinhaBackgroundColor = (statusPonto: string) => {
  if (statusPonto === 'B') return '#63c960';
  if (statusPonto === 'A') return '#c44a57';
  if (statusPonto === 'D') return '#e8ab53';
  return '#FFFFFF';
};

type PropType = {
  viagem: Progressao;
};

export const BarraViagem = ({ viagem }: PropType) => {
  const { monitSmProgresViagPontoVO } = viagem;

  const ordenado = orderBy(monitSmProgresViagPontoVO, 'numSeqPonto');

  return (
    <>
      {ordenado && (
        <>
          <tr>
            <td style={{ verticalAlign: 'middle', fontSize: '12px' }}>
              {ordenado[0] === undefined ? '' : ordenado[0].nomePonto}
            </td>
            <td colSpan={8}>
              <tr>
                <span
                  style={{ fontSize: '12px' }}
                  className="progressao-body-texto"
                >{`Nº SM: ${viagem.idSm} `}</span>
                <span
                  style={{ fontSize: '12px' }}
                  className="progressao-body-texto"
                >{`Placas: ${viagem.placas}`}</span>
                <span
                  style={{ fontSize: '12px' }}
                  className="progressao-body-texto"
                >{`Percorrido: ${viagem.percorrido}`}</span>
                {viagem.percPercorrido === 100 ? null : (
                  <span
                    style={{ fontSize: '12px' }}
                    className="progressao-body-texto"
                  >{`Próximo ponto: ${viagem.nomProxPonto}`}</span>
                )}
              </tr>
              <tr className="timeline">
                {ordenado?.map((ponto: any, index: number) => {
                  const isLastPonto = index + 1 === ordenado?.length;
                  if (isLastPonto) {
                    return null;
                  }
                  return (
                    <>
                      <div
                        key={index}
                        className="progresso-ponto"
                        style={{
                          backgroundColor:
                            ponto.percPercorridoPonto === 0
                              ? '#e2f2f2'
                              : choosePontoBackgroundColor(ponto.statusPonto),
                        }}
                      >
                        <span title={`${ponto.hintPonto}`}>{index + 1}</span>
                      </div>

                      <div className="progresso-linha pointer">
                        <div
                          className={`progresso-linha-colored ${
                            !ponto.statusConcluidoPonto ? 'textura' : null
                          }`}
                          style={{
                            width: `${ponto.percPercorridoPonto}%`,
                            backgroundColor: chooseLinhaBackgroundColor(ponto.statusPonto),
                          }}
                        />
                      </div>
                    </>
                  );
                })}
                {ordenado[0] === undefined ? null : (
                  <div
                    className="progresso-ponto"
                    style={{
                      backgroundColor:
                        ordenado[ordenado.length - 1].percPercorridoPonto === 0
                          ? '#e2f2f2'
                          : choosePontoBackgroundColor(ordenado[ordenado?.length - 1].statusPonto),
                    }}
                  >
                    <span
                      title={
                        ordenado[0] === undefined
                          ? ''
                          : `${ordenado[ordenado?.length - 1].hintPonto}`
                      }
                    >
                      {ordenado?.length}
                    </span>
                  </div>
                )}
              </tr>
            </td>
            {ordenado[0] === undefined ? null : (
              <td
                style={{
                  verticalAlign: 'middle',
                  fontSize: '12px',
                }}
              >
                {ordenado[ordenado?.length - 1].nomePonto}
              </td>
            )}
          </tr>
        </>
      )}
    </>
  );
};
