/* eslint-disable */
import { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { LancamentoNaoConformidadeType } from '../../../api/model/SolicitacaoMonitoramentoType';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { useToast } from '../../../hooks/Toast';
import { NaoConformidadeType } from '../../../api/model/NaoConformidadeType';
import { Checkbox } from 'primereact/checkbox';
import { Tela } from '../../../components/Tela';
import { orderBy } from 'lodash';
import { removerSpecials } from '../../../util/format';
import { toZoned, obterData, obterHora } from '../../../util/date';

type Ufs = {
  id: number;
  sigla: string;
};

type Municipios = {
  id: number;
  nomeMunicipio: string;
};

type VeiculoType = {
  id: number;
  placa: string;
};

type CondutorType = {
  id: number;
  nome: string;
};

const LancamentoNCTransportador = () => {
  const { addToast } = useToast();
  const [veiculo, setVeiculo] = useState<VeiculoType>();
  const [carreta, setCarreta] = useState<VeiculoType>();
  const [carreta2, setCarreta2] = useState<VeiculoType>();
  const [condutor, setCondutor] = useState<CondutorType>();

  const [naoConformidade, setNaoConformidade] = useState<LancamentoNaoConformidadeType[]>([]);
  const [naoConformidadeEmbarcador, setNaoConformidadeEmbarcador] = useState<NaoConformidadeType[]>(
    []
  );
  const [tecnologia, setTecnologia] = useState();
  const [listaUfs, setListaUfs] = useState<Ufs[]>([]);
  const [listaMunicipios, setListaMunicipios] = useState<Municipios[]>([]);

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDialogVisibleCadOk, setIsDialogVisibleCadOk] = useState(false);
  const [isDialogVisibleCadErro, setIsDialogVisibleCadErro] = useState(false);
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);
  const [smPorEmbarcador, setSmPorEmbarcador] = useState(false);
  const [loading, setLoading] = useState(false);

  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const usuarioLogado = getCookieSessionData().usuarioVO.nome;
  const history = useHistory();
  const { id }: any = useParams();
  const { empresa }: any = useParams();
  const { idCliente }: any = useParams();

  const location: {
    state: {
      tab: string;
    };
  } = useLocation();
  const { state } = location;

  const [model, setModel] = useState<LancamentoNaoConformidadeType>({
    smId: parseInt(id),
    clienteId: parseInt(idCliente),
    veiculoId: null,
    carretaId: null,
    carreta2Id: null,
    condutorId: null,
    dataOcorrencia: obterData(new Date(), 'en2'),
    horaOcorrencia: obterHora(),
    ufIbgeId: null,
    municipioIbgeId: null,
    tipoOperacao: null,
    naoConformidadeId: null,
    descricaoObs: '',
    prontaResposta: null,
    nomeLiberador: null,
    usuarioId: idUsuario,
  });

  useEffect(() => {
    handleLoadSm();
    loadUfs();
    loadData();
  }, []);

  const handleLoadSm = async () => {
    setLoading(true);
    try {
      const response1 = await axios.get(`/solicitacao-monitoramento/${id}`);
      setModel({
        ...model,
        tipoOperacao: response1.data.tipoOperacao,
        veiculoId: response1.data.idVeiculo,
      });
      if (!response1.data.idEmbarcador) {
        loadNaoConformidades();
        return;
      }
      const response2 = await axios.get(
        `/embarcador/listarDadosEmbarcadorParaSM?embarcador=${response1.data.idEmbarcador}`
      );
      if (!response2.data.idCliente) {
        setModel({
          ...model,
          condutorId: response1.data.idCondutor,
          veiculoId: response1.data.idVeiculo,
          carretaId: response1.data.idCarreta,
          carreta2Id: response1.data.idCarreta2,
          tipoOperacao: response1.data.tipoOperacao,
        });
        loadNaoConformidades();
        return;
      }
      const response3 = await axios.get(
        `/cliente/getParticularidadesCliente?cliente=${response2.data.idCliente}`
      );
      if (response3.data.isEmbarcador === true) {
        setSmPorEmbarcador(true);
        loadNaoConformidadesEmbarcador(response2.data.idCliente);
        return;
      } else {
        loadNaoConformidades();
        return;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const loadData = () => {
    axios.get(`/veiculo/listar-id-placa-por-sm/1/${id}`).then((response: any) => {
      setVeiculo(response.data);
    });

    axios.get(`/veiculo/listar-id-placa-por-sm/2/${id}`).then((response: any) => {
      setCarreta(response.data);
    });

    axios.get(`/veiculo/listar-id-placa-por-sm/2/${id}`).then((response: any) => {
      setCarreta2(response.data[1]);
    });

    axios.get(`/condutor/listar-condutor-nome-por-sm/${id}`).then((response: any) => {
      setCondutor(response.data);
    });
  };

  useEffect(() => {
    if (model.ufIbgeId) {
      loadMunicipios();
    }
  }, [model.ufIbgeId]);

  const loadUfs = async () => {
    const response = await axios.get('/uf/ListarIdSiglaEstadoPais/1');
    setListaUfs(response.data);
  };

  const loadMunicipios = async () => {
    const response = await axios.get(`/municipio-ibge/listarPorUf/${model.ufIbgeId}`);
    setListaMunicipios(response.data);
  };

  const loadNaoConformidadesEmbarcador = async (idEmbarcador: number) => {
    const response = await axios.get(`/nao-conformidade/cliente?clienteId=${idEmbarcador}`);
    setNaoConformidadeEmbarcador(
      orderBy(response.data, [(nc) => removerSpecials(nc.naturezaEvento).toLowerCase()], ['asc'])
    );
  };

  const loadNaoConformidades = async () => {
    const response = await axios.get(`/nao-conformidade/gerenciador`);
    setNaoConformidade(
      orderBy(response.data, [(nc) => removerSpecials(nc.naturezaEvento).toLowerCase()], ['asc'])
    );
  };

  useEffect(() => {
    if (veiculo?.id != 0 && veiculo?.id) {
      axios.get(`/veiculo/${veiculo?.id}`).then((response) => {
        setTecnologia(response.data.tecnologiaRastreamentoNome);
      });
    }
  }, [veiculo?.id]);

  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    setModel((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }

  const handleTipoOperacao = (tipo: any) => {
    const num = tipo?.toString();
    switch (num) {
      case '1':
        return 'TRANSFERENCIA';
      case '2':
        return 'DISTRIBUICAO';
      case '3':
        return 'COLETA';
      case '4':
        return 'EXPORTACAO';
      case '5':
        return 'DEVOLUCAO';
      default:
        return '';
    }
  };

  const handleLancarNaoConformidade = async (event: any) => {
    event.preventDefault();

    if (smPorEmbarcador) {
      if (
        !model.horaOcorrencia ||
        !model.dataOcorrencia ||
        !model.ufIbgeId ||
        !model.municipioIbgeId ||
        !model.naoConformidadeId ||
        !model.nomeLiberador
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    } else {
      if (
        !model.horaOcorrencia ||
        !model.dataOcorrencia ||
        !model.naoConformidadeId ||
        !model.descricaoObs
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    const data = {
      smId: parseInt(id),
      clienteId: parseInt(idCliente),
      veiculoId: model.veiculoId,
      carretaId: model.carretaId,
      carreta2Id: model.carreta2Id,
      condutorId: model.condutorId,
      dataOcorrencia: toZoned(`${model.dataOcorrencia} ${model.horaOcorrencia}`),
      horaOcorrencia: toZoned(`${model.dataOcorrencia} ${model.horaOcorrencia}`),
      ufIbgeId: model.ufIbgeId,
      municipioIbgeId: model.municipioIbgeId,
      tipoOperacao: handleTipoOperacao(model.tipoOperacao || ''),
      naoConformidadeId: model.naoConformidadeId,
      descricaoObs: model.descricaoObs,
      prontaResposta: model.prontaResposta,
      nomeLiberador: model.nomeLiberador,
      usuarioId: idUsuario,
    };
    try {
      await axios.post('/nao-conformidade-lancamento', data);
      setIsDialogVisible(true);
    } catch (err: any) {
      if (err.response.data) {
        if (err.response.data.message.includes('Houve erro ao enviar o email')) {
          setIsDialogVisibleCadOk(true);
          return;
        }
      }
      setIsDialogVisibleCadErro(true);
    }
  };

  return (
    <Tela
      caminho="Painel Operacional > Lançamento de Não Conformidade"
      nome="Lançamento de Não Conformidades"
      loading={loading}
    >
      <Dialog
        header="Solicitação efetuada com sucesso!"
        footer={
          <Button
            onClick={() => {
              if (state.tab === 'EM_ANDAMENTO') {
                history.push('/admin/painel-operacional/em-andamento/1');
              } else {
                history.push('/admin/painel-operacional/pendentes/1');
              }
            }}
          >
            OK
          </Button>
        }
        visible={isDialogVisible}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisible(false)}
      />

      <Dialog
        header="Não conformidade cadastrada com sucesso!"
        footer={<Button onClick={() => setIsDialogVisibleMsgEmail(true)}>OK</Button>}
        visible={isDialogVisibleCadOk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleMsgEmail(true)}
      />

      <Dialog
        header="Não foi possível cadastrar não conformidade."
        footer={<Button onClick={() => setIsDialogVisibleCadErro(false)}>OK</Button>}
        visible={isDialogVisibleCadErro}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleCadErro(false)}
      />

      <Dialog
        header="Não foi possível enviar email de não conformidade. Verifique o email cadastrado."
        footer={
          <Button
            onClick={() => {
              if (state.tab === 'EM_ANDAMENTO') {
                history.push('/admin/painel-operacional/em-andamento/1');
              } else {
                history.push('/admin/painel-operacional/pendentes/1');
              }
            }}
          >
            OK
          </Button>
        }
        visible={isDialogVisibleMsgEmail}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleMsgEmail(false)}
      />

      <Form.Row>
        <Form.Group as={Col} className="mt-3 ml-3">
          <Form.Label>NÚMERO SM</Form.Label>
          <Form.Control size="sm" value={id} name="smId" disabled type="text" />
        </Form.Group>
        <Form.Group as={Col} className="mt-3 ml-3">
          <Form.Label>CLIENTE</Form.Label>
          <Form.Control size="sm" value={empresa} type="text" disabled />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="mt-3 ml-3">
          <Form.Label>VEÍCULO</Form.Label>
          <Form.Control size="sm" value={veiculo?.placa} name="veiculoId" type="text" disabled />
        </Form.Group>
        <Form.Group as={Col} className="mt-3 ml-3">
          <Form.Label>TECNOLOGIA</Form.Label>
          <Form.Control size="sm" value={tecnologia} type="text" disabled />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col}>
          <hr />
        </Form.Group>
      </Form.Row>

      <h5>Dados da Ocorrência</h5>
      <Form noValidate validated={isFormInvalid} onSubmit={handleLancarNaoConformidade}>
        <h6 style={{ color: 'red', fontSize: 12 }}>* Campos Obrigatórios</h6>
        {smPorEmbarcador ? (
          <>
            <Form.Row style={{ marginTop: 30 }}>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label style={{ marginBottom: '0px', marginRight: '5px' }}>
                  CONDUTOR {condutor?.nome}
                </Form.Label>
                <Checkbox
                  onChange={(e) => {
                    if (model.condutorId) {
                      setModel({ ...model, condutorId: null });
                    }
                    if (!model.condutorId) {
                      setModel({ ...model, condutorId: condutor?.id || null });
                    }
                  }}
                  checked={model.condutorId ? true : false}
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label style={{ marginBottom: '0px', marginRight: '5px' }}>
                  VEÍCULO {veiculo?.placa}
                </Form.Label>
                <Checkbox
                  onChange={(e) => {
                    if (model.veiculoId) {
                      setModel({ ...model, veiculoId: null });
                    }
                    if (!model.veiculoId) {
                      setModel({ ...model, veiculoId: veiculo?.id || null });
                    }
                  }}
                  checked={model.veiculoId ? true : false}
                />
              </Form.Group>
              {carreta && (
                <Form.Group as={Col} className="mt-3 ml-3">
                  <Form.Label style={{ marginBottom: '0px', marginRight: '5px' }}>
                    CARRETA {carreta?.placa}
                  </Form.Label>
                  <Checkbox
                    onChange={(e) => {
                      if (model.carretaId) {
                        setModel({ ...model, carretaId: null });
                      }
                      if (!model.carretaId) {
                        setModel({ ...model, carretaId: carreta?.id || null });
                      }
                    }}
                    checked={model.carretaId ? true : false}
                  />
                </Form.Group>
              )}

              {carreta2 && (
                <Form.Group as={Col} className="mt-3 ml-3">
                  <Form.Label style={{ marginBottom: '0px', marginRight: '5px' }}>
                    CARRETA(2) {veiculo?.placa}
                  </Form.Label>
                  <Checkbox
                    onChange={(e) => {
                      if (model.carreta2Id) {
                        setModel({ ...model, carreta2Id: null });
                      }
                      if (!model.carreta2Id) {
                        setModel({ ...model, carreta2Id: carreta2?.id || null });
                      }
                    }}
                    checked={model.carreta2Id ? true : false}
                  />
                </Form.Group>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>DATA OCORRÊNCIA
                </Form.Label>
                <Form.Control
                  size="sm"
                  value={model.dataOcorrencia}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  id="data"
                  name="dataOcorrencia"
                  type="date"
                  required
                  disabled={
                    model.veiculoId || model.condutorId || model.carretaId || model.carreta2Id
                      ? false
                      : true
                  }
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>HORA OCORRÊNCIA
                </Form.Label>
                <Form.Control
                  size="sm"
                  value={model.horaOcorrencia}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  id="hora"
                  name="horaOcorrencia"
                  type="time"
                  disabled={
                    model.veiculoId || model.condutorId || model.carretaId || model.carreta2Id
                      ? false
                      : true
                  }
                  required
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>UF
                </Form.Label>
                <Form.Control
                  size="sm"
                  name="ufIbgeId"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  as="select"
                  disabled={
                    model.veiculoId || model.condutorId || model.carretaId || model.carreta2Id
                      ? false
                      : true
                  }
                  required
                >
                  <option value="">Selecione</option>
                  {listaUfs.map((i) => (
                    <option value={i.id} key={i.id}>
                      {i.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>MUNICÍPIO
                </Form.Label>
                <Form.Control
                  size="sm"
                  disabled={!model.ufIbgeId}
                  name="municipioIbgeId"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  as="select"
                  required
                >
                  <option value="">Selecione</option>
                  {listaMunicipios.map((i) => (
                    <option value={i.id} key={i.id}>
                      {i.nomeMunicipio}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>OPERAÇÃO</Form.Label>
                <Form.Control
                  size="sm"
                  name="tipoOperacao"
                  as="select"
                  value={model.tipoOperacao!}
                  required
                  disabled
                >
                  <option value={1}>Transferência</option>
                  <option value={2}>Distribuição</option>
                  <option value={3}>Coleta</option>
                  <option value={4}>Exportação</option>
                  <option value={5}>Devolução</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>NATUREZA DO EVENTO
                </Form.Label>
                <Form.Control
                  size="sm"
                  name="naoConformidadeId"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  as="select"
                  disabled={
                    model.veiculoId || model.condutorId || model.carretaId || model.carreta2Id
                      ? false
                      : true
                  }
                  required
                >
                  <option value={0}>Selecione</option>
                  {naoConformidadeEmbarcador.map((each: NaoConformidadeType) => (
                    <option value={each.id} key={each.id}>
                      {each.naturezaEvento}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>CAUSA RAIZ</Form.Label>
                <Form.Control
                  size="sm"
                  disabled
                  value={model.naoConformidadeId!}
                  as="select"
                  required
                >
                  <option value=""></option>
                  {naoConformidadeEmbarcador.map((each: NaoConformidadeType) => (
                    <option value={each.id} key={each.id}>
                      {each.causaRaiz}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>PROCESSO MACRO</Form.Label>
                <Form.Control
                  size="sm"
                  disabled
                  value={model.naoConformidadeId!}
                  as="select"
                  required
                >
                  <option value=""></option>
                  {naoConformidadeEmbarcador.map((each: NaoConformidadeType) => (
                    <option value={each.id} key={each.id}>
                      {each.processoMacro}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>DESCRIÇÃO OCORRÊNCIA</Form.Label>
                <Form.Control
                  size="sm"
                  as="textarea"
                  name="descricaoObs"
                  rows={2}
                  value={model.descricaoObs}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  disabled={
                    model.veiculoId || model.condutorId || model.carretaId || model.carreta2Id
                      ? false
                      : true
                  }
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>PRONTA RESPOSTA?</Form.Label>
                <Form.Control
                  size="sm"
                  name="prontaResposta"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value === '1') {
                      setModel({ ...model, prontaResposta: false });
                    }
                    if (e.target.value === '2') {
                      setModel({ ...model, prontaResposta: true });
                    }
                  }}
                  as="select"
                  disabled={
                    model.veiculoId || model.condutorId || model.carretaId || model.carreta2Id
                      ? false
                      : true
                  }
                >
                  <option value="1">Não</option>
                  <option value="2">Sim</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                {model.prontaResposta && (
                  <>
                    <Form.Label>
                      <span className="txt-obrigatorio">*</span>SOLICITANTE ACIONAMENTO
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      // name="naoConformidadeId"
                      // onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      //   setModel(() => {
                      //     return {
                      //       ...model,
                      //       [e.target.name]: parseInt(e.target.value),
                      //     };
                      //   });
                      // }}
                    ></Form.Control>
                  </>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>LIBERADO POR
                </Form.Label>
                <Form.Control
                  size="sm"
                  name="nomeLiberador"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel(() => {
                      return {
                        ...model,
                        [e.target.name]: e.target.value,
                      };
                    });
                  }}
                  disabled={
                    model.veiculoId || model.condutorId || model.carretaId || model.carreta2Id
                      ? false
                      : true
                  }
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>OPERADOR GS
                </Form.Label>
                <Form.Control size="sm" disabled value={usuarioLogado}></Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>
            <ButtonsForm />
          </>
        ) : (
          <>
            <Form.Row style={{ marginTop: 30 }}>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>DATA OCORRÊNCIA
                </Form.Label>
                <Form.Control
                  size="sm"
                  value={model.dataOcorrencia}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  id="data"
                  name="dataOcorrencia"
                  type="date"
                  required
                />
              </Form.Group>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  <span className="txt-obrigatorio">*</span>HORA OCORRÊNCIA
                </Form.Label>
                <Form.Control
                  size="sm"
                  value={model.horaOcorrencia}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  id="hora"
                  name="horaOcorrencia"
                  type="time"
                  required
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  {' '}
                  <span className="txt-obrigatorio">*</span>NÃO CONFORMIDADE
                </Form.Label>
                <Form.Control
                  size="sm"
                  name="naoConformidadeId"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel(() => {
                      return {
                        ...model,
                        [e.target.name]: parseInt(e.target.value),
                      };
                    });
                  }}
                  as="select"
                  required
                >
                  <option value="">Selecione</option>
                  {naoConformidade
                    .filter((fil) => fil.clienteId != idCliente)
                    .map((each: any) => (
                      <option value={each.id} key={each.id}>
                        {each.naturezaEvento}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mt-3 ml-3">
                <Form.Label>
                  {' '}
                  <span className="txt-obrigatorio">*</span>OBSERVAÇÕES
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="textarea"
                  name="descricaoObs"
                  rows={2}
                  value={model.descricaoObs}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>
            <ButtonsForm />
          </>
        )}
      </Form>
    </Tela>
  );
};

export default LancamentoNCTransportador;
