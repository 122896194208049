/* eslint-disable */

import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, Spinner } from 'react-bootstrap';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { EmbarcadorType } from './EmbarcadorGrid';
import { useToast } from '../../hooks/Toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import instanceMaquina from '../../config/axiosMaquina';
import { useHistory } from 'react-router-dom';
import { maskCep, soNumeros, maskCpnj, maskTel, soLetras } from '../../util/Validacoes/Validacoes';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import { validaNumero } from '../../util/Validacoes/Validacoes';

type ModelValidationType = {
  isCnpjInvalid: boolean;
  isFormInvalid: boolean;
  exists: boolean;
};

export default function EmbarcadorAdd() {
  const { addToast } = useToast();
  const history = useHistory();
  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [model, setModel] = useState<EmbarcadorType>({
    cnpj: '',
    enderecoBairro: '',
    enderecoCep: '',
    enderecoCidade: '',
    enderecoComplemento: '',
    enderecoLogradouro: '',
    enderecoNumero: '',
    idEmbarcador: 0,
    razaoSocial: '',
    statusAtivo: true,
    telefone: '',
    telefoneDois: '',
    telefoneTres: '',
    contato: '',
    contatoDois: '',
    contatoTres: '',
    uf: 0,
    idCliente: clienteId,
    razaoSocialCliente: '',
  });

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const [modelValidation, setModelValidation] = useState<ModelValidationType>({
    isCnpjInvalid: false,
    isFormInvalid: false,
    exists: false,
  });

  function updateModel(e: ChangeEvent<HTMLInputElement>, validation?: Function) {
    setModel((prevState) => {
      if (validation) {
        return {
          ...prevState,
          [e.target.name]: validation(e.target.value),
        };
      } else {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      }
    });
  }

  const verificaCnpjExistente = async () => {
    const response = await instanceMaquina.get(
      `/embarcador/existe-cnpj-cliente?cnpjEmbarcador=${soNumeros(
        model.cnpj
      )}&idCliente=${clienteId}`
    );
    setModelValidation({
      ...modelValidation,
      exists: response.data,
    });
  };

  useEffect(() => {
    if (model.cnpj.length === 18) {
      verificaCnpjExistente && verificaCnpjExistente();
    } else {
      setModelValidation({
        ...modelValidation,
        exists: false,
      });
    }
  }, [model.cnpj]);

  useEffect(() => {
    const loadEstadosPorPais = async (pais: any) => {
      try {
        const response = await instanceMaquina.get(`/uf/ListarIdSiglaEstadoPais/${pais}`);
        setUfs(response.data);
        // setTeste(true);
      } catch (err: any) {
        console.log(err.response);
      }
    };
    loadEstadosPorPais(1);
  }, []);

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      const response = await instanceMaquina.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipios(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const buscarCep = () => {
      if (model.enderecoCep.length < 9) {
        setModel({
          ...model,
          enderecoLogradouro: '',
          enderecoBairro: '',
          enderecoCidade: '',
          uf: 0,
        });
        return;
      }
      // setError(false);
      fetch(`https://viacep.com.br/ws/${model.enderecoCep}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            // setError(true);
            return;
          }
          console.log('DATA BUSCAR CEP: ', data);

          setViaCepCidade(data.localidade);

          setModel({
            ...model,
            uf: ufs.find((each: any) => each.sigla === data.uf)?.id,
            enderecoCidade: data.localidade,
            enderecoLogradouro: data.logradouro,
            enderecoBairro: data.bairro,
          });

          loadMunicipiosPorEstado(ufs.find((each: any) => each.sigla === data.uf)?.id);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
        })
        .catch((erro) => {
          console.log('error', erro);
          // setError(true);
        });
    };
    buscarCep();
  }, [model.enderecoCep]); //buscar cep

  console.log('cnpj', model.cnpj);

  async function handleCnpjValidation(cnpj: string) {
    if (cnpj.length < 18) {
      if (cnpj.length === 14) {
        setModelValidation({
          ...modelValidation,
          isCnpjInvalid: false,
        });
      } else {
        setModelValidation({
          ...modelValidation,
          isCnpjInvalid: true,
        });
      }
    } else {
      setModelValidation({
        ...modelValidation,
        isCnpjInvalid: false,
      });
    }
  }

  console.log(modelValidation);

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();

    if (
      !model.cnpj ||
      !model.razaoSocial ||
      !model.idCliente ||
      modelValidation.isCnpjInvalid == true ||
      modelValidation.exists == true
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setModelValidation({
        ...modelValidation,
        isFormInvalid: true,
      });
      e.stopPropagation();
      return;
    }

    const data = {
      ...model,
      telefone: model.telefone
        ? model.telefone.replace('(', '').replace(')', '').replace('-', '')
        : '',
      telefoneDois: model.telefoneDois
        ? model.telefoneDois.replace('(', '').replace(')', '').replace('-', '')
        : '',
      telefoneTres: model.telefoneTres
        ? model.telefoneTres.replace('(', '').replace(')', '').replace('-', '')
        : '',
      cnpj: model.cnpj.replaceAll('.', '').replace('-', '').replace('/', ''),
      uf: model.uf === 0 ? null : model.uf,
    };

    try {
      instanceMaquina.post(`/embarcador`, data);
      console.log('console.log do post: ', JSON.stringify(data));
      setIsDialogVisibleCadEfetuado(true);
    } catch (err: any) {
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar!',
        type: 'error',
      });
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Embarcador cadastrado com sucesso!"
        footer={
          <>
            <Button label="OK" onClick={() => history.push('/admin/listar/embarcadores/1')} />
          </>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleCadEfetuado(false)}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Cadastro de Regra de Apólice</h2>
          {isAllowedByRole(['user']) && (
            <h6 className="subtitulo">{'Cadastro > Regras de Apólice'}</h6>
          )}
          {isAllowedByRole(['admin']) && (
            <h6 className="subtitulo">
              {'Cadastro > Gerenciamento de Clientes > Regras de Apólice'}
            </h6>
          )}
        </div>

        <div>
          <h1 className="info-obrigatoria">*Informações obrigatórias</h1>
        </div>

        <Form
          className="form-responsive"
          onSubmit={onSubmit}
          noValidate
          validated={modelValidation.isFormInvalid}
        >
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCnpj">
              <Form.Label className="require">CNPJ Nº</Form.Label>
              <Form.Control
                type="text"
                name="cnpj"
                placeholder=""
                required
                maxLength={18}
                minLength={18}
                isInvalid={
                  modelValidation.exists
                  // || modelValidation.isCnpjInvalid === true
                }
                value={maskCpnj(model.cnpj)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setModel({
                    ...model,
                    cnpj: e.target.value,
                  });
                }}
                // onBlur={() => handleCnpjValidation(model.cnpj)}
              />
              {model.cnpj.length == 0 ? (
                <Form.Control.Feedback type="invalid">
                  CNPJ não pode ser nulo.
                </Form.Control.Feedback>
              ) : modelValidation.exists ? (
                <Form.Control.Feedback type="invalid">
                  CNPJ já possui regra de apólice cadastrada.
                </Form.Control.Feedback>
              ) : // (
              //   <Form.Control.Feedback type="invalid">CNPJ inválido.</Form.Control.Feedback>
              // )
              null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRazaoSocial">
              <Form.Label className="require">Razão Social</Form.Label>
              <Form.Control
                type="text"
                name="razaoSocial"
                placeholder=""
                maxLength={256}
                required
                value={model.razaoSocial}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCep">
              <Form.Label>CEP</Form.Label>
              <Form.Control
                type="text"
                name="enderecoCep"
                placeholder=""
                maxLength={9}
                minLength={9}
                style={{ width: '32.8%' }}
                value={model.enderecoCep}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskCep)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridUf">
              <Form.Label>UF</Form.Label>
              <Form.Control
                type="number"
                name="uf"
                placeholder=""
                as="select"
                defaultValue={0}
                value={model.uf}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateModel(e), loadMunicipiosPorEstado(e.target.value);
                }}
              >
                <option value={0}>Selecione uma opção</option>
                {ufs.map((ufs) => (
                  <option value={ufs.id} key={ufs.id}>
                    {ufs.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCidade">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                as="select"
                type="number"
                name="enderecoCidade"
                placeholder=""
                defaultValue=""
                value={model.enderecoCidade}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              >
                <option value={''}>Selecione uma opção</option>
                {municipios.map((filteredMunicipio) => (
                  <option
                    key={filteredMunicipio.nomeMunicipio}
                    value={filteredMunicipio.nomeMunicipio}
                  >
                    {filteredMunicipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridBairro">
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                type="text"
                name="enderecoBairro"
                placeholder=""
                maxLength={128}
                value={model.enderecoBairro}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridLogradouro">
              <Form.Label>Logradouro</Form.Label>
              <Form.Control
                type="text"
                name="enderecoLogradouro"
                placeholder=""
                maxLength={256}
                value={soLetras(model.enderecoLogradouro)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridComplemento">
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                type="text"
                maxLength={128}
                name="enderecoComplemento"
                placeholder=""
                value={model.enderecoComplemento}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridNumero">
              <Form.Label>Número</Form.Label>
              <Form.Control
                type="text"
                maxLength={8}
                name="enderecoNumero"
                placeholder=""
                value={model.enderecoNumero}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (validaNumero(e.target.value) || e.target.value.length === 0) {
                    updateModel(e);
                  } else {
                    return;
                  }
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridContato">
              <Form.Label>Contato 1</Form.Label>
              <Form.Control
                name="contato"
                placeholder=""
                value={soLetras(model.contato)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                maxLength={64}
                minLength={3}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridContatoDois">
              <Form.Label>Contato 2</Form.Label>
              <Form.Control
                name="contatoDois"
                placeholder=""
                value={soLetras(model.contatoDois)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                maxLength={64}
                minLength={3}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridContatoTres">
              <Form.Label>Contato 3</Form.Label>
              <Form.Control
                name="contatoTres"
                placeholder=""
                value={soLetras(model.contatoTres)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                maxLength={64}
                minLength={3}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridTelefone">
              <Form.Label>Telefone 1</Form.Label>
              <Form.Control
                name="telefone"
                placeholder=""
                value={model.telefone}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskTel)}
                maxLength={14}
                minLength={13}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelefoneDois">
              <Form.Label>Telefone 2</Form.Label>
              <Form.Control
                name="telefoneDois"
                placeholder=""
                value={model.telefoneDois}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskTel)}
                maxLength={14}
                minLength={13}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelefoneTres">
              <Form.Label>Telefone 3</Form.Label>
              <Form.Control
                name="telefoneTres"
                placeholder=""
                value={model.telefoneTres}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskTel)}
                maxLength={14}
                minLength={13}
              />
            </Form.Group>
          </Form.Row>

          <ButtonsForm />
        </Form>
      </div>
    </div>
  );
}
