import { Dispatch, SetStateAction } from 'react';
import { errorsMessages } from '../../../constants/Messages';
import { validaVazioNulo } from '../../../util/Validacoes/Validacoes';

type Pesquisa =
  | {
      condutorCPF: string;
      dataFim: string;
      dataIni: string;
      idCliente: number;
      nomeCondutor: string;
      numeroLiberacao: number;
      placaVeiculo: string;
      tipo: string;
      tipoBusca: string;
    }
  | undefined;

type InputErrors = {
  selectedTipo: string;
  dataInicio: string;
  dataFim: string;
  consultar: string;
  cpf: string;
  nome: string;
  condutorId: number;
  placa: string;
  numLiberacao: string;
  tipoBusca: string;
};

type Props = {
  pesquisa: Pesquisa;
  setInputErrors: Dispatch<SetStateAction<InputErrors>>;
  selectedTipo: string | undefined;
};

const pesquisaFieldsValidation = ({ pesquisa, setInputErrors, selectedTipo }: Props): boolean => {
  let valid = true;
  const errors: InputErrors = {} as InputErrors;

  if (!pesquisa) {
    return true;
  }

  if (selectedTipo === '') {
    if (!validaVazioNulo(pesquisa.tipoBusca)) {
      errors.tipoBusca = errorsMessages.input.nullOrEmpty;
      valid = false;
    }
  }

  if (selectedTipo === 'PERIODO') {
    if (!validaVazioNulo(pesquisa.dataIni)) {
      errors.dataInicio = errorsMessages.input.nullOrEmpty;
      valid = false;
    }

    if (!validaVazioNulo(pesquisa.dataFim)) {
      errors.dataFim = errorsMessages.input.nullOrEmpty;
      valid = false;
    }
  } else if (selectedTipo === 'CONDUTOR') {
    if (!validaVazioNulo(pesquisa.nomeCondutor)) {
      errors.nome = errorsMessages.input.nullOrEmpty;
      valid = false;
    }

    if (!validaVazioNulo(pesquisa.condutorCPF)) {
      errors.cpf = errorsMessages.input.nullOrEmpty;
      valid = false;
    }
  } else if (selectedTipo === 'VEICULO') {
    if (!validaVazioNulo(pesquisa.placaVeiculo)) {
      errors.placa = errorsMessages.input.nullOrEmpty;
      valid = false;
    }
  } else if (selectedTipo === 'COD_LIBERACAO') {
    if (!validaVazioNulo(pesquisa.numeroLiberacao)) {
      errors.numLiberacao = errorsMessages.input.nullOrEmpty;
      valid = false;
    }
  }

  if (pesquisa.dataFim && new Date(pesquisa.dataFim) < new Date(pesquisa.dataIni)) {
    errors.dataFim = errorsMessages.input.dataFinalMenorInicial;
    valid = false;
  }

  setInputErrors(errors);
  return valid;
};

export default pesquisaFieldsValidation;
