/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import instanceMaquina from '../../config/axiosMaquina';
import { isAllowedByRole } from '../../services/cookieService';
import Search from '../../components/Search/Search';
import { Button } from 'primereact/button';
import { IoCheckmarkSharp, IoMailUnread, IoMailOpenSharp } from 'react-icons/io5';
import { getCookieSessionData } from '../../services/cookieService';
import { saveCookieParticularidadesData } from '../../services/cookieParticularidades';
import { Tela } from '../../components/Tela';
import { obterDataHora } from '../../util/date';

export type PainelHomeType = {
  cidadeDestino: string;
  cidadeOrigem: string;
  dtFimReal: string;
  dtInicioReal: string;
  empresa: string;
  estadoDestino: string;
  estadoOrigem: string;
  numSM: number;
  statusEscolta: boolean;
  statusFinalizada: boolean;
  statusIniciada: boolean;
  statusIsca: boolean;
  hintChecklist: string;
  hintColor: string;
  statusVisualizada: boolean;
  tecnologiaRastreamento: string;
  ufDestino: string;
  ufOrigem: string;
  valorCarga: number;
  veiPlaca: string;
  checklistVeiculo: boolean;
  bauChecklist: number;
  carreta2Checklist: number;
  carretaChecklist: number;
  veiculoChecklist: number;
  satVeiculo: boolean;
  dataInclusao: string;
  checklist: string;
  idCliente: number;
  bauId: number;
  numeroBau: string;
  carretaId: number;
  placaCarreta: string;
  carreta2Id: number;
  placaCarreta2: string;
  idVeiculo: number;
  statusColor: string;
  statusChecklist: string;
  placa: string;
  statusCancelamento: boolean;
  bauSat: number;
  carretaSat: number;
  carreta2Sat: number;
  veiculoSat: number;
};

const Home: React.FC = () => {
  const [data, setData] = useState<PainelHomeType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<PainelHomeType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const [totalItems, setTotalItems] = useState<number>();
  const { page }: any = useParams();
  const history = useHistory();
  const { usuarioId, isEmbarcador, clienteId, cnpj, role } = getCookieSessionData().usuarioVO;
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    search.trim() !== ''
      ? filteredData.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    const handleSaveParticularidades = async () => {
      if (role === 'admin') {
        return;
      }
      const response = await instanceMaquina.get(
        `/cliente/getParticularidadesCliente?cliente=${clienteId}`
      );
      saveCookieParticularidadesData(response.data);
    };
    handleSaveParticularidades();
  }, [clienteId]);

  const insertColorHint = (data: any[]) => {
    const array = data.map((each: any) => {
      const qtdChecklist = each.hintChecklist.split(',');
      const qtdChecklistOk = qtdChecklist.filter((check: string) =>
        check?.split(':')[1]?.trim().toLowerCase().includes('ok')
      );
      return {
        ...each,
        hintColor:
          qtdChecklist && qtdChecklistOk && qtdChecklist.length === qtdChecklistOk.length
            ? '#3AE963'
            : '#E93A3A',
      };
    });
    console.log(array);
    return array;
  };

  useEffect(() => {
    const getDados = async () => {
      if (role === 'admin') {
        return;
      }
      setLoading(true);
      const params = {
        idUsuario: usuarioId,
      };
      const paramsEmb = {
        cnpjEmbarcador: cnpj,
        idUsuario: usuarioId,
      };
      try {
        const response = await instanceMaquina.get(
          '/solicitacao-monitoramento/ListarDadosTelaInicial',
          {
            params: isEmbarcador ? paramsEmb : params,
          }
        );
        setData(insertColorHint(response.data));
      } catch (err: any) {
        console.log(err.response);
      } finally {
        setLoading(false);
      }
      setTimeout(() => {
        window.location.reload();
      }, 1200000);
    };
    getDados();
  }, []);

  useEffect(() => {
    if (isAllowedByRole(['user', 'seguradora', 'corretora'])) {
      history.push(`/home/1`);
    }
  }, []);

  const handleVisualizar = async (each: PainelHomeType) => {
    const dataDispositivos = {
      idCheck: each.veiculoChecklist,
      idCheckBau: each.bauChecklist,
      idSat: each.veiculoSat,
      idBauSat: each.bauSat,
      idCarSat: each.carretaSat,
      idCarSat2: each.carreta2Sat,
      idCarCheck: each.carretaChecklist,
      idCar2Check: each.carreta2Checklist,
      veiculoId: each.idVeiculo,
      veiculoPlaca: each.placa,
      carretaPlaca: each.placaCarreta,
      carretaId: each.carretaId,
      carreta2Placa: each.placaCarreta2,
      carreta2Id: each.carreta2Id,
      bauNumero: each.numeroBau,
      bauId: each.bauId,
      clienteId: each.idCliente,
    };

    try {
      if (!each.statusIniciada) {
        window.open(
          `/sm/editar/${each.numSM}`,
          'janela',
          'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
        );
      } else {
        window.open(
          `/finalizar-sm-emandamento/${each.numSM}`,
          'janela',
          'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
        );
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const retornaEscoltaIsca = (each: any) => {
    if (each.statusEscolta && each.statusIsca) {
      return 'Escolta/Isca';
    }
    if (each.statusEscolta && !each.statusIsca) {
      return 'Escolta';
    }
    if (!each.statusEscolta && each.statusIsca) {
      return 'Isca';
    }
    return '';
  };

  const retornaStatus = (each: any) => {
    if (each.statusFinalizada) {
      return 'Finalizada';
    }
    if (each.statusIniciada) {
      return 'Em andamento';
    }
    if (each.statusCancelamento) {
      return 'Cancelada';
    }
    return 'Pendente';
  };

  useEffect(() => {
    console.log(search);
    // console.log(currentData);
    if (search.trim() !== '') {
      const value = search.toLowerCase();
      const filtered = data.filter(
        (each: PainelHomeType) =>
          each.empresa?.toLowerCase().includes(value) ||
          each.numSM?.toString().includes(value) ||
          each.cidadeOrigem?.toLowerCase().includes(value) ||
          each.ufOrigem?.toLowerCase().includes(value) ||
          each.cidadeDestino?.toLowerCase().includes(value) ||
          each.ufDestino?.toLowerCase().includes(value) ||
          each.veiPlaca?.toLowerCase().includes(value) ||
          each.tecnologiaRastreamento?.toLowerCase().includes(value) ||
          each.valorCarga?.toString().includes(value) ||
          retornaEscoltaIsca(each)?.toLowerCase().includes(value) ||
          retornaStatus(each)?.toLowerCase().includes(value) ||
          obterDataHora(each.dtInicioReal)?.toLowerCase().includes(value) ||
          obterDataHora(each.dtFimReal)?.toLowerCase().includes(value)
      );
      console.log(filtered);
      setFilteredData(filtered);
    }
  }, [search]);

  const paginate = (pageNumber: number) => {
    history.push(`/home/${pageNumber}`);
  };
  //
  return (
    <Tela home loading={loading} setLoading={setLoading}>
      {!isAllowedByRole(['admin']) && (
        <div className="content-container">
          <Search
            disabled={!data}
            setLoading={setLoadingSearch}
            setSearch={setSearch}
            setSearchValue={setSearchValue}
            search={search}
          />
          <div className="table-responsive" style={{ flex: 1 }}>
            <div className="painel">
              <table className="table">
                <thead className="thead">
                  {isAllowedByRole(['corretora', 'seguradora']) ? (
                    <tr>
                      <th>Empresa</th>
                      <th>Nº S.M</th>
                      <th>Origem</th>
                      <th>Destino</th>
                      <th>Placa</th>
                      <th>Tecnologia</th>

                      <th>Valor Carga</th>
                      <th>Escolta/Isca</th>
                      <th>Início Real</th>
                      <th>Término Real</th>
                    </tr>
                  ) : isAllowedByRole(['user']) ? (
                    <tr>
                      <th>Nº S.M</th>
                      <th>Origem</th>
                      <th>Destino</th>
                      <th>Placa</th>
                      <th>Tecnologia</th>
                      <th>Empresa</th>
                      <th>Início Real</th>
                      <th>Término Real</th>
                      <th>Situação</th>
                      <th></th>
                    </tr>
                  ) : null}
                </thead>
                <tbody>
                  {currentData &&
                    currentData.map((each, index) => {
                      {
                        if (isAllowedByRole(['corretora', 'seguradora'])) {
                          return (
                            <tr key={index}>
                              <td>{each.empresa}</td>
                              <td>{each.numSM}</td>
                              <td>
                                {each.cidadeOrigem}/{each.ufOrigem}
                              </td>
                              <td>
                                {each.cidadeDestino}/{each.ufDestino}
                              </td>
                              <td>{each.veiPlaca}</td>
                              <td>{each.tecnologiaRastreamento}</td>

                              <td>{each.valorCarga}</td>
                              <td>
                                {each.statusEscolta && each.statusIsca
                                  ? 'Escolta/Isca'
                                  : each.statusEscolta && !each.statusIsca
                                  ? 'Escolta'
                                  : !each.statusEscolta && each.statusIsca
                                  ? 'Isca'
                                  : ''}
                              </td>
                              <td>{obterDataHora(each.dtInicioReal, true)}</td>
                              <td>{obterDataHora(each.dtFimReal, true)}</td>
                            </tr>
                          );
                        } else if (isAllowedByRole(['user'])) {
                          return (
                            <tr key={index}>
                              <td>{each.numSM}</td>
                              <td>
                                {each.cidadeOrigem}/{each.ufOrigem}
                              </td>
                              <td>
                                {each.cidadeDestino}/{each.ufDestino}
                              </td>
                              <td>{each.veiPlaca}</td>
                              <td>{each.tecnologiaRastreamento}</td>
                              <td>{each.empresa}</td>
                              <td>{obterDataHora(each.dtInicioReal, true)}</td>
                              <td>{obterDataHora(each.dtFimReal, true)}</td>
                              <td>
                                {each.statusFinalizada
                                  ? 'Finalizada'
                                  : each.statusIniciada
                                  ? 'Em andamento'
                                  : each.statusCancelamento
                                  ? 'Cancelada'
                                  : 'Pendente'}
                              </td>
                              <td style={{ width: '10%' }} id="icons-container">
                                <Button
                                  tooltip={each.hintChecklist}
                                  tooltipOptions={{ position: 'bottom' }}
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderColor: 'transparent',
                                    padding: 0,
                                    paddingRight: 5,
                                    marginBottom: 2,
                                  }}
                                >
                                  <IoCheckmarkSharp size={22} color={each.hintColor} />
                                </Button>

                                <Button
                                  tooltip="Visualizar SM"
                                  tooltipOptions={{ position: 'bottom' }}
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderColor: 'transparent',
                                    padding: 0,
                                    paddingRight: 5,
                                    marginBottom: 2,
                                  }}
                                  onClick={() => handleVisualizar(each)}
                                >
                                  {!each.statusVisualizada ? (
                                    <IoMailUnread size={22} color="#9F9F9F" />
                                  ) : (
                                    <IoMailOpenSharp size={22} color="#9F9F9F" />
                                  )}
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            totalPosts={searchValue.trim() !== '' ? filteredData.length : data.length}
            paginate={paginate}
            currentPage={page}
          />
        </div>
      )}
    </Tela>
  );
};

export default Home;
