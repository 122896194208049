import { useState, useCallback } from 'react';
import axios from '../config/axiosMaquina';

const { REACT_APP_GOOGLE_API_KEY: GOOGLE_API_KEY } = process.env;

const GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api';
const GOOGLE_GEO_URL = `${GOOGLE_API_URL}/geocode/json?key=${GOOGLE_API_KEY}&address=`;

export interface AddressType {
  logradouro: string;
  numero: string;
  bairro: string;
  cidade: string;
  estado: string;
}

export default function useAddress() {
  const [isLoading, setIsLoading] = useState(false);

  // const getAddressFromPostalCode = useCallback(async (postalCode) => {
  //   if (!postalCode || postalCode.length !== 8) {
  //     return null;
  //   }
  //   setIsLoading(true);
  //   try {
  //     const { data } = await axios.get(`https://viacep.com.br/ws/${postalCode}/json`);
  //     const { logradouro, bairro, localidade: cidade, uf, erro } = data;
  //     if (!erro) {
  //       return { logradouro, bairro, cidade, uf, postalCode };
  //     }
  //   } catch (err: any) {
  //     console.error(err);
  //     throw err;
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, []);

  const getLatLngFromAddress: any = useCallback(async (address: AddressType) => {
    if (typeof address !== 'object' || !address.logradouro) {
      throw new Error('Endereço inválido');
    }
    setIsLoading(true);
    const { logradouro, numero, bairro, cidade, estado } = address;
    const string = [logradouro, numero, bairro, cidade, estado].filter(Boolean).join(', ');
    try {
      const { data } = await axios.get(`${GOOGLE_GEO_URL}${string}`);
      if (data.error_message) {
        throw data.error_message;
      }

      if (data.results?.length) {
        return data.results[0].geometry?.location;
      }

      throw new Error('Localização não encontrada');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, getLatLngFromAddress };
}
