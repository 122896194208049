/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Modal, Spinner, Col } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { getCookieSessionData } from '../../../services/cookieService';

type CapacidadeCargaType = {
  cpcCdId: number;
  capacidade: number | string;
  codigofaixaCubagemCdId: number;
  sazonalidadeCdId: number;
  idCliente: number;
  tipoVeiculoCdId?: number;
};

type SazonalidadeCargaType = {
  szcCdId: number;
  szcTxNome: string;
};

type TipoVeiculoType = {
  vtpCdId: number;
  vtpTxNome: string;
};

type FaixaCubagemType = {
  id: number;
  cubagem: number;
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const CapacidadeCargaForm: React.FC<Props> = ({ canEdit, canDelete }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();
  const { clienteId } = getCookieSessionData().usuarioVO;

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [model, setModel] = useState<CapacidadeCargaType>({
    cpcCdId: 0,
    capacidade: '',
    codigofaixaCubagemCdId: 0,
    sazonalidadeCdId: 0,
    idCliente: clienteId,
    tipoVeiculoCdId: 0,
  });
  const [sazonalidadeList, setSazonalidadeList] = useState<SazonalidadeCargaType[]>([]);
  const [tipoVeiculoList, setTipoVeiculoList] = useState<TipoVeiculoType[]>([]);
  const [cubagemList, setCubagemList] = useState<FaixaCubagemType[]>([]);
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    cpcCdId,
    capacidade,
    codigofaixaCubagemCdId,
    sazonalidadeCdId,
    idCliente,
    tipoVeiculoCdId,
  } = model;

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`/capacidade-carga/find/${id}`);
        setModel(response.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    if (!sazonalidadeList.length && !tipoVeiculoList.length && !cubagemList.length) {
      setLoading(true);
      try {
        const sazonalidade = await axios.get('/sazonalidade-carga/find-all', {
          params: { idCliente },
        });
        setSazonalidadeList(sazonalidade.data);
        const tipoVeiculo = await axios.get('/tipo-veiculo/grid-tipo-veiculo');
        setTipoVeiculoList(tipoVeiculo.data);
        const cubagem = await axios.get('/cubagem-faixa/grid', { params: { idCliente } });
        setCubagemList(cubagem.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!capacidade || !sazonalidadeCdId || !tipoVeiculoCdId || !codigofaixaCubagemCdId) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    if (id) {
      setLoading(true);
      try {
        await axios.put(`/capacidade-carga/atualizar?idCc=${id}`, model);
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        await axios.post('/capacidade-carga/save', model);
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    console.log('teste');
    try {
      await axios.delete(`/capacidade-carga/delete/${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      setTimeout(function () {
        history.push('/capacidade-de-carga/listar/1');
      }, 1500);
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Capacidade de Carga ${id ? 'alterada' : 'cadastrada'} com sucesso!`}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/capacidade-de-carga/listar/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Capacidade de Carga excluída com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && !exclusao.ok && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Capacidade de Carga</h2>
          <h6 className="subtitulo">{`Cadastro > Capacidade de Carga > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="painel">
              <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
              <Form
                className="form-sm"
                noValidate
                validated={isFormInvalid}
                onSubmit={handleSubmit}
              >
                <Form.Row>
                  <Form.Group as={Col} sm={12} lg={6}>
                    <Form.Label className="requiredField">Capacidade</Form.Label>
                    <Form.Control
                      value={capacidade}
                      onChange={(e) => {
                        setModel({ ...model, capacidade: parseInt(e.target.value, 10) });
                      }}
                      type="number"
                      required
                      maxLength={64}
                      minLength={1}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={12} lg={6}>
                    <Form.Label className="requiredField">Sazonalidade</Form.Label>
                    <Form.Control
                      value={sazonalidadeCdId}
                      onChange={(e) => {
                        setModel({ ...model, sazonalidadeCdId: +e.target.value });
                      }}
                      as="select"
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {sazonalidadeList.map((s) => (
                        <option value={s.szcCdId} key={s.szcCdId}>
                          {s.szcTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={12} lg={6}>
                    <Form.Label className="requiredField">Tipo do Veículo</Form.Label>
                    <Form.Control
                      value={tipoVeiculoCdId}
                      onChange={(e) => {
                        setModel({ ...model, tipoVeiculoCdId: +e.target.value });
                      }}
                      as="select"
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {tipoVeiculoList.map((t) => (
                        <option value={t.vtpCdId} key={t.vtpCdId}>
                          {t.vtpTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} lg={6}>
                    <Form.Label className="requiredField">Cubagem</Form.Label>
                    <Form.Control
                      value={codigofaixaCubagemCdId}
                      onChange={(e) => {
                        setModel({ ...model, codigofaixaCubagemCdId: +e.target.value });
                      }}
                      as="select"
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {cubagemList.map((t) => (
                        <option value={t.id} key={t.id}>
                          {t.cubagem}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <ButtonsForm canEdit={canEdit} />
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CapacidadeCargaForm;
