/* eslint-disable */
import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormataStringData } from '../../util/Validacoes/Validacoes';
import './styles.css';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';
import { dataConverter } from '../../util/date';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  idItem?: number | null;
  atualizaPosCancelamento: boolean;
  setAtualizaPosCancelamento: (atualizaPosCancelamento: boolean) => void;
};

type PreCancelamentoType = {
  idUsuarioCancelamento: number;
  motivoCancelamento: number;
  idSm: number;
  dataCancelamento: string;
  nomeUsuario: string;
  nomeCliente: string;
  solicitanteCancelamento: '';
};

type CancelamentoType = {
  idUsuarioCancelamento: number;
  motivoCancelamento: number;
  idSm: number;
  dataCancelamento: string;
  nomeUsuario: string;
  comunicacao: number;
};

const ModalCancelamento: FC<Props> = ({
  show,
  setShow,
  idItem,
  atualizaPosCancelamento,
  setAtualizaPosCancelamento,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [preSolicitacaoCancelamento, setPreSolicitacaoCancelamento] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;
  const { addToast } = useToast();

  const dataAtual = new Date().toLocaleString();

  const [model, setModel] = useState<PreCancelamentoType>({
    idUsuarioCancelamento: 0,
    motivoCancelamento: 0,
    idSm: 0,
    dataCancelamento: '',
    nomeUsuario: '',
    nomeCliente: '',
    solicitanteCancelamento: '',
  });

  const [modelCad, setModelCad] = useState<CancelamentoType>({
    idUsuarioCancelamento: 0,
    motivoCancelamento: 0,
    idSm: 0,
    dataCancelamento: '',
    nomeUsuario: '',
    comunicacao: 0,
  });

  useEffect(() => {
    setAtualizaPosCancelamento(false);
    const cancelaSolicitacaoGetByID = async () => {
      if (!idItem) {
        return;
      } else {
        try {
          const response = await instanceMaquina.get(
            `/solicitacao-monitoramento/getDadosCancelamento/${idItem}`
          );
          setModel(response.data);
          if (response.data.dataCancelamento !== null) {
            setPreSolicitacaoCancelamento(true);
          } else {
            setPreSolicitacaoCancelamento(false);
          }
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    cancelaSolicitacaoGetByID();
  }, [idItem]);

  const cancelaSolicitacaoMonitoramento = async (e: any) => {
    e.preventDefault();

    if (!preSolicitacaoCancelamento) {
      if (!modelCad.motivoCancelamento || !modelCad.nomeUsuario || !modelCad.comunicacao) {
        setIsFormInvalid(true);
        //alert('Campos Obrigatórios não preenchidos.');
        addToast({
          title: 'Erro!',
          description: 'Preencha os campos obrigatórios',
          type: 'error',
        });
        setShow(true);
        e.stopPropagation();
        return;
      }
    }

    const dataPre = {
      ...model,
      idUsuarioCancelamento: usuarioId,
    };

    const dataCad = {
      ...modelCad,
      idSm: idItem,
      idUsuarioCancelamento: usuarioId,
      dataCancelamento: dataConverter(new Date().toISOString()),
    };

    try {
      if (preSolicitacaoCancelamento) {
        instanceMaquina.put(
          `/solicitacao-monitoramento/cancelarSM?solicitacaoMonitoramento=${idItem}&solicitanteCancelamento=${modelCad.nomeUsuario}`,
          dataPre
        );
        setIsDialogVisible(true);
      } else {
        instanceMaquina.put(
          `/solicitacao-monitoramento/cancelarSM?solicitacaoMonitoramento=${idItem}&solicitanteCancelamento=${modelCad.nomeUsuario}`,
          dataCad
        );
        setIsDialogVisible(true);
      }
    } catch (err: any) {
      console.log(err.response);
    } finally {
      setModelCad({
        idUsuarioCancelamento: 0,
        motivoCancelamento: 0,
        idSm: 0,
        dataCancelamento: '',
        nomeUsuario: '',
        comunicacao: 0,
      });
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
        setIsFormInvalid(false);
      }}
    >
      <Dialog
        header="Solicitação de Monitoramento foi cancelada com sucesso!"
        footer={
          <Button
            onClick={() => {
              setAtualizaPosCancelamento(true);
              setIsDialogVisible(false);
            }}
          >
            Ok
          </Button>
        }
        visible={isDialogVisible}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setAtualizaPosCancelamento(true);
          setShow(false);
        }}
      />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cancelar Monitoramento</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate validated={isFormInvalid}>
          <Form.Group>
            <Form.Label>Nº SM</Form.Label>
            <Form.Control
              value={idItem ? idItem : ''}
              id="numSM"
              name="numSM"
              type="number"
              placeholder=""
              disabled
            />
          </Form.Group>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label className="required">Solicitante</Form.Label>
              <Form.Control
                value={preSolicitacaoCancelamento ? model.nomeCliente : modelCad.nomeUsuario}
                onChange={(e: any) => {
                  setModelCad({
                    ...modelCad,
                    nomeUsuario: e.target.value,
                  });
                }}
                id="solicitante"
                name="solicitante"
                placeholder=""
                required
                disabled={preSolicitacaoCancelamento}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label className="required">Comunicação</Form.Label>
              <Form.Control
                value={modelCad.comunicacao}
                onChange={(e: any) => {
                  setModelCad({
                    ...modelCad,
                    comunicacao: parseInt(e.target.value, 10),
                  });
                }}
                id="comunicacao"
                name="comunicacao"
                placeholder=""
                as="select"
                required
                disabled={preSolicitacaoCancelamento}
              >
                {!preSolicitacaoCancelamento ? <option value="">Selecione uma opção</option> : null}

                {preSolicitacaoCancelamento ? (
                  <option>Sistema</option>
                ) : (
                  COMUNICACAO.map((each) => (
                    <option key={each.id} value={each.id}>
                      {each.comunicacaoName}
                    </option>
                  ))
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group className="row-modal">
              <Form.Label>Data Solicitação</Form.Label>
              <Form.Control
                value={
                  preSolicitacaoCancelamento
                    ? FormataStringData(
                        model.dataCancelamento?.toString().split('T')[0].replaceAll('-', '/')
                      )
                    : dataAtual.split(' ')[0]
                }
                id="dataSolicitacao"
                name="dataSolicitacao"
                placeholder=""
                type="text"
                disabled
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>Hora Solicitação</Form.Label>
              <Form.Control
                value={
                  preSolicitacaoCancelamento
                    ? model.dataCancelamento?.toString().split(/[T,.]/)[1].slice(0, -3)
                    : dataAtual.split(' ')[1].slice(0, -3)
                }
                id="horaSolicitacao"
                name="horaSolicitacao"
                placeholder=""
                type={preSolicitacaoCancelamento ? 'text' : 'time'}
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group className="ml-1">
              <Form.Label className="required">Motivo</Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setModelCad({
                    ...modelCad,
                    motivoCancelamento: parseInt(e.target.value, 10),
                  });
                }}
                value={
                  preSolicitacaoCancelamento
                    ? model.motivoCancelamento
                    : modelCad.motivoCancelamento
                }
                as="select"
                id="motivo"
                name="motivo"
                placeholder=""
                required
                style={{ width: '100%' }}
                disabled={preSolicitacaoCancelamento}
              >
                <option value="">Selecione uma opção</option>
                {MOTIVOS.map((motivo: { id: number; motivoName: string }, index: number) => (
                  <option key={index} value={motivo.id}>
                    {motivo.motivoName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={cancelaSolicitacaoMonitoramento}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MOTIVOS = [
  { id: 1, motivoName: 'Checklist reprovado' },
  { id: 2, motivoName: 'Dado(s) Incorreto(s)' },
  { id: 3, motivoName: 'Equipamento incompatível para viagem' },
  { id: 4, motivoName: 'Veículo não espelhado para GS' },
];

const COMUNICACAO = [
  { id: 1, comunicacaoName: 'Email' },
  { id: 2, comunicacaoName: 'MSN' },
  { id: 3, comunicacaoName: 'Nextel' },
  { id: 4, comunicacaoName: 'Skype' },
  { id: 5, comunicacaoName: 'Telefone' },
  { id: 6, comunicacaoName: 'WhatsApp' },
];

export default ModalCancelamento;
