/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Tela } from '../../components/Tela';
import { logout } from '../../services/cookieService';
import { getCookieSessionData } from '../../services/cookieService';

const LogOut: React.FC = () => {
  const [show, setShow] = useState(true);

  const handleLogout = () => {
    logout();
  };

  const history = useHistory();

  return (
    <Tela>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente sair?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => history.push('/home')}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={handleLogout}
          >
            Sair
          </Button>
        </Modal.Footer>
      </Modal>
    </Tela>
  );
};

export default LogOut;
