/* eslint-disable */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, Col, Row, Button, Alert, Navbar, FormControl } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import { ParadaType } from '../../api/model/ParadaType';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import { maskCep, validaNumero } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';

type Props = {
  canEdit?: boolean;
};

const EditarParada = ({ canEdit }: Props) => {
  const history = useHistory();
  const { addToast } = useToast();
  const [sequencia, setSequencia] = useState('');
  const [motivo, setMotivo] = useState('');
  const [local, setLocal] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [idCodigoIbge, setIdCodigoIbge] = useState('');
  const [cep, setCep] = useState('');
  const [viaCepUf, setViaCepUf] = useState('');
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [rotaId, setRotaId] = useState('');
  const [error, setError] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const { id }: any = useParams();

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [paradas, setParadas] = useState<ParadaType[]>([]);

  function listaRotas() {
    history.push(`/rota`);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/parada/${id}`);
      setSequencia(response.data.sequencia);
      setMotivo(response.data.motivo);
      setLocal(response.data.local);
      setLogradouro(response.data.logradouro);
      setNumero(response.data.numero);
      setComplemento(response.data.complemento);
      setBairro(response.data.bairro);
      setCidade(response.data.cidade);
      setIdCodigoIbge(response.data.idCodigoIbge);
      setCep(response.data.cep);
      setRotaId(response.data.rotaId);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const loadEstadosPorPais = async () => {
      try {
        const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/1`);
        setUfs(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    };
    loadEstadosPorPais();
  }, []);

  const loadMunicipiosPorEstado = async (estado: any) => {
    console.log(estado);
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipios(response.data);
      console.log(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/parada');
      setParadas(response.data);
    };
    fetchData();
  }, []);

  const handleEdit = async (event: any) => {
    event.preventDefault();
    if (
      !motivo ||
      !cep ||
      !logradouro ||
      !bairro ||
      !cidade ||
      !local ||
      !idCodigoIbge ||
      !numero
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    const data = {
      sequencia: sequencia,
      motivo: motivo,
      local: local,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      //puxar id do cliente logado
      rotaId: rotaId,
      idCodigoIbge: idCodigoIbge,
      ativo: true,
      cep: cep,
    };
    console.log(data);
    try {
      await axios.put(`/parada/${id}`, data);
      console.log(data);
      setIsDialogVisibleCadEfetuado(true);
      // alert('Alteração efetuada com sucesso.');
    } catch {
      // const alerta = alert('Erro ao atualizar');
      addToast({
        title: 'Erro!',
        description: 'Erro ao atualizar.',
        type: 'error',
      });
      return;
    }
  };

  useEffect(() => {
    const buscarCep = () => {
      if (cep.length < 9) {
        setCidade('');
        setLogradouro('');
        setBairro('');
        setIdCodigoIbge('');
        return;
      }
      setError(false);
      fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          setViaCepCidade(data.localidade);
          setViaCepUf(data.uf);
          //setCep(data.cep);
          const ufId = ufs
            .filter((uf) => uf.sigla === data.uf)
            .map((filteredUfId) => filteredUfId.id);
          setIdCodigoIbge(ufId.toString());
          console.log(ufId);
          console.log(idCodigoIbge);
          setCidade(data.localidade);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
          let listaMunPorEstado = municipios
            .filter((municipio) => municipio.idCodigoIbge === viaCepIbgeCod)
            .map((filteredMunicipio) => filteredMunicipio.nomeMunicipio);
          console.log(listaMunPorEstado);
          console.log(codIbgeFormatado);
        })
        .catch((erro) => {
          console.log('error', erro);
          setError(true);
        });
    };
    buscarCep();
  }, [cep]);

  const validaSequencia = (event: any) => {
    console.log(event);
    const idRotaParada = paradas
      .filter((parada) => parada.id == id)
      .map((rotaParada) => rotaParada.rotaId)
      .toString();
    const idRotaParadaConvertido = parseInt(idRotaParada);
    console.log(idRotaParadaConvertido);
    const listaSequencia = paradas
      .filter((parada) => parada.rotaId == idRotaParadaConvertido)
      .map((filteredSequencia) => filteredSequencia.sequencia);
    console.log(listaSequencia);
    if (event <= listaSequencia.length && event != 0) {
      setSequencia(event);
      //console.log("Sequencia já existe");
      // alert('Sequência ja existe, escolha outra');
      addToast({
        title: 'Info',
        description: 'Sequência ja existe, escolha outra.',
        type: 'info',
      });
    } else {
      setSequencia(event);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header="Parada foi alterada com sucesso!"
        footer={
          <>
            <Button onClick={() => history.goBack()}>OK</Button>
          </>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleCadEfetuado(false)}
      />

      <div className="card" style={{ backgroundColor: '#f2f2f2', borderColor: '#f2f2f2' }}>
        <Container fluid style={{ marginTop: '0px' }}>
          <Navbar style={{ marginTop: '10px' }}>
            <h2 className="titulo">Parada</h2>
            <h6 className="subtitulo">{'Cadastro > Parada > Edição'}</h6>
          </Navbar>

          <Form noValidate validated={isFormInvalid} onSubmit={handleEdit}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1" className="mt-4 ml-3">
                <Form.Label className="required">Sequencia:</Form.Label>

                <Form.Control
                  value={sequencia}
                  onChange={(event) => validaSequencia(event.target.value)}
                  id="sequencia"
                  name="sequencia"
                  placeholder=""
                  required
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                <Form.Label className="required">Motivo:</Form.Label>

                <Form.Control
                  value={motivo}
                  onChange={(event) => {
                    setMotivo(event.target.value);
                  }}
                  id="motivo"
                  name="motivo"
                  placeholder=""
                  required
                  as="select"
                  style={{ width: '97%', height: '37px' }}
                >
                  <option defaultValue="">Selecione uma opção</option>
                  <option value="1">Alimentação</option>
                  <option value="2">Descanso</option>
                  <option value="3">Entrega</option>
                  <option value="4">Coleta</option>
                  <option value="5">Fiscalização</option>
                  <option value="9">Outros</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Group as={Col} controlId="formGridAddress1">
              <Form.Label className="required">Local:</Form.Label>

              <Form.Control
                value={local}
                onChange={(event) => {
                  setLocal(event.target.value);
                }}
                id="local"
                name="local"
                placeholder=""
                required
                maxLength={64}
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1" className=" ml-3">
                <Form.Label className="required">CEP:</Form.Label>

                <Form.Control
                  value={cep}
                  onChange={(event: any) => {
                    setCep(maskCep(event.target.value));
                  }}
                  id="cep"
                  name="cep"
                  placeholder=""
                  required
                  maxLength={9}
                  minLength={9}
                  style={{ width: '100%' }}
                />

                {error && <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label className="required">Logradouro:</Form.Label>

                <Form.Control
                  value={logradouro}
                  onChange={(event: any) => {
                    setLogradouro(event.target.value);
                  }}
                  id="logradouro"
                  name="logradouro"
                  required
                  style={{ width: '97%' }}
                  maxLength={64}
                ></Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1" className=" ml-3">
                <Form.Label className="required">Número:</Form.Label>

                <Form.Control
                  value={numero}
                  onChange={(e: any) => {
                    if (validaNumero(e.target.value) || e.target.value.length === 0) {
                      setNumero(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  id="numero"
                  name="numero"
                  //placeholder=""
                  required
                  maxLength={4}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label>Complemento:</Form.Label>

                <Form.Control
                  value={complemento}
                  onChange={(event) => {
                    setComplemento(event.target.value);
                  }}
                  id="complemento"
                  name="complemento"
                  placeholder=""
                  style={{ width: '97%' }}
                  maxLength={64}
                />
              </Form.Group>
            </Form.Row>

            <Form.Group as={Col} controlId="formGridAddress1">
              <Form.Label className="required">Bairro:</Form.Label>

              <Form.Control
                value={bairro}
                onChange={(event) => {
                  setBairro(event.target.value);
                }}
                id="bairro"
                name="bairro"
                placeholder=""
                required
                style={{ width: '50%' }}
                maxLength={64}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1" className="ml-3">
                <Form.Label className="required">UF:</Form.Label>

                <Form.Control
                  value={idCodigoIbge}
                  onChange={(event: any) => {
                    setIdCodigoIbge(event.target.value);
                    loadMunicipiosPorEstado(event.target.value);
                  }}
                  id="idCodigoIbge"
                  name="idCodigoIbge"
                  required
                  as="select"
                  defaultValue=""
                  style={{ width: '20%' }}
                >
                  {ufs.map((uf) => (
                    <option value={uf.id} key={uf.id}>
                      {uf.sigla}
                    </option>
                  ))}
                  <option value={idCodigoIbge} key={idCodigoIbge}>
                    {viaCepUf}
                  </option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label className="required">Municipio:</Form.Label>

                <Form.Control
                  value={cidade}
                  onChange={(event: any) => {
                    setCidade(event.target.value);
                  }}
                  id="cidade"
                  name="cidade"
                  placeholder=""
                  required
                  as="select"
                  defaultValue=""
                  style={{ width: '97%' }}
                >
                  {municipios.map((filteredMunicipio) => (
                    <option
                      key={filteredMunicipio.nomeMunicipio}
                      value={filteredMunicipio.nomeMunicipio}
                    >
                      {filteredMunicipio.nomeMunicipio}
                    </option>
                  ))}
                  {viaCepCidade ? (
                    <option key={cidade} value={cidade}>
                      {viaCepCidade}
                    </option>
                  ) : (
                    municipios.map((municipio) => (
                      <option key={municipio.id} value={municipio.id}>
                        {municipio.nomeMunicipio}
                      </option>
                    ))
                  )}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <ButtonsForm canEdit={canEdit} />
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default EditarParada;
