/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ListagemHistoricoPesquisa, Item } from '.';
import Input from '../../../components/Input';
import { ResumoProcessual } from '../../../components/ResumoProcessual';
import { getCookieSessionData } from '../../../services/cookieService';
import getUfEndereco from '../../../util/getUfEndereco';
import getTipoFrota from '../../../util/Validacoes/getTipoFrota';
import { SituacaoVigenciaContainer } from './styles';
import axios from '../../../config/axiosMaquina';
import { VeiculoPesquisa } from '../../../Types/PesquisaCongeladaType';
import { cpfMask, maskCpnj } from '../../../util/Validacoes/Validacoes';
import { obterData } from '../../../util/date';

type Veiculo = {
  anttRntrc: string;
  chassi: string;
  emplacamentoUf: string;
  placa: string;
  proprietarioId: string;
  renavam: string;
  tipoFrota: string;
};

type Props = {
  veiculo: VeiculoPesquisa | undefined;
  solicitacao: ListagemHistoricoPesquisa;
};

const DadosPesquisaVeiculo: FC<Props> = ({ veiculo, solicitacao }) => {
  const { role } = getCookieSessionData().usuarioVO;
  const [item, setItem] = useState<Item | undefined>();
  const [proprietarioNome, setProprietarioNome] = useState('');

  useEffect(() => {
    setItem(
      solicitacao.itens.find((each: Item) => each.itemPesquisado === veiculo?.placa)
    );
  }, [solicitacao, veiculo]);

  useEffect(() => {
    const handleProprietario = async () => {
      if (veiculo && !isNaN(veiculo?.proprietarioId as any)) {
        const response = await axios.get(`/proprietario/proprietario/${veiculo.proprietarioId}`);
        console.log(response.data.proTxNomeRazaoSocial);
        setProprietarioNome(response.data.proTxNomeRazaoSocial);
      } else {
        setProprietarioNome(veiculo?.proprietarioId || '');
      }
    };
    handleProprietario();
  }, [veiculo]);

  return (
    <>
      <Form.Label className="SMLancadasTitle">Dados da Pequisa Veículo</Form.Label>
      <Form.Row>
        <Input
          required
          value={veiculo?.placa}
          onChange={() => false}
          id="placa"
          name="placa"
          textInputTitle="Placa:"
          disabled
        />
        <Input
          required
          value={veiculo?.tipoFrota ? getTipoFrota(veiculo?.tipoFrota) : ''}
          onChange={() => false}
          id="tipo"
          name="tipo"
          textInputTitle="Tipo:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={proprietarioNome}
          onChange={() => false}
          id="proprietario"
          name="proprietario"
          textInputTitle="Proprietário:"
          disabled
        />
        <Input
          required
          value={
            veiculo?.proprietarioCpfCnpj
              ? veiculo?.proprietarioCpfCnpj.length === 11
                ? cpfMask(veiculo?.proprietarioCpfCnpj)
                : maskCpnj(veiculo?.proprietarioCpfCnpj)
              : ''
          }
          onChange={() => false}
          id="proprietario"
          name="proprietario"
          textInputTitle="CPF/CNPJ Proprietário:"
          disabled
        />
        <Input
          required
          value={
            isNaN(veiculo?.emplacamentoUf as any)
              ? veiculo?.emplacamentoUf
              : getUfEndereco(parseInt(veiculo?.emplacamentoUf as any))
          }
          onChange={() => false}
          id="uf"
          name="uf"
          textInputTitle="UF:"
          disabled
        />
        <Input
          required
          value={veiculo?.anttRntrc ? 'Sim' : 'Não'}
          onChange={() => false}
          id="consultaAntt"
          name="consultaAntt"
          textInputTitle="Consulta ANTT?:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={veiculo?.chassi}
          onChange={() => false}
          id="numChassi"
          name="numChassi"
          textInputTitle="Chassi nº:"
          disabled
        />
        <Input
          required
          value={veiculo?.renavam}
          onChange={() => false}
          id="renavam"
          name="renavam"
          textInputTitle="Renavam nº:"
          disabled
        />
        <Input
          required
          value={veiculo?.anttRntrc}
          onChange={() => false}
          id="anttRntrc"
          name="anttRntrc"
          textInputTitle="ANTT/RNTRC nº:"
          disabled
        />
      </Form.Row>
      <SituacaoVigenciaContainer>
        <div>
          <span>
            Situação:{' '}
            <span style={{ color: item?.statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO' ? 'red' : '' }}>
              {item?.statusPesquisa === 'EM_ACORDO'
                ? 'Em Acordo'
                : item?.statusPesquisa === 'EM_ANDAMENTO'
                  ? 'Em Andamento'
                  : item?.statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO'
                    ? 'Pendente de Documentação'
                    : null}
            </span>
          </span>
        </div>
        {item?.statusPesquisa != 'EM_ACORDO' ? null : (
          <div>
            <span>
              Vigência:{' '}
              <span>
                {
                  item?.validade != 'No Embarque'
                    ? obterData(item?.validade)
                    : item?.validade
                }
              </span>
            </span>
          </div>
        )}
      </SituacaoVigenciaContainer>

      {solicitacao != undefined &&
        solicitacao.itens != undefined &&
        solicitacao.itens.length > 0 ? (
        role === 'admin' && item !== undefined ?
          (
            item?.statusPesquisa?.includes('PENDENTE_DE_DOCUMENTACAO') ?
              (
                <>
                  <Form.Row className="mt-4">
                    <Form.Label>Resumo Processual</Form.Label>
                  </Form.Row>
                  <ResumoProcessual numLiberacao={solicitacao.numLiberacao} tipo="PLACA" />
                </>
              ) : null
          ) : null
      ) : null}
    </>
  );
};

export default DadosPesquisaVeiculo;
