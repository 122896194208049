import React from 'react';
import { Col, Form } from 'react-bootstrap';

export const LinhaForm = () => {
  return (
    <Form.Row>
      <Form.Group as={Col}>
        <hr />
      </Form.Group>
    </Form.Row>
  );
};
