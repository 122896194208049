/* eslint-disable no-undef */
import { GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import { format } from 'date-fns';
import { FC } from 'react';
import { GridViolacao } from '../..';
import RedTruck from '../../../../assets/markers/red-truck.png';
import GreenTruck from '../../../../assets/markers/green-truck.png';
import { MapContainer } from './styles';

const { REACT_APP_GOOGLE_API_KEY: GOOGLE_API_KEY } = process.env;

type Props = {
  currentZoom: number | undefined;
  setMap: (map: google.maps.Map) => void;
  setCenter: (center: google.maps.LatLngLiteral) => void;
  initialCenter: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral | undefined;
  mainData: GridViolacao[];
  setSelectedMarker: (location: google.maps.LatLngLiteral) => void;
  selectedMarker: google.maps.LatLngLiteral | undefined;
  setShowInfo: (showInfo: boolean) => void;
  showInfo: boolean;
  controle: number;
};

export const Mapa: FC<Props> = ({
  currentZoom,
  setMap,
  setCenter,
  initialCenter,
  center,
  mainData,
  setSelectedMarker,
  setShowInfo,
  showInfo,
  selectedMarker,
  controle,
}) => {
  const createKey = (location: { lat: number | null; lng: number | null }, index: number) => {
    if (!location.lat || !location.lng) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return location.lat + location.lng + index;
  };

  return (
    <MapContainer>
      <LoadScript googleMapsApiKey={GOOGLE_API_KEY || ''}>
        <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={currentZoom || 4}
          onLoad={(m) => {
            setMap(m);
            setCenter(initialCenter);
          }}
          center={center}
        >
          <MarkerClusterer
            imagePath="../../../../assets/clusterers"
            averageCenter
            // gridSize={50}
            title="Click to zoom"
            maxZoom={13}
            minimumClusterSize={1}
          >
            {(clusterer) =>
              mainData.map((each, index) => {
                const location = {
                  lat: Number(each.latidude),
                  lng: Number(each.longitude),
                };
                const key = createKey(location, index);
                const truckImage = each.tipoViolacao ? RedTruck : GreenTruck;
                return (
                  <Marker
                    icon={truckImage}
                    opacity={0.6}
                    key={index}
                    position={location}
                    onClick={() => {
                      setSelectedMarker(location);
                      setShowInfo(true);
                    }}
                    clusterer={clusterer}
                  >
                    {showInfo && selectedMarker && createKey(selectedMarker, index) === key && (
                      <InfoWindow position={location} onCloseClick={() => setShowInfo(false)}>
                        <div>
                          <p>
                            <strong>Placa: </strong>
                            {each.placa}
                          </p>
                          {each.tipoViolacao ? (
                            <p>
                              <strong>Tipo de Violação: </strong>
                              {each.tipoViolacao}
                            </p>
                          ) : null}
                          <p>
                            <strong>Data: </strong>
                            {format(new Date(each.data), 'dd/MM HH:mm')}
                          </p>
                          {(controle === 0 || controle === 2) && each.temperatura && (
                            <p>
                              <strong>Temperatura: </strong>
                              {each.temperatura} C°
                            </p>
                          )}
                          {(controle === 1 || controle === 2) && (
                            <p>
                              <strong>Velocidade: </strong>
                              {each.velocidade} km/h
                            </p>
                          )}
                          {each.tipoViolacao ? (
                            <p>
                              <strong>Quantidade Violada: </strong>
                              {each.tipoViolacao === 'Velocidade'
                                ? `${each.qtd} km/h`
                                : `${each.qtd} C°`}
                            </p>
                          ) : null}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })
            }
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
    </MapContainer>
  );
};
