/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Form, Col, Button, Spinner, Modal } from 'react-bootstrap';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { ContainerPesquisarCondutores } from '../../components/ComponentPesquisarCondutores';
import { ContainerPesquisarAjudante } from '../../components/ComponentPesquisarAjudante';
import { ContainerPesquisarVeiculos } from '../../components/ComponentPesquisarVeiculos';
import { getCookieSessionData } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';
import { GoInfo } from 'react-icons/go';
import { nowToZoned } from '../../util/date';

export type ErrosDataPesquisa = {
  mensagemErro: string;
  listaErros: [
    {
      codigo: string;
      mensagem: string;
    }
  ];
};

type propsType = {
  canInsert: boolean;
};

export const EnviarPesquisa = ({ canInsert }: propsType) => {
  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;
  const [isAjudanteTabFocused, setIsAjudanteTabFocused] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadErro, setIsDialogCadErro] = useState(false);
  const [isTermoOK, setIsTermoOK] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [tipoPesquisa, setTipoPesquisa] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [dialogBlock, setDialogBlock] = useState(false);
  const [idPesquisaEnviada, setIdPesquisaEnviada] = useState();
  const [itensVigentes, setItensVigentes] = useState<string[]>([]);
  const [itensPesquisasEnviadas, setItensPesquisasEnviadas] = useState<string[]>([]);
  const [dialogConsultaOuPesquisa, setDialogConsultaOuPesquisa] = useState(false);
  const [habilitarPesquisaVigente, setHabilitarPesquisaVigente] = useState(false);
  const [habilitarPesquisaIntegrada, setHabilitarPesquisaIntegrada] = useState(false);
  const { id } = useParams<any>();

  const [dataErros, setDataErros] = useState<ErrosDataPesquisa | undefined>({
    mensagemErro: '',
    listaErros: [
      {
        codigo: '',
        mensagem: '',
      },
    ],
  });

  const history = useHistory();
  const ajudanteRef = useRef<{
    validation: () => boolean;
    ajudante: {
      nome?: string;
      idAjudante: number;
      cpf: string;
      bloqueado: boolean;
      sexo: string;
    };
  } | null>(null);

  const condutorRef = useRef<{
    validation: () => boolean;
    condutor: {
      validadeCnh?: string;
      id: number;
      nome?: string;
      cpf: string;
      bloqueado: boolean;
      sexo: string;
    };
  } | null>(null);

  const veiculosRef = useRef<{
    validation: () => boolean;
    veiculo: {
      id: number;
      placa?: string;
      modelo?: string;
      bloqueado: boolean;
    };
    carreta1: {
      id: number;
      placa?: string;
      bloqueado: boolean;
    };
    carreta2: {
      id: number;
      placa?: string;
      bloqueado: boolean;
    };
  } | null>(null);

  const disabled = !habilitarPesquisaIntegrada && !!id;

  useEffect(() => {
    const getParticularidades = async () => {
      try {
        const response = await axios.get(`/cliente/getParticularidadesCliente`, {
          params: {
            cliente: clienteId,
          },
        });
        setHabilitarPesquisaVigente(response.data.flagHabilitaPesquisasConsultasVigentes);
        setHabilitarPesquisaIntegrada(response.data.flagHabilitaPesquisaIntegrada);
        if (response.data.flagHabilitaPesquisaIntegrada) {
          history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
        }
      } catch (err: any) {
        console.log(err);
      }
    };
    getParticularidades();
  }, []);

  const validaPesquisaVigente = async (data: any) => {
    try {
      const response = await axios.get(
        `/pesquisa/verificar-vigencia?dataInicio=${nowToZoned()}&idCliente=${clienteId}&${
          data.type
        }=${data.id}`
      );
      if (response.data === 'Pode enviar pesquisa' || response.data === 'pode enviar') {
        //pode enviar pesquisa
        return false;
      } else {
        // Nao pode enviar pesquisa
        return true;
      }
    } catch {
      // Erro ao consultar vigencia
      return false;
    }
  };

  const checaBlacklist = async () => {
    try {
      if (
        ajudanteRef.current?.ajudante.cpf &&
        ajudanteRef.current?.ajudante.nome &&
        isAjudanteTabFocused === true
      ) {
        const response = await axios.get(
          `/blacklist/verificarCpf?cpf=${ajudanteRef.current?.ajudante.cpf}`
        );
        return response.data
          ? `Ajudante ${ajudanteRef.current?.ajudante.nome} encontra-se ${
              ajudanteRef.current?.ajudante.sexo === 'F' ? 'bloqueada' : 'bloqueado'
            }. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02).`
          : 'não consta';
      }
      if (
        condutorRef.current?.condutor.cpf &&
        condutorRef.current?.condutor.nome &&
        isAjudanteTabFocused === false
      ) {
        const response = await axios.get(
          `/blacklist/verificarCpf?cpf=${condutorRef.current?.condutor.cpf}`
        );
        return response.data
          ? `${condutorRef.current?.condutor.sexo === 'F' ? 'Condutora ' : 'Condutor '} ${
              condutorRef.current?.condutor.nome
            } encontra-se ${
              condutorRef.current?.condutor.sexo === 'F' ? 'bloqueada' : 'bloqueado'
            }. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02).`
          : 'não consta';
      } else {
        return 'não consta';
      }
    } catch (error) {
      console.log(error);
      return 'não consta';
    }
  };

  const checaVigencia = async () => {
    const vigentes = [];
    const itensEnviados = [];

    if (condutorRef.current?.condutor && !isAjudanteTabFocused) {
      const vigente = await validaPesquisaVigente({
        type: 'idCondutor',
        id: condutorRef.current?.condutor.id,
      });
      if (vigente === true) {
        vigentes.push(
          condutorRef.current?.condutor != undefined
            ? `${condutorRef.current?.condutor.nome} ${condutorRef.current?.condutor.cpf}`
            : ''
        );
      }
      itensEnviados.push(
        condutorRef.current?.condutor != undefined
          ? `${condutorRef.current?.condutor.nome} ${condutorRef.current?.condutor.cpf}`
          : ''
      );
    } else if (ajudanteRef.current?.ajudante && isAjudanteTabFocused) {
      const vigente = await validaPesquisaVigente({
        type: 'idAjudante',
        id: ajudanteRef.current.ajudante.idAjudante,
      });
      if (vigente === true) {
        vigentes.push(
          ajudanteRef.current?.ajudante.nome != undefined
            ? `${ajudanteRef.current?.ajudante.nome} ${ajudanteRef.current?.ajudante.cpf}`
            : ''
        );
      }
      itensEnviados.push(
        ajudanteRef.current?.ajudante.nome != undefined
          ? `${ajudanteRef.current?.ajudante.nome} ${ajudanteRef.current?.ajudante.cpf}`
          : ''
      );
    }
    if (veiculosRef.current?.veiculo) {
      const vigente = await validaPesquisaVigente({
        type: 'idVeiculo',
        id: veiculosRef.current?.veiculo.id,
      });
      if (vigente === true) {
        vigentes.push(
          veiculosRef.current?.veiculo.placa != undefined
            ? `${veiculosRef.current?.veiculo.placa} ${veiculosRef.current?.veiculo.modelo}`
            : ''
        );
      }
      itensEnviados.push(
        veiculosRef.current?.veiculo.placa != undefined
          ? `${veiculosRef.current?.veiculo.placa} ${veiculosRef.current?.veiculo.modelo}`
          : ''
      );
    }
    if (veiculosRef.current?.carreta1) {
      const vigente = await validaPesquisaVigente({
        type: 'idCarreta',
        id: veiculosRef.current?.carreta1.id,
      });
      if (vigente === true) {
        vigentes.push(
          veiculosRef.current?.carreta1.placa != undefined
            ? veiculosRef.current?.carreta1.placa
            : ''
        );
      }
      itensEnviados.push(
        veiculosRef.current?.carreta1.placa != undefined ? veiculosRef.current?.carreta1.placa : ''
      );
    }
    if (veiculosRef.current?.carreta2) {
      const vigente = await validaPesquisaVigente({
        type: 'idCarreta2',
        id: veiculosRef.current?.carreta2.id,
      });
      if (vigente === true) {
        vigentes.push(
          veiculosRef.current?.carreta2.placa != undefined
            ? veiculosRef.current?.carreta2.placa
            : ''
        );
      }
      itensEnviados.push(
        veiculosRef.current?.carreta2.placa != undefined ? veiculosRef.current?.carreta2.placa : ''
      );
    }
    setItensVigentes(vigentes);
    setItensPesquisasEnviadas(itensEnviados);
    return vigentes;
  };

  const handleSubmit = async (event: any, novaPesquisa?: boolean) => {
    event.preventDefault();

    // PESQUISA AJUDANTE
    if (isAjudanteTabFocused) {
      handleRequest(
        {
          ajudante: ajudanteRef.current?.ajudante,
          idCliente: clienteId,
          idUsuario: usuarioId,
          cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
        },
        'este ajudante'
      );
    }
    // PESQUISA NÃO AJUDANTE
    else {
      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }

      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo === null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }

      if (
        condutorRef.current?.condutor === null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }

      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }

      if (
        veiculosRef.current?.veiculo != null &&
        condutorRef.current?.condutor === null &&
        veiculosRef.current?.carreta1 === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            veiculo: veiculosRef.current?.veiculo,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'este veículo'
        );
      }
      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo === null &&
        veiculosRef.current?.carreta1 === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'este condutor'
        );
      }
      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo === null &&
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }

      if (
        condutorRef.current?.condutor != null &&
        veiculosRef.current?.veiculo != null &&
        veiculosRef.current?.carreta1 === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            condutor: condutorRef.current?.condutor,
            veiculo: veiculosRef.current?.veiculo,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }
      if (
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.veiculo === null &&
        condutorRef.current?.condutor === null &&
        veiculosRef.current?.carreta2 === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'esta carreta'
        );
      }
      if (
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 != null &&
        veiculosRef.current?.veiculo === null &&
        condutorRef.current?.condutor === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            carreta1: veiculosRef.current?.carreta1,
            carreta2: veiculosRef.current?.carreta2,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }
      if (
        veiculosRef.current?.carreta1 != null &&
        veiculosRef.current?.carreta2 === null &&
        veiculosRef.current?.veiculo != null &&
        condutorRef.current?.condutor === null
      ) {
        handleRequest(
          {
            idCliente: clienteId,
            idUsuario: usuarioId,
            veiculo: veiculosRef.current?.veiculo,
            carreta1: veiculosRef.current?.carreta1,
            cadastrarPesquisaNova: novaPesquisa ? novaPesquisa : false,
          },
          'um item ou mais'
        );
      }
    }
    setIsAjudanteTabFocused(false);
  };

  const handleClick = async (event: any) => {
    if (isAjudanteTabFocused) {
      if (!ajudanteRef.current?.ajudante) {
        addToast({
          title: 'Erro',
          description: 'Selecione um Ajudante/Funcionário',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
      if (!ajudanteRef.current.validation()) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os campos obrigatórios.',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    } else {
      if (!condutorRef.current || !veiculosRef.current) return;
      if (!veiculosRef.current.validation() || !condutorRef.current.validation()) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os campos obrigatórios.',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }
    if (condutorRef.current?.condutor?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(
        `${condutorRef.current?.condutor.sexo === 'F' ? 'Condutora ' : 'Condutor '} ${
          condutorRef.current?.condutor.nome
        } está ${condutorRef.current?.condutor.sexo === 'F' ? 'bloqueada' : 'bloqueado'}.`
      );
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (ajudanteRef.current?.ajudante?.bloqueado && isAjudanteTabFocused) {
      setMensagem(
        `Ajudante ${ajudanteRef.current?.ajudante.nome} está ${
          ajudanteRef.current?.ajudante.sexo === 'F' ? 'bloqueada' : 'bloqueado'
        }.`
      );
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (veiculosRef.current?.veiculo?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(`Veículo ${veiculosRef.current?.veiculo.placa} está bloqueado.`);
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (veiculosRef.current?.carreta1?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(`Carreta(1) ${veiculosRef.current?.carreta1.placa} está bloqueada.`);
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    if (veiculosRef.current?.carreta2?.bloqueado && !isAjudanteTabFocused) {
      setMensagem(`Carreta(2) ${veiculosRef.current?.carreta2.placa} está bloqueada.`);
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    const constaBlacklist = await checaBlacklist();
    if (!constaBlacklist?.includes('não consta')) {
      setMensagem(constaBlacklist ? constaBlacklist : '');
      setDialogBlock(true);
      event.stopPropagation();
      return;
    }
    const vigentes = await checaVigencia();
    if (!vigentes.length) {
      setTipoPesquisa('Pesquisa');
      handleSubmit(event, false);
    } else {
      setDialogConsultaOuPesquisa(true);
    }
  };

  const handleRequest = async (data: any, message: string) => {
    setLoading(true);

    try {
      const response = await axios.post('/pesquisa/integrada', {
        ...data,
        pesquisaManual: false,
      });
      if (!response.data.mensagem?.includes('sucesso')) {
        setLoading(false);
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: response.data.mensagem?.includes('processamento')
            ? `já existe uma pesquisa em andamento para ${message}`
            : response.data.mensagem ||
              response.data.mensagemVeiculo ||
              response.data.mensagemCarreta ||
              response.data.mensagemCarreta2 ||
              response.data.mensagemCondutor ||
              response.data.mensagemAjudante,
          listaErros:
            response.data.errosVeiculo ||
            response.data.errosCarreta ||
            response.data.errosCarreta2 ||
            response.data.errosCondutor ||
            response.data.errosAjudante,
        });
      } else {
        if (
          response.data.mensagem?.includes('Consulta') ||
          response.data.mensagemAjudante?.includes('Consulta') ||
          response.data.mensagemCondutor?.includes('Consulta') ||
          response.data.mensagemVeiculo?.includes('Consulta') ||
          response.data.mensagemCarreta?.includes('Consulta') ||
          response.data.mensagemCarreta2?.includes('Consulta')
        ) {
          setTipoPesquisa('Consulta');
        } else {
          setTipoPesquisa('Pesquisa');
        }
        setIdPesquisaEnviada(response.data.codPesquisaIntegrada);
        setIsDialogCadOK(true);
      }
    } catch (error: any) {
      if (
        error.response.data.message?.includes('processamento') ||
        error.response.data.message?.includes('Constam resultados')
      ) {
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: `já existe uma pesquisa em andamento para ${message}`,
          listaErros: null as any,
        });
      } else {
        const message = error.response.data.message?.split('[')[2].split(']')[0];
        const codigo = message.split(',')[0].split('=')[1];
        const msg = message.split(',')[1].split('=')[1];

        addToast({
          title: 'Erro!',
          description: error.response.data.message.includes('DminerErroPesquisaVO')
            ? `Atenção: "${msg}"`
            : error.response.data.message,
          type: 'error',
        });
      }
    } finally {
      if (habilitarPesquisaIntegrada) {
        history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
      } else {
        history.replace('/enviar-pesquisa/condutor');
      }
      setLoading(false);
      return;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={
          <>
            <p>{tipoPesquisa} enviada com sucesso para:</p>
            {itensPesquisasEnviadas ? (
              <>
                {itensPesquisasEnviadas.map((each: string) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Número de liberação: {idPesquisaEnviada}</p>
            <Button
              onClick={() => {
                setIsDialogCadOK(false);
              }}
            >
              OK
            </Button>
          </div>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />
      <Dialog
        header={
          <>
            <p>{mensagem}</p>
          </>
        }
        footer={
          <Button
            onClick={() => {
              setDialogBlock(false);
              setMensagem('');
            }}
          >
            OK
          </Button>
        }
        visible={dialogBlock}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setDialogBlock(false);
          setMensagem('');
        }}
      />

      <Dialog
        header={
          <>
            {dataErros?.mensagemErro === null ? (
              <p>{`Pesquisa não enviada. `}</p>
            ) : (
              <p>{`Não foi possível enviar pesquisa, pois ${dataErros?.mensagemErro}. `}</p>
            )}
            {dataErros?.listaErros ? (
              <>
                {dataErros.listaErros.map((each) => (
                  <li style={{ fontSize: '15px' }}>{each.mensagem}</li>
                ))}
              </>
            ) : null}
          </>
        }
        closable={false}
        footer={
          <>
            <Button
              onClick={() => {
                setIsDialogCadErro(false);
              }}
            >
              OK
            </Button>
          </>
        }
        visible={isDialogCadErro}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />

      <Dialog
        header={
          <div>
            <p>Já existe pesquisa vigente para:</p>
            <br />
            {itensVigentes.map((each: string) => (
              <li>{each}</li>
            ))}
            <br />
          </div>
        }
        closable={false}
        footer={
          <>
            {habilitarPesquisaVigente ? (
              <>
                <p>O que deseja fazer?</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ color: 'black', fontSize: '12px' }}
                    onClick={(e: any) => {
                      setTipoPesquisa('Pesquisa');
                      handleSubmit(e, true);
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Nova Pesquisa
                  </Button>
                  <Button
                    style={{ color: 'black', fontSize: '12px' }}
                    onClick={(e: any) => {
                      setTipoPesquisa('Consulta');
                      handleSubmit(e, false);
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Consulta
                  </Button>
                  <Button
                    style={{
                      background: 'white',
                      border: '1px solid black',
                      color: 'black',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{
                    background: 'white',
                    border: '1px solid black',
                    color: 'black',
                    fontSize: '11px',
                  }}
                  onClick={() => {
                    setDialogConsultaOuPesquisa(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </>
        }
        visible={dialogConsultaOuPesquisa}
        style={{ width: '40vw' }}
        modal
        onHide={() => {
          setDialogConsultaOuPesquisa(false);
        }}
      />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Enviar Pesquisa</h2>
        </div>

        {isTermoOK === false ? (
          <>
            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>{' '}
            <p style={{ textAlign: 'justify' }}>
              Prezado Cliente, ATENÇÃO! A gestão do uso do nosso serviço de pesquisas é de inteira
              responsabilidade do cliente (usuário), ou seja, a vigência de cada pesquisa, a correta
              configuração do tipo de pesquisa, ou do tipo de item (Funcionário, Agregado, Autônomo,
              Frota Fixa, Veículo de Agregado, Veículo de Terceiro), e principalmente o controle das
              ATUALIZAÇÕES AUTOMÁTICAS, são única e exclusivamente de responsabilidade do SEGURADO.
              Em casos de dúvidas, nossa equipe técnica está inteiramente à disposição para auxiliar
              através do telefone (21)3644-7000.
            </p>{' '}
            <Form.Group>
              <Form.Check
                checked={isTermoOK}
                onChange={(event) => {
                  setIsTermoOK(!isTermoOK);
                }}
                defaultChecked={!isTermoOK}
                type="checkbox"
                style={{ color: 'red' }}
                id="exampleCheck1"
                label="Li e concordo com os termos."
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>
          </>
        ) : (
          <>
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <div className="painel">
                {disabled ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p>Pesquisa Integrada desativada.</p>

                    <Tooltip target=".info">
                      As Pesquisas Integradas estão desativadas para este cliente. Limpe o item
                      existente para liberar as outras opções de pesquisa.
                    </Tooltip>
                    <button
                      className="info"
                      style={{
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        height: 20,
                        display: 'flex',
                      }}
                      type="button"
                    >
                      <GoInfo color={'#737373'} size="13px" />
                    </button>
                  </div>
                ) : null}
                {habilitarPesquisaIntegrada ? (
                  <>
                    <Tabs defaultActiveKey="pesquisaIntegrada" id="uncontrolled-tab-example">
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
                        }}
                        eventKey="pesquisaIntegrada"
                        title="Pesquisar Condutor / Veiculo / Carreta"
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarCondutores ref={condutorRef} />
                          </form>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarVeiculos ref={veiculosRef} />
                          </form>
                        </div>
                      </Tab>
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/ajudante');
                          setIsAjudanteTabFocused(true);
                        }}
                        eventKey="pesquisaFuncionario"
                        title="Pesquisar Ajudante/Funcionário (Não Motorista)"
                        onExit={() => setIsAjudanteTabFocused(false)}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form>
                            <ContainerPesquisarAjudante ref={ajudanteRef} />
                          </form>
                        </div>
                      </Tab>
                    </Tabs>
                    <hr />
                    <Form.Row className="container-buttons justify-content-end">
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-cancelar"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Cancelar
                        </Button>
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-enviar"
                          onClick={(event: any) => {
                            if (canInsert) {
                              handleClick(event);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                        >
                          Confirmar
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </>
                ) : (
                  <>
                    <Tabs defaultActiveKey="pesquisaCondutores" id="uncontrolled-tab-example">
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/condutor');
                        }}
                        eventKey="pesquisaCondutores"
                        title="Pesquisar Condutores"
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarCondutores ref={condutorRef} />
                          </form>
                        </div>
                      </Tab>
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/veiculo');
                        }}
                        eventKey="pesquisaVeiculos"
                        title="Pesquisar Veículos"
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarVeiculos ref={veiculosRef} />
                          </form>
                        </div>
                      </Tab>
                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/carreta');
                        }}
                        eventKey="pesquisaCarretas"
                        title="Pesquisar Carretas"
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form onSubmit={handleClick}>
                            <ContainerPesquisarVeiculos ref={veiculosRef} />
                          </form>
                        </div>
                      </Tab>

                      <Tab
                        onEnter={() => {
                          history.replace('/enviar-pesquisa/ajudante');
                          setIsAjudanteTabFocused(true);
                        }}
                        eventKey="pesquisaFuncionario"
                        title="Pesquisar Ajudante/Funcionário (Não Motorista)"
                        onExit={() => setIsAjudanteTabFocused(false)}
                        disabled={disabled}
                      >
                        <div style={{ marginTop: 12 }}>
                          <form>
                            <ContainerPesquisarAjudante ref={ajudanteRef} />
                          </form>
                        </div>
                      </Tab>
                    </Tabs>
                    <hr />
                    <Form.Row className="container-buttons justify-content-end">
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-cancelar"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Cancelar
                        </Button>
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Button
                          className="btn-enviar"
                          onClick={(event: any) => {
                            if (canInsert) {
                              handleClick(event);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                        >
                          Confirmar
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
