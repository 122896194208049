/* eslint-disable */
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Col, Button, Navbar, InputGroup } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { validaEmail, validaSenha } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData } from '../../services/cookieService';
import { Spinner } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import { MenuPerfilType } from '../../Types/MenuPerfil';
import orderBy from 'lodash/orderBy';
import { Tela } from '../../components/Tela';
import { dataConverter } from '../../util/date';

function CadastroUsuarioGerenciador() {
  const history = useHistory();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [senhaValida, setSenhaValida] = useState(true);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidNome, setIsValidNome] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidSenha, setIsValidSenha] = useState(true);
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [validacaoLogin, setValidacaoLogin] = useState(false);
  const [validacaoEmail, setValidacaoEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmaSenha, setConfirmaSenha] = useState('');
  const [listaMenuPerfil, setListaMenuPerfil] = useState<MenuPerfilType[]>([]);
  const [selectedMenuPerfil, setSelectedMenuPerfil] = useState<number>();
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const location: { state: string } = useLocation();
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  const [isDialogRecuperarDados, setIsDialogRecuperarDados] = useState(false);
  const [idUsuarioRecuperado, setIdUsuarioRecuperado] = useState();
  const recuperarDadosUsuarioExistente = async () => {
    try {
      const response = await axios.get(`/usuario/reutilizar-usuario?login=${login}`);
      if (response.data.ativo === false) {
        setIsDialogRecuperarDados(true);
        setIdUsuarioRecuperado(response.data.usuCdId);
      } else {
        setIsDialogRecuperarDados(false);
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const validaLoginExistente = async () => {
    try {
      const response = await axios.get(`/usuario/validacaoCriacaoLogin`, {
        params: {
          login: login,
        },
      });
      if (response.data === true) {
        setValidacaoLogin(true);
        addToast({
          title: 'Erro',
          description: 'Login já existe!',
          type: 'error',
        });
        return;
      } else {
        setValidacaoLogin(false);
        setLogin(login);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const buscaListaMenuPerfis = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/menu-perfil/list/`);
      setListaMenuPerfil(orderBy(response.data, 'nomeMenu'));
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    buscaListaMenuPerfis();
  }, []);

  const handleRegister = async (event: any) => {
    event.preventDefault();

    if (
      !selectedMenuPerfil ||
      !nome ||
      !senha ||
      !login ||
      !confirmaSenha ||
      !isValidEmail ||
      !isValidLogin ||
      !isValidNome ||
      !isValidSenha
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      // alert('Verifique os dados preenchidos.');
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const data = {
      login: login.toLowerCase(),
      senha: senha,
      email: email,
      nome: nome,
      idMenuPerfil: selectedMenuPerfil,
      usuResponsavelCadastro: usuarioId,
      usuDtHrUltimaAlteracao: null,
      usuDtHrCadastro: dataConverter(new Date().toISOString()),
      access_token: getCookieSessionData().access_token,
      role: 'admin',
    };

    try {
      setLoading(true);
      if (senhaValida == true && validacaoLogin == false && validacaoEmail == false) {
        await axios.post('/usuario', data);
        setIsDialogCadOK(true);
        setLoading(false);
      } else {
        if (senhaValida == false) {
          addToast({
            title: 'Erro',
            description: 'Senhas diferentes!',
            type: 'error',
          });

          setLoading(false);
          return;
        }
        if (validacaoLogin == true) {
          addToast({
            title: 'Erro',
            description: 'Login já existe',
            type: 'error',
          });

          setLoading(false);
          return;
        } else {
          addToast({
            title: 'Erro',
            description: 'E-mail já existe.',
            type: 'error',
          });

          setLoading(false);
          return;
        }
      }
    } catch (error: any) {
      if (error.response.data.message) {
        addToast({
          title: 'Erro',
          description: error.response.data.message,
          type: 'error',
        });
      } else {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      }

      setLoading(false);
      return;
    }
  };

  return (
    <Tela
      nome="Usuário"
      caminho="Cadastro > Usuário Gerenciador"
      loading={loading}
      setLoading={setLoading}
    >
      <Dialog
        header="Usuário cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-gerenciadores/1');
        }}
      />
      <Dialog
        header="Usuário inativo. Deseja recuperar dados?"
        footer={
          <>
            <Button
              style={{ width: 120 }}
              onClick={() =>
                history.push({
                  pathname: `/editar-usuario-gerenciador/${idUsuarioRecuperado}`,
                  state: login,
                })
              }
            >
              Sim
            </Button>
            <Button style={{ width: 120 }} onClick={() => setIsDialogRecuperarDados(false)}>
              Não
            </Button>
          </>
        }
        visible={isDialogRecuperarDados}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogRecuperarDados(false);
        }}
      />

      <Form noValidate validated={isFormInvalid} onSubmit={handleRegister}>
        <Form.Group
          as={Col}
          controlId="formGridAddress1"
          style={{ marginLeft: '-14px', marginTop: '2%' }}
        >
          <Form.Label className="required">Login</Form.Label>
          <Form.Control
            value={login}
            onChange={(event) => {
              setLogin(event.target.value);
              if (login.length < 2) {
                setIsValidLogin(false);
              }
              if (login.length >= 2 || login.length === 0) {
                setIsValidLogin(true);
              }
            }}
            onBlur={() => {
              recuperarDadosUsuarioExistente();
              validaLoginExistente();
            }}
            required
            style={{ width: '50.6%' }}
            minLength={3}
            maxLength={100}
            // ref={ref}
          />
          {!isValidLogin && (
            <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
              Login inválido, digite 3 caracteres ou mais
            </p>
          )}
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Nome</Form.Label>

            <Form.Control
              value={nome}
              onChange={(event) => {
                event.preventDefault();
                setNome(event.target.value);
                if (nome.length < 2) {
                  setIsValidNome(false);
                }
                if (nome.length >= 2 || nome.length === 0) {
                  setIsValidNome(true);
                }
              }}
              required
              minLength={3}
              maxLength={100}
            />
            {!isValidNome && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Perfil</Form.Label>
            <Form.Control
              value={selectedMenuPerfil}
              onChange={(event) => {
                setSelectedMenuPerfil(parseInt(event.target.value, 10));
              }}
              required
              as="select"
            >
              <option value="">Selecione uma opção</option>
              {listaMenuPerfil &&
                listaMenuPerfil.map((each: MenuPerfilType) => (
                  <option value={each.id}>{each.nomeMenu}</option>
                ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Group as={Col} controlId="formGridEmail" style={{ marginLeft: '-14px' }}>
          <Form.Label>Email</Form.Label>

          <Form.Control
            value={email}
            onChange={(event) => {
              event.preventDefault();
              setEmail(event.target.value);
              if (validaEmail(event.target.value) || event.target.value.trim() === '') {
                setIsValidEmail(true);
              } else {
                setIsValidEmail(false);
              }
            }}
            // onBlur={() => validaEmailExistente()}
            style={{ width: '50.6%' }}
            type="email"
            // ref={ref2}
            maxLength={100}
          />
          {!isValidEmail && (
            <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
          )}
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={senha}
                onChange={(event) => {
                  event.preventDefault();
                  setSenha(event.target.value);
                  if (validaSenha(event.target.value)) {
                    setIsValidSenha(true);
                  } else {
                    setIsValidSenha(false);
                  }
                }}
                required
                type={passwordShown ? 'text' : 'password'}
                maxLength={8}
                minLength={8}
                style={{ borderRightColor: 'white' }}
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
                title="A senha deve conter 8 caracteres e pelo menos uma letra "
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {!isValidSenha && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Senha inválida, deve conter 8 dígitos e pelo menos uma letra
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Confirme a senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={confirmaSenha}
                onChange={(event: any) => {
                  event.preventDefault();
                  setConfirmaSenha(event.target.value);
                  if (event.target.value === senha) {
                    setSenhaValida(true);
                  } else {
                    setSenhaValida(false);
                  }
                }}
                required
                type={passwordShown2 ? 'text' : 'password'}
                maxLength={8}
                minLength={8}
                style={{ borderRightColor: 'white' }}
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown2 ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {senhaValida == false && senha.length === 8 && (
              <p style={{ fontSize: '12px', color: 'red' }}>Senhas diferentes</p>
            )}
          </Form.Group>
        </Form.Row>

        {loading ? (
          <div style={{ marginTop: 30 }}>
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
            <p style={{ textAlign: 'center', marginTop: '-40px' }}>
              Aguarde enquanto o cadastro está sendo concluído...
            </p>
            <ButtonsForm />
          </div>
        ) : (
          <div style={{ marginTop: 30 }}>
            <ButtonsForm />
          </div>
        )}
      </Form>
    </Tela>
  );
}

export default CadastroUsuarioGerenciador;
