const getTipoFrota = (tipoFrota: string | null): string => {
  if (tipoFrota === '1') {
    return 'PROPRIO';
  }
  if (tipoFrota === '2') {
    return 'AGREGADO';
  }
  if (tipoFrota === '3') {
    return 'TERCEIRO';
  }
  return '';
};
export default getTipoFrota;
