/* eslint-disable */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from '../../assets/logo_golden.jpg';

const GeneratePDF = (dadosTabela: any, placaVeiculo: any, periodo: string, role: string) => {
  const doc = new jsPDF('l', 'mm', 'a4');

  const tableColumn = [
    'Referência',
    'Coordenada',
    'Data',
    'Hora',
    'Ignição',
    'Baú',
    'Velocidade',
    'Temperatura',
  ];

  if (role === 'admin') {
    tableColumn.push('Odômetro');
  }

  const tableRows: any = [];

  let end: boolean = false;

  // called date-fns to format the date on the dado
  // format(new Date(dado.updated_at), "yyyy-MM-dd")

  const renderCoordenada = (data: string) => {
    let lat = Number(data.split('  ')[0]).toFixed(4);
    let lng = Number(data.split('  ')[1]).toFixed(4);
    let string = `${lat};  ${lng}`;
    return string.replaceAll('.', ',');
  };

  dadosTabela.forEach((value: any, index: any) => {
    for (let j = 0; j < value.length; j++) {
      const tableData = [
        value[j].referencia,
        renderCoordenada(value[j].coordenada),
        value[j].data,
        value[j].hora,
        value[j].ignicao,
        value[j].bau,
        `${value[j].velocidade} km/h`,
        value[j].temperatura ? `${value[j].temperatura} ºC` : '',
        `${role === 'admin' && value[j].odometro !== null ? value[j].odometro : ''}`,
      ];
      tableRows.push(tableData);
    }
    if (dadosTabela.length === index + 1) end = true;
    page();
    tableRows.length = 0;
  });

  function page() {
    // @ts-ignore
    doc.autoTable(tableColumn, tableRows, {
      startY: 50,
      theme: 'plain',

      styles: {
        overflow: 'linebreak',
        fontSize: 12,
        valign: 'middle',
      },
      columnStyles: {
        0: { valign: 'top', halign: 'left' },
      },
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [204, 204, 204],
      },
      headerStyles: {
        lineWidth: 0.2,
        lineColor: [204, 204, 204],
      },
    });

    doc.addImage(Logo, 'jpg', 10, 10, 80, 20);

    doc.setFontSize(12);
    doc.text(`Página ${doc.internal.getNumberOfPages()}/${dadosTabela.length}`, 260, 30);
    doc.text(`${new Date().toLocaleDateString('pt-BR')}`, 260, 35);
    doc.line(14, 38, 283, 38);
    doc.setFontSize(15);
    doc.text('HISTÓRICO DE POSIÇÕES', 115, 35);

    doc.setFontSize(10);
    doc.text(`VEICULO: ${placaVeiculo}`, 15, 45);
    doc.setFontSize(10);
    doc.text(periodo, 229, 45);

    if (!end) doc.addPage();
  }
  const date = new Date().toLocaleDateString('pt-BR').split(' ');
  const dateStr = date[0];

  doc.save(`historico_posicoes_${dateStr}.pdf`);
};

export default GeneratePDF;
