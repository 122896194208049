import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { toCurrency } from '../../../util/Validacoes/Validacoes';
import { getCookieSessionData } from '../../../services/cookieService';
import { Tela } from '../../../components/Tela';
import { Tabela } from '../../../components/Tabela';
import { Spinner } from '../../../components/Spinner';
import { pesquisar } from '../../../util/format';

type RankingAjudanteFuncionarioType = {
  fraCdId: number;
  quantidadeViagemRealizadaInicio: number;
  quantidadeViagemRealizadaFim: number;
  valorMedioPermitidoTransporteInicio: number;
  valorMedioPermitidoTransporteFim: number;
  valorPermitidoTransporte: number;
  idCliente: number;
};

type Props = {
  canInsert: boolean;
};

const AjudanteFuncionarioList: React.FC<Props> = ({ canInsert }) => {
  const history = useHistory();
  const { page }: any = useParams();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [countData, setCountData] = useState(0);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [historicosPerPage, setHistoricosPerPage] = useState(Number(itensPerPage));
  const indexOfLastPost = page * historicosPerPage;
  const indexOfFirstPost = indexOfLastPost - historicosPerPage;
  const titles = [
    '+id',
    'Qtde. Viagens Realizadas - Valor Inicial',
    'Qtde. Viagens Realizadas - Valor Final',
    'Valor Médio Transportado - Valor Inicial',
    'Valor Médio Transportado - Valor Final',
    'Valor Permitido para Transporte',
  ];
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/ranking/ajudante-funcionario/listar/${pageNumber}`);
  };

  const converteArray = (dt: any[]) => {
    console.log(dt);
    const array = dt.map((each: any) => {
      return [
        each.fraCdId,
        each.quantidadeViagemRealizadaInicio,
        each.quantidadeViagemRealizadaFim,
        toCurrency(each.valorMedioPermitidoTransporteInicio),
        toCurrency(each.valorMedioPermitidoTransporteFim),
        toCurrency(each.valorPermitidoTransporte),
      ];
    });
    return array;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (!countData) {
        const response = await axios.get('/faixa-ranking-ajudante-funcionario/count', {
          params: {
            idCliente: clienteId,
          },
        });
        setCountData(response.data);
      }
      const response = await axios.get(`/faixa-ranking-ajudante-funcionario/find-all`, {
        params: {
          idCliente: clienteId,
        },
      });
      setData(converteArray(response.data));
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/ranking/ajudante-funcionario/listar/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Ranking de Ajudante/Funcionário"
      caminho="Cadastro > Ranking de Ajudante/Funcionário"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/ranking/ajudante-funcionario/cadastrar"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/ranking/ajudante-funcionario/editar/${id}`)}
        />
      )}
    </Tela>
  );
};

export default AjudanteFuncionarioList;
