import { uniqBy } from 'lodash';
import { Form, Col, ProgressBar } from 'react-bootstrap';
import { EachCritica, EachCriticaType } from '../../Types/SmType';
import { LinhaForm } from '../LinhaForm';
import { InvalidInput } from './styles';

export const renderCriticaChecklist = (tipoVeiculo: string) => {
  return (
    <>
      <LinhaForm />
      <Form.Row className="justify-content-md-center">
        <Form.Group controlId="formGridAlertaChecklistCarreta2">
          <Form.Label className="txt_alerta">
            Checklist inexistente para este(a) {tipoVeiculo}.
            <br />
            Esta viagem não é recomendada pela Golden Service!
          </Form.Label>
        </Form.Group>
      </Form.Row>
    </>
  );
};

export const renderCriticaSat = (tipoVeiculo: string) => {
  return (
    <>
      <LinhaForm />
      <Form.Row className="justify-content-md-center">
        <Form.Group controlId="formGridAlertaChecklistCarreta2">
          <Form.Label className="txt_alerta">
            Localizamos uma Solicitação de Assistência Técnica em aberto para este(a) {tipoVeiculo}.
            <br />
            Esta viagem não é recomendada pela Golden Service!
          </Form.Label>
        </Form.Group>
      </Form.Row>
    </>
  );
};

type PropsType = {
  message: EachCriticaType;
  // title?: string;
};

export const Invalid = ({ message }: PropsType) => {
  if (!message) {
    return null;
  }
  return (
    <>
      <InvalidInput title={message.pesquisa}>{message.pesquisa}</InvalidInput>
    </>
  );
};
