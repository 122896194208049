import React, { memo, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router';
import { Botao } from '../../GrupoVeiculo/Modal/styles';
import axios from '../../../config/axiosMaquina';
import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import { soNumeros, soNumerosNegativo } from '../../../util/Validacoes/Validacoes';
import { useToast } from '../../../hooks/Toast';

type Props = {
  veiculo: VeiculoTypeGrid;
  loadVeiculos: () => void;
  clienteId: number;
  canEdit: boolean;
};

const ModalTemperaturaVeiculo = ({ veiculo, loadVeiculos, clienteId, canEdit }: Props) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [temperatura, setTemperatura] = useState({ maxima: '', minima: '' });
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [isDialogVisibleCadNaoEfetuado, setIsDialogVisibleCadNaoEfetuado] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (veiculo.temperaturaMaximaVeiculo && veiculo.temperaturaMinimaVeiculo) {
      setTemperatura({
        maxima: veiculo.temperaturaMaximaVeiculo.toString(),
        minima: veiculo.temperaturaMinimaVeiculo.toString(),
      });
    }
  }, [show]);

  const submit = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `/veiculo/atualizar-temperatura?idCliente=${clienteId}&placa=${veiculo.placa}&temperaturaMaxima=${temperatura.maxima}&temperaturaMinima=${temperatura.minima}`
      );
      addToast({
        title: 'Atualizado',
        description: 'Temperatura atualizada com sucesso!',
        type: 'success',
      });
    } catch (err) {
      console.error();
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar',
        type: 'error',
      });
    } finally {
      setLoading(false);
      loadVeiculos();
      setShow(false);
    }
  };

  return (
    <>
      <button
        type="button"
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
        onClick={() => {
          handleShow();
        }}
      >
        <i style={{ color: '#000' }} className="pi pi-pencil" />
      </button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header style={{ alignSelf: 'center' }}>
          <h2 className="titulo">Editar Temperatura</h2>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center', width: '90%' }}>
          <Dialog
            header="Grupo de veículos foi cadastrado com sucesso!"
            footer={
              <>
                <Button onClick={() => history.go(0)}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />
          <Dialog
            header="Erro ao cadastrar, verifique os campos!"
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadNaoEfetuado(false)}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadNaoEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadNaoEfetuado(false)}
          />
          {loading ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <Form noValidate validated={validated}>
              <Form.Row>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Marca</Form.Label>
                  <Form.Control value={veiculo.placa} disabled />
                </Form.Group>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Modelo</Form.Label>
                  <Form.Control value={veiculo.modelo} disabled />
                </Form.Group>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control value={veiculo.veiculoTipoNome} disabled />
                </Form.Group>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Temperatura Mínima (Celsius)</Form.Label>
                  <Form.Control
                    value={temperatura.minima}
                    maxLength={3}
                    onChange={(e) =>
                      setTemperatura({ ...temperatura, minima: soNumerosNegativo(e.target.value) })
                    }
                    placeholder="ºC"
                  />
                </Form.Group>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Temperatura Máxima (Celsius)</Form.Label>
                  <Form.Control
                    value={temperatura.maxima}
                    maxLength={3}
                    onChange={(e) =>
                      setTemperatura({ ...temperatura, maxima: soNumerosNegativo(e.target.value) })
                    }
                    placeholder="ºC"
                  />
                </Form.Group>
                <Botao className="container-buttons justify-content-between">
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-cancelar"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-enviar"
                      type="button"
                      onClick={() => (canEdit ? submit() : history.push('/acessonegado'))}
                    >
                      Confirmar
                    </Button>
                  </Form.Group>
                </Botao>
              </Form.Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ModalTemperaturaVeiculo);
