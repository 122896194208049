/* eslint-disable */
import React, { useState, ChangeEvent, useEffect, FC } from 'react';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/Toast';
import { Form, Col, Button } from 'react-bootstrap';
import { ConexaoIntegracao } from '../../api/model/ConexaoIntegracao';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import { getCookieSessionData } from '../../services/cookieService';
import { PrimaryButton } from '../../components/ButtonsForm/styles';

type Props = {
  canEdit?: boolean;
};

export const ParametrosIntegracao: FC<Props> = ({ canEdit }) => {
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;
  const history = useHistory();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const [conexao, setConexao] = useState<ConexaoIntegracao>({
    id: 10,
    ipTecnologiaIASWS: '',
    portaIASWS: 0,
    ipTecnologiaSIGHRA: '',
    portaSIGHRA: 0,
    ipTecnologiaAUTOTRAC: '187.95.41.69',
    portaAUTOTRAC: '8888',
  });
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  function ipAutotrac(e: ChangeEvent<HTMLInputElement>) {
    setConexao({
      ...conexao,
      ipTecnologiaAUTOTRAC: e,
    });
  }

  function portaIasws(e: ChangeEvent<HTMLInputElement>) {
    setConexao({
      ...conexao,
      portaIASWS: e,
    });
  }

  function ipIasws(e: ChangeEvent<HTMLInputElement>) {
    setConexao({
      ...conexao,
      ipTecnologiaIASWS: e,
    });
  }

  function portaSighra(e: ChangeEvent<HTMLInputElement>) {
    setConexao({
      ...conexao,
      portaSIGHRA: e,
    });
  }

  function ipSighra(e: ChangeEvent<HTMLInputElement>) {
    setConexao({
      ...conexao,
      ipTecnologiaSIGHRA: e,
    });
  }

  const pegarIntegracao = async () => {
    const response = await axios.get(`parametro-integracao`);
    setConexao({
      ...conexao,
      ipTecnologiaIASWS: response.data.ipTecnologiaIASWS,
      portaIASWS: response.data.portaIASWS,
      ipTecnologiaSIGHRA: response.data.ipTecnologiaSIGHRA,
      portaSIGHRA: response.data.portaSIGHRA,
      ipTecnologiaAUTOTRAC: response.data.ipTecnologiaAUTOTRAC,
      portaAUTOTRAC: response.data.portaAUTOTRAC,
    });
    console.log('console log do get: ', response.data);
  };

  useEffect(() => {
    pegarIntegracao();
  }, []);

  const handleIaswsSighraAutotrac = async (event: any) => {
    event.preventDefault();
    const params = {
      ipTecnologiaIASWS: conexao.ipTecnologiaIASWS,
      portaIASWS: conexao.portaIASWS,
      ipTecnologiaSIGHRA: conexao.ipTecnologiaSIGHRA,
      portaSIGHRA: conexao.portaSIGHRA,
      ipTecnologiaAUTOTRAC: conexao.ipTecnologiaAUTOTRAC,
      portaAUTOTRAC: conexao.portaAUTOTRAC,
    };
    try {
      console.log(params);
      await axios.post('/parametro-integracao/cadastrar', params);
      setConexao({
        ...conexao,
        ipTecnologiaIASWS: '',
        ipTecnologiaSIGHRA: '',
        portaIASWS: 0,
        portaSIGHRA: 0,
      });
      setIsDialogCadOK(true);
      // alert('Enviado com Sucesso');
      pegarIntegracao();
    } catch (error: any) {
      setIsDialogCadOK(false);
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar!',
        type: 'error',
      });
      // alert('Erro ao Enviar');
    }
  };

  function portaAutotrac(e: ChangeEvent<HTMLInputElement>) {
    setConexao({
      ...conexao,
      portaAUTOTRAC: e,
    });
  }

  const atualizarIntegracao = async (e: any) => {
    e.preventDefault();
    if (
      !conexao.ipTecnologiaIASWS ||
      !conexao.portaIASWS ||
      !conexao.ipTecnologiaSIGHRA ||
      !conexao.portaSIGHRA ||
      !conexao.ipTecnologiaAUTOTRAC
    ) {
      // alert('Verifique os dados preenchidos.');
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos',
        type: 'error',
      });
      setIsFormInvalid(true);
      return;
    }
    const params = {
      usuario: usuarioId,
      ipTecnologiaIASWS: conexao.ipTecnologiaIASWS,
      portaIASWS: conexao.portaIASWS,
      ipTecnologiaSIGHRA: conexao.ipTecnologiaSIGHRA,
      portaSIGHRA: conexao.portaSIGHRA,
      ipTecnologiaAUTOTRAC: conexao.ipTecnologiaAUTOTRAC,
      portaAUTOTRAC: conexao.portaAUTOTRAC,
    };
    try {
      await axios.post(`/parametro-integracao/cadastrar`, params);
      // alert('Atualizado com sucesso!');
      setIsDialogCadOK(true);
      console.log('parametros do put: ', params);
      pegarIntegracao();
      setIsFormInvalid(false);
    } catch (error: any) {
      // alert('Erro ao Atualizar!');
      addToast({
        title: 'Erro',
        description: 'Erro ao atualizar!',
        type: 'error',
      });
      console.log('parametros do put: ', params);
      pegarIntegracao();
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Parâmetro cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.goBack();
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Integração</h2>
          <h6 className="subtitulo">{'Módulo Gerenciador -> Parâmetros -> Integração'}</h6>
        </div>
        <div className="painel">
          <Form
            onSubmit={(e: any) => handleIaswsSighraAutotrac(e)}
            noValidate
            validated={isFormInvalid}
          >
            <div className="container">
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">IP conexão IASWS</Form.Label>
                  <Form.Control
                    value={conexao.ipTecnologiaIASWS}
                    onChange={(event: any) => {
                      ipIasws(event.target.value);
                    }}
                    as="select"
                    required
                  >
                    <option value="">Selecione o IP</option>
                    <option value="200.233.139.187">Oi (200.233.139.187)</option>
                    <option value="187.95.41.68">Alta Rede (187.95.41.68)</option>
                    <option value="138.204.203.110">New Group (138.204.203.110)</option>
                    <option value="187.180.225.153">NET (187.180.225.153)</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label className="required">Porta</Form.Label>
                  <Form.Control
                    type="number"
                    maxLength={5}
                    value={conexao.portaIASWS ? conexao.portaIASWS : ''}
                    onChange={(e: any) => {
                      portaIasws(e.target.value.slice(0, 5));
                    }}
                    id="portaIasws"
                    name="portaIasws"
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">IP conexão Sighra</Form.Label>
                  <Form.Control
                    value={conexao.ipTecnologiaSIGHRA}
                    onChange={(event: any) => {
                      ipSighra(event.target.value);
                    }}
                    as="select"
                    required
                  >
                    <option value="">Selecione o IP</option>
                    <option value="200.233.139.187">Oi (200.233.139.187)</option>
                    <option value="187.95.41.68">Alta Rede (187.95.41.68)</option>
                    <option value="138.204.203.110">New Group (138.204.203.110)</option>
                    <option value="187.180.225.153"> NET (187.180.225.153)</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label className="required">Porta</Form.Label>
                  <Form.Control
                    type="number"
                    value={conexao.portaSIGHRA ? conexao.portaSIGHRA : ''}
                    onChange={(e: any) => {
                      portaSighra(e.target.value.slice(0, 5));
                    }}
                    id="portaSighra"
                    name="portaSighra"
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="required">IP conexão Autotrac</Form.Label>
                  <Form.Control
                    value={conexao.ipTecnologiaAUTOTRAC}
                    onChange={(event: any) => {
                      ipAutotrac(event.target.value);
                    }}
                    as="select"
                  >
                    <option value="187.95.41.69">IP: 187.95.41.69</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Porta</Form.Label>
                  <Form.Control
                    type="number"
                    maxLength={5}
                    value={conexao.portaAUTOTRAC}
                    id="portaAutotrac"
                    name="portaAutotrac"
                    onChange={(event: any) => {
                      portaAutotrac(event.target.value);
                    }}
                    disabled
                  />
                </Form.Group>
              </Form.Row>

              {/* <Form.Row className="container-buttons justify-content-end">
                <Form.Group as={Col} md={2}>
                  <Button className="btn-enviar" type="submit">
                    Enviar
                  </Button>
                </Form.Group>
              </Form.Row> */}

              <Form.Row className="container-buttons justify-content-end">
                <Form.Group as={Col} md={2}>
                  <Button
                    style={{ display: conexao.id >= 1 ? 'none' : '' }}
                    size="sm"
                    type="submit"
                    className="btn-enviar"
                    onClick={handleIaswsSighraAutotrac}
                  >
                    Enviar
                  </Button>
                </Form.Group>
              </Form.Row>

              <Form.Row className="container-buttons justify-content-end">
                <Form.Group as={Col} md={2}>
                  {canEdit ? (
                    <PrimaryButton
                      style={{ display: conexao.id >= 1 ? '' : 'none' }}
                      type="submit"
                      onClick={atualizarIntegracao}
                    >
                      Atualizar
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      style={{ display: conexao.id >= 1 ? '' : 'none' }}
                      type="submit"
                      onClick={() => history.push('/acessonegado')}
                    >
                      Atualizar
                    </PrimaryButton>
                  )}
                </Form.Group>
              </Form.Row>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
