import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { Tela } from '../../../components/Tela';
import { Tabela } from '../../../components/Tabela';
import { Spinner } from '../../../components/Spinner';
import { pesquisar } from '../../../util/format';

type CapacidadeCargaType = {
  cpcCdId: number;
  capacidade: number;
  codigofaixaCubagemCdId: number;
  sazonalidadeCdId: number;
  idCliente: number;
  tipoVeiculo: number;
};

type SazonalidadeCargaType = {
  szcCdId: number;
  szcTxNome: string;
};

type TipoVeiculoType = {
  vtpCdId: number;
  vtpTxNome: string;
};

type FaixaCubagemType = {
  id: number;
  cubagem: number;
};

type Props = {
  canInsert: boolean;
};

const CapacidadeCargaList: React.FC<Props> = ({ canInsert }) => {
  const history = useHistory();
  const { page }: any = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const [data, setData] = useState<any[]>([]);
  const [tipoVeiculoList, setTipoVeiculoList] = useState<TipoVeiculoType[]>([]);
  const [sazonalidadeList, setSazonalidadeList] = useState<SazonalidadeCargaType[]>([]);
  const [cubagemList, setCubagemList] = useState<FaixaCubagemType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState<CapacidadeCargaType[]>([]);
  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const titles = ['+id', 'Tipo do Veículo', 'Sazonalidade', 'Cubagem', 'Capacidade'];
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/capacidade-de-carga/listar/${pageNumber}`);
  };

  const tipoVeiculo = useCallback(
    (id: number) => {
      return tipoVeiculoList.find((t) => t.vtpCdId === id)?.vtpTxNome;
    },
    [tipoVeiculoList]
  );

  const sazonalidade = useCallback(
    (id: number) => {
      return sazonalidadeList.find((t) => t.szcCdId === id)?.szcTxNome;
    },
    [sazonalidadeList]
  );

  const cubagem = useCallback(
    (id: number) => {
      return cubagemList.find((t) => t.id === id)?.cubagem;
    },
    [cubagemList]
  );

  const findData = async () => {
    const sazonalidade2 = await axios.get('/sazonalidade-carga/find-all', {
      params: { idCliente },
    });
    setSazonalidadeList(sazonalidade2.data);
    const tipoVeiculo2 = await axios.get('/tipo-veiculo/grid-tipo-veiculo');
    setTipoVeiculoList(tipoVeiculo2.data);
    const cubagem2 = await axios.get('/cubagem-faixa/grid', { params: { idCliente } });
    setCubagemList(cubagem2.data);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/capacidade-carga/find-all`, {
        params: {
          idCliente,
        },
      });
      setFullData(response.data);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const converteArray = (d: any[]) => {
    const array = d.map((each: CapacidadeCargaType) => {
      return [
        each.cpcCdId,
        tipoVeiculo(+each.tipoVeiculo),
        sazonalidade(each.sazonalidadeCdId),
        cubagem(each.codigofaixaCubagemCdId),
        each.capacidade,
      ];
    });
    return array;
  };

  useEffect(() => {
    setData(converteArray(fullData));
  }, [sazonalidadeList, cubagemList, tipoVeiculoList]);

  useEffect(() => {
    findData();
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/capacidade-de-carga/listar/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Capacidade de Carga"
      caminho="Cadastro > Capacidade de Carga"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/capacidade-de-carga/cadastrar/"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/capacidade-de-carga/editar/${id}`)}
        />
      )}
    </Tela>
  );
};

export default CapacidadeCargaList;
