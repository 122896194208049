/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../../../config/axiosMaquina';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  getAccessTokenDecodedByCookie,
  getCookieSessionData,
} from '../../../services/cookieService';

const Notificacao: React.FC = () => {
  const [avisos, setAvisos] = useState<any[]>([]);

  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const history = useHistory();

  useEffect(() => {
    if (idCliente != null) {
      axios
        .get(`/aviso-cliente/listarAvisosClientePorCliente?cliente=${idCliente}`)
        .then((response: any) => {
          if (
            getAccessTokenDecodedByCookie().resource_access.goldenservice.roles[0] === 'user' &&
            response.data != null &&
            response.data.length != 0
          ) {
            const temp = response.data.map((data: any) => {
              if (new Date(data.dataCadastro) <= new Date())
                return {
                  titulo: data.titulo,
                  mensagem: data.mensagem,
                  exibir: true,
                };
              else
                return {
                  titulo: data.titulo,
                  mensagem: data.mensagem,
                  exibir: false,
                };
            });
            setAvisos(temp);
          } else {
            history.push('/home');
          }
        });
    } else {
      history.push('/home');
    }
  }, []);

  const hideModal = (parentIndex: any) => {
    let temp = avisos.map((aviso: any, index: any) => {
      if (parentIndex == index)
        return {
          titulo: aviso.titulo,
          mensagem: aviso.mensagem,
          exibir: false,
        };
      else
        return {
          titulo: aviso.titulo,
          mensagem: aviso.mensagem,
          exibir: aviso.exibir,
        };
    });

    setAvisos(temp);
  };

  const fecharModais = () => {
    let count = 0;
    for (let i = 0; i < avisos.length; i++) {
      if (avisos[i].exibir === true) {
        count++;
      }
    }
    if (count === 0) {
      history.push('/home');
    }
  };

  return (
    <>
      {avisos.map((aviso: any, index) => (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          enforceFocus
          animation
          show={aviso.exibir}
          onHide={() => hideModal(index)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ marginLeft: '40%' }}>
              Atenção
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p key={index} style={{ marginLeft: 12, fontSize: 20, fontWeight: 'bold' }}>
              {aviso.titulo}
            </p>
            <p key={index} style={{ marginLeft: 12, marginBottom: 20 }}>
              {aviso.mensagem}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              style={{ color: '#000', fontWeight: 'bold' }}
              variant="primary"
              onClick={() => hideModal(index)}
            >
              OK
            </Button>
            {fecharModais()}
          </Modal.Footer>
        </Modal>
      ))}
    </>
  );
};

export default Notificacao;
