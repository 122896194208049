import { memo, useEffect, useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from '@amcharts/amcharts5/locales/pt_BR';
import { useToast } from '../../../../hooks/Toast';

export const Chart = ({ type, speedChart, temperatureChart }) => {
  const { addToast } = useToast();
  const chart = useRef();

  useLayoutEffect(() => {
    let root = am5.Root.new('chartdiv');

    root.locale = am5locales_pt_BR;

    let indicator = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layer: 1000,
        background: am5.Rectangle.new(root, {
          fill: am5.color(0xffffff),
          fillOpacity: 1,
        }),
      })
    );

    indicator.children.push(
      am5.Label.new(root, {
        text: 'Carregando...',
        fontSize: 25,
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
      })
    );

    var hourglass = indicator.children.push(
      am5.Graphics.new(root, {
        width: 32,
        height: 32,
        fill: am5.color(0x000000),
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        dy: -45,
        svgPath:
          'M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z',
      })
    );

    var hourglassanimation = hourglass.animate({
      key: 'rotation',
      to: 180,
      loops: Infinity,
      duration: 2000,
      easing: am5.ease.inOut(am5.ease.cubic),
    });

    async function show() {
      hourglassanimation.play();
      await indicator.show();
    }
    async function hide() {
      hourglassanimation.pause();
      await indicator.hide();
    }
    show();

    let _chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        layout: root.verticalLayout,
      })
    );

    _chart.get('colors').set('step', 3);

    let xAxis = _chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: true,
        extraMax: 0.1, // this adds some space in front
        extraMin: 0.1, // this removes some space from the beginning so that the line would not be cut off
        baseInterval: {
          timeUnit: 'minute',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
          grid: {
            location: 0,
            strokeOpacity: 1,
            stroke: am5.color(0x000000),
            strokeDasharray: '3,3',
          },
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    root.numberFormatter.set('numberFormat', '#. ');

    function createAxisAndSeries(opposite, name, _type, chartData) {
      if (!chartData.length) {
        addToast({
          title: 'Erro',
          description: `Nenhum dado de ${_type} encontrado`,
          type: 'error',
        });
        return;
      }
      let _data = chartData;

      let yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite,
      });
      let yAxis = _chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0.1,
          renderer: yRenderer,
        })
      );

      // let rangeDataItem = yAxis.makeDataItem({
      //   value: 80,
      //   color: am5.color(0xc8340b),
      // });

      // let range = yAxis.createAxisRange(rangeDataItem);

      if (_chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set('syncWithAxis', _chart.yAxes.getIndex(0));
      }

      let series = _chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '{tooltip}',
          }),
        })
      );

      // series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      series.strokes.template.setAll({ strokeWidth: 1 });

      // yRenderer.grid.template.set('strokeOpacity', 0.05);
      yRenderer.labels.template.set('fill', series.get('fill'));
      yRenderer.setAll({
        stroke: series.get('fill'),
        strokeOpacity: 1,
        opacity: 1,
      });

      // Set up data processor to parse string dates
      // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
      // series.data.processor = am5.DataProcessor.new(root, {
      //   dateFormat: 'dd/MM/YY HH:mm',
      //   dateFields: ['date'],
      // });

      series.bullets.push(function (root, series, dataItem) {
        // only create sprite if bullet == true in data context
        if (dataItem.dataContext.bullet) {
          let container = am5.Container.new(root, {});
          let circle0 = container.children.push(
            am5.Circle.new(root, {
              radius: 5,
              fill: am5.color(0xff0000),
            })
          );
          let circle1 = container.children.push(
            am5.Circle.new(root, {
              radius: 5,
              fill: am5.color(0xff0000),
            })
          );

          circle0.animate({
            key: 'radius',
            to: 10,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });
          circle0.animate({
            key: 'opacity',
            to: 0,
            from: 1,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });
          circle1.animate({
            key: 'radius',
            to: 10,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });
          circle1.animate({
            key: 'opacity',
            to: 0,
            from: 1,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });

          return am5.Bullet.new(root, {
            locationX: undefined,
            sprite: container,
          });
        }
      });
      // if (_data.length) {
      //   _data[_data.length - 1].bullet = true;
      // }
      series.data.setAll(_data);

      // setInterval(function () {
      //   addData();
      // }, 60000);

      // function addData() {
      //   let lastDataItem = series.dataItems[series.dataItems.length - 1];

      //   let lastValue = lastDataItem.get('valueY');
      //   let newValue = startValue + (Math.random() < 0.5 ? 1 : -1) * Math.random() * 5;
      //   let lastDate = new Date(lastDataItem.get('valueX'));
      //   let time = am5.time.add(new Date(lastDate), 'minute', 1).getTime();
      //   series.data.removeIndex(0);
      //   series.data.push({
      //     date: time,
      //     value: newValue,
      //   });

      //   let newDataItem = series.dataItems[series.dataItems.length - 1];
      //   newDataItem.animate({
      //     key: 'valueYWorking',
      //     to: newValue,
      //     from: lastValue,
      //     duration: 1000,
      //     easing: easing,
      //   });

      //   // use the bullet of last data item so that a new sprite is not created
      //   newDataItem.bullets = [];
      //   newDataItem.bullets[0] = lastDataItem?.bullets[0];
      //   if (newDataItem.bullets[0]) {
      //     newDataItem.bullets[0].get('sprite').dataItem = newDataItem;
      //     // reset bullets
      //     lastDataItem.dataContext.bullet = false;
      //     lastDataItem.bullets = [];
      //   }

      //   let animation = newDataItem.animate({
      //     key: 'locationX',
      //     to: 0.5,
      //     from: -0.5,
      //     duration: 1000,
      //   });
      //   if (animation) {
      //     let tooltip = xAxis.get('tooltip');
      //     if (tooltip && !tooltip.isHidden()) {
      //       animation.events.on('stopped', function () {
      //         xAxis.updateTooltip();
      //       });
      //     }
      //   }
      // }
    }
    if (type === 2) {
      createAxisAndSeries(false, 'Velocidade', 'velocidade', speedChart);
      createAxisAndSeries(true, 'Temperatura', 'temperatura', temperatureChart);
    } else if (type === 1) {
      createAxisAndSeries(false, 'Velocidade', 'velocidade', speedChart);
    } else if (type === 0) {
      createAxisAndSeries(false, 'Temperatura', 'temperatura', temperatureChart);
    }
    let cursor = _chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      })
    );
    cursor.lineY.set('visible', false);

    let legend = _chart.children.push(
      am5.Legend.new(root, {
        x: am5.percent(10),
        centerX: am5.percent(50),
        layout: root.verticalLayout,
      })
    );
    legend.data.setAll(_chart.series.values);

    hide();
    _chart.appear(1000, 100);
    chart.current = _chart;

    return () => {
      root.dispose();
    };
  }, [speedChart]);

  return <div id="chartdiv" style={{ width: '100%', height: '350px' }}></div>;
};
