/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from '../../config/axiosMaquina';
import { useHistory, useParams } from 'react-router-dom';
import { Carretas } from '../../api/Types/carretasTypes';
import Pagination from '../../components/Pagination/Pagination';
import { getCookieSessionData } from '../../services/cookieService';
import { Spinner } from 'react-bootstrap';
import { Tela } from '../../components/Tela';

type Props = {
  canInsert?: boolean;
};

const GridCarretas = ({ canInsert }: Props) => {
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const [carretas, setCarretas] = useState<Carretas[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<Carretas[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [totalPosts, setTotalPosts] = useState(0);
  const { page }: any = useParams();
  const paginate = (pageNumber: number) => {
    history.push(`/cadastro/carretas/${pageNumber}`);
  };
  const history = useHistory();
  const anoAtual = new Date().getFullYear();

  useEffect(() => {
    const loadCarretas = async () => {
      setLoading(true);
      try {
        const response = await axios.get('veiculo/listarCarretasPorCliente', {
          params: {
            cliente: clienteId,
            qtdRegistros: itensPerPage,
            pagina: page - 1,
          },
        });

        setSearchResults(response.data);
        setCarretas(response.data);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    loadCarretas();
  }, [itensPerPage, page]);

  useEffect(() => {
    const fetchQtdPosts = async () => {
      const response = await axios.get(`/veiculo/countCarretasPorCliente/${clienteId}`);
      setTotalPosts(response.data);
    };
    fetchQtdPosts();
  }, []);

  const loadCarretas = async () => {
    try {
      const response = await axios.get('veiculo/listarCarretasPorCliente', {
        params: {
          cliente: clienteId,
          qtdRegistros: itensPerPage,
          pagina: page - 1,
        },
      });

      setSearchResults(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const search = async () => {
      if (searchTerm != '') {
        const response = await axios.get(`/veiculo/searchTodasCarretas`, {
          params: {
            idCliente: clienteId,
            keyword: searchTerm,
          },
        });
        setSearchResults(response.data);
      }

      if (searchTerm === '') {
        loadCarretas();
      }
    };
    search();
  }, [searchTerm]);

  function atualizarCarreta(id: number) {
    history.push(`/cadastro/carretas/atualizar/true/${id}`);
  }

  return (
    <Tela
      nome="Carretas"
      caminho="Cadastro > Carretas"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      createDisabled={!canInsert}
      linkToAdd="/cadastro/carretas/cadastrar/true"
    >
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <table className="table">
          <thead className="thead">
            <tr>
              <th>id</th>
              <th>Placa</th>
              <th>Marca do Veiculo</th>
              <th>Modelo</th>
              <th>Tipo de Veiculo</th>
              <th>Tipo de Frota</th>
              <th>Idade</th>
              <th>Tempo de operação</th>
              <th>Quantidade de Viagens</th>
              <th>Valor Médio Transportado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {carretas &&
              searchResults.map((carreta) => (
                <tr key={carreta.id}>
                  <td>{carreta.id}</td>
                  <td>{carreta.placa}</td>
                  <td>{carreta.veiculoMarcaNome}</td>
                  <td>{carreta.modelo}</td>
                  <td>{carreta.veiculoTipoNome}</td>
                  {carreta.tipoFrota === '1' ? (
                    <td>Próprio</td>
                  ) : carreta.tipoFrota === '2' ? (
                    <td>Agregado</td>
                  ) : carreta.tipoFrota === '3' ? (
                    <td>Terceiro</td>
                  ) : (
                    ''
                  )}
                  <td>
                    {carreta.fabricacaoAno === 0 || carreta.fabricacaoAno === null
                      ? ''
                      : anoAtual - carreta.fabricacaoAno}
                  </td>
                  <td>{carreta.tempoCadastro}</td>
                  <td>{carreta.qtdeViagens === null ? 0 : carreta.qtdeViagens}</td>
                  <td>
                    {carreta.valorMedioTransportado
                      ? carreta.valorMedioTransportado.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      : ''}
                  </td>
                  <td>
                    <div className="icons">
                      <button
                        style={{
                          backgroundColor: 'transparent',
                          borderColor: 'transparent',
                        }}
                        onClick={() => atualizarCarreta(carreta.id)}
                      >
                        <i style={{ color: '#000' }} className="pi pi-pencil"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <Pagination
        totalPosts={searchTerm ? searchResults.length : totalPosts}
        paginate={paginate}
        currentPage={page}
        itensPerPage={itensPerPage}
        setItensPerPage={setItensPerPage}
      />
    </Tela>
  );
};

export default GridCarretas;
