/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Form, Row, Button, FormControl, Modal, Col } from 'react-bootstrap';
import { getCookieSessionData } from '../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import { PainelLSCType } from '../../Types/PainelLSCTypes';
import { getFullDateWithHourAndMinutes } from '../../util/format';
import api from '../../config/axiosMaquina';
import { MotivoType } from '../../Types/MotivoType';
import { TratativaType } from '../../Types/TratativaType';
import ModalComentario from './modalComentario';

type ModalProps = {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  data: PainelLSCType;
  handleStartTratativa: (idSm: number) => void;
  fetchData: () => void;
};

export type ComentariosType = {
  ativo: boolean;
  comentario: string;
  dataCadastro: string;
  dataCadastroUltimaAlteracao: string;
  idLsc: number;
  idLscTratamentoComentario: number;
  idUsuario: number;
  idUsuarioUltimaAlteracao: number;
  nomeUsuario: string;
};

const ModalLSC: FC<ModalProps> = ({
  showModal,
  setShowModal,
  data,
  handleStartTratativa,
  fetchData,
}) => {
  const dataNow = new Date().toISOString();
  const [dataAtual, setDataAtual] = useState(dataNow);
  const [descricao, setDescricao] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [mensagemErro, setMensagemErro] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadOK2, setIsDialogCadOK2] = useState(false);
  const [showModalComentario, setShowModalComentario] = useState(false);
  const [motivos, setMotivos] = useState<MotivoType[]>([]);
  const [motivoId, setMotivoId] = useState<number>();
  const [tratativa, setTratativa] = useState<TratativaType>();
  const [comentarios, setComentarios] = useState<any[]>([]);

  const loadMotivos = async () => {
    try {
      const response = await api.get(`/motivo/grid`);
      setMotivos(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const loadTratativa = async () => {
    if (data.idlsc) {
      try {
        const response = await api.get(
          `/solicitacao-monitoramento/lsc-tratamento-listar-comentarios-por-idlsc?idLsc=${data.idlsc}`
        );
        setMotivoId(response.data.idMotivo);
        setComentarios(response.data.lscComentarioList);
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  // const handlePost = async () => {
  //   if (motivoId != 0) {
  //     try {
  //       await api.post(`/solicitacao-monitoramento/lsc-tratamento-salvar`, {
  //         smlvo: {
  //           ativo: true,
  //           dataEntrada: new Date().toISOString(),
  //           dataCadastroUltimaAlteracao: new Date().toISOString(),
  //           idMotivo: motivoId,
  //           idSm: data.idsm,
  //           idLsc: data.idlsc,
  //           violacaoTemperatura: data.violoutemperatura == null ? false : data.violoutemperatura,
  //           violacaoVelocidade: data.violouvelocidade == null ? false : data.violouvelocidade,
  //           idUsuario: usuarioId,
  //           idUsuarioUltimaAlteracao: usuarioId,
  //         },
  //         tratamentovo: comentarios,
  //       });
  //       setMensagem('Tratamento salvo com sucesso!');
  //       setIsDialogCadOK(true);
  //       loadTratativa();
  //       fetchData();
  //     } catch (error: any) {
  //       console.log(error);
  //     }
  //   } else {
  //     setMensagemErro('Verifique o campo obrigatório!');
  //     setIsDialogCadOK2(true);
  //   }
  // };

  useEffect(() => {
    if (showModal) {
      setMotivoId(0);
      setComentarios([]);
      loadMotivos();
      loadTratativa();
    }
  }, [data, showModal]);

  // useEffect(() => {
  //   if (!showModalComentario && tratativa?.idMotivo) {
  //     loadTratativa();
  //   }
  // }, [showModalComentario]);

  const handlePut = async () => {
    if (motivoId) {
      try {
        await api.put(
          `/solicitacao-monitoramento/lsc-tratamento-comentarios-atualizar?idLsc=${data.idlsc}&idMotivo=${motivoId}`,
          []
        );
        setMensagem('Tratamento salvo com sucesso!');
        setIsDialogCadOK(true);
        fetchData();
      } catch (error) {}
    } else {
      setMensagemErro('Verifique o campo obrigatório!');
      setIsDialogCadOK2(true);
    }
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setMotivoId(0);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Informar tratamento</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Dialog
          header={mensagem}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK(false);
                setShowModal(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK(false);
            setShowModal(false);
          }}
        />
        <Dialog
          header={mensagemErro}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK2(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK2}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK2(false);
          }}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'row' }}> */}
        <Form>
          <Form.Row>
            <Form.Group className="row-modal">
              <Form.Label className="requiredField">Motivo do atraso</Form.Label>
              <Form.Control
                value={motivoId}
                onChange={(e) => {
                  setMotivoId(parseInt(e.target.value));
                  console.log(e.target.value);
                }}
                as="select"
              >
                <option value="0">Selecione</option>
                {motivos &&
                  motivos.map((each: any) => (
                    <option value={each.motCdId}>{each.descricao}</option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Label column sm="3">
              Tratativas
            </Form.Label>
          </Form.Row>
          <div className="resumo">
            {comentarios &&
              comentarios.map((each: ComentariosType) => (
                <div>
                  <strong>
                    {' '}
                    {getFullDateWithHourAndMinutes(each.dataCadastro)} - {each.nomeUsuario}
                    {' - '}
                  </strong>
                  {each.comentario}
                </div>
              ))}
            <Form.Row className="container-buttons justify-content-end">
              {/* <Form.Group as={Col} md={2}> */}
              <Button
                className="btn-enviar"
                style={{ width: '120px', height: '40px', fontSize: '12px', color: '#333333' }}
                onClick={() => {
                  if (motivoId) {
                    setShowModalComentario(true);
                  } else {
                    setMensagemErro('Selecione um motivo antes de adicionar um comentário!');
                    setIsDialogCadOK2(true);
                  }
                }}
              >
                Add Comentário
              </Button>
              {/* </Form.Group> */}
            </Form.Row>
          </div>
        </Form>
      </Modal.Body>

      <Form.Row className="container-buttons justify-content-end">
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-cancelar"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-enviar"
            onClick={() => {
              handlePut();
            }}
          >
            Salvar Tratamento
          </Button>
        </Form.Group>
      </Form.Row>

      <ModalComentario
        showModal={showModalComentario}
        setShowModal={setShowModalComentario}
        idlsc={data?.idlsc}
        idmotivoatraso={data?.idmotivoatraso}
        motivoId={motivoId!}
        comentarios={comentarios}
        setComentarios={setComentarios}
        loadTratativa={loadTratativa}
      />
    </Modal>
  );
};
export default ModalLSC;
