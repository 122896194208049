import React, { memo, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Spinner, ToggleButton } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router';
import Switch from 'react-bootstrap/esm/Switch';
import { Botao } from '../../GrupoVeiculo/Modal/styles';
import axios from '../../../config/axiosMaquina';
import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import { soNumeros } from '../../../util/Validacoes/Validacoes';
import { useToast } from '../../../hooks/Toast';

type Props = {
  veiculo: VeiculoTypeGrid;
  loadVeiculos: () => void;
  clienteId: number;
  canEdit: boolean;
};

const ModalVelocidadeVeiculo = ({ veiculo, loadVeiculos, clienteId, canEdit }: Props) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [velocidadePadrao, setVelocidadePadrao] = useState(false);
  const [velocidadeMax, setVelocidadeMax] = useState<number | string>();
  const [validated, setValidated] = useState(false);
  const [erroVelocidadeMaxima, setErroVelocidadeMaxima] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [isDialogVisibleCadNaoEfetuado, setIsDialogVisibleCadNaoEfetuado] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (veiculo.velocidadeIndividualVeiculo && velocidadePadrao) {
      setVelocidadeMax('');
      setErroVelocidadeMaxima(false);
    }
    if (veiculo.velocidadeIndividualVeiculo && !velocidadePadrao) {
      setVelocidadeMax(veiculo.velocidadeIndividualVeiculo);
    }
  }, [veiculo.velocidadeIndividualVeiculo, velocidadePadrao, show]);

  const submit = async () => {
    if (erroVelocidadeMaxima) {
      return;
    }
    setLoading(true);
    const data = { ...veiculo, velocidadeMaxima: Number(velocidadeMax) };
    try {
      const response = await axios.put(
        `/veiculo/atualizar-velocidade?idCliente=${clienteId}&placa=${veiculo.placa}&velocidade=${velocidadeMax}`
      );
      addToast({
        title: 'Atualizado',
        description: 'Velocidade Máxima atualizada com sucesso!',
        type: 'success',
      });
    } catch (err) {
      console.error();
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar',
        type: 'error',
      });
    } finally {
      setLoading(false);
      loadVeiculos();
      setShow(false);
    }
  };

  return (
    <>
      <button
        type="button"
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
        onClick={() => {
          handleShow();
        }}
      >
        <i style={{ color: '#000' }} className="pi pi-pencil" />
      </button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header style={{ alignSelf: 'center' }}>
          <h2 className="titulo">Editar Velocidade Máxima</h2>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center', width: '90%' }}>
          <Dialog
            header="Grupo de veículos foi cadastrado com sucesso!"
            footer={
              <>
                <Button onClick={() => history.go(0)}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />
          <Dialog
            header="Erro ao cadastrar, verifique os campos!"
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadNaoEfetuado(false)}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadNaoEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadNaoEfetuado(false)}
          />
          {loading ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <Form noValidate validated={validated}>
              <Form.Row>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Marca</Form.Label>
                  <Form.Control value={veiculo.placa} readOnly />
                </Form.Group>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Modelo</Form.Label>
                  <Form.Control value={veiculo.modelo} readOnly />
                </Form.Group>
                <Form.Group as={Col} sm={12}>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control value={veiculo.veiculoTipoNome} readOnly />
                </Form.Group>
                <Form.Group as={Col} sm={12}>
                  <Form.Label className={velocidadePadrao ? '' : 'requiredField'}>
                    Velocidade Máxima (km/h)
                  </Form.Label>
                  <Form.Control
                    value={velocidadeMax}
                    maxLength={3}
                    max={140}
                    readOnly={velocidadePadrao}
                    required={!velocidadePadrao}
                    onChange={(e) => {
                      setVelocidadeMax(soNumeros(e.target.value));
                      if (velocidadePadrao) {
                        return;
                      }
                      if (soNumeros(e.target.value) > 140 || soNumeros(e.target.value) < 50) {
                        setErroVelocidadeMaxima(true);
                      } else {
                        setErroVelocidadeMaxima(false);
                      }
                    }}
                    placeholder="Km/h"
                  />
                  {erroVelocidadeMaxima && velocidadeMax && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Velocidade máxima não pode ser menor que 50km/h ou maior que 140km/h.
                    </p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Velocidade máxima não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
                {veiculo.velocidadeIndividualVeiculo ? (
                  <Form.Group as={Col} sm={12}>
                    <Form.Check
                      style={{ fontSize: '1rem', fontWeight: 600 }}
                      disabled={!veiculo.velocidadeIndividualVeiculo}
                      value={velocidadePadrao ? 1 : 0}
                      type="switch"
                      id="custom-switch"
                      checked={velocidadePadrao}
                      onChange={() => setVelocidadePadrao(!velocidadePadrao)}
                      label="Velocidade padrão"
                    />
                  </Form.Group>
                ) : null}
                <Botao className="container-buttons justify-content-between">
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-cancelar"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-enviar"
                      type="button"
                      onClick={() => (canEdit ? submit() : history.push('/acessonegado'))}
                    >
                      Confirmar
                    </Button>
                  </Form.Group>
                </Botao>
              </Form.Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ModalVelocidadeVeiculo);
