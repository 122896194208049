/* eslint-disable */
import { Dropdown } from 'primereact/dropdown';
import { useState, ChangeEvent, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Col } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import axios from 'axios';
import api from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import {
  cpfMask,
  maskCep,
  soLetras,
  soNumeros,
  semCaracterEspecial,
} from '../../util/Validacoes/Validacoes';
import { getFullDate } from '../../util/format';
import { TIPOS_CONDUTOR_AJUDANTE } from '../../constants/Condutor';
import validateFields, { validaDataNascimentoField } from './ajudanteValidation';
import { useToast } from '../../hooks/Toast';
import {
  dataMaxParaMaioridadeFormatada,
  dataMinParaDataNascimentoFormatada,
  toZoned,
} from '../../util/date';
import Input from '../Input';
import Select from '../Select';
import { useHistory } from 'react-router-dom';

type Funcionario = {
  atualizarPesquisa: boolean;
  bairro: string;
  bloqueado: boolean;
  cep: string;
  cidade: number;
  cpf: string;
  dataNascimento: string;
  id: number;
  logradouro: string;
  logradouroComplemento: string;
  logradouroNumero: number;
  nome: string;
  nomeCidade: string;
  nomeMae: string;
  nomePai: string;
  nomeUfEmissor: string;
  nomeUfEndereco: string;
  rg: string;
  sexo: string;
  tipo: string;
  idUfEmissor: number;
  idUfEndereco: number;
  idUfNascimento: number;
};

type Ufs = {
  id: number;
  sigla: string;
};

type FuncionarioNome = {
  id: number;
  nome: string;
};

type InputError = {
  funcionario: string;
  nome: string;
  tipo: string;
  dataNascimento: string;
  rg: string;
  idUfEmissorRg: string;
  cep: string;
  logradouro: string;
  logradouroNumero: string;
  logradouroComplemento: string;
  bairro: string;
  cidade: string;
  uf: string;
  nomePai: string;
  nomeMae: string;
};

type Municipios = {
  id: number;
  nomeMunicipio: string;
};

export const ContainerPesquisarAjudante = forwardRef((pros, ref) => {
  const [funcionario, setFuncionario] = useState<Funcionario>();
  const [listaFuncionarios, setlistaFuncionarios] = useState<FuncionarioNome[]>([]);
  const [listaUfs, setListaUfs] = useState<Ufs[]>([]);
  const [inputErrors, setInputErrors] = useState({} as InputError);
  const { addToast } = useToast();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [listaMunicipios, setListaMunicipios] = useState<Municipios[]>([]);

  const [viaCepUf, setViaCepUf] = useState('');
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [cepMudou, setCepMudou] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;

  const tipoAjudante = TIPOS_CONDUTOR_AJUDANTE.find(
    (each) => each.id?.toString() === funcionario?.tipo
  )?.name;

  const formattedData = {
    nomeMae: funcionario?.nomeMae,
    nomePai: funcionario?.nomePai,
    nome: funcionario?.nome,
    logradouro: funcionario?.logradouro,
    dataNascimento: funcionario?.dataNascimento,
    cpf: funcionario?.cpf,
    sexo: funcionario?.sexo,
    bloqueado: funcionario?.bloqueado,
    cidade: funcionario?.nomeCidade,
    cep: funcionario?.cep,
    bairro: funcionario?.bairro,
    cidadeId: funcionario?.cidade,
    atualizarAutomaticamente: funcionario?.atualizarPesquisa,
    idAjudante: funcionario?.id,
    tipo: tipoAjudante,
    numRG: funcionario?.rg,
    siglaUfEmissorRG: funcionario?.nomeUfEmissor,
    numeroEndereco: funcionario?.logradouroNumero,
    complemento: funcionario?.logradouroComplemento,
    siglaUFEndereco: funcionario?.nomeUfEndereco,
    ufEnderecoId: funcionario?.idUfEndereco,
  };

  useImperativeHandle(
    ref,
    () => ({
      validation() {
        if (!funcionario) {
          setInputErrors({ ...inputErrors, funcionario: 'Selecione um ajudante/funcionário' });
          return false;
        }
        setInputErrors({ ...inputErrors, funcionario: '' });
        const isValid = validateFields({ funcionario, setInputErrors });
        if (!isValid) {
          return false;
        }
        return true;
      },
      ajudante: formattedData,
    }),
    [addToast, funcionario, inputErrors]
  );

  const toggleSwitchAtualizacaoAutomatica = () => {
    if (!funcionario) {
      return;
    }
    setFuncionario({
      ...funcionario,
      atualizarPesquisa: !funcionario.atualizarPesquisa,
    });
  };

  const loadDadosMunicipioPorUf = async (idUf: number) => {
    if (!idUf) {
      return;
    }
    try {
      const response = await api.get(`/municipio-ibge/listarPorUf/${idUf}`);
      console.log(response.data);
      setListaMunicipios(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    axios
      .all([
        api.get(`/ajudante-funcionario/listarAjudanteNome?cliente=${clienteId}`),
        api.get('/uf/ListarIdSiglaEstadoPais/1'),
      ])
      .then(
        axios.spread((funcionariosResponse, ufSiglasResponse) => {
          setlistaFuncionarios(funcionariosResponse.data);
          setListaUfs(ufSiglasResponse.data);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, [clienteId]);

  function updateModel(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!funcionario) {
      return;
    }

    let { value } = e.target;

    switch (e.target.name) {
      case 'nome':
        value = soLetras(e.target.value);
        break;
      case 'logradouroNumero':
        value = soNumeros(e.target.value);
        break;
      default:
        value = e.target.value;
        break;
    }

    setFuncionario({
      ...funcionario,
      [e.target.name]: e.target.value,
    });
  }

  const loadDadosFuncionario = async (idFuncionario: number) => {
    if (!idFuncionario) {
      history.replace(`/enviar-pesquisa/ajudante`);
      setFuncionario(undefined);
      return;
    }
    try {
      const response = await api.get(`/ajudante-funcionario/${idFuncionario}`);
      history.replace(`/enviar-pesquisa/ajudante/${idFuncionario}`);

      setFuncionario({
        ...response.data,
        dataNascimento: getFullDate(response.data.dataNascimento),
        atualizarPesquisaAutomaticamente:
          response.data.atualizarPesquisaAutomaticamente === null
            ? false
            : response.data.atualizarPesquisaAutomaticamente,
      });
      loadDadosMunicipioPorUf(response.data.idUfEndereco);
      console.log(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!funcionario) {
      setInputErrors({} as InputError);
    }
  }, [funcionario]);

  const buscarCep = (value: string) => {
    console.log(value.length);
    if (cepMudou) {
      setError(false);
      fetch(`https://viacep.com.br/ws/${value}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }

          setViaCepUf(data.uf);
          setViaCepCidade(data.localidade);
          const ufId = listaUfs
            .filter((uf) => uf.sigla === data.uf)
            .map((filteredUfId) => filteredUfId.id);
          // setIdCodigoIbge(ufId.toString());
          // console.log(ufId);
          // console.log(idCodigoIbge);
          // setCidade(data.localidade);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
          const listaMunPorEstado = listaMunicipios
            .filter((municipio) => municipio.id === viaCepIbgeCod)
            .map((filteredMunicipio) => filteredMunicipio.nomeMunicipio);
          console.log(listaMunPorEstado);
          console.log(codIbgeFormatado);
          if (funcionario) {
            setFuncionario({
              ...funcionario,
              cep: data.cep,
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.ibge,
              idUfEndereco: codIbgeFormatado,
              nomeCidade: data.localidade,
            });
          }
        })

        .catch((erro) => {
          // console.log('error', erro);
          setError(true);
        });
    }
  };

  return (
    <>
      <Form>
        <h2 className="titulo">Dados da Pesquisa Ajudante/Funcionário</h2>
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <div className="containerLimpar">
              <Form.Label>Ajudante/Funcionário:</Form.Label>
              <p
                className="limparButton"
                onClick={() => {
                  history.replace(`/enviar-pesquisa/ajudante`);
                  setFuncionario(undefined);
                }}
              >
                Limpar
              </p>
            </div>
            <Dropdown
              value={funcionario ? funcionario.id : ''}
              options={listaFuncionarios}
              onChange={(e) => {
                loadDadosFuncionario(parseInt(e.target.value, 10));
              }}
              optionLabel="nome"
              optionValue="id"
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              filter
              filterBy="nome"
              placeholder="Selecione"
              className="p-dropdown"
              showClear
              style={{
                width: '100%',
                borderColor: inputErrors.funcionario ? '#ff0000' : '#ced4da',
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Input
            disabled
            required
            value={funcionario ? cpfMask(funcionario.cpf) : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateModel(e);
            }}
            id="cpf"
            name="cpf"
            textInputTitle="CPF:"
          />
          <Input
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.nome : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (funcionario) {
                setFuncionario({
                  ...funcionario,
                  nome: soLetras(semCaracterEspecial(e.target.value)),
                });
              }
            }}
            id="nome-funcionario"
            name="nome"
            textInputTitle="Nome:"
            error={inputErrors.nome}
            maxLength={256}
          />
          <Select
            disabled={!funcionario}
            name="tipo"
            required
            value={funcionario ? funcionario.tipo : ''}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              updateModel(e);
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            textInputTitle="Tipo:"
            error={inputErrors.tipo}
          >
            <option value="0">Selecione</option>
            {TIPOS_CONDUTOR_AJUDANTE.map((t) => (
              <option value={t.id} key={t.id}>
                {t.displayName}
              </option>
            ))}
          </Select>
        </Form.Row>
        <Form.Row>
          <Input
            value={funcionario ? funcionario.dataNascimento : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateModel(e);
            }}
            onBlur={() => validaDataNascimentoField({ funcionario, setInputErrors, inputErrors })}
            id="dataNascimento"
            name="dataNascimento"
            type="date"
            max={dataMaxParaMaioridadeFormatada()}
            min={dataMinParaDataNascimentoFormatada()}
            required
            disabled={!funcionario}
            textInputTitle="Nascimento:"
            error={inputErrors.dataNascimento}
            maxLength={10}
          />
          <Input
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.rg : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateModel(e);
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            id="rg"
            name="rg"
            textInputTitle="RG nº:"
            error={inputErrors.rg}
            maxLength={15}
          />
          <Select
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.idUfEmissor : ''}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              updateModel(e);
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            textInputTitle="UF Emissor:"
            error={inputErrors.idUfEmissorRg}
            name="idUfEmissor"
            id="idUfEmissor"
          >
            <option value="0">Selecione</option>
            {listaUfs.map((i) => (
              <option value={i.id} key={i.id}>
                {i.sigla}
              </option>
            ))}
          </Select>
        </Form.Row>

        <Form.Row>
          <Input
            disabled={!funcionario}
            required
            value={funcionario ? maskCep(funcionario.cep) : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateModel(e);
              setCepMudou(true);
              if (e.target.value.length === 9) {
                buscarCep(e.target.value);
              }
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            id="cep-funcionario"
            name="cep"
            textInputTitle="CEP"
            error={inputErrors.cep}
            maxLength={9}
          />

          <Input
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.logradouro : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateModel(e);
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            id="logradouro-funcionario"
            name="logradouro"
            textInputTitle="Logradouro:"
            error={inputErrors.logradouro}
            maxLength={56}
          />
          <Input
            disabled={!funcionario}
            required
            type="number"
            value={funcionario ? funcionario.logradouroNumero : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length <= 4) {
                updateModel(e);
              }
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            id="logradouroNumero"
            name="logradouroNumero"
            textInputTitle="Número:"
            error={inputErrors.logradouroNumero}
            maxLength={8}
          />
        </Form.Row>
        <Form.Row>
          <Input
            disabled={!funcionario}
            value={funcionario ? funcionario.logradouroComplemento : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateModel(e);
            }}
            id="logradouroComplemento"
            name="logradouroComplemento"
            textInputTitle="Complemento:"
            error={inputErrors.logradouroComplemento}
            maxLength={56}
          />
          <Input
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.bairro : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateModel(e);
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            id="bairro"
            name="bairro"
            textInputTitle="Bairro:"
            error={inputErrors.bairro}
            maxLength={56}
          />
          <Select
            name="cidade"
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.cidade : ''}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              updateModel(e);
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            textInputTitle="Cidade:"
            error={inputErrors.cidade}
          >
            <option value="0">Selecione</option>
            {listaMunicipios.map((i) => (
              <option value={i.id} key={i.id}>
                {i.nomeMunicipio}
              </option>
            ))}
            {viaCepCidade && <option value={funcionario?.cidade}>{viaCepCidade}</option>}
          </Select>

          <Select
            disabled={!funcionario}
            name="idUfEndereco"
            id="idUfEndereco"
            required
            value={funcionario ? funcionario.idUfEndereco : ''}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              updateModel(e);
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            textInputTitle="UF:"
            error={inputErrors.uf}
          >
            <option value="0">Selecione</option>
            {listaUfs.map((i) => (
              <option value={i.id} key={i.id}>
                {i.sigla}
              </option>
            ))}
          </Select>
        </Form.Row>
        <Form.Row>
          <Input
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.nomePai : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (funcionario) {
                setFuncionario({
                  ...funcionario,
                  nomePai: soLetras(semCaracterEspecial(e.target.value)),
                });
              }
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            id="nomePai"
            name="nomePai"
            textInputTitle="Nome Pai:"
            error={inputErrors.nomePai}
            maxLength={256}
          />
          <Input
            disabled={!funcionario}
            required
            value={funcionario ? funcionario.nomeMae : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (funcionario) {
                setFuncionario({
                  ...funcionario,
                  nomeMae: soLetras(semCaracterEspecial(e.target.value)),
                });
              }
            }}
            onBlur={() => validateFields({ funcionario, setInputErrors })}
            id="nomeMae"
            name="nomeMae"
            textInputTitle="Nome Mãe:"
            error={inputErrors.nomeMae}
            maxLength={256}
          />
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formBasicCheckbox">
            <Switch
              disabled={!funcionario}
              value={funcionario?.atualizarPesquisa ? 1 : 0}
              checked={funcionario?.atualizarPesquisa}
              onChange={toggleSwitchAtualizacaoAutomatica}
              name="atualizacaoAutomatica"
              id="pesquisa"
              type="switch"
              label="Atualizar pesquisa automaticamente"
            />
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
});
