/* eslint-disable */
import { FC, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { ListagemHistoricoPesquisa, Item } from '.';
import Input from '../../../components/Input';
import { TIPOS_CONDUTOR_AJUDANTE } from '../../../constants/Condutor';
import { SituacaoVigenciaContainer } from './styles';
import getUfEndereco from '../../../util/getUfEndereco';
import { ResumoProcessual } from '../../../components/ResumoProcessual';
import { getCookieSessionData } from '../../../services/cookieService';
import { CondutorPesquisa } from '../../../Types/PesquisaCongeladaType';
import { obterData } from '../../../util/date';

type Props = {
  condutor: CondutorPesquisa | undefined;
  solicitacao: ListagemHistoricoPesquisa;
};
const DadosPesquisaCondutor: FC<Props> = ({ condutor, solicitacao }) => {
  const { role } = getCookieSessionData().usuarioVO;
  const [item, setItem] = useState<Item | undefined>();

  useEffect(() => {
    setItem(solicitacao.itens.find((each: Item) => each.itemPesquisado === condutor?.cpf));
  }, [solicitacao, condutor]);

  return (
    <>
      <Form.Label className="SMLancadasTitle">Dados da Pequisa Condutor</Form.Label>
      <Form.Row>
        <Input
          required
          value={condutor?.cpf}
          onChange={() => false}
          id="cpf"
          name="cpf"
          textInputTitle="CPF:"
          disabled
        />
        <Input
          required
          value={
            TIPOS_CONDUTOR_AJUDANTE.find((each) => each.id.toString() === condutor?.tipo)
              ?.displayName
          }
          onChange={() => false}
          id="tipo"
          name="tipo"
          textInputTitle="Tipo:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={condutor?.nome}
          onChange={() => false}
          id="nome"
          name="nome"
          textInputTitle="Nome:"
          disabled
        />
        <Input
          required
          value={condutor?.dataNascimento ? obterData(condutor?.dataNascimento) : ''}
          onChange={() => false}
          id="nascimento"
          name="nascimento"
          textInputTitle="Nascimento:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={condutor?.rg}
          onChange={() => false}
          id="rg"
          name="rg"
          textInputTitle="RG nº:"
          disabled
        />
        <Input
          required
          value={
            isNaN(condutor?.ufEmissor as any)
              ? condutor?.ufEmissor
              : getUfEndereco(parseInt(condutor?.ufEmissor as any))
          }
          onChange={() => false}
          id="ufEmissaoRg"
          name="ufEmissaoRg"
          textInputTitle="UF:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={condutor?.nomePai}
          onChange={() => false}
          id="nomePai"
          name="nomePai"
          textInputTitle="Pai:"
          disabled
        />
        <Input
          required
          value={condutor?.nomeMae}
          onChange={() => false}
          id="nomeMae"
          name="nomeMae"
          textInputTitle="Mãe:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={`${condutor?.logradouro}, ${condutor?.logradouroNumero}`}
          onChange={() => false}
          id="endereco"
          name="endereco"
          textInputTitle="Endereço:"
          disabled
        />
        <Input
          required
          value={condutor?.bairro}
          onChange={() => false}
          id="bairro"
          name="bairro"
          textInputTitle="Bairro:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={condutor?.cep}
          onChange={() => false}
          id="cep"
          name="cep"
          textInputTitle="CEP:"
          disabled
        />
        <Input
          required
          value={condutor?.cidade}
          onChange={() => false}
          id="cidade"
          name="cidade"
          textInputTitle="Cidade:"
          disabled
        />
        <Input
          required
          value={
            isNaN(condutor?.uf as any) ? condutor?.uf : getUfEndereco(parseInt(condutor?.uf as any))
          }
          onChange={() => false}
          id="uf"
          name="uf"
          textInputTitle="UF:"
          disabled
        />
      </Form.Row>
      <SituacaoVigenciaContainer>
        <div>
          <span>
            Situação:{' '}
            <span
              style={{
                color: item?.statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO' ? 'red' : '',
              }}
            >
              {item?.statusPesquisa === 'EM_ACORDO'
                ? 'Em Acordo'
                : item?.statusPesquisa === 'EM_ANDAMENTO'
                ? 'Em Andamento'
                : item?.statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO'
                ? 'Pendente de Documentação'
                : null}
            </span>
          </span>
        </div>
        {item?.statusPesquisa != 'EM_ACORDO' ? null : (
          <div>
            <span>
              Vigência:{' '}
              <span>
                {item?.validade != 'No Embarque' ? obterData(item?.validade) : item?.validade}
              </span>
            </span>
          </div>
        )}
      </SituacaoVigenciaContainer>

      {role === 'admin' && item?.statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO' ? (
        <>
          <Form.Row className="mt-4">
            <Form.Label>Resumo Processual</Form.Label>
          </Form.Row>
          <ResumoProcessual numLiberacao={solicitacao.numLiberacao} tipo="CPF" />
        </>
      ) : null}
    </>
  );
};

export default DadosPesquisaCondutor;
