import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { cpfMask, FormataStringData2, maskTel } from '../../util/Validacoes/Validacoes';
import { FiltroAjudante } from './ConsultarAjudante';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';

type ListagemConsulta = {
  razaoSocialCliente: string;
  cpfAjudante: string;
  nomeAjudante: string;
  tipoAjudante: string;
  atualizaDadosAjudante: boolean;
  idFuncionario: number;
  bloqueado: boolean;
  logradouro: string;
  numero: number;
  cidade: string;
  ufCidade: string;
  ufRg: string;
  telefone: string;
  telefone2: string; // telefone referencia ajudante
  nomeMae: string;
  nomePai: string;
  dataNascimento: string;
  orgaoEmissor: string;
  bairro: string;
  complemento: string;
  rg: string;
  telefoneNextel: string; // telefone 2 ajudante
};

type Props = {
  canDelete?: boolean;
};

const ListagemConsultaAjudante = ({ canDelete }: Props) => {
  const history = useHistory();
  const location: {
    state: { listagem: ListagemConsulta[]; model: FiltroAjudante };
  } = useLocation();
  const { state } = location;
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [nomeAjudante, setNomeAjudante] = useState<string | undefined>();
  const [idAjudante, setIdAjudante] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [show, setShow] = useState(false);
  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [edicaoAcessada, setEdicaoAcessada] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [historicosPerPage, setHistoricosPerPage] = useState(10);
  const indexOfLastPost = currentPage * historicosPerPage;
  const indexOfFirstPost = indexOfLastPost - historicosPerPage;
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const [itensPerPage, setItensPerPage] = useState('10');
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const titles = [
    '+id',
    'Cliente',
    'CPF',
    'Nome',
    'Tipo',
    'Bloqueado',
    'Atualizar Pesquisa Automaticamente',
  ];

  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>([
    {
      xSteps: 1,
      ySteps: 0,
      columns: [
        {
          title: 'Ajudantes',
          widthPx: 1060,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Empresa', width: { wch: 30 } },
        { title: 'Ajudante', width: { wch: 30 } },
        { title: 'CPF', width: { wch: 15 } },
        { title: 'Tipo', width: { wch: 10 } },
        { title: 'RG nº', width: { wch: 20 } },
        { title: 'RG UF', width: { wch: 5 } },
        { title: 'Órgão', width: { wch: 40 } },
        { title: 'Pai', width: { wch: 30 } },
        { title: 'Mãe', width: { wch: 30 } },
        { title: 'Nascimento', width: { wch: 15 } },
        { title: 'Cidade', width: { wch: 20 } },
        { title: 'UF', width: { wch: 5 } },
        { title: 'Logradouro', width: { wch: 30 } },
        { title: 'Telefone(1)', width: { wch: 15 } },
        { title: 'Telefone(2)', width: { wch: 15 } },
        { title: 'Referência(1)', width: { wch: 15 } },
        { title: 'Bloqueado' },
        { title: 'Atualização Automática', width: { wch: 20 } },
      ],
      data: state.listagem.map((each) => [
        { value: each.razaoSocialCliente },
        { value: each.nomeAjudante },
        { value: each.cpfAjudante.includes('-') ? each.cpfAjudante : cpfMask(each.cpfAjudante) },
        { value: each.tipoAjudante },
        { value: each.rg ? each.rg : '' },
        { value: each.ufRg },
        { value: each.orgaoEmissor ? each.orgaoEmissor : '' },
        { value: each.nomePai },
        { value: each.nomeMae },
        { value: FormataStringData2(each.dataNascimento) },
        { value: each.cidade },
        { value: each.ufCidade },
        {
          value: `${each.logradouro}, ${each.numero ? each.numero : ''} ${
            each.bairro ? each.bairro : ''
          }, ${each.complemento ? each.complemento : ''}, ${each.cidade} - ${each.ufCidade}`,
        },
        { value: each.telefone.includes('(') ? each.telefone : maskTel(each.telefone) },
        {
          value: each.telefoneNextel.includes('(')
            ? each.telefoneNextel
            : maskTel(each.telefoneNextel),
        },
        { value: each.telefone2.includes('(') ? each.telefone2 : maskTel(each.telefone2) },
        { value: each.bloqueado === true ? 'Sim' : 'Não' },
        { value: each.atualizaDadosAjudante === true ? 'Sim' : 'Não' },
      ]),
    },
  ]);

  const handleObject = (listagem: any[]) => {
    const array = listagem.map((each: ListagemConsulta) => {
      return [
        each.idFuncionario,
        each.razaoSocialCliente,
        each.cpfAjudante,
        each.nomeAjudante,
        each.tipoAjudante,
        each.bloqueado ? 'Sim' : 'Não',
        each.atualizaDadosAjudante ? 'Sim' : 'Não',
      ];
    });
    return array;
  };

  const handleDelete = async (id: number | undefined) => {
    try {
      const response = await axios.put(`/ajudante-funcionario/delete-logico/${id}`);
      console.log(response);
      setExclusaoOK(true);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      setMensagemErro('Erro ao excluir!');
    }
  };

  useEffect(() => {
    setDataTabela(handleObject(state.listagem));
    const ajudante = state.listagem
      ?.filter((aju) => aju.idFuncionario === idAjudante)
      .find((each) => each.nomeAjudante);
    setNomeAjudante(ajudante?.nomeAjudante);
  }, [state, idAjudante]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(dataTabela, searchTerm));
    }
  }, [searchTerm]);

  useEffect(() => {
    const reloadDados = async () => {
      try {
        const response = await axios.get(`/ajudante-funcionario/consulta-ajudante-gerenciar`, {
          params: {
            ...state.model,
          },
        });
        setDataTabela(handleObject(response.data));
      } catch (err: any) {
        console.log(err.response);
      }
    };
    reloadDados();
  }, [edicaoAcessada]);

  return (
    <Tela
      nome="Ajudante"
      caminho="Cadastro > Gerenciamento de Clientes > Ajudante"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchTerm}
      search={searchTerm}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={currentPage.toString()}
      excelGeneration={excelGeneration}
      filename="Ajudantes Funcionários (Não Motoristas)"
    >
      <div style={{ display: 'flex', justifyContent: 'row' }}>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          enforceFocus
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton>
            {!exclusaoOK && !exclusaoErro && (
              <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
            )}
            {exclusaoOK && <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>}
          </Modal.Header>

          {!exclusaoOK && !exclusaoErro && (
            <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
              Deseja realmente excluir o registro: {`${nomeAjudante}`}?
            </Modal.Body>
          )}

          {exclusaoOK && (
            <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
              Registro excluído com sucesso.
            </Modal.Body>
          )}

          {exclusaoErro && (
            <Modal.Body
              style={{ fontSize: 20, alignSelf: 'center' }}
            >{`${mensagemErro}`}</Modal.Body>
          )}

          {!exclusaoErro && !exclusaoErro && !exclusaoOK && (
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ width: '120px', height: '50px', marginRight: '2%' }}
                onClick={() => setShow(false)}
                label="Não"
              />
              <Button
                style={{ width: '120px', height: '50px' }}
                variant="secondary"
                onClick={() => handleDelete(idAjudante)}
                label="Sim"
              />
            </Modal.Footer>
          )}
          {exclusaoOK && (
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ width: '120px', height: '50px', marginRight: '2%' }}
                onClick={() => {
                  window.location.reload();
                }}
                label="OK"
              />
            </Modal.Footer>
          )}
        </Modal>
        {loadingSearch ? (
          <Spinner />
        ) : (
          <Tabela
            data={currentData}
            titles={titles}
            left
            onClickEditar={(id: any) => {
              setEdicaoAcessada(true);
              history.push({
                pathname: `/ajudantes/editar/${id}`,
              });
            }}
            onClickExcluir={(id: any) => {
              if (canDelete) {
                setExclusaoErro(false);
                setExclusaoOK(false);
                setShow(true);
                setIdAjudante(id);
              } else {
                history.push('/acessonegado');
              }
            }}
          />
        )}
      </div>
    </Tela>
  );
};

export default ListagemConsultaAjudante;
