/* eslint-disable */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import { getCookieSessionData } from '../../../../services/cookieService';
import { ClientesType } from '../../../../api/model/UsuariosType';
import {
  ConsultaLancamentoNaoConformidadeType,
  NaturezaDoEventoType,
} from '../../../../api/model/SolicitacaoMonitoramentoType';
import { validarPlaca, cpfMask } from '../../../../util/Validacoes/Validacoes';
import { useToast } from '../../../../hooks/Toast';
import { soNumeros } from '../../../../util/Validacoes/Validacoes';
import checaCPF from '../../../../util/checaCpf';
import axios from '../../../../config/axiosMaquina';
import ButtonsForm from '../../../../components/ButtonsForm/ButtonsForm';
import { dataAtualFormatoAnoMesDia, toZoned } from '../../../../util/date';
import { Tela } from '../../../../components/Tela';

const ConsultaNaoConformidade = () => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [eventos, setEventos] = useState<NaturezaDoEventoType[]>([]);
  const [clientes, setClientes] = useState<ClientesType[]>([]);
  const [model, setModel] = useState<ConsultaLancamentoNaoConformidadeType>({
    clienteId: null,
    dtOcorrenciaInicial: null,
    dtOcorrenciaFinal: null,
    placaVeiculo: null,
    cpfCondutor: null,
    idSM: null,
    observacao: null,
    naoConformidadeId: null,
    situacao: null,
  });

  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const roleLogado = getCookieSessionData().usuarioVO.role;
  const razaoSocialEmbarcador = getCookieSessionData().usuarioVO.clienteRazaoSocial;
  const { cnpj, isEmbarcador, role } = getCookieSessionData().usuarioVO;
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const periodoObrigatório = role === 'user' || role === 'admin';

  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    if (idCliente || isEmbarcador) {
      getClientes();
    } else {
      axios.get(`/cliente/getIdRazaoSocial`).then((response) => {
        setClientes(response.data);
      });
    }
  }, [isEmbarcador]);

  const getClientes = async () => {
    const res = await axios.get(`/embarcador/embarcador-rel-cliente-por-cnpj?cnpj=${cnpj}`);
    setClientes(res.data);
  };

  useEffect(() => {
    if (idCliente != null) {
      axios.get(`/nao-conformidade/cliente?clienteId=${idCliente}`).then((response) => {
        setEventos(response.data);
      });
    }
  }, []);

  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    setModel((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }

  const buscar = async (event: any) => {
    event.preventDefault();

    try {
      if (
        !model.dtOcorrenciaInicial ||
        !model.dtOcorrenciaFinal ||
        (roleLogado === 'admin' && !model.clienteId)
      ) {
        setIsFormInvalid(true);
        addToast({
          title: 'Erro',
          description: 'Preencha os campos obrigatórios.',
          type: 'error',
        });
        return;
      }
      history.push({
        pathname: `/nao-conformidade/consulta/grid`,
        state: {
          clienteId: model.clienteId,
          dtOcorrenciaInicial: model.dtOcorrenciaInicial
            ? toZoned(model.dtOcorrenciaInicial)
            : null,
          dtOcorrenciaFinal: model.dtOcorrenciaFinal
            ? toZoned(model.dtOcorrenciaFinal, false)
            : null,
          placaVeiculo: model.placaVeiculo,
          cpfCondutor: model.cpfCondutor,
          idSM: model.idSM,
          observacao: model.observacao,
          naoConformidadeId: model.naoConformidadeId,
          situacao: model.situacao,
        },
      });
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro',
        description: 'Houve algum erro!',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    console.log(model);
  }, [model]);

  return (
    <Tela
      nome="Consulta de Não Conformidades"
      caminho="Não Conformidades > Consulta"
      loading={loading}
      setLoading={setLoading}
    >
      <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
        {roleLogado === 'user' && isEmbarcador === false ? (
          <>
            <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
            <Form.Row className="row-container-modal">
              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatório ? 'requiredField' : ''}>
                  Data Início
                </Form.Label>
                <Form.Control
                  value={model.dtOcorrenciaInicial != null ? model.dtOcorrenciaInicial : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value.length > 10 ? null : updateModel(e);
                  }}
                  id="periodoInicial"
                  name="dtOcorrenciaInicial"
                  maxLength={10}
                  type="date"
                  required={periodoObrigatório}
                />
              </Form.Group>
              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatório ? 'requiredField' : ''}>
                  Data fim
                </Form.Label>
                <Form.Control
                  value={model.dtOcorrenciaFinal != null ? model.dtOcorrenciaFinal : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value.length > 10 ? null : updateModel(e);
                  }}
                  id="periodoFinal"
                  name="dtOcorrenciaFinal"
                  maxLength={10}
                  type="date"
                  required={periodoObrigatório}
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Veículo</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>PLACA</Form.Label>
                <Form.Control
                  id="placa"
                  name="placaVeiculo"
                  value={model.placaVeiculo?.toUpperCase()}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (validarPlaca(e.target.value)) {
                      updateModel(e);
                    }
                  }}
                  maxLength={7}
                  minLength={7}
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por condutor</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  id="cpf"
                  //value={model.cpfCondutor}
                  onChange={(e: any) => {
                    if (checaCPF(e.target.value) === false) {
                      setModel((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: cpfMask(e.target.value),
                        };
                      });
                      setIsValid(false);
                    } else {
                      setModel((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: cpfMask(e.target.value),
                        };
                      });
                      setIsValid(true);
                    }
                  }}
                  maxLength={14}
                  minLength={14}
                  name="cpfCondutor"
                  type="text"
                />
                {!isValid && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Cpf inválido</p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por viagem</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>NÚMERO SM</Form.Label>
                <Form.Control
                  value={model.idSM != null ? model.idSM : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: soNumeros(e.target.value),
                      };
                    });
                  }}
                  id="idSM"
                  name="idSM"
                  maxLength={10}
                  type="text"
                />
              </Form.Group>
            </Form.Row>
          </>
        ) : roleLogado === 'admin' || roleLogado === 'corretora' || roleLogado === 'seguradora' ? (
          <>
            <Form.Label className="SMLancadasTitle">Busca por cliente</Form.Label>
            <Form.Group>
              <Form.Label className="requiredField">EMPRESA</Form.Label>
              <Dropdown
                style={{ width: '100%' }}
                value={model.clienteId}
                name="clienteId"
                onChange={(e: any) => {
                  setModel((prevState) => {
                    return {
                      ...prevState,
                      clienteId: e.target.value,
                    };
                  });
                }}
                options={clientes}
                optionLabel="razaoSocial"
                required
                optionValue="id"
                filter
                showClear
                filterBy="razaoSocial"
                placeholder="Selecione"
              />
            </Form.Group>

            <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
            <Form.Row className="row-container-modal">
              <Form.Group className="row-modal">
                <Form.Label className="requiredField">Data início</Form.Label>
                <Form.Control
                  required
                  value={model.dtOcorrenciaInicial != null ? model.dtOcorrenciaInicial : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value.length > 10 ? null : updateModel(e);
                  }}
                  id="periodoInicial"
                  name="dtOcorrenciaInicial"
                  maxLength={10}
                  max={dataAtualFormatoAnoMesDia()}
                  type="date"
                />
              </Form.Group>
              <Form.Group className="row-modal">
                <Form.Label className="requiredField">Data final</Form.Label>
                <Form.Control
                  required
                  value={model.dtOcorrenciaFinal != null ? model.dtOcorrenciaFinal : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value.length > 10 ? null : updateModel(e);
                  }}
                  id="periodoFinal"
                  name="dtOcorrenciaFinal"
                  maxLength={10}
                  type="date"
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por veículo</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>PLACA</Form.Label>
                <Form.Control
                  id="placa"
                  name="placaVeiculo"
                  value={model.placaVeiculo?.toUpperCase()}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (validarPlaca(e.target.value)) {
                      updateModel(e);
                    }
                  }}
                  maxLength={7}
                  minLength={7}
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por viagem</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>NÚMERO SM</Form.Label>
                <Form.Control
                  value={model.idSM != null ? model.idSM : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: soNumeros(e.target.value),
                      };
                    });
                  }}
                  name="idSM"
                  maxLength={10}
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            {/* <h6>Busca por observações</h6> */}
            <Form.Label className="SMLancadasTitle">Busca por observações</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>QUE CONTENHA EM OBSERVAÇÕES</Form.Label>
                <Form.Control
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  //value={model.observacao}
                  id="observacoes"
                  name="observacao"
                  maxLength={256}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form.Row>
          </>
        ) : (
          <></>
        )}

        {roleLogado === 'user' && isEmbarcador === true ? (
          <>
            <Form.Label className="SMLancadasTitle">Busca por transportador</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>TRANSPORTADOR</Form.Label>
                <br />
                <Dropdown
                  style={{ width: '100%' }}
                  value={model.clienteId}
                  name="idCliente"
                  onChange={(e: any) => {
                    setModel((prevState) => {
                      return {
                        ...prevState,
                        clienteId: e.target.value,
                      };
                    });
                  }}
                  options={clientes}
                  optionLabel="razaoSocial"
                  optionValue="idCliente"
                  filter
                  showClear
                  filterBy="razaoSocial"
                  placeholder="Selecione"
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Evento</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>EVENTO</Form.Label>
                <br />
                <Dropdown
                  style={{ width: '100%' }}
                  value={model.naoConformidadeId}
                  name="naoConformidadeId"
                  onChange={(e: any) => updateModel(e)}
                  options={eventos}
                  optionLabel="naturezaEvento"
                  optionValue="id"
                  filter
                  showClear
                  filterBy="naturezaEvento"
                  placeholder="Selecione"
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>Data início</Form.Label>
                <Form.Control
                  value={model.dtOcorrenciaInicial != null ? model.dtOcorrenciaInicial : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value.length > 10 ? null : updateModel(e);
                  }}
                  id="periodoInicial"
                  name="dtOcorrenciaInicial"
                  maxLength={10}
                  type="date"
                />
              </Form.Group>
              <Form.Group className="row-modal">
                <Form.Label>Data final</Form.Label>
                <Form.Control
                  value={model.dtOcorrenciaFinal ? model.dtOcorrenciaFinal : undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value.length > 10 ? null : updateModel(e);
                  }}
                  id="periodoFinal"
                  name="dtOcorrenciaFinal"
                  maxLength={10}
                  type="date"
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por veículo</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>PLACA</Form.Label>
                <Form.Control
                  id="placa"
                  name="placaVeiculo"
                  value={model.placaVeiculo?.toUpperCase()}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (validarPlaca(e.target.value)) {
                      updateModel(e);
                    }
                  }}
                  maxLength={7}
                  minLength={7}
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por viagem</Form.Label>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>NÚMERO SM</Form.Label>
                <Form.Control
                  type="text"
                  value={model.idSM != null ? model.idSM : undefined}
                  onChange={(e: any) => {
                    setModel((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: soNumeros(e.target.value),
                      };
                    });
                  }}
                  name="idSM"
                  maxLength={10}
                />
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Situação</Form.Label>
            <Form.Check
              type="checkbox"
              label="Pendentes"
              value="Pendentes"
              onChange={(e: any) => {
                setModel((prevState) => {
                  return {
                    ...prevState,
                    situacao: e.target.value,
                  };
                });
              }}
            />
            <Form.Check
              type="checkbox"
              label="Em Andamento"
              value="Em Andamento"
              onChange={(e: any) => {
                setModel((prevState) => {
                  return {
                    ...prevState,
                    situacao: e.target.value,
                  };
                });
              }}
            />
            <Form.Check
              type="checkbox"
              label="Finalizadas"
              value="Finalizadas"
              onChange={(e: any) => {
                setModel((prevState) => {
                  return {
                    ...prevState,
                    situacao: e.target.value,
                  };
                });
              }}
            />
            <Form.Check
              type="checkbox"
              label="Canceladas"
              value="Canceladas"
              onChange={(e: any) => {
                setModel((prevState) => {
                  return {
                    ...prevState,
                    situacao: e.target.value,
                  };
                });
              }}
            />
          </>
        ) : (
          <></>
        )}
        <ButtonsForm />
      </Form>
    </Tela>
  );
};

export default ConsultaNaoConformidade;
