/* eslint-disable */
import axios from '../../config/axiosMaquina';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { PreAgendamentoSMType } from '../../api/model/PreAgendamentoSMTypes';
import { getCookieSessionData } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';
import { Tela } from '../../components/Tela';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { obterDataHora } from '../../util/date';

type Props = {
  canDelete?: boolean;
  canInsert?: boolean;
};

const PreAgendamentoSM = ({ canDelete, canInsert }: Props) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [idSm, setIdSm] = useState<number>();
  const [preAgendamentosSM, setPreAgendamentosSM] = useState<PreAgendamentoSMType[]>([]);
  const [searchResults, setSearchResults] = useState<PreAgendamentoSMType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const history = useHistory();
  const cnpjEmbarcador = getCookieSessionData().usuarioVO.cnpj;
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = (searchTerm.trim().length ? searchResults : preAgendamentosSM)?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = (pageNumber: number) => {
    history.push(`/pre-agendamento-viagens/${pageNumber}`);
  };
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/solicitacao-monitoramento/listar-grid-pre-sm-embarcador`, {
        params: {
          cnpjEmbarcador: cnpjEmbarcador,
        },
      });
      setPreAgendamentosSM(response.data);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  async function handleDelete(idSm: any) {
    setLoadingDelete(true);
    try {
      await axios.put(`/solicitacao-monitoramento/deleteLogicoPreSM/${idSm}`);
      addToast({
        title: 'Sucesso!',
        description: 'Pre-Agendamento cancelado com sucesso.',
        type: 'success',
      });
      setLoadingDelete(false);
      setShow(false);
      fetchData();
    } catch (alert) {
      setLoadingDelete(false);
      ('Erro ao Desativar Cadastro');
    }
  }

  useEffect(() => {
    if (searchTerm.trim() != '') {
      setSearchResults(
        preAgendamentosSM.filter(
          (each: PreAgendamentoSMType) =>
            each.idSm?.toString().includes(searchTerm) ||
            each.transportador?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            obterDataHora(each.inicioViagem, true)?.includes(searchTerm.toLowerCase()) ||
            obterDataHora(each.fimViagem, true)?.includes(searchTerm.toLowerCase()) ||
            handleSituacao(each)?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm]);

  const handleSituacao = (each: PreAgendamentoSMType) => {
    return each.cancelada ? 'Cancelada' : each.ativo === false ? 'Pendente' : 'S.M n° ' + each.idSm;
  };

  return (
    <Tela
      nome="Pré-SM"
      caminho="Operacional > Pré-SM"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd="/cliente/presm/agendar-viagens"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm ? searchResults?.length : preAgendamentosSM?.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          {loadingDelete ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            `Deseja realmente cancelar o registro ${idSm}?`
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Voltar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(idSm)}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="painel">
        <div className="table-responsive" style={{ flex: 1 }}>
          {loadingSearch ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Nº SM</th>
                  <th>Transportador</th>
                  <th>Início Viagem</th>
                  <th>Término Viagem</th>
                  <th>Situação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((each, index) => (
                  <tr key={index}>
                    <td>{each.idSm}</td>
                    <td>{each.transportador}</td>
                    <td>{obterDataHora(each.inicioViagem, true)}</td>
                    <td>{obterDataHora(each.fimViagem, true)}</td>
                    <td>{handleSituacao(each)}</td>
                    {!each.cancelada && each.ativo === false ? (
                      <td>
                        <ButtonTooltip
                          type="Cancelar"
                          onClick={() => {
                            if (canDelete) {
                              setIdSm(each.idSm);
                              setShow(true);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                        />
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Tela>
  );
};

export default PreAgendamentoSM;
