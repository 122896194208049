/* eslint-disable */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from '../../assets/logo_golden.jpg';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import { getCookieSessionData } from '../../services/cookieService';
import { obterData, obterDataHora } from '../../util/date';

const GeneratePDFChecklist = (dadosTabela: any, model: any, totalData: any) => {
  const doc = new jsPDF();

  let tableColumn: any = [];
  let tableRows: any = [];

  let end: boolean = false;

  const filtrarDispositivos = (veiculo: any) => {
    return veiculo.listaDispositivo.filter(
      (each: any) => each.situacaoDispositivo === '3' || each.situacaoDispositivo === '2'
    );
  };

  const empresa = model.nomeClienteTransportador
    ? model.nomeClienteTransportador
    : getCookieSessionData().usuarioVO.clienteRazaoSocial;
  let counter = 0;

  dadosTabela.map((veiculo: any, i: any) => {
    title(veiculo);
    let tableTitle = ['Dispositivos Avaliados', 'Situação'];

    tableColumn = tableTitle;

    const checklist = filtrarDispositivos(veiculo);
    checklist.map((each: any, idx: any) => {
      let tableDataRow = [
        each.nomeDispositivo,
        each.situacaoDispositivo === '3' ? 'Com Defeito' : 'Ok',
      ];

      tableRows.push(tableDataRow);

      if (dadosTabela.length === i + 1) end = true;

      if (checklist.length === idx + 1) {
        page();
        tableRows.length = 0;
      }
    });
  });

  function title(veiculo: any) {
    doc.setFontSize(10);
    doc.text(
      `Veículo: ${
        veiculo.placaVeiculo
          ? veiculo.placaVeiculo
          : veiculo.placaCarreta
          ? veiculo.placaCarreta
          : veiculo.numeroBau
          ? veiculo.numeroBau
          : ''
      }`,
      15,
      60
    );
    doc.setFontSize(10);
    doc.text(
      `${
        veiculo.tipoFrotaCarreta
          ? getTipoFrota(veiculo.tipoFrotaCarreta)
          : veiculo.tipoFrotaVeiculo
          ? getTipoFrota(veiculo.tipoFrotaVeiculo)
          : ''
      }`,
      80,
      60
    );
    doc.setFontSize(10);
    doc.text(
      `Data da realização: ${
        veiculo.dataInclusao ? obterDataHora(veiculo.dataInclusao, true) : ''
      }`,
      15,
      65
    );
    doc.setFontSize(10);
    doc.text(
      `Validade: ${
        veiculo.listaDispositivo.filter((each: any) => each.situacaoDispositivo.includes('3'))
          .length > 0
          ? `Checklist Não OK`
          : veiculo.checklistValidade.includes('EMBARQUE')
          ? veiculo.checklistValidade.split(': ')[1]
          : veiculo.dataValidade
          ? obterDataHora(veiculo.dataValidade, true)
          : ''
      }`,
      80,
      65
    );

    doc.setFontSize(10);
    doc.text(`Responsável: ${veiculo.responsavel ? veiculo.responsavel : ''}`, 15, 70);
    doc.setFontSize(10);
    doc.text(`${veiculo.telefoneResponsavel ? veiculo.telefoneResponsavel : ''}`, 80, 70);
    doc.setFontSize(10);
    doc.text(`Operador: ${veiculo.usuarioLancamento ? veiculo.usuarioLancamento : ''}`, 15, 75);
  }

  function page() {
    // @ts-ignore

    doc.autoTable(tableColumn, tableRows, {
      startY: 80,
      theme: 'plain',
      styles: {
        overflow: 'linebreak',
        fontSize: 10,
        valign: 'middle',
      },
      columnStyles: {
        0: { valign: 'top' },
      },
      margin: { right: 95 },
      pageBreak: 'avoid',
      tableWidth: 'auto',
    });
    doc.addImage(Logo, 'jpg', 10, 10, 80, 20);

    doc.setFontSize(10);
    doc.text(`Página ${doc.internal.getNumberOfPages()}/${totalData}`, 180, 30);
    doc.text(`${new Date().toLocaleDateString('pt-BR')}`, 180, 35);
    doc.line(10, 38, 200, 38);
    doc.setFontSize(15);
    doc.text('Checklist Veículos', 80, 35);

    doc.setFontSize(10);
    doc.text(`Empresa: ${empresa} `, 15, 45);
    doc.setFontSize(10);
    doc.text(
      `PERÍODO: ${model.dataInicio ? obterData(model.dataInicio) : '/ /'} e ${
        model.dataFim ? obterData(model.dataFim) : '/ /'
      }`,
      142,
      45
    );
    doc.line(10, 50, 200, 50);

    if (!end) doc.addPage();
  }
  const date = new Date().toLocaleDateString('pt-BR').split(' ');
  const dateStr = date[0];

  doc.save(`relatorio_checklist${dateStr}.pdf`);
};

export default GeneratePDFChecklist;
