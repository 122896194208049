import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import api from '../../../config/axiosMaquina';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { Tela } from '../../../components/Tela';
import { useToast } from '../../../hooks/Toast';
import { maskTel, soNumeros, validaEmail } from '../../../util/Validacoes/Validacoes';
import { GerenteType } from '../../../Types/Gerente';

export const GerentesCadastro = () => {
  const defaultModel = { id: 0, ativo: true, nome: '', email: '', telefone: '' };
  const [model, setModel] = useState<GerenteType>(defaultModel);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isValidTelefone, setIsValidTelefone] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [header, setHeader] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { id } = useParams<any>();
  const { addToast } = useToast();
  const history = useHistory();

  const findGerente = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/gerente/${id}`);
      setModel(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      findGerente();
    }
  }, []);

  const updateModel = (e: any) => {
    if (!model) {
      return;
    }
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const handlePut = async (e: any) => {
    e.preventDefault();
    setLoadingRequest(true);
    try {
      await api.put(`/gerente/atualizar/${id}`, {
        id,
        nome: model.nome,
        telefone: soNumeros(model.telefone),
        email: model.email,
        ativo: true,
      });
      setHeader('Gerente editado com sucesso!');
      setDialog(true);
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao editar gerente.',
        type: 'error',
      });
    } finally {
      setLoadingRequest(false);
    }
  };

  const handlePost = async (e: any) => {
    e.preventDefault();
    setLoadingRequest(true);
    try {
      await api.post(`/gerente`, {
        nome: model.nome,
        telefone: soNumeros(model.telefone),
        email: model.email,
        ativo: true,
      });
      setHeader('Gerente cadastrado com sucesso!');
      setDialog(true);
      setModel(defaultModel);
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar gerente.',
        type: 'error',
      });
    } finally {
      setLoadingRequest(false);
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (
      !model.nome ||
      !model.email ||
      !model.telefone ||
      !isValidEmail ||
      soNumeros(model.telefone).length < 10
    ) {
      setIsFormInvalid(true);
      addToast({
        title: 'Erro',
        description: 'Preencha os campos obrigatórios.',
        type: 'error',
      });
      return;
    }

    if (id) {
      handlePut(e);
    } else {
      handlePost(e);
    }
  };

  return (
    <Tela nome="Gerentes" caminho="Cadastro > Gerentes > Cadastro" loading={loading}>
      <Dialog
        header={header}
        footer={
          <>
            <Button label="OK" onClick={() => history.push('/admin/listar/gerentes/1')} />
          </>
        }
        visible={dialog}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.push('/admin/listar/gerentes/1')}
      />
      <Form noValidate validated={isFormInvalid} onSubmit={onSubmit}>
        <Form.Row>
          <Form.Group as={Col} className="row-modal">
            <Form.Label className="requiredField">Nome</Form.Label>
            <Form.Control
              value={model.nome}
              onChange={updateModel}
              name="nome"
              type="text"
              placeholder="Nome"
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className="row-modal">
            <Form.Label className="requiredField">Telefone</Form.Label>
            <Form.Control
              value={maskTel(model.telefone)}
              onChange={(e: any) => {
                setModel({ ...model, telefone: e.target.value });
              }}
              name="telefone"
              type="text"
              placeholder="Telefone"
              maxLength={14}
              minLength={13}
              required
            />
            {model.telefone && soNumeros(model.telefone).length < 10 && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Telefone inválido</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className="row-modal">
            <Form.Label className="requiredField">E-mail</Form.Label>
            <Form.Control
              value={model.email}
              onChange={(e: any) => {
                updateModel(e);
                setIsValidEmail(validaEmail(e.target.value));
              }}
              name="email"
              type="email"
              placeholder="E-mail"
              required
            />
            {!isValidEmail && model.email.length >= 1 && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
            )}
          </Form.Group>
        </Form.Row>

        <ButtonsForm />
      </Form>
    </Tela>
  );
};
