/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Form, Row, Button, FormControl, Modal, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { SeguradoraType } from '../../Types/CorretoraType';
import api from '../../config/axiosMaquina';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';

type ModalSeguradoraProps = {
  showModalSeguradora: boolean;
  setShowModalSeguradora: (bool: boolean) => void;
};

const ModalAddSeguradora: FC<ModalSeguradoraProps> = ({
  showModalSeguradora,
  setShowModalSeguradora,
}) => {
  const history = useHistory();
  // const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [seguradora, setSeguradora] = useState<SeguradoraType>({
    segBlAtivo: true,
    segNuOrdem: 0,
  } as SeguradoraType);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadOK2, setIsDialogCadOK2] = useState(false);
  const { addToast } = useToast();

  const handleCadastro = async (event: any) => {
    event.preventDefault();

    if (
      !seguradora.segTxDescricao ||
      !seguradora.segTxNome ||
      seguradora.segTxDescricao.trim().length == 0 ||
      seguradora.segTxNome.trim().length == 0
    ) {
      setIsFormInvalid(true);
      setMensagem('Preencha os campos obrigatórios!');
      setIsDialogCadOK2(true);
      event.stopPropagation();
      return;
    }

    try {
      const response = await api.post('/seguradora/seguradora', seguradora);
      console.log(response.data);
      setLoading(false);
      setMensagem('Seguradora cadastrada com sucesso!');
      setIsDialogCadOK(true);
      setSeguradora({
        ...seguradora,
        segTxNome: '',
        segTxDescricao: '',
      });
    } catch (error: any) {
      console.log(error);
      setMensagem('Erro na comunicação com o servidor.');
      setIsDialogCadOK2(true);
      setLoading(false);
    }
  };

  const valida = (event: any) => {
    if (!event || event.trim().length == 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        animation
        show={showModalSeguradora}
        onHide={() => {
          setShowModalSeguradora(false);
          setSeguradora({
            ...seguradora,
            segTxNome: '',
            segTxDescricao: '',
          });
          // setIsFormInvalid(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Cadastro Seguradora</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Dialog
            header={mensagem}
            footer={
              <Button
                onClick={() => {
                  setIsDialogCadOK(false);
                  setShowModalSeguradora(false);
                }}
              >
                Ok
              </Button>
            }
            visible={isDialogCadOK}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setIsDialogCadOK(false);
              setShowModalSeguradora(false);
            }}
          />
          <Dialog
            header={mensagem}
            footer={
              <Button
                onClick={() => {
                  setIsDialogCadOK2(false);
                }}
              >
                Ok
              </Button>
            }
            visible={isDialogCadOK2}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setIsDialogCadOK2(false);
            }}
          />
          {/* <div style={{ display: 'flex', justifyContent: 'row' }}> */}
          <Form noValidate validated={isFormInvalid} onSubmit={handleCadastro}>
            <Form.Row className="container-buttons justify-content-end">
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome</Form.Label>
                <Form.Control
                  value={seguradora?.segTxNome}
                  onChange={(e) => {
                    setSeguradora({
                      ...seguradora,
                      segTxNome: e.target.value,
                    });
                  }}
                  onBlur={() => valida(seguradora?.segTxNome)}
                  type="text"
                  placeholder=""
                  maxLength={128}
                  required
                  title="Campo Obrigatório"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="container-buttons justify-content-end">
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Descrição</Form.Label>
                <Form.Control
                  value={seguradora?.segTxDescricao}
                  onChange={(e) => {
                    setSeguradora({
                      ...seguradora,
                      segTxDescricao: e.target.value,
                    });
                  }}
                  onBlur={() => valida(seguradora?.segTxDescricao)}
                  type="text"
                  placeholder=""
                  maxLength={128}
                  required
                  title="Campo Obrigatório"
                  // ref={ref}
                />
              </Form.Group>
            </Form.Row>
            <Form.Switch style={{ marginLeft: '-30px' }}>
              <Form.Group>
                <Form.Check
                  checked={seguradora?.segBlAtivo}
                  onChange={() => {
                    setSeguradora({
                      ...seguradora,
                      segBlAtivo: !seguradora.segBlAtivo,
                    });
                  }}
                  type="switch"
                  id="custom-switch1"
                  label="Ativo"
                />
              </Form.Group>
            </Form.Switch>
            {/* <ButtonsForm /> */}
          </Form>
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ color: '#000', fontWeight: 'bold' }}
            variant="primary"
            onClick={handleCadastro}
            // type="submit"
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalAddSeguradora;
