/* eslint-disable */
import { Dispatch, SetStateAction } from 'react';
import { errorsMessages } from '../../constants/Messages';
import checaCPF from '../../util/checaCpf';
import { validaNome, validaVazioNulo } from '../../util/Validacoes/Validacoes';
import { isDateLessThanBaseDate } from '../../util/Validacoes/Validacoes';

type Condutor =
  | {
      atualizarPesquisaAutomaticamente: boolean;
      bairro: string;
      categoriaCnh: string;
      cep: string;
      cidade: number;
      cnhRegistro: string;
      complementoLogradouro: string;
      cpf: string;
      dataNascimento: string;
      id: number;
      idUfEmissorCnh: number;
      idUfEmissorRg: number;
      logradouro: string;
      logradouroNumero: number;
      nome: string;
      nomeCidade: string;
      nomeMae: string;
      nomePai: string;
      nomeUfEndereco: string;
      rg: string;
      segurancaCnh: string;
      tipo: string;
      uf: number;
      validadeCnh: string;
    }
  | undefined;

type InputErrors = {
  condutor: string;
  cpf: string;
  nome: string;
  rg: string;
  tipo: string;
  cnhRegistro: string;
  categoriaCnh: string;
  dataNascimento: string;
  validadeCnh: string;
  segurancaCnh: string;
  idUfEmissorRg: string;
  idUfEmissorCnh: string;
  nomePai: string;
  nomeMae: string;
  cep: string;
  logradouro: string;
  logradouroNumero: string;
  complementoLogradouro: string;
  bairro: string;
  cidade: string;
  uf: string;
};

type Props = {
  condutor: Condutor;
  setInputErrors: Dispatch<SetStateAction<InputErrors>>;
};

const condutorFieldsValidation = ({ condutor, setInputErrors }: Props): boolean => {
  let valid = true;
  const errors: InputErrors = {} as InputErrors;

  if (!condutor) {
    return true;
  }

  if (condutor.cnhRegistro.length < 11) {
    errors.cnhRegistro = errorsMessages.input.cnhInvalid;
    valid = false;
  }

  if (condutor.segurancaCnh.length < 11) {
    errors.segurancaCnh = errorsMessages.input.cnhNumSegurancaInvalid;
    valid = false;
  }

  if (!validaVazioNulo(condutor.cpf)) {
    errors.cpf = errorsMessages.input.nullOrEmpty;
    valid = false;
  } else if (!checaCPF(condutor.cpf)) {
    errors.cpf = errorsMessages.input.cpfInvalid;
    valid = false;
  }

  if (!validaVazioNulo(condutor.nome)) {
    errors.nome = errorsMessages.input.nullOrEmpty;
    valid = false;
  } else if (!validaNome(condutor.nome)) {
    errors.nome = errorsMessages.input.invalidName;
    valid = false;
  }

  if (!validaVazioNulo(condutor.rg)) {
    errors.rg = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (condutor.tipo === 'Selecione' || !validaVazioNulo(condutor.tipo)) {
    errors.tipo = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.cnhRegistro)) {
    errors.cnhRegistro = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (condutor.categoriaCnh === 'Selecione' || !validaVazioNulo(condutor.categoriaCnh)) {
    errors.categoriaCnh = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.dataNascimento)) {
    errors.dataNascimento = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.validadeCnh)) {
    errors.validadeCnh = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.segurancaCnh)) {
    errors.segurancaCnh = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.idUfEmissorRg) || condutor.idUfEmissorRg?.toString() === '0') {
    errors.idUfEmissorRg = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.uf) || condutor.uf?.toString() === '0') {
    errors.uf = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (condutor.idUfEmissorCnh?.toString() === '0' || !validaVazioNulo(condutor.idUfEmissorCnh)) {
    errors.idUfEmissorCnh = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.cep)) {
    errors.cep = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.logradouro)) {
    errors.logradouro = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.logradouroNumero)) {
    errors.logradouroNumero = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.bairro)) {
    errors.bairro = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (condutor.cidade?.toString() === '0' || !validaVazioNulo(condutor.cidade)) {
    errors.cidade = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(condutor.nomePai)) {
    errors.nomePai = errorsMessages.input.nullOrEmpty;
    valid = false;
  } else if (!validaNome(condutor.nomePai)) {
    errors.nomePai = errorsMessages.input.nomeESobrenome;
    valid = false;
  }

  if (!validaVazioNulo(condutor.nomeMae)) {
    errors.nomeMae = errorsMessages.input.nullOrEmpty;
    valid = false;
  } else if (!validaNome(condutor.nomeMae)) {
    errors.nomeMae = errorsMessages.input.nomeESobrenome;
    valid = false;
  }

  setInputErrors(errors);

  return valid;
};

export default condutorFieldsValidation;
