/* eslint-disable */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IoAlertCircle } from 'react-icons/io5';
import Pagination from '../../../components/Pagination/Pagination';
import { AvisosBloqueios, AvisosOperacionaisTypes } from '../../../api/Types/Types';
import instanceMaquina from '../../../config/axiosMaquina';
import './styles.css';
import { getCookieSessionData } from '../../../services/cookieService';
import { Spinner } from 'react-bootstrap';
import Search from '../../../components/Search/Search';

const AvisosImportantes = () => {
  const [data, setData] = useState<AvisosOperacionaisTypes[]>([]);
  const [dataServicosContratados, setDataServicosContratados] = useState<AvisosBloqueios[]>([]);
  const [searchData, setSearchData] = useState<AvisosOperacionaisTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [totalGrid, setTotalGrid] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [avisoPerPage, setAvisoPerPage] = useState(10);
  const indexOfLastPost = currentPage * avisoPerPage;
  const indexOfFirstPost = indexOfLastPost - avisoPerPage;
  const currentAviso = searchData.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const { page }: any = useParams();
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await instanceMaquina.get(
          `/aviso-operacional/buscarporusuario/${idUsuario}`,
          {
            params: {
              type: 'AVISOS_IMPORTANTES',
              pagina: page - 1,
              qtdRegistros: itensPerPage,
            },
          }
        );
        setData(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    fetchData();
  }, [page, itensPerPage]);

  useEffect(() => {
    const fetchDataServicosContratados = async () => {
      try {
        setLoading(true);
        const response = await instanceMaquina.get(`/cliente-servico-contratado/getAll`);
        const listaClientesServicos = response.data.filter(
          (fil: any) =>
            fil.cscNuMonitoramentoVeiculos === 2 ||
            fil.cscNuWsposicoes === 2 ||
            fil.cscNuRotaPorPontos === 2 ||
            fil.cscNuCadastroConsulta === 2 ||
            fil.cscNuSmsviagem === 2 ||
            fil.cscNuMapaRastreamento === 2 ||
            fil.cscNuCronogramaViagens === 2
        );
        setDataServicosContratados(listaClientesServicos);

        // setDataServicosContratados(response.data);
        console.log(listaClientesServicos);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    fetchDataServicosContratados();
  }, [page, itensPerPage]);

  console.log(dataServicosContratados);

  useEffect(() => {
    if (searchData) {
      const totalRegistrosGrid = searchData.length + dataServicosContratados.length;
      setTotalGrid(totalRegistrosGrid);
    } else {
      const totalRegistrosGrid = data.length + dataServicosContratados.length;
      setTotalGrid(totalRegistrosGrid);
    }
  }, [data, dataServicosContratados, searchData]);

  function dataFinal(aviso: any) {
    const dc = new Date(aviso.dataCadastro);
    const permanenciaMs = 86400000 * aviso.diasPermanencia;
    const expiracaoMs = permanenciaMs + dc.getTime();
    const dayToDate = new Date(expiracaoMs);
    return dayToDate;
  }

  function avisoExpirado(aviso: any) {
    const current = new Date();
    if (current.getTime() > dataFinal(aviso).getTime())
      instanceMaquina.delete(`/aviso-operacional/${aviso.id}`).then((response: any) => {
        setData(response.data);
      });
    else return aviso.diasPermanencia;
  }

  useEffect(() => {
    instanceMaquina
      .get(`/aviso-operacional/buscarporusuario/${idUsuario}`)
      .then((response: any) => {
        const results = response.data.filter(
          (avisos: any) =>
            avisos.mensagem.toLowerCase().includes(searchTerm.toLowerCase()) ||
            avisos.prioridade.toLowerCase().includes(searchTerm.toLowerCase()) ||
            avisos.razaoSocialCliente.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchData(results);
      })
      .catch((error: any) => {
        setSearchData([]);
        console.log(error);
      });
  }, [searchValue]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <div className="content-container">
        <div className="painel">
          <div className="table-responsive" style={{ flex: 1 }}>
            <Search
              setLoading={setLoading}
              setSearch={setSearchTerm}
              setSearchValue={setSearchValue}
              search={searchTerm}
            />
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Mensagem</th>
                    {/* <th>Cliente</th> */}
                  </tr>
                </thead>
                <tbody>
                  {dataServicosContratados &&
                    dataServicosContratados.map((each) => {
                      return (
                        <tr>
                          <td>
                            <IoAlertCircle color={'#fc3103'} size={23} />
                            <span style={{ fontWeight: 'bold', fontSize: 18, marginLeft: '10px' }}>
                              Bloqueio {each.razaoSocialCliente}.
                            </span>{' '}
                            <span>
                              Não aceitar viagens nem por e-mail. Qualquer questionamento do cliente
                              sobre assunto financeiro, favor direcionar para (21)3644-9001
                              (21)3644-9003
                            </span>
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  {data &&
                    currentAviso.map((each, index) => {
                      avisoExpirado(each);
                      return (
                        <tr key={index}>
                          <td>
                            <IoAlertCircle
                              color={
                                each.prioridade === 'Média'
                                  ? '#fcba03'
                                  : each.prioridade === 'Alta'
                                  ? '#fc3103'
                                  : '#a4a4a4'
                              }
                              size={26}
                            />
                            <span className="row-aviso">{each.mensagem}</span>
                          </td>
                          {/* <td>{each.razaoSocialCliente}</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            <Pagination
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={totalGrid}
              // searchData
              //   ? searchData.length
              //   : data && data[0].totalRegistros
              //   ? data[0].totalRegistros
              //   : 0

              paginate={paginate}
              currentPage={currentPage.toString()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvisosImportantes;
