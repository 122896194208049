/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Form } from 'react-bootstrap';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import checaCPF from '../../util/checaCpf';
import { cpfMask, maskCpnj, soNumeros } from '../../util/Validacoes/Validacoes';
import '../ConsultarPesquisasLancadas/styles.css';
import ListagemConsultaProprietario from './ListagemConsultaProprietario';
import { Tela } from '../../components/Tela';

type ListagemConsulta = {
  idProprietario: number;
  cliente: string;
  cnpjProprietario: string;
  razaoSocialProprietario: string;
};

export type FiltroProprietario = {
  cnpjCpfProprietario: string | null;
  idCliente: number;
  razaoSocialProprietario: string | null;
  pagina: number;
  qtdRegistros: number;
  tipo: string | null;
};

const ConsultarProprietario = () => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const listaTipos = [
    { value: '', nome: 'Selecione' },
    { value: 'Fisica', nome: 'Física' },
    { value: 'Juridica', nome: 'Jurídica' },
  ];

  const [empresa, setEmpresa] = useState('');
  const [tipo, setTipo] = useState('');

  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [model, setModel] = useState<FiltroProprietario>({} as FiltroProprietario);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [dataResults, setDataResults] = useState<ListagemConsulta[]>();
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [itensPerPage, setItensPerPage] = useState('10');

  const [isCpfValid, setIsCpfValid] = useState(true);
  const [isCnpjValid, setIsCnpjValid] = useState(true);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaClientes(listaAtivos);
        console.log(listaAtivos);
      });
    };
    loadData();
  }, []);

  const buscar = async (event: any) => {
    event.preventDefault();
    if (cpf) {
      if (!isCpfValid) {
        addToast({
          title: 'Erro',
          description: 'Verifique os dados preenchidos!',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    if (cnpj) {
      if (!isCnpjValid) {
        addToast({
          title: 'Erro',
          description: 'Verifique os dados preenchidos!',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    try {
      const response = await api.get(`/proprietario/gerenciar-proprietario`, {
        params: {
          ...model,
        },
      });
      console.log(response);
      if (response.status === 200) {
        setCadastroRealizado(true);
        setDataResults(response.data);
        if (response.data.length === 0) {
          addToast({
            title: 'Erro',
            description: 'Não existe resultado para esse filtro!',
            type: 'error',
          });
          event.stopPropagation();
          return;
        } else {
          history.push({
            pathname: '/admin/listar/proprietarios',
            state: { listagem: response.data, model: model },
          });
          return;
        }
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    console.log('cnpj ', cnpj);
    console.log('cnpj tam ', cnpj.length);
  }, [cnpj]);

  return (
    <Tela nome="Proprietário" caminho="Cadastro > Gerenciamento de Clientes > Proprietário">
      {/* {cadastroRealizado ? (
          <ListagemConsultaProprietario
            dataResults={dataResults}
            setDataResults={setDataResults}
            model={model}
          />
        ) : ( */}
      <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
        <Form.Group className="row-modal">
          <Form.Label>Cliente</Form.Label>

          <Dropdown
            style={{ width: '100%' }}
            value={empresa}
            options={listaClientes}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                idCliente: e.target.value,
              });

              setEmpresa(e.target.value);
            }}
            optionLabel="razaoSocial"
            optionValue="id"
            showClear
            filter
            className="multiselect-custom"
            id="empresa"
          />
        </Form.Group>

        <Form.Row className="row-container-modal">
          <Form.Group className="row-modal">
            <Form.Label>Nome / Razão Social</Form.Label>
            <Form.Control
              onChange={(e) => {
                if (e.target.value === '') {
                  setModel({
                    ...model,
                    razaoSocialProprietario: null,
                  });
                } else {
                  setModel({
                    ...model,
                    razaoSocialProprietario: e.target.value,
                  });
                }
              }}
              value={model?.razaoSocialProprietario ? model?.razaoSocialProprietario : ''}
              type="text"
              id="razaoSocialProprietario"
              name="razaoSocialProprietario"
              placeholder=""
              style={{ width: '100%' }}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group className="row-modal">
          <Form.Label>Tipo de Pessoa</Form.Label>

          <Dropdown
            style={{ width: '100%' }}
            value={tipo}
            options={listaTipos}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                tipo: e.target.value,
              });
              setTipo(e.target.value);
              setCpf('');
              setCnpj('');
            }}
            optionLabel="nome"
            optionValue="value"
            showClear
            className="multiselect-custom"
            id="tipo"
          />
        </Form.Group>
        {model.tipo === 'Fisica' ? (
          <Form.Group>
            <Form.Label>CPF</Form.Label>
            <Form.Control
              value={cpfMask(cpf)}
              isInvalid={!isCpfValid && cpf.length > 0}
              id="cpf"
              name="cpf"
              placeholder=""
              maxLength={14}
              onChange={(event: any) => {
                if (event.target.value === '') {
                  setModel({
                    ...model,
                    cnpjCpfProprietario: null,
                  });
                  setCpf(cpfMask(event.target.value));
                } else {
                  if (checaCPF(event.target.value) === false) {
                    setCpf(cpfMask(event.target.value));
                    setIsCpfValid(false);
                  } else {
                    setCpf(cpfMask(event.target.value));
                    setIsCpfValid(true);
                    setModel({
                      ...model,
                      cnpjCpfProprietario: event.target.value.includes('-')
                        ? event.target.value.replace('-', '').replaceAll('.', '')
                        : event.target.value,
                    });
                  }
                }
              }}
            />
            {!isCpfValid && cpf.length > 0 && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>Cpf inválido.</p>
            )}
          </Form.Group>
        ) : model.tipo === 'Juridica' ? (
          <Form.Group>
            <Form.Label>CNPJ</Form.Label>
            <Form.Control
              value={cnpj}
              id="cnpj"
              name="cnpj"
              isInvalid={!isCnpjValid && cnpj.length > 0}
              placeholder=""
              maxLength={18}
              onChange={(event: any) => {
                if (event.target.value === '') {
                  setModel({
                    ...model,
                    cnpjCpfProprietario: null,
                  });
                  setCnpj(maskCpnj(event.target.value));
                } else {
                  setCnpj(maskCpnj(event.target.value));
                  setModel({
                    ...model,
                    cnpjCpfProprietario: event.target.value.includes('/')
                      ? event.target.value.replace('-', '').replaceAll('.', '').replace('/', '')
                      : event.target.value,
                  });
                }
              }}
              onBlur={() => {
                if (cnpj.length === 14 || cnpj.length === 18) {
                  setCnpj(cnpj);
                  setIsCnpjValid(true);
                } else {
                  setIsCnpjValid(false);
                }
              }}
            />
            {!isCnpjValid && cnpj.length > 0 && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>Cnpj inválido.</p>
            )}
          </Form.Group>
        ) : null}

        {!model.tipo && (
          <Form.Group>
            {!model.tipo ? <Form.Label>CPF / CNPJ</Form.Label> : <Form.Label>CNPJ</Form.Label>}
            <Form.Control
              value={
                cnpj.length === 18 && cnpj.includes('/')
                  ? maskCpnj(cnpj)
                  : cnpj.length === 11
                  ? cpfMask(cnpj)
                  : cnpj.length === 14 && cnpj.includes('-')
                  ? cpfMask(cnpj)
                  : cnpj.length === 14
                  ? maskCpnj(cnpj)
                  : cnpj
              }
              id="cnpj"
              name="cnpj"
              placeholder=""
              maxLength={18}
              onChange={(event: any) => {
                if (event.target.value === '') {
                  setModel({
                    ...model,
                    cnpjCpfProprietario: null,
                  });
                  setCnpj(event.target.value);
                } else {
                  setCnpj(event.target.value);
                  setModel({
                    ...model,
                    cnpjCpfProprietario: event.target.value.includes('/')
                      ? event.target.value.replace('-', '').replaceAll('.', '').replace('/', '')
                      : event.target.value.length === 14
                      ? event.target.value.replace('-', '').replaceAll('.', '')
                      : event.target.value,
                  });
                }
              }}
              onBlur={() => {
                if (cnpj.length === 14 || cnpj.length === 18) {
                  setCnpj(cnpj);
                  setIsCpfValid(true);
                  return;
                } else {
                  if (cnpj.length === 11 || (cnpj.includes('-') && cnpj.length === 14)) {
                    if (checaCPF(cnpj) === false) {
                      setCpf(cpfMask(cnpj));
                      setIsCpfValid(false);
                    } else {
                      setCpf(cpfMask(cnpj));
                      setIsCpfValid(true);
                    }
                  }
                }
              }}
            />
            {!isCpfValid && cpf.length > 0 && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>Cpf inválido.</p>
            )}
          </Form.Group>
        )}

        <ButtonsForm />
      </Form>
    </Tela>
  );
};

export default ConsultarProprietario;
