/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { CardInformativo } from '../../components/CardInformativo';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import instanceMaquina from '../../config/axiosMaquina';
import { GridExpansivel } from './gridExpansivel';
import { getCookieSessionData } from '../../services/cookieService';
import { Form } from 'react-bootstrap';
import Pagination from '../../components/Pagination/Pagination';
import { PainelLSCType, ValuesCardsType } from '../../Types/PainelLSCTypes';
import { Slider } from 'primereact/slider';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from '../../components/Spinner';
import Search from '../../components/Search/Search';
import { FaClipboardList, FaTemperatureHigh } from 'react-icons/fa';
import { RiAlertFill, RiTruckFill } from 'react-icons/ri';
import { IoMdSpeedometer } from 'react-icons/io';
import { MdOutlineArrowDropUp } from 'react-icons/md';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

type RangeType = [number, number];

type Props = {
  canEdit?: boolean;
};

type CanShowViolacaoType = {
  idCliente: any;
  velocidade: boolean;
  temperatura: boolean;
};

export function LSC({ canEdit }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [rangeDistancia, setRangeDistancia] = useState<any>({ max: 500, min: 0 });
  const [valueRange, setValueRange] = useState<RangeType>([0, 500]);
  const [filtro, setFiltro] = useState('Total de Viagens');
  const [filtroBusca, setFiltroBusca] = useState('Todos');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [valuesCards, setValuesCards] = useState<ValuesCardsType[]>([]);
  const [filtrado, setFiltrado] = useState<PainelLSCType[]>();
  const [objetos, setObjetos] = useState<PainelLSCType[]>([]);
  const usuarioLogado = getCookieSessionData().usuarioVO.usuarioId;
  const { page }: any = useParams();
  const history = useHistory();
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = filtrado?.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: any) => history.push(`/painel-lsc/${pageNumber}`);
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const [sliderDisabled, setSliderDisabled] = useState(false);

  const [canShowViolacao, setCanShowViolacao] = useState<CanShowViolacaoType[]>([]);

  const getData = async (data: PainelLSCType[]) => {
    const promises = data.map((obj: PainelLSCType) => {
      return obj.idcliente;
    });
    const ids = uniq(promises);

    let arr: CanShowViolacaoType[] = [];
    ids.forEach(async (eachId: number) => {
      let object = {
        idCliente: eachId,
        velocidade: false,
        temperatura: false,
      };
      try {
        const velocidadeResponse = await instanceMaquina.get(
          `/violacao-velocidade/idCliente?idCliente=${eachId}`
        );
        if (velocidadeResponse.data.moduloTipo === 'AVANCADO') {
          object.velocidade = true;
        }
        const temperaturaResponse = await instanceMaquina.get(
          `/violacao-temperatura/idCliente?idCliente=${eachId}`
        );
        if (temperaturaResponse.data.moduloTipo === 'AVANCADO') {
          object.temperatura = true;
        }
        arr.push(object);
        const _uniq = uniqBy(arr.concat(canShowViolacao), 'idCliente');
        setCanShowViolacao(_uniq);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getViolacaoCards = () => {
    let velocidadeLength = 0;
    let temperaturaLength = 0;
    objetos.forEach((obj: PainelLSCType) => {
      velocidadeLength += canShowViolacao.some((obj2: any) => {
        return obj2.idCliente === obj.idcliente && obj.violouvelocidade;
      })
        ? 1
        : 0;
      temperaturaLength += canShowViolacao.some((obj2: any) => {
        return obj2.idCliente === obj.idcliente && obj.violoutemperatura;
      })
        ? 1
        : 0;
    });
    setValuesCards([
      ...valuesCards,
      {
        nome: 'Excesso de Velocidade',
        value: velocidadeLength,
        icon: <IoMdSpeedometer color="#E03B24" size="40px" />,
        color: '#E03B24',
        show: canShowViolacao.some((obj: any) => {
          return obj.velocidade;
        }),
      },
      {
        nome: 'Violação de Temperatura',
        value: temperaturaLength,
        icon: <FaTemperatureHigh color="#F57C00" size="35px" />,
        color: '#F57C00',
        show: canShowViolacao.some((obj: any) => {
          return obj.temperatura;
        }),
      },
    ]);
  };

  useEffect(() => {
    if (canShowViolacao.length) {
      getViolacaoCards();
    }
  }, [canShowViolacao]);

  const fetchData = async () => {
    try {
      const response = await instanceMaquina.get(
        `/solicitacao-monitoramento/lsc-lista?idUsuario=${idUsuario}`
      );
      try {
        setObjetos(response.data);
        await getData(response.data);

        setValuesCards([
          {
            nome: 'Total de Viagens',
            value: response.data.length,
            icon: <FaClipboardList color="#3865A3" size="35px" />,
            color: '#3865A3',
            show: true,
          },
          {
            nome: 'Em Atraso',
            value: response.data?.filter(
              (e: PainelLSCType) =>
                e.statusviagemsm?.toLowerCase() === 'em atraso' ||
                e.statusviagemsm.toLowerCase() === 'atrasada'
            ).length,
            icon: <RiAlertFill color="#E03B24" size="40px" />,
            color: '#E03B24',
            show: true,
          },
          {
            nome: 'Parcialmente Atrasada',
            value: response.data?.filter(
              (e: PainelLSCType) => e.statusviagemsm === 'Parcialmente Atrasada'
            ).length,
            icon: <RiAlertFill color="#F57C00" size="40px" />,
            color: '#F57C00',
            show: true,
          },
          {
            nome: 'Em Tratamento',
            value: response.data?.filter((e: PainelLSCType) =>
              e.statustratamentosm?.toLowerCase().includes('em tratamento')
            ).length,
            icon: <RiTruckFill color="#64A338" size="40px" />,
            color: '#64A338',
            show: true,
          },
          {
            nome: 'Pendente',
            value: response.data?.filter((e: PainelLSCType) => e.statustratamentosm === 'Pendente')
              .length,
            icon: <RiAlertFill color="#FFCC00" size="40px" />,
            color: '#FFCC00',
            show: true,
          },
          {
            nome: 'Excesso de Velocidade',
            value: response.data?.filter((e: PainelLSCType) => e.violouvelocidade).length,
            icon: <IoMdSpeedometer color="#E03B24" size="40px" />,
            color: '#E03B24',
            show: false,
          },
          {
            nome: 'Violação de Temperatura',
            value: response.data?.filter((e: PainelLSCType) => e.violoutemperatura).length,
            icon: <FaTemperatureHigh color="#F57C00" size="35px" />,
            color: '#F57C00',
            show: false,
          },
          {
            nome: 'Tempo Máximo de Trânsito Excedido',
            value: 12,
            color: Math.sign(12) === 1 ? '#E03B24' : '#64A338',
            icon: <MdOutlineArrowDropUp color="#64A338" size="40px" />,
            show: true,
          },
          {
            nome: 'Tempo mínimo de Parada Não Realizada',
            value: 3,
            color: Math.sign(3) === 1 ? '#E03B24' : '#64A338',
            icon: <MdOutlineArrowDropUp color="#64A338" size="40px" />,
            show: true,
          },
        ]);
        setRangeDistancia({
          max: Math.max.apply(
            Math,
            response.data.map(function (o: any) {
              return o.diantanciaproxponto;
            })
          ),
          min: Math.min.apply(
            Math,
            response.data.map(function (o: any) {
              return o.diantanciaproxponto;
            })
          ),
        });
        setValueRange([
          Math.min.apply(
            Math,
            response.data.map(function (o: any) {
              return o.diantanciaproxponto;
            })
          ),
          Math.max.apply(
            Math,
            response.data.map(function (o: any) {
              return o.diantanciaproxponto;
            })
          ),
        ]);
        setSliderDisabled(
          response.data.map(function (o: any) {
            return o.diantanciaproxponto;
          })
            ? false
            : true
        );
      } catch (err: any) {
        console.log(err);
        setSliderDisabled(true);
      }
    } catch (error: any) {
      console.log(error);
      setSliderDisabled(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleFiltro = () => {
      if (objetos) {
        var filtrado: any = [];
        filtrado = objetos;
        // Filtro Cards
        if (filtro === 'Total de Viagens') {
          filtrado = objetos;
        } else if (filtro === 'Pendente') {
          filtrado = objetos.filter((each: PainelLSCType) =>
            each.statustratamentosm?.includes('Pendente')
          );
        } else if (filtro === 'Em Tratamento') {
          filtrado = objetos.filter((each: PainelLSCType) =>
            each.statustratamentosm?.toLowerCase().includes(filtro.toLowerCase())
          );
        } else if (filtro === 'Parcialmente Atrasada') {
          filtrado = objetos.filter((each: PainelLSCType) => each.statusviagemsm?.includes(filtro));
        } else if (filtro === 'Em Atraso' || filtro === 'Atrasada') {
          filtrado = objetos.filter(
            (each: PainelLSCType) =>
              each.statusviagemsm?.includes('Atrasada') ||
              each.statusviagemsm?.includes('Em Atraso')
          );
        } else if (filtro === 'Excesso de Velocidade') {
          filtrado = objetos.filter(
            (each: PainelLSCType) =>
              each.violouvelocidade &&
              canShowViolacao.find((e: any) => e.idCliente === each.idcliente && e.velocidade)
          );
        } else if (filtro === 'Violação de Temperatura') {
          filtrado = objetos.filter((each: PainelLSCType) => each.violoutemperatura);
        }

        //Filtro distancia
        filtrado = filtrado?.filter(
          (each: any) =>
            each.diantanciaproxponto >= valueRange[0] && each.diantanciaproxponto <= valueRange[1]
        );
        //Filtro Search
        if (filtroBusca === 'Todos' || searchTerm.trim() === '') {
          filtrado = filtrado.filter(
            (each: PainelLSCType) =>
              each.placas?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
              each.idsm?.toString()?.includes(searchTerm) ||
              each.nomefantasiacli?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
              each.nomeembarcador?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
              each.proxponto?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
              each.previsaoproxponto?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
              each.tempochegadaproxponto?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
              each.motivoatraso?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        } else if (filtroBusca === 'SM') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.idsm?.toString()?.includes(searchTerm)
          );
        } else if (filtroBusca === 'Placa') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.placas?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        } else if (filtroBusca === 'Cliente') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.nomefantasiacli?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        } else if (filtroBusca === 'Embarcador') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.nomeembarcador?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        } else if (filtroBusca === 'Próximo ponto') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.proxponto?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        } else if (filtroBusca === 'Previsão') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.previsaoproxponto?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        } else if (filtroBusca === 'Tempo de chegada') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.tempochegadaproxponto?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        } else if (filtroBusca === 'Motivo') {
          filtrado = filtrado.filter((each: PainelLSCType) =>
            each.motivoatraso?.toLowerCase()?.includes(searchTerm.toLowerCase())
          );
        }
        setFiltrado(filtrado);
      } else {
        return;
      }
    };
    handleFiltro();
  }, [filtro, objetos, valueRange, filtroBusca, searchTerm]);

  const handleRange = () => {
    //Filtro distancia KM
    let filtered = filtrado?.filter(
      (each: any) =>
        each.diantanciaproxponto > valueRange[0] && each.diantanciaproxponto < valueRange[1]
    );
    setFiltrado(filtered);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="whitecard">
        <div className="titulo-container">
          <h2 className="titulo">Painel LSC</h2>
          <h6 className="subtitulo">{'Operacional > Painel LSC'}</h6>
        </div>
        <div className="alertas">
          {valuesCards &&
            valuesCards.map((each: ValuesCardsType, index) => {
              if (each.show === true) {
                return (
                  <CardInformativo
                    key={index}
                    name={each.nome}
                    data={each.value}
                    onClick={() => {
                      setFiltro(each.nome);
                    }}
                    icon={each.icon}
                    color={each.color}
                  />
                );
              }
            })}
        </div>
        <div className="content-container">
          <div className="painel">
            <div className="filtros">
              <Form.Group className="row-modal" style={{ maxWidth: '160px' }}>
                <Form.Control
                  id="viagemEscolta"
                  name="viagemEscolta"
                  placeholder=""
                  onChange={(e) => {
                    setFiltroBusca(e.target.value);
                  }}
                  as="select"
                >
                  <option value="Todos">Todos</option>
                  <option value="SM">SM</option>
                  <option value="Cliente">Cliente</option>
                  <option value="Embarcador">Embarcador</option>
                  <option value="Placa">Placa</option>
                  <option value="Próximo ponto">Próximo ponto</option>
                  <option value="Previsão">Previsão</option>
                  <option value="Tempo de chegada">Tempo de chegada</option>
                  <option value="Motivo">Motivo</option>
                </Form.Control>
              </Form.Group>
              <Search
                disabled={!filtrado}
                setLoading={setLoading}
                styles={{ marginTop: '-15px' }}
                setSearch={setSearchTerm}
                setSearchValue={setSearch}
                search={searchTerm}
                placeholder={filtroBusca}
              />
              <Form.Group className="row-modal" id="range">
                <Slider
                  value={valueRange}
                  onChange={(e: any) => {
                    setValueRange(e.value);
                  }}
                  range
                  max={rangeDistancia.max}
                  min={rangeDistancia.min}
                  className="p-slider-handle"
                  disabled={sliderDisabled}
                />
              </Form.Group>
              <Form.Group className="row-modal" id="km">
                {isFinite(valueRange[0]) ? valueRange[0] : 0}km a{' '}
                {isFinite(valueRange[1]) ? valueRange[1] : 0}km
              </Form.Group>
            </div>
            <div className="table-responsive mt-4" style={{ flex: 1 }}>
              <table className="table" id="lsc">
                <thead className="thead">
                  <tr>
                    <th>SM</th>
                    <th>Cliente</th>
                    <th>Embarcador</th>
                    <th>Placa</th>
                    <th>Próximo ponto</th>
                    <th>Previsão chegada próx. ponto</th>
                    <th>Distância p/ próx. ponto</th>
                    <th>Tempo chegada próx. ponto</th>
                    <th>Status</th>
                    <th>Tratamento</th>
                    <th>Motivo do atraso</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData ? (
                    currentData.map((each: PainelLSCType, index) => (
                      <>
                        <GridExpansivel
                          key={index}
                          each={each}
                          fetchData={fetchData}
                          canEdit={canEdit}
                        />
                      </>
                    ))
                  ) : (
                    <Spinner />
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            totalPosts={filtrado?.length || 0}
            paginate={paginate}
            currentPage={page.toString()}
          />
        </div>
      </div>
    </div>
  );
}
