/* eslint-disable */
import { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import Input from '../../../components/Input';

export type AtualizarPesquisaType = {
  dataVencimento?: string;
  diasVencimento?: number;
  idDpe: number;
  idUsuarioLogado: number;
  resposta: string;
  tipoProcesso?: string;
  motivoAlteracao?: string;
};

type Props = {
  canEdit?: boolean;
};

const EditarAnalisePerfil = ({ canEdit }: Props) => {
  const history = useHistory();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [pesquisaCond, setPesquisaCond] = useState<AtualizarPesquisaType | any>();
  const [pesquisaCnh, setPesquisaCnh] = useState<AtualizarPesquisaType | any>();
  const [pesquisaVeiculo, setPesquisaVeiculo] = useState<AtualizarPesquisaType | any>();
  const [pesquisa, setPesquisa] = useState<any>();
  const { usuarioId } = getCookieSessionData().usuarioVO;
  const { addToast } = useToast();

  const location: any = useLocation();
  const { state } = location;
  console.log(state);

  if (!pesquisa) {
    if (state.state) {
      setPesquisa(state.state);
    } else if (state) {
      setPesquisa(state);
    }
  }

  useEffect(() => {
    setPesquisaCond({
      idDpe: pesquisa.itens[0].idPesquisaEntidade,
      tipoProcesso: 'PROCESSO',
      idUsuarioLogado: usuarioId,
      dataVencimento: pesquisa.itens[0].validade,
    });
    setPesquisaCnh({
      idDpe: pesquisa.itens[0].idPesquisaEntidade,
      tipoProcesso: 'CNH',
      idUsuarioLogado: usuarioId,
      dataVencimento: pesquisa.itens[0].validade,
    });
    setPesquisaVeiculo({
      idDpe: pesquisa.itens[1]
        ? pesquisa.itens[1].idPesquisaEntidade
        : pesquisa.itens[0].idPesquisaEntidade,
      tipoProcesso: 'VEICULO',
      idUsuarioLogado: usuarioId,
      dataVencimento: pesquisa.itens[1] ? pesquisa.itens[1].validade : pesquisa.itens[0].validade,
    });
  }, [pesquisa]);

  const handleEdit = async (event: any) => {
    event.preventDefault();
    if (pesquisaCond.resposta && pesquisaCond.resposta != 'Selecione') {
      try {
        const response = await axios.post(
          `/pesquisa/atualizar-dados-pesquisa?idDpe=${pesquisaCond.idDpe}&idUsuarioAlteracao=${usuarioId}&idUsuarioLogado=${usuarioId}&motivoAlteracao=${pesquisaCond.motivoAlteracao}&resposta=${pesquisaCond.resposta}&tipoProcesso=${pesquisaCond.tipoProcesso}`
        );
        console.log(response.data);
        setPesquisaCond({
          ...pesquisaCond,
          resposta: '',
        });
        setIsDialogCadOK(true);
        setMensagem('Pesquisa atualizada com sucesso!');
        console.log(pesquisaCond);
      } catch (error: any) {
        console.log(error);
        addToast({
          title: 'Erro',
          description: 'Erro ao atualizar pesquisa',
          type: 'error',
        });
      }
    }
    if (pesquisaCnh.resposta && pesquisaCnh.resposta != 'Selecione') {
      try {
        // const response = await axios.post(`/pesquisa/atualizar-dados-pesquisa?idDpe=${pesquisaCnh.idDpe}&idUsuarioLogado=${usuarioId}&resposta=${pesquisaCnh.resposta}&tipoProcesso=${pesquisaCnh.tipoProcesso}`);
        const response = await axios.post(
          `/pesquisa/atualizar-dados-pesquisa?idDpe=${pesquisaCnh.idDpe}&idUsuarioAlteracao=${usuarioId}&idUsuarioLogado=${usuarioId}&motivoAlteracao=${pesquisaCnh.motivoAlteracao}&resposta=${pesquisaCnh.resposta}&tipoProcesso=${pesquisaCnh.tipoProcesso}`
        );
        console.log(response.data);
        setPesquisaCnh({
          ...pesquisaCnh,
          resposta: '',
        });
        setIsDialogCadOK(true);
        setMensagem('Pesquisa atualizada com sucesso!');
        console.log(pesquisaCnh);
      } catch (error: any) {
        console.log(error);
        addToast({
          title: 'Erro',
          description: 'Erro ao atualizar pesquisa',
          type: 'error',
        });
      }
    }
    if (pesquisaVeiculo.resposta && pesquisaVeiculo.resposta != 'Selecione') {
      try {
        const response = await axios.post(
          `/pesquisa/atualizar-dados-pesquisa?idDpe=${pesquisaVeiculo.idDpe}&idUsuarioAlteracao=${usuarioId}&idUsuarioLogado=${usuarioId}&motivoAlteracao=${pesquisaVeiculo.motivoAlteracao}&resposta=${pesquisaVeiculo.resposta}&tipoProcesso=${pesquisaVeiculo.tipoProcesso}`
        );
        console.log(response.data);
        setPesquisaVeiculo({
          ...pesquisaVeiculo,
          resposta: '',
        });
        setIsDialogCadOK(true);
        setMensagem('Pesquisa atualizada com sucesso!');
        console.log(pesquisaVeiculo);
      } catch (error: any) {
        console.log(error);
        addToast({
          title: 'Erro',
          description: 'Erro ao atualizar pesquisa',
          type: 'error',
        });
      }
    }
  };

  const handleBack = () => {
    // if (state.state) {
    //   history.push({
    //     pathname: `/consultar-pesquisas-lancadas/last-request/${state?.page}`,
    //     state: { request: state.request },
    //   });
    // } else {
    history.goBack();
    // }
  };

  const retornaResultado = (result: string) => {
    switch (result) {
      case 'EM_ANDAMENTO':
        return 'Em Andamento';
      case 'EM_ACORDO':
        return 'Em Acordo';
      case 'PENDENTE_DE_DOCUMENTACAO':
        return 'Pendente de Documentação';
      default:
        return '';
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Análise de Perfil</h2>
          <h6 className="subtitulo">{'Análise de Perfil  > Atualização '}</h6>
        </div>

        {pesquisa && (
          <Form
            onSubmit={(e: any) => {
              if (canEdit) {
                handleEdit(e);
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            <Form.Row>
              <Input
                required
                value={pesquisa.numLiberacao}
                onChange={() => false}
                id="cpf"
                name="cpf"
                textInputTitle="N° LIBERAÇÃO:"
                disabled
              />
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>

            {pesquisa.operacao?.includes('Integrada') || pesquisa.operacao?.includes('Condutor') ? (
              <>
                <Form.Label className="SMLancadasTitle">Dados da pesquisa Condutor</Form.Label>
                <Form.Row style={{ marginTop: 20 }}>
                  <Input
                    required
                    value={pesquisa.itens[0].itemPesquisado}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="CPF:"
                    disabled
                  />
                  <Input
                    required
                    value={pesquisa.itens[0].descricao}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="NOME:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    required
                    value={retornaResultado(pesquisa.itens[0].statusPesquisa)}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="SITUAÇÃO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Nova Situação</Form.Label>
                    <Form.Control
                      value={pesquisaCond ? pesquisaCond.resposta : null}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          resposta: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      <option value="EM_ANDAMENTO">Em andamento</option>
                      <option value="EM_ACORDO">Em acordo</option>
                      <option value="PENDENTE_DE_DOCUMENTACAO">Pendente de documentação</option>
                    </Form.Control>
                  </Form.Group>
                  <Input
                    value={pesquisaCond ? pesquisaCond.motivoAlteracao : null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPesquisaCond({
                        ...pesquisaCond,
                        motivoAlteracao: e.target.value,
                      })
                    }
                    textInputTitle="MOTIVO:"
                  />
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <hr />
                  </Form.Group>
                </Form.Row>

                <Form.Label className="SMLancadasTitle">Dados da pesquisa CNH</Form.Label>

                <Form.Row style={{ marginTop: 20 }}>
                  <Input
                    required
                    value={pesquisa.itens[0].itemPesquisado}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="CPF:"
                    disabled
                  />
                  <Input
                    required
                    value={pesquisa.itens[0].descricao}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="NOME:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    required
                    value={retornaResultado(pesquisa.itens[0].statusPesquisaCondutorCnh)}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="SITUAÇÃO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label style={{ marginTop: '10px' }}>Nova Situação</Form.Label>
                    <Form.Control
                      value={pesquisaCnh ? pesquisaCnh.resposta : 0}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCnh({
                          ...pesquisaCnh,
                          resposta: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      <option value="EM_ANDAMENTO">Em andamento</option>
                      <option value="EM_ACORDO">Em acordo</option>
                      <option value="PENDENTE_DE_DOCUMENTACAO">Pendente de documentação</option>
                    </Form.Control>
                  </Form.Group>

                  <Input
                    value={pesquisaCnh ? pesquisaCnh.motivoAlteracao : null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPesquisaCnh({
                        ...pesquisaCnh,
                        motivoAlteracao: e.target.value,
                      })
                    }
                    textInputTitle="MOTIVO:"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <hr />
                  </Form.Group>
                </Form.Row>
              </>
            ) : null}
            <Dialog
              header={mensagem}
              footer={<Button onClick={() => handleBack()}>Ok</Button>}
              visible={isDialogCadOK}
              style={{ width: '50vw' }}
              modal
              onHide={() => {
                history.goBack();
              }}
            />
            {pesquisa.operacao?.includes('Integrada') || pesquisa.operacao?.includes('Veículo') ? (
              <>
                <Form.Label className="SMLancadasTitle">Dados da pesquisa Veículo</Form.Label>
                <Form.Row style={{ marginTop: 20 }}>
                  <Input
                    required
                    value={
                      pesquisa.itens[1]
                        ? pesquisa.itens[1].itemPesquisado
                        : pesquisa.itens[0].itemPesquisado
                    }
                    onChange={() => false}
                    textInputTitle="PLACA:"
                    disabled
                  />
                  <Input
                    required
                    value={pesquisa.itens[1] ? pesquisa.itens[1].tipo : pesquisa.itens[0].tipo}
                    onChange={() => false}
                    textInputTitle="TIPO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    required
                    value={
                      pesquisa.itens[1]
                        ? retornaResultado(pesquisa.itens[1].statusPesquisa)
                        : retornaResultado(pesquisa.itens[0].statusPesquisa)
                    }
                    onChange={() => false}
                    textInputTitle="SITUAÇÃO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label style={{ marginTop: '10px' }}>Nova Situação</Form.Label>
                    <Form.Control
                      value={pesquisaVeiculo ? pesquisaVeiculo.resposta : 0}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaVeiculo({
                          ...pesquisaVeiculo,
                          resposta: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      <option value="EM_ANDAMENTO">Em andamento</option>
                      <option value="EM_ACORDO">Em acordo</option>
                      <option value="PENDENTE_DE_DOCUMENTACAO">Pendente de documentação</option>
                    </Form.Control>
                  </Form.Group>
                  <Input
                    value={pesquisaVeiculo ? pesquisaVeiculo.motivoAlteracao : null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPesquisaVeiculo({
                        ...pesquisaVeiculo,
                        motivoAlteracao: e.target.value,
                      })
                    }
                    textInputTitle="MOTIVO:"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <hr />
                  </Form.Group>
                </Form.Row>
              </>
            ) : null}
            {pesquisa.operacao?.includes('Ajudante') && (
              <>
                <Form.Label className="SMLancadasTitle">Dados da pesquisa Ajudante</Form.Label>
                <Form.Row style={{ marginTop: 20 }}>
                  <Input
                    required
                    value={pesquisa.itens[0].itemPesquisado}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="CPF:"
                    disabled
                  />
                  <Input
                    required
                    value={pesquisa.itens[0].descricao}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="NOME:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    required
                    value={retornaResultado(pesquisa.itens[0].statusPesquisa)}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="SITUAÇÃO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Nova Situação</Form.Label>
                    <Form.Control
                      value={pesquisaCond ? pesquisaCond.resposta : null}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          resposta: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      <option value="EM_ANDAMENTO">Em andamento</option>
                      <option value="EM_ACORDO">Em acordo</option>
                      <option value="PENDENTE_DE_DOCUMENTACAO">Pendente de documentação</option>
                    </Form.Control>
                  </Form.Group>
                  <Input
                    value={pesquisaCond ? pesquisaCond.motivoAlteracao : null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPesquisaCond({
                        ...pesquisaCond,
                        motivoAlteracao: e.target.value,
                      })
                    }
                    textInputTitle="MOTIVO:"
                  />
                </Form.Row>
              </>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
            <Form.Row className="container-buttons justify-content-end">
              <Form.Group as={Col} md={2}>
                <Button className="btn-cancelar" onClick={handleBack}>
                  Cancelar
                </Button>
              </Form.Group>
              <Form.Group as={Col} md={2}>
                <Button className="btn-enviar" type="submit">
                  Confirmar
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </div>
    </div>
  );
};

export default EditarAnalisePerfil;
