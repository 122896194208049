/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { Button as Button2 } from 'primereact/button';
import { Button, Col, Form } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { Spinner } from 'react-bootstrap';
import api from '../../config/axiosMaquina';
import Pagination from '../../components/Pagination/Pagination';
import { ModalDelete } from '../../components/ModalDelete';
import { ComunicacaoRow } from './editableGrid';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { ImCancelCircle } from 'react-icons/im';
import { ComunicacaoType } from '../../api/model/Veiculos/ComunicacaoType';
import { AiOutlineCheck } from 'react-icons/ai';
import { useParams, useHistory } from 'react-router-dom';
import Search from '../../components/Search/Search';
import './styles.css';
import { ButtonTooltip } from '../../components/ButtonTooltipo';

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
  canInsert?: boolean;
};

const ConsultarTiposComunicacao: FC<Props> = ({ canDelete, canEdit, canInsert }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const [newLine, setNewLine] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [tiposComunicacao, setTiposComunicacao] = useState<ComunicacaoType[]>([]);
  const [searchResults, setSearchResults] = useState<ComunicacaoType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const history = useHistory();
  const { page }: any = useParams();
  const paginate = (pageNumber: number) => {
    history.push(`/listar/tipos-de-comunicacao/${pageNumber}`);
  };
  const [totalItems, setTotalItems] = useState(0);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [mensagem, setMensagem] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const [search, setSearch] = useState('');
  const [model, setModel] = useState<ComunicacaoType>();
  const [idEdit, setIdEdit] = useState(0);

  useEffect(() => {
    console.log('canEdit?', canEdit);
  }, [canEdit]);

  useEffect(() => {
    setLoading(true);
    const loadDadosTiposComunicacao = async () => {
      try {
        const response = await api.get('/tipo-comunicacao/grid', {
          params: {
            pagina: page - 1,
            qtdRegistro: itensPerPage,
          },
        });
        console.log(response.data);
        setLoading(false);
        setTiposComunicacao(response.data);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    };

    loadDadosTiposComunicacao();
  }, [page, itensPerPage]);

  useEffect(() => {
    const loadCount = async () => {
      try {
        const response = await api.get('/tipo-comunicacao/count-ativo');
        console.log(response.data);
        setLoading(false);
        setTotalItems(response.data);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    };
    loadCount();
  }, []);

  useEffect(() => {
    const handleSearch = async () => {
      history.push('/listar/tipos-de-comunicacao/1');
      if (searchTerm && searchTerm.trim()) {
        try {
          const response = await api.get(`/tipo-comunicacao/search-grid?keyword=${searchTerm}`);
          console.log(response.data);
          setSearchResults(response.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    handleSearch();
  }, [searchTerm]);

  const handleDelete = async () => {
    try {
      const response = await api.put(`/tipo-comunicacao/delete-logico?idTipoComunicao=${idDelete}`);
      console.log(response.data);
      setOpenModal(false);
      setIsDialogCadOK(true);
      setMensagem('Tipo de Comunicação excluído com sucesso!');
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir Tipo de Comunicacão.',
        type: 'error',
      });
    }
  };

  async function handleSubmit() {
    setDisabled(false);
    if (nome.trim() != '') {
      try {
        const data = {
          ttcTxNome: nome,
          ttcTxDescricao: descricao,
          ttcBlAtivo: true,
        };
        console.log(data);
        await api.post(`/tipo-comunicacao/tecnologiatipocomunicacao`, data);
        setMensagem('Tipo de Comunicação cadastrado com sucesso!');
        setIsDialogCadOK(true);
      } catch (err: any) {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar Tipo de Comunicação.',
          type: 'error',
        });
      }
    } else {
      addToast({
        title: 'Erro',
        description: 'Verifique os campos obrigatórios.',
        type: 'error',
      });
    }
  }

  const handleCancel = () => {
    setDisabled(true);
    setNewLine(false);
    setNome('');
    setDescricao('');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <ModalDelete
          show={openModal}
          onHide={() => setOpenModal(false)}
          handleDelete={handleDelete}
          handleClose={() => setOpenModal(false)}
          message="Deseja excluir este registro?"
        />
        <Dialog
          header={mensagem}
          footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK(false);
            window.location.reload();
          }}
        />
        <div className="titulo-container">
          <h2 className="titulo">Tipos de Comunicação</h2>
          <h6 className="subtitulo">{'Cadastro > Rastreamento > Tipos de Comunicação'}</h6>
        </div>
        <div className="search-create mb-4" style={{ width: '100%' }}>
          <Search
            onClick={() => {
              setNewLine(true);
              setIdEdit(0);
            }}
            setLoading={setLoading}
            setSearch={setSearch}
            setSearchValue={setSearchTerm}
            search={search}
            createDisabled={!canInsert}
          />
        </div>

        <div className="content-container" style={{ width: '100%' }}>
          <div className="painel">
            <div className="table-responsive" style={{ flex: 1 }}>
              <table className="table">
                <thead className="titleLine">
                  <tr>
                    <th>
                      Nome<span className="txt-obrigatorio"> *</span>
                    </th>
                    <th>Descrição</th>
                  </tr>
                </thead>
              </table>
              {newLine && idEdit === 0 ? (
                <div
                  className="lineGridTipoComunicacao2"
                  style={{ filter: 'drop-shadow(0 0 0.5rem #d8d8d8)' }}
                >
                  <input
                    placeholder="Nome"
                    type="text"
                    value={nome}
                    maxLength={64}
                    onChange={(e: any) => {
                      setNome(e.target.value);
                    }}
                  />
                  <input
                    placeholder="Descrição"
                    type="text"
                    value={descricao}
                    maxLength={64}
                    onChange={(e: any) => {
                      setDescricao(e.target.value);
                    }}
                  />
                  <ButtonTooltip onClick={handleCancel} type="Cancelar" />
                  <ButtonTooltip onClick={handleSubmit} type="Enviar" />
                </div>
              ) : null}
              {loading ? (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              ) : (
                <div>
                  {(searchTerm.trim() != '' ? searchResults : tiposComunicacao).map(
                    (tipoComunicacao) => {
                      return (
                        <ComunicacaoRow
                          tiposComunicacao={tipoComunicacao}
                          setOpenModal={setOpenModal}
                          setCloseModal={setOpenModal}
                          setIdDelete={setIdDelete}
                          setIdEdit={setIdEdit}
                          idAtual={idEdit}
                          canEdit={canEdit}
                          canDelete={canDelete}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Pagination
          totalPosts={searchTerm.trim() ? searchResults.length : totalItems}
          paginate={paginate}
          currentPage={page}
          itensPerPage={itensPerPage}
          setItensPerPage={setItensPerPage}
        />
      </div>
    </div>
  );
};

export default ConsultarTiposComunicacao;
