/* eslint-disable */
export const itemsCorretora = [
  {
    label: 'Home',
    command: () => {
      window.location.href = '/home';
    },
  },

  {
    label: 'Consultar SM Lançadas',
    command: () => {
      window.location.href = '/consultar-sm-lancadas';
    },
  },
  {
    label: 'Relatório de Não Conformidades',
    command: () => {
      window.location.href = '/nao-conformidade/consulta';
   },
  },
  {
    label: 'Usuarios',
    command: () => {
      window.location.href = '/listar-usuarios-corretoras/1';
    },
  },
  {
    label: 'Fazer Logout',
    icon: 'pi pi-fw pi-user-minus',
    command: () => {
      window.location.href = '/logout';
    },
  },
];
