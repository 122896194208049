const getMotivoParada = (tipo: string | undefined): string => {
  if (tipo === '1') {
    return 'Alimentação';
  }
  if (tipo === '2') {
    return 'Descanso';
  }
  if (tipo === '3') {
    return 'Entrega';
  }
  if (tipo === '4') {
    return 'Coleta';
  }
  if (tipo === '5') {
    return 'Fiscalização';
  }
  if (tipo === '9') {
    return 'Outros';
  }
  return '';
};
export default getMotivoParada;
