import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { AvisosBloqueios } from '../../api/Types/Types';
import Search from '../../components/Search/Search';
import { Spinner } from '../../components/Spinner';
import Table from '../../components/Table/Table';
import { Tela } from '../../components/Tela';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { getCookieSessionData } from '../../services/cookieService';
import { PendentesObj } from '../../types';
import AvisosImportantes from '../Monitoramento/AvisosImportantes/AvisosImportantes';
import './styles.css';

type Contagem = {
  countAltoValor: number;
  countEmAndamento: number;
  countPamcary: number;
  countPendente: number;
  countAvisos: number;
};

type Props = {
  canInsertNaoConformidade?: boolean;
  canDelete?: boolean;
  canEditSat?: boolean;
  canInsertSat?: boolean;
  canEditNaoConformidade?: boolean;
};

export const PainelOperacional = ({
  canInsertNaoConformidade,
  canDelete,
  canEditNaoConformidade,
  canEditSat,
  canInsertSat,
}: Props) => {
  const { page }: any = useParams();
  const { tab }: any = useParams();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [disabled, setDisabled] = useState(false);
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false);
  const { usuarioId } = getCookieSessionData().usuarioVO;
  const [solicitacoesContagem, setSolicitacoesContagem] = useState<Contagem>({
    countAltoValor: 0,
    countEmAndamento: 0,
    countPamcary: 0,
    countPendente: 0,
    countAvisos: 0,
  });
  const [dataServicosContratados, setDataServicosContratados] = useState<AvisosBloqueios[]>([]);
  const [totalBloqueio, setTotalBloqueio] = useState<number>(0);
  const { addToast } = useToast();
  const history = useHistory();
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const [atualizaPosCancelamento, setAtualizaPosCancelamento] = useState(false);

  useEffect(() => {
    const fetchDataServicosContratados = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/cliente-servico-contratado/getAll`);

        const listaClientesServicos = response.data.filter(
          (fil: any) =>
            fil.cscNuMonitoramentoVeiculos === 2 ||
            fil.cscNuWsposicoes === 2 ||
            fil.cscNuRotaPorPontos === 2 ||
            fil.cscNuCadastroConsulta === 2 ||
            fil.cscNuSmsviagem === 2 ||
            fil.cscNuMapaRastreamento === 2 ||
            fil.cscNuCronogramaViagens === 2
        );
        setDataServicosContratados(listaClientesServicos);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };

    fetchDataServicosContratados();
  }, []);

  const getFullTime = (date: string | null | undefined) => {
    if (!date) {
      return null;
    }
    const dateArr = date.split('T');
    const hour = dateArr[1].split('.')[0].split(':')[0];
    const minutes = dateArr[1].split('.')[0].split(':')[1];
    const seconds = dateArr[1].split('.')[0].split(':')[2];

    return `${hour}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    setTotalBloqueio(dataServicosContratados.length);
  }, [dataServicosContratados]);

  const retornaTipo = (t: string) => {
    switch (t) {
      case 'em-andamento':
        return 'EM_ANDAMENTO';
      case 'pendentes':
        return 'PENDENTE';
      case 'avisos-importantes':
        return 'AVISOS_IMPORTANTES';
      case 'alto-valor':
        return 'ALTO_VALOR';
      case 'pamcary':
        return 'PAMCARY';
      default:
        return '';
    }
  };

  const insertColorHint = (d: any[]) => {
    const array = d.map((each: any) => {
      const arrayCheck = each.hintChecklist?.split(',');
      const arrayOk = each.hintChecklist
        ?.split(',')
        .filter((check: string) => check?.split(':')[1]?.trim().toLowerCase().includes('ok'));
      return { ...each, hintColor: arrayCheck.length === arrayOk.length ? '#3AE963' : '#E93A3A' };
    });
    return array;
  };

  let timer: any;

  const fetch = async () => {
    try {
      const response = await api.get('/solicitacao-monitoramento/contarsolicitacoes', {
        params: {
          idUsuario: usuarioId,
        },
      });
      setLoading(false);
      setSolicitacoesContagem(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    if (retornaTipo(tab) === 'AVISOS_IMPORTANTES') {
      setLoadingSearch(false);
      return;
    }
    setIsDialogAtualizandoOK(!loadingSearch);
    setDisabled(true);
    try {
      const response = await api.get('/solicitacao-monitoramento/bytype', {
        params: {
          idUsuario: usuarioId,
          type: retornaTipo(tab),
        },
      });
      if (response.data.length) {
        if (response.data[0].tipo !== retornaTipo(tab)) {
          fetchData();
        }
        setData(
          retornaTipo(tab) === 'PENDENTE'
            ? insertColorHint(response.data)
            : retornaTipo(tab) === 'EM_ANDAMENTO'
            ? orderBy(response.data, 'dataInicioReal', 'desc')
            : response.data
        );
      } else {
        setData([]);
      }
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro',
        description: 'Houve algum erro ao atualizar os dados.',
        type: 'error',
      });
      // fetchData();
    } finally {
      setIsDialogAtualizandoOK(false);
      setDisabled(false);
      setLoadingSearch(false);
      fetch();
    }
  };

  let timerCount: any;

  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (newValue && data.find((each: PendentesObj) => each.id === parseInt(newValue, 10))) {
      fetchData();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, [data, solicitacoesContagem]);

  useEffect(() => {
    if (atualizaPosCancelamento) {
      setIsDialogAtualizandoOK(!loadingSearch);
      setDisabled(true);
      setTimeout(() => {
        console.log('cancelou, atualizando');
        fetchData();
      }, 3000);
    }
  }, [atualizaPosCancelamento]);

  useEffect(() => {
    setLoading(true);
    fetch();
  }, []);

  useEffect(() => {
    setLoadingSearch(true);
    setData([]);
    setDataSearch([]);
    setSearchTerm('');
    fetchData();
    timer = setInterval(() => {
      fetchData();
    }, 120000);
    return () => clearInterval(timer);
  }, [tab]);

  const paginate = (pageNumber: number) => {
    history.push(`/admin/painel-operacional/${tab}/${pageNumber}`);
  };

  const handleSituacao = (value: string) => {
    switch (value) {
      case 'EM_ANDAMENTO':
        return 'Em Andamento';
      case 'PENDENTE':
        return 'Pendente';
      default:
        return '';
    }
  };

  const handleEscoltaIsca = (escolta: boolean, isca: boolean) => {
    if (escolta === true && isca === false) return 'Escolta';
    if (isca === true && escolta === false) return ' Isca';
    if (isca === true && escolta === true) return ' Escolta / Isca';

    return '';
  };

  useEffect(() => {
    if (!searchTerm) {
      return;
    }
    if (data) {
      const searchV = searchTerm.toLowerCase();
      const filtered = data.filter(
        (each: any) =>
          each.id?.toString().includes(searchV) ||
          each.empresa?.toLowerCase()?.includes(searchV) ||
          each.origem?.toLowerCase().includes(searchV) ||
          each.destino?.toLowerCase().includes(searchV) ||
          each.placa?.toLowerCase().includes(searchV) ||
          each.tecnologia?.toLowerCase().includes(searchV) ||
          each.valorCarga
            ?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
            ?.includes(searchV) ||
          handleEscoltaIsca(each.escolta, each.isca)?.toLowerCase().includes(searchV) ||
          `${new Date(each.previsao).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })} ${new Date(each.previsao).toLocaleTimeString().slice(0, -3)}`?.includes(searchV) ||
          `${new Date(each.inclusao).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })} ${new Date(each.inclusao).toLocaleTimeString().slice(0, -3)}`?.includes(searchV) ||
          handleSituacao(each.situacao)?.toLowerCase().includes(searchV)
      );
      setDataSearch(filtered);
    }
  }, [searchTerm]);

  return (
    <Tela
      nome="Painel Operacional"
      caminho="Operacional > Painel Operacional"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={tab === 'avisos-importantes' ? undefined : page}
    >
      {solicitacoesContagem && (
        <div className="abas-search">
          <div>
            <button
              type="button"
              className={`tabbar ${tab === 'pendentes' ? 'tabbar-active' : ''}`}
              onClick={() => history.push('/admin/painel-operacional/pendentes/1')}
              disabled={disabled}
            >
              <span
                className={`tabbar-title ${tab === 'PENDENTE' ? 'tabbar-title-active' : ''}`}
              >{`Pendentes (${solicitacoesContagem.countPendente})`}</span>
            </button>
            <button
              type="button"
              className={`tabbar ${tab === 'em-andamento' ? 'tabbar-active' : ''}`}
              onClick={() => history.push('/admin/painel-operacional/em-andamento/1')}
              disabled={disabled}
            >
              <span
                className={`tabbar-title ${tab === 'em-andamento' ? 'tabbar-title-active' : ''}`}
              >{`Em andamento (${solicitacoesContagem.countEmAndamento})`}</span>
            </button>
            <button
              type="button"
              className={`tabbar ${tab === 'avisos-importantes' ? 'tabbar-active' : ''}`}
              onClick={() => history.push('/admin/painel-operacional/avisos-importantes/1')}
              disabled={disabled}
            >
              <span>
                <span
                  className={`tabbar-title ${
                    tab === 'avisos-importantes' ? 'tabbar-title-active' : ''
                  }`}
                >{`Avisos Importantes `}</span>
                <span style={{ color: 'red' }}>{`(${
                  solicitacoesContagem.countAvisos
                    ? totalBloqueio + solicitacoesContagem.countAvisos
                    : totalBloqueio
                })`}</span>
              </span>
            </button>
            <button
              type="button"
              className={`tabbar ${tab === 'alto-valor' ? 'tabbar-active' : ''}`}
              onClick={() => history.push('/admin/painel-operacional/alto-valor/1')}
              disabled={disabled}
            >
              <span
                className={`tabbar-title ${tab === 'alto-valor' ? 'tabbar-title-active' : ''}`}
              >{`Alto Valor (${solicitacoesContagem.countAltoValor}) `}</span>
            </button>
            <button
              type="button"
              className={`tabbar ${tab === 'pamcary' ? 'tabbar-active' : ''}`}
              onClick={() => history.push('/admin/painel-operacional/pamcary/1')}
              disabled={disabled}
            >
              <span>
                <span
                  className={`tabbar-title ${tab === 'pamcary' ? 'tabbar-title-active' : ''}`}
                >{`Pamcary `}</span>
                <span style={{ color: 'red' }}>{`(${solicitacoesContagem.countPamcary})`}</span>
              </span>
            </button>
          </div>
          {tab !== 'avisos-importantes' && (
            <Search
              setLoading={setLoadingSearch}
              setSearch={setSearchTerm}
              setSearchValue={setSearchValue}
              search={searchTerm}
            />
          )}
        </div>
      )}

      {loadingSearch ? (
        <Spinner />
      ) : tab === 'avisos-importantes' ? (
        <AvisosImportantes />
      ) : (
        <Table
          atualizaPosCancelamento={atualizaPosCancelamento}
          setAtualizaPosCancelamento={setAtualizaPosCancelamento}
          data={currentData}
          currentTab={retornaTipo(tab)}
          setIsDialogAtualizandoOK={setIsDialogAtualizandoOK}
          isDialogAtualizandoOK={isDialogAtualizandoOK}
          canDelete={canDelete}
          canInsertNaoConformidade={canInsertNaoConformidade}
          canEditNaoConformidade={canEditNaoConformidade}
          canEditSat={canEditSat}
          canInsertSat={canInsertSat}
        />
      )}
    </Tela>
  );
};
