/* eslint-disable */

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SMEmAndamentoType } from '../../api/model/Veiculos/SMEmAndamentoType';
import { UsuarioType } from '../../types';
import maskCPF from '../../util/marcaraCPF';
import { isDateLessThanBaseDate, maskTel, moneyToNumber } from '../../util/Validacoes/Validacoes';
import './styles.css';
import { PreCancelamentoType } from '../../pages/IniciarSMPendente/index';
import { FormataStringData2 } from '../../util/Validacoes/Validacoes';
import getMotivoParada from '../../util/Validacoes/getMotivoParada';
import axios from '../../config/axiosMaquina';
import { BauDTO, Dispositivo, DispositivoDTO } from '../../Types/AssistenciaTecnica';
import { getCookieSessionData } from '../../services/cookieService';
import { ContainerButtons, PrimaryButton, SecundaryButton } from '../ButtonsForm/styles';
import { obterData, obterHora, obterDataHora } from '../../util/date';

type SolicitacaoMonitoramentoDTO = {
  smData: SMEmAndamentoType;
  usuarioLogado: UsuarioType | undefined;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isAdmin: boolean;
  buttonSubmitTitle?: string;
  editValorDescricaoCarga?: boolean;
  setSmData?: React.Dispatch<React.SetStateAction<SMEmAndamentoType | undefined>>;
  statusInicializacao?: boolean;
  setStatusInicializacao?: (statusInicializacao: boolean) => void;
  motivos?: { id: number; motivo: string }[];
  motivoFinalizacazao?: number;
  setMotivoFinalizacao?: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  dadosCancelamento: PreCancelamentoType;
  statusVeiculoCarreta2?: string;
  statusVeiculo?: string;
  valorCargaMinimoErro?: boolean;
  erroValorCarga?: boolean;
  flagObrigatoriedadeIsca?: boolean;
  flagObrigatoriedadeEscolta?: boolean;
  canEdit?: boolean;
  iniciarSm?: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SolicitacaoMonitoramento = ({
  smData,
  usuarioLogado,
  onSubmit,
  isAdmin,
  buttonSubmitTitle,
  editValorDescricaoCarga,
  setSmData,
  statusInicializacao,
  setStatusInicializacao,
  motivos,
  motivoFinalizacazao,
  setMotivoFinalizacao,
  loading,
  dadosCancelamento,
  valorCargaMinimoErro,
  erroValorCarga,
  flagObrigatoriedadeIsca,
  flagObrigatoriedadeEscolta,
  canEdit,
  iniciarSm,
}: SolicitacaoMonitoramentoDTO) => {
  const history = useHistory();
  const [tipoComunicacao, setTipoComunicacao] = useState('');
  const [statusVeiculoCarreta, setStatusVeiculoCarreta] = useState<string>('');
  const [statusVeiculoCarreta2, setStatusVeiculoCarreta2] = useState<string>('');
  const [statusVeiculo, setStatusVeiculo] = useState<string>('');
  const [statusBau, setStatusBau] = useState<string>('');
  const [dispositivos, setDispositivos] = useState<DispositivoDTO>({} as DispositivoDTO);
  const [dispositivosCarreta, setDispositivosCarreta] = useState<DispositivoDTO>(
    {} as DispositivoDTO
  );
  const [dispositivosCarreta2, setDispositivosCarreta2] = useState<DispositivoDTO>(
    {} as DispositivoDTO
  );
  const [dispositivosBau, setDispositivosBau] = useState<BauDTO>({} as BauDTO);
  const { role } = getCookieSessionData().usuarioVO;

  const goBack = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  const fetchDadosVeiculosParaSat = useCallback(async (idVeiculo: number, idSat: number) => {
    const response = await axios.get('/solicitacao-assistencia-tecnica/listar-dados-sat', {
      params: {
        idSat: idSat,
        idVeiculo: idVeiculo,
      },
    });

    response.data.listaSatDispositivos = response.data.listaSatDispositivos.map(
      (dispositivo: Dispositivo) => {
        if (dispositivo.dtFinalizacao) {
          if (
            isDateLessThanBaseDate({
              baseDate: new Date(smData?.dataInicioReal!).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
              date: new Date(dispositivo.dtFinalizacao).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
            }) === true
          ) {
            dispositivo.defeito = false;
          } else {
            dispositivo.defeito = true;
          }
          return dispositivo;
        } else if (dispositivo.dtLancamento) {
          if (
            isDateLessThanBaseDate({
              baseDate: new Date(smData?.dataInicioReal!).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
              date: new Date(dispositivo.dtLancamento).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
            }) === true
          ) {
            dispositivo.defeito = false;
          } else {
            dispositivo.defeito = true;
          }
          return dispositivo;
        }
      }
    );
    return response;
  }, []);

  const fetchDadosBauParaSat = useCallback(async (idBau: number, idSat: number) => {
    const response = await axios.get('/solicitacao-assistencia-tecnica/listar-dados-sat-bau', {
      params: {
        idBau: idBau,
        idSat: idSat,
      },
    });

    response.data.listaSatDispositivos = response.data.listaSatDispositivos.map(
      (dispositivo: Dispositivo) => {
        if (dispositivo.dtFinalizacao) {
          if (
            isDateLessThanBaseDate({
              baseDate: new Date(smData?.dataInicioReal!).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
              date: new Date(dispositivo.dtFinalizacao).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
            }) === true
          ) {
            dispositivo.defeito = false;
          } else {
            dispositivo.defeito = true;
          }
          return dispositivo;
        } else if (dispositivo.dtLancamento) {
          if (
            isDateLessThanBaseDate({
              baseDate: new Date(smData?.dataInicioReal!).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
              date: new Date(dispositivo.dtLancamento).toLocaleDateString('pt-br', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }),
            }) === true
          ) {
            dispositivo.defeito = false;
          } else {
            dispositivo.defeito = true;
          }
          return dispositivo;
        }
      }
    );

    return response;
  }, []);

  useEffect(() => {
    if (smData?.idVeiculo && smData?.idSat) {
      fetchDadosVeiculosParaSat(smData?.idVeiculo, smData?.idSat).then((response) => {
        setDispositivos(response.data);
      });
    }
    if (smData?.idCarreta1 && smData?.idSatCarreta) {
      fetchDadosVeiculosParaSat(smData?.idCarreta1, smData?.idSatCarreta).then((response) => {
        setDispositivosCarreta(response.data);
      });
    }
    if (smData?.idCarreta2 && smData?.idSatCarreta2) {
      fetchDadosVeiculosParaSat(smData?.idCarreta2, smData?.idSatCarreta2).then((response) => {
        setDispositivosCarreta2(response.data);
      });
    }
    if (smData?.idBau && smData?.idSatBau) {
      fetchDadosBauParaSat(smData?.idBau, smData?.idSatBau).then((response) => {
        setDispositivosBau(response.data);
      });
    }
  }, [
    fetchDadosBauParaSat,
    fetchDadosVeiculosParaSat,
    smData?.idBau,
    smData?.idCarreta2,
    smData?.idCarreta1,
    smData?.idVeiculo,
  ]);

  useEffect(() => {
    if (smData && smData?.idCheckBau && smData?.idBau) {
      axios
        .get(
          `/checklist-veiculo/dados-bau-para-sm?idCheck=${smData?.idCheckBau}&idSm=${smData?.id}`
        )
        .then((response: any) => {
          let listaDispositivosComDefeito = [];
          if (response.data.checklist.includes('Vencido')) {
            listaDispositivosComDefeito = response.data.listaDispositivo.filter(
              (fil: any) => fil.situacaoDispositivo === '3'
            );
          }

          setStatusBau(
            listaDispositivosComDefeito.length > 0
              ? `Dispositivos pendentes de manutenção: (${listaDispositivosComDefeito.map(
                  (each: any) => each.nomeDispositivo
                )})`
              : response.data.checklist
          );
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else if (smData && smData?.idBau) {
      setStatusBau('Checklist Inexistente');
    }

    if (smData && smData?.idCheckCarreta && smData?.idCarreta1) {
      axios
        .get(
          `/checklist-veiculo/dados-veiculo-para-sm?idCheck=${smData?.idCheckCarreta}&idSm=${smData?.id}`
        )
        .then((response: any) => {
          let listaDispositivosComDefeito = [];
          if (response.data.checklist.includes('Vencido')) {
            listaDispositivosComDefeito = response.data.listaDispositivo.filter(
              (fil: any) => fil.situacaoDispositivo === '3'
            );
          }

          setStatusVeiculoCarreta(
            listaDispositivosComDefeito.length > 0
              ? `Dispositivos pendentes de manutenção: (${listaDispositivosComDefeito.map(
                  (each: any) => each.nomeDispositivo
                )})`
              : response.data.checklist
          );
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else if ((smData && smData?.idCarreta1) || smData?.idCarreta2) {
      setStatusVeiculoCarreta('Checklist Inexistente');
    }

    if (smData && smData?.idCheckCarreta2 && smData?.idCarreta2) {
      axios
        .get(
          `/checklist-veiculo/dados-veiculo-para-sm?idCheck=${smData?.idCheckCarreta2}&idSm=${smData?.id}`
        )
        .then((response: any) => {
          let listaDispositivosComDefeito = [];
          if (response.data.checklist.includes('Vencido')) {
            listaDispositivosComDefeito = response.data.listaDispositivo.filter(
              (fil: any) => fil.situacaoDispositivo === '3'
            );
          }

          setStatusVeiculoCarreta2(
            listaDispositivosComDefeito.length > 0
              ? `Dispositivos pendentes de manutenção: (${listaDispositivosComDefeito.map(
                  (each: any) => each.nomeDispositivo
                )})`
              : response.data.checklist
          );
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else if ((smData && smData?.idCarreta1) || smData?.idCarreta2) {
      setStatusVeiculoCarreta2('Checklist Inexistente');
    }

    if (smData && smData?.idCheck && smData?.idVeiculo) {
      axios
        .get(
          `/checklist-veiculo/dados-veiculo-para-sm?idCheck=${smData?.idCheck}&idSm=${smData?.id}`
        )
        .then((response: any) => {
          let listaDispositivosComDefeito = [];
          if (response.data.checklist.includes('Vencido')) {
            listaDispositivosComDefeito = response.data.listaDispositivo.filter(
              (fil: any) => fil.situacaoDispositivo === '3'
            );
          }

          setStatusVeiculo(
            listaDispositivosComDefeito.length > 0
              ? `Dispositivos pendentes de manutenção: (${listaDispositivosComDefeito.map(
                  (each: any) => each.nomeDispositivo
                )})`
              : response.data.checklist
          );
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else if (smData?.idVeiculo) {
      setStatusVeiculo('Checklist Inexistente');
    }
  }, [smData]);

  useEffect(() => {
    const loadTipoComunicacao = async () => {
      if (isNumber(smData.tipoComunicacao)) {
        try {
          const response = await axios.get(
            `/tipo-comunicacao/tecnologiatipocomunicacao/${smData.tipoComunicacao}`
          );
          setTipoComunicacao(response.data.ttcTxNome);
        } catch (err: any) {
          console.log(err);
        }
      } else {
        setTipoComunicacao(smData.tipoComunicacao != null ? smData.tipoComunicacao : '');
      }
    };
    loadTipoComunicacao();
  }, [smData]);

  const isNumber = (n: any) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const renderSatMessage = (idSat: number | undefined | null) => {
    if (idSat) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flex: 1,
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          <p
            style={{ color: 'black', fontSize: 18 }}
          >{`Solicitação Monitoramento enviada, após bloqueio e não recomendação da viagem pela Golden Service (Equipamento de rastreio com defeito) e posterior liberação pelo cliente através de redigitação de senha de acesso. Liberado por: ${smData.usuarioNome}.`}</p>
        </div>
      );
    }

    return null;
  };

  const renderChecklistMessageVeiculo = () => {
    if (statusVeiculo?.includes('OK')) {
      return;
    } else {
      if (smData?.idVeiculo && statusVeiculo) {
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
              }}
            >
              <p style={{ color: 'red', fontSize: 18, fontWeight: 'bold' }}>{statusVeiculo}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
                textAlign: 'center',
              }}
            >
              <p
                style={{ color: 'black', fontSize: 18 }}
              >{`Solicitação Monitoramento enviada, após não recomendação da viagem pela Golden Service e posterior liberação pelo cliente através de redigitação de senha de acesso. Liberado por: ${smData.usuarioNome}.`}</p>
            </div>
          </>
        );
      }
    }
  };

  const renderChecklistMessageCarreta = () => {
    if (statusVeiculoCarreta?.includes('OK')) {
      return;
    } else {
      if (smData?.idCarreta1 && statusVeiculoCarreta) {
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
              }}
            >
              <p style={{ color: 'red', fontSize: 18, fontWeight: 'bold' }}>
                {statusVeiculoCarreta}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
                textAlign: 'center',
              }}
            >
              <p
                style={{ color: 'black', fontSize: 18 }}
              >{`Solicitação Monitoramento enviada, após não recomendação da viagem pela Golden Service e posterior liberação pelo cliente através de redigitação de senha de acesso. Liberado por: ${smData.usuarioNome}.`}</p>
            </div>
          </>
        );
      }
    }
  };

  const renderChecklistMessageCarreta2 = () => {
    if (statusVeiculoCarreta2?.includes('OK')) {
      return;
    } else {
      if (smData?.idCarreta2 && statusVeiculoCarreta2) {
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
              }}
            >
              <p style={{ color: 'red', fontSize: 18, fontWeight: 'bold' }}>
                {statusVeiculoCarreta2}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
                textAlign: 'center',
              }}
            >
              <p
                style={{ color: 'black', fontSize: 18 }}
              >{`Solicitação Monitoramento enviada, após não recomendação da viagem pela Golden Service e posterior liberação pelo cliente através de redigitação de senha de acesso. Liberado por: ${smData.usuarioNome}.`}</p>
            </div>
          </>
        );
      }
    }
  };

  const renderChecklistMessageBau = () => {
    if (statusBau?.includes('OK')) {
      return;
    } else {
      if (smData?.idBau && statusBau) {
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
              }}
            >
              <p style={{ color: 'red', fontSize: 18, fontWeight: 'bold' }}>{statusBau}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
                textAlign: 'center',
              }}
            >
              <p
                style={{ color: 'black', fontSize: 18 }}
              >{`Solicitação Monitoramento enviada, após não recomendação da viagem pela Golden Service e posterior liberação pelo cliente através de redigitação de senha de acesso. Liberado por: ${smData.usuarioNome}.`}</p>
            </div>
          </>
        );
      }
    }
  };

  const renderDispositivosVeiculo = (dispositivosVeiculo: DispositivoDTO) => {
    return (
      <>
        <Form.Row>
          <Form.Group as={Col} className="mt-3 ml-3">
            <Form.Label>Solicitação de Assistência Técnica Nº</Form.Label>
            <Form.Control
              value={
                smData && smData?.idSat
                  ? smData?.idSat
                  : smData?.idSatCarreta
                  ? smData?.idSatCarreta
                  : smData?.idSatCarreta2
                  ? smData?.idSatCarreta2
                  : ''
              }
              id="idSat"
              name="idSat"
              placeholder=""
              style={{ width: '50%' }}
              disabled
            />
          </Form.Group>
        </Form.Row>
        <div className="table-responsive" style={{ width: '45%', marginLeft: 20 }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Dispositivo</th>
                <th>Inclusão</th>
              </tr>
            </thead>
            <tbody>
              {dispositivosVeiculo.id &&
                dispositivosVeiculo.listaSatDispositivos.map((each) => {
                  if (!each) {
                    return null;
                  }
                  return (
                    <tr key={each.id} className="dispositivos-table">
                      <td>{each.dispositivoNome}</td>
                      <td>{each.dtLancamento ? obterDataHora(each.dtLancamento) : ''}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderDispositivosBau = (dispositivosVeiculo: DispositivoDTO) => {
    return (
      <>
        <Form.Row>
          <Form.Group as={Col} className="mt-3 ml-3">
            <Form.Label>Solicitação de Assistência Técnica Nº</Form.Label>
            <Form.Control
              value={smData && smData?.idSatBau ? smData?.idSatBau : ''}
              id="idSat"
              name="idSat"
              placeholder=""
              style={{ width: '50%' }}
              disabled
            />
          </Form.Group>
        </Form.Row>
        <div className="table-responsive" style={{ width: '45%', marginLeft: 20 }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Dispositivo</th>
                <th>Inclusão</th>
              </tr>
            </thead>
            <tbody>
              {dispositivosVeiculo.id &&
                dispositivosVeiculo.listaSatDispositivos.map((each) => {
                  if (!each) {
                    return null;
                  }
                  return (
                    <tr key={each.id} className="dispositivos-table">
                      <td>{each.dispositivoNome}</td>
                      <td>{each.dtLancamento ? obterDataHora(each.dtLancamento) : ''}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const solicitacaoCancelamento = (dadosCancelamento: any) => {
    const { solicitanteCancelamento, dataCancelamento, nomeCliente, nomeUsuario } =
      dadosCancelamento;
    const dataHoraFormatada = `${FormataStringData2(
      dataCancelamento.split('T')[0]
    )}  ${dataCancelamento.split('T')[1].slice(0, -13)}`;

    if (solicitanteCancelamento && dataHoraFormatada) {
      return `${solicitanteCancelamento}  ${dataHoraFormatada}`;
    }

    if (!dataCancelamento && !nomeCliente && !nomeUsuario) {
      return '';
    }
    if (dataCancelamento && !nomeCliente && !nomeUsuario) {
      return dataHoraFormatada;
    }
    if ((dataCancelamento && nomeCliente && nomeUsuario) || (dataCancelamento && nomeCliente)) {
      return `${nomeCliente}  ${dataHoraFormatada}`;
    }
    if (dataCancelamento && nomeUsuario) {
      return `${nomeUsuario}  ${dataHoraFormatada}`;
    } else {
      return '';
    }
  };

  const confirmacaoCancelamento = (dadosCancelamento: any) => {
    const { dataCancelamento, nomeCliente, nomeUsuario } = dadosCancelamento;
    const dataHoraFormatada = `${obterData(dataCancelamento)} ${obterHora(dataCancelamento, true)}`;

    if (!dataCancelamento && !nomeCliente && !nomeUsuario) {
      return '';
    }
    if (dataCancelamento && !nomeCliente && !nomeUsuario) {
      return dataHoraFormatada;
    }
    if (dataCancelamento && nomeUsuario) {
      return `${nomeUsuario ?? nomeCliente}  ${dataHoraFormatada}`;
    }
    if (dataCancelamento && nomeCliente) {
      return `${nomeCliente}  ${dataHoraFormatada}`;
    } else {
      return '';
    }
  };

  return (
    <Form style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Form.Row>
        <Form.Group as={Col} className="mt-2 ml-3">
          <Form.Label>Nº SM</Form.Label>

          <Form.Control value={smData.id} id="id" name="id" placeholder="" disabled />
        </Form.Group>

        <Form.Group as={Col} className="mt-2">
          <Form.Label>Nome Empresa</Form.Label>

          <Form.Control
            value={smData.clienteRazaoSocial}
            id="clienteRazaoSocial"
            name="clienteRazaoSocial"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Usuário</Form.Label>

          <Form.Control
            value={smData.usuarioNome}
            id="usuario"
            name="usuario"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Lançamento</Form.Label>

          <Form.Control
            value={
              smData.dataLancamento
                ? `${obterData(smData.dataLancamento)} ${obterHora(smData.dataLancamento, true)}`
                : ''
            }
            id="dataLancamento"
            name="dataLancamento"
            type="text"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      {smData.cancelada ? (
        <>
          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Solicitação Cancelamento</Form.Label>

              <Form.Control
                value={solicitacaoCancelamento(dadosCancelamento)}
                id="solicitacaoCancelamento"
                name="solicitacaoCancelamento"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Via</Form.Label>

              <Form.Control
                value={dadosCancelamento.dataCancelamento ? 'Sistema' : ''}
                id="comunicacao"
                name="comunicacao"
                type="text"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Motivo</Form.Label>

              <Form.Control
                value={MOTIVOS.filter((fil) => fil.id === dadosCancelamento.motivoCancelamento).map(
                  (each) => each.motivoName
                )}
                id="motivo"
                name="motivo"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Confirmação Cancelamento</Form.Label>

              <Form.Control
                value={confirmacaoCancelamento(dadosCancelamento)}
                id="comunicacao"
                name="comunicacao"
                type="text"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
        </>
      ) : null}

      <Form.Text style={{ fontWeight: 'bold' }}>Situação da Viagem</Form.Text>

      <Form.Group as={Col} className="mt-3">
        <Form.Label>Status</Form.Label>
        <Form.Control
          value={
            smData.finalizada
              ? 'Finalizada'
              : smData.cancelada
              ? 'Cancelada'
              : smData.statusInicializacao === undefined
              ? 'Em Andamento'
              : 'Pendente'
          }
          id="status-sm"
          name="status-sm"
          placeholder=""
          style={{ width: '15%' }}
          disabled
        />
      </Form.Group>

      <Form.Text style={{ fontWeight: 'bold' }}>Dados da Viagem</Form.Text>

      <Form.Row>
        <Form.Group as={Col} className="mt-3 ml-3">
          <Form.Label>Início Viagem</Form.Label>

          <Form.Control
            id="dataInicioPend"
            name="dataInicioPend"
            placeholder=""
            disabled
            value={smData.dataInicio != null ? obterData(smData.dataInicio, 'en2') : ' '}
            type="date"
          />
        </Form.Group>

        <Form.Group as={Col} className="mt-3">
          <Form.Label>Hora Início</Form.Label>

          <Form.Control
            value={smData.horaInicio != null ? obterHora(smData.horaInicio) : ' '}
            id="horaInicio"
            name="horaInicio"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Término Viagem</Form.Label>

          <Form.Control
            value={smData.dataFim != null ? obterData(smData.dataFim, 'en2') : ' '}
            id="dataFim"
            name="dataFim"
            placeholder=""
            disabled
            type="date"
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Hora Término</Form.Label>

          <Form.Control
            value={smData.horaFim != null ? obterHora(smData.horaFim) : ' '}
            id="horaFim"
            name="horaFim"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      <Form.Group as={Col}>
        <Form.Label>Embarcador</Form.Label>

        <Form.Control
          value={smData.embarcador}
          id="embarcador"
          name="embarcador"
          placeholder=""
          disabled
          style={{ width: '101%' }}
        />
      </Form.Group>

      {smData.listaPontos[0] ? (
        <Form.Text style={{ fontWeight: 'bold' }}>Dados da Rota</Form.Text>
      ) : (
        <Form.Text style={{ fontWeight: 'bold' }}>Dados da Rota Cadastrada</Form.Text>
      )}

      {smData.listaPontos[0] ? (
        smData.listaPontos.map((each, index) => {
          return (
            <Form>
              <Form.Row className="ml-2">
                <Form.Group as={Col} className="mt-3">
                  {index === 0 ? (
                    <Form.Label>Ponto Origem</Form.Label>
                  ) : (
                    <Form.Label>Ponto({index + 1})</Form.Label>
                  )}

                  <Form.Control
                    value={each.descricao}
                    id="pontoOrigem"
                    name="pontoOrigem"
                    placeholder=""
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} className="mt-3">
                  <Form.Label>Nº Remessa</Form.Label>

                  <Form.Control
                    value={each.numeroRemessa ? each.numeroRemessa : ''}
                    id="remessa"
                    name="remessa"
                    placeholder=""
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} className="mt-3">
                  <Form.Label>Agendamento</Form.Label>

                  <Form.Control
                    value={`${obterData(each.agendamento)} ${obterHora(each.agendamento)}`}
                    id="estadoDestino"
                    name="estadoDestino"
                    placeholder=""
                    disabled
                    style={{ width: '101%' }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Endereço</Form.Label>

                <Form.Control
                  value={`${each.endereco} ${each.numero ? `, ${each.numero}` : ''} ${
                    each.complemento ? `, ${each.complemento}` : ''
                  }${each.bairro ? `, ${each.bairro}` : ''}`}
                  id="endereco"
                  name="endereco"
                  placeholder=""
                  disabled
                  style={{ width: '101.3%' }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Cidade</Form.Label>

                <Form.Control
                  value={`${each.cidade ? each.cidade : ''} - ${each.uf ? each.uf : ''}`}
                  id="cidade"
                  name="cidade"
                  placeholder=""
                  disabled
                  style={{ width: '101.3%' }}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <hr />
                </Form.Group>
              </Form.Row>
            </Form>
          );
        })
      ) : (
        <Form>
          <Form.Group as={Col} className="mt-3">
            <Form.Label>Origem</Form.Label>

            <Form.Control
              value={smData.estadoOrigem ? smData.estadoOrigem : ''}
              id="estadoOrigem"
              name="estadoOrigem"
              placeholder=""
              disabled
              style={{ width: '101%' }}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Endereço Origem</Form.Label>

            <Form.Control
              value={
                smData.cidadeOrigem || smData.logradouroOrigem
                  ? `${
                      smData.logradouroOrigem && smData.logradouroOrigem != undefined
                        ? smData.logradouroOrigem
                        : ''
                    } ${
                      smData.numeroOrigem && smData.numeroOrigem != undefined
                        ? smData.numeroOrigem + ','
                        : ''
                    } ${smData.descricaoOrigem ? smData.descricaoOrigem + ',' : ''} ${
                      smData.bairroOrigem && smData.bairroOrigem != undefined
                        ? smData.bairroOrigem + ','
                        : ''
                    } ${
                      smData.cidadeOrigem && smData.cidadeOrigem != undefined
                        ? smData.cidadeOrigem
                        : ''
                    }  ${
                      smData.estadoOrigem && smData.estadoOrigem != undefined
                        ? '- ' + smData.estadoOrigem
                        : ''
                    } `
                  : ''
              }
              id="enderecoOrigem"
              name="enderecoOrigem"
              placeholder=""
              disabled
              style={{ width: '101%' }}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Destino</Form.Label>

            <Form.Control
              value={smData.estadoDestino ? smData.estadoDestino : ''}
              id="estadoDestino"
              name="estadoDestino"
              placeholder=""
              disabled
              style={{ width: '101%' }}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Endereço Destino</Form.Label>

            <Form.Control
              value={
                smData.cidadeDestino || smData.logradouroDestino
                  ? `${
                      smData.logradouroDestino && smData.logradouroDestino != undefined
                        ? smData.logradouroDestino
                        : ''
                    } ${
                      smData.numeroDestino && smData.numeroDestino != undefined
                        ? smData.numeroDestino + ','
                        : ''
                    } ${smData.descricaoDestino ? smData.descricaoDestino + ',' : ''} ${
                      smData.bairroDestino && smData.bairroDestino != undefined
                        ? smData.bairroDestino + ','
                        : ''
                    } ${
                      smData.cidadeDestino && smData.cidadeDestino != undefined
                        ? smData.cidadeDestino
                        : ''
                    }  ${
                      smData.estadoDestino && smData.estadoDestino != undefined
                        ? '- ' + smData.estadoDestino
                        : ''
                    } `
                  : ''
              }
              id="enderecoDestino"
              name="enderecoDestino"
              placeholder=""
              disabled
              style={{ width: '101%' }}
            />
          </Form.Group>
        </Form>
      )}

      {smData.romaneio ? (
        <Form>
          <Form.Text style={{ fontWeight: 'bold' }}>Romaneio</Form.Text>
          <Form.Row>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Control
                value={smData.romaneio}
                id="romaneio"
                name="romaneio"
                placeholder=""
                disabled
                as="textarea"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>
        </Form>
      ) : null}

      {smData.listaParadaPorSm[0] ? (
        <Form.Text style={{ fontWeight: 'bold' }}>Roteirização</Form.Text>
      ) : null}
      {smData.listaParadaPorSm[0]
        ? smData.listaParadaPorSm.map((each, index) => {
            return (
              <Form>
                <Form.Group as={Col} className="mt-3">
                  <Form.Label>Dados Regionais</Form.Label>

                  <Form.Control
                    value={`${each.paradaCidade} - ${each.uf}`}
                    id="dadosRegionais"
                    name="dadosRegionais"
                    placeholder=""
                    disabled
                    style={{ width: '50%' }}
                  />
                </Form.Group>
                <Form.Row className="ml-2">
                  <Form.Group as={Col}>
                    <Form.Label>Local</Form.Label>
                    <Form.Control
                      value={each.paradaLocal ? each.paradaLocal : ''}
                      id="local"
                      name="local"
                      placeholder=""
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Motivo</Form.Label>
                    <Form.Control
                      value={each.paradaMotivo ? getMotivoParada(each.paradaMotivo) : ''}
                      id="motivo"
                      name="motivo"
                      placeholder=""
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Endereço</Form.Label>

                  <Form.Control
                    value={`${each.paradaLogradouro ? each.paradaLogradouro : ''} ${
                      each.paradaNumero ? `, ${each.paradaNumero}` : ''
                    } ${each.paradaComplemento ? `, ${each.paradaComplemento}` : ''}${
                      each.paradaBairro ? ` , ${each.paradaBairro}` : ''
                    } ${each.paradaCep ? each.paradaCep : ''} ${each.paradaCidade} - ${each.uf}`}
                    id="endereco"
                    name="endereco"
                    placeholder=""
                    disabled
                    style={{ width: '101%' }}
                  />
                </Form.Group>

                <Form.Row>
                  <Form.Group as={Col}>
                    <hr />
                  </Form.Group>
                </Form.Row>
              </Form>
            );
          })
        : null}

      {smData.observacao && (
        <>
          <Form.Group as={Col} className="mt-3">
            <Form.Label>Observação</Form.Label>
            <Form.Control
              value={smData.observacao}
              placeholder=""
              disabled
              style={{ width: '101%' }}
            />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>
        </>
      )}

      <Form.Text style={{ fontWeight: 'bold' }}>Dados da Carga</Form.Text>

      <Form.Group as={Col} className="mt-3">
        <Form.Label>Operação</Form.Label>

        <Form.Control
          value={smData.tipoOperacao}
          id="tipoOperacao"
          name="tipoOperacao"
          placeholder=""
          disabled
          style={{ width: '50.2%' }}
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Valor</Form.Label>

          <Form.Control
            value={Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
              smData.valorCarga!
            )}
            id="valorCarga"
            name="valorCarga"
            placeholder=""
            disabled={!editValorDescricaoCarga}
            maxLength={21}
            onChange={(e) => {
              setSmData?.((prevState) => {
                if (!prevState) {
                  return prevState;
                }
                const value = moneyToNumber(e.target.value ?? '0');
                return {
                  ...prevState,
                  valorCarga: value,
                };
              });
            }}
          />
          {valorCargaMinimoErro && (
            <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
              Valor da carga abaixo do limite permitido
            </p>
          )}
          {erroValorCarga && smData.valorCarga! > 1 && (
            <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
              Valor da carga acima do limite permitido para o Embarcador
            </p>
          )}
          {flagObrigatoriedadeEscolta && smData.valorCarga! > 1 && (
            <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
              Escolta obrigatória para o valor informado.
            </p>
          )}
          {flagObrigatoriedadeIsca && smData.valorCarga! > 1 && (
            <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
              Isca obrigatória para o valor informado.
            </p>
          )}
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Descrição</Form.Label>

          <Form.Control
            value={smData.descricaoCarga}
            onChange={(e) => {
              setSmData?.((prevState) => {
                if (!prevState) {
                  return prevState;
                }
                return { ...prevState, descricaoCarga: e.target.value };
              });
            }}
            id="descricaoCarga"
            name="descricaoCarga"
            placeholder=""
            disabled={!editValorDescricaoCarga}
          />
        </Form.Group>
      </Form.Row>
      {smData.velocidadeMaxima && (
        <Form.Row>
          <Form.Group as={Col} className="mt-3 ml-3">
            <Form.Label>Velocidade Máxima</Form.Label>

            <Form.Control
              value={smData.velocidadeMaxima ? `${smData.velocidadeMaxima} km/h` : ''}
              id="velocidadeMaxima"
              name="velocidadeMaxima"
              placeholder=""
              disabled
              style={{ width: '50.2%' }}
            />
          </Form.Group>
        </Form.Row>
      )}
      {smData.temperatura && (
        <Form.Row>
          <Form.Group as={Col} className="ml-3">
            <Form.Label>Temperatura</Form.Label>
            <Form.Control
              value={`${smData.temperatura} °C`}
              id="temperatura"
              name="temperatura"
              placeholder=""
              disabled
            />
          </Form.Group>
        </Form.Row>
      )}
      {smData.temperaturaMinima && smData.temperaturaMaxima && (
        <Form.Row>
          <Form.Group as={Col} className="ml-3">
            <Form.Label>Temperatura Mínima</Form.Label>
            <Form.Control
              value={`${smData.temperaturaMinima} °C`}
              id="temperaturaMinima"
              name="temperaturaMinima"
              placeholder=""
              disabled
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Temperatura Máxima</Form.Label>
            <Form.Control
              value={`${smData.temperaturaMaxima} °C`}
              id="temperaturaMaxima"
              name="temperaturaMaxima"
              placeholder=""
              disabled
            />
          </Form.Group>
        </Form.Row>
      )}

      {smData.veiculoEscolta ? (
        <>
          <Form.Text style={{ fontWeight: 'bold' }}>Dados da Escolta</Form.Text>
          <Form.Row>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label>Veículo Escolta</Form.Label>

              <Form.Control
                value={smData.veiculoEscolta}
                id="veiEscolta"
                name="veiescolta"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Label>Monitorado pela GS?</Form.Label>

              <Form.Control
                value={smData.escoltaGps === true ? 'Sim' : 'Não'}
                id="monitoramentoGS"
                name="monitoramentoGS"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Tecnologia</Form.Label>

              <Form.Control
                value={smData.tecnologiaEscolta}
                id="tecnologia"
                name="tecnologia"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Comunicação</Form.Label>

              <Form.Control
                value={smData.comunicacaoEscolta}
                id="comunicacaoEscolta"
                name="comunicacaoEscolta"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Nº Equipamento</Form.Label>

              <Form.Control
                value={smData.numeroEquipamentoTecnologia}
                id="numEquipamento"
                name="numEquipamento"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Empresa Escolta</Form.Label>

              <Form.Control
                value={smData.empresaEscolta}
                id="empresaEscolta"
                name="empresaEscolta"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Telefone Contato</Form.Label>

              <Form.Control
                value={smData.telEscolta && maskTel(smData.telEscolta)}
                id="telEmpresa"
                name="telEmpresa"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Agente(1)</Form.Label>

              <Form.Control
                value={smData.agenteEscolta}
                id="agente1"
                name="agente1"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Telefone Contato</Form.Label>

              <Form.Control
                value={smData.telAgenteEscolta && maskTel(smData.telAgenteEscolta)}
                id="telAgente1"
                name="telAgente1"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Agente(2)</Form.Label>

              <Form.Control
                value={smData.agenteEscolta2}
                id="agente2"
                name="agente2"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Telefone Contato</Form.Label>

              <Form.Control
                value={smData.telAgenteEscolta2 && maskTel(smData.telAgenteEscolta2)}
                id="telAgente2"
                name="telAgente2"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
        </>
      ) : null}

      {smData.comunicacaoIsca ? (
        <>
          <Form.Text style={{ fontWeight: 'bold' }}>Dados da Isca</Form.Text>
          <Form.Row>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label>Marca Isca</Form.Label>

              <Form.Control
                value={smData.tecnologiaIsca}
                id="marcaIsca"
                name="marcaIsca"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Label>Comunicação</Form.Label>

              <Form.Control
                value={smData.comunicacaoIsca}
                id="comunicacaoIsca"
                name="comunicacaoIsca"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} className="mt-3">
              <Form.Label>Nº Equipamento</Form.Label>

              <Form.Control
                value={smData.iscaEquipamento}
                id="numEquipamentoIsca"
                name="numEquipamentoIsca"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Site</Form.Label>

              <Form.Control
                value={smData.iscaSite}
                id="siteIsca"
                name="siteIsca"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Login</Form.Label>

              <Form.Control
                value={smData.loginIsca}
                id="loginIsca"
                name="loginIsca"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Senha</Form.Label>

              <Form.Control
                value={smData.senhaIsca}
                id="senhaIsca"
                name="senhaIsca"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
        </>
      ) : null}

      <Form.Text style={{ fontWeight: 'bold' }}>Dados do Veículo</Form.Text>

      <Form.Row>
        <Form.Group as={Col} className="mt-3 ml-3">
          <Form.Label>Placa</Form.Label>

          <Form.Control
            value={smData.veiPlaca}
            id="veiPlaca"
            name="veiPlaca"
            placeholder=""
            disabled
            style={{ width: '49.7%' }}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Tipo Veículo</Form.Label>

          <Form.Control
            value={smData.tipoVeiculo}
            id="veiTipo"
            name="veiTipo"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Tipo Frota</Form.Label>

          <Form.Control
            value={
              smData.tipoFrota === '1'
                ? 'Veiculo Próprio'
                : smData.tipoFrota === '2'
                ? 'Veiculo Agregado'
                : smData.tipoFrota === '3'
                ? 'Veiculo de Terceiros'
                : ' '
            }
            id="tipoFrota"
            name="tipoFrota"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Marca</Form.Label>

          <Form.Control
            value={smData.veiMarca}
            id="veiMarca"
            name="veiMarca"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Modelo</Form.Label>

          <Form.Control
            value={smData.veiModelo}
            id="veiModelo"
            name="veiModelo"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Fabricação/Modelo</Form.Label>

          <Form.Control
            value={`${smData.fabricacaoAno}/${smData.modeloAno}`}
            id="fabricacaoModelo"
            name="fabricacaoModelo"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Cor</Form.Label>

          <Form.Control value={smData.veiCor} id="veiCor" name="veiCor" placeholder="" disabled />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Tecnologia</Form.Label>

          <Form.Control
            value={`${smData.tecnologiaRastreamento} / ${tipoComunicacao}`}
            id="tecnologiaRastreamento"
            name="tecnologiaRastreamento"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Nº Equipamento</Form.Label>

          <Form.Control
            value={smData.numEquipamento}
            id="numEquipamento"
            name="numEquipamento"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>
      {renderChecklistMessageVeiculo()}

      {smData?.idSat ? (
        <Form.Text style={{ fontWeight: 'bold', marginBottom: 10 }}>
          SAT - Exibição somente do(s) dispositivo(s) com defeito
        </Form.Text>
      ) : null}

      {smData?.idSat && dispositivos && renderDispositivosVeiculo(dispositivos)}

      {smData?.idSat && dispositivos && renderSatMessage(smData?.idSat)}

      {smData.idCarreta1 && (
        <Form>
          <Form.Text style={{ fontWeight: 'bold' }}>Dados da(s) Carreta(s)</Form.Text>

          <Form.Row>
            <Form.Group as={Col} className="mt-3 ml-3" style={{ width: '50%' }}>
              <Form.Label>Carreta (1)</Form.Label>
              <Form.Control
                value={smData.placaCarreta1}
                id="placaCarreta1"
                name="placaCarreta1"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Label>Tipo Frota</Form.Label>

              <Form.Control
                value={
                  smData.tipoFrotaCarreta1 === '1'
                    ? 'Veiculo Próprio'
                    : smData.tipoFrotaCarreta1 === '2'
                    ? 'Veiculo Agregado'
                    : smData.tipoFrotaCarreta1 === '3'
                    ? 'Veiculo de Terceiros'
                    : ' '
                }
                id="tipoFrotaCarreta1"
                name="tipoFrotaCarreta1"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Marca</Form.Label>

              <Form.Control
                value={smData.marcaCarreta1}
                id="marcaCarreta1"
                name="marcaCarreta1"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Modelo</Form.Label>

              <Form.Control
                value={smData.modeloCarreta1}
                id="modeloCarreta1"
                name="modeloCarreta1"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Fabricação/Modelo</Form.Label>

              <Form.Control
                value={
                  smData.anoFabricacaoCarreta1 && smData.anoModeloCarreta1
                    ? `${smData.anoFabricacaoCarreta1}/${smData.anoModeloCarreta1}`
                    : ''
                }
                id="anoFabricacaoCarreta1"
                name="anoFabricacaoCarreta1"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Cor</Form.Label>

              <Form.Control
                value={smData.corCarreta1}
                id="corCarreta1"
                name="corCarreta1"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
        </Form>
      )}
      {smData.idCarreta1 && renderChecklistMessageCarreta()}
      {smData?.idSatCarreta ? (
        <Form.Text style={{ fontWeight: 'bold', marginBottom: 10 }}>
          SAT - Exibição somente do(s) dispositivo(s) com defeito
        </Form.Text>
      ) : null}
      {smData.idCarreta1 &&
        smData?.idSatCarreta &&
        dispositivosCarreta &&
        renderDispositivosVeiculo(dispositivosCarreta)}
      {smData.idCarreta1 &&
        smData?.idSatCarreta &&
        dispositivosCarreta &&
        renderSatMessage(smData?.idSatCarreta)}

      {smData.idCarreta2 && (
        <Form>
          <Form.Row>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label>Carreta (2)</Form.Label>

              <Form.Control
                value={smData.placaCarreta2}
                id="idCarreta2"
                name="idCarreta2"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Label>Tipo Frota</Form.Label>

              <Form.Control
                value={
                  smData.tipoFrotaCarreta2 === '1'
                    ? 'Veiculo Próprio'
                    : smData.tipoFrotaCarreta2 === '2'
                    ? 'Veiculo Agregado'
                    : smData.tipoFrotaCarreta2 === '3'
                    ? 'Veiculo de Terceiros'
                    : ' '
                }
                id="tipoFrotaCarreta2"
                name="tipoFrotaCarreta2"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Marca</Form.Label>

              <Form.Control
                value={smData.marcaCarreta2}
                id="marcaCarreta2"
                name="marcaCarreta2"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Modelo</Form.Label>

              <Form.Control
                value={smData.modeloCarreta2}
                id="modeloCarreta2"
                name="modeloCarreta2"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Fabricação/Modelo</Form.Label>

              <Form.Control
                value={
                  smData.anoFabricacaoCarreta2 && smData.anoModeloCarreta2
                    ? `${smData.anoFabricacaoCarreta2}/${smData.anoModeloCarreta2}`
                    : ''
                }
                id="anoFabricacaoCarreta2"
                name="anoFabricacaoCarreta2"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Cor</Form.Label>

              <Form.Control
                value={smData.corCarreta2}
                id="corCarreta2"
                name="corCarreta2"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
          {smData.idCarreta2 && renderChecklistMessageCarreta2()}
          {smData?.idSatCarreta2 ? (
            <Form.Text style={{ fontWeight: 'bold', marginBottom: 10 }}>
              SAT - Exibição somente do(s) dispositivo(s) com defeito
            </Form.Text>
          ) : null}
          {smData.idCarreta2 &&
            smData?.idSatCarreta2 &&
            dispositivosCarreta2 &&
            renderDispositivosVeiculo(dispositivosCarreta2)}
        </Form>
      )}
      {smData.idCarreta2 &&
        smData?.idSatCarreta2 &&
        dispositivosCarreta2 &&
        renderSatMessage(smData?.idSatCarreta)}

      {smData.numeroBau && (
        <Form>
          <Form.Text style={{ fontWeight: 'bold' }}>Dados do Baú</Form.Text>

          <Form.Row>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label>Número</Form.Label>
              <Form.Control
                value={smData.numeroBau}
                id="numeroBau"
                name="numeroBau"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                value={smData.modeloBau}
                id="modeloBau"
                name="modeloBau"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
        </Form>
      )}
      {smData.numeroBau && renderChecklistMessageBau()}
      {smData?.idSatBau ? (
        <Form.Text style={{ fontWeight: 'bold', marginBottom: 10 }}>
          SAT - Exibição somente do(s) dispositivo(s) com defeito
        </Form.Text>
      ) : null}

      {smData.numeroBau &&
        smData?.idSatBau &&
        dispositivosBau &&
        renderDispositivosBau(dispositivosBau)}
      {smData.numeroBau &&
        smData?.idSatBau &&
        dispositivosBau &&
        renderSatMessage(smData?.idSatBau)}

      <Form.Text style={{ fontWeight: 'bold' }}>Dados do(s) Condutor(es)</Form.Text>

      <Form.Group as={Col} className="mt-3">
        <Form.Label>CPF</Form.Label>

        <Form.Control
          value={smData.cpfCondutor != null ? maskCPF(smData.cpfCondutor) : ' '}
          id="cpfCondutor"
          name="cpfCondutor"
          placeholder=""
          style={{ width: '50%' }}
          disabled
        />
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label>Nome</Form.Label>

        <Form.Control
          value={smData.nomeCondutor}
          id="nomeCondutor"
          name="nomeCondutor"
          placeholder=""
          disabled
          style={{ width: '50%' }}
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>CNH Nº</Form.Label>

          <Form.Control
            value={smData.cnhCondutor}
            id="cnhCondutor"
            name="cnhCondutor"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Categoria</Form.Label>

          <Form.Control
            value={
              smData.categoriaCNHCondutor != null ? smData.categoriaCNHCondutor.toUpperCase() : ' '
            }
            id="categoriaCNHCondutor"
            name="categoriaCNHCondutor"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Tipo</Form.Label>

          <Form.Control
            value={
              smData.tipoCondutor === '1'
                ? 'Agregado'
                : smData.tipoCondutor === '2'
                ? 'Autônomo'
                : smData.tipoCondutor === '3'
                ? 'Funcionário'
                : ' '
            }
            id="tipoCondutor"
            name="tipoCondutor"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="ml-3">
          <Form.Label>Celular(1)</Form.Label>

          <Form.Control
            value={smData.tel1Condutor && maskTel(smData.tel1Condutor)}
            id="tel1Condutor"
            name="tel1Condutor"
            placeholder=""
            disabled
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Celular(2)</Form.Label>

          <Form.Control
            value={smData.tel2Condutor && maskTel(smData.tel2Condutor)}
            id="tel2Condutor"
            name="tel2Condutor"
            placeholder=""
            disabled
          />
        </Form.Group>
      </Form.Row>

      {smData.nomeAju1 ? (
        <>
          <Form.Text style={{ fontWeight: 'bold' }}>Dados do(s) Ajudante(es)</Form.Text>

          <Form.Group as={Col} className="mt-3">
            <Form.Label>CPF</Form.Label>

            <Form.Control
              value={smData.cpfAju1 != null ? maskCPF(smData.cpfAju1) : ' '}
              id="cpfAjudante"
              name="cpfAjudante"
              placeholder=""
              style={{ width: '50%' }}
              disabled
            />
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Nome</Form.Label>

              <Form.Control
                value={smData.nomeAju1}
                id="nomeAjudante"
                name="nomeAjudante"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Tipo</Form.Label>

              <Form.Control
                value={
                  smData.tipoAju1 === '1'
                    ? 'Agregado'
                    : smData.tipoAju1 === '2'
                    ? 'Autônomo'
                    : smData.tipoAju1 === '3'
                    ? 'Funcionário'
                    : ' '
                }
                id="tipoAjudante"
                name="tipoAjudante"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Group as={Col} className="mt-3">
            <Form.Label>Celular(1)</Form.Label>

            <Form.Control
              value={smData.telAju1 && maskTel(smData.telAju1)}
              id="tel1Condutor"
              name="tel1Condutor"
              placeholder=""
              disabled
              style={{ width: '50%' }}
            />
          </Form.Group>
        </>
      ) : null}

      {smData.nomeAju2 ? (
        <>
          <Form.Text style={{ fontWeight: 'bold' }}>Dados do(s) Ajudante(es)</Form.Text>

          <Form.Group as={Col} className="mt-3">
            <Form.Label>CPF</Form.Label>

            <Form.Control
              value={smData.cpfAju2 != null ? maskCPF(smData.cpfAju2) : ' '}
              id="cpfAjudante"
              name="cpfAjudante"
              placeholder=""
              style={{ width: '50%' }}
              disabled
            />
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col} className="ml-3">
              <Form.Label>Nome</Form.Label>

              <Form.Control
                value={smData.nomeAju2}
                id="nomeAjudante"
                name="nomeAjudante"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Tipo</Form.Label>

              <Form.Control
                value={
                  smData.tipoAju2 === '1'
                    ? 'Agregado'
                    : smData.tipoAju2 === '2'
                    ? 'Autônomo'
                    : smData.tipoAju2 === '3'
                    ? 'Funcionário'
                    : ' '
                }
                id="tipoAjudante"
                name="tipoAjudante"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>
          <Form.Group as={Col} className="mt-3">
            <Form.Label>Celular(1)</Form.Label>

            <Form.Control
              value={smData.telAju2 && maskTel(smData.telAju2)}
              id="tel1Condutor"
              name="tel1Condutor"
              placeholder=""
              disabled
              style={{ width: '50%' }}
            />
          </Form.Group>
        </>
      ) : null}
      {smData?.obsPesquisa && (
        <Form.Row
          className="justify-content-md-center"
          style={{ marginTop: '20px', marginBottom: '20px' }}
        >
          <Form.Group>
            <Form.Label className="txt_alerta">{smData?.obsPesquisa}</Form.Label>
          </Form.Group>
        </Form.Row>
      )}

      {isAdmin && setStatusInicializacao && iniciarSm && iniciarSm ? (
        <>
          <Form.Text style={{ fontWeight: 'bold' }}>Situação da Viagem</Form.Text>

          <Form.Group>
            <Form.Check
              checked={statusInicializacao}
              onChange={() => {
                setStatusInicializacao(!statusInicializacao);
              }}
              id="statusInicializacao"
              name="statusInicializacao"
              defaultChecked={statusInicializacao}
              type="checkbox"
              label="Iniciar Viagem"
              style={{ marginLeft: '1.5%', marginTop: '1%' }}
            />
          </Form.Group>
        </>
      ) : null}

      {isAdmin && setMotivoFinalizacao && motivos ? (
        <Form.Group as={Col}>
          <Form.Label>Motivo de Finalização</Form.Label>
          <Form.Control
            value={motivoFinalizacazao}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setMotivoFinalizacao(parseInt(e.target.value, 10));
            }}
            as="select"
            id="motivoFinalizacao"
            name="motivoFinalizacao"
            placeholder=""
          >
            <option>Selecione uma opção</option>
            <option value="1">Viagem Concluída</option>
            <option value="2">Veículo no Destino Correto</option>
            <option value="3">Alteração de Dados por Solicitação do Cliente</option>
            <option value="4">Espelhamento Retirado</option>
            <option value="5">Sinistrado</option>
            <option value="6">Problema Mecânico/Transbordo</option>
          </Form.Control>
        </Form.Group>
      ) : null}

      {(isAdmin && setStatusInicializacao) ||
      (editValorDescricaoCarga && setSmData) ||
      (isAdmin && setMotivoFinalizacao && motivos) ? (
        <ContainerButtons>
          <SecundaryButton
            onClick={(e: any) => {
              e.preventDefault();
              window.close();
              history.goBack();
            }}
          >
            Cancelar
          </SecundaryButton>
          <PrimaryButton
            onClick={(e) => {
              if (canEdit) {
                onSubmit?.(e);
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            {buttonSubmitTitle || 'Confirmar'}
          </PrimaryButton>
        </ContainerButtons>
      ) : (
        <ContainerButtons>
          <SecundaryButton
            onClick={(e: any) => {
              e.preventDefault();
              window.close();
              history.goBack();
            }}
          >
            Voltar
          </SecundaryButton>
        </ContainerButtons>
      )}
    </Form>
  );
};

const MOTIVOS = [
  { id: 1, motivoName: 'Checklist reprovado' },
  { id: 2, motivoName: 'Dado(s) Incorreto(s)' },
  { id: 3, motivoName: 'Equipamento incompatível para viagem' },
  { id: 4, motivoName: 'Veículo não espelhado para GS' },
];

export default SolicitacaoMonitoramento;
