/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { TecnologiaRastreamentoIdNome } from '../../api/model/TecnologiaRastreamentoIdNomeType';
import { AUTOTRAC } from '../../api/model/TecnologiasAutotrac';
import axios from '../../config/axiosMaquina';
import { FaWindows } from 'react-icons/fa';

type Props = {
  canEdit?: boolean;
};

export const ComponentAutotrac = ({ canEdit }: Props) => {
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [mensagen, setMensagen] = useState('');
  const [tecnologiaAutotrac, setTecnologiaAutotrac] = useState<AUTOTRAC>({
    id: 0,
    botaoPanico: 'MENSAGEM, SIRENE E BLOQUEIO',
    desvioRota: 'MENSAGEM, SIRENE E BLOQUEIO',
    deteccaoJammer: 'MENSAGEM, SIRENE E BLOQUEIO',
    perdaSinal: 'MENSAGEM, SIRENE E BLOQUEIO',
    paradaIndevida: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoBau: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoEngate: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoCabine: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoPainelAntena: 'MENSAGEM, SIRENE E BLOQUEIO',
  });
  const [tecnologiaRastreamentoIdNome, setTecnologiaRastreamentoIdNome] =
    useState<TecnologiaRastreamentoIdNome>({
      id: 0,
      nome: '',
    });
  useEffect(() => {
    const loadDadosTecnologiaRastreamentoIdNome = async () => {
      try {
        const response = await axios.get('/listarIdNome');
        const autotrac = response.data.find(
          (filt: any) => filt.nome.toLowerCase().indexOf('autotrac'.toLowerCase()) !== -1
        );
        setTecnologiaRastreamentoIdNome({
          id: autotrac.id,
          nome: autotrac.nome,
        });
      } catch (err: any) {
        console.log(err);
      }
    };

    loadDadosTecnologiaRastreamentoIdNome();
  }, [setTecnologiaRastreamentoIdNome]);
  const sistemGerencial = async (e: any) => {
    e.preventDefault();

    const params = {
      botaoPanico: tecnologiaAutotrac.botaoPanico,
      desvioRota: tecnologiaAutotrac.desvioRota,
      deteccaoJammer: tecnologiaAutotrac.deteccaoJammer,
      id: tecnologiaAutotrac.id,
      mensagem: mensagen,
      paradaIndevida: tecnologiaAutotrac.paradaIndevida,
      perdaSinal: tecnologiaAutotrac.perdaSinal,
      tecnologia: [tecnologiaRastreamentoIdNome.id],
      violacaoBau: tecnologiaAutotrac.violacaoBau,
      violacaoCabine: tecnologiaAutotrac.violacaoCabine,
      violacaoEngate: tecnologiaAutotrac.violacaoEngate,
      violacaoPainelAntena: tecnologiaAutotrac.violacaoPainelAntena,
    };

    try {
      await axios.post('/cadastrar', params);
      setIsDialogCadOK(true);
      // alert('Enviado Com Sucesso');
      setMensagen('');
      setTecnologiaAutotrac({
        ...tecnologiaAutotrac,
        botaoPanico: 'MENSAGEM, SIRENE E BLOQUEIO',
        desvioRota: 'MENSAGEM, SIRENE E BLOQUEIO',
        deteccaoJammer: 'MENSAGEM, SIRENE E BLOQUEIO',
        perdaSinal: 'MENSAGEM, SIRENE E BLOQUEIO',
        paradaIndevida: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoBau: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoEngate: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoCabine: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoPainelAntena: 'MENSAGEM, SIRENE E BLOQUEIO',
      });
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar',
        type: 'error',
      });
      // alert('Error ao Enviar');
    }
  };

  const recebe = [
    'SELECIONE',
    'APENAS MENSAGEM',
    'MENSAGEM E SIRENE',
    'MENSAGEM, SIRENE E BLOQUEIO',
  ];
  return (
    <>
      <Dialog
        header="Parâmetro cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          window.location.reload();
        }}
      />

      <Form onSubmit={(e: any) => sistemGerencial(e)}>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Botão de Pânico
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.botaoPanico}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  botaoPanico: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Desvio de Rota
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.desvioRota}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  desvioRota: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Detecção de Jammer
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.deteccaoJammer}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  deteccaoJammer: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Perda de Sinal
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.perdaSinal}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  perdaSinal: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Parada Indevida
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.paradaIndevida}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  paradaIndevida: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Violação de Baú
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.violacaoBau}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  violacaoBau: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Violação de Engate
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.violacaoEngate}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  violacaoEngate: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Violação de Cabine
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.violacaoCabine}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  violacaoCabine: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Violação de Painel/Antena
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaAutotrac.violacaoPainelAntena}
              onChange={(event: any) => {
                setTecnologiaAutotrac({
                  ...tecnologiaAutotrac,
                  violacaoPainelAntena: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Mensagem
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="textarea"
              style={{ resize: 'none', height: '5rem' }}
              rows={3}
              value={mensagen}
              onChange={(e: any) => {
                setMensagen(e.target.value);
              }}
              id="emailAvisoServicoInoperante"
              name="emailAvisoServicoInoperante"
            />
          </Col>
        </Form.Group>
        <Form.Row className="container-buttons justify-content-end">
          <Form.Group as={Col} md={2}>
            {canEdit ? (
              <Button className="btn-enviar" type="submit">
                Enviar
              </Button>
            ) : (
              <Button
                className="btn-enviar"
                type="button"
                onClick={() => history.push('/acessonegado')}
              >
                Enviar
              </Button>
            )}
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
};
