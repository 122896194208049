import React, { useEffect, useState, memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactExport from 'react-data-export';
import { Button } from 'primereact/button';
import { orderBy } from 'lodash';
import axios from '../../config/axiosMaquina';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { filterItems, pesquisar, removerSpecials } from '../../util/format';

type NaoConformidadeType = {
  id: number;
  causaRaiz: string;
  naturezaEvento: string;
  processoMacro: string;
  infracaoDescricao?: string;
  penalidadeDescricao?: string;
  ativo?: boolean;
};

type Props = {
  canInsert?: boolean;
};

const NaoConformidadeList: React.FC<Props> = ({ canInsert }) => {
  const history = useHistory();
  const { page }: any = useParams();

  const [data, setData] = useState<NaoConformidadeType[]>([]);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<NaoConformidadeType[]>([]);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [countData, setCountData] = useState(0);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = searchValue
    ? dataSearch?.slice(indexOfFirstPost, indexOfLastPost)
    : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;
  const titles = ['+id', 'Natureza do evento', 'Causa raiz', 'Processo macro'];

  const paginate = (pageNumber: number) => {
    history.push(`/listar/nao-conformidades/${pageNumber}`);
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Não conformidades',
          widthPx: 1200,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      columns: [],
      data: [],
    },
    {
      columns: [
        { title: 'Natureza do evento', width: { wch: 30 } },
        { title: 'Causa raiz', width: { wch: 25 } },
        { title: 'Processo macro', width: { wch: 40 } },
        { title: 'Descrição da infração', width: { wch: 40 } },
        { title: 'Descrição da penalidade', width: { wch: 40 } },
        { title: 'Status', width: { wch: 15 } },
      ],
      data: data?.map((each) => [
        { value: each.naturezaEvento ? each.naturezaEvento : '' },
        { value: each.causaRaiz ? each.causaRaiz : '' },
        { value: each.processoMacro ? each.processoMacro : '' },
        { value: each.infracaoDescricao ? each.infracaoDescricao : '' },
        { value: each.penalidadeDescricao ? each.penalidadeDescricao : '' },
        { value: each.ativo ? 'ATIVO' : 'INATIVO' },
      ]),
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (!countData) {
        const response = await axios.get('/nao-conformidade/gerenciador/count');
        setCountData(response.data);
      }
      if (!data.length) {
        const response = await axios.get(`/nao-conformidade/gerenciador`);
        const array = orderBy(
          response.data,
          [(nc) => removerSpecials(nc.naturezaEvento).toLowerCase()],
          ['asc']
        )
          .filter((fil: any) => fil.ativo === true)
          .map((each: NaoConformidadeType) => {
            return [each.id, each.naturezaEvento, each.causaRaiz, each.processoMacro];
          });
        console.log(array);
        setDataTabela(array);
        setData(response.data.filter((fil: any) => fil.ativo === true));
        console.log(response.data);
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [data, countData]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (id: any) => {
    history.push({
      pathname: `/editar-nao-conformidade/${id}`,
    });
  };

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar/nao-conformidades/1');
      setDataSearch(pesquisar(dataTabela, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Não conformidades"
      caminho="Cadastro > Não conformidades"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchValue}
      search={search}
      linkToAdd="/cadastrar-nao-conformidade"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchValue ? filteredData.length : data.length}
      paginate={paginate}
      currentPage={page}
      excelGeneration={multiDataSet}
      filename="Nao conformidade"
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela data={currentData} titles={titles} onClickEditar={handleEdit} />
      )}
    </Tela>
  );
};

export default memo(NaoConformidadeList);
