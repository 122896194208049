import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { getCookieSessionData } from '../../../services/cookieService';
import axios from '../../../config/axiosMaquina';
import { UsuariosType, SeguradorasType } from '../../../api/model/UsuariosType';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import Search from '../../../components/Search/Search';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import { DefaultImage } from '../../../components/DefaultImageFilter';

type Props = {
  canInsert: boolean;
  canEdit: boolean;
};

const ListagemUsuariosSeguradoras = ({ canInsert, canEdit }: Props) => {
  const [seguradora, setSeguradora] = useState<SeguradorasType>();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [listaSeguradora, setListaSeguradora] = useState<SeguradorasType[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const { page }: any = useParams();
  const { seguradoraId, role } = getCookieSessionData().usuarioVO;
  const [data, setData] = useState<UsuariosType[]>([]);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [dataSearch, setDataSearch] = useState<UsuariosType[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/listar-usuarios-seguradoras/${pageNumber}`);
  };

  useEffect(() => {
    const fetchDataPlaca = async () => {
      try {
        const response = await axios.get(`/seguradora`);
        setListaSeguradora(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchDataPlaca();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (role === 'admin') {
        if (!seguradora) return;
        setLoadingSearch(true);
        try {
          const response = await axios.get(`/usuario/seguradora`, {
            params: {
              seguradora,
            },
          });
          setData(response.data);
          setLoadingSearch(false);
        } catch (err: any) {
          console.log(err);
          setLoadingSearch(false);
        }
      } else {
        setLoadingSearch(true);
        try {
          const response = await axios.get(`/usuario/seguradora`, {
            params: {
              seguradora: seguradoraId,
            },
          });
          setLoadingSearch(false);
          setData(response.data);
        } catch (err: any) {
          console.log(err);
          setLoadingSearch(false);
        }
      }
    };
    fetchData();
  }, [seguradora]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      const s = searchValue.toLowerCase();
      const filteredData = data.filter(
        (each: UsuariosType) =>
          each.login.toLowerCase().includes(s) ||
          each.nome.toLowerCase().includes(s) ||
          each.seguradoraId.toString().includes(s)
      );
      console.log(filteredData);
      setDataSearch(filteredData);
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Usuários"
      caminho="Usuários > Usuários Seguradora"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      {role === 'admin' && (
        <div className="header-associar-container">
          <div className="header-associar-field">
            <span>Seguradora</span>
            <Dropdown
              value={seguradora}
              options={listaSeguradora}
              onChange={(e) => {
                setSeguradora(e.target.value);
              }}
              optionLabel="segTxNome"
              optionValue="segCdId"
              filter
              filterBy="segTxNome"
              placeholder="Selecione uma opção"
              className="p-dropdown"
              showClear
              style={{ width: '120%' }}
            />
          </div>
        </div>
      )}
      <Search
        setLoading={setLoadingSearch}
        setSearch={setSearchTerm}
        setSearchValue={setSearchValue}
        search={searchTerm}
        linkToAdd={`/cadastro-usuario-seguradoras/${role === 'admin' ? seguradora : seguradoraId}`}
        createDisabled={!canInsert}
        buttonDisabled={!seguradora && role === 'admin'}
        disabled={!seguradora && role === 'admin'}
      />
      {loadingSearch ? (
        <Spinner />
      ) : (seguradora || role === 'seguradora') && currentData.length ? (
        <div className="table-responsive">
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Nome Usuário</th>
                <th>Código da Seguradora</th>
                <th>Login</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {currentData &&
                currentData.map((each) => (
                  <tr key={each.seguradoraId} className="dispositivos-table">
                    <td>{each.nome}</td>
                    <td>{each.seguradoraId}</td>
                    <td>{each.login}</td>
                    <td>
                      <ButtonTooltip
                        type="Editar"
                        onClick={() => {
                          if (canEdit) {
                            history.push(
                              `/atualizacao-usuario-seguradoras/${each.usuCdId}/${each.seguradoraId}/${each.login}`
                            );
                          } else {
                            history.push('/acessonegado');
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <DefaultImage />
      )}
    </Tela>
  );
};

export default ListagemUsuariosSeguradoras;
