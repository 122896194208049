/* eslint-disable */
import React from 'react';
import MenuLateral from '../../components/MenuLateral/MenuLateral';

const Forgotten: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <h1>Tela para criação do 'Esqueci a Senha'</h1>
    </div>
  );
};

export default Forgotten;
