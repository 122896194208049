import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { moneyToNumber } from '../../util/Validacoes/Validacoes';

type Props = {
  canEdit?: boolean;
};

export const ContainerOperacional: FC<Props> = ({ canEdit }) => {
  const history = useHistory();
  const [smAoOperadorEmDias, setSmAoOperadorEmDias] = useState(0);
  const [antecedenciaInclusaoSmEmMinutos, setAntecedenciaInclusaoSmEmMinutos] = useState(0);
  const [pisoCargasEspeciais, setPisoCargasEspeciais] = useState(0);
  const [valorMinimoCargas, setValorMinimoCargas] = useState(0);
  const { addToast } = useToast();

  const pegarSistemOperacional = useCallback(async () => {
    const response = await axios.get('/parametro-operacional');
    const gs = response.data;
    setSmAoOperadorEmDias(gs.smAoOperadorEmDias);
    setAntecedenciaInclusaoSmEmMinutos(gs.antecedenciaInclusaoSmEmMinutos);
    setPisoCargasEspeciais(gs.pisoCargasEspeciais);
    setValorMinimoCargas(gs.valorMinimoCargas);
  }, []);

  useEffect(() => {
    pegarSistemOperacional();
  }, [pegarSistemOperacional]);

  const atualizarParametros = async () => {
    const data = {
      antecedenciaInclusaoSmEmMinutos,
      pisoCargasEspeciais,
      smAoOperadorEmDias,
      valorMinimoCargas,
    };

    try {
      await axios.post(`/parametro-operacional/cadastrar`, data);
      pegarSistemOperacional();
      addToast({
        title: 'Sucesso!',
        description: 'Os parametros foram atualizados com sucesso!',
        type: 'success',
      });
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Houve algum problema ao atualizar os parametros.',
        type: 'error',
      });
    }
  };

  return (
    <>
      <Form>
        <Form.Group as={Row}>
          <Form.Label>Disponibilizar S.M. ao operador (em dias): </Form.Label>
          <Form.Control
            value={smAoOperadorEmDias}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSmAoOperadorEmDias(Number(e.target.value))
            }
            id="smAoOperadorEmDias"
            name="smAoOperadorEmDias"
          />
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>Antecedência para inclusão SM (em minutos): </Form.Label>
          <Form.Control
            value={antecedenciaInclusaoSmEmMinutos}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAntecedenciaInclusaoSmEmMinutos(Number(e.target.value))
            }
            id="antecedenciaInclusaoSmEmMinutos"
            name="antecedenciaInclusaoSmEmMinutos"
          />
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>Piso para cargas especiais: </Form.Label>
          <Form.Control
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPisoCargasEspeciais(e.target.value ? moneyToNumber(e.target.value.toString()) : 0);
            }}
            value={
              !pisoCargasEspeciais
                ? ''
                : Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                    pisoCargasEspeciais
                  )
            }
            id="pisoCargasEspeciais"
            name="pisoCargasEspeciais"
            maxLength={19}
          />
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>Valor mínimo para Cargas:</Form.Label>
          <Form.Control
            value={
              !valorMinimoCargas
                ? ''
                : Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                    valorMinimoCargas
                  )
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setValorMinimoCargas(e.target.value ? moneyToNumber(e.target.value.toString()) : 0);
            }}
            id="valorMinimoCargas"
            name="valorMinimoCargas"
            maxLength={19}
          />
        </Form.Group>
      </Form>

      <Form.Row className="container-buttons justify-content-end">
        <Form.Group as={Col} md={2}>
          {canEdit ? (
            <Button onClick={atualizarParametros} type="submit" className="btn-enviar">
              Atualizar
            </Button>
          ) : (
            <Button
              onClick={() => history.push('/acessonegado')}
              type="button"
              className="btn-enviar"
            >
              Atualizar
            </Button>
          )}
        </Form.Group>
      </Form.Row>
    </>
  );
};
