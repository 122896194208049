import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/Toast';
import api from '../../../config/axiosMaquina';
import { Tela } from '../../../components/Tela';
import { PrimaryButton } from '../../../components/ButtonsForm/styles';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import { cpfMask } from '../../../util/Validacoes/Validacoes';

export const MacrosJornadaEditar = () => {
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const { page }: any = useParams();

  return (
    <Tela
      nome="Controle de Jornada"
      caminho="Controle Logístico > Controle de Jornada > Editar"
      loading={loading}
      setLoading={setLoading}
    >
      aaaa
    </Tela>
  );
};
