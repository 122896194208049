import { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import moment from 'moment';
import axios from '../../../config/axiosMaquina';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import { Tabela } from '../../../components/Tabela';
import { dateNow, pesquisar } from '../../../util/format';
import { maskTelefone } from '../../../util/masks';
import { ExcelGeneration } from '../../../Types/ExcelType';
import { getCookieSessionData } from '../../../services/cookieService';
import Search from '../../../components/Search/Search';
import { DefaultImage } from '../../../components/DefaultImageFilter';
import { obterData, obterDataAtual, obterDataHora, obterHora, toZoned } from '../../../util/date';
import { useToast } from '../../../hooks/Toast';

type SmsEnviadosType = {
  dataHoraEnvio: string;
  dataEnvio: string;
  horaEnvio: string;
  idCliente: number;
  idSm: number;
  idSms: number;
  mensagem: string;
  nomeCliente: string;
  numeroCelular: string;
};

const handleLast7Days = () => {
  const startDate = moment().subtract(7, 'days');
  const endDate = moment();
  return {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  };
};

export const SmsEnviados = () => {
  const history = useHistory();
  const { page }: any = useParams();
  const { addToast } = useToast();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { startDate, endDate } = handleLast7Days();
  const [dataInicio, setDataInicio] = useState(startDate);
  const [dataFim, setDataFim] = useState(endDate);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = ['+id', 'SM n°', 'Transportador', 'Celular', 'Mensagem', 'Data/Hora'];
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();
  const { cnpj } = getCookieSessionData().usuarioVO;
  const myRef = useRef<any>(null);
  const paginate = (pageNumber: number) => {
    history.push(`/listar/sms-enviados/${pageNumber}`);
  };

  const scrollTo = () => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const onSubmit = async () => {
    if (!dataInicio || !dataFim) {
      addToast({
        title: 'Erro',
        description: 'Preencha os campos de data corretamente',
        type: 'error',
      });
      return;
    }

    try {
      setLoadingSearch(true);

      const response = await axios.get('/log-sms/lista', {
        params: {
          cnpj,
          dataInicio: toZoned(dataInicio),
          dataFim: toZoned(dataFim, false),
        },
      });

      setData(response.data);

      if (response.data) {
        const array = response.data.map((each: SmsEnviadosType) => {
          return [
            each.idSms,
            each.idSm,
            each.nomeCliente,
            maskTelefone(each.numeroCelular),
            each.mensagem,
            obterDataHora(each.dataHoraEnvio),
          ];
        });
        setDataTabela(array);
        scrollTo();
      }
    } catch (err: any) {
      addToast({
        title: 'Erro ao fazer requisição',
        description: err.message,
        type: 'error',
      });
    } finally {
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar/sms-enviados/1');
      setDataSearch(pesquisar(dataTabela, searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    if (!data) return;

    setExcelGeneration([
      {
        xSteps: 0,
        columns: [
          {
            title: 'SMS Enviados',
            widthPx: 1060,
            style: { font: { bold: true } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        columns: [],
        data: [],
      },
      {
        xSteps: 0,
        columns: [
          {
            title: `Período ${obterData(dataInicio)} - ${obterData(dataFim)}`,
            widthPx: 1060,
            style: { font: { bold: true } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        columns: [
          { title: 'Data' },
          { title: 'Hora' },
          { title: 'Celular' },
          { title: 'Mensagem' },
          { title: 'SM n°' },
          { title: 'Transportador' },
        ],
        data: data.map((each: SmsEnviadosType) => [
          { value: obterData(each.dataHoraEnvio) },
          { value: obterHora(each.dataHoraEnvio) },
          { value: maskTelefone(each.numeroCelular) },
          { value: each.mensagem },
          { value: each.idSm },
          { value: each.nomeCliente },
        ]),
      },
    ]);
  }, [data]);

  return (
    <Tela
      nome="SMS Enviados"
      caminho="Operacional > Consultas e Relatórios > SMS Enviados"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate}
      currentPage={page}
      excelGeneration={excelGeneration}
      filename="SMS Enviados"
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Form.Row>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Data Início</Form.Label>
              <Form.Control
                onChange={(event: any) => setDataInicio(event.target.value)}
                value={dataInicio}
                type="date"
                max={obterDataAtual()}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label className="requiredField">Data Término</Form.Label>
              <Form.Control
                onChange={(event: any) => setDataFim(event.target.value)}
                value={dataFim}
                type="date"
                disabled={!dataInicio}
                min={dataInicio}
                max={dateNow()}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Button
                type="submit"
                // disabled={!choosenStartDate || !choosenEndDate}
              >
                Filtrar
              </Button>
            </Form.Group>
          </div>
        </Form.Row>
        <Search
          setLoading={setLoadingSearch}
          setSearch={setSearchTerm}
          setSearchValue={setSearchValue}
          search={searchTerm}
          disabled={!currentData}
        />
      </Form>
      <div ref={myRef}>
        {loadingSearch ? (
          <Spinner />
        ) : currentData.length ? (
          <Tabela data={currentData} titles={titles} />
        ) : (
          <DefaultImage />
        )}
      </div>
    </Tela>
  );
};
