/* eslint-disable */
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import LoginParceiros from './pages/LoginParceiros';
import Register from './pages/Register';
import history from './services/history';
import Forgotten from './pages/Forgotten';
import RoutesSideBar from './RoutesPrivate';
import Sidebar from './components/Sidebar/Sidebar';

const Routes: React.FC = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" exact>
        <Login />
      </Route>

      <Route path="/parceiros" exact>
        <LoginParceiros />
      </Route>

      <Route path="/register" exact>
        <Register />
      </Route>

      <Route path="/forgotten" exact>
        <Forgotten />
      </Route>

      <Route>
        <RoutesSideBar />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
