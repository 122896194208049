/* eslint-disable */
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { Col, Form, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../config/axiosMaquina';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
//@ts-ignore
import ReactExport from 'react-data-export';
import { Dropdown } from 'primereact/dropdown';

import './styles.css';
import GeneratePDF from './generatePDF';
import { DataMaxima, FormataStringData2 } from '../../util/Validacoes/Validacoes';
import orderBy from 'lodash/orderBy';
import { DefaultImage } from '../../components/DefaultImageFilter';
import { Tela } from '../../components/Tela';
import { obterData, obterDataAtual, obterHora, toZoned } from '../../util/date';

type DataType = {
  bau: string;
  coordenada: string;
  data: string;
  hora: string;
  idCliente: number;
  idSm: number;
  ignicao: string;
  odometro: number;
  placaVeiculo: string;
  referencia: string;
  temperatura: number;
  velocidade: number;
};

type VeiculoType = {
  id: number;
  placa: string;
};

const renderCoordenada = (data: string) => {
  let lat = Number(data.split('  ')[0]).toFixed(4);
  let lng = Number(data.split('  ')[1]).toFixed(4);
  let string = `${lat};  ${lng}`;
  return string.replaceAll('.', ',');
};

const HistoricoPosicoes = () => {
  const history = useHistory();
  const { clienteId, role } = getCookieSessionData().usuarioVO;
  const [veiculo, setVeiculo] = useState<VeiculoType>();
  const [veiculosArr, setVeiculosArr] = useState<VeiculoType[] | undefined>();
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<DataType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [data, setData] = useState<DataType[] | undefined>();
  const [dataInicio, setDataInicio] = useState<string | undefined>();
  const [dataFim, setDataFim] = useState<string | undefined>();

  const periodo =
    dataInicio && dataFim
      ? `Período: ${FormataStringData2(dataInicio?.split('T')[0])} à ${FormataStringData2(
          dataFim?.split('T')[0]
        )}`
      : 'Período: ';

  const borders = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Histórico de Posições',
          widthPx: 800,
          style: {
            font: { sz: '24', bold: true },
            alignment: { horizontal: 'center' },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title: `Veículo: ${veiculo?.placa}`,
          widthPx: 800,
          style: {
            font: { sz: '16', bold: true },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title: periodo,
          widthPx: 800,
          style: {
            font: { sz: '16', bold: true },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title: 'Referência',
          width: { wch: 40 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              vertical: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Coordenada',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Data',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Hora',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Ignição',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Baú',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Velocidade',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Temperatura',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
      ],
      data: data?.map((each) => [
        { value: each.referencia },
        {
          value: renderCoordenada(each.coordenada),
        },
        {
          value: each.data,
        },
        {
          value: each.hora,
        },
        {
          value: each.ignicao,
        },
        {
          value: each.bau,
        },
        {
          value: `${each.velocidade} km/h`,
        },
        {
          value: each.temperatura ? `${each.temperatura} ºC` : '',
        },
      ]),
    },
  ];

  if (role === 'admin') {
    //@ts-ignore
    multiDataSet[3].columns.push({
      title: 'Odômetro',
      width: { wch: 25 },
      style: {
        font: { sz: '14', bold: true },
        fill: { fgColor: { rgb: 'FFD3D3D3' } },
        alignment: {
          horizontal: 'bottom',
          wrapText: true,
        },
        border: borders,
      },
    });
    //@ts-ignore
    multiDataSet[3]?.data?.push((prev) =>
      prev.map((each: any) => [
        ...each,
        {
          value: each.odometro,
        },
      ])
    );
  }

  const paginate = (pageNumber: number) => {
    history.push(`/rastreamento/historico-posicoes/${pageNumber}`);
  };

  function separar(base: any, max: any) {
    const pages = Math.ceil(base.length / max);
    let arr: any[] = [];
    for (let i = 0; i < pages; i++) {
      const start = i * max;
      const end = start + max;
      const _data = base.slice(start, end);
      arr = [...arr, _data];
    }
    return arr;
  }

  {
    isAllowedByRole(['admin']) &&
      useEffect(() => {
        const fetchVeiculos = async () => {
          const response = await api.get('/veiculo/listarTodasPlacas').then((response: any) => {
            setVeiculosArr(response.data);
            console.log('console log veiculos', response.data);
          });
        };
        fetchVeiculos();
      }, []);
  }

  {
    isAllowedByRole(['user']) &&
      useEffect(() => {
        const fetchVeiculos = async () => {
          const response = await api.get(`/veiculo/listarVeiculosPorCliente?cliente=${clienteId}`);
          setVeiculosArr(response.data);
        };
        fetchVeiculos();
      }, []);
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    history.push(`/rastreamento/historico-posicoes/1`);
    try {
      if (role === 'admin') {
        const response = await api.get(`/tecnologia-rastreamento/relatorio-historico-posicao`, {
          params: {
            dataInicio: toZoned(dataInicio),
            dataFim: toZoned(dataFim, false),
            placa: veiculo?.placa,
          },
        });
        setData(response.data);
      } else {
        const response = await api.get(
          `/tecnologia-rastreamento/relatorio-historico-posicao-cliente`,
          {
            params: {
              dataInicio: toZoned(dataInicio),
              dataFim: toZoned(dataFim, false),
              placa: veiculo?.placa,
            },
          }
        );
        setData(response.data);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tela
      nome="Histórico de Posições"
      caminho={
        isAllowedByRole(['admin'])
          ? 'Consultas/Relatórios > Rastreamento > Histórico de Posições'
          : 'Operacional > Consultas e relatórios > Rastreamento > Histórico de Posições'
      }
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm ? searchResults?.length : data?.length || 0}
      paginate={paginate}
      currentPage={page}
      excelGeneration={multiDataSet}
      filename="relatorio_historico_posicoes"
      pdf
      onClickPdf={() => GeneratePDF(separar(data, 15), veiculo?.placa, periodo, role)}
      disabledExport={!data}
    >
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <div className="painel">
          <div className="header-associar-container">
            <div className="header-associar-field">
              <Form onSubmit={(e: any) => onSubmit(e)}>
                <div className="header-associar-field">
                  <Form.Label className="requiredField">Veículo</Form.Label>
                  <Dropdown
                    value={veiculo}
                    options={orderBy(veiculosArr, 'placa')}
                    onChange={(e) => setVeiculo(e.value)}
                    optionLabel="placa"
                    filter
                    filterBy="placa"
                    placeholder="Placa"
                    className="p-dropdown"
                    style={{ marginBottom: '15px' }}
                  />
                </div>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="requiredField">Data Início</Form.Label>
                    <Form.Control
                      onChange={(event: any) => setDataInicio(event.target.value)}
                      value={dataInicio}
                      type="date"
                      max={obterDataAtual()}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className="requiredField">Data Término</Form.Label>
                    <Form.Control
                      onChange={(event: any) => setDataFim(event.target.value)}
                      type="date"
                      value={dataFim}
                      min={dataInicio}
                      max={DataMaxima(dataInicio, 30)}
                      disabled={!dataInicio}
                    />
                  </Form.Group>
                  <div
                    className="header-associar-field button-field"
                    style={{ paddingTop: '6.4%' }}
                  >
                    <Form.Group as={Col} md={2}>
                      <Button className="btn-enviar" style={{ height: '87.5%' }} type="submit">
                        Filtrar
                      </Button>
                    </Form.Group>
                  </div>
                </Form.Row>
              </Form>
            </div>
          </div>
          <div className="table-responsive" style={{ flex: 1 }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Referência</th>
                  <th style={{ textAlign: 'center' }}>Coordenada</th>
                  <th style={{ textAlign: 'center' }}>Data</th>
                  <th style={{ textAlign: 'center' }}>Hora</th>
                  <th style={{ textAlign: 'center' }}>Ignição</th>
                  <th style={{ textAlign: 'center' }}>Baú</th>
                  <th style={{ textAlign: 'center' }}>Velocidade</th>
                  <th style={{ textAlign: 'center' }}>Temperatura</th>
                  <th style={{ textAlign: 'center' }}>IdCliente</th>
                  {isAllowedByRole(['admin']) && <th>Odômetro</th>}
                </tr>
              </thead>
              {!dataInicio || !dataFim ? (
                <tr>
                  <th colSpan={12}>
                    <DefaultImage />
                  </th>
                </tr>
              ) : (
                <tbody>
                  {data &&
                    data
                      .slice(
                        parseInt(itensPerPage, 10) * page - parseInt(itensPerPage, 10),
                        parseInt(itensPerPage, 10) * page
                      )
                      .map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data.referencia}</td>
                            <td align="center">{renderCoordenada(data.coordenada)}</td>
                            <td align="center">{obterData(data.data)}</td>
                            <td align="center">{obterHora(data.data)}</td>
                            <td align="center">{data.ignicao}</td>
                            <td align="center">{data.bau}</td>
                            <td align="center">{data.velocidade} km/h</td>
                            <td align="center">
                              {data.temperatura ? `${data.temperatura} ºC` : null}
                            </td>
                            <td align="center">{data.idCliente}</td>
                            {isAllowedByRole(['admin']) && <td>{data.odometro}</td>}
                          </tr>
                        );
                      })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
      {/* <div>
            <div id="gerar-xls">
              <div style={{ marginLeft: '23px' }}>
                <div id="gerar-xls">
                  {role === 'user' && (
                    <ExcelFile
                      element={
                        <Button disabled={!data?.length || loading}>Exportar para XLS</Button>
                      }
                      filename="relatorio_historico_posicoes"
                    >
                      <ExcelSheet dataSet={multiDataSet} name="Relatório Histórico de Posições" />
                    </ExcelFile>
                  )}
                  <Button
                    style={{ marginLeft: '10px' }}
                    disabled={!data?.length}
                    onClick={() => GeneratePDF(separar(data, 15), veiculo?.placa, periodo, role)}
                  >
                    Exportar para PDF
                  </Button>
                </div>
              </div> */}
    </Tela>
  );
};

export default HistoricoPosicoes;
