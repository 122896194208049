/* eslint-disable */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  ResumoCnhType,
  ResumoCondutorType,
  ResumoVeiculoType,
} from '../../Types/ResumoCondutorType';
import './styles.css';
import api from '../../config/axiosMaquina';
import { orderBy, uniqBy } from 'lodash';

export function ResumoProcessual(props: any) {
  const [processos, setProcessos] = useState<ResumoCondutorType[]>();
  const [dadosCNH, setDadosCNH] = useState<ResumoCnhType>();
  const [dadosVeiculo, setDadosVeiculo] = useState<ResumoVeiculoType>();
  const [dadosRoubosFurtos, setDadosRoubosFurtos] = useState<ResumoVeiculoType[]>();

  useEffect(() => {
    const handleVeiculo = async () => {
      if (props.numLiberacao && props.tipo === 'PLACA') {
        try {
          const response = await api.get(
            `/pesquisa/consulta-veiculo?idPesquisa=${props.numLiberacao}`
          );
          setDadosVeiculo(response.data[0]);
          setDadosRoubosFurtos(response.data);
          console.log(response.data);
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    handleVeiculo();
  }, [props.numLiberacao, props.tipo]);

  useEffect(() => {
    const handleCnh = async () => {
      if (props.numLiberacao && props.tipo === 'CNH') {
        try {
          const response = await api.get(`/pesquisa/cnh?idPesquisa=${props.numLiberacao}`);
          setDadosCNH(response.data[0]);
          console.log(response.data);
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    handleCnh();
  }, [props.numLiberacao, props.tipo]);

  useEffect(() => {
    const handleCpf = async () => {
      if (props.numLiberacao && props.tipo === 'CPF') {
        try {
          const response = await api.get(`/pesquisa/processo?idPesquisa=${props.numLiberacao}`);
          if (response.data.length > 0) {
            setProcessos(response.data);
          }
          console.log(response.data);
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    handleCpf();
  }, [props.numLiberacao, props.tipo]);

  const handleValidacaoNumeroSeguranca = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 1: {
        return 'Confirmado';
      }
      case 2: {
        return 'Existe uma CNH emitida mais recente do que a CNH que está sendo validada';
      }
      case 3: {
        return 'CNH com data de validade vencida ';
      }
      case 5: {
        return 'CNH válida para identificação do condutor, porém vencida para condução de veículos';
      }
      case 6: {
        return 'CNH válida para identificação do condutor e condução de veículos conforme termos da deliberação COTRAN Nº 185 DE 19/03/2020.';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoUFCNH = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Pontuação não disponível';
      }
      case 0: {
        return 'Inválido';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoCPF = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'CNH com dados';
      }
      case 1: {
        return 'Confirmado';
      }
      case 3: {
        return 'CPF não pertence a CNH informada';
      }
      case 4: {
        return 'CNH/Condutor não encontrado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoProprietario = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 1: {
        return 'Confirmado';
      }
      case 0: {
        return 'Divergente do informado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoRenavam = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'Divergente do informado';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoUFVeiculo = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'Divergente do informado';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoChassiVeiculo = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'Divergente do informado';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoAnttVeiculo = (n: number) => {
    switch (n) {
      case 20: {
        return 'Resultado encontrado';
      }
      case 19: {
        return 'Resultado não encontrado';
      }
      case 13: {
        return 'Fonte indisponível';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoRoubo = (n: any) => {
    switch (n) {
      case '1': {
        return 'DECLARACAO';
      }
      case '2': {
        return 'RECUPERACAO';
      }
      case '3': {
        return 'DEVOLUCAO';
      }
      default: {
        return n;
      }
    }
  };

  return (
    <div className="resumo">
      {processos ? (
        uniqBy(processos, 'numeroProcesso').map((each: ResumoCondutorType) => {
          return (
            <>
              <br />
              <p>Dados Processo</p>
              <br />
              <div id="row">
                <p>Processo nº: {each.numeroProcesso}</p>
                <p>UF: {each.uf}</p>
              </div>
              <div id="row">
                <p>Classificação: {each.areaProcesso}</p>
                <p>Situação: {each.situacaoProcesso}</p>
              </div>
              <div id="row">
                <p>Comarca: {each.comarca}</p>
                <p>Vara:</p>
              </div>
              <div id="row">
                <p>Classificação da Parte: {each.tipoParte}</p>
                <p>Situação da Parte: {each.situacao}</p>
              </div>
              {each.dataProcesso && (
                <p>
                  Data do Processo: {each.dataProcesso ? each.dataProcesso?.split('-')[2] : ''} /
                  {each.dataProcesso ? each.dataProcesso?.split('-')[1] : ''}/
                  {each.dataProcesso ? each.dataProcesso?.split('-')[0] : ''}
                </p>
              )}
              <p>
                Classe: {each.classeProcessual} {each.areaProcesso} -{' '}
                {each.assuntosProcesso ? each.assuntosProcesso.split(';')[0] : ''}
              </p>
              <p>
                Artigos: {each.codigoArtigo} - {each.descricao} ({each.descricaoGrupo})
              </p>
            </>
          );
        })
      ) : dadosCNH ? (
        <div className="resumoCard">
          <br />
          <p>Dados CNH</p>
          <br />
          <p>
            CPF n°: {props.cpf} ({handleValidacaoCPF(dadosCNH.validacaoCondutor)})
          </p>
          <div id="row">
            <p>Nome: {dadosCNH.nome}</p>
            <p>Nascimento: {dadosCNH.dataNascimento}</p>
          </div>

          <div id="row">
            <p>CNH Registro n°: {dadosCNH.numeroRegistro}</p>
            <p>
              UF: {dadosCNH.ufCnh} ({handleValidacaoUFCNH(dadosCNH.validacaoUfCnh)})
            </p>
          </div>

          <div id="row">
            <p>
              CNH Segurança n°: {dadosCNH.numeroSeguranca} (
              {handleValidacaoNumeroSeguranca(dadosCNH.validadaoNumeroSeguranca)})
            </p>
          </div>
          <div id="row">
            <p>Categoria: {dadosCNH.categoriaAtual}</p>
            <p>Validade: {dadosCNH.dataValidade}</p>
          </div>
          <div id="row">
            <p>Impedimento: {dadosCNH.impedimento}</p>
            <p>Portaria: {dadosCNH.portaria}</p>
          </div>
          {/* (Existe uma CNH emitida mais recente do que a que está sendo validada) */}
          {dadosCNH.mensagem}
        </div>
      ) : dadosVeiculo ? (
        <>
          <br />
          <p>Dados Veículo</p>
          <br />
          <p>
            Proprietário: {dadosVeiculo.documento} {dadosVeiculo.nomeProprietario} (
            {handleValidacaoProprietario(dadosVeiculo.validacaoProprietario)})
          </p>
          <p>
            Renavam nº: {dadosVeiculo.renavam} (
            {handleValidacaoRenavam(dadosVeiculo.validacaoRenavam)})
          </p>
          <p>
            UF: {dadosVeiculo.uf} ({handleValidacaoUFVeiculo(dadosVeiculo.validacaoUf)})
          </p>
          <p>
            Chassi nº: {dadosVeiculo.chassi} (
            {handleValidacaoChassiVeiculo(dadosVeiculo.validacaoChassi)})
          </p>
          <p>Marca/Modelo: {dadosVeiculo.marca}</p>
          <br />
          <p></p>
          {dadosVeiculo.anttStatus ? (
            <>
              <p>Status ANTT: {handleValidacaoAnttVeiculo(dadosVeiculo.anttStatus)}</p>
              {/* Ocorrência(as) {} */}
            </>
          ) : null}
          {dadosVeiculo.anttCadastro && (
            <>
              <p>Dados ANTT</p>
              <br />
              <p>Situação Veículo: {dadosVeiculo.situacaoVeiculo || ''}</p>
              <p>RNTRC nº: {dadosVeiculo.anttRntrc}</p>
              <p>
                Transportador: {dadosVeiculo.anttDocumento || ''}{' '}
                {dadosVeiculo.anttTransportador || ''}
              </p>
              <p>
                Situação: {dadosVeiculo.anttSituacao || ''} Cidade: {dadosVeiculo.anttCidade || ''}
              </p>
              <div id="row">
                <p>Data Cadastro: {dadosVeiculo.anttCadastro || ''}</p>
                <p>Data Validade: {dadosVeiculo.anttValidade || ''}</p>
              </div>
              <br />{' '}
            </>
          )}
          {dadosVeiculo.anttMensagemVeiculo && dadosVeiculo.anttMensagemVeiculo.includes('NÃO')
            ? (
                <>
                  <p>Situação Transportador:</p>
                  <p>{dadosVeiculo.anttMensagemVeiculo}</p>
                </>
              )
            : (<br />)
          }
          {dadosVeiculo.anttMensagemTransportador && dadosVeiculo.anttMensagemTransportador.includes('NÃO')
            ? (
                <>
                  <p>Situação Transportador:</p>
                  <p>{dadosVeiculo.anttMensagemTransportador}</p>
                </>
              )
            : (<br />)
          }
          Obs: {dadosVeiculo.ocorrencia}
          <br />
          <br />
          {dadosRoubosFurtos &&
          (dadosVeiculo.ocorrencia.includes('VEÍCULO JÁ CONSTOU HISTÓRICO DE ROUBO/FURTO') ||
            dadosVeiculo.ocorrencia.includes('VEICULO INDICA OCORRENCIA DE ROUBO/FURTO') ||
            dadosVeiculo.ocorrencia.includes('VEÍCULO CONSTA HISTÓRICO DE ROUBO/FURTO')) ? (
            <>
              <br />
              <p>Ocorrência(as) ROUBO/FURTO</p>
              <br />
              {dadosRoubosFurtos.map((each: ResumoVeiculoType, index: number) => (
                <>
                  <div id="row">
                    <p>Ocorrência: {handleValidacaoRoubo(each.rouboFurtoDeclaracao)}</p>
                    <p>Em: {each.rouboFurtoDataOcorrencia}</p>
                    <p>Boletim n° {each.rouboFurtoBoletim}</p>
                  </div>
                </>
              ))}
            </>
          ) : null}
          {/* Situação Veículo: O veiculo NÃO esta cadastrado na frota do transportador informado. */}
        </>
      ) : (
        'NÃO HÁ DADOS DISPONÍVEIS'
      )}
    </div>
  );
}
