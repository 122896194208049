import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Table from '../../components/TableComponent';
import { Tela } from '../../components/Tela';

interface LogType {
  id: number;
  archive: string;
  ultAtualizacao: string;
}

export const ParametrosLogs = () => {
  const { page }: any = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LogType[]>([
    { id: 1, archive: 'LOG123458598-1.log', ultAtualizacao: '11/07/2022' },
    { id: 2, archive: 'LOG123458598-2.log', ultAtualizacao: '12/07/2022' },
    { id: 3, archive: 'LOG123458598-3.log', ultAtualizacao: '13/07/2022' },
    { id: 4, archive: 'LOG123458598-4.log', ultAtualizacao: '14/07/2022' },
    { id: 5, archive: 'LOG123458598-5.log', ultAtualizacao: '15/07/2022' },
    { id: 6, archive: 'LOG123458598-6.log', ultAtualizacao: '16/07/2022' },
    { id: 7, archive: 'LOG123458598-7.log', ultAtualizacao: '17/07/2022' },
    { id: 8, archive: 'LOG123458598-8.log', ultAtualizacao: '18/07/2022' },
    { id: 9, archive: 'LOG123458598-9.log', ultAtualizacao: '19/07/2022' },
    { id: 10, archive: 'LOG123458598-10.log', ultAtualizacao: '20/07/2022' },
    { id: 11, archive: 'LOG123458598-11.log', ultAtualizacao: '21/07/2022' },
    { id: 12, archive: 'LOG123458598-12.log', ultAtualizacao: '22/07/2022' },
  ]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/parametros/logs/webservice-sm/${pageNumber}`);
  };

  const renderName = () => {
    if (pathname.includes('webservice-sm')) {
      return { name: 'WebService S.M', path: 'Parâmetros > LOGs > WebService S.M' };
    }
    if (pathname.includes('webservice-pamcary')) {
      return { name: 'WebService Pamcary', path: 'Parâmetros > LOGs > WebService Pamcary' };
    }
    if (pathname.includes('integracao-dminer')) {
      return { name: 'Integração Dminer', path: 'Parâmetros > LOGs > Integração Dminer' };
    }
    return { name: '', path: '' };
  };

  const { name, path } = renderName();

  return (
    <Tela
      nome={name}
      caminho={path}
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={data.length}
      paginate={paginate}
      currentPage={page}
    >
      {currentData.length && (
        <Table
          headers={{ id: 'Id', archive: 'Arquivo', ultAtualizacao: 'Última Atualização' }}
          items={currentData}
          handleEdit={() => null}
          customRenderers={{
            archive: (it) => (
              <Button variant="link" size="sm" onClick={() => null}>
                {it.archive}
              </Button>
            ),
          }}
        />
      )}
    </Tela>
  );
};
