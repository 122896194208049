/* eslint-disable */
import React, { FC } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ContainerButtons, PrimaryButton, SecundaryButton } from './styles';
import './styles.css';

interface Props {
  disabled?: boolean;
  canEdit?: boolean;
  canInsert?: boolean;
}

const ButtonsForm: FC<Props> = (props) => {
  const history = useHistory();

  return (
    <ContainerButtons>
      <SecundaryButton
        type="button"
        onClick={() => {
          history.goBack();
        }}
      >
        Cancelar
      </SecundaryButton>
      {props.canEdit === false || props.canInsert === false ? (
        <PrimaryButton
          disabled={props.disabled}
          type="button"
          onClick={() => history.push('/acessonegado')}
        >
          Confirmar
        </PrimaryButton>
      ) : (
        <PrimaryButton type="submit" disabled={props.disabled}>
          Confirmar
        </PrimaryButton>
      )}
    </ContainerButtons>
  );
};

export default ButtonsForm;
