/* eslint-disable */
import React from 'react';

const Register = () => {
  return (
    <div>
      <iframe
        src="http://192.168.79.31:8090/scriptcase/app/Golden_Service_V2/grid_public_ponto_1/?nmgp_outra_jan=true&nmgp_start=SC&script_case_session=tv66g39q41epoo3cqd1qruqro0&7060"
        height="1080"
        width="100%"
        title="Testando"
      ></iframe>
    </div>
  );
};

export default Register;
