/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable*/
import axios from 'axios';
import React, { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Form, Button, Col, Spinner } from 'react-bootstrap';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { Dialog } from 'primereact/dialog';
import { useHistory, useLocation } from 'react-router-dom';
import { getCookieSessionData } from '../../services/cookieService';
import Input from '../../components/Input';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import api from '../../config/axiosMaquina';
import { errorsMessages } from '../../constants/Messages';
import { useToast } from '../../hooks/Toast';
import './styles.css';
import { Dropdown } from 'primereact/dropdown';

import { isDateLessThanBaseDate, isHourEqualsThanBaseHour } from '../../util/Validacoes/Validacoes';

type Cliente = {
  id: number;
  razaoSocial: string;
};
type Veiculo = {
  id: number;
  placa: string;
};
type Bau = {
  id: number;
  numero: number;
};
type DispositivoData = {
  defeito: boolean;
  dispositivoId: number;
  dispositivoNome: string;
  dtFinalizacao: string;
  dtLancamento: string;
  id: number;
  satId: number;
};
type VeiculoData = {
  id: number;
  idSat: number;
  idCarreta: number;
  idVeiculo: number;
  nomeTipoFrota: string;
  modeloVeiculo: string;
  marcaVeiculo: string;
  numeroEquipamento: string;
  placaVeiculo: string;
  tecnologiaVeiculo: string;
  tipoComunicacaoVeiculo: string;
  listaSatDispositivos: DispositivoData[];
  idUsuarioCadastro: number;
};
type BauData = {
  id: number;
  idBau: number;
  numeroBau: string;
  idCliente: number;
  modeloBau: string;
  idSat: number;
  dataInclusaoSat: string;
  nomeResponsavel: string;
  listaSatDispositivos: DispositivoData[];
  idUsuarioCadastro: number;
};

const CadastroSatFromMenu: FC = () => {
  const [clienteArr, setClienteArr] = useState<Cliente[]>([]);
  const [veiculosArr, setVeiculosArr] = useState<Veiculo[]>([]);
  const [carretasArr, setCarretasArr] = useState<Veiculo[]>([]);
  const [bauArr, setBauArr] = useState<Bau[]>([]);

  const [selectedCliente, setSelectedCliente] = useState<Cliente>({} as Cliente);
  const [selectedVeiculo, setSelectedVeiculo] = useState<Veiculo>({} as Veiculo);
  const [selectedCarreta, setSelectedCarreta] = useState<Veiculo>({} as Veiculo);
  const [selectedBau, setSelectedBau] = useState<Bau>({} as Bau);

  const [loading, setLoading] = useState(false);
  const usuario = getCookieSessionData().usuarioVO.usuarioId;
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);
  const [isDialogVisibleCadOk, setIsDialogVisibleCadOk] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const [responsavel, setResponsavel] = useState('');
  const [responsavelErro, setResponsavelErro] = useState('');

  const [veiculoData, setVeiculoData] = useState<VeiculoData>({} as VeiculoData);
  const [bauData, setBauData] = useState({} as BauData);

  const history = useHistory();
  const { addToast } = useToast();
  const location: {
    state: {
      id: number;
      type: 'veiculo' | 'carreta' | 'bau';
      clienteId: number;
      tab: string;
      idSATVeiculo: number;
      idSATBau: number;
      idSATCarreta: number;
      idSATCarreta2: number;
      dataInicializacaoSM: string;
    };
  } = useLocation();
  const { state } = location;

  const isNumber = (n: any) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  useEffect(() => {
    const loadTipoComunicacao = async () => {
      if (isNumber(veiculoData.tipoComunicacaoVeiculo)) {
        try {
          const response = await api.get(
            `/tipo-comunicacao/tecnologiatipocomunicacao/${veiculoData.tipoComunicacaoVeiculo}`
          );
          console.log(response.data);
          setVeiculoData({
            ...veiculoData,
            tipoComunicacaoVeiculo: response.data.ttcTxNome,
          });
          console.log(response.data);
        } catch (err: any) {
          console.log(err);
        }
      }
    };
    loadTipoComunicacao();
  }, [veiculoData]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get('/cliente/getIdRazaoSocial');
      setClienteArr(response.data);
    };
    fetchData();
  }, []);

  function reloadPage() {
    window.location.reload();
  }

  const buscarTipoComunicacao = async () => {
    const response = await api.get('/cliente/getIdRazaoSocial');
    setClienteArr(response.data);
  };

  const loadClienteData = useCallback((value: string) => {
    axios
      .all([
        api.get('/veiculo/listarIdEPlaca', {
          params: {
            cliente: value,
            grupoMacroVeículo: 1,
          },
        }),
        api.get('/veiculo/listarIdEPlaca', {
          params: {
            cliente: value,
            grupoMacroVeículo: 2,
          },
        }),
        api.get('/bau-intercambiavel/listaIdENumero', {
          params: {
            cliente: value,
          },
        }),
      ])
      .then(
        axios.spread((veiculosResponse, carretasResponse, bauResponse) => {
          setVeiculosArr(veiculosResponse.data);
          setCarretasArr(carretasResponse.data);
          setBauArr(bauResponse.data);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [idSat, setIdSat] = useState<number>();

  const loadVeiculoDataForSM = useCallback(async (idVeiculo: number, idSat: number) => {
    console.log('id sat', idSat);
    try {
      const response = await api.get(`/solicitacao-assistencia-tecnica/listar-dados-sat`, {
        params: {
          idVeiculo: idVeiculo,
          idSat: idSat,
        },
      });
      console.log(response.data);
      console.log('dispositivos', response.data.listaSatDispositivos);
      response.data.listaSatDispositivos = response.data.listaSatDispositivos.map(
        (dispositivo: DispositivoData) => {
          if (dispositivo.dtFinalizacao) {
            if (
              isDateLessThanBaseDate({
                baseDate: new Date(state.dataInicializacaoSM).toLocaleDateString('pt-br', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                }),
                date: new Date(dispositivo.dtFinalizacao).toLocaleDateString('pt-br', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                }),
              }) === true
            ) {
              dispositivo.defeito = false;
            } else {
              dispositivo.defeito = true;
            }
            return dispositivo;
          } else if (dispositivo.dtLancamento) {
            if (
              isDateLessThanBaseDate({
                baseDate: new Date(state.dataInicializacaoSM).toLocaleDateString('pt-br', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                }),
                date: new Date(dispositivo.dtLancamento).toLocaleDateString('pt-br', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                }),
              }) === true
            ) {
              dispositivo.defeito = false;
            } else {
              dispositivo.defeito = true;
            }
            return dispositivo;
          }
        }
      );

      console.log('DATA VEICULO', response.data);

      setIdSat(idSat);
      setVeiculoData(response.data);
    } catch (err: any) {
      console.error(err);
    }
  }, []);

  const loadBauDataForSM = useCallback(async (idBau: number, idSat: number) => {
    if (idSat) {
      try {
        const response = await api.get(`/solicitacao-assistencia-tecnica/listar-dados-sat-bau`, {
          params: {
            idBau: idBau,
            idSat: idSat,
          },
        });

        response.data.listaSatDispositivos = response.data.listaSatDispositivos.map(
          (dispositivo: DispositivoData) => {
            if (dispositivo.dtFinalizacao) {
              if (
                isDateLessThanBaseDate({
                  baseDate: new Date(state.dataInicializacaoSM).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  }),
                  date: new Date(dispositivo.dtFinalizacao).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  }),
                }) === true
              ) {
                dispositivo.defeito = false;
              } else {
                dispositivo.defeito = true;
              }
              return dispositivo;
            } else if (dispositivo.dtLancamento) {
              if (
                isDateLessThanBaseDate({
                  baseDate: new Date(state.dataInicializacaoSM).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  }),
                  date: new Date(dispositivo.dtLancamento).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  }),
                }) === true
              ) {
                dispositivo.defeito = false;
              } else {
                dispositivo.defeito = true;
              }
              return dispositivo;
            }
          }
        );

        setBauData(response.data);
      } catch (err: any) {
        console.error(err);
      }
    }
  }, []);

  const loadVeiculoData = useCallback(
    async (value: string) => {
      try {
        console.log(selectedCliente);
        const responseVeiculo = await api.get(
          `/veiculo/listarDadosVeiculoParaSAT?idCliente=${selectedCliente.id}&veiculo=${value}`
        );

        setVeiculoData(responseVeiculo.data);
      } catch (err: any) {
        console.log(err);
      }
    },
    [selectedCliente.id]
  );

  const loadBauData = useCallback(async (value: string) => {
    try {
      const responseBau = await api.get('bau-intercambiavel/listarDadosBauParaSAT', {
        params: {
          bau: value,
        },
      });
      setBauData(responseBau.data);
    } catch (err: any) {
      console.log(err);
    }
  }, []);

  const loadVeiculoSemSatForSM = useCallback(
    async (value: number) => {
      try {
        const response = await api.get(
          `/veiculo/listarDadosVeiculoParaSAT?idCliente=${selectedCliente.id}&veiculo=${value}`
        );
        console.log('DISPOSIVITOS DATA', response.data);
        response.data.listaSatDispositivos = response.data.listaSatDispositivos.map(
          (dispositivo: DispositivoData) => {
            dispositivo.defeito = false;
            return dispositivo;
          }
        );

        setVeiculoData(response.data);
      } catch (err: any) {
        console.log(err);
      }
    },
    [selectedCliente.id]
  );

  const loadBauSemSatForSM = useCallback(async (value: number) => {
    try {
      const response = await api.get(`/bau-intercambiavel/listarDadosBauParaSAT`, {
        params: {
          bau: value,
        },
      });

      response.data.listaSatDispositivos = response.data.listaSatDispositivos.map(
        (dispositivo: DispositivoData) => {
          dispositivo.defeito = false;
          return dispositivo;
        }
      );

      setBauData(response.data);
    } catch (err: any) {
      console.log(err);
    }
  }, []);

  const handleCheckDefeitoVeiculo = (id: number) => {
    const changedDispositivos = veiculoData.listaSatDispositivos.map((dispositivo) => {
      if (dispositivo.dispositivoId === id) {
        const item = { ...dispositivo };
        item.defeito = !dispositivo.defeito;
        return item;
      }
      return dispositivo;
    });

    setVeiculoData({ ...veiculoData, listaSatDispositivos: changedDispositivos });
  };

  useEffect(() => {
    if (!state) {
      return;
    }
    loadClienteData(state.clienteId.toString());
    const client = clienteArr.find((each) => each.id.toString() === state.clienteId.toString());
    if (client) setSelectedCliente(client);
  }, [clienteArr, loadClienteData, state]);

  useEffect(() => {
    if (!state) {
      return;
    }
    if (state && state.type === 'veiculo' && state.tab === 'PENDENTE' && state.idSATVeiculo) {
      loadVeiculoDataForSM(state.id, state.idSATVeiculo);
      const veiculo = veiculosArr.find((each) => each.id === state.id);

      if (veiculo) setSelectedVeiculo(veiculo);
    } else if (state && state.type === 'veiculo' && state.tab === 'PENDENTE') {
      loadVeiculoSemSatForSM(state.id);
      const veiculo = veiculosArr.find((each) => each.id === state.id);

      if (veiculo) setSelectedVeiculo(veiculo);
    } else if (
      state &&
      state.type === 'carreta' &&
      state.tab === 'PENDENTE' &&
      state.idSATCarreta
    ) {
      loadVeiculoDataForSM(state.id, state.idSATCarreta);
      const carreta = carretasArr.find((each) => each.id === state.id);
      console.log(carreta);
      if (carreta) setSelectedVeiculo(carreta);
    } else if (state && state.type === 'carreta' && state.tab === 'PENDENTE') {
      loadVeiculoSemSatForSM(state.id);
      const carreta = carretasArr.find((each) => each.id.toString() === state.id.toString());

      if (carreta) setSelectedCarreta(carreta);
    } else if (
      state &&
      state.type === 'carreta' &&
      state.tab === 'PENDENTE' &&
      state.idSATCarreta2
    ) {
      loadVeiculoDataForSM(state.id, state.idSATCarreta2);
      const veiculo = veiculosArr.find((each) => each.id === state.id);

      if (veiculo) setSelectedVeiculo(veiculo);
    } else if (state && state.type === 'carreta' && state.tab === 'PENDENTE') {
      loadVeiculoSemSatForSM(state.id);
      const carreta = carretasArr.find((each) => each.id.toString() === state.id.toString());

      if (carreta) setSelectedCarreta(carreta);
    } else if (state.type === 'veiculo') {
      loadVeiculoData(state.id.toString());
      const veiculo = veiculosArr.find((each) => each.id.toString() === state.id.toString());

      if (veiculo) setSelectedVeiculo(veiculo);
    } else if (state.type === 'carreta') {
      loadVeiculoData(state.id.toString());
      const carreta = carretasArr.find((each) => each.id.toString() === state.id.toString());

      if (carreta) setSelectedCarreta(carreta);
    } else if (state && state.type === 'bau' && state.tab === 'PENDENTE' && state.idSATBau) {
      loadBauDataForSM(state.id, state.idSATBau);
      const bau = bauArr.find((each) => each.id.toString() === state.id.toString());

      if (bau) setSelectedBau(bau);
    } else if (state && state.type === 'bau' && state.tab === 'PENDENTE') {
      loadBauSemSatForSM(state.id);
      const bau = bauArr.find((each) => each.id.toString() === state.id.toString());

      if (bau) setSelectedBau(bau);
    } else if (state.type === 'bau') {
      loadBauData(state.id.toString());
      const bau = bauArr.find((each) => each.id.toString() === state.id.toString());

      if (bau) setSelectedBau(bau);
    }
  }, [
    bauArr,
    carretasArr,
    loadBauData,
    loadVeiculoData,
    loadBauDataForSM,
    loadVeiculoDataForSM,
    state,
    veiculosArr,
  ]);

  const handleCheckDefeitoBau = (id: number) => {
    const changedDispositivos = bauData.listaSatDispositivos.map((dispositivo) => {
      if (dispositivo.dispositivoId === id) {
        const item = { ...dispositivo };
        item.defeito = !dispositivo.defeito;
        return item;
      }
      return dispositivo;
    });

    setBauData({ ...bauData, listaSatDispositivos: changedDispositivos });
  };

  const handleSubmit = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (!responsavel) {
        setResponsavelErro(errorsMessages.input.nullOrEmpty);
        return;
      }
      setResponsavelErro('');
      setLoading(true);
      if (veiculoData.idVeiculo) {
        try {
          const data = {
            ...veiculoData,
            idSat: idSat,
            idVeiculo: selectedVeiculo.id ? veiculoData.idVeiculo : null,
            idCarreta: selectedCarreta.id ? veiculoData.idVeiculo : null,
            idCliente: selectedCliente.id,
            nomeResponsavel: responsavel,
            idUsuarioCadastro: usuario,
            usuarioUltimaAtualizacao: usuario,
          };
          console.log('REQUEST', data);

          const response = await api.post(
            'solicitacao-assistencia-tecnica/incluir-sat-veiculo',
            data
          );
          if (response.data === 'Operação realizada com sucesso!') {
            setIsDialogVisibleCadEfetuado(true);

            setLoading(false);
          } else {
            addToast({
              title: 'Atenção',
              description: response.data,
            });
          }
        } catch (err: any) {
          setLoading(false);
          console.log(err);
        }
      } else if (bauData.id) {
        const data = {
          ...bauData,
          idCliente: selectedCliente.id,
          nomeResponsavel: responsavel,
          idUsuarioCadastro: usuario,
          usuarioUltimaAtualizacao: usuario,
        };

        console.log('DATA', data);
        try {
          const response = await api.post('/solicitacao-assistencia-tecnica/incluir-sat-bau', data);

          if (response.data === 'Operação realizada com sucesso!') {
            setIsDialogVisibleCadEfetuado(true);
            setLoading(false);
          } else {
            addToast({
              title: 'Atenção',
              description: response.data,
            });
          }
        } catch (err: any) {
          console.log(err.response);
        }
      }
    },
    [addToast, bauData, history, responsavel, selectedCliente.id, veiculoData]
  );

  const buttonCancelar = () => (
    <Form.Row style={{ marginTop: '10px' }} className="container-buttons justify-content-end">
      {!selectedVeiculo.id && !selectedBau.id && !selectedCarreta.id ? (
        <Form.Group as={Col} md={2}>
          <Button
            style={{
              backgroundColor: '#ffffff',
              color: 'black',
              borderColor: 'black',
              borderWidth: '2px',
            }}
            className="btn-cancelar"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
      ) : null}
    </Form.Row>
  );

  const renderVeiculoComponent = () => (
    <Col lg="auto">
      <Form.Row>
        {!selectedCarreta.id && !selectedBau.id && (
          <Form.Group className="row-modal">
            <Form.Label className="requiredField">Veículo</Form.Label>
            <Dropdown
              style={{ width: '100%' }}
              value={selectedVeiculo.id}
              options={veiculosArr}
              placeholder={'Selecione'}
              onChange={(e: any) => {
                const veiculo = veiculosArr.find((each) => each.id === e.target.value);
                console.log(veiculo);
                if (!veiculo) {
                  setVeiculoData({} as VeiculoData);
                  setSelectedVeiculo({} as Veiculo);
                  return;
                }
                setSelectedVeiculo(veiculo);
                setSelectedBau({} as Bau);
                setBauData({} as BauData);
                loadVeiculoData(e.target.value);
                if (state && state.tab !== undefined && state.tab === 'PENDENTE') {
                  loadVeiculoDataForSM(e.target.value, state.idSATVeiculo);
                }
              }}
              optionLabel="placa"
              optionValue="id"
              showClear
              filter
              className="multiselect-custom"
              id="veiculo"
              required
              disabled={!selectedCliente.id || !!state}
            />
          </Form.Group>
        )}
        {veiculoData.idVeiculo && selectedVeiculo.id && (
          <>
            <Input
              value={`${veiculoData.marcaVeiculo} ${veiculoData.modeloVeiculo}`.toUpperCase()}
              id="modelo"
              name="modelo"
              textInputTitle="Marca / Modelo"
              disabled
            />
            <Input
              value={veiculoData.nomeTipoFrota}
              id="tipoFrota"
              name="tipoFrota"
              textInputTitle="Tipo Frota"
              disabled
            />
          </>
        )}
      </Form.Row>
      {veiculoData.idVeiculo && selectedVeiculo.id && (
        <>
          <Form.Row>
            <Input
              value={veiculoData.tecnologiaVeiculo}
              id="tecnologia"
              name="tecnologia"
              textInputTitle="Tecnologia"
              disabled
            />
            <Input
              value={veiculoData.tipoComunicacaoVeiculo}
              id="comunicacao"
              name="comunicacao"
              textInputTitle="Comunicação"
              disabled
            />
            <Input
              value={veiculoData.numeroEquipamento}
              id="numeroEquipamento"
              name="numeroEquipamento"
              textInputTitle="Nº Equipamento"
              disabled
            />
          </Form.Row>
        </>
      )}
    </Col>
  );

  const renderCarretaComponent = () => (
    <Col lg="auto">
      <Form.Row>
        {!selectedVeiculo.id && !selectedBau.id && (
          <Form.Group className="row-modal">
            <Form.Label className="requiredField">Carreta</Form.Label>
            <Dropdown
              style={{ width: '100%' }}
              value={selectedCarreta.id}
              options={carretasArr}
              placeholder={'Selecione'}
              onChange={(e: any) => {
                const carreta = carretasArr.find((each) => each.id === e.target.value);

                if (!carreta) {
                  setVeiculoData({} as VeiculoData);
                  setSelectedCarreta({} as Veiculo);
                  return;
                }
                setSelectedCarreta(carreta);
                setSelectedBau({} as Bau);
                setBauData({} as BauData);
                if (
                  state &&
                  state.tab !== undefined &&
                  state.tab === 'PENDENTE' &&
                  state.idSATCarreta !== null
                ) {
                  loadVeiculoDataForSM(e.target.value, state.idSATCarreta);
                } else if (
                  state &&
                  state.tab !== undefined &&
                  state.tab === 'PENDENTE' &&
                  state.idSATCarreta2 !== null
                ) {
                  loadVeiculoDataForSM(e.target.value, state.idSATCarreta2);
                } else if (
                  state &&
                  state.tab !== undefined &&
                  state.tab === 'PENDENTE' &&
                  state.idSATCarreta2 === null
                ) {
                  loadVeiculoSemSatForSM(e.target.value);
                }
                loadVeiculoData(e.target.value);
              }}
              optionLabel="placa"
              optionValue="id"
              showClear
              filter
              className="multiselect-custom"
              id="veiculo"
              required
              disabled={!selectedCliente.id || !!state}
            />
          </Form.Group>
        )}
        {veiculoData.idVeiculo && selectedCarreta.id && (
          <>
            <Form.Row>
              <Input
                value={veiculoData.modeloVeiculo}
                id="tipoVeiculo"
                name="tipoVeiculo"
                textInputTitle="Tipo"
                disabled
              />
              <Input
                value={veiculoData.nomeTipoFrota}
                id="tipoFrota"
                name="tipoFrota"
                textInputTitle="Tipo Frota"
                disabled
              />
            </Form.Row>
          </>
        )}
      </Form.Row>
    </Col>
  );

  const renderBauComponent = () => (
    <Col lg="auto">
      <Form.Row>
        {!selectedVeiculo.id && !selectedCarreta.id && (
          <Form.Group className="row-modal">
            <Form.Label className="requiredField">Baú</Form.Label>
            <Dropdown
              style={{ width: '100%' }}
              value={selectedBau.id}
              options={bauArr}
              placeholder={'Selecione'}
              onChange={(e: any) => {
                const bau = bauArr.find((each) => each.id === e.target.value);
                if (!bau) {
                  setSelectedBau({} as Bau);
                  setBauData({} as BauData);
                  return;
                }
                setSelectedBau(bau);
                setVeiculoData({} as VeiculoData);
                setSelectedVeiculo({} as Veiculo);
                loadBauData(e.target.value);

                if (state !== undefined && state.tab === 'PENDENTE' && state.idSATBau !== null) {
                  loadBauDataForSM(e.target.value, state.idSATBau);
                } else if (
                  state !== undefined &&
                  state.tab === 'PENDENTE' &&
                  state.idSATBau === null
                ) {
                  loadBauSemSatForSM(e.target.value);
                } else {
                  loadBauData(e.target.value);
                }
              }}
              optionLabel="numero"
              optionValue="id"
              showClear
              filter
              className="multiselect-custom"
              id="bau"
              required
              disabled={!selectedCliente.id || !!state}
            />
          </Form.Group>
        )}
        {selectedBau.id && (
          <Input value={bauData.modeloBau} id="tipo" name="tipo" textInputTitle="Tipo" disabled />
        )}
      </Form.Row>
    </Col>
  );

  const renderListagemDispositivosVeiculo = () => (
    <tbody>
      {veiculoData &&
        veiculoData.listaSatDispositivos &&
        veiculoData.listaSatDispositivos.map((dispositivo) =>
          dispositivo && dispositivo.dispositivoId ? (
            <tr
              onClick={() => handleCheckDefeitoVeiculo(dispositivo.dispositivoId)}
              key={dispositivo.dispositivoId}
            >
              <td>{dispositivo.dispositivoId}</td>
              <td>{dispositivo.dispositivoNome}</td>
              <td>
                <IoCheckmarkCircle color={dispositivo.defeito ? '#63c960' : '#969696'} size={22} />
              </td>
            </tr>
          ) : (
            <tr></tr>
          )
        )}
    </tbody>
  );

  console.log('DISPOSITIVO', veiculoData);
  console.log('state', state);
  const renderListagemDispositivosBau = () => (
    <tbody>
      {bauData &&
        bauData.listaSatDispositivos.length > 0 &&
        bauData.listaSatDispositivos.map((dispositivo) => (
          <tr
            onClick={() => handleCheckDefeitoBau(dispositivo?.dispositivoId)}
            key={dispositivo?.dispositivoId}
          >
            <td>{dispositivo?.dispositivoId}</td>
            <td>{dispositivo?.dispositivoNome}</td>
            <td>
              <IoCheckmarkCircle color={dispositivo?.defeito ? '#63c960' : '#969696'} size={22} />
            </td>
          </tr>
        ))}
    </tbody>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação Assistência Técnica</h2>
          <h6 className="subtitulo">
            {'Operacional > Solicitação > Solicitação Assistência Técnica'}
          </h6>
        </div>

        <Dialog
          header="SAT cadastrada com sucesso."
          footer={<Button onClick={() => setIsDialogVisibleCadOk(false)}>OK</Button>}
          visible={isDialogVisibleCadOk}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgEmail(true)}
        />

        <Dialog
          header="SAT cadastrada com sucesso."
          footer={<Button onClick={() => setIsDialogVisibleCadEfetuado(false)}>OK</Button>}
          visible={isDialogVisibleCadEfetuado}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            if (!state) {
              history.goBack();
              return;
            }
            if (state.tab === 'EM_ANDAMENTO') {
              history.push('/admin/painel-operacional/em-andamento/1');
            } else if (state && state.tab !== undefined && state.tab === 'PENDENTE') {
              history.push('/admin/painel-operacional/pendentes/1');
            }
          }}
        />

        <Dialog
          header="Não foi possível enviar email de SAT. Verifique o email cadastrado."
          footer={<Button onClick={() => setIsDialogVisibleMsgEmail(false)}>OK</Button>}
          visible={isDialogVisibleMsgEmail}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            if (!state) {
              reloadPage();
            } else {
              history.push('/admin/painel-operacional/pendentes/1');
            }
          }}
        />

        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <Col lg="auto">
              <Form.Row>
                <Form.Group style={{ marginTop: '5px' }} className="row-modal">
                  <Form.Label className="requiredField" id="empresaTitle">
                    Empresa
                  </Form.Label>
                  <Dropdown
                    style={{ width: '100%' }}
                    value={selectedCliente.id}
                    options={clienteArr}
                    placeholder={
                      selectedCliente.razaoSocial ? selectedCliente.razaoSocial : 'Selecione'
                    }
                    onChange={(e: any) => {
                      const client = clienteArr.find((each) => each.id === e.target.value);
                      setSelectedBau({} as Bau);
                      setSelectedCarreta({} as Veiculo);
                      setSelectedVeiculo({} as Veiculo);
                      setVeiculoData({} as VeiculoData);
                      setBauData({} as BauData);
                      setResponsavel('');
                      if (!client) {
                        setSelectedCliente({} as Cliente);

                        return;
                      }
                      setSelectedCliente(client);
                      loadClienteData(e.target.value);
                    }}
                    optionLabel="razaoSocial"
                    optionValue="id"
                    showClear
                    filter
                    disabled={
                      location.state
                        ? location.state.tab.includes('PENDENTE')
                          ? true
                          : false
                        : false
                    }
                    className="multiselect-custom"
                    id="empresa"
                    required
                  />
                </Form.Group>
                <Input
                  value={selectedCliente.id ? selectedCliente.id : ''}
                  name="codigo"
                  textInputTitle="Código"
                  disabled
                />
              </Form.Row>
            </Col>

            {renderVeiculoComponent()}
            {renderCarretaComponent()}
            {renderBauComponent()}
            {selectedVeiculo.id || selectedCarreta.id || selectedBau.id ? (
              <Col lg="auto">
                <Form.Row>
                  <Input
                    required
                    error={responsavelErro}
                    value={responsavel}
                    id="responsavel"
                    name="responsavel"
                    textInputTitle="Responsável"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setResponsavel(e.target.value)}
                  />
                </Form.Row>
                <hr />
                <Form.Label className="SMLancadasTitle">
                  Dispositivos vinculados ao veículo
                </Form.Label>
                <Col lg={{ span: 6, offset: 3 }}>
                  <div className="painel" style={{ marginTop: 20 }}>
                    <div className="table-responsive">
                      <table className="table" id="table-cadastro-sat">
                        <thead className="thead">
                          <tr>
                            <th>ID </th>
                            <th>Dispositivo</th>
                            <th>Defeito</th>
                          </tr>
                        </thead>
                        {bauData.id && renderListagemDispositivosBau()}
                        {veiculoData.idVeiculo && renderListagemDispositivosVeiculo()}
                      </table>
                    </div>
                  </div>
                </Col>
                <Form.Row className="container-buttons justify-content-end">
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-cancelar"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Button type="submit" onClick={handleSubmit} className="btn-enviar">
                      Confirmar
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Col>
            ) : null}
          </>
        )}
        {buttonCancelar()}
      </div>
    </div>
  );
};

export default CadastroSatFromMenu;
