/* eslint-disable */

//2020-12-30T19:26:54.606+00:00
export const getFullDateWithHourAndMinutes = (
  date: string | null | undefined,
  noSeconds?: boolean,
  noYears?: boolean
) => {
  if (!date) {
    return '';
  }
  const dateArr = date.split('T');

  const year = dateArr[0].split('-')[0];
  const month = dateArr[0].split('-')[1];
  const day = dateArr[0].split('-')[2];

  const hourAndMinutes = dateArr[1].split('.')[0];
  if (noSeconds && noYears) {
    const hour = hourAndMinutes.split(':')[0];
    const minutes = hourAndMinutes.split(':')[1];
    return `${day}/${month} ${hour}:${minutes}`;
  }
  if (noSeconds) {
    const hour = hourAndMinutes.split(':')[0];
    const minutes = hourAndMinutes.split(':')[1];
    return `${day}/${month}/${year} ${hour}:${minutes}`;
  }

  return `${day}/${month}/${year} ${hourAndMinutes}`;
};

export const getFullDate = (date: string) => {
  const dateArr = date.split('T');

  return dateArr[0];
};

export const getFormatedData = (date: string) => {
  if (!date) {
    return;
  }
  var dia = date.split('-')[2];
  var mes = date.split('-')[1];
  var ano = date.split('-')[0];

  return `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
};

export const formataDataAnoMesDia = (date: string) => {
  if (!date) {
    return;
  }
  var dia = date.split('-')[2];
  var mes = date.split('-')[1];
  var ano = date.split('-')[0];

  return `${ano}/${mes.padStart(2, '0')}/${dia.padStart(2, '0')}`;
};

export const getFullTime = (date: string | null | undefined) => {
  if (!date) {
    return null;
  }
  const dateArr = date.split('T');
  const hour = dateArr[1].split('.')[0].split(':')[0];
  const minutes = dateArr[1].split('.')[0].split(':')[1];

  return `${hour}:${minutes}`;
};

export const dateNow = () => {
  const now = new Date();
  const dateNow = new Date(now.getTime());
  const dateNowFormated =
    dateNow.getFullYear().toString() +
    '-' +
    (dateNow.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    dateNow.getDate().toString().padStart(2, '0');
  //console.log(dateNow);
  return dateNowFormated;
};

export const getFormatedData2 = (date: string) => {
  if (!date) {
    return '';
  }
  var dia = date.split('-')[2];
  var mes = date.split('-')[1];
  var ano = date.split('-')[0];

  return `${ano}/${mes.padStart(2, '0')}/${dia.padStart(2, '0')} 00:00`;
};

export function removerSpecials(texto: string) {
  if (!texto) {
    return '';
  }
  // eliminando acentuação
  texto = texto.replace(/[íìî]/, 'i');
  texto = texto.replace(/[àáâãäå]/, 'a');
  texto = texto.replace(/[òóôõ]/, 'o');
  texto = texto.replace(/[éèêë]/, 'e');
  texto = texto.replace(/[úùüû]/, 'u');
  texto = texto.replace(/[ç]/, 'c');
  return texto.replace(/[^a-z0-9]/gi, '');
}

function objectValues<T extends {}>(obj: T) {
  return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
}

export function filterItems(dt: any, query: any) {
  return dt.filter(function (el: any) {
    return (
      removerSpecials(el?.toString().toLowerCase())?.indexOf(
        removerSpecials(query?.toString().toLowerCase())
      ) > -1
    );
  });
}

export function filterItems2(dt: any, query: string) {
  if (!query.length) return [];
  return objectValues(dt).filter((obj) => {
    if (
      Object.values(obj).some((o: any) => {
        if (!o || o === null) {
          return false;
        }
        if (typeof o === 'string' && o.length > 1) {
          return removerSpecials(o.toLowerCase()).includes(removerSpecials(query.toLowerCase()));
        }
        return removerSpecials(o.toString().toLowerCase()).includes(
          removerSpecials(query.toLowerCase())
        );
      })
    ) {
      return dt;
    }
    return null;
  });
}

export const pesquisar = (data: any[], searchValue: string) => {
  return data.filter((each: any) => filterItems(each, searchValue).length > 0);
};

export function FormatDateTime(data: any, time: any) {
  if (typeof data != 'number' && !time) {
    return;
  } else {
    let dia = data.split('/')[0];
    let mes = data.split('/')[1];
    let ano = data.split('/')[2];
    if (!dia || !mes || !ano) {
      dia = data.split('-')[0];
      mes = data.split('-')[1];
      ano = data.split('-')[2];
    }
    return `${ano}-${('0' + mes).slice(-2)}-${('0' + dia).slice(-2)}T${time}:00`;
  }
}

export const getFullDateNow = (noSeconds?: boolean) => {
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  return localISOTime;
};

export const getFullDateFormatted = (date: string | null | undefined) => {
  if (!date) {
    return '';
  }
  const dateArr = date.split('T');

  const year = dateArr[0].split('-')[0];
  const month = dateArr[0].split('-')[1];
  const day = dateArr[0].split('-')[2];

  const hourAndMinutes = dateArr[1].split('.')[0];

  return `${day}/${month}/${year}`;
};
