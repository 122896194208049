/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import api from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { handleResultado } from '../../../util/validaResultadoPesquisa';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import { removerSpecials } from '../../../util/format';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import { obterDataHora } from '../../../util/date';

export type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    statusPesquisa?: string;
    statusPesquisaCondutorCnh?: string;
    tipoEnvioPesquisa?: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  tipo?: string;
};

type Props = {
  canEdit?: boolean;
};

const AnalisePerfilGrid = ({ canEdit }: Props) => {
  const [loading, setLoading] = useState(false);
  const { page }: any = useParams();
  const [itensPerPage, setItensPerPage] = useState('10');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const { addToast } = useToast();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [firstRequest, setFirstRequest] = useState(false);
  const [data, setData] = useState<ListagemHistoricoPesquisa[]>([]);
  const [dataSearch, setDataSearch] = useState<ListagemHistoricoPesquisa[]>([]);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false);

  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/analise-perfil/painel/${pageNumber}`);
  };

  const handleGoToDetails = (id: any) => {
    const getByNumLib = data?.find((fil) => fil.numLiberacao === id);

    history.push({
      pathname: `/detalhe-pesquisa`,
      state: { ...getByNumLib },
    });
  };

  const handleGoEdit = (id: any) => {
    const getByNumLib = data?.find((fil) => fil.numLiberacao === id);

    history.push({
      pathname: `/analise-perfil/editar`,
      state: { ...getByNumLib },
    });
  };

  const retornaItemPesquisado = (each: any) => {
    let arr: string[] = [];

    if (each.operacao.includes('Integrada')) {
      each.itens.map((e: any) => {
        if (e.tipo.includes('Veículo')) {
          arr.push(e.itemPesquisado);
        } else {
          arr.push(e.descricao);
        }
      });
    } else {
      if (each.itens[0].tipo.includes('Veículo')) {
        arr.push(each.itens[0].itemPesquisado);
      } else {
        arr.push(each.itens[0].descricao);
      }
    }
    return arr.join();
  };

  const retornaTipo = (each: any) => {
    if (each.itens[0].tipoEnvioPesquisa === 'PESQUISA' || each.tipo === 'PESQUISA') {
      return 'Cadastro';
    }
    if (each.itens[0].tipoEnvioPesquisa === 'CONSULTA' || each.tipo === 'CONSULTA') {
      return 'Consulta';
    }
    return '';
  };

  const retornaResultado = (i: any) => {
    console.log(i);
    console.log(handleResultado(i));
    if (handleResultado(i) === 'Análise Vencida') {
      return 'alert-row';
    }
    if (handleResultado(i) === 'Pendente de Documentação') {
      return 'alert-row';
    }
    if (handleResultado(i) === 'Em Acordo') {
      return 'success-row';
    }
    return '';
  };

  const handleRequest = async () => {
    setIsDialogAtualizandoOK(firstRequest);
    const dataTodos = {
      tipo: 'TODOS',
      tipoBusca: 'SEM_PARAMETROS',
    };
    try {
      const response = await api.post(`/pesquisa/historico`, dataTodos);
      setFirstRequest(true);
      setData(response.data);
      console.log(`Página ${page}, qtd ${itensPerPage}`, response.data);
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro!',
        description: 'Erro na comunicação com o servidor.',
        type: 'error',
      });
    } finally {
      setIsDialogAtualizandoOK(false);
      return () => clearInterval(interval);
    }
  };
  var interval: any;

  useEffect(() => {
    handleRequest();
    interval = setInterval(async () => {
      console.log('refresh');
      handleRequest();
    }, 120000);

    return () => clearInterval(interval);
  }, []);

  const clearValue = (v: any) => {
    return removerSpecials(v?.toString().toLowerCase());
  };

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/analise-perfil/painel/1');
      const search = clearValue(searchValue);

      const filteredData = data.filter(
        (each: ListagemHistoricoPesquisa) =>
          clearValue(each.numLiberacao).includes(search) ||
          clearValue(each.empresa).includes(search) ||
          clearValue(retornaItemPesquisado(each)).includes(search) ||
          clearValue(retornaTipo(each)).includes(search) ||
          clearValue(handleResultado(each.itens)).includes(search) ||
          clearValue(obterDataHora(each.dataLancamento, true)).includes(search) ||
          clearValue(
            each.itens[0].dataRetorno === null || handleResultado(each.itens) === 'Em Andamento'
              ? ''
              : obterDataHora(each.itens[0].dataRetorno, true)
          ).includes(search)
      );
      setDataSearch(filteredData);
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Painel Análise Perfil"
      caminho="Análises de Perfil > Painel Análise Perfil"
      loading={!currentData.length && !firstRequest}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          setIsDialogAtualizandoOK(false);
        }}
      />
      {loadingSearch ? (
        <Spinner />
      ) : (
        <div className="table-responsive" style={{ flex: 1 }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>N° Liberação</th>
                <th>Empresa</th>
                <th>Item Pesquisado</th>
                <th>Tipo</th>
                <th>Situação</th>
                <th>Lançamento</th>
                <th>Retorno</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentData &&
                currentData.map((each: ListagemHistoricoPesquisa, index: number) => {
                  return (
                    <tr key={index} className={retornaResultado(each.itens)}>
                      <td>{each.numLiberacao}</td>
                      <td>{each.empresa}</td>
                      <td>{retornaItemPesquisado(each)}</td>
                      <td>{retornaTipo(each)}</td>
                      <td>{handleResultado(each.itens)}</td>
                      <td>{obterDataHora(each.dataLancamento, true)}</td>
                      <td>
                        {each.itens[0].dataRetorno === null ||
                        handleResultado(each.itens) === 'Em Andamento'
                          ? ''
                          : obterDataHora(each.itens[0].dataRetorno, true)}
                      </td>
                      <td align="right">
                        <ButtonTooltip
                          type="Editar"
                          onClick={() => {
                            if (canEdit) {
                              handleGoEdit(each.numLiberacao);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                        />
                      </td>
                      <td align="right">
                        <ButtonTooltip
                          type="Visualizar"
                          onClick={() => handleGoToDetails(each.numLiberacao)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </Tela>
  );
};

export default AnalisePerfilGrid;
