import { format } from 'date-fns';
import { memo } from 'react';
import { Button } from 'react-bootstrap';
import { GridViolacao } from '../..';
import { Table } from '../../styles';

type Props = {
  data: GridViolacao[];
  columns: string[];
  openMap: (lat: string, lng: string) => void;
  controle: number;
  velocidadeFrota: number;
};

const MainTable = ({ data, columns, openMap, controle, velocidadeFrota }: Props) => {
  const renderTemperaturaMinMax = (temperaturaMin: number, temperaturaMax: number) => {
    if (temperaturaMin && temperaturaMax) {
      return `${temperaturaMin} °C / ${temperaturaMax} °C`;
    }
    if (temperaturaMin && !temperaturaMax) {
      return `${temperaturaMin}°C / -`;
    }
    if (!temperaturaMin && temperaturaMax) {
      return `- / ${temperaturaMax}°C`;
    }
    return '-';
  };

  const renderVelocidadeMax = (velocidadeMax: number) => {
    if (velocidadeMax) {
      return `${velocidadeMax} Km/h`;
    }
    return '-';
  };

  const renderTable = () => {
    return (
      <Table width="100%">
        <div className="table-header">
          {columns.map((each: string, index: number) => (
            <p key={index}>{each}</p>
          ))}
        </div>
        <div className="table-body">
          {data.map((each: GridViolacao, index: number) => (
            <div className="table-body-row" key={index}>
              <p>{format(new Date(each.data), 'dd/MM HH:mm')}</p>
              <p>{each.placa}</p>
              {controle === 0 || controle === 2 ? (
                <p>{each.temperatura ? `${each.temperatura} ºC` : '-'}</p>
              ) : null}
              {controle === 0 || controle === 2 ? (
                <p>{renderTemperaturaMinMax(each.temperaturaMinima, each.temperaturaMaxima)}</p>
              ) : null}
              {controle === 1 || controle === 2 ? <p>{each.velocidade} km/h</p> : null}
              {controle === 1 || controle === 2 ? (
                <p>{renderVelocidadeMax(each.velocidadeMaxima || velocidadeFrota)}</p>
              ) : null}
              <p>
                <Button
                  variant="Link"
                  size="sm"
                  onClick={() => openMap(each.latidude, each.longitude)}
                >
                  {each.localizacao}
                </Button>
              </p>
              <p>{each.tipoViolacao || '-'}</p>
            </div>
          ))}
        </div>
      </Table>
    );
  };
  return renderTable();
};

export default memo(MainTable);
