/* eslint-disable */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import api from '../../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { useToast } from '../../../hooks/Toast';
import { getCookieSessionData } from '../../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import { ErrosDataPesquisa } from '../../EnviarPesquisa';
import { Spinner } from '../../../components/Spinner';
import getUfEndereco from '../../../util/getUfEndereco';
import { TIPOS_CONDUTOR_AJUDANTE } from '../../../constants/Condutor';
import getTipoFrota from '../../../util/Validacoes/getTipoFrota';
import { cpfMask, FormataStringData4, soNumeros } from '../../../util/Validacoes/Validacoes';
import { toZoned, obterData } from '../../../util/date';

type Props = {
  canEdit?: boolean;
};

const PesquisaCadastral = ({ canEdit }: Props) => {
  const history = useHistory();
  const location: {
    state: any;
  } = useLocation();
  const { state } = location;
  const { id }: any = useParams();
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const { addToast } = useToast();
  const [dataErros, setDataErros] = useState<ErrosDataPesquisa | undefined>({
    mensagemErro: '',
    listaErros: [
      {
        codigo: '',
        mensagem: '',
      },
    ],
  });
  const [condutor, setCondutor] = useState<any>();
  const [condutores, setCondutores] = useState<any[]>([]);
  const [ajudante, setAjudante] = useState<any>();
  const [ajudantes, setAjudantes] = useState<any[]>([]);
  const [veiculo, setVeiculo] = useState<any>();
  const [veiculos, setVeiculos] = useState<any[]>([]);
  const [carreta1, setCarreta1] = useState<any>();
  const [carretas, setCarretas] = useState<any[]>([]);
  const [carreta2, setCarreta2] = useState<any>();
  const [carretas2, setCarretas2] = useState<any[]>([]);
  const [analista, setAnalista] = useState('');
  const [dataAvaliacao, setDataAvaliacao] = useState('');
  const [idCliente, setIdCliente] = useState<number | null>();
  const [tipoPesquisa, setTipoPesquisa] = useState('');
  const [numLiberacao, setNumLiberacao] = useState('');
  const [transportadores, setTransportadores] = useState<any>([]);
  const [analistas, setAnalistas] = useState<any>([]);
  const [isDialogCadErro, setIsDialogCadErro] = useState(false);
  const [isDialogMensagem, setIsDialogMensagem] = useState(false);
  const [validacaoFormulario, setValidacaoFormulario] = useState(false);
  const [idPesquisaEnviada, setIdPesquisaEnviada] = useState();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [buscarPorCpf, setBuscarPorCpf] = useState<boolean>(false);
  const [buscarPorCpfAjudante, setBuscarPorCpfAjudante] = useState<boolean>(false);
  const [condutorInexistente, setCondutorInexistente] = useState<boolean>(false);
  const [ajudanteInexistente, setAjudanteInexistente] = useState<boolean>(false);
  const [numeroExistente, setNumeroExistente] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [itensPesquisasEnviadas, setItensPesquisasEnviadas] = useState<string[]>([]);
  const [cpfCondutor, setCpfCondutor] = useState('');
  const [cpfAjudante, setCpfAjudante] = useState('');
  const [mensagem, setMensagem] = useState('');
  const veiculoItensObrigatorio = tipoPesquisa !== 'Integrada' || !!veiculo?.id;
  const ajudanteItensObrigatorio = tipoPesquisa !== 'Integrada' || !!ajudante?.id;
  const condutorItensObrigatorio = tipoPesquisa !== 'Integrada' || !!condutor?.id;
  const carreta1ItensObrigatorio = tipoPesquisa !== 'Integrada' || !!carreta1?.id;
  const carreta2ItensObrigatorio = tipoPesquisa !== 'Integrada' || !!carreta2?.id;

  useEffect(() => {
    if (state) {
      loadData(state.idCliente);
    }
    loadTransportadoresAnalistas();
  }, []);

  useEffect(() => {
    const recuperaDados = async () => {
      if (id && state && veiculos && carretas) {
        console.log(state);
        setAnalista(state.empresaAnalista);
        setNumLiberacao(
          state.itens[0].codigoPesquisaDminerProcesso
            ? state.itens[0].codigoPesquisaDminerProcesso
            : state.itens[0].codigoPesquisaDminerVeiculo
        );
        setDataAvaliacao(obterData(state.dataLancamento, 'en2'));
        if (state.operacao?.includes('Ajudante')) {
          // PESQUISA AJUDANTE
          setTipoPesquisa('Ajudante');
          const response = await api.get(
            `/ajudante-funcionario/ajudante-por-cpf?cpf=${state.itens[0].itemPesquisado}&idCliente=${state.idCliente}`
          );
          setAjudante({
            dataValidadePesquisaManual: obterData(state.itens[0]?.validade, 'en2'),
            id: response.data?.id,
            statusPesquisaAjudante: state.itens[0]?.statusPesquisa,
          });
        }
        if (state.operacao?.includes('Integrada')) {
          // PESQUISA INTEGRADA
          setTipoPesquisa('Integrada');
          // CONDUTOR
          const cond = state.itens.find((each: any) => each.tipoPesquisa === 'CONDUTOR');
          if (cond) {
            const condResponse = await api.get(
              `/condutor/condutor-por-cpf?cpf=${cond.itemPesquisado}&idCliente=${state.idCliente}`
            );
            setCondutor({
              dataValidadePesquisaManual: obterData(cond.validade, 'en2'),
              id: condResponse.data?.id,
              statusPesquisaCondutorProcesso: cond.statusPesquisa,
              statusPesquisaCondutorCnh: cond.statusPesquisaCondutorCnh,
            });
          }
          // VEICULO
          const vei = state.itens.find((each: any) => each.tipoPesquisa === 'VEICULO');
          if (vei) {
            const veiResponse = await api.get(
              `/veiculo/veiculo-por-placa-cliente?idCliente=${state.idCliente}&placa=${vei.itemPesquisado}`
            );
            setVeiculo({
              dataValidadePesquisaManual: obterData(vei.validade, 'en2'),
              id: veiResponse.data?.id,
              statusPesquisa: vei.statusPesquisa,
            });
          }
          const car1 = state.itens.find((each: any) => each.tipoPesquisa === 'CARRETA');
          if (car1) {
            const car1Response = await api.get(
              `/veiculo/veiculo-por-placa-cliente?idCliente=${state.idCliente}&placa=${car1.itemPesquisado}`
            );
            setCarreta1({
              dataValidadePesquisaManual: obterData(car1.validade, 'en2'),
              id: car1Response.data?.id,
              statusPesquisa: car1.statusPesquisa,
            });
          }
          const car2 = state.itens.find((each: any) => each.tipoPesquisa === 'CARRETA2');
          if (car2) {
            const car2Response = await api.get(
              `/veiculo/veiculo-por-placa-cliente?idCliente=${state.idCliente}&placa=${car2.itemPesquisado}`
            );
            setCarreta2({
              dataValidadePesquisaManual: obterData(car2.validade, 'en2'),
              id: car2Response.data?.id,
              statusPesquisa: car2.statusPesquisa,
            });
          }
        }
        if (state.operacao?.includes('Condutor')) {
          // PESQUISA CONDUTOR
          setTipoPesquisa('Condutor');
          const response = await api.get(
            `/condutor/condutor-por-cpf?cpf=${state.itens[0].itemPesquisado}&idCliente=${state.idCliente}`
          );
          // console.log(state.itens[0].validade)
          // console.log(obterData(state.itens[0].validade, 'en2'))
          setCondutor({
            dataValidadePesquisaManual: obterData(state.itens[0].validade, 'en2'),
            id: response.data?.id,
            statusPesquisaCondutorProcesso: state.itens[0].statusPesquisa,
            statusPesquisaCondutorCnh: state.itens[0].statusPesquisa,
          });
        }
        if (state.operacao?.includes('Veículo')) {
          // PESQUISA VEÍCULO
          setTipoPesquisa('Veículo');
          const response = await api.get(
            `/veiculo/veiculo-por-placa-cliente?idCliente=${state.idCliente}&placa=${state.itens[0].itemPesquisado}`
          );
          setVeiculo({
            dataValidadePesquisaManual: obterData(state.itens[0].validade, 'en2'),
            id: response.data?.id,
            statusPesquisa: state.itens[0].statusPesquisa,
          });
        }
      }
    };
    recuperaDados();
  }, [id, veiculos, carretas]);

  const verificaNumeroExistente = async (numLib: any) => {
    if (numLib) {
      const response = await api.get(
        `/pesquisa/verificar-numero-liberacao?numeroLiberacao=${numLib}`
      );
      setNumeroExistente(response.data);
    } else {
      setNumeroExistente(false);
    }
  };

  const loadDadosAjudantePorCpf = async (cpf: string, cliente: any) => {
    try {
      const response = await api.get(
        `/ajudante-funcionario/ajudante-por-cpf?cpf=${cpf}&idCliente=${cliente}`
      );
      setAjudanteInexistente(false);
      console.log(response.data);
      const tipo = TIPOS_CONDUTOR_AJUDANTE?.find(
        (each) => each.id.toString() === ajudante?.tipo
      )?.name;
      setAjudante({
        ...ajudante,
        id: response.data?.id,
        nomeMae: response.data?.nomeMae,
        nomePai: response.data?.nomePai,
        nome: response.data?.nome,
        logradouro: response.data?.logradouro,
        dataNascimento: response.data?.dataNascimento,
        cpf: cpf,
        sexo: response.data?.sexo,
        bloqueado: response.data?.bloqueado,
        cidade: response.data?.nomeCidade,
        cep: response.data?.cep,
        bairro: response.data?.bairro,
        cidadeId: response.data?.cidade,
        atualizarAutomaticamente: response.data?.atualizarPesquisa,
        idAjudante: response.data?.id,
        tipo: tipo,
        numRG: response.data?.rg,
        siglaUfEmissorRG: response.data?.nomeUfEmissor,
        numeroEndereco: response.data?.logradouroNumero,
        complemento: response.data?.logradouroComplemento,
        siglaUFEndereco: response.data?.nomeUfEndereco,
        ufEnderecoId: response.data?.idUfEndereco,
      });
    } catch (err: any) {
      setAjudante({ ...ajudante, nome: '', id: null });
      console.log(err);
      setAjudanteInexistente(true);
    }
  };

  const loadDadosCondutorPorCpf = async (cpf: string, cliente: any) => {
    try {
      const response = await api.get(`/condutor/condutor-por-cpf?cpf=${cpf}&idCliente=${cliente}`);
      setCondutorInexistente(false);
      const tipo = TIPOS_CONDUTOR_AJUDANTE?.find(
        (each) => each.id.toString() === condutor?.tipo
      )?.name;
      setCpfCondutor(response.data?.cpf);
      setCondutor({
        ...condutor,
        atualizarPesquisaAutomaticamente: response.data?.atualizarPesquisaAutomaticamente,
        bairro: response.data?.bairro,
        bloqueado: response.data?.bloqueado,
        categoriaCnh: response.data?.categoriaCnh,
        cep: response.data?.cep,
        cidade: response.data?.cidade,
        cidadeNome: response.data?.nomeCidade,
        cnhRegistro: response.data?.cnhRegistro,
        complementoLogradouro: response.data?.complementoLogradouro,
        cpf: response.data?.cpf,
        dataNascimento: response.data?.dataNascimento,
        id: response.data?.id,
        sexo: response.data?.sexo,
        idUfEmissorCnh: response.data?.idUfEmissorCnh,
        idUfEmissorRg: response.data?.idUfEmissorRg,
        logradouro: response.data?.logradouro,
        logradouroNumero: response.data?.logradouroNumero,
        nome: response.data?.nome,
        nomeMae: response.data?.nomeMae,
        nomePai: response.data?.nomePai,
        rg: response.data?.rg,
        segurancaCnh: response.data?.segurancaCnh,
        siglaUFEndereco: response.data?.nomeUfEndereco,
        siglaUfEmissorCNH: response.data?.idUfEmissorCnh && getUfEndereco(condutor?.idUfEmissorCnh),
        siglaUfEmissorRG: response.data?.idUfEmissorRg && getUfEndereco(condutor?.idUfEmissorRg),
        tipo: response.data?.tipo,
        tipoEnum: tipo,
        uf: response.data?.uf,
        validadeCnh: response.data?.validadeCnh,
      });
    } catch (err: any) {
      setCondutor({ ...condutor, nome: '' });
      console.log(err);
      setCondutorInexistente(true);
    }
  };

  const loadDadosVeiculo = async (
    idVeiculo: number,
    statusPesquisa?: any,
    dataValidadePesquisaManual?: any
  ) => {
    if (!idVeiculo) {
      setVeiculo(undefined);
      return;
    }

    try {
      const response = await api.get(`/veiculo/${idVeiculo}`);
      setVeiculo({
        ...veiculo,
        anttRntrc: response.data?.anttRntrc,
        atualizacaoAutomatica: response.data?.atualizacaoAutomatica,
        chassi: response.data?.chassi,
        bloqueado: response.data?.bloqueado,
        emplacamentoUf: response.data?.emplacamentoUf,
        id: idVeiculo,
        placa: response.data?.placa,
        proprietarioId: response.data?.proprietarioId,
        renavam: response.data?.renavam,
        tipoEnum: response.data?.tipoFrota && getTipoFrota(response.data?.tipoFrota),
        veiculoTipo: response.data?.veiculoTipo,
        modelo: response.data?.modelo,
        statusPesquisa: statusPesquisa,
        dataValidadePesquisaManual: dataValidadePesquisaManual,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const loadDadosCarreta1 = async (
    idCarreta: number,
    statusPesquisa?: any,
    dataValidadePesquisaManual?: any
  ) => {
    if (!idCarreta) {
      setCarreta1(undefined);
      return;
    }
    setCarretas2(carretas.filter((e: any) => e.id != idCarreta));
    try {
      const response = await api.get(`/veiculo/${idCarreta}`);
      setCarreta1({
        ...carreta1,
        anttRntrc: response.data?.anttRntrc,
        atualizacaoAutomatica: response.data?.atualizacaoAutomatica,
        chassi: response.data?.chassi,
        emplacamentoUf: response.data?.emplacamentoUf,
        id: idCarreta,
        placa: response.data?.placa,
        proprietarioId: response.data?.proprietarioId,
        renavam: response.data?.renavam,
        tipoEnum: response.data?.tipoFrota && getTipoFrota(response.data?.tipoFrota),
        veiculoTipo: response.data?.veiculoTipo,
        statusPesquisa: statusPesquisa,
        dataValidadePesquisaManual: dataValidadePesquisaManual,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const loadDadosCarreta2 = async (
    idCarreta: number,
    statusPesquisa?: any,
    dataValidadePesquisaManual?: any
  ) => {
    if (!idCarreta) {
      setCarreta2(undefined);
      return;
    }
    try {
      const response = await api.get(`/veiculo/${idCarreta}`);
      setCarreta2({
        ...carreta2,
        anttRntrc: response.data?.anttRntrc,
        atualizacaoAutomatica: response.data?.atualizacaoAutomatica,
        chassi: response.data?.chassi,
        emplacamentoUf: response.data?.emplacamentoUf,
        id: idCarreta,
        placa: response.data?.placa,
        proprietarioId: response.data?.proprietarioId,
        renavam: response.data?.renavam,
        tipoEnum: response.data?.tipoFrota && getTipoFrota(response.data?.tipoFrota),
        veiculoTipo: response.data?.veiculoTipo,
        statusPesquisa: statusPesquisa,
        dataValidadePesquisaManual: dataValidadePesquisaManual,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const loadDadosCondutor = async (idCondutor: number) => {
    console.log(idCondutor);
    if (!idCondutor) {
      setCondutor(undefined);
      return;
    }
    try {
      const response = await api.get(`/condutor/${idCondutor}`);

      const tipo = TIPOS_CONDUTOR_AJUDANTE?.find(
        (each) => each.id.toString() === condutor?.tipo
      )?.name;
      setCpfCondutor(response.data?.cpf);
      setCondutor({
        ...condutor,
        atualizarPesquisaAutomaticamente: response.data?.atualizarPesquisaAutomaticamente,
        bairro: response.data?.bairro,
        bloqueado: response.data?.bloqueado,
        categoriaCnh: response.data?.categoriaCnh,
        cep: response.data?.cep,
        cidade: response.data?.cidade,
        cidadeNome: response.data?.nomeCidade,
        cnhRegistro: response.data?.cnhRegistro,
        complementoLogradouro: response.data?.complementoLogradouro,
        cpf: response.data?.cpf,
        dataNascimento: response.data?.dataNascimento,
        id: response.data?.id,
        sexo: response.data?.sexo,
        idUfEmissorCnh: response.data?.idUfEmissorCnh,
        idUfEmissorRg: response.data?.idUfEmissorRg,
        logradouro: response.data?.logradouro,
        logradouroNumero: response.data?.logradouroNumero,
        nome: response.data?.nome,
        nomeMae: response.data?.nomeMae,
        nomePai: response.data?.nomePai,
        rg: response.data?.rg,
        segurancaCnh: response.data?.segurancaCnh,
        siglaUFEndereco: response.data?.nomeUfEndereco,
        siglaUfEmissorCNH: getUfEndereco(condutor?.idUfEmissorCnh),
        siglaUfEmissorRG: getUfEndereco(condutor?.idUfEmissorRg),
        tipo: response.data?.tipo,
        tipoEnum: tipo,
        uf: response.data?.uf,
        validadeCnh: response.data?.validadeCnh,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const loadDadosFuncionario = async (idFuncionario: number) => {
    if (!idFuncionario) {
      setAjudante(undefined);
      return;
    }
    try {
      const response = await api.get(`/ajudante-funcionario/${idFuncionario}`);

      const tipo = TIPOS_CONDUTOR_AJUDANTE?.find(
        (each) => each.id.toString() === ajudante?.tipo
      )?.name;
      setCpfAjudante(response.data?.cpf);
      setAjudante({
        ...ajudante,
        id: idFuncionario,
        nomeMae: response.data?.nomeMae,
        nomePai: response.data?.nomePai,
        nome: response.data?.nome,
        logradouro: response.data?.logradouro,
        dataNascimento: response.data?.dataNascimento,
        cpf: response.data?.cpf,
        sexo: response.data?.sexo,
        bloqueado: response.data?.bloqueado,
        cidade: response.data?.nomeCidade,
        cep: response.data?.cep,
        bairro: response.data?.bairro,
        cidadeId: response.data?.cidade,
        atualizarAutomaticamente: response.data?.atualizarPesquisa,
        idAjudante: response.data?.id,
        tipo: tipo,
        numRG: response.data?.rg,
        siglaUfEmissorRG: response.data?.nomeUfEmissor,
        numeroEndereco: response.data?.logradouroNumero,
        complemento: response.data?.logradouroComplemento,
        siglaUFEndereco: response.data?.nomeUfEndereco,
        ufEnderecoId: response.data?.idUfEndereco,
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const checaBlacklist = async (pessoa: any, tipo: string) => {
    try {
      if (pessoa.cpf && pessoa.nome) {
        const response = await api.get(`/blacklist/verificarCpf?cpf=${pessoa.cpf}`);
        return response.data
          ? `${tipo === 'Condutor' ? (pessoa.sexo === 'F' ? 'Condutora' : 'Condutor') : 'Ajudante'
          } ${pessoa.nome} encontra-se ${pessoa.sexo === 'F' ? 'bloqueada' : 'bloqueado'
          }. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02).`
          : 'não consta';
      }
    } catch (error) {
      console.log(error);
      return 'não consta';
    }
  };

  const resetForm = () => {
    setCondutor(undefined);
    setVeiculo(undefined);
    setCarreta1(undefined);
    setCarreta2(undefined);
  };

  const resetFormTodo = () => {
    setCondutor(undefined);
    setVeiculo(undefined);
    setCarreta1(undefined);
    setCarreta2(undefined);
    setTipoPesquisa('');
    setDataAvaliacao('');
    setNumLiberacao('');
    setNumeroExistente(false);
    setIdCliente(null);
    setAnalista('');
    setValidacaoFormulario(false);
  };

  const enviar = async (e: any) => {
    e.preventDefault();

    const carreta1Existe = !!(
      carreta1?.id &&
      carreta1?.statusPesquisa &&
      carreta1?.dataValidadePesquisaManual
    );
    const carreta2Existe = !!(
      carreta2?.id &&
      carreta2?.statusPesquisa &&
      carreta2?.dataValidadePesquisaManual
    );
    const veiculoExiste = !!(
      veiculo?.id &&
      veiculo?.statusPesquisa &&
      veiculo?.dataValidadePesquisaManual
    );
    const ajudanteExiste = !!(
      ajudante?.id &&
      ajudante?.statusPesquisaAjudante &&
      ajudante?.dataValidadePesquisaManual
    );
    const condutorExiste = !!(
      condutor?.id &&
      condutor?.statusPesquisaCondutorProcesso &&
      condutor?.dataValidadePesquisaManual
    );
    const arrayItens = [condutorExiste, veiculoExiste, carreta1Existe, carreta2Existe];
    if (
      !analista ||
      !dataAvaliacao ||
      !idCliente ||
      !numLiberacao ||
      !tipoPesquisa ||
      numeroExistente ||
      (tipoPesquisa === 'Condutor' && !condutorExiste) ||
      (tipoPesquisa === 'Veículo' && !veiculoExiste) ||
      (tipoPesquisa === 'Ajudante' && !ajudanteExiste) ||
      (tipoPesquisa === 'Carreta' && !carreta1Existe)
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os campos obrigatórios',
        type: 'error',
      });
      setValidacaoFormulario(true);
      e.stopPropagation();
      return;
    } else if (tipoPesquisa === 'Integrada') {
      if (
        (condutor?.id &&
          (!condutor?.statusPesquisaCondutorProcesso || !condutor?.dataValidadePesquisaManual)) ||
        (veiculo?.id && (!veiculo?.statusPesquisa || !veiculo?.dataValidadePesquisaManual)) ||
        (carreta2?.id && (!carreta2?.statusPesquisa || !carreta2?.dataValidadePesquisaManual)) ||
        (carreta1?.id && (!carreta1?.statusPesquisa || !carreta1?.dataValidadePesquisaManual))
      ) {
        addToast({
          title: 'Erro',
          description: 'Verifique os campos obrigatórios',
          type: 'error',
        });
        setValidacaoFormulario(true);
        e.stopPropagation();
        return;
      }
      if (arrayItens.filter((e: any) => e === true).length < 2) {
        console.log(arrayItens.filter((e: any) => e === true).length);
        addToast({
          title: 'Erro',
          description: '2 itens ou mais são obrigatórios para Pesquisa Integrada',
          type: 'error',
        });
        setValidacaoFormulario(true);
        e.stopPropagation();
        return;
      }
    }

    if (condutorExiste) {
      const constaBlacklist = await checaBlacklist(condutor, 'Condutor');
      if (!constaBlacklist?.includes('não consta') && constaBlacklist != undefined) {
        setMensagem(constaBlacklist ? constaBlacklist : '');
        console.log(constaBlacklist);
        setIsDialogMensagem(true);
        e.stopPropagation();
        return;
      }
      if (condutor?.bloqueado) {
        console.log('bloqueado');
        setMensagem(
          `${condutor?.sexo === 'F' ? 'Condutora ' : 'Condutor '} ${condutor?.nome} está ${condutor?.sexo === 'F' ? 'bloqueada' : 'bloqueado'
          }.`
        );
        setIsDialogMensagem(true);
        e.stopPropagation();
        return;
      }
    }
    if (ajudanteExiste) {
      const constaBlacklist = await checaBlacklist(ajudante, 'Ajudante');
      if (!constaBlacklist?.includes('não consta') && constaBlacklist != undefined) {
        setMensagem(constaBlacklist ? constaBlacklist : '');
        setIsDialogMensagem(true);
        e.stopPropagation();
        return;
      }
      if (ajudante?.bloqueado) {
        setMensagem(
          `Ajudante ${ajudante?.nome} está ${ajudante?.sexo === 'F' ? 'bloqueada' : 'bloqueado'}.`
        );
        setIsDialogMensagem(true);
        e.stopPropagation();
        return;
      }
    }
    if (id && state) {
      console.log('editar');
      handlePut();
    } else {
      handlePost();
    }
  };

  const handlePut = async () => {
    console.log('put');
    try {
      const data2 = {
        ajudante: ajudante?.id
          ? {
            idAjudante: ajudante?.id,
            dataCadastro: toZoned(dataAvaliacao),
            statusPesquisa: ajudante.statusPesquisaAjudante,
            dataValidade: toZoned(ajudante.dataValidadePesquisaManual),
          }
          : null,
        carreta: carreta1?.id
          ? {
            dataCadastro: toZoned(dataAvaliacao),
            idCarreta: carreta1.id,
            dataValidade: toZoned(carreta1.dataValidadePesquisaManual),
          }
          : null,
        carreta2: carreta2?.id
          ? {
            idCarreta2: carreta2.id,
            dataCadastro: toZoned(dataAvaliacao),
            dataValidade: toZoned(carreta2.dataValidadePesquisaManual),
          }
          : null,
        condutor: condutor?.id
          ? {
            idCondutor: condutor.id,
            statusPesquisa: condutor.statusPesquisaCondutorCnh,
            dataCadastro: toZoned(dataAvaliacao),
            dataValidade: toZoned(condutor.dataValidadePesquisaManual),
          }
          : null,
        empresaAnalitica: analista,
        numeroLiberacao: numLiberacao,
        veiculo: veiculo?.id
          ? {
            idVeiculo: veiculo.id,
            dataCadastro: toZoned(dataAvaliacao),
            dataValidade: toZoned(veiculo.dataValidadePesquisaManual),
            statusPesquisa: veiculo.statusPesquisa,
          }
          : null,
      };
      const response = await api.put(
        `/pesquisa/atualizar-pesquisa-manual?idDp=${state.numLiberacao}`,
        data2
      );
      setMensagem('Pesquisa atualizada com sucesso!');
      setIsDialogMensagem(true);
      console.log(response.data);
    } catch (error: any) {
      setMensagem('Erro ao atualizar pesquisa');
      setIsDialogMensagem(true);
      console.log(error);
    }
  };

  const handlePost = () => {
    const array = [];

    if (tipoPesquisa === 'Condutor') {
      console.log({
        idCliente: idCliente,
        idUsuario: idUsuario,
        condutor: {
          ...condutor,
          codigoSolicitacaoCnh: numLiberacao,
          codigoSolicitacaoProcesso: numLiberacao,
          dataCadastroPesquisaManual: toZoned(dataAvaliacao),
        },
      });
      array.push(`${condutor?.nome} ${condutor?.cpf}`);
      setItensPesquisasEnviadas(array);
      handleRequest(
        {
          idCliente: idCliente,
          idUsuario: idUsuario,
          condutor: {
            ...condutor,
            codigoSolicitacaoCnh: numLiberacao,
            codigoSolicitacaoProcesso: numLiberacao,
            dataCadastroPesquisaManual: toZoned(dataAvaliacao),
            dataValidadePesquisaManual: toZoned(condutor?.dataValidadePesquisaManual),
          },
        },
        'este condutor'
      );
    }
    if (tipoPesquisa === 'Ajudante') {
      array.push(`${ajudante?.nome} ${ajudante?.cpf}`);
      setItensPesquisasEnviadas(array);
      handleRequest(
        {
          idCliente: idCliente,
          idUsuario: idUsuario,
          ajudante: {
            ...ajudante,
            codigoSolicitacao: numLiberacao,
            dataCadastroPesquisaManual: toZoned(dataAvaliacao),
            dataValidadePesquisaManual: toZoned(ajudante?.dataValidadePesquisaManual),
          },
        },
        'este ajudante'
      );
    }
    if (tipoPesquisa === 'Veículo') {
      array.push(`${veiculo?.placa} ${veiculo?.modelo}`);
      setItensPesquisasEnviadas(array);
      handleRequest(
        {
          idCliente: idCliente,
          idUsuario: idUsuario,
          veiculo: {
            ...veiculo,
            codigoSolicitacao: numLiberacao,
            dataCadastroPesquisaManual: toZoned(dataAvaliacao),
            dataValidadePesquisaManual: toZoned(veiculo?.dataValidadePesquisaManual),
          },
        },
        'este veículo'
      );
    }
    if (tipoPesquisa === 'Carreta') {
      array.push(`${carreta1?.placa} ${carreta1?.modelo ? carreta1?.modelo : ''}`);
      setItensPesquisasEnviadas(array);
      handleRequest(
        {
          idCliente: idCliente,
          idUsuario: idUsuario,
          carreta1: {
            ...carreta1,
            codigoSolicitacao: numLiberacao,
            dataCadastroPesquisaManual: toZoned(dataAvaliacao),
            dataValidadePesquisaManual: toZoned(carreta1?.dataValidadePesquisaManual),
          },
        },
        'esta carreta'
      );
    }
    if (tipoPesquisa === 'Integrada') {
      if (condutor?.id) {
        array.push(`${condutor?.nome} ${condutor?.cpf}`);
      }
      if (veiculo?.id) {
        array.push(`${veiculo?.placa} ${veiculo?.modelo ? veiculo?.modelo : ''}`);
      }
      if (carreta1?.id) {
        array.push(`${carreta1?.placa} ${carreta1?.modelo ? carreta1?.modelo : ''}`);
      }
      if (carreta2?.id) {
        array.push(`${carreta2?.placa} ${carreta2?.modelo ? carreta2?.modelo : ''}`);
      }
      setItensPesquisasEnviadas(array);
      handleRequest(
        {
          idCliente: idCliente,
          idUsuario: idUsuario,
          condutor: condutor?.id
            ? {
              ...condutor,
              codigoSolicitacaoCnh: numLiberacao,
              codigoSolicitacaoProcesso: numLiberacao,
              dataCadastroPesquisaManual: toZoned(dataAvaliacao),
              dataValidadePesquisaManual: toZoned(condutor?.dataValidadePesquisaManual),
            }
            : null,
          veiculo: veiculo?.id
            ? {
              ...veiculo,
              codigoSolicitacao: numLiberacao,
              dataCadastroPesquisaManual: toZoned(dataAvaliacao),
              dataValidadePesquisaManual: toZoned(veiculo?.dataValidadePesquisaManual),
            }
            : null,
          carreta1: carreta1?.id
            ? {
              ...carreta1,
              codigoSolicitacao: numLiberacao,
              dataCadastroPesquisaManual: toZoned(dataAvaliacao),
              dataValidadePesquisaManual: toZoned(carreta1?.dataValidadePesquisaManual),
            }
            : null,
          carreta2: carreta2?.id
            ? {
              ...carreta2,
              codigoSolicitacao: numLiberacao,
              dataCadastroPesquisaManual: toZoned(dataAvaliacao),
              dataValidadePesquisaManual: toZoned(carreta2?.dataValidadePesquisaManual),
            }
            : null,
        },
        'esta carreta'
      );
    }
  };

  const handleRequest = async (data: any, message: string) => {
    setLoading(true);
    try {
      const response = await api.post('/pesquisa/integrada', {
        ...data,
        pesquisaManual: true,
        cadastrarPesquisaNova: false,
        empresaAnalista: analista,
      });
      if (
        !response.data.mensagem?.includes('sucesso') &&
        !response.data.mensagemAjudante?.includes('sucesso') &&
        !response.data.mensagemCondutor?.includes('sucesso') &&
        !response.data.mensagemVeiculo?.includes('sucesso') &&
        !response.data.mensagemCarreta?.includes('sucesso') &&
        !response.data.mensagemCarreta2?.includes('sucesso')
      ) {
        setLoading(false);
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: response.data.mensagem?.includes('processamento')
            ? `já existe uma pesquisa em andamento para ${message}`
            : response.data.mensagem ||
            response.data.mensagemVeiculo ||
            response.data.mensagemCarreta ||
            response.data.mensagemCarreta2 ||
            response.data.mensagemCondutor ||
            response.data.mensagemAjudante,
          listaErros:
            response.data.errosVeiculo ||
            response.data.errosCarreta ||
            response.data.errosCarreta2 ||
            response.data.errosCondutor ||
            response.data.errosAjudante,
        });
      } else {
        setIdPesquisaEnviada(response.data.codPesquisaIntegrada);
        setIsDialogCadOK(true);
      }
    } catch (error: any) {
      if (
        error.response.data.message?.includes('processamento') ||
        error.response.data.message?.includes('Constam resultados')
      ) {
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: `já existe uma pesquisa em andamento para ${message}`,
          listaErros: null as any,
        });
      } else {
        addToast({
          title: 'Erro!',
          description: 'Erro no envio da pesquisa. Tente novamente',
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    loadTransportadoresAnalistas();
  }, []);

  const loadTransportadoresAnalistas = async () => {
    const transportadores = await api.get(`/cliente/getIdRazaoSocial`);
    setTransportadores(transportadores.data.filter((e: any) => e.ativo));
    const analistas = await api.get(
      `/pesquisa-ajudante-condutor-veiculo/lista-responsavel-analise-perfil`
    );
    setAnalistas(analistas.data.filter((e: any) => e.rapBlAtivo));
  };

  const loadData = async (idCliente: number) => {
    setIdCliente(idCliente);

    const condutores = await api.get(`/condutor/listarCondutorNome?cliente=${idCliente}`);
    setCondutores(condutores.data);
    const ajudantes = await api.get(
      `/ajudante-funcionario/listarAjudanteNome?cliente=${idCliente}`
    );
    setAjudantes(ajudantes.data);
    const veiculos = await api.get(
      `/veiculo/listarIdEPlaca?cliente=${idCliente}&grupoMacroVe%C3%ADculo=1`
    );
    setVeiculos(veiculos.data);
    const carretas = await api.get(
      `/veiculo/listarIdEPlaca?cliente=${idCliente}&grupoMacroVe%C3%ADculo=2`
    );
    setCarretas(carretas.data);
  };

  const situacoes = [
    { situacao: 'Em acordo', value: 'EM_ACORDO' },
    { situacao: 'Em desacordo', value: 'PENDENTE_DE_DOCUMENTACAO' },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Análise de Perfil</h2>
          {id ? (
            <h6 className="subtitulo">
              {'Análise de Perfil > Pesquisa Cadastral > Editar Lançamento'}
            </h6>
          ) : (
            <h6 className="subtitulo">{'Análise de Perfil > Pesquisa Cadastral > Lançamento'}</h6>
          )}
          {/* <h1 className="info-obrigatorio">*Informações obrigatórias</h1> */}
        </div>
        <Dialog
          header={
            <>
              {dataErros?.mensagemErro === null ? (
                <p>{`Pesquisa não enviada. `}</p>
              ) : (
                <p>{`Não foi possível enviar pesquisa, pois ${dataErros?.mensagemErro}. `}</p>
              )}
              {dataErros?.listaErros ? (
                <>
                  {dataErros.listaErros.map((each) => (
                    <li style={{ fontSize: '15px' }}>{each.mensagem}</li>
                  ))}
                </>
              ) : null}
            </>
          }
          closable={false}
          footer={
            <>
              <Button
                onClick={() => {
                  setIsDialogCadErro(false);
                }}
              >
                OK
              </Button>
            </>
          }
          visible={isDialogCadErro}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadErro(false);
          }}
        />
        <Dialog
          header={
            <>
              <p>{mensagem}</p>
            </>
          }
          closable={false}
          footer={
            <>
              <Button
                onClick={() => {
                  setIsDialogMensagem(false);
                  if (mensagem?.includes('sucesso')) {
                    history.goBack();
                  }
                }}
              >
                OK
              </Button>
            </>
          }
          visible={isDialogMensagem}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogMensagem(false);
          }}
        />
        <Dialog
          header={
            <>
              <p>Pesquisa manual cadastrada com sucesso para:</p>
              {itensPesquisasEnviadas ? (
                <>
                  {itensPesquisasEnviadas.map((each: string) => (
                    <li style={{ fontSize: '15px' }}>{each}</li>
                  ))}
                </>
              ) : null}
            </>
          }
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Número de liberação: {idPesquisaEnviada}</p>
              <Button
                onClick={() => {
                  resetFormTodo();
                  setIsDialogCadOK(false);
                }}
              >
                OK
              </Button>
            </div>
          }
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            resetFormTodo();
            setIsDialogCadOK(false);
          }}
        />
        {loading ? (
          <Spinner />
        ) : (
          <Form noValidate validated={validacaoFormulario} onSubmit={enviar}>
            <h6 style={{ marginLeft: 15 }}> Dados da solicitação</h6>
            <Form.Row>
              <Form.Group as={Col} className="ml-4 mt-3">
                <Form.Label className="required">Transportador</Form.Label>
                <Dropdown
                  style={{ width: '100%' }}
                  value={idCliente}
                  options={transportadores}
                  onChange={(e: any) => {
                    loadData(e.target.value);
                    if (e.target.value === undefined) {
                      resetForm();
                    }
                  }}
                  optionLabel="razaoSocial"
                  optionValue="id"
                  filter
                  filterBy="razaoSocial"
                  placeholder="Selecione"
                  className={validacaoFormulario && !idCliente ? 'erro-dropdown' : ''}
                  showClear
                  required
                  disabled={!!id}
                />
              </Form.Group>
              <Form.Group as={Col} className="ml-4 mt-3">
                <Form.Label className="required">Empresa Analista</Form.Label>
                <Dropdown
                  style={{ width: '100%' }}
                  className={validacaoFormulario && !analista ? 'erro-dropdown' : ''}
                  value={analista}
                  options={analistas}
                  onChange={(e: any) => {
                    setAnalista(e.target.value);
                  }}
                  optionLabel="rapTxNome"
                  optionValue="rapTxNome"
                  filter
                  filterBy="rapTxNome"
                  placeholder="Selecione"
                  // className="p-dropdown"
                  showClear
                  disabled={!idCliente}
                  required
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="ml-4 mt-3">
                <Form.Label className="required">N° liberação</Form.Label>
                <Form.Control
                  value={numLiberacao}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setNumLiberacao(soNumeros(e.target.value));
                    verificaNumeroExistente(soNumeros(e.target.value));
                  }}
                  disabled={!idCliente}
                  required
                />
                {numeroExistente && (
                  <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                    N° liberação ja existente.
                  </p>
                )}
              </Form.Group>
              <Form.Group as={Col} className="ml-4 mt-3">
                <Form.Label className="required">Data Avaliação</Form.Label>
                <Form.Control
                  value={dataAvaliacao}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDataAvaliacao(e.target.value);
                  }}
                  type="date"
                  // max={new Date().toISOString()}
                  disabled={!idCliente}
                  required
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} className="ml-4 mt-3">
                <Form.Label className="required">Tipo de pesquisa</Form.Label>
                <Form.Control
                  value={tipoPesquisa}
                  onChange={(e: any) => {
                    resetForm();
                    setTipoPesquisa(e.target.value);
                    console.log(e.target.value);
                  }}
                  id="analista"
                  name="analista"
                  as="select"
                  disabled={!idCliente || !!id}
                  required
                >
                  <option value="">Selecione</option>
                  <option value="Condutor">Condutor</option>
                  <option value="Ajudante">Ajudante</option>
                  <option value="Veículo">Veículo</option>
                  <option value="Carreta">Carreta</option>
                  <option value="Integrada">Integrada</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>
            {tipoPesquisa != '' && (
              <h6 style={{ marginLeft: 15 }}> Dados da pesquisa {tipoPesquisa}</h6>
            )}

            {(tipoPesquisa === 'Integrada' || tipoPesquisa === 'Condutor') && (
              <>
                <Form.Row>
                  {buscarPorCpf ? (
                    <>
                      <Form.Group as={Col} className="ml-4 mt-3">
                        <Form.Label className={tipoPesquisa === 'Integrada' ? '' : 'required'}>
                          CPF Condutor
                        </Form.Label>
                        <Form.Control
                          value={cpfMask(cpfCondutor)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setCpfCondutor(e.target.value);
                            if (e.target.value?.length === 14) {
                              loadDadosCondutorPorCpf(e.target.value, idCliente);
                            }
                          }}
                          disabled={!idCliente || !!id}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="ml-4 mt-3">
                        <Form.Label className="required">Nome </Form.Label>
                        <Form.Control value={condutor?.nome} disabled required />
                      </Form.Group>
                    </>
                  ) : (
                    <Form.Group as={Col} className="ml-4 mt-3">
                      <Form.Label className={tipoPesquisa === 'Integrada' ? '' : 'required'}>
                        Condutor
                      </Form.Label>
                      <Dropdown
                        style={{ width: '100%' }}
                        value={condutor?.id}
                        options={condutores}
                        onChange={(e: any) => {
                          loadDadosCondutor(e.target.value);
                        }}
                        optionLabel="nome"
                        optionValue="id"
                        filter
                        filterBy="nome"
                        placeholder="Selecione"
                        className={
                          tipoPesquisa !== 'Integrada' && validacaoFormulario && !condutor?.id
                            ? 'erro-dropdown'
                            : ''
                        }
                        showClear
                        required
                        disabled={!!id}
                      />
                    </Form.Group>
                  )}
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={condutorItensObrigatorio ? 'required' : ''}>
                      Situação
                    </Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={condutor?.statusPesquisaCondutorProcesso}
                      options={situacoes}
                      onChange={(e: any) => {
                        setCondutor({
                          ...condutor,
                          statusPesquisaCondutorProcesso: e.target.value,
                          statusPesquisaCondutorCnh: e.target.value,
                        });
                      }}
                      optionLabel="situacao"
                      optionValue="value"
                      placeholder="Selecione"
                      className={
                        condutorItensObrigatorio &&
                          validacaoFormulario &&
                          !condutor?.statusPesquisaCondutorProcesso
                          ? 'erro-dropdown'
                          : ''
                      }
                      disabled={!!id && !condutor?.id}
                      required={condutorItensObrigatorio}
                      showClear
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={condutorItensObrigatorio ? 'required' : ''}>
                      Vigência
                    </Form.Label>
                    <Form.Control
                      value={condutor?.dataValidadePesquisaManual}
                      onChange={(e: any) => {
                        setCondutor({ ...condutor, dataValidadePesquisaManual: e.target.value });
                      }}
                      disabled={!!id && !condutor?.id}
                      type="date"
                      required={condutorItensObrigatorio}
                    />
                  </Form.Group>
                </Form.Row>
                {condutorInexistente && buscarPorCpf && (
                  <p style={{ fontSize: 12, color: 'red', marginLeft: 20 }}>
                    Condutor não encontrado
                  </p>
                )}
                <Form.Group as={Col} className="mt-2 ml-2">
                  <Switch
                    value={Number(buscarPorCpf)}
                    checked={buscarPorCpf}
                    onChange={() => setBuscarPorCpf(!buscarPorCpf)}
                    type="switch"
                    name="buscarPorCpf"
                    id="buscarPorCpf"
                    label="Buscar por CPF"
                    disabled={!!id}
                  />
                </Form.Group>
              </>
            )}
            {tipoPesquisa === 'Ajudante' && (
              <>
                <Form.Row>
                  {buscarPorCpfAjudante ? (
                    <>
                      <Form.Group as={Col} className="ml-4 mt-3">
                        <Form.Label className="required">CPF Ajudante</Form.Label>
                        <Form.Control
                          value={cpfMask(cpfAjudante)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setCpfAjudante(e.target.value);
                            if (e.target.value?.length === 14) {
                              loadDadosAjudantePorCpf(e.target.value, idCliente);
                            }
                          }}
                          disabled={!idCliente || !!id}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="ml-4 mt-3">
                        <Form.Label className="required">Nome </Form.Label>
                        <Form.Control value={ajudante?.nome} disabled required />
                      </Form.Group>
                    </>
                  ) : (
                    <Form.Group as={Col} className="ml-4 mt-3">
                      <Form.Label className="required">Ajudante</Form.Label>
                      <Dropdown
                        style={{ width: '100%' }}
                        value={ajudante?.id}
                        options={ajudantes}
                        onChange={(e: any) => {
                          loadDadosFuncionario(e.target.value);
                        }}
                        optionLabel="nome"
                        optionValue="id"
                        filter
                        filterBy="nome"
                        placeholder="Selecione"
                        className={validacaoFormulario && !ajudante?.id ? 'erro-dropdown' : ''}
                        showClear
                        required
                        disabled={!!id}
                      />
                    </Form.Group>
                  )}

                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className="required">Situação</Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={ajudante?.statusPesquisaAjudante}
                      options={situacoes}
                      onChange={(e: any) => {
                        setAjudante({ ...ajudante!, statusPesquisaAjudante: e.target.value });
                      }}
                      className={
                        ajudanteItensObrigatorio &&
                          validacaoFormulario &&
                          !ajudante?.statusPesquisaAjudante
                          ? 'erro-dropdown'
                          : ''
                      }
                      optionLabel="situacao"
                      optionValue="value"
                      placeholder="Selecione"
                      required
                      showClear
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className="required">Vigência</Form.Label>
                    <Form.Control
                      value={ajudante?.dataValidadePesquisaManual}
                      onChange={(e: any) => {
                        setAjudante({ ...ajudante, dataValidadePesquisaManual: e.target.value });
                      }}
                      type="date"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                {ajudanteInexistente && buscarPorCpfAjudante && (
                  <p style={{ fontSize: 12, color: 'red', marginLeft: 20 }}>
                    Ajudante não encontrado
                  </p>
                )}
                <Form.Group as={Col} className="mt-2 ml-2">
                  <Switch
                    value={Number(buscarPorCpfAjudante)}
                    checked={buscarPorCpfAjudante}
                    onChange={() => setBuscarPorCpfAjudante(!buscarPorCpfAjudante)}
                    type="switch"
                    name="buscarPorCpfAjudante"
                    id="buscarPorCpfAjudante"
                    label="Buscar por CPF"
                    disabled={!!id}
                  />
                </Form.Group>
              </>
            )}
            {(tipoPesquisa === 'Integrada' || tipoPesquisa === 'Veículo') && (
              <>
                <Form.Row>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={tipoPesquisa === 'Integrada' ? '' : 'required'}>
                      Veículo
                    </Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={veiculo?.id}
                      options={veiculos}
                      onChange={(e: any) => {
                        loadDadosVeiculo(e.target.value);
                      }}
                      optionLabel="placa"
                      optionValue="id"
                      filter
                      filterBy="placa"
                      className={
                        tipoPesquisa !== 'Integrada' && validacaoFormulario && !veiculo?.id
                          ? 'erro-dropdown'
                          : ''
                      }
                      placeholder="Selecione"
                      required={tipoPesquisa !== 'Integrada'}
                      showClear
                      disabled={!!id}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={veiculoItensObrigatorio ? 'required' : ''}>
                      Situação
                    </Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={veiculo?.statusPesquisa}
                      options={situacoes}
                      onChange={(e: any) => {
                        setVeiculo({ ...veiculo, statusPesquisa: e.target.value });
                      }}
                      optionLabel="situacao"
                      optionValue="value"
                      required={veiculoItensObrigatorio}
                      placeholder="Selecione"
                      className={
                        veiculoItensObrigatorio && validacaoFormulario && !veiculo?.statusPesquisa
                          ? 'erro-dropdown'
                          : ''
                      }
                      disabled={!!id && !veiculo?.id}
                      showClear
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={veiculoItensObrigatorio ? 'required' : ''}>
                      Vigência
                    </Form.Label>
                    <Form.Control
                      value={veiculo?.dataValidadePesquisaManual}
                      onChange={(e: any) => {
                        setVeiculo({ ...veiculo, dataValidadePesquisaManual: e.target.value });
                      }}
                      type="date"
                      required={veiculoItensObrigatorio}
                      disabled={!!id && !veiculo?.id}
                    />
                  </Form.Group>
                </Form.Row>
              </>
            )}

            {(tipoPesquisa === 'Integrada' || tipoPesquisa === 'Carreta') && (
              <>
                <Form.Row>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={tipoPesquisa === 'Integrada' ? '' : 'required'}>
                      Carreta
                    </Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={carreta1?.id}
                      options={carretas}
                      onChange={(e: any) => {
                        loadDadosCarreta1(e.target.value);
                      }}
                      optionLabel="placa"
                      optionValue="id"
                      filter
                      filterBy="placa"
                      className={
                        tipoPesquisa !== 'Integrada' && validacaoFormulario && !carreta1?.id
                          ? 'erro-dropdown'
                          : ''
                      }
                      placeholder="Selecione"
                      required={tipoPesquisa !== 'Integrada'}
                      showClear
                      disabled={!!id}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={carreta1ItensObrigatorio ? 'required' : ''}>
                      Situação
                    </Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={carreta1?.statusPesquisa}
                      options={situacoes}
                      onChange={(e: any) => {
                        setCarreta1({ ...carreta1, statusPesquisa: e.target.value });
                      }}
                      optionLabel="situacao"
                      optionValue="value"
                      placeholder="Selecione"
                      required={carreta1ItensObrigatorio}
                      className={
                        carreta1ItensObrigatorio && validacaoFormulario && !carreta1?.statusPesquisa
                          ? 'erro-dropdown'
                          : ''
                      }
                      disabled={!!id && !carreta1?.id}
                      showClear
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={carreta1ItensObrigatorio ? 'required' : ''}>
                      Vigência
                    </Form.Label>
                    <Form.Control
                      value={carreta1?.dataValidadePesquisaManual}
                      onChange={(e: any) => {
                        setCarreta1({ ...carreta1, dataValidadePesquisaManual: e.target.value });
                      }}
                      type="date"
                      required={carreta1ItensObrigatorio}
                      disabled={!!id && !carreta1?.id}
                    />
                  </Form.Group>
                </Form.Row>
              </>
            )}
            {tipoPesquisa === 'Integrada' && (
              <>
                <Form.Row>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label>Carreta(2)</Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={carreta2?.id}
                      options={id ? carretas : carretas2}
                      onChange={(e: any) => {
                        loadDadosCarreta2(e.target.value);
                      }}
                      optionLabel="placa"
                      optionValue="id"
                      filter
                      filterBy="placa"
                      placeholder="Selecione"
                      showClear
                      disabled={!!id && !carreta2?.id}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={carreta2ItensObrigatorio ? 'required' : ''}>
                      Situação
                    </Form.Label>
                    <Dropdown
                      style={{ width: '100%' }}
                      value={carreta2?.statusPesquisa}
                      options={situacoes}
                      onChange={(e: any) => {
                        setCarreta2({ ...carreta2, statusPesquisa: e.target.value });
                      }}
                      optionLabel="situacao"
                      optionValue="value"
                      placeholder="Selecione"
                      className={
                        carreta2ItensObrigatorio && validacaoFormulario && !carreta2?.statusPesquisa
                          ? 'erro-dropdown'
                          : ''
                      }
                      showClear
                      required={carreta2ItensObrigatorio}
                      disabled={!!id && !carreta2?.id}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="ml-4 mt-3">
                    <Form.Label className={carreta2ItensObrigatorio ? 'required' : ''}>
                      Vigência
                    </Form.Label>
                    <Form.Control
                      value={carreta2?.dataValidadePesquisaManual}
                      onChange={(e: any) => {
                        setCarreta2({ ...carreta2, dataValidadePesquisaManual: e.target.value });
                      }}
                      type="date"
                      disabled={!!id && !carreta2?.id}
                      required={carreta2ItensObrigatorio}
                    />
                  </Form.Group>
                </Form.Row>
              </>
            )}

            <ButtonsForm canEdit={canEdit && id} />
          </Form>
        )}
      </div>
    </div>
  );
};

export default PesquisaCadastral;
