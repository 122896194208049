import { FC } from 'react';
import { Form } from 'react-bootstrap';
import Input from '../../../components/Input';
import { ListagemHistoricoPesquisa } from './index';
import { obterDataHora } from '../../../util/date';

type Props = {
  solicitacao: ListagemHistoricoPesquisa;
};

const DadosSolicitacao: FC<Props> = ({ solicitacao }) => {
  console.log(solicitacao);
  return (
    <>
      <Form.Label className="SMLancadasTitle">Dados da Solicitacão</Form.Label>
      <Form.Row>
        <Input
          required
          value={solicitacao.empresa}
          onChange={() => false}
          id="empresa"
          name="empresa"
          textInputTitle="Cliente:"
          disabled
        />
      </Form.Row>
      <Form.Row>
        <Input
          required
          value={solicitacao.numLiberacao}
          onChange={() => false}
          id="numLiberacao"
          name="numLiberacao"
          textInputTitle="Nº Liberação:"
          disabled
        />
        <Input
          required
          value={
            solicitacao.dataLancamento === null
              ? ''
              : `${obterDataHora(solicitacao.dataLancamento)} ${solicitacao.usuario}`
          }
          onChange={() => false}
          id="dataLancamento"
          name="dataLancamento"
          textInputTitle="Lançamento:"
          disabled
        />
      </Form.Row>
    </>
  );
};

export default DadosSolicitacao;
