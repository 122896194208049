/* eslint-disable */
import React, { FC, useState, useEffect, memo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import { Botao } from './styles';
import { Dialog } from 'primereact/dialog';
import { Spinner } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import axios from '../../../config/axiosMaquina';
import Select from '../../../components/Select';
import { useToast } from '../../../hooks/Toast';

type GrupoMacroVeiculosType = {
  vgmCdId: number;
  vgmTxNome: string;
  vgmBlAtivo?: boolean;
};

type Props = {
  canInsert?: boolean;
};

const ModalCadastro = ({ canInsert }: Props) => {
  const { addToast } = useToast();
  const [show, setShow] = useState<boolean>(false);
  const [nome, setNome] = useState('');
  const [hasError, setHasError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [grupo_macro_de_veiculos, setGrupo_macro_de_veiculos] = useState<any>();
  const [grupoMacrodeVeiculos, setGrupoMacrodeVeiculos] = useState<GrupoMacroVeiculosType[]>([]);
  const [tipoVeiculoId, setTipoVeiculoId] = useState<any>([]);
  const [tipoVeiculoList, setTiposVeiculoList] = useState<any[]>([]);
  const [isDialogVisibleCadNaoEfetuado, setIsDialogVisibleCadNaoEfetuado] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const fetchData = async () => {
    setLoading(true);
    try {
      const _grupoMacro = await axios.get('/grupo-macro-veiculo');
      setGrupoMacrodeVeiculos(_grupoMacro.data.filter((d: any) => d.vgmBlAtivo));
      const _tipoVeiculo = await axios.get('/tipo-veiculo/grid-tipo-veiculo');
      setTiposVeiculoList(_tipoVeiculo.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchData();
    }
  }, [show]);

  const selectTipos = tipoVeiculoList.map((tipo) => ({ name: tipo.vtpTxNome, code: tipo.vtpCdId }));

  const cadastrarGrupodeVeiculos = async (event: any) => {
    event.preventDefault();
    const novoNome = nome.trim();
    if (novoNome.length <= 0 || !grupo_macro_de_veiculos) {
      setNome(novoNome);
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setValidated(false);
      event.stopPropagation();
      return;
    }
    const array: any[] = [];
    tipoVeiculoId.map((t: any) => array.push(t.code));
    const dados = {
      nome: nome,
      veiculoGrupoMacro: grupo_macro_de_veiculos,
      tipoVeiculo: array,
    };
    setLoading(true);
    try {
      await axios.post(`/grupo-tipo-veiculo/veiculotipogrupo/`, dados);
      setIsDialogVisibleCadEfetuado(true);
    } catch (error: any) {
      setIsDialogVisibleCadNaoEfetuado(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function back() {
    history.go(0);
  }
  return (
    <>
      <i
        style={{
          fontSize: '1.2em',
          margin: '25px 25px 15px 10px',
          cursor: 'pointer',
        }}
        className="pi pi-plus-circle icone_adicionar"
        onClick={handleShow}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header style={{ alignSelf: 'center' }}>
          <h2 className="titulo">Grupo de Veículos</h2>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center', width: '90%' }}>
          <Dialog
            header="Grupo de veículos foi cadastrado com sucesso!"
            footer={
              <>
                <Button onClick={() => back()}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />
          <Dialog
            header="Erro ao cadastrar, verifique os campos!"
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadNaoEfetuado(false)}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadNaoEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadNaoEfetuado(false)}
          />
          {loading ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <Form noValidate validated={validated}>
              <Form.Row>
                <Form.Group as={Col} sm={12}>
                  <Form.Label className="requiredField">NOME</Form.Label>
                  <Form.Control
                    value={nome}
                    onChange={(event: any) => setNome(event.target.value)}
                    name="nome"
                    type="text"
                    required
                    title="Preencha com o nome do grupo de veículos "
                    maxLength={256}
                    style={{ marginBottom: 15 }}
                  />
                  <Select
                    style={{
                      marginBottom: 10,
                      borderColor:
                        grupo_macro_de_veiculos === undefined && validated ? '#dc3545' : '',
                    }}
                    required={true}
                    value={grupo_macro_de_veiculos}
                    textInputTitle="GRUPO MACRO DE VEÍCULOS"
                    onChange={(e: any) => setGrupo_macro_de_veiculos(e.target.value)}
                  >
                    <option value="">Selecione o grupo macro</option>
                    {grupoMacrodeVeiculos.map((tipo, index) => (
                      <option key={index} value={tipo.vgmCdId}>
                        {tipo.vgmTxNome}
                      </option>
                    ))}
                  </Select>
                  <Form.Label>Tipo de Veículo</Form.Label>
                  <MultiSelect
                    style={{
                      width: '100%',
                      lineHeight: 1.2,
                      marginBottom: 15,
                      borderColor: tipoVeiculoId.length <= 0 && validated ? '#dc3545' : '',
                    }}
                    emptyFilterMessage={<Button></Button>}
                    display="chip"
                    optionLabel="name"
                    value={tipoVeiculoId}
                    options={selectTipos}
                    onChange={(e: any) => setTipoVeiculoId(e.target.value)}
                    filter
                  />
                </Form.Group>
                <Botao className="container-buttons justify-content-between">
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-cancelar"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-enviar"
                      type="button"
                      onClick={(e: any) => {
                        if (canInsert) {
                          cadastrarGrupodeVeiculos(e);
                        } else {
                          history.push('/acessonegado');
                        }
                      }}
                    >
                      Confirmar
                    </Button>
                  </Form.Group>
                </Botao>
              </Form.Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCadastro;
