/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Col, Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { orderBy, uniqBy } from 'lodash';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { soNumeros } from '../../util/Validacoes/Validacoes';
import { dataConverter } from '../../util/date';

type UsuarioPerfilType = {
  nome: string;
  ativo: boolean;
  id?: number;
};

type FuncionalidadeType = {
  consulta: boolean;
  dataCadastroAssociacao: string | Date;
  delete: boolean;
  idMenuGrupo: number;
  idUsuCadastroAssociacao: number;
  insert: boolean;
  update: boolean;
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

export const CadastroPerfisUsuarios = ({ canEdit, canDelete }: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingChecagem, setLoadingChecagem] = useState(false);
  const [show, setShow] = useState(false);
  const [header, setHeader] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [fullData, setFullData] = useState<any[]>([]);
  const [fullLista, setFullLista] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>();
  const [usuarioPerfil, setUsuarioPerfil] = useState<UsuarioPerfilType>({
    nome: '',
    ativo: true,
  });
  const { addToast } = useToast();
  const { id }: any = useParams();
  const { role, usuarioId, isEmbarcador, clienteId } = getCookieSessionData().usuarioVO;
  const history = useHistory();
  const [expandedKeys, setExpandedKeys] = useState({});
  const [nomesIguais, setNomesIguais] = useState(false);

  const expandNode = (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const expandAll = (d: any) => {
    let _expandedKeys = {};
    for (let node of d) {
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  // SE FOR EDIÇÃO
  const findById = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/menu-perfil/${id}`);
      setUsuarioPerfil({
        ...response.data,
        nome: response.data.nomeMenu,
        role: role,
      });

      const list = response.data.listaMenuPerfilFuncionalidade;
      let newSelected = {};
      for (let i = 0; i < list.length; i += 1) {
        const idConsulta = `${list[i].idMenuGrupo}:Consulta`;
        const idDelete = `${list[i].idMenuGrupo}:Exclusao`;
        const idUpdate = `${list[i].idMenuGrupo}:Edicao`;
        const idInsert = `${list[i].idMenuGrupo}:Inclusao`;
        const childrenPreenchido = list.filter((e: any) => e.idPai === list[i].idMenuGrupo);
        const childrenOriginal = fullData.filter((f: any) => f.idPai === list[i].idMenuGrupo);
        const childrenPrenChecked = childrenPreenchido.filter(
          (g: any) => g.insert && g.consulta && g.update && g.delete
        );
        const checked =
          childrenOriginal.length > 0 &&
          childrenPreenchido.length > 0 &&
          childrenOriginal.length === childrenPrenChecked.length;
        const partialChecked =
          childrenOriginal.length > 0 &&
          childrenPreenchido.length > 0 &&
          !(childrenOriginal.length === childrenPrenChecked.length);

        if (list[i]) {
          if (childrenOriginal.length > 0) {
            newSelected = {
              ...newSelected,
              [list[i].idMenuGrupo]: {
                checked: checked,
                partialChecked: partialChecked,
              },
            };
          } else {
            if (list[i].insert && list[i].update && list[i].delete && list[i].consulta) {
              newSelected = {
                ...newSelected,
                [list[i].idMenuGrupo]: {
                  checked: true,
                  partialChecked: false,
                },
              };
            } else if (list[i].insert || list[i].update || list[i].delete || list[i].consulta) {
              newSelected = {
                ...newSelected,
                [list[i].idMenuGrupo]: {
                  checked: false,
                  partialChecked: true,
                },
              };
            } else {
              newSelected = {
                ...newSelected,
                [list[i].idMenuGrupo]: {
                  checked: false,
                  partialChecked: false,
                },
              };
            }
          }
          newSelected = {
            ...newSelected,
            [idDelete]: {
              checked: list[i].delete,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].consulta,
            },
            [idUpdate]: {
              checked: list[i].update,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].consulta,
            },
            [idInsert]: {
              checked: list[i].insert,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].consulta,
            },
            [idConsulta]: {
              checked: list[i].consulta,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].consulta,
            },
          };
        }
      }

      setSelected(newSelected);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && fullData.length) {
      findById();
    }
  }, [id, fullData]);

  const confereNome = async (nome: String) => {
    setLoadingChecagem(true);
    try {
      const response = await api.get(
        `/usuario/consulta-existencia-menu-perfil?idCliente=${
          role === 'admin' ? '' : clienteId
        }&nomeMenuPerfil=${nome}&role=${role}`
      );
      setNomesIguais(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoadingChecagem(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (nomesIguais) {
      addToast({
        title: 'Erro',
        description: 'Nome de Perfil já existe',
        type: 'error',
      });
      return;
    }
    setLoading(true);
    // FORMATA ESTRUTURA DO ARRAY PARA ENVIAR NO ENDPOINT
    const listaMenu: FuncionalidadeType[] = [];
    if (selected) {
      const keys = Object.entries(selected);
      const array = keys.map((each: any) => {
        return { id: each[0], value: each[1].checked };
      });
      for (let i = 0; i < array.length; i += 1) {
        if (
          !array[i].id.includes(':') &&
          array.find((each: any) => each.id.includes(`${array[i].id}:`))
        ) {
          listaMenu.push({
            dataCadastroAssociacao: dataConverter(new Date().toISOString()),
            delete: array.find((each: any) => each.id === `${array[i].id}:Exclusao`)
              ? array.find((each: any) => each.id === `${array[i].id}:Exclusao`)?.value
              : false,
            insert: array.find((each: any) => each.id === `${array[i].id}:Inclusao`)
              ? array.find((each: any) => each.id === `${array[i].id}:Inclusao`)?.value
              : false,
            consulta: array.find((each: any) => each.id === `${array[i].id}:Consulta`)
              ? array.find((each: any) => each.id === `${array[i].id}:Consulta`)?.value
              : false,
            idMenuGrupo: parseInt(array[i].id, 10),
            update: array.find((each: any) => each.id === `${array[i].id}:Edicao`)
              ? array.find((each: any) => each.id === `${array[i].id}:Edicao`)?.value
              : false,
            idUsuCadastroAssociacao: usuarioId,
            // isEmbarcador: usuarioPerfil.role === 'embarcador',
          });
        } else if (
          !listaMenu.find(
            (f: FuncionalidadeType) => f.idMenuGrupo === parseInt(soNumeros(array[i].id), 10)
          )
        ) {
          listaMenu.push({
            dataCadastroAssociacao: dataConverter(new Date().toISOString()),
            delete: false,
            insert: false,
            consulta: false,
            idMenuGrupo: parseInt(soNumeros(array[i].id), 10),
            update: false,
            idUsuCadastroAssociacao: usuarioId,
          });
        }
      }
    }
    const d = {
      ativo: true,
      dataCadastro: dataConverter(new Date().toISOString()),
      listaMenuPerfilFuncionalidade: listaMenu,
      idUsuarioCadastro: usuarioId,
      nomeMenu: usuarioPerfil.nome,
    };
    try {
      let response: any = {};
      if (id) {
        response = await api.put(`/menu-perfil/${id}`, d);
        setHeader('Editado com sucesso!');
        setLoading(false);
      } else {
        response = await api.post('/menu-perfil', d);
        setHeader('Cadastrado com sucesso!');
        setLoading(false);
      }
      setIsDialogCadOK(true);
    } catch (error) {
      setLoading(false);

      addToast({
        title: 'Erro',
        description: `Erro ao ${id ? 'Editar' : 'Cadastrar'}`,
        type: 'error',
      });
      console.log(error);
    }
  };

  const converteArray = (dataL: any) => {
    // FORMATA ESTRUTURA DO ARRAY PARA USAR NA ÁRVORE
    const array: any[] = [];
    dataL.forEach((each: any) => {
      const filhos = dataL.filter((e: any) => e.idPai === each.id);
      if (!each.idPai) {
        if (filhos.length) {
          array.push({
            key: each.id,
            label: each.nomeMenu,
            children: filhos.map((e: any) => {
              const netos = dataL.filter((f: any) => f.idPai === e.id);
              if (netos.length) {
                return {
                  key: e.id,
                  label: e.nomeMenu,
                  children: netos.map((g: any) => {
                    const bisnetos = dataL.filter((f: any) => f.idPai === g.id);
                    if (bisnetos.length) {
                      return {
                        key: g.id,
                        label: g.nomeMenu,
                        children: bisnetos.map((h: any) => {
                          return {
                            key: h.id,
                            label: h.nomeMenu,
                            children: [
                              { key: `${h.id}:Consulta`, label: 'Consulta' },
                              { key: `${h.id}:Inclusao`, label: 'Inclusão' },
                              { key: `${h.id}:Edicao`, label: 'Edição' },
                              { key: `${h.id}:Exclusao`, label: 'Exclusão' },
                            ],
                          };
                        }),
                      };
                    }
                    return {
                      key: g.id,
                      label: g.nomeMenu,
                      children: [
                        { key: `${g.id}:Consulta`, label: 'Consulta' },
                        { key: `${g.id}:Inclusao`, label: 'Inclusão' },
                        { key: `${g.id}:Edicao`, label: 'Edição' },
                        { key: `${g.id}:Exclusao`, label: 'Exclusão' },
                      ],
                    };
                  }),
                };
              }
              return {
                key: e.id,
                label: e.nomeMenu,
                children: [
                  { key: `${e.id}:Consulta`, label: 'Consulta' },
                  { key: `${e.id}:Inclusao`, label: 'Inclusão' },
                  { key: `${e.id}:Edicao`, label: 'Edição' },
                  { key: `${e.id}:Exclusao`, label: 'Exclusão' },
                ],
              };
            }),
          });
        } else {
          array.push({
            key: each.id,
            label: each.nomeMenu,
            children: [
              { key: `${each.id}:Consulta`, label: 'Consulta' },
              { key: `${each.id}:Inclusao`, label: 'Inclusão' },
              { key: `${each.id}:Edicao`, label: 'Edição' },
              { key: `${each.id}:Exclusao`, label: 'Exclusão' },
            ],
          });
        }
      }
    });
    return array;
  };

  const handleData = async () => {
    try {
      const response = await api.get('/menu-grupo/list-com-embarcador');
      const comEmbarcador = response.data.map((each: any) => {
        return {
          ...each,
          roleUsuarioPermitido: each.isEmbarcador ? 'embarcador' : each.roleUsuarioPermitido,
        };
      });
      setFullLista(uniqBy(comEmbarcador, 'id'));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (fullLista.length) {
      let listaPorRole: any[] = [];
      listaPorRole = orderBy(
        fullLista.filter((each: any) =>
          isEmbarcador === true
            ? each.roleUsuarioPermitido === 'embarcador'
            : each.roleUsuarioPermitido === role
        ),
        'id'
      );
      setFullData(listaPorRole);
      setData(converteArray(listaPorRole));
      if (id) {
        expandAll(converteArray(listaPorRole));
      }
    }
  }, [fullLista]);

  const handleDelete = async () => {
    try {
      await api.put(`/menu-perfil/delete-logico?id=${id}`);
      setShow(false);
      setHeader('Cadastrado com sucesso!');
      setIsDialogCadOK(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        header={header}
        footer={
          <Button
            onClick={() => {
              setIsDialogCadOK(false);
              history.push('/perfis-usuarios/listar/1');
            }}
          >
            Ok
          </Button>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
          history.push('/perfis-usuarios/listar/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro {` "${usuarioPerfil.nome}" `}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setShow(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={handleDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
      <Tela loading={loading} setLoading={setLoading} onClickDelete={setShow} canDelete={canDelete}>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} className="ml-4 mt-3">
              <Form.Label className="required">Nome do Perfil</Form.Label>
              <Form.Control
                value={usuarioPerfil.nome}
                onChange={(e: any) => {
                  setUsuarioPerfil({ ...usuarioPerfil, nome: e.target.value });
                  confereNome(e.target.value);
                }}
                type="text"
                maxLength={80}
                required
              />
              {nomesIguais && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome de Perfil já existe
                </p>
              )}
            </Form.Group>
          </Form.Row>
          {data && (
            <Tree
              expandedKeys={expandedKeys}
              onToggle={(e) => setExpandedKeys(e.value)}
              style={{ marginLeft: '23px', marginTop: '30px' }}
              value={data}
              selectionMode="checkbox"
              selectionKeys={selected}
              onSelectionChange={(e) => {
                setSelected(e.value);
              }}
            />
          )}
          <ButtonsForm canEdit={canEdit} disabled={loadingChecagem} />
        </Form>
      </Tela>
    </>
  );
};
