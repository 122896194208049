/* eslint-disable */
import React, { ChangeEvent, useState, useEffect } from 'react';
import { BinResponse } from '../../../api/model/BauIntercambiavelType';
import axios from '../../../../src/config/axiosMaquina';
import { useHistory, useParams } from 'react-router-dom';
import './styles.css';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { semCaracterEspecial, soNumeros } from '../../../util/Validacoes/Validacoes';
import { useToast } from '../../../hooks/Toast';

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const BinEdit = ({ canEdit, canDelete }: Props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { id }: any = useParams();
  const { addToast } = useToast();
  const history = useHistory();

  const [isCubagem, setIsCubagem] = useState(true);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const [model, setModel] = useState<BinResponse>({
    id: 0,
    numeroBau: '',
    modelo: '',
    statusAtivo: true,
    capacidadeCarga: 0,
    cubagem: 0,
    qtdeViagens: 0,
    valorMedioTransportado: 0,
    tipoBau: '',
  });

  useEffect(() => {
    axios
      .get(`/bau-intercambiavel/${id}`)
      .then((response) => {
        setModel({
          id: response.data.id,
          numeroBau: response.data.numeroBau,
          modelo: response.data.modelo,
          statusAtivo: response.data.statusAtivo,
          capacidadeCarga: response.data.capacidadeCarga,
          cubagem: response.data.cubagem,
          qtdeViagens: response.data.qtdeViagens,
          valorMedioTransportado: response.data.valorMedioTransportado,
          tipoBau: response.data.tipoBau,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    setModel({
      ...model,
      [e.target.name]: parseInt(soNumeros(e.target.value)),
    });
  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!model.numeroBau || !model.modelo) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      e.stopPropagation();
      return;
    }
    try {
      await axios.put(`/bau-intercambiavel/${id}`, model);
      setIsDialogVisibleCadEfetuado(true);
      //alert('Atualização realizada com sucesso.');
      //history.push('/bau-intercambiavel/1');
    } catch {
      // const alerta = alert('Erro ao Atualizar');
      addToast({
        title: 'Erro!',
        description: 'Erro ao Atualizar.',
        type: 'error',
      });
      return;
    }
  }

  function back() {
    history.goBack();
  }

  async function deleteFunc(id: number) {
    try {
      await axios.delete(`/bau-intercambiavel/${id}`);
      // alert('Baú Intercambiável desativado com sucesso');
      addToast({
        title: 'Sucesso!',
        description: 'Baú Intercambiável desativado com sucesso.',
        type: 'success',
      });
      history.push('/bau-intercambiavel/1');
    } catch (alert) {
      ('Erro ao Desativar Cadastro');
    }
    handleClose();
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header="Baú foi alterado com sucesso!"
        footer={
          <>
            <Button onClick={() => setIsDialogVisibleCadEfetuado(false)}>OK</Button>
          </>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.goBack()}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${model.modelo}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => deleteFunc(model.id)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Baú Intercambiável</h2>
          <h6 className="subtitulo">{'Cadastro > Baú Intercambiável'}</h6>

          <button
            className="btn-desativar"
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              position: 'absolute',
              right: '2%',
            }}
            onClick={() => (canDelete ? handleShow() : history.push('/acessonegado'))}
          >
            <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
          </button>
        </div>
        <div className="painel">
          <Form
            noValidate
            validated={isFormInvalid}
            className="form-responsive"
            onSubmit={onSubmit}
          >
            <Form.Row>
              <Form.Group as={Col} controlId="formGridbinTxNumeroBau">
                <Form.Label className="required">NÚMERO BAÚ</Form.Label>
                <Form.Control
                  value={model.numeroBau}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: semCaracterEspecial(e.target.value),
                    });
                  }}
                  name="numeroBau"
                  maxLength={64}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridbinTxTipoFrota">
                <Form.Label className="require">TIPO BAÚ</Form.Label>
                <Form.Control
                  value={model.tipoBau}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  name="tipoBau"
                  as="select"
                  placeholder=""
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">PRÓPRIO</option>
                  <option value="2">AGREGADO</option>
                  <option value="3">TERCEIRO</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridbinTxModelo">
                <Form.Label className="required">MODELO</Form.Label>
                <Form.Control
                  value={model.modelo}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: semCaracterEspecial(e.target.value),
                    });
                  }}
                  maxLength={60}
                  name="modelo"
                  required
                  type="text"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridbinNuCapacidadeCarga">
                <Form.Label>CAPACIDADE DE CARGA</Form.Label>
                <Form.Control
                  value={model.capacidadeCarga === 0 ? undefined : model.capacidadeCarga}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: e.target.value.length === 0 ? 0 : soNumeros(e.target.value),
                    });
                  }}
                  name="capacidadeCarga"
                  type="text"
                  maxLength={10}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridbinNuCubagem">
                <Form.Label>CUBAGEM</Form.Label>
                <Form.Control
                  value={model.cubagem === 0 ? undefined : model.cubagem}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: e.target.value.length === 0 ? 0 : soNumeros(e.target.value),
                    });
                    if (soNumeros(e.target.value)) {
                      setIsCubagem(true);
                    } else {
                      setIsCubagem(false);
                    }
                  }}
                  name="cubagem"
                  type="text"
                  maxLength={10}
                />
                {!isCubagem && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Apenas Números</p>
                )}
              </Form.Group>
            </Form.Row>
            <ButtonsForm canEdit={canEdit} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BinEdit;
