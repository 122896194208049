/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { useHistory, useParams } from 'react-router-dom';
import SolicitacaoMonitoramento from '../../components/SolicitacaoMonitoramento';
import { UsuarioType } from '../../types';
import { SMEmAndamentoType } from '../../api/model/Veiculos/SMEmAndamentoType';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';
import { BauDTO, DispositivoDTO } from '../../Types/AssistenciaTecnica';
import { PreCancelamentoType } from '../../pages/IniciarSMPendente/index';

type Props = {
  canEdit?: boolean;
};

function EditarSMEmAndamento({ canEdit }: Props) {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();
  const [smData, setSmData] = useState<SMEmAndamentoType | undefined>();
  const [dadosCancelamento, setDadosCancelamento] = useState<PreCancelamentoType>({
    idUsuario: 0,
    motivoCancelamento: 0,
    idSm: 0,
    dataCancelamento: '',
    nomeUsuario: '',
    solicitanteCancelamento: '',
  });
  const [usuario, setUsuario] = useState<UsuarioType | undefined>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const usuarioData = getCookieSessionData().usuarioVO;
    setUsuario(usuarioData);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/solicitacao-monitoramento/listardadossmandamento?solicitacaoMonitoramento=${id}`
        );
        setSmData(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!smData) {
        return;
      }

      await axios.put(
        `/solicitacao-monitoramento/atualizardadossmandamento?solicitacaoMonitoramento=${smData.id}`,
        {
          valorCarga: smData.valorCarga,
          descricaoCarga: smData.descricaoCarga,
        }
      );
      setLoading(false);
      addToast({
        title: 'Sucesso!',
        description: 'Atualização efetuada com sucesso.',
        type: 'success',
      });

      setTimeout(function () {
        history.push('/home');
      }, 1500);
    } catch (err: any) {
      setLoading(false);
      addToast({
        title: 'Erro!',
        description: 'Erro ao editar Solicitação de Monitoramento.',
        type: 'error',
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação de Monitoramento</h2>
          <h6 className="subtitulo">{'Cadastro > SM > Visualização'}</h6>
        </div>

        <div className="content-container"></div>
        <div className="painel">
          {smData ? (
            <SolicitacaoMonitoramento
              smData={smData}
              setSmData={setSmData}
              editValorDescricaoCarga
              isAdmin={isAllowedByRole(['admin'])}
              usuarioLogado={usuario}
              onSubmit={handleSubmit}
              loading={loading}
              dadosCancelamento={dadosCancelamento}
              canEdit={canEdit}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default EditarSMEmAndamento;
