/* eslint-disable */
import React from 'react';
import { AiFillClockCircle } from 'react-icons/ai';
import { FaClipboardList, FaTemperatureHigh } from 'react-icons/fa';
import { IoMdSpeedometer } from 'react-icons/io';
import { RiAlertFill, RiTruckFill } from 'react-icons/ri';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { IoMdClock } from 'react-icons/io';
import { CardContainer, CardTitle, CardQty } from './styles';

export interface AlertProps {
  color?: string;
  name?: string;
  height?: number;
  data: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  icon?: JSX.Element;
}

export function CardInformativo({ data, onClick, color, height, icon, name }: AlertProps) {
  const renderIcon = () => {
    if (icon) {
      return icon;
    } else if (Math.sign(data) === -1) {
      return <MdOutlineArrowDropDown size="40px" color="#E03B24" />;
    } else if (Math.sign(data) === 1) {
      return <MdOutlineArrowDropUp size="40px" color="#64A338" />;
    } else {
      return <BsDot size="40px" color="#0075c9" />;
    }
  };

  return (
    <CardContainer
      style={{ borderBottom: `5px solid ${color}`, cursor: `${onClick ? 'pointer' : 'normal'}` }}
      onClick={onClick}
    >
      <div className="cardPosition">
        <div className="cardAdjustment" style={{ height: `${height}px` }}>
          <CardQty>
            {name?.includes('relação') ? (Math.sign(data) === 1 ? '+' + data : data) : data}
          </CardQty>
          {renderIcon()}
        </div>
        <CardTitle>{name}</CardTitle>
      </div>
    </CardContainer>
  );
}
