import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { cpfMask, FormataStringData2, maskTel } from '../../util/Validacoes/Validacoes';
import { FiltroCondutor } from './ConsultarCondutor';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';

type ListagemConsulta = {
  atualizaDadosCondutor: boolean;
  cpfCondutor: string;
  idCondutor: number;
  nomeCondutor: string;
  razaoSocialCliente: string;
  tipoCondutor: string;
  bloqueado: boolean;
  cnh: string;
  segurancaCnh: string;
  categoria: string;
  validadeCnh: string;
  rg: string;
  ufRg: string;
  orgaoEmissor: string;
  nomePai: string;
  nomeMae: string;
  dataNascimento: string;
  cidade: string;
  ufCidade: string;
  logradouro: string;
  numeroLogradouro: string;
  tel1: string;
  tel2: string;
  telReferencia: string;
  bairro: string;
  complemento: string;
};

type Props = {
  canDelete: boolean;
};

const ListagemConsultaCondutor = ({ canDelete }: Props) => {
  const history = useHistory();
  const [nomeCondutor, setNomeCondutor] = useState<string | undefined>();
  const [idCondutor, setIdCondutor] = useState<number | undefined>();
  const location: {
    state: { listagem: ListagemConsulta[]; model: FiltroCondutor };
  } = useLocation();
  const { state } = location;
  const [searchTerm, setSearchTerm] = useState('');
  const [edicaoAcessada, setEdicaoAcessada] = useState(false);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = [
    '+id',
    'Cliente',
    'CPF',
    'Nome',
    'Tipo',
    'Bloqueado',
    'Atualizar Pesquisa Automaticamente',
  ];

  const paginate = (pageNumber: any) => setCurrentPage(Number(pageNumber));

  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>([
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Condutores',
          widthPx: 1060,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Empresa', width: { wch: 30 } },
        { title: 'Condutor', width: { wch: 30 } },
        { title: 'CPF', width: { wch: 15 } },
        { title: 'Tipo', width: { wch: 10 } },
        { title: 'CNH Registro', width: { wch: 20 } },
        { title: 'CNH Segurança', width: { wch: 20 } },
        { title: 'Categoria', width: { wch: 5 } },
        { title: 'Validade', width: { wch: 15 } },
        { title: 'RG nº', width: { wch: 20 } },
        { title: 'RG UF', width: { wch: 5 } },
        { title: 'Órgão', width: { wch: 40 } },
        { title: 'Pai', width: { wch: 30 } },
        { title: 'Mãe', width: { wch: 30 } },
        { title: 'Nascimento', width: { wch: 15 } },
        { title: 'Cidade', width: { wch: 20 } },
        { title: 'UF', width: { wch: 5 } },
        { title: 'Logradouro', width: { wch: 30 } },
        { title: 'Telefone(1)', width: { wch: 15 } },
        { title: 'Telefone(2)', width: { wch: 15 } },
        { title: 'Referência(1)', width: { wch: 15 } },
        { title: 'Bloqueado' },
        { title: 'Atualização Automática', width: { wch: 20 } },
      ],
      data: state.listagem.map((each) => [
        { value: each.razaoSocialCliente ? each.razaoSocialCliente : '' },
        { value: each.nomeCondutor ? each.nomeCondutor : '' },
        { value: each.cpfCondutor.includes('-') ? each.cpfCondutor : cpfMask(each.cpfCondutor) },
        { value: each.tipoCondutor ? each.tipoCondutor : '' },
        { value: each.cnh ? each.cnh : '' },
        { value: each.segurancaCnh ? each.segurancaCnh : '' },
        { value: each.categoria ? each.categoria : '' },
        { value: each.validadeCnh ? FormataStringData2(each.validadeCnh) : '' },
        { value: each.rg ? each.rg : '' },
        { value: each.ufRg ? each.ufRg : '' },
        { value: each.orgaoEmissor ? each.orgaoEmissor : '' },
        { value: each.nomePai ? each.nomePai : '' },
        { value: each.nomeMae ? each.nomeMae : '' },
        { value: each.dataNascimento ? FormataStringData2(each.dataNascimento) : '' },
        { value: each.cidade ? each.cidade : '' },
        { value: each.ufCidade ? each.ufCidade : '' },
        {
          value: `${each.logradouro}, ${each.numeroLogradouro ? each.numeroLogradouro : ''} ${
            each.bairro ? each.bairro : ''
          }, ${each.complemento ? each.complemento : ''}, ${each.cidade} - ${each.ufCidade}`,
        },
        { value: each.tel1.includes('(') ? each.tel1 : maskTel(each.tel1) },
        { value: each.tel2.includes('(') ? each.tel2 : maskTel(each.tel2) },
        {
          value: each.telReferencia.includes('(')
            ? each.telReferencia
            : maskTel(each.telReferencia),
        },
        { value: each.bloqueado === true ? 'Sim' : 'Não' },
        { value: each.atualizaDadosCondutor === true ? 'Sim' : 'Não' },
      ]),
    },
  ]);

  const handleDelete = async (id: number | undefined) => {
    try {
      const response = await axios.put(`/condutor/delete-logico/${id}`);
      console.log(response);
      setExclusaoOK(true);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      setMensagemErro('Erro ao excluir!');
    }
  };

  const handleObject = (listagem: any[]) => {
    const array = listagem.map((each: ListagemConsulta) => {
      return [
        each.idCondutor,
        each.razaoSocialCliente,
        each.cpfCondutor,
        each.nomeCondutor,
        each.tipoCondutor,
        each.bloqueado ? 'Sim' : 'Não',
        each.atualizaDadosCondutor ? 'Sim' : 'Não',
      ];
    });
    return array;
  };

  useEffect(() => {
    setDataTabela(handleObject(state.listagem));
    const ajudante = state.listagem
      ?.filter((aju) => aju.idCondutor === idCondutor)
      .find((each) => each.nomeCondutor);
    console.log(ajudante);
    setNomeCondutor(ajudante?.nomeCondutor);
  }, [state, idCondutor]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(dataTabela, searchTerm));
    }
  }, [searchTerm]);

  useEffect(() => {
    const reloadDados = async () => {
      try {
        const response = await axios.get(`/condutor/consulta-condutor-gerenciar`, {
          params: {
            ...state.model,
          },
        });
        setDataTabela(handleObject(response.data));
      } catch (err: any) {
        console.log(err.response);
      }
    };
    reloadDados();
  }, [edicaoAcessada]);

  return (
    <Tela
      nome="Condutor"
      caminho="Cadastro > Gerenciamento de Clientes > Condutor"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchTerm}
      search={search}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={currentPage.toString()}
      excelGeneration={excelGeneration}
      filename="Condutor"
    >
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {!exclusaoOK && !exclusaoErro && (
            <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
          )}
          {exclusaoOK && <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>}
        </Modal.Header>

        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro: {`${nomeCondutor}`}?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Registro excluído com sucesso.
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{`${mensagemErro}`}</Modal.Body>
        )}

        {!exclusaoErro && !exclusaoErro && !exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={handleClose}
              label="Não"
            />
            <Button
              style={{ width: '120px', height: '50px' }}
              variant="secondary"
              onClick={() => handleDelete(idCondutor)}
              label="Sim"
            />
          </Modal.Footer>
        )}
        {exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={() => {
                window.location.reload();
              }}
              label="OK"
            />
          </Modal.Footer>
        )}
      </Modal>
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          left
          onClickEditar={(id: any) => {
            setEdicaoAcessada(true);
            history.push({
              pathname: `/condutores/editar/${id}`,
            });
          }}
          onClickExcluir={(id: any) => {
            if (canDelete) {
              setExclusaoErro(false);
              setExclusaoOK(false);
              handleShow();
              setIdCondutor(id);
            } else {
              history.push('/acessonegado');
            }
          }}
        />
      )}
    </Tela>
  );
};

export default ListagemConsultaCondutor;
