/* eslint-disable */
import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import Logo from '../../assets/logo_melhorresol.png';
import { ReactComponent as MapImage } from '../../assets/undraw_map_1r69.svg';
import './styles.css';
import { authContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/esm/Modal';
import { getCookieSessionData, loginUser } from '../../services/cookieService';
import { doLogin } from '../../services/cookieService';
import { noArroba } from '../../util/Validacoes/Validacoes';
import { Spinner } from 'react-bootstrap';

const LoginParceiros: React.FC = () => {
  const { auth, setAuth } = useContext(authContext);
  const [login, setLogin] = useState<string>('');
  const [codigo, setCodigo] = useState<number | null>(null);
  const [senha, setSenha] = useState<string>('');
  const [role, setRole] = useState<string>('user');
  const [notShow, setNotShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const messages = useRef<Toast>();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogin = useCallback(() => {
    setLoading(true);
    loginUser(login, senha, role, codigo)
      .then((resultAuthResponse) => {
        setAuth(resultAuthResponse);
        doLogin();
      })
      .catch((error) => {
        if (messages.current) {
          // messages.current.show({
          //   severity: 'error',
          //   summary: 'Success Message',
          //   detail: error.message,
          // });
          handleShow();
          setLoading(false);
        }
      });
  }, [login, senha, role, codigo]);

  useEffect(() => {
    const listener = (event: globalThis.KeyboardEvent) => {
      // if ( event.code === 'Enter' ) {
      // if ( event.code === 'Enter' || ((key===13) && (e.location===3)) ) {
      if (event.keyCode === 13) {
        handleLogin();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [login, senha, role, codigo, handleLogin]);

  return (
    <div className="p-grid screen-container">
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Usuário ou Senha incorretos!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="p-col screen-left-container">
        <img src={Logo} className="logo-image" />
        <div className="container-title">
          <div className="login-title">Seja bem-vindo à Golden Service!</div>

          <div className="line"></div>
        </div>

        <Toast
          ref={(el) => {
            if (el) messages.current = el;
          }}
        />

        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              marginLeft: '47.5%',
              marginRight: '20%',
              marginTop: '10%',
            }}
          />
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                marginLeft: '20%',
                marginRight: '20%',
                marginTop: '5%',
              }}
            >
              <div
                style={{
                  marginInline: '5%',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="cb1" className="role-text">
                  Corretora
                </label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <RadioButton
                    inputId="cb1"
                    name="tipoLogin"
                    value="corretora"
                    onChange={(e) => {
                      setRole(e.value);
                      setNotShow(false);
                    }}
                    checked={role === 'corretora'}
                  />
                </div>
              </div>
              <div
                style={{
                  marginInline: '5%',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="cb1" className="role-text">
                  Seguradora
                </label>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
                  <RadioButton
                    inputId="cb1"
                    name="tipoLogin"
                    value="seguradora"
                    onChange={(e) => {
                      setRole(e.value);
                      setNotShow(false);
                    }}
                    checked={role === 'seguradora'}
                  />
                </div>
              </div>
            </div>
            {notShow === true ? (
              <></>
            ) : (
              <>
                <label htmlFor="codigo" className="codigo-text">
                  Código:
                </label>
                <div>
                  <InputText
                    style={{
                      background: '#FFFFFF0F',
                      color: '#fff',
                      marginLeft: '25%',
                    }}
                    className="codigo-box"
                    id="codigo-login"
                    type="number"
                    disabled={role !== 'admin' ? false : true}
                    value={codigo === null ? '' : codigo}
                    onChange={(e) => setCodigo(e.currentTarget.valueAsNumber)}
                  />
                </div>
              </>
            )}

            <label htmlFor="user" className="user-text">
              Usuário:
            </label>
            <div>
              <InputText
                style={{
                  background: '#FFFFFF0F',
                  color: '#fff',
                  marginLeft: '25%',
                }}
                className="user-box"
                id="login"
                value={login}
                keyfilter={/^[^@!]+$/}
                onChange={(e) => setLogin(noArroba(e.currentTarget.value))}
              />
            </div>

            <label htmlFor="password" className="password-text">
              Senha:
            </label>
            <div>
              <Password
                style={{
                  background: '#FFFFFF0F',
                  color: '#fff',
                  marginLeft: '25%',
                }}
                className="password-box"
                id="password"
                value={senha}
                feedback={false}
                onChange={(e) => setSenha(e.currentTarget.value)}
              />
            </div>

            {/* <div className="forgotten">
              <Link to="/forgotten" style={{ color: '#fff' }}>
                Esqueci minha senha
              </Link>
            </div> */}

            <div>
              <Button
                type="submit"
                style={{
                  background: '#FFC800',
                  color: '#0B141F',
                  fontWeight: 'bold',
                  marginTop: '7%',
                  marginLeft: '30%',
                }}
                label="Entrar"
                className="login-button"
                onClick={() => handleLogin()}
              />
            </div>
          </div>
        )}
      </div>

      <div className="p-col screen-right-container">
        <div className="info-title">Sistema de rastreamento e acompanhamento</div>
        <div className="info-subtitle">
          O sistema GoldenLog possui diversas ferramentas que visam trazer como benefícios maior
          segurança, informações, identificação de problemas e pontos de melhoria nos processos
          internos e redução de custos operacionais.
        </div>
        <div className="list-title">Vantagens</div>
        <div className="list-content">
          <div className="arrow-container">
            <p> Grid Completo de Acompanhamento de Viagens;</p>
          </div>
          <div className="arrow-container">
            <p> Transit Time – Acompanhamento de Progressão de Viagens;</p>
          </div>
          <div className="arrow-container">
            <p> Disponibilidade de Veículos;</p>
          </div>
          <div className="arrow-container">
            <p> Controle de Produtividade de Motoristas e Veículos;</p>
          </div>
          <div className="arrow-container">
            <p> Grid de Viagens;</p>
          </div>
          <div className="arrow-container">
            <p> Controle de Entregas;</p>
          </div>
          <div className="arrow-container">
            <p> Projetos Personalizados.</p>
          </div>
          <MapImage className="map-image" />
        </div>
      </div>
    </div>
  );
};

export default LoginParceiros;
