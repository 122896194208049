import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import axios from '../../config/axiosMaquina';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { Tela } from '../../components/Tela';
import { pesquisar } from '../../util/format';
import { getCookieSessionData } from '../../services/cookieService';

type Props = {
  canInsert?: boolean;
};

export const ConsultarPerfisUsuarios = ({ canInsert }: Props) => {
  const history = useHistory();
  const { page }: any = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const titles = ['+id', 'Perfil'];
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const { usuarioId, clienteId, role } = getCookieSessionData().usuarioVO;
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => {
    history.push(`/perfis-usuarios/listar/${pageNumber}`);
  };
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        role === 'admin' ? '/menu-perfil/list/' : `/menu-perfil/list/?idCliente=${clienteId}`
      );
      const array = orderBy(response.data, 'nomeMenu').map((each: any) => {
        return [each.id, each.nomeMenu];
      });
      setData(array);
      setLoading(false);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/perfis-usuarios/listar/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Perfis de Usuários"
      caminho="Usuários > Perfis de Usuários"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd="/cadastro-perfis-usuarios"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/edicao-perfis-usuarios/${id}`)}
        />
      )}
    </Tela>
  );
};
