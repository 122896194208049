import { Dispatch, SetStateAction } from 'react';
import { errorsMessages } from '../../constants/Messages';
import DadosPesquisaFuncionario from '../../pages/Pesquisa/DetalhePesquisa/DadosPesquisaFuncionario';
import { isDataMaioridade } from '../../util/date';
import { validaNome, validaVazioNulo } from '../../util/Validacoes/Validacoes';

type Funcionario =
  | {
      atualizarPesquisa: boolean;
      bairro: string;
      cep: string;
      cidade: number;
      cpf: string;
      dataNascimento: string;
      id: number;
      logradouro: string;
      logradouroComplemento: string;
      logradouroNumero: number;
      nome: string;
      nomeCidade: string;
      nomeMae: string;
      nomePai: string;
      nomeUfEmissor: string;
      nomeUfEndereco: string;
      rg: string;
      sexo: string;
      tipo: string;
      idUfEmissor: number;
      idUfEndereco: number;
      idUfNascimento: number;
    }
  | undefined;

type InputErrors = {
  funcionario: string;
  nome: string;
  tipo: string;
  dataNascimento: string;
  rg: string;
  idUfEmissorRg: string;
  cep: string;
  logradouro: string;
  logradouroNumero: string;
  logradouroComplemento: string;
  bairro: string;
  cidade: string;
  uf: string;
  nomePai: string;
  nomeMae: string;
};

type Props = {
  funcionario: Funcionario;
  setInputErrors: Dispatch<SetStateAction<InputErrors>>;
};

export const validaDataNascimentoField = ({
  funcionario,
  setInputErrors,
  inputErrors,
}: {
  funcionario: Funcionario;
  setInputErrors: Dispatch<SetStateAction<InputErrors>>;
  inputErrors: InputErrors;
}): void => {
  if (!funcionario) {
    return;
  }
  console.log(isDataMaioridade(funcionario.dataNascimento));
  if (isDataMaioridade(funcionario.dataNascimento)) {
    setInputErrors({ ...inputErrors, dataNascimento: '' });
    return;
  }

  setInputErrors({
    ...inputErrors,
    dataNascimento: errorsMessages.input.dataNascimentoNaoMaioridade,
  });
};

export const validateFields = ({ funcionario, setInputErrors }: Props): boolean => {
  let valid = true;
  const errors: InputErrors = {} as InputErrors;

  if (!funcionario) {
    return true;
  }

  if (!validaVazioNulo(funcionario.nome)) {
    errors.nome = errorsMessages.input.nullOrEmpty;
    valid = false;
  } else if (!validaNome(funcionario.nome)) {
    errors.nome = errorsMessages.input.invalidName;
  }

  if (
    funcionario.tipo === 'Selecione' ||
    !validaVazioNulo(funcionario.tipo) ||
    funcionario.tipo?.toString() === '0'
  ) {
    errors.tipo = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.dataNascimento)) {
    errors.dataNascimento = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.rg)) {
    errors.rg = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (
    funcionario.nomeUfEmissor === 'Selecione' ||
    !validaVazioNulo(funcionario.idUfEmissor) ||
    funcionario.idUfEmissor?.toString() === '0'
  ) {
    errors.idUfEmissorRg = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.cep)) {
    errors.cep = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.logradouro)) {
    errors.logradouro = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.logradouroNumero)) {
    errors.logradouroNumero = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.bairro)) {
    errors.bairro = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.cidade) || funcionario.cidade?.toString() === '0') {
    errors.cidade = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (
    funcionario.nomeUfEndereco === 'Selecione' ||
    !validaVazioNulo(funcionario.idUfEndereco) ||
    funcionario.idUfEndereco?.toString() === '0'
  ) {
    errors.uf = errorsMessages.input.nullOrEmpty;
    valid = false;
  }

  if (!validaVazioNulo(funcionario.nomePai)) {
    errors.nomePai = errorsMessages.input.nullOrEmpty;
    valid = false;
  } else if (!validaNome(funcionario.nomePai)) {
    errors.nomePai = errorsMessages.input.nomeESobrenome;
  }

  if (!validaVazioNulo(funcionario.nomeMae)) {
    errors.nomeMae = errorsMessages.input.nullOrEmpty;
    valid = false;
  } else if (!validaNome(funcionario.nomeMae)) {
    errors.nomeMae = errorsMessages.input.nomeESobrenome;
  }

  setInputErrors(errors);
  return valid;
};

export default validateFields;
