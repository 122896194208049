/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import axios from '../../../config/axiosMaquina';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import { ListagemCliente } from '../../../api/Types/listagemResumoTypes';
import { soNumeros } from '../../../util/Validacoes/Validacoes';
import { dataConverter } from '../../../util/date';

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const AtualizacaoAvisos = ({ canEdit, canDelete }: Props) => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const [clientes, setClientes] = useState([]);
  const [mensagem, setMensagem] = useState('');
  const [titulo, setTitulo] = useState('');
  const [permanencia, setPermanencia] = useState<number>();
  const [ativo, setAtivo] = useState(true);
  const [disponivel, setDisponivel] = useState('');
  const [isNull, setIsNull] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogExclusaoOK, setIsDialogExclusaoOK] = useState(false);
  const { addToast } = useToast();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const { id }: any = useParams();

  useEffect(() => {
    const loadData = async () => {
      try {
        const responseClientes = await axios.get('/cliente/getIdRazaoSocial');
        setListaClientes(responseClientes.data);

        const responseAvisos = await axios.get(`/aviso-cliente/${id}`);

        const selecionados: any = [];

        for (let i = 0; i < responseAvisos.data.clientes.length; i++) {
          for (let j = 0; j < responseClientes.data.length; j++) {
            if (responseAvisos.data.clientes[i] === responseClientes.data[j].id)
              selecionados.push(responseClientes.data[j].razaoSocial);
          }
        }

        setClientes(selecionados);
        setDisponivel(responseAvisos.data.disponivel.split('T')[0]);
        setTitulo(responseAvisos.data.titulo);
        setMensagem(responseAvisos.data.mensagem);
        setPermanencia(responseAvisos.data.permanencia);
      } catch (error: any) {
        console.log(error);
        addToast({
          title: 'Erro',
          description: 'Erro ao recuperar aviso!',
          type: 'error',
        });
      }
    };
    loadData();
  }, [id]);

  const excluirAviso = () => {
    axios
      .delete(`/aviso-cliente/deletar/${id}`)
      .then(() => {
        setShow(false);
        setIsDialogExclusaoOK(true);
      })
      .catch((err: any) => {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao excluir!',
          type: 'error',
        });
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const clientesId: number[] = [];

    const temp: any[] = listaClientes;

    for (let i = 0; i < clientes.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (clientes[i] === temp[j].razaoSocial) clientesId.push(temp[j].id);
      }
    }

    if (permanencia && permanencia <= 0) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Dias de permanência deve ser maior que zero',
      });
      return;
    }

    const dados = {
      mensagem,
      titulo,
      disponivel: dataConverter(disponivel),
      clientes: clientesId,
      permanencia,
      ativo,
    };

    axios
      .put(`/aviso-cliente/editar/${id}`, dados)
      .then(() => {
        setIsDialogCadOK(true);
      })
      .catch((err: any) => {
        console.log(err);

        addToast({
          title: 'Erro',
          description: 'Erro ao alterar!',
          type: 'error',
        });
      });
  };

  const validaCampoNull = (event: any) => {
    event.preventDefault();
    if (event.target.value.length > 0) {
      setIsNull(false);
      setTitulo(event.target.value);
    }
    setIsNull(true);
    setTitulo(event.target.value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row', minWidth: 667 }}>
      <MenuLateral />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${titulo}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => excluirAviso()}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        header="Aviso alterado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/avisos-clientes');
        }}
      />

      <Dialog
        header="Aviso excluído com sucesso!"
        footer={<Button onClick={() => setIsDialogExclusaoOK(false)}>Ok</Button>}
        visible={isDialogExclusaoOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/avisos-clientes');
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Avisos Clientes</h2>
          <h6 className="subtitulo">{'Avisos > Clientes > Atualização'}</h6>
          <button
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              marginLeft: '40%',
            }}
            onClick={() => {
              if (canDelete) {
                handleShow();
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
          </button>
        </div>
        <Form onSubmit={handleSubmit} validated={isNull}>
          <h6 style={{ color: 'red', fontSize: 12 }}>* Campos Obrigatórios</h6>
          <Form.Row>
            <Form.Group as={Col} md={4} className="mb-3 mt-5 ml-4">
              <Form.Label className="requiredField">EMPRESAS</Form.Label>

              <MultiSelect
                style={{ width: '100%' }}
                value={clientes}
                optionValue="razaoSocial"
                options={listaClientes}
                onChange={(event: any) => setClientes(event.target.value)}
                optionLabel="razaoSocial"
                filter
                className="multiselect-custom"
                required
                tooltip="Selecione um ou mais clientes"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={4} className="mb-3 ml-4">
              <Form.Label className="requiredField">TÍTULO</Form.Label>
              <Form.Control
                placeholder={titulo}
                value={titulo}
                onChange={validaCampoNull}
                name="titulo"
                type="text"
                title="Campo Obrigatório"
                required
                maxLength={64}
              />
              <Form.Control.Feedback type="invalid">
                Campo título não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={4} className="mb-3 ml-4">
              <Form.Label className="requiredField">MENSAGEM</Form.Label>
              <Form.Control
                value={mensagem}
                placeholder={mensagem}
                onChange={(event: any) => setMensagem(event.target.value)}
                id="mensagem"
                required
                as="textarea"
                title="Campo Obrigatório"
                rows={3}
                maxLength={500}
              />
              <Form.Control.Feedback type="invalid">
                Campo mensagem não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={2} className="mb-3 ml-4">
              <Form.Label className="requiredField">DISPONIBILIZAR Á PARTIR DE</Form.Label>
              <Form.Control
                onChange={(event: any) => setDisponivel(event.target.value)}
                id="disponivel"
                name="disponivel"
                type="date"
                value={disponivel.split('T')[0]}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={2} className="mb-3 ml-4">
              <Form.Label className="requiredField">DIAS DE PERMANÊNCIA</Form.Label>
              <Form.Control
                value={permanencia}
                onChange={(event: any) => {
                  setPermanencia(soNumeros(event.target.value));
                }}
                id="permanencia"
                name="permanencia"
                type="text"
                maxLength={3}
                isInvalid={permanencia && permanencia <= 0 ? true : false}
                title="Campo Obrigatório"
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo não pode ser igual a 0.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: '.75' }}></div>
            <div style={{ display: 'flex', alignSelf: 'end' }}>
              <Form.Row className="container-buttons justify-content-end">
                <Form.Group as={Col} md={2}>
                  <Button
                    style={{
                      height: 50,
                      width: 175,
                      marginBottom: 20,
                      marginTop: 20,
                      marginRight: 10,
                      backgroundColor: 'transparent',
                      borderColor: 'black',
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                    onClick={() => history.push('/listar/avisos-clientes')}
                  >
                    Cancelar
                  </Button>
                </Form.Group>
              </Form.Row>
            </div>

            <div style={{ display: 'flex', alignSelf: 'right' }}>
              <Form.Row className="container-buttons justify-content-end">
                <Form.Group as={Col} md={2}>
                  {canEdit ? (
                    <Button
                      style={{
                        height: 50,
                        width: 175,
                        marginBottom: 20,
                        marginTop: 20,
                        backgroundColor: '#FFDA53',
                        borderColor: '#FFDA53',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </Button>
                  ) : (
                    <Button
                      style={{
                        height: 50,
                        width: 175,
                        marginBottom: 20,
                        marginTop: 20,
                        backgroundColor: '#FFDA53',
                        borderColor: '#FFDA53',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      type="button"
                      onClick={() => history.push('/acessonegado')}
                    >
                      Confirmar
                    </Button>
                  )}
                </Form.Group>
              </Form.Row>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AtualizacaoAvisos;
