/* eslint-disable */
import { useState } from 'react';
import { Modal, Button, Form, Col, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Botao } from './styles';
import { Dialog } from 'primereact/dialog';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { AddButton } from '../../../components/AddButton';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import {
  ContainerButtons,
  PrimaryButton,
  SecundaryButton,
} from '../../../components/ButtonsForm/styles';

type TipoComunicacaoType = {
  ttcCdId: number;
  ttcTxNome: string;
  ttcTxDescricao: string;
  ttcNuOrdem: number;
  ttcBlAtivo: boolean;
};

const ModalCadastroTipoComunicacao = () => {
  const { addToast } = useToast();
  const [show, setShow] = useState<boolean>(false);
  const [model, setModel] = useState<TipoComunicacaoType>({
    ttcCdId: 0,
    ttcTxNome: '',
    ttcTxDescricao: '',
    ttcNuOrdem: 0,
    ttcBlAtivo: true,
  });
  const [validated, setValidated] = useState(false);
  const [isDialogVisibleCadNaoEfetuado, setIsDialogVisibleCadNaoEfetuado] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { ttcTxNome, ttcTxDescricao } = model;

  const cadastrarTipoComunicacao = async (event: any) => {
    event.preventDefault();
    if (!ttcTxNome) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setValidated(false);
      event.stopPropagation();
      return;
    }
    setLoading(true);
    try {
      await axios.post(`/tipo-comunicacao/tecnologiatipocomunicacao`, model);
      setIsDialogVisibleCadEfetuado(true);
    } catch (error: any) {
      setIsDialogVisibleCadNaoEfetuado(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function back() {
    history.go(0);
  }
  return (
    <>
      <AddButton onClick={handleShow} style={{ marginTop: '0px' }} adicionarPonto />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Tipo de Comunicação</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center', width: '90%' }}>
          <Dialog
            header="Tipo de Comunicação foi cadastrado com sucesso!"
            footer={
              <>
                <Button onClick={() => back()}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />
          <Dialog
            header="Erro ao cadastrar, verifique os campos!"
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadNaoEfetuado(false)}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadNaoEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadNaoEfetuado(false)}
          />
          {loading ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <Form noValidate validated={validated}>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="requiredField">NOME</Form.Label>
                  <Form.Control
                    value={ttcTxNome}
                    onChange={(event: any) => setModel({ ...model, ttcTxNome: event.target.value })}
                    name="nome"
                    type="text"
                    required
                    title="Preencha com o nome do tipo de comunicação"
                    maxLength={256}
                    style={{ marginBottom: 15 }}
                  />
                  <Form.Label>DESCRIÇÃO</Form.Label>
                  <Form.Control
                    value={ttcTxDescricao}
                    onChange={(event: any) =>
                      setModel({ ...model, ttcTxDescricao: event.target.value })
                    }
                    name="descricao"
                    type="text"
                    required
                    title="Preencha com a descrição do tipo de comunicação"
                    maxLength={256}
                    style={{ marginBottom: 15 }}
                  />
                </Form.Group>
                <ContainerButtons>
                  <SecundaryButton
                    type="button"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancelar
                  </SecundaryButton>
                  <PrimaryButton type="submit" onClick={cadastrarTipoComunicacao}>
                    Confirmar
                  </PrimaryButton>
                </ContainerButtons>

                {/* <Botao className="container-buttons justify-content-between">
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-cancelar"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Button className="btn-enviar" type="button" onClick={cadastrarTipoComunicacao}>
                      Confirmar
                    </Button>
                  </Form.Group>
                </Botao> */}
              </Form.Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCadastroTipoComunicacao;
