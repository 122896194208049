/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, Col, Button, Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { InfracoesType, PenalidadesType } from '../../api/model/NaoConformidadeType';
import { getCookieSessionData } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';

type Props = {
  canDelete?: boolean;
  canEdit?: boolean;
};

function EditarNaoConformidade({ canEdit, canDelete }: Props) {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();

  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidNome, setIsValidNome] = useState(true);
  const [causaRaiz, setCausaRaiz] = useState('');
  const [naturezaEvento, setNaturezaEvento] = useState('');
  const [processoMacro, setProcessoMacro] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [penalidadeId, setPenalidadeId] = useState('');
  const [infracaoId, setInfracaoId] = useState('');
  const [penalidades, setPenalidades] = useState<PenalidadesType[]>([]);
  const [infracoes, setInfracoes] = useState<InfracoesType[]>([]);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`penalidade-nao-conformidade`);
        setPenalidades(response.data);
        //console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`infracao-nao-conformidade`);
        setInfracoes(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log(id);
      const response = await axios.get(`/nao-conformidade/${id}`);
      setCausaRaiz(response.data.causaRaiz);
      setNaturezaEvento(response.data.naturezaEvento);
      setProcessoMacro(response.data.processoMacro);
      setInfracaoId(response.data.infracaoId);
      setPenalidadeId(response.data.penalidadeId);
    };
    fetchData();
  }, [id]);

  const handleEdit = async (event: any) => {
    event.preventDefault();

    if (!penalidadeId || !naturezaEvento || !infracaoId) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    const data = {
      causaRaiz: causaRaiz,
      naturezaEvento: naturezaEvento,
      processoMacro: processoMacro,
      infracaoId: infracaoId,
      penalidadeId: penalidadeId,
      ativo: true,
      penalidadeDescricao: null,
      listPenalidade: null,
      infracaoDescricao: null,
      listInfracao: null,
      clienteId: clienteId,
      listCliente: null,
    };
    console.log(data);
    try {
      await axios.put(`/nao-conformidade/${id}`, data);
      console.log(data);
      // alert('Alteração efetuada com sucesso.');
      addToast({
        title: 'Sucesso!',
        description: 'Alteração efetuada com sucesso.',
        type: 'success',
      });
      setTimeout(function () {
        history.push('/listar-naoconformidades/1');
      }, 1500);
    } catch {
      // const alerta = alert('Erro ao atualizar');
      addToast({
        title: 'Erro!',
        description: 'Erro ao atualizar.',
        type: 'error',
      });
      return;
    }
  };

  const handleDelete = async (event: any) => {
    try {
      await axios.delete(`/nao-conformidade/${id}`);
      // alert('Exclusão efetuada com sucesso.');
      addToast({
        title: 'Sucesso!',
        description: 'Exclusão efetuada com sucesso.',
        type: 'success',
      });
      setTimeout(function () {
        history.goBack();
      }, 1500);
    } catch {
      // const alerta = alert('Erro ao excluir');
      addToast({
        title: 'Erro!',
        description: 'Erro ao excluir.',
        type: 'error',
      });
      return;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${naturezaEvento}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(id)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="card" style={{ backgroundColor: '#f2f2f2', borderColor: '#f2f2f2' }}>
        <Container fluid style={{ marginTop: '0px' }}>
          <div className="titulo-container" style={{ marginTop: '20px' }}>
            <h2 className="titulo">Não Conformidades</h2>
            <h6 className="subtitulo">{'Alterar > Não Conformidade'}</h6>
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '5%',
              }}
              onClick={() => {
                if (canDelete) {
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
            </button>
          </div>

          <Form onSubmit={handleEdit} noValidate validated={isFormInvalid}>
            <Form.Group
              as={Col}
              controlId="formGridAddress1"
              style={{ marginLeft: '-14px', marginTop: '2%' }}
            >
              <Form.Label className="required">Natureza do Evento</Form.Label>
              <Form.Control
                value={naturezaEvento}
                onChange={(event) => {
                  event.preventDefault();
                  setNaturezaEvento(event.target.value);
                  if (naturezaEvento.length < 2) {
                    setIsValidLogin(false);
                  } else {
                    setIsValidLogin(true);
                  }
                }}
                required
                style={{ width: '102%' }}
                type="text"
                minLength={3}
                maxLength={64}
              />
              {!isValidLogin && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido, digite 3 caracteres ou mais
                </p>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '-14px' }}>
              <Form.Label>Causa Raiz</Form.Label>

              <Form.Control
                value={causaRaiz}
                onChange={(event) => {
                  event.preventDefault();
                  setCausaRaiz(event.target.value);
                  if (causaRaiz.length < 2) {
                    setIsValidNome(false);
                  } else {
                    setIsValidNome(true);
                  }
                }}
                style={{ width: '102%' }}
                minLength={3}
                maxLength={64}
              />
              {!isValidNome && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido, digite 3 caracteres ou mais
                </p>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '-14px' }}>
              <Form.Label>Processo Macro</Form.Label>

              <Form.Control
                value={processoMacro}
                onChange={(event) => {
                  event.preventDefault();
                  setProcessoMacro(event.target.value);
                  if (processoMacro.length < 2) {
                    setIsValidNome(false);
                  } else {
                    setIsValidNome(true);
                  }
                }}
                minLength={3}
                maxLength={32}
                style={{ width: '102%' }}
              />
              {!isValidNome && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido, digite 3 caracteres ou mais
                </p>
              )}
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label className="required">Penalidade</Form.Label>

                <Form.Control
                  value={penalidadeId}
                  onChange={(event) => {
                    setPenalidadeId(event.target.value);
                  }}
                  required
                  as="select"
                >
                  <option value="">Selecione uma opção</option>
                  {penalidades.map((penalidade) => (
                    <option value={penalidade.id} key={penalidade.id}>
                      {penalidade.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label className="required">Infração</Form.Label>

                <Form.Control
                  value={infracaoId}
                  onChange={(event) => {
                    setInfracaoId(event.target.value);
                  }}
                  required
                  as="select"
                >
                  <option value="">Selecione uma opção</option>
                  {infracoes.map((infracao) => (
                    <option value={infracao.id} key={infracao.id}>
                      {infracao.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <ButtonsForm canEdit={canEdit} />
          </Form>
        </Container>
      </div>
    </div>
  );
}

export default EditarNaoConformidade;
