/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { RotaType } from '../../api/model/RotaType';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import {
  Container,
  Table,
  Button,
  Form,
  FormControl,
  Navbar,
  Accordion,
  Card,
  Spinner,
} from 'react-bootstrap';
import { ParadaType } from '../../api/model/ParadaType';
import './styles.css';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Pagination from '../../components/Pagination/Pagination';
import { getCookieSessionData } from '../../services/cookieService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tela } from '../../components/Tela';

type Props = {
  canInsert: boolean;
};

function Rotas({ canInsert }: Props) {
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;

  const [paradas, setParadas] = useState<ParadaType[]>([]);
  const [rotas, setRotas] = useState<RotaType[]>([]);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<RotaType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const { page }: any = useParams();
  const [currentPage, setCurrentPage] = useState(page);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage);
  const currentRota = searchResults.slice(indexOfFirstPost, indexOfLastPost);

  const [totalPosts, setTotalPosts] = useState(0);

  const paginate = (pageNumber: number) => {
    history.push(`/rota/${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/rota/grid-rota-cliente`, {
        params: {
          idCliente: clienteId,
          qtdRegistros: itensPerPage,
          pagina: page - 1,
        },
      });
      const routes = response.data.filter((item: RotaType) => !item.rotaAvulsa);
      setRotas(routes);
      setSearchResults(routes);
      //console.log(response);
      console.log('rotas ', routes);
      setLoading(false);
    };
    fetchData();
  }, [itensPerPage, page]);

  useEffect(() => {
    const fetchQtdPosts = async () => {
      const response = await axios.get(`/rota/cliente/count/${clienteId}`);
      setTotalPosts(response.data);
      console.log(response.data);
    };
    fetchQtdPosts();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/parada');
      setParadas(response.data);
      console.log('paradas ', response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm.trim() === '') {
        setSearchResults(rotas);
      } else {
        const response = await axios.get('/rota/search/grid-rota-cliente', {
          params: {
            idCliente: clienteId,
            keyword: searchTerm,
            pagina: page - 1,
            qtdRegistros: itensPerPage,
          },
        });
        setSearchResults(response.data);
        console.log('rotas ', response.data);
      }
    };
    fetchData();
  }, [searchTerm]);

  const handleSearch = (event: any) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  function rotaEditar(id: number) {
    history.push(`/rota-editar-cadastrar/${id}`);
  }

  const handleParadas = async (idRota: number) => {
    setParadas([] as any);
    const response = await axios.get(`/rota/parada-por-rota?idRota=${idRota}`);
    setParadas(response.data);
  };

  return (
    <Tela
      nome="Rotas"
      caminho="Cadastro > Rotas"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/rota-editar-cadastrar"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      createDisabled={!canInsert}
    >
      <div className="painel" style={{ width: '100%' }}>
        <div className="table-responsive" style={{ width: '100%' }}>
          <Accordion>
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <Table className="rotas">
                <thead>
                  <tr>
                    <th></th>

                    <th>Nome</th>
                    <th>Descrição da Origem</th>
                    <th>Descrição do Destino</th>
                    <th>UF Origem</th>
                    <th>UF Destino</th>
                    <th>Rota Internacional</th>
                    <th></th>
                  </tr>
                </thead>

                {currentRota.map((rota) => (
                  <tbody key={rota.idRota}>
                    <tr>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={rota.idRota.toString()}
                        key={rota.idRota}
                        onClick={() => {
                          handleParadas(rota.idRota);
                        }}
                      >
                        <i
                          key={rota.idRota}
                          className="pi pi-caret-right mt-2"
                          title="Visualizar Paradas"
                        ></i>
                      </Accordion.Toggle>
                      <td>{rota.nome}</td>
                      <td>{rota.descricaoOrigem ? rota.descricaoOrigem : ''}</td>
                      <td>{rota.descricaoDestino ? rota.descricaoDestino : ''}</td>
                      {/* <td>{rota.ufOrigem}</td> */}
                      <td>
                        {rota.ufOrigemInternacional ? rota.ufOrigemInternacional : rota.ufOrigem}
                      </td>
                      <td>
                        {rota.ufDestinoInternacional ? rota.ufDestinoInternacional : rota.ufDestino}
                      </td>
                      {rota.execRotaInternacional ? <td>Sim</td> : <td>Não</td>}
                      <td>
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            width: 20,
                            height: 20,
                            padding: 0,
                            marginRight: 15,
                            marginBottom: 2,
                          }}
                        >
                          <i
                            key={rota.idRota}
                            onClick={() => rotaEditar(rota.idRota)}
                            className="pi pi-pencil"
                            title="Editar Rota"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <td colSpan={8} key={rota.idRota}>
                      <Accordion.Collapse eventKey={rota.idRota.toString()}>
                        <div>
                          <tr>
                            <th>Sequência de Parada</th>
                            <th>Motivo da Parada</th>
                            <th>Local da Parada</th>
                            <th>Cidade da Parada</th>
                          </tr>
                          {paradas &&
                            paradas
                              .sort(function (a: any, b: any) {
                                return a.sequencia - b.sequencia;
                              })
                              .map((filteredParada) => (
                                <tr>
                                  <td>{filteredParada.sequencia}</td>

                                  {filteredParada.motivo === '1' && <td>Alimentação</td>}
                                  {filteredParada.motivo === '2' && <td>Descanso</td>}
                                  {filteredParada.motivo === '3' && <td>Entrega</td>}
                                  {filteredParada.motivo === '4' && <td>Coleta</td>}
                                  {filteredParada.motivo === '5' && <td>Fiscalização</td>}
                                  {filteredParada.motivo === '9' && <td>Outros</td>}

                                  <td>{filteredParada.local}</td>
                                  <td>{filteredParada.cidade}</td>
                                </tr>
                              ))}
                        </div>
                      </Accordion.Collapse>
                    </td>
                  </tbody>
                ))}
              </Table>
            )}
            <Pagination
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={totalPosts}
              paginate={paginate}
              currentPage={page}
            />
          </Accordion>
        </div>
      </div>
    </Tela>
  );
}

export default Rotas;
