import { Dropdown } from 'primereact/dropdown';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

interface Props {
  width?: string;
}

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 140px;
`;

export const Select = styled(Dropdown)`
  width: 100%;
`;

export const Table = styled.div<Props>`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: ${(props) => (props.width ? props.width : '100%')};

  .table-header {
    display: flex;
    background-color: #dedede;
    flex-direction: row;
    font-weight: 600;

    p {
      display: flex;
      margin: 10px;
      width: 100%;
      justify-content: space-around;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }

  .table-body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 300px;
    margin-bottom: 20px;

    .table-body-row {
      display: flex;
      flex-direction: row;
      outline: 1px solid #dedede;

      p {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 5px auto;
      }
    }
  }
`;

export const SideTableHeader = styled.div`
  display: flex;
  background-color: #dedede;
  flex-direction: row;
  font-weight: 600;
`;

export const FilterBtn = styled(Button)`
  padding: 0;
  margin: auto;
  max-width: 120px;
  height: 40px !important;
  margin-left: 20px !important;
  margin-bottom: 20px !important;
  font-weight: 600;
  color: #000 !important;
  &:active {
    background-color: #ffda53 !important;
    color: #000;
  }
`;

export const TableMapContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1280px) {
    flex-direction: column;
    position: relative;
  }
`;
