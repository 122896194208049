/* eslint-disable */
import React, { useState, useEffect, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';
import { useToast } from '../../hooks/Toast';
import api from '../../config/axiosMaquina';
import { SMEmAndamentoType } from '../../api/model/Veiculos/SMEmAndamentoType';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { UsuarioType } from '../../types';
import SolicitacaoMonitoramento from '../../components/SolicitacaoMonitoramento';
import { ParametroOperacional } from '../../api/model/ParametroOperacional';
import { EmbarcadorDadosSM } from '../../api/model/EmbarcadorDadosSMType';

export type PreCancelamentoType = {
  nomeCliente?: string;
  idUsuario: number;
  motivoCancelamento: number;
  idSm: number;
  dataCancelamento: string;
  nomeUsuario: string;
  idClienteUsuario?: number;
  solicitanteCancelamento: string;
};

type Props = {
  canEdit?: boolean;
};

const IniciarSMPendente: FC<Props> = ({ canEdit }) => {
  const history = useHistory();
  const { id, type }: any = useParams();
  const { addToast } = useToast();
  const [smData, setSmData] = useState<SMEmAndamentoType | undefined>();
  const [statusInicializacao, setStatusInicializacao] = useState(false);
  const [parametros, setParametros] = useState<ParametroOperacional>();
  const [embarcador, setEmbarcador] = useState<EmbarcadorDadosSM | null>(null);

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [usuario, setUsuario] = useState<UsuarioType | undefined>();

  const [mensagem, setMensagem] = useState<string>('');
  const [cliente, setCliente] = useState('');
  const [valorCargaOriginal, setValorCargaOriginal] = useState(0);
  const [descricaoCargaOriginal, setDescricaoCargaOriginal] = useState('');
  const [valorCargaMinimoErro, setValorCargaMinimoErro] = useState(false);
  const [erroValorCarga, setErroValorCarga] = useState(false);
  const [flagObrigatoriedadeIsca, setFlagObrigatoriedadeIsca] = useState(false);
  const [flagObrigatoriedadeEscolta, setFlagObrigatoriedadeEscolta] = useState(false);
  const [mostrarCamposEscolta, setMostrarCamposEscolta] = useState(false);
  const [mostrarCamposIsca, setMostrarCamposIsca] = useState(false);

  const [dadosCancelamento, setDadosCancelamento] = useState<PreCancelamentoType>({
    nomeCliente: '',
    idUsuario: 0,
    motivoCancelamento: 0,
    idSm: 0,
    dataCancelamento: '',
    nomeUsuario: '',
    idClienteUsuario: 0,
    solicitanteCancelamento: '',
  });

  const usuarioLogado = getCookieSessionData().usuarioVO.usuarioId;
  const usuarioData = getCookieSessionData().usuarioVO;

  useEffect(() => {
    const usuarioData = getCookieSessionData().usuarioVO;
    setUsuario(usuarioData);
  }, []);

  useEffect(() => {
    const cancelaSolicitacaoGetByID = async () => {
      try {
        const response = await api.get(`/solicitacao-monitoramento/getDadosCancelamento/${id}`);
        setDadosCancelamento(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    };
    cancelaSolicitacaoGetByID();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/solicitacao-monitoramento/listardadossmpendente?solicitacaoMonitoramento=${id}`
        );
        setSmData(response.data);
        setValorCargaOriginal(response.data.valorCarga);
        setDescricaoCargaOriginal(response.data.descricaoCarga);
        setCliente(response.data.clienteId);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const handleValorCargaChange = (value: number) => {
      if (parametros && value <= parametros.valorMinimoCargas) {
        setValorCargaMinimoErro(true);
      } else {
        setValorCargaMinimoErro(false);
      }
      confereValoresLimiteEmbarcador(value);
    };
    if (
      smData?.valorCarga != undefined &&
      parametros &&
      smData.valorCarga != valorCargaOriginal &&
      embarcador
    ) {
      handleValorCargaChange(smData.valorCarga);
    }
  }, [smData?.valorCarga, parametros, embarcador]);

  const confereValoresLimiteEmbarcador = (vrCarga: number) => {
    const tipoOperacaoSelecionada = smData?.tipoOperacao;

    if (tipoOperacaoSelecionada === 'Transferência') {
      const valorLimiteCargaTransferencia = embarcador
        ? embarcador.valorLimiteCargaTransferencia
        : 0;
      const valorPisoEscoltaTransferencia = embarcador
        ? embarcador.valorPisoEscoltaTransferencia
        : 0;
      const valorPisoIscaTransferencia = embarcador ? embarcador.valorPisoIscaTransferencia : 0;

      if (vrCarga >= valorPisoEscoltaTransferencia && !smData?.veiculoEscolta) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
      }

      if (vrCarga > valorLimiteCargaTransferencia) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoIscaTransferencia && !smData?.tecnologiaIsca) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
      }
    } else if (tipoOperacaoSelecionada === 'Distribuição') {
      const valorLimiteCargaDistribuicao = embarcador ? embarcador.valorLimiteCargaDistribuicao : 0;
      const valorPisoEscoltaDistribuicao = embarcador ? embarcador.valorPisoEscoltaDistribuicao : 0;
      const valorPisoIscaDistribuicao = embarcador ? embarcador.valorPisoIscaDistribuicao : 0;

      if (vrCarga > valorLimiteCargaDistribuicao) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaDistribuicao && !smData?.veiculoEscolta) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
      }

      if (vrCarga >= valorPisoIscaDistribuicao && !smData?.tecnologiaIsca) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
      }
    } else if (tipoOperacaoSelecionada === 'Coleta') {
      const valorLimiteCargaColeta = embarcador ? embarcador.valorLimiteCargaColeta : 0;
      const valorPisoEscoltaColeta = embarcador ? embarcador.valorPisoEscoltaColeta : 0;
      const valorPisoIscaColeta = embarcador ? embarcador.valorPisoIscaColeta : 0;

      if (vrCarga > valorLimiteCargaColeta) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaColeta && !smData?.veiculoEscolta) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
      }

      if (vrCarga >= valorPisoIscaColeta && !smData?.tecnologiaIsca) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
      }
    } else if (tipoOperacaoSelecionada === 'Exportação') {
      const valorLimiteCargaExportacao = embarcador ? embarcador.valorLimiteCargaExportacao : 0;
      const valorPisoEscoltaExportacao = embarcador ? embarcador.valorPisoEscoltaExportacao : 0;
      const valorPisoIscaExportacao = embarcador ? embarcador.valorPisoIscaExportacao : 0;

      if (vrCarga > valorLimiteCargaExportacao) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaExportacao && !smData?.veiculoEscolta) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
      }

      if (vrCarga >= valorPisoIscaExportacao && !smData?.tecnologiaIsca) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
      }
    } else if (tipoOperacaoSelecionada === 'Devolução') {
      const valorLimiteCargaDevolucao = embarcador ? embarcador.valorLimiteCargaDevolucao : 0;
      const valorPisoEscoltaDevolucao = embarcador ? embarcador.valorPisoEscoltaDevolucao : 0;
      const valorPisoIscaDevolucao = embarcador ? embarcador.valorPisoIscaDevolucao : 0;

      if (vrCarga > valorLimiteCargaDevolucao) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaDevolucao && !smData?.veiculoEscolta) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
      }

      if (vrCarga >= valorPisoIscaDevolucao && !smData?.tecnologiaIsca) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
      }
    }
  };

  useEffect(() => {
    const fetchParametros = async () => {
      const response = await api.get('/parametro-operacional');
      setParametros(response.data);
    };
    fetchParametros();
  }, []);

  useEffect(() => {
    const loadDadosEmbarcador = async (idEmbarcador: number) => {
      try {
        const response = await api.get(
          `/embarcador/listarDadosEmbarcadorParaSM?embarcador=${idEmbarcador}`
        );
        setEmbarcador(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    if (smData != undefined) {
      loadDadosEmbarcador(smData.idEmbarcador);
    }
  }, [smData?.idEmbarcador]);

  const handleEdit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!smData) {
      return;
    }
    if (usuarioData.role === 'admin') {
      try {
        if (
          statusInicializacao &&
          smData.valorCarga === valorCargaOriginal &&
          smData.descricaoCarga === descricaoCargaOriginal
        ) {
          await api.put(
            `/solicitacao-monitoramento/atualizarDadosSmPendente/${smData.id}?idUsuario=${usuarioLogado}`
          );
          localStorage.setItem('smIniciada', smData.id.toString());
          setMensagem('Viagem iniciada com sucesso!');
        } else if (
          !statusInicializacao &&
          smData.valorCarga != null &&
          (smData.valorCarga != valorCargaOriginal ||
            smData.descricaoCarga != descricaoCargaOriginal)
        ) {
          // confereValoresLimiteEmbarcador(smData.valorCarga);
          if (
            erroValorCarga ||
            valorCargaMinimoErro ||
            (flagObrigatoriedadeEscolta && !smData?.veiculoEscolta) ||
            (flagObrigatoriedadeIsca && !smData?.tecnologiaIsca)
          ) {
            addToast({
              title: 'Erro!',
              description: 'Verifique o valor informado.',
              type: 'error',
            });
            e.stopPropagation();
            setLoading(false);
            return;
          }
          await api.put(
            `/solicitacao-monitoramento/listardadossmpendente?solicitacaoMonitoramento=${smData.id}`,
            {
              valorCarga: smData.valorCarga,
              descricaoCarga: smData.descricaoCarga,
            }
          );
          setMensagem('Viagem editada com sucesso!');
        } else if (
          statusInicializacao &&
          (smData.valorCarga != valorCargaOriginal ||
            smData.descricaoCarga === descricaoCargaOriginal)
        ) {
          await api.put(
            `/solicitacao-monitoramento/atualizarDadosSmPendente/${smData.id}?idUsuario=${usuarioLogado}`
          );
          localStorage.setItem('smIniciada', smData.id.toString());

          await api.put(
            `/solicitacao-monitoramento/listardadossmpendente?solicitacaoMonitoramento=${smData.id}`,
            {
              valorCarga: smData.valorCarga,
              descricaoCarga: smData.descricaoCarga,
            }
          );
          setMensagem('Viagem editada e iniciada com sucesso!');
        } else {
          setLoading(false);
          addToast({
            title: 'Erro!',
            description: 'Marque a checkbox ou altere os campos para realizar uma alteração.',
            type: 'error',
          });

          return;
        }
        setLoading(false);
        setIsDialogVisible(true);
      } catch (err: any) {
        console.log(err.response);
        setLoading(false);
        if (err.response.data?.message?.includes('index 0 out of bounds')) {
          addToast({
            title: 'Erro!',
            description: 'Erro de comunicação com o servidor',
            type: 'error',
          });
        } else if (err.response.data.message === 'timeout') {
          addToast({
            title: 'Erro!',
            description: 'Tempo de resposta excedido, tente novamente.',
            type: 'error',
          });
        } else if (err.response.data.message) {
          addToast({
            title: 'Erro!',
            description: err.response.data.message,
            type: 'error',
          });
        } else {
          addToast({
            title: 'Erro!',
            description: 'Erro ao editar.',
            type: 'error',
          });
        }
      }
    }
    if (usuarioData.role === 'user') {
      if (
        erroValorCarga ||
        valorCargaMinimoErro ||
        (flagObrigatoriedadeEscolta && !smData?.veiculoEscolta) ||
        (flagObrigatoriedadeIsca && !smData?.tecnologiaIsca)
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o valor informado.',
          type: 'error',
        });
        e.stopPropagation();
        setLoading(false);
        return;
      }
      try {
        const edit = await api.put(
          `/solicitacao-monitoramento/listardadossmpendente?solicitacaoMonitoramento=${smData.id}`,
          {
            valorCarga: smData.valorCarga,
            descricaoCarga: smData.descricaoCarga,
          }
        );
        setMensagem('SM editada com sucesso!');
        setLoading(false);
        setIsDialogVisible(true);
      } catch (err: any) {
        console.log(err.response);
        setLoading(false);
        addToast({
          title: 'Erro!',
          description: 'Erro ao editar.',
          type: 'error',
        });
      }
    }
  };

  if (!smData) {
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação de Monitoramento</h2>
          <h6 className="subtitulo">{'SM > Visualização'}</h6>
        </div>
        <Dialog
          header={mensagem}
          footer={
            <Button
              label="Ok"
              onClick={() => {
                if (mensagem.includes('iniciada')) {
                  window.close();
                  history.goBack();
                } else {
                  window.location.reload();
                }
              }}
            />
          }
          visible={isDialogVisible}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisible(false)}
        />

        {loading ? (
          <>
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
            <p style={{ textAlign: 'center', marginTop: '-40px' }}>
              Aguarde enquanto a alteração está sendo concluída...
            </p>
          </>
        ) : (
          <>
            <div className="content-container">
              <div className="painel">
                <SolicitacaoMonitoramento
                  smData={smData}
                  dadosCancelamento={dadosCancelamento}
                  isAdmin={isAllowedByRole(['admin'])}
                  usuarioLogado={usuario}
                  onSubmit={handleEdit}
                  setSmData={setSmData}
                  setStatusInicializacao={setStatusInicializacao}
                  statusInicializacao={statusInicializacao}
                  editValorDescricaoCarga={true}
                  loading={loading}
                  erroValorCarga={erroValorCarga}
                  valorCargaMinimoErro={valorCargaMinimoErro}
                  flagObrigatoriedadeEscolta={flagObrigatoriedadeEscolta}
                  flagObrigatoriedadeIsca={flagObrigatoriedadeIsca}
                  canEdit={canEdit}
                  iniciarSm={type === 'iniciar'}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IniciarSMPendente;
