/* eslint-disable */
import React, { useState, useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Col, Button, Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import './styles.css';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import { maskCep } from '../../util/Validacoes/Validacoes';
import { getCookieSessionData } from '../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  loadDadosRotaIdNome: (data?: {
    nome: string;
    cepOrigem: string;
    numeroOrigem: string;
    cepDestino: string;
    numeroDestino: string;
  }) => void;
  isAvulsa: boolean;
};

const ModalCadastrarRotaPorSM: FC<Props> = ({ show, setShow, loadDadosRotaIdNome, isAvulsa }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const [nome, setNome] = useState('');
  const [descricaoOrigem, setDescricaoOrigem] = useState('');
  const [cepOrigem, setCepOrigem] = useState('');
  const [logradouroOrigem, setLogradouroOrigem] = useState('');
  const [numeroOrigem, setNumeroOrigem] = useState('');
  const [complementoOrigem, setComplementoOrigem] = useState('');
  const [bairroOrigem, setBairroOrigem] = useState('');
  const [cidadeOrigem, setCidadeOrigem] = useState('');
  const [idUfOrigem, setIdUfOrigem] = useState(null || '');
  const [descricaoDestino, setDescricaoDestino] = useState('');
  const [cepDestino, setCepDestino] = useState('');
  const [logradouroDestino, setLogradouroDestino] = useState('');
  const [numeroDestino, setNumeroDestino] = useState('');
  const [complementoDestino, setComplementoDestino] = useState('');
  const [bairroDestino, setBairroDestino] = useState('');
  const [idUfDestino, setIdUfDestino] = useState(null || '');
  const [cidadeDestino, setCidadeDestino] = useState('');
  const [execRotaInternacional, setExecRotaInternacional] = useState(false);
  const [paisOrigem, setPaisOrigem] = useState('');
  const [ufInternacionalOrigem, setUfInternacionalOrigem] = useState('');
  const [cidadeInternacionalOrigem, setCidadeInternacionalOrigem] = useState('');
  const [paisDestino, setPaisDestino] = useState('');
  const [ufInternacionalDestino, setUfInternacionalDestino] = useState('');
  const [cidadeInternacionalDestino, setCidadeInternacionalDestino] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [viaCepUfOrigem, setViaCepUfOrigem] = useState('');
  const [viaCepUfDestino, setViaCepUfDestino] = useState('');
  const [viaCepCidadeDestino, setViaCepCidadeDestino] = useState('');
  const [viaCepIbgeCodDestino, setViaCepIbgeCodDestino] = useState(0);
  const [viaCepCidadeOrigem, setViaCepCidadeOrigem] = useState('');
  const [viaCepIbgeCodOrigem, setViaCepIbgeCodOrigem] = useState(0);
  const [error, setError] = useState(false);
  const [errorDest, setErrorDest] = useState(false);
  const [isFormInvalid1, setIsFormInvalid1] = useState(false);
  const [isFormInvalid2, setIsFormInvalid2] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipiosOrigem, setMunicipiosOrigem] = useState<MunicipiosType[]>([]);
  const [municipiosDestino, setMunicipiosDestino] = useState<MunicipiosType[]>([]);

  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  useEffect(() => {
    const loadEstadosPorPais = async () => {
      try {
        const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/1`);
        setUfs(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    };
    if (show) {
      loadEstadosPorPais();
    }
  }, [show]);

  const loadMunicipiosPorEstadoOrigem = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosOrigem(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadMunicipiosPorEstadoDestino = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosDestino(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const buscarCepOrigem = () => {
      if (cepOrigem.length < 9) {
        setCidadeOrigem('');
        setLogradouroOrigem('');
        setBairroOrigem('');
        setIdUfOrigem('');
        return;
      }
      setError(false);

      fetch(`https://viacep.com.br/ws/${cepOrigem}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }
          setLogradouroOrigem(data.logradouro);
          setBairroOrigem(data.bairro);
          setViaCepCidadeOrigem(data.localidade);
          setViaCepUfOrigem(data.uf);
          const ufId = ufs
            .filter((uf) => uf.sigla === data.uf)
            .map((filteredUfId) => filteredUfId.id);
          setIdUfOrigem(ufId.toString());
          const codIbgeFloat = data.ibge / 100000;
          setViaCepIbgeCodOrigem(data.ibge);
          setCidadeOrigem(data.localidade);
        })
        .catch((erro) => {
          console.log('error', erro);
          setError(true);
        });
    };
    if (show) {
      buscarCepOrigem();
    }
  }, [cepOrigem]);

  useEffect(() => {
    const buscarCepDestino = () => {
      if (cepDestino.length < 9) {
        setCidadeDestino('');
        setLogradouroDestino('');
        setBairroDestino('');
        setIdUfDestino('');
        return;
      }
      setErrorDest(false);
      fetch(`https://viacep.com.br/ws/${cepDestino}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setErrorDest(true);
            return;
          }
          setLogradouroDestino(data.logradouro);
          setBairroDestino(data.bairro);
          setViaCepCidadeDestino(data.localidade);
          setViaCepUfDestino(data.uf);
          const ufId = ufs
            .filter((uf) => uf.sigla === data.uf)
            .map((filteredUfId) => filteredUfId.id);
          setIdUfDestino(ufId.toString());
          setViaCepIbgeCodDestino(data.ibge);
          setCidadeDestino(data.localidade);
        })
        .catch((erro) => {
          console.log('error', erro);
          setErrorDest(true);
        });
    };
    if (show) {
      buscarCepDestino();
    }
  }, [cepDestino, show]);

  const handleRegisterNacional = async (event: any) => {
    event.preventDefault();
    if (flagDadosEnderecoObrigatorio === false) {
      if (
        !nome ||
        !cidadeOrigem ||
        !idUfOrigem ||
        !idUfDestino ||
        !cidadeDestino ||
        numeroOrigem === null ||
        numeroDestino === null
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid2(true);
        event.stopPropagation();
        return;
      }
    } else {
      if (
        !nome ||
        !descricaoOrigem ||
        !cepOrigem ||
        !logradouroOrigem ||
        numeroOrigem === null ||
        !bairroOrigem ||
        !cidadeOrigem ||
        !idUfOrigem ||
        !descricaoDestino ||
        !cepDestino ||
        !logradouroDestino ||
        numeroDestino === null ||
        !bairroDestino ||
        !idUfDestino ||
        !cidadeDestino
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid2(true);
        event.stopPropagation();
        return;
      }
    }

    const data = {
      nome: nome,
      descricaoOrigem: descricaoOrigem,
      cepOrigem: cepOrigem,
      logradouroOrigem: logradouroOrigem,
      numeroOrigem: numeroOrigem,
      complementoOrigem: complementoOrigem,
      bairroOrigem: bairroOrigem,
      cidadeOrigem: cidadeOrigem,
      idUFOrigem: parseInt(idUfOrigem, 10),
      descricaoDestino: descricaoDestino,
      cepDestino: cepDestino,
      logradouroDestino: logradouroDestino,
      numeroDestino: numeroDestino,
      complementoDestino: complementoDestino,
      bairroDestino: bairroDestino,
      idUFDestino: parseInt(idUfDestino, 10),
      cidadeDestino: cidadeDestino,
      cliente: clienteId,
      ativo: ativo,
      paisOrigem: paisOrigem,
      paisDestino: paisDestino,
      execRotaInternacional: execRotaInternacional,
      ufInternacionalOrigem: ufInternacionalOrigem,
      cidadeInternacionalOrigem: cidadeInternacionalOrigem,
      ufInternacionalDestino: ufInternacionalDestino,
      cidadeInternacionalDestino: cidadeInternacionalDestino,
      rotaAvulsa: isAvulsa ? true : false,
    };
    try {
      await axios.post('/rota', data);
      setIsDialogVisibleCadEfetuado(true);
      setTimeout(() => {
        limparFormulario();
        setIsDialogVisibleCadEfetuado(true);
        setIsFormInvalid2(false);
        if (isAvulsa) {
          const dataToCompare = { nome, cepOrigem, numeroOrigem, cepDestino, numeroDestino };
          loadDadosRotaIdNome(dataToCompare);
        } else {
          loadDadosRotaIdNome();
        }
        setShow(false);
      }, 2000);
    } catch {
      // const alerta = alert('Erro ao cadastrar');
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });
      return;
    }
  };

  const handleRegisterInternacional = async (event: any) => {
    event.preventDefault();
    if (
      !paisOrigem ||
      !paisDestino ||
      !ufInternacionalOrigem ||
      !cidadeInternacionalOrigem ||
      !ufInternacionalDestino ||
      !cidadeInternacionalDestino ||
      !nome ||
      !descricaoDestino ||
      !descricaoOrigem
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid1(true);
      event.stopPropagation();
      return;
    }

    const data = {
      nome: nome,
      descricaoOrigem: descricaoOrigem,
      cepOrigem: ' ',
      logradouroOrigem: ' ',
      numeroOrigem: 0,
      complementoOrigem: ' ',
      bairroOrigem: '',
      cidadeOrigem: ' ',
      idUfOrigem: idUfOrigem == '' ? null : idUfOrigem,
      descricaoDestino: descricaoDestino,
      cepDestino: ' ',
      logradouroDestino: ' ',
      numeroDestino: 0,
      complementoDestino: ' ',
      bairroDestino: ' ',
      idUfDestino: idUfDestino == '' ? null : idUfDestino,
      cidadeDestino: ' ',
      cliente: clienteId,
      ativo: ativo,
      paisOrigem: paisOrigem,
      paisDestino: paisDestino,
      execRotaInternacional: execRotaInternacional,
      ufInternacionalOrigem: ufInternacionalOrigem,
      cidadeInternacionalOrigem: cidadeInternacionalOrigem,
      ufInternacionalDestino: ufInternacionalDestino,
      cidadeInternacionalDestino: cidadeInternacionalDestino,
    };
    try {
      await axios.post('rota', data);
      setIsDialogVisibleCadEfetuado(true);
      setTimeout(() => {
        setShow(false);
        limparFormulario();
        if (isAvulsa) {
          const dataToCompare = { nome, cepOrigem, numeroOrigem, cepDestino, numeroDestino };
          loadDadosRotaIdNome(dataToCompare);
        } else {
          loadDadosRotaIdNome();
        }
        setIsDialogVisibleCadEfetuado(true);
        setIsFormInvalid1(false);
      }, 1000);
    } catch {
      // const alerta = alert('Erro ao cadastrar');
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });
      return;
    }
  };

  const limparFormulario = () => {
    setNome('');
    setDescricaoOrigem('');
    setCepOrigem('');
    setLogradouroOrigem('');
    setNumeroOrigem('');
    setComplementoOrigem('');
    setBairroOrigem('');
    setCidadeOrigem('');
    setIdUfOrigem('');
    setDescricaoDestino('');
    setCepDestino('');
    setLogradouroDestino('');
    setNumeroDestino('');
    setComplementoDestino('');
    setBairroDestino('');
    setIdUfDestino('');
    setCidadeDestino('');
    setAtivo(true);
    setPaisOrigem('');
    setPaisDestino('');
    setExecRotaInternacional(false);
    setUfInternacionalOrigem('');
    setCidadeInternacionalOrigem('');
    setUfInternacionalDestino('');
    setCidadeInternacionalDestino('');
  };

  const [flagExecutarRotaInternacional, setFlagExecutarRotaInternacional] = useState(false);
  const [flagDadosEnderecoObrigatorio, setFlagDadosEnderecoObrigatorio] = useState(false);

  useEffect(() => {
    const getParticularidades = async () => {
      try {
        const response = await axios.get(
          `/cliente/getParticularidadesCliente?cliente=${clienteId}`
        );

        setFlagExecutarRotaInternacional(response.data.flagExecutarRotaInternacional);
        setFlagDadosEnderecoObrigatorio(response.data.flagDadosEnderecoObrigatorio);
      } catch (err: any) {
        console.log(err);
      }
    };
    if (show) {
      getParticularidades();
    }
  }, [clienteId, show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cadastrar Rota</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          <Dialog
            header="Rota foi cadastrada com sucesso!"
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadEfetuado(false)}>OK</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />
          <Container fluid style={{ marginTop: '0px' }}>
            <Form.Row>
              {flagExecutarRotaInternacional === false ? (
                <Form.Group style={{ marginTop: '3%' }}>
                  <Form.Check
                    checked={execRotaInternacional}
                    onChange={(event) => {
                      setExecRotaInternacional(!execRotaInternacional);
                    }}
                    name="execRotaInternacional"
                    defaultChecked={execRotaInternacional}
                    type="switch"
                    id="custom-switch2"
                    label="Executar rota internacional?"
                    className="ml-3 mt-2"
                    disabled
                  />
                </Form.Group>
              ) : (
                <Form.Group style={{ marginTop: '3%' }}>
                  <Form.Check
                    checked={execRotaInternacional}
                    onChange={(event) => {
                      setExecRotaInternacional(!execRotaInternacional);
                    }}
                    name="execRotaInternacional"
                    defaultChecked={execRotaInternacional}
                    type="switch"
                    id="custom-switch2"
                    label="Executar rota internacional?"
                    className="ml-3 mt-2 mr-5"
                  />
                </Form.Group>
              )}

              {isAvulsa ? (
                <Form.Group style={{ marginTop: '3%' }}>
                  <Form.Check
                    checked={true}
                    defaultChecked={true}
                    name="rotaAvulsa"
                    type="switch"
                    id="rotaAvulsa"
                    label="Rota avulsa?"
                    className="ml-4 mt-2"
                    disabled
                  />
                </Form.Group>
              ) : null}
            </Form.Row>

            {execRotaInternacional ? (
              <Form noValidate validated={isFormInvalid1} onSubmit={handleRegisterInternacional}>
                <Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className="required">Nome da Rota:</Form.Label>

                    <Form.Control
                      value={nome}
                      onChange={(event) => {
                        setNome(event.target.value);
                      }}
                      id="nome"
                      name="nome"
                      placeholder=""
                      required
                      style={{ width: '100%' }}
                      maxLength={64}
                    />
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} style={{ marginTop: 1, marginBottom: 1 }}>
                      <hr />
                    </Form.Group>
                  </Form.Row>
                  <Form.Text>Dados da Origem</Form.Text>

                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className="required">Descrição:</Form.Label>

                    <Form.Control
                      value={descricaoOrigem}
                      onChange={(event) => {
                        setDescricaoOrigem(event.target.value);
                      }}
                      id="descricaoOrigem"
                      name="descricaoOrigem"
                      placeholder=""
                      required
                      style={{ width: '100%' }}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className="required">País:</Form.Label>

                    <Form.Control
                      value={paisOrigem}
                      onChange={(event) => {
                        setPaisOrigem(event.target.value);
                      }}
                      id="paisOrigem"
                      name="paisOrigem"
                      placeholder=""
                      required
                      style={{ width: '50%' }}
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="formGridAddress1"
                      style={{ marginLeft: '1.5%' }}
                    >
                      <Form.Label className="required">UF:</Form.Label>

                      <Form.Control
                        value={ufInternacionalOrigem}
                        onChange={(event: any) => {
                          setUfInternacionalOrigem(event.target.value);
                        }}
                        id="ufInternacionalOrigem"
                        name="ufInternacionalOrigem"
                        required
                        style={{ width: '20%' }}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className="required">Cidade:</Form.Label>

                      <Form.Control
                        value={cidadeInternacionalOrigem}
                        onChange={(event) => {
                          setCidadeInternacionalOrigem(event.target.value);
                        }}
                        id="cidadeInternacionalOrigem"
                        name="cidadeInternacionalOrigem"
                        placeholder=""
                        required
                        style={{ width: '97%' }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} style={{ marginTop: 1, marginBottom: 1 }}>
                      <hr />
                    </Form.Group>
                  </Form.Row>
                  <Form.Text>Dados do Destino</Form.Text>

                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className="required">Descrição:</Form.Label>

                    <Form.Control
                      value={descricaoDestino}
                      onChange={(event) => {
                        setDescricaoDestino(event.target.value);
                      }}
                      id="descricaoDestino"
                      name="descricaoDestino"
                      placeholder=""
                      required
                      style={{ width: '100%' }}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className="required">País:</Form.Label>

                    <Form.Control
                      value={paisDestino}
                      onChange={(event) => {
                        setPaisDestino(event.target.value);
                      }}
                      id="paisDestino"
                      name="paisDestino"
                      placeholder=""
                      required
                      style={{ width: '50%' }}
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="formGridAddress1"
                      style={{ marginLeft: '1.5%' }}
                    >
                      <Form.Label className="required">UF:</Form.Label>

                      <Form.Control
                        value={ufInternacionalDestino}
                        onChange={(event: any) => {
                          setUfInternacionalDestino(event.target.value);
                        }}
                        id="ufInternacionalDestino"
                        name="ufInternacionalDestino"
                        required
                        style={{ width: '20%' }}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className="required">Cidade:</Form.Label>

                      <Form.Control
                        value={cidadeInternacionalDestino}
                        onChange={(event) => {
                          setCidadeInternacionalDestino(event.target.value);
                        }}
                        id="cidadeInternacionalDestino"
                        name="cidadeInternacionalDestino"
                        placeholder=""
                        required
                        style={{ width: '97%' }}
                      />
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Form>
            ) : (
              <Form noValidate validated={isFormInvalid2} onSubmit={handleRegisterNacional}>
                <Form.Group>
                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-2">
                    <Form.Label className="required">Nome da Rota:</Form.Label>

                    <Form.Control
                      value={nome}
                      onChange={(event) => {
                        setNome(event.target.value);
                      }}
                      id="nome"
                      name="nome"
                      placeholder=""
                      required
                      maxLength={64}
                    />
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} style={{ marginTop: 1, marginBottom: 1 }}>
                      <hr />
                    </Form.Group>
                  </Form.Row>
                  <Form.Text>Dados da Origem</Form.Text>
                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Descrição:
                    </Form.Label>

                    <Form.Control
                      value={descricaoOrigem}
                      onChange={(event) => {
                        setDescricaoOrigem(event.target.value);
                      }}
                      id="descricaoOrigem"
                      name="descricaoOrigem"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      CEP:
                    </Form.Label>

                    <Form.Control
                      value={cepOrigem}
                      onChange={(event) => {
                        setCepOrigem(maskCep(event.target.value));
                      }}
                      id="cepOrigem"
                      name="cepOrigem"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={9}
                      minLength={9}
                    />
                    {error && (
                      <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>
                    )}
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '1%' }}>
                      <Form.Label
                        className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}
                      >
                        Logradouro:
                      </Form.Label>

                      <Form.Control
                        value={logradouroOrigem}
                        onChange={(event) => {
                          setLogradouroOrigem(event.target.value);
                        }}
                        id="logradouroOrigem"
                        name="logradouroOrigem"
                        placeholder=""
                        required={flagDadosEnderecoObrigatorio}
                        maxLength={64}
                        style={{ width: '100%' }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className={'required'}>Numero:</Form.Label>

                      <Form.Control
                        value={numeroOrigem}
                        onChange={(event) => {
                          setNumeroOrigem(event.target.value);
                        }}
                        id="numeroOrigem"
                        name="numeroOrigem"
                        placeholder=""
                        required
                        maxLength={8}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '1%' }}>
                      <Form.Label>Complemento:</Form.Label>

                      <Form.Control
                        value={complementoOrigem}
                        onChange={(event) => {
                          setComplementoOrigem(event.target.value);
                        }}
                        id="complementoOrigem"
                        name="complementoOrigem"
                        placeholder=""
                        style={{ width: '100%' }}
                        maxLength={64}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label
                        className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}
                      >
                        Bairro:
                      </Form.Label>

                      <Form.Control
                        value={bairroOrigem}
                        onChange={(event) => {
                          setBairroOrigem(event.target.value);
                        }}
                        id="bairroOrigem"
                        name="bairroOrigem"
                        placeholder=""
                        required={flagDadosEnderecoObrigatorio}
                        style={{ width: '97%' }}
                        maxLength={64}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className="ml-3">
                      <Form.Label className="required">UF:</Form.Label>

                      <Form.Control
                        value={idUfOrigem}
                        onChange={(event: any) => {
                          setIdUfOrigem(event.target.value);
                          loadMunicipiosPorEstadoOrigem(parseInt(event.target.value));
                        }}
                        id="idUfOrigem"
                        name="idUfOrigem"
                        required
                        as="select"
                        defaultValue=""
                      >
                        <option></option>
                        {ufs.map((uf) => (
                          <option value={uf.id} key={uf.id}>
                            {uf.sigla}
                          </option>
                        ))}
                        <option value={idUfOrigem} key={idUfOrigem}>
                          {idUfOrigem}
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className="required">Município:</Form.Label>

                      <Form.Control
                        value={cidadeOrigem}
                        onChange={(event) => {
                          setCidadeOrigem(event.target.value);
                        }}
                        id="cidadeOrigem"
                        name="cidadeOrigem"
                        placeholder=""
                        required
                        as="select"
                        defaultValue=""
                        style={{ width: '97%' }}
                        disabled={!idUfOrigem}
                      >
                        <option value="">Selecione uma opção</option>
                        {/* {municipiosOrigem
                        .filter((municipio) => municipio.idCodigoIbge === viaCepIbgeCodOrigem)
                        .map((filteredMunicipio) => (
                          <option
                            key={filteredMunicipio.nomeMunicipio}
                            value={filteredMunicipio.nomeMunicipio}
                          >
                            {filteredMunicipio.nomeMunicipio}
                          </option>
                        ))} */}
                        {viaCepCidadeOrigem ? (
                          <option key={cidadeOrigem} value={cidadeOrigem}>
                            {viaCepCidadeOrigem}
                          </option>
                        ) : (
                          municipiosOrigem.map((municipio) => (
                            <option key={municipio.nomeMunicipio} value={municipio.nomeMunicipio}>
                              {municipio.nomeMunicipio}
                            </option>
                          ))
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} style={{ marginTop: 1, marginBottom: 1 }}>
                      <hr />
                    </Form.Group>
                  </Form.Row>
                  <Form.Text>Dados do Destino</Form.Text>

                  <Form.Group as={Col} controlId="formGridAddress1" className="mt-4">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      Descrição:
                    </Form.Label>

                    <Form.Control
                      value={descricaoDestino}
                      onChange={(event) => {
                        setDescricaoDestino(event.target.value);
                      }}
                      id="descricaoDestino"
                      name="descricaoDestino"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={64}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}>
                      CEP:
                    </Form.Label>

                    <Form.Control
                      value={cepDestino}
                      onChange={(event) => {
                        setCepDestino(maskCep(event.target.value));
                      }}
                      id="cepDestino"
                      name="cepDestino"
                      placeholder=""
                      required={flagDadosEnderecoObrigatorio}
                      maxLength={9}
                      minLength={9}
                    />
                    {errorDest && (
                      <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>
                    )}
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className="ml-3">
                      <Form.Label
                        className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}
                      >
                        Logradouro:
                      </Form.Label>

                      <Form.Control
                        value={logradouroDestino}
                        onChange={(event) => {
                          setLogradouroDestino(event.target.value);
                        }}
                        id="logradouroDestino"
                        name="logradouroDestino"
                        placeholder=""
                        required={flagDadosEnderecoObrigatorio}
                        style={{ width: '100%' }}
                        maxLength={64}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className={'required'}>Numero:</Form.Label>

                      <Form.Control
                        value={numeroDestino}
                        onChange={(event) => {
                          setNumeroDestino(event.target.value);
                        }}
                        id="numeroDestino"
                        name="numeroDestino"
                        placeholder=""
                        required
                        maxLength={8}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className="ml-3">
                      <Form.Label>Complemento:</Form.Label>

                      <Form.Control
                        value={complementoDestino}
                        onChange={(event) => {
                          setComplementoDestino(event.target.value);
                        }}
                        id="complementoDestino"
                        name="complementoDestino"
                        placeholder=""
                        style={{ width: '100%' }}
                        maxLength={64}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label
                        className={flagDadosEnderecoObrigatorio === true ? 'required' : ''}
                      >
                        Bairro:
                      </Form.Label>

                      <Form.Control
                        value={bairroDestino}
                        onChange={(event) => {
                          setBairroDestino(event.target.value);
                        }}
                        id="bairroDestino"
                        name="bairroDestino"
                        placeholder=""
                        required={flagDadosEnderecoObrigatorio}
                        style={{ width: '97%' }}
                        maxLength={64}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAddress1" className="ml-3">
                      <Form.Label className="required">UF:</Form.Label>

                      <Form.Control
                        value={idUfDestino}
                        onChange={(event: any) => {
                          setIdUfDestino(event.target.value);
                          loadMunicipiosPorEstadoDestino(parseInt(event.target.value));
                        }}
                        id="idUfDestino"
                        name="idUfDestino"
                        required
                        as="select"
                        defaultValue=""
                      >
                        <option></option>
                        {ufs.map((uf) => (
                          <option value={uf.id} key={uf.id}>
                            {uf.sigla}
                          </option>
                        ))}
                        <option value={idUfDestino} key={idUfDestino}>
                          {viaCepUfDestino}
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress1">
                      <Form.Label className="required">Município:</Form.Label>

                      <Form.Control
                        value={cidadeDestino}
                        onChange={(event) => {
                          setCidadeDestino(event.target.value);
                        }}
                        id="cidadeDestino"
                        name="cidadeDestino"
                        placeholder=""
                        required
                        as="select"
                        defaultValue=""
                        style={{ width: '97%' }}
                        disabled={!idUfDestino}
                      >
                        <option value="">Selecione uma opção</option>
                        {/* {municipiosDestino
                        .filter((municipio) => municipio.idCodigoIbge === viaCepIbgeCodDestino)
                        .map((filteredMunicipio) => (
                          <option
                            key={filteredMunicipio.nomeMunicipio}
                            value={filteredMunicipio.nomeMunicipio}
                          >
                            {filteredMunicipio.nomeMunicipio}
                          </option>
                        ))} */}
                        {viaCepCidadeDestino ? (
                          <option key={cidadeDestino} value={cidadeDestino}>
                            {viaCepCidadeDestino}
                          </option>
                        ) : (
                          municipiosDestino.map((municipio) => (
                            <option key={municipio.nomeMunicipio} value={municipio.nomeMunicipio}>
                              {municipio.nomeMunicipio}
                            </option>
                          ))
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Form>
            )}
          </Container>
        </div>
      </Modal.Body>
      {execRotaInternacional ? (
        <Modal.Footer>
          <Button
            style={{ color: '#000', fontWeight: 'bold' }}
            variant="primary"
            onClick={handleRegisterInternacional}
          >
            Salvar
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button
            style={{ color: '#000', fontWeight: 'bold' }}
            variant="primary"
            onClick={handleRegisterNacional}
          >
            Salvar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalCadastrarRotaPorSM;
