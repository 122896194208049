import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { ListaPontos, Pontos, Transportadores } from '../../api/Types/agendarViagensType';
import FileList from '../../components/FileList';
import { Tela } from '../../components/Tela';
import Upload from '../../components/Upload';
import { FileProvider } from '../../context/files';
import { useToast } from '../../hooks/Toast';
import { getCookieSessionData } from '../../services/cookieService';
import { Content } from '../Condutor/CadastroCondutor/styles';
import api from '../../config/axiosMaquina';
import { FormatDateTime } from '../../util/format';
import { soNumeros } from '../../util/Validacoes/Validacoes';

export const ImportarPreSm = () => {
  const [csvName, setCsvName] = useState('');
  const [idEmbarcador, setIdEmbarcador] = useState(0);
  const [itemsNotFound, setItemsNotFound] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();
  const { clienteId, usuarioId, cnpj } = getCookieSessionData().usuarioVO;
  const [transportadores, setTrasportadores] = useState<Transportadores[]>([]);
  const [pontosCliente, setPontosCliente] = useState<ListaPontos[]>([]);
  const [idSmEnviada, setIdSmEnviada] = useState(0);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const getEmbarcadorId = async () => {
    const res = await api.get(`/embarcador/embarcador-rel-cliente-por-cnpj?cnpj=${cnpj}`);
    setIdEmbarcador(res.data[0]?.idEmbarcador);
  };

  const handleCsv = async () => {
    setLoading(true);
    const response = await api.get(`/foto/downloadFile/{file}?file=${csvName}`);
    const body = response.data
      .split('\n')[1]
      .split(';')
      .slice(1, response.data.split('\n')[1].split(';').length);
    let planoViagem: Pontos[] = [];
    const arrayErros: string[] = [];
    for (let i = 0; i < body.length; i += 1) {
      if (i % 4 === 0) {
        if (body[i]) {
          const position = i / 4 + 1;
          const idPonto = pontosCliente.find(
            (each: ListaPontos) => each.nomePonto.toLowerCase() === body[i].toLowerCase()
          )?.idPonto;
          if (idPonto) {
            planoViagem.push({
              idPonto,
              dataPonto: FormatDateTime(body[i + 1], body[i + 2]),
              idUsuario: usuarioId,
              idSm: null,
              idSmPv: null,
              sequencia: position,
              remessa: body[i + 3],
            });
          } else {
            arrayErros.push(`${body[i]} (Ponto ${position})`);
          }
        }
      }
    }
    planoViagem = planoViagem.map((each: Pontos, index: number) => {
      return {
        ...each,
        tipoPonto: index === 0 ? 'O' : index === planoViagem.length - 1 ? 'D' : 'I',
      };
    });
    const idCli = parseInt(response.data.split('\n')[1].split(';')[0], 10);
    const idClienteRecuperado = transportadores.find(
      (each: Transportadores) => each.idCliente === idCli
    )?.idCliente;
    console.log(idClienteRecuperado);
    console.log(transportadores);
    if (!idClienteRecuperado) {
      arrayErros.push(`${idCli} (Transportador)`);
    }

    console.log(arrayErros);
    console.log(idClienteRecuperado);
    // TUDO CERTO, FAZENDO REQUISIÇÃO
    if (!arrayErros.length && idClienteRecuperado) {
      setItemsNotFound([]);
      console.log('TUDO CERTO, FAZENDO REQUISIÇÃO');
      const data = {
        inicioDataViagem: planoViagem[0].dataPonto,
        inicioHoraViagem: planoViagem[0].dataPonto,
        fimDataViagem: planoViagem[planoViagem.length - 1].dataPonto,
        fimHoraViagem: planoViagem[planoViagem.length - 1].dataPonto,
        idCliente: idClienteRecuperado,
        idEmbarcador,
        solicitacaoMonitoramentoPlanoViagemVO: planoViagem,
      };
      console.log('dados cadastrados: ', data);
      try {
        const res = await api.post('/solicitacao-monitoramento/cadastrar-pre-sm', data);
        setIdSmEnviada(soNumeros(res.data));
        setIsDialogVisible(true);
      } catch (err: any) {
        console.log(err.response);
        addToast({
          title: 'Erro!',
          description: 'Erro ao cadastrar.',
          type: 'error',
        });
      }
    } else {
      setItemsNotFound(arrayErros);
      console.log('ERRO, FALTANDO ITENS');
      setShow(true);
    }
    setLoading(false);
    setCsvName('');
  };

  const fetchTransportadores = async () => {
    if (cnpj) {
      const res = await api.get(`/embarcador/embarcador-rel-cliente-por-cnpj?cnpj=${cnpj}`);
      setTrasportadores(res.data);
    }
  };

  const fetchPontos = async () => {
    if (clienteId) {
      const response = await api.get(
        `/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${clienteId}`
      );
      const filtered = response.data.filter((ponto: ListaPontos) => ponto.ativo);
      setPontosCliente(filtered);
    }
  };

  useEffect(() => {
    if (cnpj) {
      fetchTransportadores();
      getEmbarcadorId();
    }
  }, [cnpj]);

  useEffect(() => {
    fetchPontos();
  }, [clienteId]);

  useEffect(() => {
    if (csvName) {
      setLoading(true);
      setTimeout(() => handleCsv(), 2000);
    }
  }, [csvName]);

  return (
    <Tela nome="Importar CSV" caminho="Operacional > Importar CSV" loading={loading}>
      <Content>
        <FileProvider foto={csvName} setFoto={setCsvName} name="teste">
          <Upload acceptedFiles={['text/cvs', '.csv']} />
          <FileList />
        </FileProvider>
      </Content>
      <Dialog
        header="Seu Agendamento foi criado com sucesso!"
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Número SM: {idSmEnviada}</p>
            <Button onClick={() => history.push('/pre-agendamento-viagens/1')}>OK</Button>
          </div>
        }
        visible={isDialogVisible}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisible(false)}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          {itemsNotFound.length ? (
            <>
              <p>Pre-SM não enviada!</p>
              <p>Os seguintes itens não foram encontrados:</p>
              <ol>
                {itemsNotFound.map((each: string, index: number) => (
                  <li key={index}>{each}</li>
                ))}
              </ol>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => setShow(false)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Tela>
  );
};
