/* eslint-disable */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFiles } from '../../context/files';
import { IoMdCloudUpload } from 'react-icons/io';
import { DropContainer, UploadMessage } from './styles';

type PropsType = {
  acceptedFiles?: string[];
};

function Upload({
  acceptedFiles = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'],
}: PropsType) {
  const { handleUpload, uploadedFiles: files } = useFiles();

  const onDrop = useCallback(
    (files) => {
      handleUpload(files);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: acceptedFiles,
    onDrop,
    maxFiles: 1,
  });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Clique ou arraste seu arquivo até aqui...
          <IoMdCloudUpload size={60} />
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          Só é possivel fazer o upload de um arquivo nos formatos: {acceptedFiles.join(',')}.
        </UploadMessage>
      );
    }

    if (files.length >= 1) {
      return !isDragAccept;
    }

    return <UploadMessage type="success">Solte seu arquivo aqui</UploadMessage>;
  }, [isDragActive, isDragReject]);
  return (
    <DropContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {renderDragMessage()}
    </DropContainer>
  );
}

export default Upload;
