/* eslint-disable */
import './styles.css';
import { useEffect, useState } from 'react';
import { useToast } from '../../hooks/Toast';
import { CorType } from '../../Types/MarcaCorTypes';
import api from '../../config/axiosMaquina';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { useHistory } from 'react-router-dom';

type LineProps = {
  cor: CorType;
  setOpenModal: (openModal: boolean) => void;
  setCloseModal: (closeModal: boolean) => void;
  setIdDelete: (idDelete: number) => void;
  setIdEdit: (idEdit: number) => void;
  idAtual: number;
  canEdit?: boolean;
  canDelete?: boolean;
};

export const CorRow = ({
  cor,
  setOpenModal,
  setCloseModal,
  setIdDelete,
  setIdEdit,
  idAtual,
  canEdit,
  canDelete,
}: LineProps) => {
  const [disabled, setDisabled] = useState(true);
  const [model, setModel] = useState<CorType>(cor);
  const { addToast } = useToast();
  const [nomeOriginal, setNomeOriginal] = useState(cor.corNome);
  const history = useHistory();

  const handleEdit = (id: number) => {
    setDisabled(false);
    setIdEdit(id);
  };

  const handleCancel = () => {
    setDisabled(true);
    setIdEdit(0);
  };

  useEffect(() => {
    setModel(cor);
  }, [cor, disabled, idAtual]);

  async function handleSubmit() {
    if (model.corNome.trim() != '' && model.corNome != nomeOriginal) {
      try {
        const data = {
          corBlAtivo: model.statusAtivo,
          corCdId: model.id,
          corNuOrdem: model.corOrdem,
          corTxNome: model.corNome,
        };
        await api.put(`/cor?idCor=${model.id}`, data);
        addToast({
          title: 'Sucesso!',
          description: 'Cor atualizada com sucesso!',
          type: 'success',
        });
        setDisabled(true);
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } catch (err: any) {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao editar registro',
          type: 'error',
        });
      }
    } else {
      addToast({
        title: 'Info',
        description: 'Para realizar a edição, atualize o nome.',
        type: 'info',
      });
    }
  }

  return (
    <div>
      <div
        className="lineGrid2"
        style={{ filter: idAtual != model.id ? '' : 'drop-shadow(0 0 0.5rem #d8d8d8)' }}
      >
        <input
          disabled={idAtual != model.id}
          value={model.corNome}
          onChange={(e: any) => {
            setModel({
              ...model,
              corNome: e.target.value,
            });
          }}
          type="text"
          maxLength={64}
        />
        {!disabled && idAtual === model.id && idAtual != 0 ? (
          <ButtonTooltip onClick={handleCancel} type="Cancelar" />
        ) : (
          <ButtonTooltip
            onClick={() => {
              if (canEdit) {
                handleEdit(model.id);
              } else {
                history.push('/acessonegado');
              }
            }}
            type="Editar"
          />
        )}
        {!disabled && idAtual === model.id && idAtual != 0 ? (
          <ButtonTooltip onClick={handleSubmit} type="Enviar" />
        ) : (
          <ButtonTooltip
            onClick={() => {
              if (canDelete) {
                setOpenModal(true);
                if (model.id != undefined) {
                  setIdDelete(model.id);
                }
              } else {
                history.push('/acessonegado');
              }
            }}
            type="Excluir"
          />
        )}
      </div>
    </div>
  );
};
