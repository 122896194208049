/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoPencilSharp } from 'react-icons/io5';
import { ViagemTransitTime } from '../../Types/PainelLSCTypes';
import { dateNow, getFullTime } from '../../util/format';

type PropsType = {
  each: any;
  acrescentar: (index: number) => void;
  diminuir: (index: number) => void;
  index: number;
  lastIndex: number;
  onPartidaHoraChange: (
    idPlanoViagem: number,
    data: string,
    hora: string,
    dataHora: string
  ) => void;
  findFirstIndex: () => number;
};

const GridEditavelPontos: FC<PropsType> = ({
  each,
  acrescentar,
  diminuir,
  index,
  lastIndex,
  onPartidaHoraChange,
  findFirstIndex,
}) => {
  const [enabled, setEnabled] = useState(false);
  const [hora, setHora] = useState('');
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState<string>('');
  let dataHora = `${data}T${hora}`;

  const handleDataHora = () => {
    if (data.length === 10 && hora.length === 5) {
      setLoading(true);
      onPartidaHoraChange(each.idPontoViagem, data, hora, new Date(dataHora).toISOString());
      setEnabled(false);
      setLoading(false);
      setData('');
      setHora('');
    }
  };

  return (
    <tr key={each.numSeqPonto}>
      <td>{each.nomePonto}</td>
      <td>{each.remessa}</td>
      <td>
        {new Date(each.dataChegadaPlanejada || each.dataReplanejada).toLocaleDateString('pt-br', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}{' '}
        {new Date(each.dataChegadaPlanejada || each.dataReplanejada)
          .toLocaleTimeString()
          .slice(0, -3)}
      </td>
      <td>
        <input
          className="input-grid"
          value={each.data ? each.data : data}
          type="date"
          min={dateNow()}
          onChange={(e) => {
            setData(e.target.value);
            console.log(each);
          }}
          disabled={!enabled}
          style={{ color: !enabled ? '#969696' : '#333333' }}
        ></input>
      </td>
      <td>
        <input
          className="input-grid"
          value={each.hora ? each.hora : hora}
          type="time"
          onChange={(e) => {
            setHora(e.target.value);
          }}
          disabled={!enabled || !data}
          style={{ color: !enabled ? '#969696' : '#333333' }}
        ></input>
      </td>
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <>
          <td>
            <button
              disabled={index <= findFirstIndex() || !!enabled || each.percPercorridoPonto !== 0}
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                width: 20,
                height: 20,
                padding: 0,
                marginRight: 15,
                marginBottom: 2,
              }}
              onClick={() => {
                acrescentar(index);
              }}
            >
              <i title="Subir posição" className="pi pi-chevron-up"></i>
            </button>
          </td>
          <td>
            <button
              disabled={index === lastIndex || !!enabled || each.percPercorridoPonto !== 0}
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                width: 20,
                height: 20,
                padding: 0,
                marginRight: 15,
                marginBottom: 2,
              }}
              onClick={() => {
                diminuir(index);
              }}
            >
              <i title="Descer posição" className="pi pi-chevron-down"></i>
            </button>
          </td>
          <td>
            <button
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                width: 20,
                height: 20,
                padding: 0,
                marginRight: 15,
                marginBottom: 2,
              }}
              onClick={() => {
                setEnabled(!enabled);
              }}
              disabled={each.percPercorridoPonto !== 0}
            >
              {enabled ? (
                <AiOutlineCheck size="20px" id="icon" onClick={handleDataHora} />
              ) : (
                <IoPencilSharp size={18} color="#2f2f2f" />
              )}
            </button>
          </td>
        </>
      )}
    </tr>
  );
};

export default GridEditavelPontos;
