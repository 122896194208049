import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Table from '../../components/TableComponent';
import { Tela } from '../../components/Tela';
import api from '../../config/axiosMaquina';
import { obterDataHora } from '../../util/date';
import { pesquisar } from '../../util/format';

type TecnologiasRastreamento = {
  dataEvento: string;
  idTecnologia: number;
  nome: string;
  ultimaPosicao: string;
  ultimaAtualizacao: string;
};

const AtualizacaoTecnologias = () => {
  const history = useHistory();
  const { page }: any = useParams();
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const titles = {
    id: 'Id',
    nome: 'Tecnologia',
    ultimaAtualizacao: 'Última Posição',
    ultimaPosicao: 'Última Atualização',
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: any) => history.push(`/listar/historico-tecnologias/${pageNumber}`);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/tecnologia-rastreamento/evento-tecnologia`);
      const array = response.data
        .filter((d: TecnologiasRastreamento) => d)
        .map((each: TecnologiasRastreamento) => {
          return {
            nome: each.nome || '',
            ultimaPosicao: obterDataHora(each.ultimaPosicao, true),
            ultimaAtualizacao: obterDataHora(each.ultimaAtualizacao, true),
          };
        });
      setData(array);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push(`/listar/historico-tecnologias/1`);
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Tecnologias"
      caminho="Operacional > Tecnologias"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      <Table loading={loadingSearch || loading} items={currentData} headers={titles} />
    </Tela>
  );
};

export default AtualizacaoTecnologias;
