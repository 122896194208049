/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import ModalCadastroTipoComunicacao from '../Modal';

type TecnologiaRastreamento = {
  terCdId: number;
  terTxNome: string;
  idTipoComunicacao?: number[];
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const FormTecnologiaRastreamento: React.FC<Props> = ({ canEdit, canDelete }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [tipoComunicacaoId, setTipoComunicacaoId] = useState<any>([]);
  const [model, setModel] = useState<TecnologiaRastreamento>({
    terCdId: 0,
    terTxNome: '',
    idTipoComunicacao: tipoComunicacaoId.map((d: any) => d.code),
  });
  const [tipoComunicacaoList, setTipoComunicacaoList] = useState<any[]>([]);
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { terTxNome } = model;

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`/tecnologia-rastreamento/tecnologiarastreamento/${id}`);
        setModel(response.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    if (!tipoComunicacaoList.length) {
      setLoading(true);
      try {
        const _idTipoComunicacao = await axios.get('/tipo-comunicacao/grid');
        setTipoComunicacaoList(_idTipoComunicacao.data);
        if (id) {
          const response = await axios.get(
            `/tipo-comunicacao/listarIdNomePorTecnologia?tecnologia=${id}`
          );
          let arr: any[] = [];
          const idsTipoComunicacao = response.data.map((r: any) =>
            arr.push({ name: r.nome, code: r.id })
          );
          arr = arr.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.code === value.code && t.name === value.name)
          );

          setTipoComunicacaoId(arr);
        }
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  console.log(tipoComunicacaoId);

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!terTxNome) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const array: any[] = [];
    tipoComunicacaoId.map((t: any) => array.push(t.code));
    if (id) {
      setLoading(true);
      try {
        await axios.put(`/tecnologia-rastreamento/atualizar?idTecnologia=${id}`, {
          ...model,
          idTipoComunicacao: array,
        });
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        await axios.post('/tecnologia-rastreamento/tecnologiarastreamento', {
          ...model,
          terNuOrdem: 0,
          terBlAtivo: true,
          idTipoComunicacao: array,
        });
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    try {
      await axios.put(`/tecnologia-rastreamento/delete-logico?idTecnologia=${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      history.push('/listar/tecnologias-de-rastreamento/1');
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  const selectTipos = tipoComunicacaoList.map((tipo) => ({
    name: tipo.nome,
    code: tipo.id,
  }));

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Tecnologia de rastreamento ${id ? 'alterada' : 'cadastrada'} com sucesso!`}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/tecnologias-de-rastreamento/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Tecnologia de rastreamento excluída com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Tecnologia de rastreamento</h2>
          <h6 className="subtitulo">{`Cadastro > Tecnologia de rastreamento > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="painel">
              <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
              <Form
                className="form-sm"
                noValidate
                validated={isFormInvalid}
                onSubmit={handleSubmit}
              >
                <Form.Row>
                  <Form.Group className="row-modal">
                    <Form.Label className="requiredField">Nome</Form.Label>
                    <Form.Control
                      value={terTxNome}
                      onChange={(e) => {
                        setModel({ ...model, terTxNome: e.target.value });
                      }}
                      type="text"
                      required
                      maxLength={32}
                      minLength={1}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group className="row-modal">
                    <Form.Label>Tipo de Comunicação</Form.Label>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <MultiSelect
                        style={{
                          width: '100%',
                          height: 40,
                          lineHeight: 1.4,
                          borderColor:
                            tipoComunicacaoId.length <= 0 && isFormInvalid ? '#dc3545' : '',
                        }}
                        emptyFilterMessage={<Button />}
                        display="chip"
                        optionLabel="name"
                        value={tipoComunicacaoId}
                        options={selectTipos}
                        onChange={(e: any) => setTipoComunicacaoId(e.target.value)}
                        filter
                      />
                      <ModalCadastroTipoComunicacao />
                    </div>
                  </Form.Group>
                </Form.Row>
                <ButtonsForm canEdit={canEdit} />
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default FormTecnologiaRastreamento;
