import React, { FC, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Spinner } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import ModalProgressao from './ModalProgressao';
import api from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { obterDataHora } from '../../util/date';

export type Progressao = {
  blStatusInicializacao: boolean;
  desStatusInicioSm: string;
  dtHrChegadaProxPonto: string;
  dtHrUltimoEvento: string;
  dtInicioRealSm: string;
  hrInicioRealSm: string;
  idCarreta: number;
  idCarreta2: number;
  idCliente: number;
  idSm: number;
  idveiculo: number;
  monitSmProgresViagPontoVO: {
    dataChegadaPlanejada: string;
    dataReplanejada: string;
    distanciaProxPonto: number;
    dtHrCalculadaChegadaPonto: string;
    dtHrCalculadaProxPonto: string;
    dtHrChegadaPonto: string;
    dtHrInicioRealSM: string;
    dtHrInicioSM: string;
    dtHrPlanejadaViagemPonto: string;
    dtHrUltimoEvento: string;
    enderecoPonto: string;
    hintPonto: string;
    idLsc: number;
    idPlanoViagem: number;
    idPontoPlanejamentoSmo: number;
    idPontoSmo: number;
    idPontoViagem: number;
    idPontoViagemReplanejada: number;
    idSm: number;
    latitudePonto: number;
    longitudePonto: number;
    nomePonto: string;
    numSeqPonto: number;
    origemDestinoPonto: string;
    percPercorridoPonto: number;
    previsao: string;
    previsaoComparadas: string;
    remessa: any;
    statusConcluidoPonto: boolean;
    statusPonto: string;
    tempoProxPonto: number;
  }[];
  nomProxPonto: string;
  percPercorrido: number;
  percorrido: string;
  placas: string;
  proximoPonto: string;
  statusSm: string;
};

type Ponto = {
  dataChegadaPlanejada: string;
  dataReplanejada: string;
  distanciaProxPonto: number;
  dtHrCalculadaChegadaPonto: string;
  dtHrCalculadaProxPonto: string;
  dtHrChegadaPonto: string;
  dtHrInicioRealSM: string;
  dtHrInicioSM: string;
  dtHrPlanejadaViagemPonto: string;
  dtHrUltimoEvento: string;
  enderecoPonto: string;
  hintPonto: string;
  idLsc: number;
  idPlanoViagem: number;
  idPontoPlanejamentoSmo: number;
  idPontoSmo: number;
  idPontoViagem: number;
  idPontoViagemReplanejada: number;
  idSm: number;
  latitudePonto: number;
  longitudePonto: number;
  nomePonto: string;
  numSeqPonto: number;
  origemDestinoPonto: string;
  percPercorridoPonto: number;
  previsao: string;
  previsaoComparadas: string;
  remessa: any;
  statusConcluidoPonto: boolean;
  statusPonto: string;
  tempoProxPonto: number;
};

const ProgressaoViagens: FC = () => {
  const [parametroLogistico, setParametroLogistico] = useState<{
    tempoAtualizacaoDadosEstaticosEmMinutos: number | undefined;
  }>();
  // const [modalData, setModalData] = useState<{ index: number; sm: number; ponto: Ponto }>();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataProgressao, setDataProgressao] = useState<Progressao[]>([]);
  const usuario = getCookieSessionData().usuarioVO.usuarioId;
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false);

  // const handleClickProgress = (ponto: Ponto, index: number, sm: number) => {
  //   setModalData({
  //     index: index,
  //     ponto: ponto,
  //     sm: sm,
  //   });
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   if (modalData) {
  //     setShow(true);
  //     setLoading(false);
  //   } else {
  //     setShow(false);
  //     setLoading(false);
  //   }
  // }, [modalData]);

  useEffect(() => {
    api.get('parametro-logistico').then((response) => setParametroLogistico(response.data));
  }, []);

  useEffect(() => {
    const loadProgressaoViagem = async () => {
      if (!parametroLogistico || !parametroLogistico.tempoAtualizacaoDadosEstaticosEmMinutos) {
        return;
      }
      const milisegundosParaAtualizacao =
        parametroLogistico.tempoAtualizacaoDadosEstaticosEmMinutos * 1000 * 100;

      setLoading(true);
      try {
        console.log('entrou try');
        console.log(usuario);
        const response = await api.get(
          `/solicitacao-monitoramento/monitoramento-sm-progressao-viagem-mestre-detalhe?idUsuario=${usuario}`
        );
        setDataProgressao(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
      setTimeout(() => {
        loadProgressaoViagem();
        setIsDialogAtualizandoOK(true);
        console.log(milisegundosParaAtualizacao);
      }, milisegundosParaAtualizacao);
      setIsDialogAtualizandoOK(false);
    };
    loadProgressaoViagem();
  }, [parametroLogistico]);

  const choosePontoBackgroundColor = (statusPonto: string) => {
    if (statusPonto === 'B') return '#bfffbd';
    if (statusPonto === 'A') return '#ffabb4';
    if (statusPonto === 'D') return '#ffdaa6';
    return '#FFFFFF';
  };

  const chooseLinhaBackgroundColor = (statusPonto: string) => {
    if (statusPonto === 'B') return '#63c960';
    if (statusPonto === 'A') return '#c44a57';
    if (statusPonto === 'D') return '#e8ab53';
    return '#FFFFFF';
  };

  const getHintPonto = (ponto: Ponto) => {
    if (ponto.origemDestinoPonto === 'O') {
      return `${ponto.nomePonto} Previsão de Saída: ${obterDataHora(
        ponto.dtHrInicioSM,
        true
      )} Saída: ${obterDataHora(ponto.dtHrInicioRealSM, true)}`;
    }

    return `${ponto.nomePonto} ${
      ponto.dtHrPlanejadaViagemPonto === null
        ? ''
        : `Agendada: ${obterDataHora(ponto.dtHrPlanejadaViagemPonto, true)} `
    }${
      ponto.dtHrCalculadaProxPonto === null
        ? ''
        : `Previsão de Chegada: ${obterDataHora(ponto.dtHrCalculadaProxPonto, true)} `
    }${
      ponto.dtHrChegadaPonto === null
        ? ''
        : `Chegada: ${obterDataHora(ponto.dtHrChegadaPonto, true)}`
    }`;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          setIsDialogAtualizandoOK(false);
        }}
      />

      {/* <ModalProgressao setShow={setShow} show={show} data={modalData} /> */}
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Rastreamento</h2>
          <h6 className="subtitulo">{'Rastreamento > Progressão de viagens'}</h6>
        </div>
        <div className="painel">
          {loading ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <div className="table-responsive" style={{ flex: 1 }}>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Origem</th>
                    <th id="progressao-linha">Progressão</th>
                    <th id="destino-header">Destino</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="progressao-cabecalho" />
                    <td className="progressao-cabecalho">
                      <span className="progressao-cabecalho-texto">{`Total de viagens: ${dataProgressao.length} `}</span>
                      <span className="progressao-cabecalho-texto">{`No prazo: ${
                        dataProgressao.filter((each) => each.statusSm === 'B').length
                      }`}</span>
                      <span className="progressao-cabecalho-texto">{`Atrasadas: ${
                        dataProgressao.filter((each) => each.statusSm === 'A').length
                      }`}</span>
                    </td>
                    <td className="progressao-cabecalho" />
                  </tr>
                  {dataProgressao &&
                    dataProgressao.map((viagem) => {
                      return (
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>
                            {viagem.monitSmProgresViagPontoVO[0] === undefined
                              ? ''
                              : viagem.monitSmProgresViagPontoVO[0].nomePonto}
                          </td>
                          <td>
                            <tr>
                              <span className="progressao-body-texto">{`Nº SM: ${viagem.idSm} `}</span>
                              <span className="progressao-body-texto">{`Placas: ${viagem.placas}`}</span>
                              <span className="progressao-body-texto">{`Percorrido: ${viagem.percorrido}`}</span>
                              {viagem.percPercorrido === 100 ? null : (
                                <span className="progressao-body-texto">{`Próximo ponto: ${
                                  viagem.nomProxPonto
                                } ( ${obterDataHora(viagem.dtHrChegadaProxPonto, true)} )`}</span>
                              )}
                            </tr>
                            <tr className="timeline">
                              {viagem.monitSmProgresViagPontoVO.map((ponto, index) => {
                                const isLastPonto =
                                  index + 1 === viagem.monitSmProgresViagPontoVO.length;
                                // console.log(isLastPonto);
                                if (isLastPonto) {
                                  return null;
                                }
                                return (
                                  <>
                                    <div
                                      className="progresso-ponto"
                                      style={{
                                        backgroundColor:
                                          ponto.percPercorridoPonto === 0
                                            ? '#e2f2f2'
                                            : choosePontoBackgroundColor(ponto.statusPonto),
                                      }}
                                    >
                                      <span title={getHintPonto(ponto)}>{index + 1}</span>
                                    </div>

                                    <div className="progresso-linha pointer">
                                      <div
                                        className={`progresso-linha-colored ${
                                          !ponto.statusConcluidoPonto ? 'textura' : null
                                        }`}
                                        style={{
                                          width: `${ponto.percPercorridoPonto}%`,
                                          backgroundColor: chooseLinhaBackgroundColor(
                                            ponto.statusPonto
                                          ),
                                        }}
                                      />
                                    </div>
                                  </>
                                );
                              })}
                              {viagem.monitSmProgresViagPontoVO[0] === undefined ? null : (
                                <div
                                  className="progresso-ponto"
                                  style={{
                                    backgroundColor:
                                      viagem.monitSmProgresViagPontoVO[
                                        viagem.monitSmProgresViagPontoVO.length - 1
                                      ].percPercorridoPonto === 0
                                        ? '#e2f2f2'
                                        : choosePontoBackgroundColor(
                                            viagem.monitSmProgresViagPontoVO[
                                              viagem.monitSmProgresViagPontoVO.length - 1
                                            ].statusPonto
                                          ),
                                  }}
                                >
                                  <span
                                    title={
                                      viagem.monitSmProgresViagPontoVO[0] === undefined
                                        ? ''
                                        : `${getHintPonto(
                                            viagem.monitSmProgresViagPontoVO[
                                              viagem.monitSmProgresViagPontoVO.length - 1
                                            ]
                                          )}`
                                    }
                                  >
                                    {viagem.monitSmProgresViagPontoVO.length}
                                  </span>
                                </div>
                              )}
                            </tr>
                          </td>
                          {viagem.monitSmProgresViagPontoVO[0] === undefined ? null : (
                            <td
                              style={{
                                verticalAlign: 'middle',
                                justifyItems: 'flex-end',
                                alignSelf: 'flex-end',
                                justifyContent: 'flex-end',
                                textAlign: 'end',
                              }}
                            >
                              {
                                viagem.monitSmProgresViagPontoVO[
                                  viagem.monitSmProgresViagPontoVO.length - 1
                                ].nomePonto
                              }
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressaoViagens;
