/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { format } from 'date-fns';
import instanceMaquina from '../../config/axiosMaquina';

export const PocTeste = () => {
  const [id, setId] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [data, setData] = useState<string>('');
  const [hora, setHora] = useState<string>('');
  const [lista, setLista] = useState<any[]>([]);

  async function fetchData() {
    const response = await instanceMaquina.get('/teste-poc');
    setLista(response.data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function dataHoraConverter(data: string, hora: string): Date {
    const dataArr = data?.split('-');
    const horaArr = hora?.split(':');
    const year = dataArr[0];
    const month = parseInt(dataArr[1], 10) - 1;

    const dataAtt = new Date();
    dataAtt.setFullYear(parseInt(year, 10));
    dataAtt.setMonth(month);
    dataAtt.setDate(parseInt(dataArr[2], 10));
    dataAtt.setHours(parseInt(horaArr[0], 10));
    dataAtt.setMinutes(parseInt(horaArr[1], 10));
    dataAtt.setSeconds(0);
    dataAtt.setMilliseconds(0);
    return dataAtt;
  }

  function obterData(data: string): string {
    console.log(new Date(data), 'yyyy-MM-dd');
    return format(new Date(data), 'yyyy-MM-dd');
  }

  function obterHora(data: string): string {
    return format(new Date(data), 'HH:mm');
  }

  return (
    <Form>
      <Form.Group className="mb-3" controlId="id">
        <Form.Label>Id: </Form.Label>
        <Form.Control type="text" value={id} onChange={(props) => setId(props.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="nome">
        <Form.Label>Nome: </Form.Label>
        <Form.Control type="text" value={nome} onChange={(props) => setNome(props.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="data">
        <Form.Label>Data: </Form.Label>
        <Form.Control
          type="date"
          placeholder=""
          value={data}
          onChange={(props) => setData(props.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="data">
        <Form.Label>Hora: </Form.Label>
        <Form.Control
          type="time"
          placeholder=""
          value={hora}
          onChange={(props) => setHora(props.target.value)}
        />
      </Form.Group>
      <Button
        onClick={async () => {
          const body = {
            id,
            nome,
            dataCadastro: dataHoraConverter(data, hora),
            dataCadastroZ: dataHoraConverter(data, hora),
          };
          console.log(body.dataCadastro);
          console.log(JSON.stringify(body));

          const response = await instanceMaquina.post('/teste-poc', body);

          alert(JSON.stringify(response));
        }}
      >
        Submit
      </Button>
      <br />
      <br />

      {lista.map((item) => (
        <>
          <div>{item.id}</div>
          <Form.Control type="date" placeholder="" value={obterData(item.dataCadastro)} disabled />
          <Form.Control type="time" placeholder="" value={obterHora(item.dataCadastro)} disabled />

          <br />
        </>
      ))}
    </Form>
  );
};
