/* eslint-disable */

import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import { obterData, obterDataHora } from '../../util/date';

type ExcelGeneration2 = {
  ySteps?: number;
  xSteps?: number;
  columns: { title: any | undefined; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined; style?: any }[][];
};

const RelatorioXLSAdmin = (state: any, setExcelGenerationAdmin: any) => {
  const alignment = {
    horizontal: 'center',
    wrapText: true,
  };

  // style linhas
  const borders2 = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const borderTitle = {
    bottom: { style: 'medium' },
  };
  let excel: ExcelGeneration2[] = [
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Relatório Checklist',
          widthPx: 1060,
          style: {
            alignment: { horizontal: 'center' },
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 2,
      ySteps: 2,
      columns: [
        {
          title: `Empresa: ${state.listagem ? state.listagem[0].idClienteTransportador : ''} ${
            state.listagem ? state.listagem[0].responsavel : ''
          }`,
          width: { wpx: 2000 },
          style: { font: { sz: '12' }, alignment: { horizontal: 'center' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: `Periodo: ${
            state.model.dataInicio != undefined ? obterData(state.model.dataInicio) : '/ /'
          } e ${state.model.dataFim != undefined ? obterData(state.model.dataFim) : '/ /'}`,
          width: { wpx: 2000 },
          style: { font: { sz: '12' }, alignment: { horizontal: 'center' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 1,
      ySteps: 0,
      columns: [],
      data: [],
    },
  ];

  const loadTitle1 = (veiculo: any) => {
    const title = {
      ySteps: 0,
      columns: [
        {
          title:
            veiculo.placaCarreta != null && veiculo.placaVeiculo != null
              ? `Carreta: ${veiculo.placaCarreta}`
              : veiculo.placaVeiculo != null && veiculo.placaCarreta === null
              ? `Veículo: ${veiculo.placaVeiculo}`
              : veiculo.numeroBau != null
              ? `Báu: ${veiculo.numeroBau}`
              : '',
          width: { wch: 40 },
          style: { font: { sz: '12', bold: true } },
        },
        {
          title: veiculo.tipoFrotaCarreta
            ? getTipoFrota(veiculo.tipoFrotaCarreta)
            : veiculo.tipoFrotaVeiculo
            ? getTipoFrota(veiculo.tipoFrotaVeiculo)
            : '',
          width: { wch: 20 },
          style: { font: { sz: '12' } },
        },
      ],
      data: [],
    };
    excel.push(title);
  };

  const loadTitle2 = (veiculo: any) => {
    const title2 = {
      ySteps: 0,
      columns: [
        {
          title: `Data da Realização: ${
            veiculo.dataInclusao ? obterDataHora(veiculo.dataInclusao, true) : ''
          }`,
          width: { wch: 40 },
          style: { font: { sz: '12' } },
        },
        {
          title: `Validade: ${
            veiculo.listaDispositivo.filter((each: any) => each.situacaoDispositivo.includes('3'))
              .length > 0
              ? `Checklist Não OK`
              : veiculo.checklistValidade.includes('EMBARQUE')
              ? veiculo.checklistValidade.split(': ')[1]
              : veiculo.dataValidade
              ? obterDataHora(veiculo.dataValidade, true)
              : ''
          }`,
          width: { wch: 20 },
          style: { font: { sz: '12' } },
        },
      ],
      data: [],
    };
    excel.push(title2);
  };

  const loadTitle3 = (veiculo: any) => {
    const title3 = {
      ySteps: 0,
      columns: [
        {
          title: `Responsavel: ${veiculo.responsavel ? veiculo.responsavel : ''}`,
          width: { wch: 40 },
          style: { font: { sz: '12' } },
        },
        {
          title: `${veiculo.telefoneResponsavel ? veiculo.telefoneResponsavel : ''}`,
          width: { wch: 20 },
          style: { font: { sz: '12' } },
        },
      ],
      data: [],
    };
    excel.push(title3);
  };

  const loadTitle4 = (veiculo: any) => {
    const title4 = {
      ySteps: 0,
      columns: [
        {
          title: `Operador: ${veiculo.usuarioLancamento ? veiculo.usuarioLancamento : ''}`,
          width: { wch: 40 },
          style: { font: { sz: '12' } },
        },
      ],
      data: [],
    };
    excel.push(title4);
  };

  const filtrarDispositivos = (veiculo: any) => {
    return veiculo.listaDispositivo.filter(
      (each: any) => each.situacaoDispositivo === '3' || each.situacaoDispositivo === '2'
    );
  };

  const loadDados = () => {
    state.listagem.map((veiculo: any) => {
      const checklist = filtrarDispositivos(veiculo);
      if (veiculo.listaDispositivo.length > 0) {
        loadTitle1(veiculo);
        loadTitle2(veiculo);
        loadTitle3(veiculo);
        loadTitle4(veiculo);
        const tableData = {
          xSteps: 0,
          ySteps: 0,
          columns: [
            {
              title: `Dispositivos Avaliados`,
              width: { wch: 40 },
              style: {
                border: borderTitle,
                font: { sz: '12', bold: true },
                alignment: alignment,
              },
            },
            {
              title: `Situação`,
              width: { wch: 40 },
              style: {
                border: borderTitle,
                font: { sz: '12', bold: true },
                alignment: alignment,
              },
            },
          ],

          data: checklist.map((each: any, idx: any) => [
            {
              value: each.nomeDispositivo !== null ? each.nomeDispositivo : '',
              style: { border: borders2 },
            },
            {
              value: each.situacaoDispositivo === '3' ? 'Defeito' : 'Ok',
              style: { border: borders2 },
            },
          ]),
        };

        const linhaEntreVeiculos = {
          xSteps: 2,
          ySteps: 0,
          columns: [],
          data: [],
        };

        excel.push(tableData);
        excel.push(linhaEntreVeiculos);
      }
    });
  };

  loadDados();
  setExcelGenerationAdmin(excel);
};

export default RelatorioXLSAdmin;
