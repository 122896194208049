/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Form, Spinner } from 'react-bootstrap';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import checaCPF from '../../util/checaCpf';
import { cpfMask, soNumeros } from '../../util/Validacoes/Validacoes';
import { validarPlaca } from '../../util/Validacoes/Validacoes';
import './styles.css';
import { Pesquisa, ListagemHistoricoPesquisa } from '../../pages/Pesquisa/HistoricoPesquisas/index';
import TablePesquisa from './Table';
import { toZoned, dataConverter } from '../../util/date';

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const ConsultarPesquisasLancadas = ({ canEdit, canDelete }: Props) => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const [empresa, setEmpresa] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [placa, setPlaca] = useState('');
  const [cpf, setCpf] = useState('');
  const [numLiberacao, setNumLiberacao] = useState('');
  const [model, setModel] = useState<Pesquisa>({} as Pesquisa);
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState<ListagemHistoricoPesquisa[]>();
  const { addToast } = useToast();
  const history = useHistory();
  const { page, request }: any = useParams();
  const [isCpfValid, setIsCpfValid] = useState(true);
  const [empresaInvalid, setEmpresaInvalid] = useState(false);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        setListaClientes(response.data);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    if (request) {
      setShowTable(true);
      buscar(request);
    } else {
      setData([]);
      setShowTable(false);
    }
  }, [request]);

  const submit = (e: any) => {
    e.preventDefault();
    let d: any;
    let arrayRequest: any;
    const array = [
      { name: 'idCliente', value: empresa },
      { name: 'dataIni', value: model.dataIni },
      { name: 'dataFim', value: model.dataFim },
      { name: 'cpfAjudante', value: cpf },
      { name: 'cpfCondutor', value: cpf },
      { name: 'placaVeiculo', value: placa },
      { name: 'numeroLiberacao', value: model.numeroLiberacao },
    ];

    if (array.length === 0) {
      addToast({
        title: 'Erro',
        description: 'Preencha pelo menos um dos campos.',
        type: 'error',
      });
      return;
    } else {
      if (!model.numeroLiberacao && !placa && !cpf) {
        if (!model.dataIni || !model.dataFim) {
          addToast({
            title: 'Erro',
            description: 'Preencha os campos obrigatórios.',
            type: 'error',
          });
          return;
        }
      }
    }
    array.map((each: any) => {
      if (each.value) {
        d = { ...d, [each.name]: each.value };
      }
    });
    if (cpf) {
      arrayRequest = [
        { ...d, tipoBusca: 'AJUDANTE' },
        { ...d, tipoBusca: 'CONDUTOR' },
      ];
    } else if (placa) {
      arrayRequest = [{ ...d, tipoBusca: 'VEICULO' }];
    } else if (model.numeroLiberacao) {
      arrayRequest = [{ ...d, tipoBusca: 'COD_LIBERACAO' }];
    } else if (model.dataIni && model.dataFim) {
      arrayRequest = [{ ...d, tipoBusca: 'PERIODO' }];
    } else {
      arrayRequest = [{ ...d, tipoBusca: 'SEM_PARAMETROS' }];
    }
    console.log(page);
    history.push({
      pathname: `/consultar-pesquisas-lancadas/${JSON.stringify(arrayRequest)}/${page || 1}`,
    });
  };

  const buscar = async (request: string) => {
    const arrayRequest = JSON.parse(request);

    console.log(arrayRequest);
    let res: any[] = [];
    for (let i = 0; i < arrayRequest.length; i++) {
      setLoading(true);
      try {
        const response = await api.post('/pesquisa/historico', arrayRequest[i]);
        res = [...res, ...response.data];
        // console.log(response);
        if (i === arrayRequest.length - 1) {
          if (res.length === 0) {
            console.log(res);
            setShowTable(false);
            setData([]);
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para o filtro utilizado.',
              type: 'error',
            });
          } else {
            setCadastroRealizado(true);
            setShowTable(true);
            setData(res);
            history.push({
              pathname: `/consultar-pesquisas-lancadas/${JSON.stringify(arrayRequest)}/${page || 1
                }`,
            });
          }
        }
      } catch (error: any) {
        console.log(error);
        if (i === arrayRequest.length - 1) {
          if (res.length === 0) {
            addToast({
              title: 'Erro',
              description: 'Não existe pesquisa para o filtro utilizado.',
              type: 'error',
            });
            setShowTable(false);
            setData([]);
            history.push({
              pathname: `/consultar-pesquisas-lancadas/`,
            });
          } else {
            setCadastroRealizado(true);
            setShowTable(true);
            setData(res);
            history.push({
              pathname: `/consultar-pesquisas-lancadas/${JSON.stringify(arrayRequest)}/${page}`,
            });
          }
        }
      } finally {
        if (i === arrayRequest.length - 1) {
          setLoading(false);
        }
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Consultar Pesquisas Lançadas</h2>
          <h6 className="subtitulo">{'Consultas/Relatórios > Análise Perfil'}</h6>
        </div>

        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : showTable && data && page ? (
          <TablePesquisa canEdit={canEdit} canDelete={canDelete} data={data} />
        ) : (
          <Form noValidate validated={isFormInvalid} onSubmit={submit}>
            <Form.Group className="row-modal">
              <Form.Label>Empresas</Form.Label>

              <Dropdown
                style={{ width: '100%', borderColor: empresaInvalid ? 'red' : '#c9c9c9' }}
                value={empresa}
                options={listaClientes}
                placeholder="Selecione"
                onChange={(e: any) => {
                  setEmpresa(e.target.value);
                  setEmpresaInvalid(false);
                }}
                optionLabel="razaoSocial"
                optionValue="id"
                filter
                showClear
                className="multiselect-custom"
                name="empresa"
                id="empresa"
              />
            </Form.Group>

            <Form.Row className="row-container-modal">
              <Form.Group className="row-modal">
                <Form.Label>
                  {model.numeroLiberacao || placa || cpf ? null : (
                    <span className="txt-obrigatorio">*</span>
                  )}
                  Data Início
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                    if (e.target.value !== '') {
                      setModel({
                        ...model,
                        dataIni: toZoned(e.target.value),
                      });
                    }
                  }}
                  value={dataInicio}
                  type="date"
                  id="date"
                  name="date"
                  placeholder=""
                />
                <Form.Control.Feedback type="invalid">
                  Campo Data Início não pode ser nulo.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="row-modal">
                <Form.Label>
                  {model.numeroLiberacao || placa || cpf ? null : (
                    <span className="txt-obrigatorio">*</span>
                  )}
                  Data Fim
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataFim(e.target.value);
                    if (e.target.value !== '') {
                      setModel({
                        ...model,
                        dataFim: toZoned(e.target.value, false),
                      });
                    }
                  }}
                  value={dataFim}
                  type="date"
                  id="dataFim"
                  name="dataFim"
                  placeholder=""
                />
                <Form.Control.Feedback type="invalid">
                  Campo Data Fim não pode ser nulo.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Group>
              <Form.Label>Placa nº</Form.Label>
              <Form.Control
                value={placa}
                id="rota"
                name="rota"
                placeholder=""
                onChange={(e) => {
                  validarPlaca(e.target.value) ? setPlaca(e.target.value) : null;
                  setModel({
                    ...model,
                    placaVeiculo: e.target.value,
                    tipoBusca: 'VEICULO',
                  });
                }}
                disabled={!!(cpf || model.numeroLiberacao)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>CPF nº</Form.Label>
              <Form.Control
                value={cpf}
                isInvalid={!isCpfValid && cpf != ''}
                id="cpf"
                name="cpf"
                placeholder=""
                onChange={(event: any) => {
                  if (checaCPF(event?.target.value) === false) {
                    setCpf(cpfMask(event?.target.value));
                    setIsCpfValid(false);
                  } else {
                    setCpf(cpfMask(event?.target.value));
                    setIsCpfValid(true);
                    setModel({
                      ...model,
                      condutorCPF: event?.target.value,
                      tipoBusca: 'CONDUTOR',
                    });
                  }
                }}
                disabled={!!(placa || model.numeroLiberacao)}
              />
              {!isCpfValid && cpf != '' && (
                <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>Cpf inválido.</p>
              )}
              {/* {!nomeCondutor && !nomeAjudante && isCpfValid && cpf != '' && (
                <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>
                  Não existe nenhum condutor ou ajudante para este cpf.
                </p>
              )} */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Nº Liberação</Form.Label>
              <Form.Control
                value={numLiberacao}
                id="rota"
                name="rota"
                placeholder=""
                onChange={(e) => {
                  setNumLiberacao(soNumeros(e.target.value));
                  setModel({
                    ...model,
                    numeroLiberacao: parseInt(e.target.value, 10),
                    tipoBusca: 'COD_LIBERACAO',
                  });
                }}
                disabled={!!(placa || cpf)}
              />
            </Form.Group>
            <ButtonsForm />
          </Form>
        )}
      </div>
    </div>
  );
};

export default ConsultarPesquisasLancadas;
