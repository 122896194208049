/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Form, Row, Button, FormControl, Modal, Col } from 'react-bootstrap';
import {
  Circle,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import TruckImage from '../../assets/markers/truck.png';
import BuildImage from '../../assets/markers/building.png';
import StackedBuildingImage from '../../assets/markers/stackedBuilding.png';
import { PainelLSCType, ViagemTransitTime } from '../../Types/PainelLSCTypes';
import { PositionBuild, PositionTruck } from '../../types';
import { FormataStringData2 } from '../../util/Validacoes/Validacoes';
import { getCookieSessionData } from '../../services/cookieService';
import instanceMaquina from '../../config/axiosMaquina';
import CadVeiculo from '../../pages/scriptCase/CadVeiculo';
import uniqWith from 'lodash/uniqWith';

type ModalProps = {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  data?: PainelLSCType;
  viagem?: ViagemTransitTime;
  idSm?: number;
};

type SMMapa = {
  bau: string;
  desMotivoFinalizacaoSm: string;
  dtCadastroSm: string;
  dtFimPlanejadoSm: string;
  dtFimRealSm: string;
  dtHrUltimaPosicao: string;
  dtInicioPlanejadoSm: string;
  dtInicioRealSm: string;
  hrFimPlanejadoSm: string;
  hrFimRealSm: string;
  hrInicioPlanejadoSm: string;
  hrInicioRealSm: string;
  idCliente: number;
  idSm: number;
  latitudeAtual: number;
  listMonitoramentoSmMapaPontoVO: {
    distancia: string;
    horario: string;
    idSm: number;
    latitude: number;
    longitude: number;
    ponto: string;
    remessa: string;
    previsao: string;
    previsaocomparadas: string;
    raioAproximacao: number;
  }[];

  localizacao: string;
  longitudeAtual: number;
  nomeTransportador: string;
  nomeTecnologiaRastreamento: string;
  operacao: string;
  placas: string;
  possuiRotaOuPonto: string;
  progressao: string;
  razaoSocialEmbarcador: string;
  rota: string;
  statusFinalizacaoSm: boolean;
  statusViagem: string;
  tecnologia: string;
};

export type LatLng = {
  lat: any;
  lng: any;
};

const { REACT_APP_GOOGLE_API_KEY: GOOGLE_API_KEY } = process.env;

const options = {
  fillColor: '#D5D4F4',
  strokeColor: '#AFAFFC',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

const PopUpMapa: FC<ModalProps> = ({ showModal, setShowModal, data, viagem, idSm }) => {
  const { usuarioId } = getCookieSessionData().usuarioVO;

  const [selectedMarker, setSelectedMarker] = useState<LatLng>();
  const [selectedMarkerBuild, setSelectedMarkerBuild] = useState<LatLng>();
  const [showInfo, setShowInfo] = useState(false);
  const [showInfoBuild, setShowInfoBuild] = useState(false);
  const [currentZoom, setCurrentZoom] = useState<number>();
  const [map, setMap] = useState<google.maps.Map>();
  const [currentViagem, setCurrentViagem] = useState<SMMapa[]>([]);
  const [erroViagem, setErroViagem] = useState(false);

  const [initialCenter, setInitialCenter] = useState<LatLng>();

  const [positionsPontos, setPositionsPontos] = useState<PositionBuild[]>([]);
  const [positions, setPositions] = useState<PositionTruck[]>([]);

  const [center, setCenter] = useState<LatLng | undefined>(
    (showInfo && selectedMarker) || (showInfoBuild && selectedMarkerBuild) || initialCenter
  );

  const mapStyles = {
    height: '60vh',
    width: '100%',
  };

  const uniqueLocations = uniqWith(
    positionsPontos,
    (locationA, locationB) => locationA.lat === locationB.lat && locationA.lng === locationB.lng
  );

  const getDetalhesMapa = async () => {
    try {
      const response = await instanceMaquina.get(
        `/solicitacao-monitoramento/monitoramento-sm-mapa-mestre-detalhe?idSm=${
          idSm || viagem?.idSm
        }&idUsuario=${usuarioId}`
      );
      const _viagem = response.data.filter(
        (item: SMMapa) => item.idSm === idSm || item.idSm === viagem?.idSm
      );
      if (_viagem.length) {
        setCurrentViagem(_viagem);
        setErroViagem(false);
      } else {
        setErroViagem(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    !currentViagem?.length && getDetalhesMapa();
  }, [currentViagem]);

  const renderPositionsPontos = () => {
    let positionsBuild: any[] = [];
    let positionsTruck: PositionTruck[] = [];
    currentViagem?.forEach((cV: any) => {
      setInitialCenter({ lat: Number(cV.latitudeAtual), lng: Number(cV.longitudeAtual) });
      positionsTruck.push({
        lat: Number(cV.latitudeAtual),
        lng: Number(cV.longitudeAtual),
        isTruck: true,
        idSm: cV.idSm,
        inicio: cV.dtInicioRealSm,
        statusIgnicao: cV.statusIgnicao,
        transportador: cV.nomeTransportador,
        proximoPonto: viagem ? viagem.nomProxPonto : '',
        placas: cV.placas,
      });
      setPositions(positionsTruck);
      cV.listMonitoramentoSmMapaPontoVO.forEach((each: any) => {
        positionsBuild.push({
          lat: parseFloat(each.latitude.replace(',', '.')),
          lng: parseFloat(each.longitude.replace(',', '.')),
          isTruck: false,
          raioAproximacao: each.raioAproximacao,
          distancia: each.distancia,
          horario: each.horario,
          idSm: each.idSm,
          ponto: each.ponto,
          remessa: each.remessa,
          previsao: each.previsao,
          previsaocomparadas: each.previsaocomparadas,
        });
      });
    });
    setPositionsPontos(positionsBuild);
  };
  useEffect(() => {
    currentViagem && renderPositionsPontos();
  }, [currentViagem]);

  const createKey = (location: { lat: number | null; lng: number | null }) => {
    if (!location.lat || !location.lng) {
      return;
    }
    return location.lat + location.lng;
  };

  const renderBuildingImage = (location: LatLng) => {
    const { lat, lng } = location;
    const stackedLocation = positionsPontos.filter((p: any) => {
      return p.lat === lat && p.lng === lng;
    });
    if (stackedLocation.length > 1) {
      return StackedBuildingImage;
    } else if (stackedLocation.length === 1) {
      return BuildImage;
    }
  };

  const renderDescricaoPontos = (latitude: number, longitude: number) => {
    const pontos = positionsPontos.filter((p: any) => {
      return p.lat === latitude && p.lng === longitude;
    });
    if (!pontos.length) return;

    return pontos.map((d: any, index) => {
      if (!d.ponto) return null;
      return (
        <ul key={index} style={{ listStyle: 'none', padding: 0 }}>
          <li
            style={{
              borderBottom: '1px solid rgba(139,139,139,0.2)',
              paddingTop: 5,
              paddingBottom: 2,
              textAlign: 'left',
              listStyleType: 'none',
              listStylePosition: 'inside',
              textTransform: 'uppercase',
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {`${d.ponto} - Raio: ${d.raioAproximacao}m`}
          </li>
        </ul>
      );
    });
  };

  const renderRadius = (location: LatLng) => {
    const { lat, lng } = location;
    const pontos = positionsPontos.filter((p: any) => {
      return p.lat === lat && p.lng === lng;
    });
    return pontos.map((d: any, index) => {
      return <Circle key={index} center={location} radius={d.raioAproximacao} options={options} />;
    });
  };

  map?.addListener('zoom_changed', function () {
    const zoom = map.getZoom();
    setCurrentZoom(zoom);
  });

  useEffect(() => {
    if (currentZoom && currentZoom >= 14) {
      setShowInfoBuild(true);
      if (map?.getCenter()?.lat() && map?.getCenter()?.lng()) {
        const location = {
          lat: Number(map?.getCenter()?.lat().toFixed(7)),
          lng: Number(map?.getCenter()?.lng().toFixed(7)),
        };
        setSelectedMarkerBuild(location);
      }
    }
  }, [currentZoom]);

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>

      {positions.length && uniqueLocations.length ? (
        <Modal.Body>
          <Modal.Body>
            <LoadScript googleMapsApiKey={GOOGLE_API_KEY || ''}>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={17}
                onLoad={(m) => {
                  setMap(m);
                  setCenter(initialCenter && initialCenter);
                }}
                center={center}
              >
                <MarkerClusterer
                  imagePath={'../../assets/clusterers'}
                  averageCenter={true}
                  // gridSize={50}
                  title={'Click to zoom'}
                  maxZoom={13}
                  minimumClusterSize={1}
                >
                  {(clusterer) =>
                    positions.map((each, index) => {
                      const location = { lat: each.lat, lng: each.lng };
                      const key = createKey(location);
                      return (
                        <>
                          <Marker
                            icon={TruckImage}
                            key={index}
                            position={location}
                            clusterer={clusterer}
                            onClick={() => {
                              setSelectedMarker(location);
                              setShowInfo(true);
                            }}
                          >
                            {showInfo && selectedMarker && createKey(selectedMarker) === key && (
                              <InfoWindow
                                position={location}
                                onCloseClick={() => setShowInfo(false)}
                              >
                                <div style={{ width: '250px' }}>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        // backgroundColor: '#FFDA53',
                                        // borderRadius: '5px',
                                      }}
                                    >
                                      {each.placas}
                                    </span>
                                  </div>

                                  <div>
                                    <span>&nbsp;</span>
                                  </div>

                                  <div>
                                    <span style={{ fontWeight: 'bold' }}>Transportadora:</span>
                                    <span> {each.transportador}</span>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>SM nº: </span>
                                    <span style={{ marginLeft: '5px' }}>{each.idSm}</span>

                                    <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
                                      Início:
                                    </span>
                                    {each.inicio && (
                                      <span style={{ marginLeft: '5px' }}>
                                        {FormataStringData2(each.inicio.split('T')[0])}{' '}
                                        {each.inicio.split('T')[1].slice(0, -13)}
                                      </span>
                                    )}
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      // justifyContent: 'space-between',
                                    }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>Próximo Ponto:</span>
                                    <span style={{ marginLeft: '5px' }}>
                                      {each.proximoPonto ? each.proximoPonto : 'Viagem Concluída'}
                                    </span>
                                  </div>

                                  <div>
                                    <span>&nbsp;</span>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      // justifyContent: 'space-between',
                                    }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>
                                      Distância Percorrida:
                                    </span>
                                    <span style={{ marginLeft: '5px' }}></span>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      // justifyContent: 'space-between',
                                    }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>Tempo Decorrido:</span>
                                    <span style={{ marginLeft: '5px' }}></span>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      // justifyContent: 'space-between',
                                    }}
                                  >
                                    <span style={{ fontWeight: 'bold' }}>Velocidade Média:</span>
                                    <span style={{ marginLeft: '5px' }}></span>
                                  </div>

                                  <div>
                                    <span>&nbsp;</span>
                                  </div>

                                  <span style={{ fontWeight: 'bold' }}>Status ignição:</span>
                                  <span style={{ marginLeft: '5px' }}>
                                    {each.statusIgnicao === true ? 'Ligada' : 'Desligada'}
                                  </span>
                                </div>
                              </InfoWindow>
                            )}
                          </Marker>
                        </>
                      );
                    })
                  }
                </MarkerClusterer>
                <MarkerClusterer
                  imagePath={'../../assets/clusterers'}
                  averageCenter={true}
                  // gridSize={50}
                  title={'Click to zoom'}
                  maxZoom={13}
                  minimumClusterSize={1}
                >
                  {(clusterer) =>
                    uniqueLocations.map((each: any, index: number) => {
                      const location = { lat: each.lat, lng: each.lng };
                      const key = createKey(location);
                      return (
                        <>
                          <Marker
                            icon={renderBuildingImage(location)}
                            key={index}
                            position={location}
                            clusterer={clusterer}
                            onClick={() => {
                              setSelectedMarkerBuild(location);
                              setShowInfoBuild(true);
                            }}
                          >
                            {showInfoBuild &&
                              currentZoom &&
                              currentZoom >= 14 &&
                              each.ponto &&
                              each.lat &&
                              each.lng &&
                              selectedMarkerBuild &&
                              createKey(selectedMarkerBuild) === key && (
                                <InfoWindow
                                  position={location}
                                  onCloseClick={() => {
                                    setShowInfoBuild(false);
                                  }}
                                >
                                  <div
                                    style={{
                                      padding: '5px, 10px',
                                      width: 'fit-content',
                                      overflow: 'none',
                                    }}
                                  >
                                    {renderDescricaoPontos(each.lat, each.lng)}
                                  </div>
                                </InfoWindow>
                              )}
                          </Marker>
                          {renderRadius(location)}
                        </>
                      );
                    })
                  }
                </MarkerClusterer>
              </GoogleMap>
            </LoadScript>
          </Modal.Body>
        </Modal.Body>
      ) : null}
      {erroViagem ? <Modal.Body>Não foi possível carregar o mapa</Modal.Body> : null}
    </Modal>
  );
};

export default PopUpMapa;
