/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable */
// @ts-ignore
import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactExport from 'react-data-export';
import { Button } from 'primereact/button';
import { Col, Form } from 'react-bootstrap';
import { IoMailOpenSharp, IoEllipse } from 'react-icons/io5';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import Pagination from '../../../components/Pagination/Pagination';
import { cpfMask, FormataStringData2 } from '../../../util/Validacoes/Validacoes';
import { ExcelGeneration } from '../../ListagemSMLancadas/ListagemSMLancadas';
import { useToast } from '../../../hooks/Toast';
import { handleResultado } from '../../../util/validaResultadoPesquisa';
import { ClienteParticularidadesDTO } from '../../../Types/ParticularidadesType';
import { getCookieSessionData } from '../../../services/cookieService';
import api from '../../../config/axiosMaquina';
import { validaVigencia } from '../../../util/validaVigenciaPesquisa';
import { obterData, obterDataHora } from '../../../util/date';

type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    validadePesquisaEntidade: string;
    statusPesquisa?: string;
    statusPesquisaCondutorCnh?: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  validadeAnaliseCondutorAutonomo: number;
  validadeAnaliseNaoMotoristaAgregado: number;
  validadeAnaliseNaoMotoristaAutonomo: number;
  validadeAnaliseNaoMotoristaFuncionario: number;
  validadeAnaliseVeiculoTerceiro: number;
};

type Props = {
  dataResults: ListagemHistoricoPesquisa[];
  setDataResults: React.Dispatch<React.SetStateAction<ListagemHistoricoPesquisa[] | undefined>>;
  dataInicio: string;
  dataFim: string;
  currentPage: any;
  setCurrentPage: (currentPage: any) => void;
};

const ListagemHistoricoPesquisas: React.FC<Props> = ({
  dataInicio,
  dataFim,
  dataResults,
  setDataResults,
  currentPage,
  setCurrentPage,
}) => {
  const history = useHistory();
  const { page }: any = useParams();
  const [data, setData] = useState<ListagemHistoricoPesquisa[]>();

  const [historicosPerPage, setHistoricosPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(historicosPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(historicosPerPage, 10);
  const currentPesquisas = dataResults?.slice(indexOfFirstPost, indexOfLastPost);

  const [itensPerPage, setItensPerPage] = useState('10');
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const { addToast } = useToast();
  const { clienteId } = getCookieSessionData().usuarioVO;

  // style cabeçalho
  const borders = {
    top: { style: 'medium' },
    bottom: { style: 'medium' },
    left: { style: 'medium' },
    right: { style: 'medium' },
  };

  const alignment = {
    horizontal: 'center',
    wrapText: true,
  };

  // style linhas
  const borders2 = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  useEffect(() => {
    setData(dataResults);
    if (dataResults.length === 0) {
      addToast({
        title: 'Info',
        description: 'Não foi possível encontrar nenhum histórico utilizando este filtro.',
        type: 'info',
      });
    }
  }, [dataResults]);

  const [isRepetido, setIsRepetido] = useState(false);

  const arrayCompletoDados: any = [];

  const arrayDatasLancamento = dataResults.map((each, index) => each.dataLancamento.split('T')[0]);

  var aux = arrayDatasLancamento.filter(function (elemento, i) {
    return arrayDatasLancamento.indexOf(elemento) == i;
  });

  const arrayItens = dataResults.map((each: any) => each.itens);

  const arrayItensPesquisados: any = [];
  const item = arrayItens.map((each: any) => each.map((value: any) => value.itemPesquisado));

  const loadDados = () => {
    dataResults.map((pesquisa: any, i: any) => {
      const arrayListaItens = pesquisa.itens;

      if (arrayListaItens.length > 0) {
        let tableDados = arrayListaItens.map((each: any, idx: any) => [
          {
            value: pesquisa.numLiberacao ? pesquisa.numLiberacao : '',
            style: { border: borders2 },
          },
          {
            value: each.itemPesquisado ? each.itemPesquisado : '',
            style: { border: borders2 },
          },
          {
            value: each.descricao ? each.descricao : '',
            style: { border: borders2 },
          },
          {
            value: pesquisa.operacao.includes('Integrada')
              ? `${pesquisa.operacao}(${idx + 1})`
              : pesquisa.operacao,
            style: { border: borders2 },
          },
          {
            value: pesquisa.dataLancamento ? obterData(each.dataLancamento) : '',
            style: { border: borders2 },
          },
          { value: pesquisa.usuario ? pesquisa.usuario : '', style: { border: borders2 } },
          {
            value: handleResultado(pesquisa.itens) === 'Em Acordo' ? validaVigencia(pesquisa) : '',
            style: { border: borders2 },
          },
          {
            value: handleResultado(pesquisa.itens),
            style: { border: borders2 },
          },
        ]);
        arrayCompletoDados.push(...tableDados);
      }
    });
  };
  loadDados();
  const excel: ExcelGeneration[] = [
    {
      xSteps: 3,
      ySteps: 0,
      columns: [
        {
          title: 'Histórico de Pesquisas',
          widthPx: 3000,
          style: {
            alignment: alignment,
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title:
            dataInicio && dataFim
              ? `Período: ${dataInicio.split('-')[2]}/${dataInicio.split('-')[1]}/${
                  dataInicio.split('-')[0]
                } a ${dataFim.split('-')[2]}/${dataFim.split('-')[1]}/${dataFim.split('-')[0]}`
              : 'Período:',
          width: { wch: 15 },
          style: { font: { sz: '12' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: 'Nº Liberação',
          width: { wch: 15 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Item Pesquisado',
          width: { wch: 20 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: '',
          width: { wch: 30 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Operação',
          width: { wch: 40 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Data',
          width: { wch: 20 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Usuário',
          width: { wch: 20 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Vigência',
          width: { wch: 15 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
        {
          title: 'Status',
          width: { wch: 30 },
          style: { border: borders, font: { sz: '12', bold: true } },
        },
      ],
      data: [...arrayCompletoDados],
    },
  ];
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>(excel);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const handleGoToDetails = (numLib: number) => {
    const getByNumLib = dataResults?.find((fil) => fil.numLiberacao === numLib);

    history.push({
      pathname: `/detalhe-pesquisa`,
      state: { ...getByNumLib },
    });
  };

  return (
    <div className="content-container">
      <div className="painel">
        <div className="table-responsive" style={{ flex: 1 }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th style={{ width: 150 }}>Item pesquisado</th>
                <th style={{ width: 250 }} />
                <th>Empresa</th>
                <th style={{ width: 125 }}>Tipo</th>
                <th>Nº Liberação</th>
                <th>Lançamento</th>
                <th>Usuário</th>
                <th>Vigência</th>
                <th>Status</th>
                <th style={{ width: 125 }} />
              </tr>
            </thead>
            <tbody>
              {currentPesquisas &&
                currentPesquisas.map((each, index) => (
                  <tr>
                    {each.operacao.includes('Integrada') ? (
                      <td
                        style={{ fontSize: each.itens[3] ? '11px' : each.itens[2] ? '12px' : '' }}
                      >
                        {each.itens[0] ? (
                          <>
                            {each.itens[0].itemPesquisado}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                        {each.itens[1] ? (
                          <>
                            {each.itens[1].itemPesquisado}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                        {each.itens[2] ? (
                          <>
                            {each.itens[2].itemPesquisado}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                        {each.itens[3] ? (
                          <>
                            {each.itens[3].itemPesquisado}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                    ) : (
                      <td>{each.itens[0].itemPesquisado}</td>
                    )}
                    <td
                      style={{
                        wordWrap: 'break-word',
                        fontSize: each.itens[3] ? '11px' : each.itens[2] ? '12px' : '',
                      }}
                    >
                      {each.itens[0] ? (
                        <>
                          {each.itens[0].descricao}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {each.itens[1] ? (
                        <>
                          {each.itens[1].descricao}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {each.itens[2] ? (
                        <>
                          {each.itens[2].descricao}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {each.itens[3] ? (
                        <>
                          {each.itens[3].descricao}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{each.empresa}</td>
                    <td>{each.operacao}</td>
                    <td>{each.numLiberacao}</td>
                    <td>
                      {each.dataLancamento === null ? '' : obterDataHora(each.dataLancamento, true)}
                    </td>
                    <td>{each.usuario}</td>
                    <td>
                      {handleResultado(each.itens) === 'Em Acordo' ? validaVigencia(each) : ''}
                    </td>
                    <td>{handleResultado(each.itens)}</td>
                    {handleResultado(each.itens) === 'Em Andamento' ? (
                      <td />
                    ) : (
                      <td style={{ width: '5%' }}>
                        <IoEllipse
                          size={15}
                          color={
                            handleResultado(each.itens) === 'Pendente de Documentação' ||
                            handleResultado(each.itens) === 'Análise Vencida'
                              ? 'red'
                              : 'green'
                          }
                        />
                      </td>
                    )}
                    {handleResultado(each.itens) === 'Pendente de Documentação' ? (
                      <td />
                    ) : (
                      <td style={{ width: '5%' }}>
                        <Button
                          tooltip="Detalhamento pesquisa"
                          tooltipOptions={{ position: 'bottom' }}
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            padding: 0,
                            paddingRight: 5,
                            marginBottom: 2,
                          }}
                          onClick={() => handleGoToDetails(each.numLiberacao)}
                        >
                          <IoMailOpenSharp size={22} color="#9F9F9F" />
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div id="gerar-xls">
          <Form.Group as={Col} md={2} style={{ paddingLeft: '70px' }}>
            <Button
              className="btn-cancelar"
              onClick={() => {
                window.location.reload();
              }}
            >
              Voltar
            </Button>
          </Form.Group>
          <ExcelFile
            element={<Button disabled={!data}>Exportar para XLS</Button>}
            filename="HistoricoDePesquisas"
          >
            <ExcelSheet dataSet={excelGeneration} name="Histórico de pesquisas" />
          </ExcelFile>
        </div>

        <Pagination
          totalPosts={dataResults ? dataResults.length : 0}
          paginate={paginate}
          currentPage={currentPage.toString()}
          itensPerPage={historicosPerPage.toString()}
          setItensPerPage={setHistoricosPerPage}
        />
      </div>
    </div>
  );
};

export default ListagemHistoricoPesquisas;
