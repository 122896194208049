import React, { useEffect, useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import api from '../../../config/axiosMaquina';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { Tela } from '../../../components/Tela';
import { defaultModelValidacao, ValidacaoCnhType } from '../../../Types/ValidacaoCnhType';
import { UfIbgeType } from '../../../api/model/UfIbgeType';
import { useToast } from '../../../hooks/Toast';

export const ValidacaoCnhCadastro = () => {
  const [model, setModel] = useState<ValidacaoCnhType>(defaultModelValidacao);
  const { id }: any = useParams();
  const [listaEstados, setListaEstados] = useState<UfIbgeType[]>([]);
  const [header, setHeader] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();

  const findById = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/validacaoCNH/${id}`);
      setModel({
        ...response.data,
        dataInicio: response.data.dataInicio,
        dataFim: response.data.dataFim,
        dataRenovacao: response.data.dataRenovacao,
      });
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao recuperar dados da Validação CNH.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePost = async () => {
    try {
      const data = {
        ativo: true,
        dataFim: model.dataFim,
        dataInicio: model.dataInicio,
        dataRenovacao: model.dataRenovacao,
        idUf: model.idUf,
      };

      await api.post('/validacaoCNH/salvarValidacao', data);
      setHeader('Cadastrado com sucesso!');
      setShowDialog(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handlePut = async () => {
    try {
      const data = {
        ativo: true,
        dataFim: model.dataFim,
        dataInicio: model.dataInicio,
        dataRenovacao: model.dataRenovacao,
      };

      await api.put(`/validacaoCNH/atualizarValidacao/${id}`, data);
      setHeader('Editado com sucesso!');
      setShowDialog(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleRequest = (e: any) => {
    e.preventDefault();

    if (!model.dataInicio || !model.dataFim || !model.dataRenovacao || !model.idUf) {
      addToast({
        title: 'Erro',
        description: 'Preencha os campos obrigatórios.',
        type: 'error',
      });
      return;
    }

    if (id) {
      handlePut();
    } else {
      handlePost();
    }
  };

  const updateModel = (e: any) => {
    if (!model) {
      return;
    }

    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const fetchEstados = async () => {
    try {
      const response = await api.get('/uf');
      setListaEstados(response.data.filter((each: any) => each.paisId === 1));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEstados();
  }, []);

  useEffect(() => {
    if (id) {
      findById();
    }
  }, [id]);

  return (
    <Tela
      nome="Validação CNH"
      caminho={`Gerenciamento de Clientes > Validação CNH > ${id ? 'Edição' : 'Cadastro'}`}
      loading={loading}
    >
      <Dialog
        header={header}
        footer={<Button onClick={() => history.push('/admin/listar/validacao-cnh/1')}>Ok</Button>}
        visible={showDialog}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.push('/admin/listar/validacao-cnh/1')}
      />
      <Form onSubmit={handleRequest}>
        <Form.Row>
          <Form.Group as={Col} md={4} className="row-modal">
            <Form.Label className="requiredField">UF Emissor</Form.Label>
            <Form.Control
              onChange={updateModel}
              value={model.idUf}
              name="idUf"
              placeholder=""
              type="number"
              as="select"
              required
            >
              <option value="">Selecione</option>
              {listaEstados &&
                listaEstados.map((estado: UfIbgeType) => (
                  <option key={estado.codigoIbge} value={estado.codigoIbge}>
                    {estado.sigla}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Label className="SMLancadasTitle">Período de validade</Form.Label>
        <Form.Row>
          <Form.Group as={Col} md={4} className="row-modal">
            <Form.Label className="requiredField">Data Início</Form.Label>
            <Form.Control
              onChange={(e: any) => {
                if (e.target.value.length > 10) return;
                updateModel(e);
              }}
              value={model.dataInicio}
              name="dataInicio"
              type="date"
              placeholder=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Campo Data Início não pode ser nulo.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md={4} className="row-modal">
            <Form.Label className="requiredField">Data Fim</Form.Label>
            <Form.Control
              onChange={(e: any) => {
                if (e.target.value.length > 10) return;
                updateModel(e);
              }}
              value={model.dataFim}
              name="dataFim"
              type="date"
              placeholder=""
              required
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={4} className="row-modal">
            <Form.Label className="requiredField">Renovação até</Form.Label>
            <Form.Control
              type="date"
              onChange={(e: any) => {
                if (e.target.value.length > 10) return;
                updateModel(e);
              }}
              value={model.dataRenovacao}
              name="dataRenovacao"
              placeholder=""
              required
            />
          </Form.Group>
        </Form.Row>
        <ButtonsForm />
      </Form>
    </Tela>
  );
};
