import Cookies from 'universal-cookie';
import api from '../config/axiosMaquina';

const cookies = new Cookies();

const decodeCookie = (data: string[]) => {
  let decoded = {};
  let atobText = '';
  data.forEach((d: string) => {
    atobText += d;
    return atobText;
  });
  try {
    decoded = JSON.parse(atob(atobText));
    return decoded;
  } catch (err) {
    return decoded;
  }
};

export const getCookiePermissoesData = (): any => {
  const cook: any[] = [];
  for (let i = 1; i <= 3; i += 1) {
    if (cookies.get(`permissao${i}`)) {
      cook.push(cookies.get(`permissao${i}`));
    }
  }

  if (cook.length) {
    return decodeCookie(cook);
  }
  return '';
};

const encodeCookie = (data: any) => {
  const encodeText = btoa(JSON.stringify(data));
  const size = Math.ceil(encodeText.length / 3500);
  for (let i = 1; i <= 5; i += 1) {
    if (cookies.get(`permissao${i}`)) {
      cookies.remove(`permissao${i}`);
    }
  }
  for (let i = 1; i <= size; i += 1) {
    const last = i * 3500;
    const first = last - 3500;
    cookies.set(`permissao${i}`, encodeText.slice(first, last), {
      path: '/',
    });
  }
};

export const permissionamento = async (idUsuario: number) => {
  const response = await api.get(`/menu-perfil/list-funcionalidades/${idUsuario}`);
  let permissoes = {};
  if (response.data && response.data[0]?.listaMenuPerfilFuncionalidade) {
    const lista = response.data[0].listaMenuPerfilFuncionalidade;
    for (let i = 0; i < lista.length; i += 1) {
      permissoes = {
        ...permissoes,
        [lista[i].idMenuGrupo]: {
          idFuncionalidade: lista[i].id,
          idMenuGrupo: lista[i].idMenuGrupo,
          inserir: lista[i].insert,
          editar: lista[i].update,
          excluir: lista[i].delete,
          consultar: lista[i].consulta,
        },
      };
    }
  }
  encodeCookie(permissoes);
  return permissoes;
};
