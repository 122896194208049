/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosMaquina';
import { Link, useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Pagination from '../../components/Pagination/Pagination';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import {
  CondutorCpfType,
  ListaReciclagemType,
  VeiculoPlacaType,
  ClienteNomeType,
} from '../../api/model/ListaReciclagemType';
import { FormataStringData, cpfMask } from '../../util/Validacoes/Validacoes';
import { ListFormat } from 'typescript';
import { getCookieSessionData } from '../../services/cookieService';
import { Spinner } from 'react-bootstrap';
import { getFullDateWithHourAndMinutes } from '../../util/format';
import { obterData } from '../../util/date';
import { obterDataHora } from './../../util/date';

function GridReciclagem() {
  const [listaTransportador, setListaTransportador] = useState<ClienteNomeType[]>([]);
  const [transportador, setTransportador] = useState();
  const [listaReciclagem, setListaReciclagem] = useState<ListaReciclagemType[]>([]);
  const [listaCpfCondutor, setListaCpfCondutor] = useState<CondutorCpfType[]>([]);
  const [listaPlaca, setListaPlaca] = useState<VeiculoPlacaType[]>([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<ListaReciclagemType[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [cpf, setCpf] = useState('');
  const [placa, setPlaca] = useState('');

  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;

  const paginate = (pageNumber: number) => {
    history.push(`/cliente/listar-reciclagem/${pageNumber}`);
  };

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]);

  console.log(itensPerPage);

  // lista reciclagem (grid)
  useEffect(() => {
    console.log(placa);
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/reciclagem/listarDadosGrid`, {
          params: {
            pagina: page - 1,
            qtdRegistros: itensPerPage,
            idCliente: transportador || clienteId,
            placaVeiculo: placa ? placa : cpf ? null : null,
            cpfCondutor: cpf ? cpf : placa ? null : null,
          },
        });
        setSearchResults(response.data);
        setListaReciclagem(response.data);
        setLoading(false);
        console.log(response.data);
        console.log(response.config.params);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [page, itensPerPage, transportador, placa, clienteId, cpf]);

  useEffect(() => {
    const fetchDataCount = async () => {
      if (!transportador) return;
      try {
        const response = await axios.get(`/reciclagem/count/${transportador}`);
        setTotalRegistros(response.data);
        //console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchDataCount();
  }, [transportador]);

  // listar CPF condutor

  useEffect(() => {
    const fetchDataCpf = async () => {
      if (!transportador) return;
      try {
        const response = await axios.get(`/condutor/listarDadosGrid`, {
          params: {
            idCliente: transportador,
            pagina: 0,
            qtdRegistros: 1000,
          },
        });
        setListaCpfCondutor(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchDataCpf();
  }, [transportador]);

  // listar placa veiculos

  useEffect(() => {
    const fetchDataPlaca = async () => {
      if (!transportador) {
        return;
      }
      try {
        const response = await axios.get(
          `/veiculo/listarVeiculosPorCliente?cliente=${transportador}`
        );
        setListaPlaca(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchDataPlaca();
  }, [transportador]);

  // lista clientes(transportador)
  useEffect(() => {
    const fetchDataTransportador = async () => {
      try {
        const response = await axios.get('/cliente/getIdRazaoSocial');
        setListaTransportador(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchDataTransportador();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Reciclagem</h2>
          <h6 className="subtitulo">{'Operacional > Solicitações > Reciclagem'}</h6>
        </div>
        <div className="header-associar-container">
          <div className="header-associar-field" style={{ width: '150%' }}>
            <span>Transportador</span>
            <Dropdown
              value={transportador}
              options={listaTransportador}
              onChange={(e) => {
                setTransportador(e.value);
              }}
              optionLabel="razaoSocial"
              optionValue="id"
              filter
              filterBy="razaoSocial"
              placeholder="Transportador"
              className="p-dropdown"
              showClear
              style={{ width: '97%' }}
            />
          </div>
          <div className="header-associar-field">
            <span>CPF</span>
            <Dropdown
              value={cpf}
              options={listaCpfCondutor}
              onChange={(e) => {
                setCpf(e.value);
              }}
              optionLabel="cpf"
              optionValue="cpf"
              filter
              filterBy="cpf"
              placeholder={!transportador ? 'CPF' : 'TODOS'}
              className="p-dropdown"
              disabled={!transportador || placa}
              showClear
            />
          </div>
          <div className="header-associar-field">
            <span>Placa</span>
            <Dropdown
              value={placa}
              options={listaPlaca}
              onChange={(e) => {
                setPlaca(e.value);
              }}
              optionLabel="placa"
              optionValue="placa"
              filter
              filterBy="placa"
              placeholder={!transportador ? 'Placa' : 'TODOS'}
              className="p-dropdown"
              disabled={!transportador || cpf}
              showClear
            />
          </div>
          <div className="search-create mt-4">
            <Link to="/cliente/operacional/Solicitacoes/Reciclagem" className="btn-create">
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
                title="Cadastro Reciclagem"
              ></i>
            </Link>
          </div>
        </div>

        <div className="painel" style={{ width: '100%' }}>
          <div className="table-responsive" style={{ width: '100%' }}>
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>CPF/Placa</th>
                    <th>Nome Condutor/Modelo Veículo</th>
                    <th>Data Reciclagem</th>
                    <th>Data Lançamento</th>
                    <th>Usuário Lançamento</th>
                  </tr>
                </thead>
                <tbody>
                  {!transportador ? (
                    <tr>
                      <th style={{ textAlign: 'center', marginTop: 25, color: 'red' }} colSpan={5}>
                        Selecione um transportador para fazer a busca
                      </th>
                    </tr>
                  ) : (
                    listaReciclagem.map((listaR) => (
                      <tr>
                        <td>{listaR.placaVeiculo ? listaR.placaVeiculo : listaR.cpfCondutor}</td>
                        <td>{listaR.modeloVeiculo ? listaR.modeloVeiculo : listaR.nomeCondutor}</td>
                        <td>
                          {listaR.dataTreinamento === null
                            ? '--/--/--'
                            : obterData(listaR.dataTreinamento)}
                        </td>
                        <td>
                          {listaR.dataCadastro === null ? '' : obterDataHora(listaR.dataCadastro)}
                        </td>
                        <td>{listaR.nomeUsuario}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>

          <Pagination
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            totalPosts={totalRegistros}
            paginate={paginate}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  );
}

export default GridReciclagem;
