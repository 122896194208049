import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import api from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { Tela } from '../../../components/Tela';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';

export const FiltroJornada = () => {
  const [embarcador, setEmbarcador] = useState('');
  const [transportador, setTransportador] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [model, setModel] = useState<any>();
  const { addToast } = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    history.push('/listar/macros-jornada/1');
  };

  return (
    <Tela
      nome="Jornada de Trabalho"
      caminho="Operacional > Consultas/Relatórios > Rastreamento > Jornada de Trabalho"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
        <Form.Row>
          <Form.Group className="row-modal">
            <Form.Label>Data Início</Form.Label>
            <Form.Control value={dataInicio} type="date" id="date" name="date" placeholder="" />
            <Form.Control.Feedback type="invalid">
              Campo Data Início não pode ser nulo.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="row-modal">
            <Form.Label>Data Fim</Form.Label>
            <Form.Control value={dataFim} type="date" id="dataFim" name="dataFim" placeholder="" />
            {/* {dataFimError && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                {dataFimError}
              </p>
            )} */}
          </Form.Group>
        </Form.Row>

        <Form.Label className="SMLancadasTitle">Busca por veículo</Form.Label>
        <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
          <Form.Label>Placa n°</Form.Label>
          <Form.Control
            value={model?.placaVeiculo ? model?.placaVeiculo : ''}
            type="text"
            id="placaVeiculo"
            name="placaVeiculo"
            placeholder=""
          />
        </Form.Group>

        <Form.Label className="SMLancadasTitle">Busca por Viagem</Form.Label>
        <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
          <Form.Label>N° SM</Form.Label>
          <Form.Control
            value={model?.placaVeiculo ? model?.placaVeiculo : ''}
            type="text"
            id="placaVeiculo"
            name="placaVeiculo"
            placeholder=""
          />
        </Form.Group>

        <ButtonsForm />
      </Form>
    </Tela>
  );
};
