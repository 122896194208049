import React, { FC } from 'react';
// import { useHistory } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { ComponentAutotrac } from '../../components/ComponentAutotrac';
import { ComponentOmnilink } from '../../components/ComponentOmnilink';
import { ComponentOnixsat } from '../../components/ComponentOnixsat';
import { ComponentSascar } from '../../components/ComponentSascar';

type Props = {
  canEdit?: boolean;
};

export const ParametrosAutomatizacao: FC<Props> = ({ canEdit }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Automatização</h2>
          <h6 className="subtitulo">{'Módulo Gerenciador -> Parâmetros -> Automatização'}</h6>
        </div>
        <div className="painel">
          <Tabs defaultActiveKey="AUTOTRAC" id="uncontrolled-tab-example">
            <Tab eventKey="AUTOTRAC" title="AUTOTRAC">
              <div className="container" style={{ marginTop: 12 }}>
                <ComponentAutotrac canEdit={canEdit} />
              </div>
            </Tab>
            <Tab eventKey="OMNILINK" title="OMNILINK">
              <div className="container" style={{ marginTop: 12 }}>
                <ComponentOmnilink canEdit={canEdit} />
              </div>
            </Tab>
            <Tab eventKey="ONIXSAT" title="ONIXSAT">
              <div className="container" style={{ marginTop: 12 }}>
                <ComponentOnixsat canEdit={canEdit} />
              </div>
            </Tab>
            <Tab eventKey="SASCAR" title="SASCAR">
              <div className="container" style={{ marginTop: 12 }}>
                <ComponentSascar canEdit={canEdit} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
