import React, { useState, ChangeEvent, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../hooks/Toast';
import { CadastroServicoSmpt } from '../../api/model/CadastroServicoSmpt';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { PrimaryButton } from '../../components/ButtonsForm/styles';

type Props = {
  canEdit?: boolean;
};

export const ServicoSmpt = ({ canEdit }: Props) => {
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [id, setId] = useState(Number);
  const [ssl, setSsl] = useState<any>();
  const [auten, setAuten] = useState<any>();
  const [model, setModel] = useState<CadastroServicoSmpt>({
    email: '',
    nomeExibicao: '',
    servidorSaida: '',
    porta: 0,
    requerSSL: 1,
    requerAutenticacao: 1,
    usuario: '',
    senha: '',
  });
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  // -------------------GET
  const pegarEmail = async () => {
    const response = await axios.get('/parametro-email');
    const recebeDados = response.data.find((filtId: any) => filtId.id === 1 || [0]);
    if (recebeDados !== undefined) {
      setId(recebeDados.id);
      const recebeSSL = recebeDados.requerSSL ? 0 : 1;
      const recebeAuten = recebeDados.requerAutenticacao ? 0 : 1;
      setModel({
        ...model,
        email: recebeDados.email,
        nomeExibicao: recebeDados.nomeExibicao,
        porta: recebeDados.porta,
        senha: recebeDados.senha,
        requerSSL: recebeSSL,
        requerAutenticacao: recebeAuten,
        servidorSaida: recebeDados.servidorSaida,
        usuario: recebeDados.usuario,
      });
    }
  };
  // --------------UseEffect DO GET
  useEffect(() => {
    pegarEmail();
  }, []);

  // ------------------POST
  async function handleCadastro(event: any) {
    event.preventDefault();
    if (
      !model.email ||
      !model.nomeExibicao ||
      !model.servidorSaida ||
      !model.usuario ||
      !model.senha
    ) {
      // alert('Verifique os dados preenchidos.');
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      return;
    }
    const params = {
      email: model.email,
      nomeExibicao: model.nomeExibicao,
      porta: model.porta,
      requerAutenticacao: auten,
      requerSSL: ssl,
      senha: model.senha,
      servidorSaida: model.servidorSaida,
      usuario: model.usuario,
    };
    try {
      await axios.post('/parametro-email/enviar', params);
      setIsDialogCadOK(true);
      // alert(
      //   'Após confirmação , você receberá um e-mail no endereço indicado para validar o cadastro'
      // );
      pegarEmail();
      setIsFormInvalid(false);
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Erro ao enviar o e-mail',
        type: 'error',
      });
      // alert('Erro ao tentar enviar o e-mail');
    }
  }

  // ---------------------UPDATE
  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setModel({
      ...model,
      [e.target.name]: e.target.value,
    });
  }

  // ---------------------UPDATE
  const atualizarEmail = async (e: any) => {
    e.preventDefault();
    if (
      !model.email ||
      !model.nomeExibicao ||
      !model.servidorSaida ||
      !model.usuario ||
      !model.senha
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      return;
    }
    const params = {
      email: model.email,
      nomeExibicao: model.nomeExibicao,
      porta: model.porta,
      requerAutenticacao: auten,
      requerSSL: ssl,
      senha: model.senha,
      servidorSaida: model.servidorSaida,
      usuario: model.usuario,
    };
    try {
      await axios.put(`parametro-email/atualizar/${id}`, params);
      setIsDialogCadOK(true);
      // alert('Atualizado com sucesso!');
      pegarEmail();
      setIsFormInvalid(false);
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Erro ao atualizar',
        type: 'error',
      });
      // alert('Erro ao Atualizar!');
    }
  };

  const handleSSL = (e: ChangeEvent<HTMLInputElement>) => {
    const convert = parseInt(e.target.value, 32);
    let recebe;
    setModel({
      ...model,
      requerSSL: e.target.value,
    });
    if (convert <= 0) {
      recebe = true;
    } else {
      recebe = false;
    }
    setSsl(recebe);
  };

  const handleAuten = (e: ChangeEvent<HTMLInputElement>) => {
    const converter = parseInt(e.target.value, 32);
    let recebe;
    setModel({
      ...model,
      requerAutenticacao: e.target.value,
    });
    if (converter <= 0) {
      recebe = true;
    } else {
      recebe = false;
    }
    setAuten(recebe);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header={
          id
            ? 'Atualizado com sucesso!'
            : 'Após confirmação , você receberá um e-mail no endereço indicado para validar o cadastro'
        }
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          window.location.reload();
        }}
      />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">E-mail</h2>
          <h6 className="subtitulo">{'Módulo Gerenciador -> Parâmetros -> E-mail'}</h6>
        </div>
        <div className="painel">
          <Form onSubmit={(e) => handleCadastro(e)} noValidate validated={isFormInvalid}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="required">E-mail</Form.Label>
                <Form.Control
                  value={model.email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                    // if (validaEmail(e.target.value)) {
                    //   setIsValidEmail(true);
                    // } else {
                    //   setIsValidEmail(false);
                    // }
                  }}
                  type="email"
                  id="email"
                  name="email"
                  maxLength={50}
                  minLength={7}
                  // title="Deve conter entre 7 e 50 caracteres"
                  required
                />
                {/* {!isValidEmail && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
                )} */}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="required">Nome Exibição</Form.Label>
                <Form.Control
                  value={model.nomeExibicao}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                    // if (validaNome(e.target.value)) {
                    //   setIsNomeExibicaoValid(true);
                    // } else {
                    //   setIsNomeExibicaoValid(false);
                    // }
                  }}
                  id="nomeExibicao"
                  name="nomeExibicao"
                  type="text"
                  maxLength={36}
                  minLength={3}
                  // title="Deve conter entre 3 e 36 caracteres"
                  required
                />
                {/* {!isNomeExibicaoValid && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Nome Exibição inválido, inserir nome e sobrenome.
                  </p>
                )} */}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="required">Servidor de Saida</Form.Label>
                <Form.Control
                  value={model.servidorSaida}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                  }}
                  id="servidorSaida"
                  name="servidorSaida"
                  required
                  minLength={3}
                  maxLength={96}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Porta</Form.Label>
                <Form.Control
                  type="number"
                  value={model.porta}
                  onChange={(event: any) =>
                    setModel({
                      ...model,
                      porta: event.target.value.slice(0, 5),
                    })
                  }
                  id="porta"
                  name="porta"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Requer SSL?</Form.Label>
                <Form.Control
                  value={model.requerSSL}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleSSL(e)}
                  as="select"
                  id="requerSSL"
                  name="requerSSL"
                >
                  <option value="0">Sim</option>
                  <option value="1">Não</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Requer Autenticação?</Form.Label>
                <Form.Control
                  value={model.requerAutenticacao}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleAuten(e)}
                  as="select"
                  id="requerAltenticacao"
                  name="requerAltenticacao"
                >
                  <option value="0">Sim</option>
                  <option value="1">Não</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="required">Usuário</Form.Label>
                <Form.Control
                  value={model.usuario}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    updateModel(e);
                    // if (validaNome(e.target.value)) {
                    //   setIsUsuarioValid(true);
                    // } else {
                    //   setIsUsuarioValid(false);
                    // }
                  }}
                  id="usuario"
                  name="usuario"
                  required
                  type="text"
                  maxLength={36}
                  minLength={3}
                  // title="Deve conter entre 3 e 36 caracteres"
                />
                {/* {!isUsuarioValid && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Usuario inválido, inserir nome e sobrenome.
                  </p>
                )} */}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="required">Senha</Form.Label>
                <Form.Control
                  value={model.senha}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateModel(event);
                  }}
                  id="senha"
                  name="senha"
                  type="password"
                  maxLength={16}
                  minLength={8}
                  // title="Deve conter entre 8 e 16 caracteres"
                  required
                />
              </Form.Group>
            </Form.Row>
          </Form>

          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col} md={2}>
              <Button
                style={{ display: id >= 1 ? 'none' : '' }}
                size="sm"
                type="submit"
                className="btn-enviar"
                onClick={(e) => handleCadastro(e)}
              >
                Enviar
              </Button>
            </Form.Group>
          </Form.Row>

          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col} md={2}>
              {canEdit ? (
                <PrimaryButton
                  style={{ display: id >= 1 ? '' : 'none' }}
                  type="submit"
                  onClick={atualizarEmail}
                >
                  Atualizar
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  style={{ display: id >= 1 ? '' : 'none' }}
                  type="button"
                  onClick={() => history.push('/acessonegado')}
                >
                  Atualizar
                </PrimaryButton>
              )}
            </Form.Group>
          </Form.Row>
        </div>
      </div>
    </div>
  );
};
