/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { IoCheckbox, IoChevronUp } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Pagination from '../../components/Pagination/Pagination';
import axios from '../../config/axiosMaquina';

import Search from '../../components/Search/Search';
import './styles.css';
import { Spinner } from 'react-bootstrap';
import { ConfirmarPreSMType, ListaPontosType } from '../../api/model/ConfirmarPreSMType';
import { getCookieSessionData } from '../../services/cookieService';
import { obterDataHora } from '../../util/date';

type Props = {
  canEdit?: boolean;
};

const ConfirmaPreSM = ({ canEdit }: Props) => {
  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [itensPerPage, setItensPerPage] = useState('10');
  // const [search, setSearch] = useState('');
  // const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<ConfirmarPreSMType[]>([]);
  const [listaConfirmarPreSM, setListaConfirmarPreSM] = useState<ConfirmarPreSMType[]>([]);
  const [listaPontos, setListaPontos] = useState<ListaPontosType[]>([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState<ConfirmarPreSMType[]>([]);

  const { page }: any = useParams();

  const history = useHistory();

  const paginate = (pageNumber: number) => {
    history.push(`/confirmacao-presm/${pageNumber}`);
  };
  // busca por todos os registros

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/solicitacao-monitoramento/listarGridSmConfirmacao`, {
        params: {
          idCliente: clienteId,
          qtdRegistros: itensPerPage,
          pagina: page - 1,
        },
      });

      // response.data = response.data.sort((a: ConfirmarPreSMType, b: ConfirmarPreSMType) => {
      //   return a.numeroSm < b.numeroSm ? 1 : b.numeroSm < a.numeroSm ? -1 : 0;
      // });

      setListaConfirmarPreSM(response.data);
      setLoading(false);
    };
    fetchData();
  }, [itensPerPage, page]);

  useEffect(() => {
    const fetchQtdPosts = async () => {
      const response = await axios.get(
        `/solicitacao-monitoramento/listarGridConfirmarPreSM/count`,
        {
          params: {
            idCliente: clienteId,
          },
        }
      );
      setTotalPosts(response.data);
    };
    fetchQtdPosts();
  }, []);

  const onClickHandler = async (index: number, numeroSm: number) => {
    const response = await axios.get(
      `/solicitacao-monitoramento-plano-viagem/listarPontosPorPreSM/${numeroSm}`
    );

    setListaPontos(response.data);

    setFilteredData((prevState) => {
      return prevState.map((each, eachIndex) => {
        if (numeroSm === each.numeroSm && (each.show === false || each.show === undefined)) {
          return { ...each, show: true };
        } else {
          return { ...each, show: false };
        }
      });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!searchTerm) {
        setFilteredData(listaConfirmarPreSM);
        return;
      }
      try {
        const response = await axios.get('/solicitacao-monitoramento/searchConfirmarPreSM', {
          params: {
            idCliente: clienteId,
            keyword: searchTerm,
          },
        });

        setFilteredData(response.data);
      } catch (err: any) {
        setFilteredData(listaConfirmarPreSM);
        console.log(err);
      }
    };
    fetchData();
  }, [listaConfirmarPreSM, searchTerm]);

  function goToCadSM(numeroSm: number) {
    history.push({
      pathname: `/solicitacao-monitoramento/${numeroSm}`,
      state: 'confirmaSM',
    });
  }

  const formataHorario = (hora: string) => {
    let horario: string = '';
    if (hora) {
      let minuto = hora.split(':')[1];
      let splittedHora = hora.split(':')[0];
      if (splittedHora === '00') {
        horario = '21' + ':' + minuto;
      } else {
        let timeZone = parseInt(splittedHora);
        horario = timeZone.toString() + ':' + minuto;
      }
    }

    return horario;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Confirmação Pré-SM</h2>
          <h6 className="subtitulo">{'Operacional > Solicitações > Confirmação Pré-SM'}</h6>
        </div>
        <Search
          disabled={!listaConfirmarPreSM}
          setLoading={setLoading}
          setSearch={setSearch}
          setSearchValue={setSearchTerm}
          search={search}
        />
        <div className="content-container">
          {loading || !listaConfirmarPreSM ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <table className="table">
              <thead className="thead">
                <tr>
                  <th style={{ width: 80 }}></th>
                  <th>Nº</th>
                  <th>Embarcador</th>
                  <th>Ponto Origem</th>
                  <th>Previsão Início</th>
                  <th style={{ width: 80 }}>Confirmar</th>
                </tr>
              </thead>
              <tbody>
                {filteredData &&
                  filteredData.map((each, index) => {
                    return (
                      <>
                        <tr key={index} onClick={() => onClickHandler(index, each.numeroSm)}>
                          <td>
                            {
                              <IoChevronUp
                                size={22}
                                color="#9F9F9F"
                                className={`animation-icon ${
                                  each.show ? 'animation-icon-animate' : ''
                                }`}
                              />
                            }
                          </td>
                          <td>{each.numeroSm}</td>
                          <td>{each.embarcador}</td>
                          <td>{each.pontoOrigem}</td>
                          <td>
                            {each.previsaoInicio ? obterDataHora(each.previsaoInicio, true) : ''}
                          </td>
                          <td>
                            <Button
                              tooltip="Confirmar pré-SM"
                              tooltipOptions={{ position: 'bottom' }}
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                paddingRight: 20,
                                marginBottom: 2,
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                if (canEdit) {
                                  goToCadSM(each.numeroSm);
                                } else {
                                  history.push('/acessonegado');
                                }
                              }}
                            >
                              <IoCheckbox size={22} color="#9F9F9F" />
                            </Button>
                          </td>
                        </tr>
                        <tr className={`collapse ${each.show ? 'show' : ''}`}>
                          <td colSpan={10}>
                            <tr>
                              <div
                                style={{
                                  marginLeft: 20,
                                  width: '100%',
                                  display: 'flex',
                                  flex: 1,
                                  flexDirection: 'column',
                                }}
                              >
                                {listaPontos
                                  .sort(function (a: any, b: any) {
                                    return a.sequencia - b.sequencia;
                                  })
                                  .map((lp) => {
                                    return (
                                      <>
                                        <span key={index}>
                                          Ponto ({lp.sequencia === null ? 0 : lp.sequencia}):{' '}
                                          {lp.nomePonto}
                                        </span>
                                        <ul>
                                          <li>Remessa: {lp.remessa === null ? 0 : lp.remessa}</li>
                                          <li>
                                            Agendamento:{' '}
                                            {lp.agendamento === null
                                              ? ''
                                              : obterDataHora(lp.agendamento, true)}
                                          </li>
                                        </ul>
                                      </>
                                    );
                                  })}
                              </div>
                            </tr>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          )}
          <Pagination
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            totalPosts={searchTerm ? searchResults?.length : totalPosts ? totalPosts : 0}
            paginate={paginate}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmaPreSM;
