/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Modal, Spinner, Col } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { getCookieSessionData } from '../../../services/cookieService';
import { obterData, toZoned } from '../../../util/date';

type SazonalidadeCargaType = {
  szcCdId?: number | null;
  szcTxNome: string;
  dataInicio: string | undefined;
  dataFim: string | undefined;
  idCliente: number;
};

function formatDate(date: string | null | Date | undefined) {
  if (!date) {
    return ' ';
  }
  return date.toString();
}

const dateMax = () => {
  const _dateMax = new Date('1987-01-01 GMT-0300');
  return `${_dateMax.getFullYear().toString()}-${(_dateMax.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${_dateMax.getDate().toString().padStart(2, '0')}`;
};

const dateNow = () => {
  const now = new Date();
  const _dateNow = new Date(now.getTime());
  return `${_dateNow.getFullYear().toString()}-${(_dateNow.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${_dateNow.getDate().toString().padStart(2, '0')}`;
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const SazonalidadeCargaForm: React.FC<Props> = ({ canEdit, canDelete }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();
  const { clienteId } = getCookieSessionData().usuarioVO;

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [model, setModel] = useState<SazonalidadeCargaType>({
    szcCdId: id || null,
    szcTxNome: '',
    dataInicio: '',
    dataFim: '',
    idCliente: clienteId,
  });
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { szcTxNome, dataInicio, dataFim } = model;

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setModel({ ...model, [event.target.name]: event.target.value });
    },
    [model]
  );

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`/sazonalidade-carga/find/${id}`);
        setModel(response.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!szcTxNome || !dataInicio || !dataFim) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    if (id) {
      setLoading(true);
      try {
        await axios.put(`/sazonalidade-carga/atualizar?idFrc=${id}`, {
          ...model,
          dataInicio: model.dataInicio,
          dataFim: model.dataFim,
        });
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        await axios.post('/sazonalidade-carga/save', {
          ...model,
          dataInicio: model.dataInicio,
          dataFim: model.dataFim,
        });
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`/sazonalidade-carga/delete/${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      history.push('/listar-sazonalidade-da-carga/1');
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Sazonalidade da Carga ${id ? 'alterada' : 'cadastrada'} com sucesso!`}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-sazonalidade-da-carga/1');
        }}
      />
      <Modal
        animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Sazonalidade da Carga excluída com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Sazonalidade da Carga</h2>
          <h6 className="subtitulo">{`Cadastro > Sazonalidade da Carga > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <div className="painel">
            <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
            <Form className="form-sm" noValidate validated={isFormInvalid} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="requiredField">Nome</Form.Label>
                <Form.Control
                  value={szcTxNome}
                  onChange={handleOnChange}
                  required
                  name="szcTxNome"
                  maxLength={64}
                  minLength={1}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} lg={3} md={5}>
                  <Form.Label className="requiredField">Data Início</Form.Label>
                  <Form.Control
                    value={obterData(dataInicio, 'en2')}
                    id="dataInicial"
                    onChange={handleOnChange}
                    required
                    min={dateMax()}
                    max="2040-12-31"
                    name="dataInicio"
                    type="date"
                  />
                </Form.Group>
                <Form.Group as={Col} lg={3} md={5}>
                  <Form.Label className="requiredField">Data fim</Form.Label>
                  <Form.Control
                    value={obterData(dataFim, 'en2')}
                    id="dataFim"
                    onChange={handleOnChange}
                    required
                    min={dateNow()}
                    max="2040-12-31"
                    name="dataFim"
                    type="date"
                  />
                </Form.Group>
              </Form.Row>
              <ButtonsForm canEdit={canEdit} />
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default SazonalidadeCargaForm;
