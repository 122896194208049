/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable*/
import React, { FC, useEffect, useState } from 'react';
import { Form, Button, Col, Spinner } from 'react-bootstrap';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import Input from '../../components/Input';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import api from '../../config/axiosMaquina';
import getTipoBau from '../../util/Validacoes/getTipoBau';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';

type SatData = {
  comunicacao: string;
  empresa: string;
  idCliente: number;
  marcaCarreta: string;
  marcaVeiculo: string;
  modeloBau: string;
  modeloCarreta: string;
  modeloVeiculo: string;
  numeroBau: string;
  numeroEquipamento: string;
  placaVeiculo: string;
  placaCarreta: string;
  responsavel: string;
  tecnologia: string;
  tecnologiaCarreta: string;
  tipoBau: string;
  tipoCarreta: string;
  tipoFrota: string;
  idVeiculo: number;
  idCarreta: number;
  idBau: number;
  tipoFrotaCarreta: string;
};

type DispositivoData = {
  defeito: boolean;
  dispositivoId: number;
  dispositivoNome: string;
  dtFinalizacao: string;
  dtLancamento: string;
  id: number;
  satId: number;
};
type VeiculoData = {
  id: number;
  idSat: number;
  nomeTipoFrota: string;
  modeloVeiculo: string;
  numeroEquipamento: string;
  placaVeiculo: string;
  tecnologiaVeiculo: string;
  tipoComunicacaoVeiculo: string;
  listaSatDispositivos: DispositivoData[];
};
type BauData = {
  id: number;
  idBau: number;
  numeroBau: string;
  idCliente: number;
  modeloBau: string;
  idSat: number;
  dataInclusaoSat: string;
  nomeResponsavel: string;
  listaSatDispositivos: DispositivoData[];
};

const VisualizarSat: FC = () => {
  const [satDados, setSatDados] = useState<SatData>({} as SatData);
  const [loading, setLoading] = useState(false);
  const [veiculoData, setVeiculoData] = useState<VeiculoData>({} as VeiculoData);
  const [bauData, setBauData] = useState({} as BauData);
  const { id }: any = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `/solicitacao-assistencia-tecnica/visualizar-dado-sat/${id}`
        );
        setSatDados(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        console.log(err.response);
      }
    };
    fetchData();
  }, []);

  function reloadPage() {
    window.location.reload();
  }
  useEffect(() => {
    const loadVeiculoData = async () => {
      console.log(satDados.idVeiculo);
      if (satDados.idVeiculo === null && satDados.idCarreta === null) {
        return;
      }
      try {
        const responseVeiculo = await api.get(`/veiculo/listarDadosVeiculoParaSAT`, {
          params: {
            idCliente: satDados.idCliente,
            veiculo: satDados.idVeiculo !== null ? satDados.idVeiculo : satDados.idCarreta,
          },
        });
        console.log(responseVeiculo.data);
        setVeiculoData(responseVeiculo.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    loadVeiculoData();
  }, [satDados.idVeiculo, satDados.idCarreta]);

  useEffect(() => {
    const loadBauData = async () => {
      if (satDados.idBau === null) {
        return;
      }
      try {
        const responseBau = await api.get('bau-intercambiavel/listarDadosBauParaSAT', {
          params: {
            bau: satDados.idBau,
          },
        });
        console.log(responseBau.data);
        setBauData(responseBau.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    loadBauData();
  }, [satDados.idBau]);

  const renderVeiculoComponent = () => (
    <>
      <Form.Row>
        <Input
          value={satDados.placaVeiculo}
          id="veiculo"
          name="veiculo"
          textInputTitle="Veículo"
          disabled
        />
        <Input
          value={`${satDados.marcaVeiculo} ${satDados.modeloVeiculo}`.toUpperCase()}
          id="modelo"
          name="modelo"
          textInputTitle="Marca / Modelo"
          disabled
        />
        <Input
          value={getTipoFrota(satDados.tipoFrota)}
          id="tipoFrota"
          name="tipoFrota"
          textInputTitle="Tipo Frota"
          disabled
        />
      </Form.Row>

      <Form.Row>
        <Input
          value={satDados.tecnologia}
          id="tecnologia"
          name="tecnologia"
          textInputTitle="Tecnologia"
          disabled
        />
        <Input
          value={satDados.comunicacao}
          id="comunicacao"
          name="comunicacao"
          textInputTitle="Comunicação"
          disabled
        />
        <Input
          value={satDados.numeroEquipamento}
          id="numeroEquipamento"
          name="numeroEquipamento"
          textInputTitle="Nº Equipamento"
          disabled
        />
      </Form.Row>
    </>
  );

  const renderCarretaComponent = () => (
    <>
      <Form.Row>
        <Input
          value={satDados.placaCarreta}
          id="carreta"
          name="carreta"
          textInputTitle="Carreta"
          disabled
        />
        <Input
          value={satDados.modeloCarreta}
          id="tipoVeiculo"
          name="tipoVeiculo"
          textInputTitle="Tipo"
          disabled
        />
        <Input
          value={getTipoFrota(satDados.tipoFrotaCarreta)}
          id="tipoFrota"
          name="tipoFrota"
          textInputTitle="Tipo Frota"
          disabled
        />
      </Form.Row>
    </>
  );

  const renderBauComponent = () => (
    <Form.Row>
      <Input
        value={satDados.numeroBau}
        id="numeroBau"
        name="numeroBau"
        textInputTitle="Baú"
        disabled
      />

      <Input
        value={getTipoBau(satDados.tipoBau)}
        id="tipo"
        name="tipo"
        textInputTitle="Tipo"
        disabled
      />
    </Form.Row>
  );

  const renderListagemDispositivosVeiculo = () => (
    <tbody>
      {veiculoData &&
        veiculoData.listaSatDispositivos &&
        veiculoData.listaSatDispositivos.map((dispositivo) => (
          <tr key={dispositivo.dispositivoId}>
            <td style={{ width: 65 }}>{dispositivo.dispositivoId}</td>
            <td style={{ width: 300 }}>{dispositivo.dispositivoNome}</td>
            <td style={{ width: 80 }}>
              <IoCheckmarkCircle color={dispositivo.defeito ? '#63c960' : '#969696'} size={22} />
            </td>
          </tr>
        ))}
    </tbody>
  );

  const renderListagemDispositivosBau = () => (
    <tbody>
      {bauData &&
        bauData.listaSatDispositivos &&
        bauData.listaSatDispositivos.map((dispositivo) => (
          <tr key={dispositivo.dispositivoId}>
            <td style={{ width: 65 }}>{dispositivo.dispositivoId}</td>
            <td style={{ width: 300 }}>{dispositivo.dispositivoNome}</td>
            <td style={{ width: 80 }}>
              <IoCheckmarkCircle color={dispositivo.defeito ? '#63c960' : '#969696'} size={22} />
            </td>
          </tr>
        ))}
    </tbody>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação Assistência Técnica</h2>
          <h6 className="subtitulo">
            {'Operacional > Solicitação > Solicitação Assistência Técnica > Visualizar SAT'}
          </h6>
        </div>

        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <Form.Row>
              <Input
                value={satDados.empresa ? satDados.empresa : ''}
                id="empresa"
                name="empresa"
                textInputTitle="Empresa"
                disabled
              />

              <Input
                value={satDados.idCliente ? satDados.idCliente : ''}
                id="codigo"
                name="codigo"
                textInputTitle="Código"
                disabled
              />
            </Form.Row>
            {satDados.marcaVeiculo !== null ? renderVeiculoComponent() : null}
            {satDados.marcaCarreta !== null ? renderCarretaComponent() : null}
            {satDados.numeroBau !== null ? renderBauComponent() : null}
            {satDados.idCliente ? (
              <>
                <Form.Row>
                  <Input
                    disabled
                    value={satDados.responsavel}
                    id="responsavel"
                    name="responsavel"
                    textInputTitle="Responsável"
                  />
                </Form.Row>
                <hr />
                <Form.Label className="SMLancadasTitle">
                  Dispositivos vinculados ao veículo
                </Form.Label>
                <div className="painel" style={{ alignSelf: 'center', marginTop: 20 }}>
                  <div className="table-responsive">
                    <table className="table" id="table-cadastro-sat">
                      <thead className="thead">
                        <tr>
                          <th style={{ width: 65 }}>ID </th>
                          <th style={{ minWidth: 300 }}>Dispositivo</th>
                          <th style={{ width: 80 }}>Defeito</th>
                        </tr>
                      </thead>
                      {bauData.id && renderListagemDispositivosBau()}
                      {veiculoData.id && renderListagemDispositivosVeiculo()}
                    </table>
                  </div>
                </div>
                <Form.Row className="container-buttons justify-content-end">
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-cancelar"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Group>
                </Form.Row>
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default VisualizarSat;
