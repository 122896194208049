/*eslint-disable*/
import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import BinEdit from './pages/BauIntercambiavel/BinEdit';
import Home from './pages/Home';
import PrivateRoute from './components/Routes/PrivateRoute';
import Veiculo from './pages/Veiculo';
import VeiDetalhes from './pages/Veiculo/Form/index';
import Rotas from './pages/Rotas';
import CadastrarRota from './pages/CadastrarRota';
import CadastroVeiculo from './pages/Veiculo/CadastroVeiculo/index';
import AvisosImportantes from './pages/Monitoramento/AvisosImportantes/AvisosImportantes';
import CadastroBau from './pages/BauIntercambiavel/BinAdd';
import BauIntercambiavel from './pages/BauIntercambiavel';
import CadastroSat from './pages/CadastroSat/CadastroSat';
import EditarRotas from './pages/EditarRotas';
import SolicitacaoMonitoramentoCadastro from './pages/SolicitacaoMonitoramentoCadastro';
import Ajudantes from './pages/Ajudantes';
import AjudanteCadastro from './pages/AjudanteCadastro';
import AjudanteEditar from './pages/AjudanteEditar';
import CadastroUsuarioGerenciador from './pages/CadastroUsuarioGerenciador';
import UsuariosGerenciadores from './pages/UsuariosGerenciadores';
import AtualizacaoAvisos from './pages/AvisoOperacional/AtualizacaoAvisos';
import CadastroAvisos from './pages/AvisoOperacional/CadastroAvisos';
import ListagemCondutor from './pages/Condutor/ListagemCondutor';
import ListagemAvisos from './pages/AvisoOperacional/ListagemAvisos';
import AtualizarCondutor from './pages/Condutor/AtualizacaoCondutor';
import CadastroCondutor from './pages/Condutor/CadastroCondutor';
import AtualizarAvisosCliente from './pages/AvisoClientes/AtualizacaoAvisosCliente';
import CadastroAvisosCliente from './pages/AvisoClientes/CadastroAvisosCliente';
import ListagemAvisosCliente from './pages/AvisoClientes/ListagemAvisosCliente';
import Notificacao from './pages/AvisoClientes/Notificacao';
import GridPortaria from './pages/Portaria';
import ListagemChecklistEmbarcador from './pages/ChecklistEmbarcador';
import ListagemUsuariosCorretoras from './pages/Corretoras/GridUsuarios';
import CadastroUsuariosCorretoras from './pages/Corretoras/CadastroUsuario';
import AtualizarUsuariosCorretoras from './pages/Corretoras/AtualizacaoUsuario';
import CadastroUsuariosSeguradoras from './pages/Seguradoras/CadastroUsuario';
import ListagemUsuariosSeguradoras from './pages/Seguradoras/GridUsuarios';
import AtualizarUsuariosSeguradoras from './pages/Seguradoras/AtualizacaoUsuario';
import LancamentoNCTransportador from './pages/LancamentoNaoConformidade/LancarParaTransportador';
import LogOut from './pages/Logout';
import TempoParado from './pages/TempoParado';
import AnalisePerfilGrid from './pages/AnalisePerfil/Grid';
import EditarAnalisePerfil from './pages/AnalisePerfil/EditarAnalise';
import PesquisaCadastral from './pages/AnalisePerfil/PesquisaCadastral';
import AtualizacaoTecnologias from './pages/Tecnologias';
import CadastroParada from './pages/CadastroParada';
import EditarParada from './pages/EditarParada';
import IniciarSMPendente from './pages/IniciarSMPendente';
import EditarSMEmAndamento from './pages/EditarSMEmAndamento';
import { ServicoSmpt } from './pages/ParametrosCadastroServicoSmpt';
import { ParametrosSistema } from './pages/ParametrosSistema';
import { ParametrosIntegracao } from './pages/ParametrosIntegracao';
import { ParametrosAutomatizacao } from './pages/ParametrosAutomatizacao';
import { Reciclagem } from './pages/Reciclagem';
import { FaturamentoFrotaPage } from './pages/FaturamentoFrota';
import ProgressaoViagens from './pages/ProgressaoViagens/ProgressaoViagens';
import Mapa from './pages/Mapa/Mapa';
import EditarUsuarioGerenciador from './pages/EditarUsuarioGerenciador';
import AssociarClientes from './pages/AssociarClientes/AssociarClientes';
import CadastroUsuarioCliente from './pages/CadastroUsuarioCliente';
import UsuariosClientes from './pages/UsuariosClientes';
import EditarUsuarioCliente from './pages/EditarUsuarioCliente';
import NaoConformidades from './pages/NaoConformidades';
import CadastroNaoConformidade from './pages/CadastroNaoConformidade';
import FormularioConsultaNaoConformidade from './pages/NaoConformidades/ConsultaNaoConformidades/FormularioConsultaNaoConformidades';
import GridConsultaNaoConformidades from './pages/NaoConformidades/ConsultaNaoConformidades/GridConsultaNaoConformidades';
import EditarNaoConformidade from './pages/EditarNaoConformidade';
import GridCarretas from './pages/Carretas';
import VeiculosRastreamento from './pages/VeiculosRastreamento/VeiculosRastreamento';
import BlackListSeguradoras from './pages/BlackListSeguradoras';
import CadastroBlackListSeguradora from './pages/CadastroBlackListSeguradora';
import FaturamentoGerenciador from './pages/FaturamentoGerenciador';
import { EnviarPesquisa } from './pages/EnviarPesquisa';
import { HistoricoPesquisas } from './pages/Pesquisa/HistoricoPesquisas';
import GridReciclagem from './pages/GridReciclagem';
import RequisicaoAPI from './pages/RequisicaoAPI/RequisicaoAPI';
import HistoricoPosicoes from './pages/HistoricoPosicoes/HistoricoPosicoes';
import AcompanhamentoRemessa from './pages/AcompanhamentoRemessa/AcompanhamentoRemessas';
import AgendarViagens from './pages/AgendarViagens';
import ConfirmaPreSM from './pages/ConfirmaPreSM/ConfirmaPreSM';
import CancelamentoMonitoramento from './pages/CancelamentoMonitoramento/CancelamentoMonitoramento';
import ConsultarPesquisasLancadas from './pages/ConsultarPesquisasLancadas/ConsultarPesquisasLancadas';
import ConsultarSMLancadas from './pages/ConsultarSMLancadas/ConsultarSMLancadas';
import ListagemSMLancadas from './pages/ListagemSMLancadas/ListagemSMLancadas';
import FinalizarSMEmAndamento from './pages/FinalizarSMEmAndamento';
import VisualizarSMCancelada from './pages/VisualizarSMCancelada';
import EditarSMPendente from './pages/EditarSMPendente';
import CadastroPontos from './pages/Pontos/CadastroPontos';
import Paradas from './pages/Paradas';
import Pontos from './pages/Pontos/Pontos';
import EditarPontos from './pages/Pontos/EditarPontos';
import GridPreAgendamentoSM from './pages/AgendarViagens/GridPreAgendamentoSM';
import { DetalhePesquisa } from './pages/Pesquisa/DetalhePesquisa';
import CadastroSatFromMenu from './pages/CadastroSatFromMenu';
import NaoPossuiServico from './pages/NaoPossuiServico';
import ClienteGrid from './pages/Cliente/ClienteGrid';
import ClienteCadastro from './pages/Cliente/ClienteCadastro';
import ListagemChecklist from './pages/Checklist/ListagemChecklist';
import CadastroChecklist from './pages/Checklist/CadastroChecklist';
import EmbarcadorGrid from './pages/Embarcador/EmbarcadorGrid';
import EmbarcadorAdd from './pages/Embarcador/EmbarcadorAdd';
import CadastroCorretora from './pages/CadastroCorretora';
import CadastroSeguradora from './pages/CadastroSeguradora';
import ConsultarAjudante from './pages/ConsultarAjudantes/ConsultarAjudante';
import ConsultarCondutor from './pages/ConsultarCondutor/ConsultarCondutor';
import ConsultarProprietario from './pages/ConsultarProprietario/ConsultarProprietario';
import EditarProprietario from './pages/Proprietario/EditarProprietario';
import ListagemProprietario from './pages/Proprietario/ListagemProprietario';
import GridSat from './pages/GridSat/GridSat';
import VisualizarSat from './pages/GridSat/VisualizarSat';
import ConsultarVeiculo from './pages/ConsultarVeiculo/ConsultarVeiculo';
import ListagemConsultaVeiculo from './pages/ConsultarVeiculo/ListagemConsultarVeiculo';
import ConsultarCarreta from './pages/ConsultarCarreta/ConsultarCarreta';
import ListagemConsultaCarreta from './pages/ConsultarCarreta/ListagemConsultaCarreta';
import ListagemConsultaAjudante from './pages/ConsultarAjudantes/ListagemConsultaAjudante';
import ListagemConsultaCondutor from './pages/ConsultarCondutor/ListagemConsultaCondutor';
import ListagemConsultaProprietario from './pages/ConsultarProprietario/ListagemConsultaProprietario';
import ResumoSat from './pages/ResumoSat/ResumoSat';
import ListagemResumoSat from './pages/ResumoSat/ListagemResumoSat';
import ResumoChecklist from './pages/ResumoChecklist/ResumoChecklist';
import FiltroChecklist from './pages/ResumoChecklist';
import GridPesquisaCadastral from './pages/AnalisePerfil/PesquisaCadastral/GridPesquisaCadastral';
import ConsultarCorretora from './pages/ConsultarCorretora';
import ConsultarSeguradora from './pages/ConsultarSeguradora';
import ConsultarMarca from './pages/GridEditavelMarca';
import ConsultarCor from './pages/GridEditavelCor';
import ConsultarTiposComunicacao from './pages/GridEditavelTipoComunicacao';
import ListagemDispositivos from './pages/Dispositivos/List';
import { LSC } from './pages/LSC';
import FormDispositivo from './pages/Dispositivos/Form';
import ListagemNaoConformidades from './pages/NaoConformidadeAdmin/List';
import FormNaoConformidade from './pages/NaoConformidadeAdmin/Form';
import ListagemGrupoMacroVeiculos from './pages/GrupoMacroVeiculos/List';
import ListagemGrupoVeiculos from './pages/GrupoVeiculo/List';
import FormGrupoVeiculo from './pages/GrupoVeiculo/Form';
import FormTiposDeVeiculos from './pages/TiposDeVeiculos/FormTiposDeVeiculos';
import ListagemTiposDeVeiculos from './pages/TiposDeVeiculos/ListagemTiposDeVeiculos';
import ListagemFaixasCubagem from './pages/FaixasCubagem/List';
import FormFaixasCubagem from './pages/FaixasCubagem/Form';
import ListagemSazonalidadeCarga from './pages/SazonalidadeCarga/List';
import FormSazonalidadeCarga from './pages/SazonalidadeCarga/Form';
import ListagemCapacidadeCarga from './pages/CapacidadeCarga/List';
import FormCapacidadeCarga from './pages/CapacidadeCarga/Form';
import ListagemRankingAjudanteFuncionario from './pages/RankingAjudanteFuncionario/List';
import FormRankingAjudanteFuncionario from './pages/RankingAjudanteFuncionario/Form';
import ListagemRankingCondutor from './pages/RankingCondutor/List';
import FormRankingCondutor from './pages/RankingCondutor/Form';
import ListagemRankingVeiculo from './pages/RankingVeiculo/List';
import FormRankingVeiculo from './pages/RankingVeiculo/Form';
import ConsultarCD from './pages/GridEditavelCD';
import ConsultarRegional from './pages/GridEditavelRegional';
import ListagemAnalistaPerfil from './pages/AnalistaPerfil/List';
import FormAnalistaPerfil from './pages/AnalistaPerfil/Form';
import ListagemTecnologiaRastreamento from './pages/TecnologiaAdmin/List';
import FormTecnologiaRastreamento from './pages/TecnologiaAdmin/Form';
import Motivos from './pages/Motivos';
import './global/styles.css';
import { PrevencaoAcidentes } from './pages/PrevencaoAcidentes';
import { ConsultarPerfisUsuarios } from './pages/ConsultarPerfisUsuarios';
import { CadastroPerfisUsuarios } from './pages/CadastroPerfisUsuarios';
import VelocidadePorVeiculo from './pages/Veiculo/VelocidadePorVeiculo';
import TemperaturaPorVeiculo from './pages/Veiculo/TemperaturaPorVeiculo';
import AcessoNegado from './components/AcessoNegado';
import { isAllowedByPermission } from './services/cookieService';
import { PaginaTeste } from './pages/PaginaTeste';
import { Sm } from './components/SM';
import { CarregandoPermissoes } from './pages/CarregandoPermissoes';
import { SmsEnviados } from './pages/SmsEnviados/List';
import { PainelOperacional } from './pages/PainelOperacional';
import { ParametrosJornada } from './pages/ParametrosJornada';
import { ImportarPreSm } from './pages/ImportarPreSm';
import { MacrosJornada } from './pages/MacrosJornada/Form';
import { FiltroJornada } from './pages/MacrosJornada/Filter';
import { MacrosJornadaEditar } from './pages/MacrosJornada/Edicao';
import { ValidacaoCnh } from './pages/ValidacaoCnh/List';
import { ValidacaoCnhCadastro } from './pages/ValidacaoCnh/Form';
import { Gerentes } from './pages/Gerentes/List';
import { GerentesCadastro } from './pages/Gerentes/Form';
import { ParametrosLogs } from './pages/ParametrosLogs';
import { PERMISSION } from './constants/Permissionamento';
import { PocTeste } from './pages/AAPOC-TESTE';

const RoutesSideBar: FC = () => {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;

  return (
    <div>
      <Switch>
        <PrivateRoute
          path="/home/:page?"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={Home}
        >
          <Home />
        </PrivateRoute>
        {/* ROTAS PESQUISA */}
        <PrivateRoute
          path="/enviar-pesquisa/:tabAtual/:id?"
          servicoContratado="cscNuCadastroConsulta"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.ANALISE_DE_PERFIL.ENVIAR_PESQUISA]}
          exact
        >
          <EnviarPesquisa
            canInsert={isAllowedByPermission(
              [TRANSPORTADOR.ANALISE_DE_PERFIL.ENVIAR_PESQUISA],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/historico-pesquisas"
          servicoContratado="cscNuCadastroConsulta"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.ANALISE_DE_PERFIL.HISTORICO_DE_PESQUISA]}
          exact
        >
          <HistoricoPesquisas />
        </PrivateRoute>
        <PrivateRoute path="/listar/sms-enviados/:page" allowedRoutes={['user']} exact>
          <SmsEnviados />
        </PrivateRoute>
        <PrivateRoute
          path="/detalhe-pesquisa"
          servicoContratado="cscNuCadastroConsulta"
          allowedRoutes={['user', 'admin']}
          permission={[
            TRANSPORTADOR.ANALISE_DE_PERFIL.HISTORICO_DE_PESQUISA,
            ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL,
          ]}
          exact
        >
          <DetalhePesquisa />
        </PrivateRoute>
        <PrivateRoute
          path="/progressao-viagens"
          allowedRoutes={['admin', 'user']}
          permission={[TRANSPORTADOR.CONTROLE_LOGISTICO.PROGRESSAO_DE_VIAGEM]}
          exact
        >
          <ProgressaoViagens />
        </PrivateRoute>
        <PrivateRoute
          path="/mapa"
          servicoContratado="cscNuMapaRastreamento"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CONTROLE_LOGISTICO.MAPA, EMBARCADOR.CONTROLE_LOGISTICO.MAPA]}
          exact
        >
          <Mapa />
        </PrivateRoute>
        <PrivateRoute
          path="/mapa-operacao"
          servicoContratado="cscNuMapaRastreamento"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CONTROLE_LOGISTICO.MAPA]}
        >
          <Mapa />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/ajudantes"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.AJUDANTE]}
          exact
        >
          <ConsultarAjudante />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/condutores"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CONDUTOR]}
          exact
        >
          <ConsultarCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/veiculos"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.VEICULO]}
          exact
        >
          <ConsultarVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/veiculos"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.VEICULO]}
          exact
        >
          <ListagemConsultaVeiculo
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.VEICULO], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/carretas"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CARRETA]}
          exact
        >
          <ConsultarCarreta />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/carretas"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CARRETA]}
          exact
        >
          <ListagemConsultaCarreta
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.CARRETA], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/ajudantes"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.AJUDANTE]}
          exact
        >
          <ListagemConsultaAjudante
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.AJUDANTE], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/condutores"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CONDUTOR]}
          exact
        >
          <ListagemConsultaCondutor
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.CONDUTOR], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/proprietarios"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.PROPRIETARIO]}
          exact
        >
          <ListagemConsultaProprietario
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.PROPRIETARIO], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/filtrar/proprietarios"
          permission={[ADMIN.CADASTRO.PROPRIETARIO]}
          allowedRoutes={['admin']}
          exact
        >
          <ConsultarProprietario />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-proprietario/:id"
          allowedRoutes={['admin', 'user']}
          permission={[TRANSPORTADOR.CADASTRO.PROPRIETARIO, ADMIN.CADASTRO.PROPRIETARIO]}
        >
          <EditarProprietario
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.PROPRIETARIO, ADMIN.CADASTRO.PROPRIETARIO],
              'editar'
            )}
            canDelete={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.PROPRIETARIO, ADMIN.CADASTRO.PROPRIETARIO],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-proprietario"
          allowedRoutes={['admin', 'user']}
          permission={[TRANSPORTADOR.CADASTRO.PROPRIETARIO, ADMIN.CADASTRO.PROPRIETARIO]}
          exact
        >
          <EditarProprietario />
        </PrivateRoute>
        {/* ROTAS USER */}
        <PrivateRoute
          path="/cliente/listagem/proprietario/:page?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.PROPRIETARIO]}
        >
          <ListagemProprietario
            canInsert={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.PROPRIETARIO, ADMIN.CADASTRO.PROPRIETARIO],
              'inserir'
            )}
          />
        </PrivateRoute>
        {/* ROTA CLIENTE FATURAMENTO/FROTA */}
        <PrivateRoute
          path="/cliente/FaturamentoFrota/:page?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.FATURAMENTO_FROTA]}
          exact
        >
          <FaturamentoFrotaPage
            canEdit={isAllowedByPermission([TRANSPORTADOR.FATURAMENTO_FROTA], 'editar')}
            canInsert={isAllowedByPermission([TRANSPORTADOR.FATURAMENTO_FROTA], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-condutor/:fromSMCadastro?"
          component={CadastroCondutor}
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.CONDUTOR]}
        >
          <CadastroCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/condutores/listar/:page?"
          component={ListagemCondutor}
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.CONDUTOR]}
        >
          <ListagemCondutor
            canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.CONDUTOR], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/condutores/editar/:id"
          component={AtualizarCondutor}
          allowedRoutes={['user', 'admin']}
          permission={[ADMIN.CADASTRO.CONDUTOR, TRANSPORTADOR.CADASTRO.CONDUTOR]}
        >
          <AtualizarCondutor
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.CONDUTOR, ADMIN.CADASTRO.CONDUTOR],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ajudantes/listar/:page"
          component={Ajudantes}
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.AJUDANTE]}
        >
          <Ajudantes
            canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.AJUDANTE], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ajudantes/editar/:id"
          allowedRoutes={['user', 'admin']}
          permission={[TRANSPORTADOR.CADASTRO.AJUDANTE, ADMIN.CADASTRO.AJUDANTE]}
        >
          <AjudanteEditar
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.AJUDANTE, ADMIN.CADASTRO.AJUDANTE],
              'editar'
            )}
            canDelete={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.AJUDANTE, ADMIN.CADASTRO.AJUDANTE],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ajudantes/cadastrar"
          allowedRoutes={['user', 'admin']}
          permission={[TRANSPORTADOR.CADASTRO.AJUDANTE, ADMIN.CADASTRO.AJUDANTE]}
        >
          <AjudanteCadastro />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro/veiculo"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.VEICULO]}
          exact
        >
          <Veiculo canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.VEICULO], 'inserir')} />
        </PrivateRoute>
        <PrivateRoute
          path="/controle-velocidade-por-veiculo"
          servicoContratado="controleVelocidade"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_VELOCIDADE]}
          exact
        >
          <VelocidadePorVeiculo
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_VELOCIDADE],
              'editar'
            )}
            canInsert={isAllowedByPermission(
              [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_VELOCIDADE],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/controle-temperatura-por-veiculo"
          servicoContratado="controleTemperatura"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_TEMPERATURA]}
          exact
        >
          <TemperaturaPorVeiculo
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_TEMPERATURA],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/atualizarveiculo/:id"
          allowedRoutes={['user', 'admin']}
          permission={[ADMIN.CADASTRO.VEICULO, TRANSPORTADOR.CADASTRO.VEICULO]}
        >
          <CadastroVeiculo
            canEdit={isAllowedByPermission(
              [ADMIN.CADASTRO.VEICULO, TRANSPORTADOR.CADASTRO.VEICULO],
              'editar'
            )}
            canDelete={isAllowedByPermission(
              [ADMIN.CADASTRO.VEICULO, TRANSPORTADOR.CADASTRO.VEICULO],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/veicadastro/:fromSMCadastro?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.VEICULO]}
        >
          <CadastroVeiculo
            canEdit={isAllowedByPermission([TRANSPORTADOR.CADASTRO.VEICULO], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/veidetalhes/:id"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.VEICULO]}
        >
          <VeiDetalhes />
        </PrivateRoute>
        {/* <PrivateRoute path="/cliente/cadastro/carreta" allowedRoutes={['user']} exact>
          <CadCarreta />
        </PrivateRoute>
        <PrivateRoute path="/cliente/cadastro/cadastrarCarreta" allowedRoutes={['user']} exact>
          <CadastrarCarreta />
        </PrivateRoute> */}
        <PrivateRoute
          path="/bau-intercambiavel/:page?"
          allowedRoutes={['user']}
          component={BauIntercambiavel}
          permission={[TRANSPORTADOR.CADASTRO.BAU]}
        >
          <BauIntercambiavel
            canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.BAU], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/bau-intercambiavel-add/:fromSMCadastro?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.BAU]}
        >
          <CadastroBau canEdit={isAllowedByPermission([TRANSPORTADOR.CADASTRO.BAU], 'editar')} />
        </PrivateRoute>
        <PrivateRoute
          path="/editar/bau-intercambiavel/:id"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.BAU]}
        >
          <BinEdit
            canEdit={isAllowedByPermission([TRANSPORTADOR.CADASTRO.BAU], 'editar')}
            canDelete={isAllowedByPermission([TRANSPORTADOR.CADASTRO.BAU], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-sazonalidade-da-carga/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA]}
        >
          <ListagemSazonalidadeCarga
            canInsert={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-sazonalidade-da-carga"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA]}
          exact
        >
          <FormSazonalidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-sazonalidade-da-carga/:id"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA]}
        >
          <FormSazonalidadeCarga
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA], 'editar')}
            canDelete={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/capacidade-de-carga/listar/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA]}
        >
          <ListagemCapacidadeCarga
            canInsert={isAllowedByPermission([EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/capacidade-de-carga/cadastrar"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA]}
          exact
        >
          <FormCapacidadeCarga />
        </PrivateRoute>
        <PrivateRoute
          path="/capacidade-de-carga/editar/:id"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA]}
        >
          <FormCapacidadeCarga
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA], 'editar')}
            canDelete={isAllowedByPermission([EMBARCADOR.CADASTRO.CAPACIDADE_DE_CARGA], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/ajudante-funcionario/listar/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE]}
        >
          <ListagemRankingAjudanteFuncionario
            canInsert={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/ajudante-funcionario/cadastrar"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE]}
          exact
        >
          <FormRankingAjudanteFuncionario />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/ajudante-funcionario/editar/:id"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE]}
        >
          <FormRankingAjudanteFuncionario
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE], 'editar')}
            canDelete={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.RANKING_FAIXA.AJUDANTE],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/condutor/listar/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR]}
        >
          <ListagemRankingCondutor
            canInsert={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/condutor/cadastrar/"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR]}
          exact
        >
          <FormRankingCondutor />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/condutor/editar/:id"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR]}
        >
          <FormRankingCondutor
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR], 'editar')}
            canDelete={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/veiculo/listar/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO]}
        >
          <ListagemRankingVeiculo
            canInsert={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/veiculo/cadastrar/"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO]}
          exact
        >
          <FormRankingVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/ranking/veiculo/editar/:id"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO]}
        >
          <FormRankingVeiculo
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO], 'editar')}
            canDelete={isAllowedByPermission(
              [EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/analistas-de-perfil/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.ANALISTA_PERFIL]}
        >
          <ListagemAnalistaPerfil
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.ANALISTA_PERFIL], 'inserir')}
          />
        </PrivateRoute>

        <PrivateRoute
          path="/analistas-de-perfil/cadastrar/"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.ANALISTA_PERFIL]}
          exact
        >
          <FormAnalistaPerfil />
        </PrivateRoute>

        <PrivateRoute
          path="/analistas-de-perfil/editar/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.ANALISTA_PERFIL]}
        >
          <FormAnalistaPerfil
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.ANALISTA_PERFIL], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.ANALISTA_PERFIL], 'excluir')}
          />
        </PrivateRoute>
        {/* <PrivateRoute
          path="/cliente/ranking/ajudantefuncionarionmotor"
          allowedRoutes={['user']}
          exact
        >
          <CadAjudanteFuncNMotor />
        </PrivateRoute> */}
        <PrivateRoute
          path="/rota/:page?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.ROTA]}
        >
          <Rotas canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'inserir')} />
        </PrivateRoute>

        <PrivateRoute
          path="/consultar-sm-lancadas"
          allowedRoutes={['user', 'admin', 'corretora', 'seguradora']}
          permission={[
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
            EMBARCADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADAS,
            ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
          ]}
          exact
        >
          <ConsultarSMLancadas />
        </PrivateRoute>

        <PrivateRoute
          path="/listagem-sm-lancadas/:page?"
          allowedRoutes={['user', 'admin', 'corretora', 'seguradora']}
          permission={[
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
            EMBARCADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADAS,
            ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
          ]}
        >
          <ListagemSMLancadas />
        </PrivateRoute>

        <PrivateRoute
          path="/rota-editar-cadastrar/:id"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.ROTA]}
        >
          <EditarRotas
            canEdit={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'editar')}
            canDelete={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'excluir')}
          />
        </PrivateRoute>

        <PrivateRoute
          path="/rota-editar-cadastrar"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.ROTA]}
          exact
        >
          <EditarRotas />
        </PrivateRoute>

        <PrivateRoute
          path="/rotaeditarparada/:id"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.ROTA]}
        >
          <Paradas canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'inserir')} />
        </PrivateRoute>

        <PrivateRoute
          path="/rotacadastro/:fromSMCadastro?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.ROTA]}
        >
          <CadastrarRota
            canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'inserir')}
          />
        </PrivateRoute>

        <PrivateRoute
          path="/paradacadastro/:id"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.ROTA]}
        >
          <CadastroParada
            canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'inserir')}
          />
        </PrivateRoute>

        <PrivateRoute
          path="/paradaeditar/:id"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.ROTA]}
        >
          <EditarParada canEdit={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'editar')} />
        </PrivateRoute>
        <PrivateRoute
          path="/sm/:type/:id"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          permission={[
            ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
            ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
          ]}
        >
          <IniciarSMPendente
            canEdit={isAllowedByPermission(
              [
                ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
                ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
                TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
              ],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/finalizar-sm-emandamento/:id"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          permission={[
            ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
            ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
          ]}
        >
          <FinalizarSMEmAndamento
            canEdit={isAllowedByPermission(
              [
                ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
                ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
                TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
              ],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-sm-pendente/:id"
          allowedRoutes={['user', 'admin']}
          permission={[
            ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
            ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
          ]}
        >
          <EditarSMPendente
            canEdit={isAllowedByPermission(
              [
                ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
                ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
                TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
              ],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-sm-emandamento/:id?"
          allowedRoutes={['user', 'admin']}
          permission={[
            ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
            ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
          ]}
        >
          <EditarSMEmAndamento
            canEdit={isAllowedByPermission(
              [
                ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
                ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
                TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
              ],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/visualizar-sm-cancelada/:id?"
          allowedRoutes={['user', 'admin']}
          permission={[
            ADMIN.OPERACIONAL.PAINEL_OPERACIONAL,
            ADMIN.CONSULTA_RELATORIO.SM_LANCADA,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA,
          ]}
        >
          <VisualizarSMCancelada />
        </PrivateRoute>
        <PrivateRoute
          path="/solicitacao-monitoramento/:id?"
          servicoContratado="cscNuMonitoramentoVeiculos"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.OPERACIONAL.SOLICITACAO.SOLICITACAO_MONITORAMENTO]}
        >
          <SolicitacaoMonitoramentoCadastro
            canInsert={isAllowedByPermission(
              [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.SOLICITACAO_MONITORAMENTO],
              'inserir'
            )}
            canInsertVeiculo={isAllowedByPermission([TRANSPORTADOR.CADASTRO.VEICULO], 'inserir')}
            canInsertBau={isAllowedByPermission([TRANSPORTADOR.CADASTRO.BAU], 'inserir')}
            canInsertCondutor={isAllowedByPermission([TRANSPORTADOR.CADASTRO.CONDUTOR], 'inserir')}
            canInsertAjudante={isAllowedByPermission([TRANSPORTADOR.CADASTRO.AJUDANTE], 'inserir')}
            canInsertRota={isAllowedByPermission([TRANSPORTADOR.CADASTRO.ROTA], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cancelamento-monitoramento/:page?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.OPERACIONAL.SOLICITACAO.CANCELAMENTO_MONITORAMENTO]}
        >
          <CancelamentoMonitoramento
            canDelete={isAllowedByPermission(
              [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.CANCELAMENTO_MONITORAMENTO],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/confirmacao-presm/:page?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.OPERACIONAL.SOLICITACAO.CONFIRMACAO_PRE_SM]}
        >
          <ConfirmaPreSM
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.CONFIRMACAO_PRE_SM],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/presm/agendar-viagens"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.OPERACIONAL.PRE_SM]}
          exact
        >
          <AgendarViagens />
        </PrivateRoute>
        <PrivateRoute
          path="/importar-pre-sm"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.OPERACIONAL.IMPORTAR_CSV]}
          exact
        >
          <ImportarPreSm />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/painel-operacional/:tab/:page"
          allowedRoutes={['user', 'admin']}
          permission={[ADMIN.OPERACIONAL.PAINEL_OPERACIONAL]}
          exact
        >
          <PainelOperacional
            canInsertNaoConformidade={isAllowedByPermission(
              [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL],
              'inserir'
            )}
            canEditNaoConformidade={isAllowedByPermission(
              [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL],
              'editar'
            )}
            canEditSat={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA],
              'editar'
            )}
            canInsertSat={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA],
              'inserir'
            )}
            canDelete={isAllowedByPermission([ADMIN.OPERACIONAL.PAINEL_OPERACIONAL], 'excluir')}
          />
        </PrivateRoute>

        <PrivateRoute
          path="/cliente/operacional/cadastrosat"
          allowedRoutes={['admin']}
          permission={[
            ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA,
            ADMIN.CONSULTA_RELATORIO.RESUMO_SAT,
          ]}
          exact
        >
          <CadastroSatFromMenu />
        </PrivateRoute>
        {/* FOI  */}
        <PrivateRoute
          path="/cliente/operacional/visualizarsat/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA]}
        >
          <VisualizarSat />
        </PrivateRoute>

        <PrivateRoute
          path="/checklist/filtro-checklist"
          allowedRoutes={['admin', 'user']}
          permission={[
            ADMIN.CONSULTA_RELATORIO.CHECKLIST,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.CHECKLIST,
          ]}
          exact
        >
          <FiltroChecklist />
        </PrivateRoute>

        <PrivateRoute
          path="/checklist/resumo-checklist"
          allowedRoutes={['admin', 'user']}
          permission={[
            ADMIN.CONSULTA_RELATORIO.CHECKLIST,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.CHECKLIST,
          ]}
          exact
        >
          <ResumoChecklist />
        </PrivateRoute>

        <PrivateRoute
          path="/clientes/listar/:page?"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CLIENTE]}
        >
          <ClienteGrid canInsert={isAllowedByPermission([ADMIN.CADASTRO.CLIENTE], 'inserir')} />
        </PrivateRoute>

        <PrivateRoute
          path="/cadastrar-clientes/:activeKey"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CLIENTE]}
          exact
        >
          <ClienteCadastro
            canInsertCorretora={isAllowedByPermission([ADMIN.USUARIO.CORRETORA], 'inserir')}
            canInsertSeguradora={isAllowedByPermission([ADMIN.USUARIO.SEGURADORA], 'inserir')}
          />
        </PrivateRoute>

        <PrivateRoute
          path="/editar-cliente/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CLIENTE]}
        >
          <ClienteCadastro
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.CLIENTE], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.CLIENTE], 'excluir')}
            canInsertCorretora={isAllowedByPermission([ADMIN.USUARIO.CORRETORA], 'inserir')}
            canInsertSeguradora={isAllowedByPermission([ADMIN.USUARIO.SEGURADORA], 'inserir')}
          />
        </PrivateRoute>
        {/* Rota Reciclagem */}
        <PrivateRoute
          path="/cliente/operacional/Solicitacoes/Reciclagem"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.OPERACIONAL.RECICLAGEM]}
          exact
        >
          <Reciclagem
            canInsert={isAllowedByPermission([EMBARCADOR.OPERACIONAL.RECICLAGEM], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/listar-reciclagem/:page?"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.OPERACIONAL.RECICLAGEM]}
        >
          <GridReciclagem />
        </PrivateRoute>
        {/* VERIFICAR TROCA DE MENU */}
        <PrivateRoute
          path="/listar/avisos-operacionais"
          allowedRoutes={['admin']}
          component={ListagemAvisos}
          permission={[ADMIN.AVISO.OPERACIONAL]}
          exact
        >
          <ListagemAvisos
            canInsert={isAllowedByPermission([ADMIN.AVISO.OPERACIONAL], 'inserir')}
            canEdit={isAllowedByPermission([ADMIN.AVISO.OPERACIONAL], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/operacionais/novo"
          allowedRoutes={['admin']}
          component={CadastroAvisos}
          permission={[ADMIN.AVISO.OPERACIONAL]}
          exact
        >
          <CadastroAvisos />
        </PrivateRoute>
        <PrivateRoute
          path="/operacionais/atualizar/:id"
          allowedRoutes={['admin']}
          component={AtualizacaoAvisos}
          permission={[ADMIN.AVISO.OPERACIONAL]}
        >
          <AtualizacaoAvisos
            canEdit={isAllowedByPermission([ADMIN.AVISO.OPERACIONAL], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.AVISO.OPERACIONAL], 'excluir')}
          />
        </PrivateRoute>
        {/* AVISOS CLIENTES */}
        <PrivateRoute
          path="/avisos/clientes/atualizar/:id"
          allowedRoutes={['admin']}
          component={AtualizarAvisosCliente}
          permission={[ADMIN.AVISO.CLIENTE]}
        >
          <AtualizarAvisosCliente
            canEdit={isAllowedByPermission([ADMIN.AVISO.CLIENTE], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.AVISO.CLIENTE], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/avisos/clientes/cadastro"
          allowedRoutes={['admin']}
          component={CadastroAvisosCliente}
          permission={[ADMIN.AVISO.CLIENTE]}
        >
          <CadastroAvisosCliente />
        </PrivateRoute>
        <PrivateRoute path="/poc-teste" allowedRoutes={['user', 'admin']} component={PocTeste}>
          <PocTeste />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/avisos-clientes"
          allowedRoutes={['admin']}
          component={ListagemAvisosCliente}
          permission={[ADMIN.AVISO.CLIENTE]}
        >
          <ListagemAvisosCliente
            canInsert={isAllowedByPermission([ADMIN.AVISO.CLIENTE], 'inserir')}
            canEdit={isAllowedByPermission([ADMIN.AVISO.CLIENTE], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute path="/notificacao" allowedRoutes={['user']} component={Notificacao}>
          <Notificacao />
        </PrivateRoute>
        <PrivateRoute
          path="/carregando-permissoes"
          allowedRoutes={['user', 'admin', 'corretora', 'seguradora']}
          component={CarregandoPermissoes}
        >
          <CarregandoPermissoes />
        </PrivateRoute>
        {/* */}
        {/* Rotas Portaria */}
        <PrivateRoute
          path="/portaria"
          allowedRoutes={['user']}
          component={GridPortaria}
          permission={[EMBARCADOR.PORTARIA]}
        >
          <GridPortaria />
        </PrivateRoute>
        {/* */}
        {/* Rotas Expedição */}
        <PrivateRoute
          path="/embarcador/checklist"
          allowedRoutes={['user']}
          component={ListagemChecklistEmbarcador}
          permission={[EMBARCADOR.CHECKLIST]}
        >
          <ListagemChecklistEmbarcador />
        </PrivateRoute>
        {/* */}
        {/* Rotas Seguradoras */}
        <PrivateRoute
          path="/listar-usuarios-seguradoras/:page?"
          allowedRoutes={['seguradora', 'admin']}
          component={ListagemUsuariosSeguradoras}
          permission={[ADMIN.USUARIO.SEGURADORA]}
        >
          <ListagemUsuariosSeguradoras
            canInsert={isAllowedByPermission([ADMIN.USUARIO.SEGURADORA], 'inserir')}
            canEdit={isAllowedByPermission([ADMIN.USUARIO.SEGURADORA], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-usuario-seguradoras/:id"
          allowedRoutes={['seguradora', 'admin']}
          component={CadastroUsuariosSeguradoras}
          permission={[ADMIN.USUARIO.SEGURADORA]}
        >
          <CadastroUsuariosSeguradoras />
        </PrivateRoute>
        <PrivateRoute
          path="/atualizacao-usuario-seguradoras/:idUsu/:idSeguradora/:loginAntigo"
          allowedRoutes={['seguradora', 'admin']}
          component={AtualizarUsuariosSeguradoras}
          permission={[ADMIN.USUARIO.SEGURADORA]}
        >
          <AtualizarUsuariosSeguradoras
            canEdit={isAllowedByPermission([ADMIN.USUARIO.SEGURADORA], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.USUARIO.SEGURADORA], 'excluir')}
          />
        </PrivateRoute>
        {/* */}
        {/* Rotas Corretoras */}
        <PrivateRoute
          path="/listar-usuarios-corretoras/:page?"
          allowedRoutes={['corretora', 'admin']}
          component={ListagemUsuariosCorretoras}
          permission={[ADMIN.USUARIO.CORRETORA]}
        >
          <ListagemUsuariosCorretoras
            canInsert={isAllowedByPermission([ADMIN.USUARIO.CORRETORA], 'inserir')}
            canEdit={isAllowedByPermission([ADMIN.USUARIO.CORRETORA], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-usuario-corretoras/:idCorretora"
          allowedRoutes={['corretora', 'admin']}
          component={CadastroUsuariosCorretoras}
          permission={[ADMIN.USUARIO.CORRETORA]}
        >
          <CadastroUsuariosCorretoras />
        </PrivateRoute>
        <PrivateRoute
          path="/atualizacao-usuario-corretoras/:idUsu/:idCorretora/:loginAntigo"
          allowedRoutes={['corretora', 'admin']}
          component={AtualizarUsuariosCorretoras}
          permission={[ADMIN.USUARIO.CORRETORA]}
        >
          <AtualizarUsuariosCorretoras
            canEdit={isAllowedByPermission([ADMIN.USUARIO.CORRETORA], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.USUARIO.CORRETORA], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/macros-jornada/:page"
          allowedRoutes={['user']}
          component={MacrosJornada}
        >
          <MacrosJornada />
        </PrivateRoute>
        <PrivateRoute
          path="/editar/macros-jornada/:id"
          allowedRoutes={['user']}
          component={MacrosJornadaEditar}
        >
          <MacrosJornadaEditar />
        </PrivateRoute>
        <PrivateRoute
          path="/filtrar/macros-jornada/:page"
          allowedRoutes={['user']}
          component={MacrosJornada}
        >
          <FiltroJornada />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/motivos/:page"
          allowedRoutes={['admin']}
          component={Motivos}
          permission={[ADMIN.CADASTRO.MOTIVO]}
        >
          <Motivos
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.MOTIVO], 'inserir')}
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.MOTIVO], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.MOTIVO], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/gerentes/:page"
          allowedRoutes={['admin']}
          component={Gerentes}
          permission={[ADMIN.CADASTRO.GERENTE]}
        >
          <Gerentes
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.GERENTE], 'inserir')}
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.GERENTE], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.GERENTE], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/cadastrar/gerentes"
          allowedRoutes={['admin']}
          component={GerentesCadastro}
          permission={[ADMIN.CADASTRO.GERENTE]}
        >
          <GerentesCadastro />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/editar/gerentes/:id"
          allowedRoutes={['admin']}
          component={GerentesCadastro}
          permission={[ADMIN.CADASTRO.GERENTE]}
        >
          <GerentesCadastro />
        </PrivateRoute>
        <PrivateRoute
          path="/prevencao-de-acidentes"
          allowedRoutes={['user']}
          component={PrevencaoAcidentes}
          permission={[
            TRANSPORTADOR.CONTROLE_LOGISTICO.PREVENCAO_DE_ACIDENTE,
            EMBARCADOR.CONTROLE_LOGISTICO.PREVENCAO_ACIDENTE,
          ]}
        >
          <PrevencaoAcidentes />
        </PrivateRoute>
        {/* Lançamento de não conformidade */}
        <PrivateRoute
          path="/lancar-nao-conformidade/:id/:idCliente/:empresa"
          allowedRoutes={['admin']}
          component={LancamentoNCTransportador}
        >
          <LancamentoNCTransportador />
        </PrivateRoute>
        <PrivateRoute path="/paginateste" allowedRoutes={['admin', 'user']} component={PaginaTeste}>
          <PaginaTeste />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/nao-conformidades/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.NAO_CONFORMIDADE]}
        >
          <ListagemNaoConformidades
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.NAO_CONFORMIDADE], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-nao-conformidade"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.NAO_CONFORMIDADE]}
        >
          <FormNaoConformidade />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-nao-conformidade/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.NAO_CONFORMIDADE]}
        >
          <FormNaoConformidade
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.NAO_CONFORMIDADE], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.NAO_CONFORMIDADE], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute path="/sm/:id?" allowedRoutes={['admin', 'user']} exact>
          <Sm />
        </PrivateRoute>
        {/* */}
        {/* Lançamento de não conformidade */}
        <PrivateRoute
          path="/nao-conformidade/consulta"
          exact
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={FormularioConsultaNaoConformidade}
          permission={[
            ADMIN.CONSULTA_RELATORIO.NAO_CONFORMIDADE,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.NAO_CONFORMIDADE,
          ]}
        >
          <FormularioConsultaNaoConformidade />
        </PrivateRoute>
        <PrivateRoute
          path="/nao-conformidade/consulta/grid"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={GridConsultaNaoConformidades}
          permission={[
            ADMIN.CONSULTA_RELATORIO.NAO_CONFORMIDADE,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.NAO_CONFORMIDADE,
          ]}
        >
          <GridConsultaNaoConformidades
            canDelete={isAllowedByPermission(
              [
                ADMIN.CONSULTA_RELATORIO.NAO_CONFORMIDADE,
                TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.NAO_CONFORMIDADE,
              ],
              'excluir'
            )}
          />
        </PrivateRoute>
        {/* */}
        {/* Logout */}
        <PrivateRoute
          path="/logout"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={LogOut}
        >
          <LogOut />
        </PrivateRoute>
        <PrivateRoute
          path="/erroservico"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={NaoPossuiServico}
        >
          <NaoPossuiServico />
        </PrivateRoute>
        <PrivateRoute
          path="/acessonegado"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          component={NaoPossuiServico}
        >
          <AcessoNegado />
        </PrivateRoute>
        {/* */}
        {/* Tempo Parado */}
        <PrivateRoute
          path="/rastreamento/tempo-parado"
          allowedRoutes={['admin', 'user']}
          component={TempoParado}
          permission={[
            ADMIN.CONSULTA_RELATORIO.TEMPO_PARADO,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RASTREAMENTO.TEMPO_PARADO,
          ]}
        >
          <TempoParado />
        </PrivateRoute>
        {/* */}
        {/* Análise Perfil */}
        <PrivateRoute
          path="/analise-perfil/painel/:page"
          allowedRoutes={['admin']}
          component={AnalisePerfilGrid}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL]}
        >
          <AnalisePerfilGrid
            canEdit={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/editar"
          allowedRoutes={['admin']}
          component={EditarAnalisePerfil}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL]}
          exact
        >
          <EditarAnalisePerfil
            canEdit={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/pesquisa"
          allowedRoutes={['admin']}
          component={PesquisaCadastral}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL]}
          exact
        >
          <PesquisaCadastral />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/pesquisa/:id"
          allowedRoutes={['admin']}
          component={PesquisaCadastral}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL]}
        >
          <PesquisaCadastral
            canEdit={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/analise-perfil/grid"
          allowedRoutes={['admin']}
          component={GridPesquisaCadastral}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO]}
          exact
        >
          <GridPesquisaCadastral
            canDelete={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO],
              'excluir'
            )}
            canInsert={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO],
              'inserir'
            )}
            canEdit={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO],
              'editar'
            )}
          />
        </PrivateRoute>
        {/* */}
        {/* Tecnologias */}
        <PrivateRoute
          path="/listar/historico-tecnologias/:page"
          allowedRoutes={['admin']}
          component={AtualizacaoTecnologias}
          permission={[ADMIN.OPERACIONAL.TECNOLOGIA]}
        >
          <AtualizacaoTecnologias />
        </PrivateRoute>
        {/* Checklist */}
        <PrivateRoute
          path="/cliente/operacional/listar-checklist/:page?"
          allowedRoutes={['admin']}
          component={ListagemChecklist}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO]}
        >
          <ListagemChecklist
            canInsert={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/operacional/cadastro-checklist"
          allowedRoutes={['admin']}
          component={CadastroChecklist}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO]}
        >
          <CadastroChecklist
            canEdit={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO],
              'editar'
            )}
            canInsert={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cliente/operacional/atualizar-checklist/:id"
          allowedRoutes={['admin']}
          component={CadastroChecklist}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO]}
        >
          <CadastroChecklist />
        </PrivateRoute>
        {/* */}
        {/* Carretas */}
        <PrivateRoute
          path="/cadastro/carretas/:page?"
          allowedRoutes={['user']}
          component={GridCarretas}
          permission={[TRANSPORTADOR.CADASTRO.CARRETA]}
          exact
        >
          <GridCarretas
            canInsert={isAllowedByPermission([TRANSPORTADOR.CADASTRO.CARRETA], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro/carretas/cadastrar/:isCarreta?"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.CARRETA]}
        >
          <CadastroVeiculo
            canEdit={isAllowedByPermission([TRANSPORTADOR.CADASTRO.CARRETA], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro/carretas/atualizar/:isCarreta?/:id?"
          allowedRoutes={['user', 'admin']}
          permission={[TRANSPORTADOR.CADASTRO.CARRETA, ADMIN.CADASTRO.CARRETA]}
        >
          <CadastroVeiculo
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.CARRETA, ADMIN.CADASTRO.CARRETA],
              'editar'
            )}
            canDelete={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.CARRETA, ADMIN.CADASTRO.CARRETA],
              'excluir'
            )}
          />
        </PrivateRoute>
        {/* */}
        <Route path="/monitoramento/cadastro-sat/:id?/:register?">
          <CadastroSat />
        </Route>
        <Route path="/avisos" component={AvisosImportantes} exact>
          <AvisosImportantes />
        </Route>
        {/* VERIFICAR TROCA DE MENU */}
        {/* ROTAS ADMIN */}
        <PrivateRoute
          path="/associar-clientes/:user?/:page?"
          allowedRoutes={['admin']}
          permission={[ADMIN.USUARIO.ASSOCIAR_CLIENTE]}
        >
          <AssociarClientes
            canEdit={isAllowedByPermission([ADMIN.USUARIO.ASSOCIAR_CLIENTE], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-pontos"
          servicoContratado="cscNuRotaPorPontos"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.PONTO, EMBARCADOR.CADASTRO.PONTO]}
        >
          <CadastroPontos />
        </PrivateRoute>
        <PrivateRoute
          path="/pontos/:page?"
          servicoContratado="cscNuRotaPorPontos"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.PONTO, EMBARCADOR.CADASTRO.PONTO]}
        >
          <Pontos
            canInsert={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.PONTO, EMBARCADOR.CADASTRO.PONTO],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-pontos/:id"
          servicoContratado="cscNuRotaPorPontos"
          allowedRoutes={['user']}
          permission={[TRANSPORTADOR.CADASTRO.PONTO, EMBARCADOR.CADASTRO.PONTO]}
        >
          <EditarPontos
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.PONTO, EMBARCADOR.CADASTRO.PONTO],
              'editar'
            )}
            canDelete={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.PONTO, EMBARCADOR.CADASTRO.PONTO],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/pre-agendamento-viagens/:page?"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.OPERACIONAL.PRE_SM]}
        >
          <GridPreAgendamentoSM
            canInsert={isAllowedByPermission([EMBARCADOR.OPERACIONAL.PRE_SM], 'inserir')}
            canDelete={isAllowedByPermission([EMBARCADOR.OPERACIONAL.PRE_SM], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/tipos-de-comunicacao/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.TIPO_COMUNICACAO]}
        >
          <ConsultarTiposComunicacao
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.TIPO_COMUNICACAO], 'inserir')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.TIPO_COMUNICACAO], 'excluir')}
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.TIPO_COMUNICACAO], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/tecnologias-de-rastreamento/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.TECNOLOGIA]}
        >
          <ListagemTecnologiaRastreamento
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.TECNOLOGIA], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/editar-tecnologia-de-rastreamento/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.TECNOLOGIA]}
        >
          <FormTecnologiaRastreamento
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.TECNOLOGIA], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.TECNOLOGIA], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/cadastrar-tecnologia-de-rastreamento"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.TECNOLOGIA]}
          exact
        >
          <FormTecnologiaRastreamento />
        </PrivateRoute>

        <PrivateRoute
          path="/admin/listar/validacao-cnh/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.VALIDACAO_CNH]}
          exact
        >
          <ValidacaoCnh
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.VALIDACAO_CNH], 'inserir')}
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.VALIDACAO_CNH], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.VALIDACAO_CNH], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute path="/admin/cadastrar/validacao-cnh" allowedRoutes={['admin']} exact>
          <ValidacaoCnhCadastro />
        </PrivateRoute>

        <PrivateRoute path="/admin/editar/validacao-cnh/:id" allowedRoutes={['admin']} exact>
          <ValidacaoCnhCadastro />
        </PrivateRoute>

        <PrivateRoute
          path="/listar/dispositivos/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.DISPOSITIVO]}
        >
          <ListagemDispositivos
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.DISPOSITIVO], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-dispositivo/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.DISPOSITIVO]}
        >
          <FormDispositivo
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.DISPOSITIVO], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.DISPOSITIVO], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-dispositivo"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.DISPOSITIVO]}
        >
          <FormDispositivo />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/grupo-macro-de-veiculos"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.GRUPO_MACRO_VEICULO]}
        >
          <ListagemGrupoMacroVeiculos />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/grupos-de-veiculos/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.GRUPO_VEICULO]}
        >
          <ListagemGrupoVeiculos
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.GRUPO_VEICULO], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-grupos-de-veiculos"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.GRUPO_VEICULO]}
        >
          <FormGrupoVeiculo />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-grupos-de-veiculos/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.GRUPO_VEICULO]}
        >
          <FormGrupoVeiculo
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.GRUPO_VEICULO], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.GRUPO_VEICULO], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/marcas/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.MARCA]}
        >
          <ConsultarMarca
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.MARCA], 'inserir')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.MARCA], 'excluir')}
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.MARCA], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/cor/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.COR]}
        >
          <ConsultarCor
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.COR], 'inserir')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.COR], 'excluir')}
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.COR], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/rastreamento/:page?"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.RASTREAMENTO]}
        >
          <VeiculosRastreamento />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/cadastro-corretora"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CORRETORA]}
        >
          <CadastroCorretora />
        </PrivateRoute>
        <PrivateRoute
          path="/edicao-corretora/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CORRETORA]}
        >
          <CadastroCorretora
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.CORRETORA], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/corretoras"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.CORRETORA]}
        >
          <ConsultarCorretora
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.CORRETORA], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/cadastro-seguradora"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.SEGURADORA]}
        >
          <CadastroSeguradora />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/tipos-de-veiculo/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.TIPO_VEICULO]}
        >
          <ListagemTiposDeVeiculos
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.TIPO_VEICULO], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/tipo-de-veiculo/cadastrar"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.TIPO_VEICULO]}
        >
          <FormTiposDeVeiculos />
        </PrivateRoute>
        <PrivateRoute
          path="/tipo-de-veiculo/editar/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.TIPO_VEICULO]}
        >
          <FormTiposDeVeiculos
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.TIPO_VEICULO], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.TIPO_VEICULO], 'excluir')}
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.GRUPO_VEICULO], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/edicao-seguradora/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.SEGURADORA]}
        >
          <CadastroSeguradora
            canEdit={isAllowedByPermission([ADMIN.CADASTRO.SEGURADORA], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/seguradoras"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.SEGURADORA]}
        >
          <ConsultarSeguradora
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.SEGURADORA], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute path="/cadastro-usuario-gerenciador" allowedRoutes={['admin']} exact>
          <CadastroUsuarioGerenciador />
        </PrivateRoute>
        <PrivateRoute
          path="/consultar-pesquisas-lancadas/:request?/:page?"
          allowedRoutes={['admin']}
          permission={[ADMIN.CONSULTA_RELATORIO.ANALISE_PERFIL]}
        >
          <ConsultarPesquisasLancadas
            canEdit={isAllowedByPermission([ADMIN.CONSULTA_RELATORIO.ANALISE_PERFIL], 'editar')}
            canDelete={isAllowedByPermission([ADMIN.CONSULTA_RELATORIO.ANALISE_PERFIL], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-usuarios-gerenciadores/:page?"
          allowedRoutes={['admin']}
          permission={[ADMIN.USUARIO.GERENCIADOR]}
        >
          <UsuariosGerenciadores
            canInsert={isAllowedByPermission([ADMIN.USUARIO.GERENCIADOR], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-usuario-cliente/:id"
          allowedRoutes={['user', 'admin']}
          permission={[
            TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO,
            EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO,
            ADMIN.USUARIO.CLIENTE,
          ]}
        >
          <CadastroUsuarioCliente />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-usuarios-clientes/:page?"
          allowedRoutes={['user', 'admin']}
          permission={[
            TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO,
            ADMIN.USUARIO.CLIENTE,
            EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO,
          ]}
        >
          <UsuariosClientes
            canInsert={isAllowedByPermission(
              [
                TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO,
                ADMIN.USUARIO.CLIENTE,
                EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO,
              ],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-perfis-usuarios"
          allowedRoutes={['admin', 'user']}
          permission={[
            TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS,
            EMBARCADOR.USUARIO.PERFIL_DE_USUARIO,
            ADMIN.USUARIO.PERFIL_USUARIO,
          ]}
          exact
        >
          <CadastroPerfisUsuarios
            canEdit={isAllowedByPermission(
              [
                TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS,
                EMBARCADOR.USUARIO.PERFIL_DE_USUARIO,
                ADMIN.USUARIO.PERFIL_USUARIO,
              ],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/edicao-perfis-usuarios/:id"
          allowedRoutes={['admin', 'user']}
          permission={[
            TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS,
            EMBARCADOR.USUARIO.PERFIL_DE_USUARIO,
            ADMIN.USUARIO.PERFIL_USUARIO,
          ]}
        >
          <CadastroPerfisUsuarios
            canEdit={isAllowedByPermission(
              [
                TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS,
                EMBARCADOR.USUARIO.PERFIL_DE_USUARIO,
                ADMIN.USUARIO.PERFIL_USUARIO,
              ],
              'editar'
            )}
            canDelete={isAllowedByPermission(
              [
                TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS,
                EMBARCADOR.USUARIO.PERFIL_DE_USUARIO,
                ADMIN.USUARIO.PERFIL_USUARIO,
              ],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/perfis-usuarios/listar/:page"
          allowedRoutes={['admin', 'user', 'corretora', 'seguradora']}
          permission={[
            TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS,
            EMBARCADOR.USUARIO.PERFIL_DE_USUARIO,
            ADMIN.USUARIO.PERFIL_USUARIO,
          ]}
        >
          <ConsultarPerfisUsuarios
            canInsert={isAllowedByPermission(
              [
                TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS,
                EMBARCADOR.USUARIO.PERFIL_DE_USUARIO,
                ADMIN.USUARIO.PERFIL_USUARIO,
              ],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-usuario-gerenciador/:id"
          allowedRoutes={['admin']}
          permission={[ADMIN.USUARIO.GERENCIADOR]}
        >
          <EditarUsuarioGerenciador
            canDelete={isAllowedByPermission([ADMIN.USUARIO.GERENCIADOR], 'excluir')}
            canEdit={isAllowedByPermission([ADMIN.USUARIO.GERENCIADOR], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-usuario-cliente/:id/:cliente"
          allowedRoutes={['user', 'admin']}
          permission={[
            TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO,
            ADMIN.USUARIO.CLIENTE,
            EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO,
          ]}
          exact
        >
          <EditarUsuarioCliente
            canDelete={isAllowedByPermission(
              [
                TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO,
                ADMIN.USUARIO.CLIENTE,
                EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO,
              ],
              'excluir'
            )}
            canEdit={isAllowedByPermission(
              [
                TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO,
                ADMIN.USUARIO.CLIENTE,
                EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO,
              ],
              'editar'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-naoconformidades/:page?"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.NAO_CONFORMIDADE]}
        >
          <NaoConformidades
            canInsert={isAllowedByPermission([EMBARCADOR.CADASTRO.NAO_CONFORMIDADE], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/listar-faixas-de-cubagem/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM]}
        >
          <ListagemFaixasCubagem
            canInsert={isAllowedByPermission([EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM], 'inserir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastrar-faixas-de-cubagem"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM]}
          exact
        >
          <FormFaixasCubagem />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-faixas-de-cubagem/:id"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM]}
        >
          <FormFaixasCubagem
            canDelete={isAllowedByPermission([EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM], 'excluir')}
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.FAIXA_DE_CUBAGEM], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-naoconformidades"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.NAO_CONFORMIDADE]}
          exact
        >
          <CadastroNaoConformidade />
        </PrivateRoute>
        <PrivateRoute
          path="/editar-naoconformidades/:id"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.NAO_CONFORMIDADE]}
        >
          <EditarNaoConformidade
            canDelete={isAllowedByPermission([EMBARCADOR.CADASTRO.NAO_CONFORMIDADE], 'excluir')}
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.NAO_CONFORMIDADE], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/blacklist-seguradora/:page?"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.BLACKLIST_SEGURADORA]}
        >
          <BlackListSeguradoras
            canInsert={isAllowedByPermission([ADMIN.CADASTRO.BLACKLIST_SEGURADORA], 'inserir')}
            canDelete={isAllowedByPermission([ADMIN.CADASTRO.BLACKLIST_SEGURADORA], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/cadastro-blacklistseguradora"
          allowedRoutes={['admin']}
          permission={[ADMIN.CADASTRO.BLACKLIST_SEGURADORA]}
          exact
        >
          <CadastroBlackListSeguradora />
        </PrivateRoute>
        <PrivateRoute
          path="/listar/faturamento-frota/:page?"
          allowedRoutes={['admin']}
          permission={[ADMIN.OPERACIONAL.FATURAMENTO_FROTA]}
        >
          <FaturamentoGerenciador
            canEdit={isAllowedByPermission([ADMIN.OPERACIONAL.FATURAMENTO_FROTA], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/painel-lsc/:page"
          allowedRoutes={['admin']}
          permission={[ADMIN.OPERACIONAL.PAINEL_LSC]}
        >
          <LSC canEdit={isAllowedByPermission([ADMIN.OPERACIONAL.PAINEL_LSC], 'editar')} />
        </PrivateRoute>
        <PrivateRoute
          path="/rastreamento/requisicao-api"
          allowedRoutes={['admin']}
          permission={[ADMIN.CONSULTA_RELATORIO.REQUISICAO_API]}
          exact
        >
          <RequisicaoAPI />
        </PrivateRoute>
        <PrivateRoute
          path="/rastreamento/historico-posicoes/:page"
          allowedRoutes={['admin', 'user']}
          permission={[
            ADMIN.CONSULTA_RELATORIO.HISTORICO_POSICAO,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RASTREAMENTO.HISTORICO_POSICAO,
          ]}
        >
          <HistoricoPosicoes />
        </PrivateRoute>
        <PrivateRoute
          path="/consultas-relatorios/acompanhamento-remessas/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.OPERACIONAL.CONSULTA_E_RELATORIO.ACOMPANHAMENTO_DE_REMESSA]}
          exact
        >
          <AcompanhamentoRemessa />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listar/embarcadores/:page?"
          allowedRoutes={['user', 'admin']}
          permission={[TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE, ADMIN.CADASTRO.EMBARCADOR]}
        >
          <EmbarcadorGrid
            canInsert={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE, ADMIN.CADASTRO.EMBARCADOR],
              'inserir'
            )}
            canEdit={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE, ADMIN.CADASTRO.EMBARCADOR],
              'editar'
            )}
            canDelete={isAllowedByPermission(
              [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE, ADMIN.CADASTRO.EMBARCADOR],
              'excluir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/listagem-sat/:page?"
          allowedRoutes={['admin']}
          component={GridSat}
          permission={[ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA]}
        >
          <GridSat
            canInsert={isAllowedByPermission(
              [ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA],
              'inserir'
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/consultar-sat"
          allowedRoutes={['admin', 'user']}
          permission={[
            ADMIN.CONSULTA_RELATORIO.RESUMO_SAT,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RESUMO_SAT,
          ]}
          exact
        >
          <ResumoSat />
        </PrivateRoute>
        <PrivateRoute
          path="/listagem-resumo-sat"
          allowedRoutes={['admin', 'user']}
          permission={[
            ADMIN.CONSULTA_RELATORIO.RESUMO_SAT,
            TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.RESUMO_SAT,
          ]}
          exact
        >
          <ListagemResumoSat />
        </PrivateRoute>
        <PrivateRoute
          path="/embarcador/add"
          allowedRoutes={['user', 'admin']}
          permission={[TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE, ADMIN.CADASTRO.EMBARCADOR]}
          exact
        >
          <EmbarcadorAdd />
        </PrivateRoute>
        <PrivateRoute
          path="/centro-de-distribuicao/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.CD]}
        >
          <ConsultarCD
            canInsert={isAllowedByPermission([EMBARCADOR.CADASTRO.CD], 'inserir')}
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.CD], 'editar')}
            canDelete={isAllowedByPermission([EMBARCADOR.CADASTRO.CD], 'excluir')}
          />
        </PrivateRoute>
        <PrivateRoute
          path="/regional/:page"
          allowedRoutes={['user']}
          permission={[EMBARCADOR.CADASTRO.REGIONAL]}
        >
          <ConsultarRegional
            canDelete={isAllowedByPermission([EMBARCADOR.CADASTRO.REGIONAL], 'excluir')}
            canInsert={isAllowedByPermission([EMBARCADOR.CADASTRO.REGIONAL], 'inserir')}
            canEdit={isAllowedByPermission([EMBARCADOR.CADASTRO.REGIONAL], 'editar')}
          />
        </PrivateRoute>

        {/* Rotas dos Parametros feita por Cicero */}
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/servicoSmpt"
          permission={[ADMIN.PARAMETRO.EMAIL]}
          exact
        >
          <ServicoSmpt canEdit={isAllowedByPermission([ADMIN.PARAMETRO.EMAIL], 'editar')} />
        </PrivateRoute>
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/sistema"
          permission={[PERMISSION.ADMIN.PARAMETRO.SISTEMA]}
          exact
        >
          <ParametrosSistema canEdit={isAllowedByPermission([ADMIN.PARAMETRO.SISTEMA], 'editar')} />
        </PrivateRoute>
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/integracao"
          permission={[ADMIN.PARAMETRO.INTEGRACAO]}
          exact
        >
          <ParametrosIntegracao
            canEdit={isAllowedByPermission([ADMIN.PARAMETRO.INTEGRACAO], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute
          allowedRoutes={['admin']}
          path="/parametros/automatizacao"
          permission={[ADMIN.PARAMETRO.AUTOMATIZACAO]}
          exact
        >
          <ParametrosAutomatizacao
            canEdit={isAllowedByPermission([ADMIN.PARAMETRO.AUTOMATIZACAO], 'editar')}
          />
        </PrivateRoute>
        <PrivateRoute allowedRoutes={['admin']} path="/parametros/jornada-de-trabalho" exact>
          <ParametrosJornada />
        </PrivateRoute>
        <PrivateRoute allowedRoutes={['admin']} path="/parametros/logs/webservice-sm/:page">
          <ParametrosLogs />
        </PrivateRoute>
        <PrivateRoute allowedRoutes={['admin']} path="/parametros/logs/webservice-pamcary/:page">
          <ParametrosLogs />
        </PrivateRoute>
        <PrivateRoute allowedRoutes={['admin']} path="/parametros/logs/integracao-dminer/:page">
          <ParametrosLogs />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default RoutesSideBar;
