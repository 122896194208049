/* eslint-disable */
import { useState, useEffect, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { Button as Button2 } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import axios from '../../config/axiosMaquina';
import {
  maskCpnj,
  formatReal,
  moneyToNumber,
  toCurrency,
  toDecimal,
} from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';

type EmbarcadorViewType = {
  eltCdId: number;
  embCdId: number;
  limiteCargaTransferencia: number;
  pisoEscoltaTransferencia: number;
  pisoIscaTransferencia: number;
  limiteCargaDistribuicao: number;
  pisoEscoltaDistribuicao: number;
  pisoIscaDistribuicao: number;
  limiteCargaColeta: number;
  pisoEscoltaColeta: number;
  pisoIscaColeta: number;
  limiteCargaExportacao: number;
  pisoEscoltaExportacao: number;
  pisoIscaEsportacao: number;
  limiteCargaDevolucao: number;
  pisoEscoltaDevolucao: number;
  pisoIscaDevolucao: number;
  limiteSegIscaTransferencia: number;
  pisoSegIscaTransferencia: number;
  pisoImobilizadorEletrIscaTransferencia: number;
  criticarMarcasIguaisTransferencia: boolean;
  limiteSegIscaDistribuicao: number;
  pisoSegIscaDistribuicao: number;
  pisoImobilizadorEletrIscaDistribuicao: number;
  criticarMarcasIguaisDistribuicao: boolean;
  limiteSegIscaColeta: number;
  pisoSegIscaColeta: number;
  pisoImobilizadorEletrIscaColeta: number;
  criticarMarcasIguaisColeta: boolean;
  limiteSegIscaExportacao: number;
  pisoSegIscaExportacao: number;
  pisoImobilizadorEletrIscaExportacao: number;
  criticarMarcasIguaisExportacao: boolean;
  limiteSegIscaDevolucao: number;
  pisoSegIscaDevolucao: number;
  pisoImobilizadorEletrIscaDevolucao: number;
  criticarMarcasIguaisDevolucao: boolean;
  limiteImobilizadorEletrTransferencia: number;
  limiteImobilizadorEletrDistribuicao: number;
  limiteImobilizadorEletrColeta: number;
  limiteImobilizadorEletrExportacao: number;
  limiteImobilizadorEletrDevolucao: number;
  limiteIscaTransferencia: number;
  limiteIscaDistribuicao: number;
  limiteIscaColeta: number;
  limiteIscaExportacao: number;
  limiteIscaDevolucao: number;
  limiteEscoltaTransferencia: number;
  limiteEscoltaDistribuicao: number;
  limiteEscoltaColeta: number;
  limiteEscoltaExportacao: number;
  limiteEscoltaDevolucao: number;
  cnpjEmbarcador: string;
  razaoSocialEmbarcador: string;
};

type ViewProps = {
  showEdit: boolean;
  setShowView: (bool: boolean) => void;
  id: any;
  canEdit?: boolean;
  canInsert?: boolean;
};

const EmbarcadorView: FC<ViewProps> = ({ id, showEdit, setShowView, canEdit, canInsert }) => {
  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  // const { id }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const [embarcadorSemCadastroDeValores, setEmbarcadorSemCadastroDeValores] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');

  const [model, setModel] = useState<EmbarcadorViewType>({
    eltCdId: 0,
    embCdId: 0,
    limiteCargaTransferencia: 0,
    pisoEscoltaTransferencia: 0,
    pisoIscaTransferencia: 0,
    limiteCargaDistribuicao: 0,
    pisoEscoltaDistribuicao: 0,
    pisoIscaDistribuicao: 0,
    limiteCargaColeta: 0,
    pisoEscoltaColeta: 0,
    pisoIscaColeta: 0,
    limiteCargaExportacao: 0,
    pisoEscoltaExportacao: 0,
    pisoIscaEsportacao: 0,
    limiteCargaDevolucao: 0,
    pisoEscoltaDevolucao: 0,
    pisoIscaDevolucao: 0,
    limiteSegIscaTransferencia: 0,
    pisoSegIscaTransferencia: 0,
    pisoImobilizadorEletrIscaTransferencia: 0,
    criticarMarcasIguaisTransferencia: false,
    limiteSegIscaDistribuicao: 0,
    pisoSegIscaDistribuicao: 0,
    pisoImobilizadorEletrIscaDistribuicao: 0,
    criticarMarcasIguaisDistribuicao: false,
    limiteSegIscaColeta: 0,
    pisoSegIscaColeta: 0,
    pisoImobilizadorEletrIscaColeta: 0,
    criticarMarcasIguaisColeta: false,
    limiteSegIscaExportacao: 0,
    pisoSegIscaExportacao: 0,
    pisoImobilizadorEletrIscaExportacao: 0,
    criticarMarcasIguaisExportacao: false,
    limiteSegIscaDevolucao: 0,
    pisoSegIscaDevolucao: 0,
    pisoImobilizadorEletrIscaDevolucao: 0,
    criticarMarcasIguaisDevolucao: false,
    limiteImobilizadorEletrTransferencia: 0,
    limiteImobilizadorEletrDistribuicao: 0,
    limiteImobilizadorEletrColeta: 0,
    limiteImobilizadorEletrExportacao: 0,
    limiteImobilizadorEletrDevolucao: 0,
    limiteIscaTransferencia: 0,
    limiteIscaDistribuicao: 0,
    limiteIscaColeta: 0,
    limiteIscaExportacao: 0,
    limiteIscaDevolucao: 0,
    limiteEscoltaTransferencia: 0,
    limiteEscoltaDistribuicao: 0,
    limiteEscoltaColeta: 0,
    limiteEscoltaExportacao: 0,
    limiteEscoltaDevolucao: 0,
    cnpjEmbarcador: '',
    razaoSocialEmbarcador: '',
  });

  useEffect(() => {
    async function loadData() {
      console.log(id);
      try {
        const response = await axios.get(
          `/embarcador/listarDadosEmbarcadorParaSM?embarcador=${id}`
        );
        console.log(response.data);
        setCnpj(response.data.cnpj);
        setRazaoSocial(response.data.razaoSocial);
      } catch (err: any) {
        console.log(err.response);
      }
    }
    loadData();
  }, [id]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await axios.get(`/embarcador-limite-transporte/${id}`);
        console.log(response.data);
        setModel(response.data);
        console.log('model: ', model);
        setEmbarcadorSemCadastroDeValores(false);
      } catch (err: any) {
        console.log(err.response);
        if (err.response.status === 424) {
          setEmbarcadorSemCadastroDeValores(true);
        } else {
          setEmbarcadorSemCadastroDeValores(false);
        }
      }
    }
    loadData();
  }, [id]); // GET Embarcador

  function onSubmit(e: any) {
    e.preventDefault();
    console.log('entrou submit');
    console.log(embarcadorSemCadastroDeValores);
    const data = {
      ...model,
      eltCdId: embarcadorSemCadastroDeValores === true ? 0 : model.eltCdId,
      embCdId: id,
      cnpjEmbarcador: cnpj,
      razaoSocialEmbarcador: razaoSocial,
    };

    if (embarcadorSemCadastroDeValores === true) {
      try {
        const response = axios.post('/embarcador-limite-transporte/salvar', data);
        console.log(JSON.stringify(data));
        console.log(response);
        setIsDialogCadOK(true);
      } catch (err: any) {
        console.log(err.response);
      }
    } else {
      try {
        const response = axios.put(
          `/embarcador-limite-transporte/atualizar/${model.eltCdId}`,
          data
        );
        console.log(JSON.stringify(data));
        console.log(response);
        setIsDialogCadOK(true);
      } catch (err: any) {
        console.log(err.response);
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      }
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header={
          embarcadorSemCadastroDeValores
            ? 'Limites de Transporte cadastrado com sucesso!'
            : 'Limites de Transporte alterados com sucesso!'
        }
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setShowView(false);
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Limites de Transporte</h2>
          {isAllowedByRole(['user']) && (
            <h6 className="subtitulo">{'Cadastro > Regras de Apólice'}</h6>
          )}
          {isAllowedByRole(['admin']) && (
            <h6 className="subtitulo">
              {'Cadastro > Gerenciamento de Clientes > Embarcador (Regras de Apólice)'}
            </h6>
          )}
        </div>

        <Form
          className="form-responsive"
          onSubmit={(e) => onSubmit(e)}
          noValidate
          validated={isFormInvalid}
        >
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCnpj">
              <Form.Label>CNPJ Nº</Form.Label>
              <Form.Control
                type="text"
                name="cnpj"
                placeholder=""
                disabled
                value={model.cnpjEmbarcador ? maskCpnj(model.cnpjEmbarcador) : maskCpnj(cnpj)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRazaoSocial">
              <Form.Label>Razão Social</Form.Label>
              <Form.Control
                type="text"
                name="razaoSocial"
                placeholder=""
                disabled
                value={model.razaoSocialEmbarcador ? model.razaoSocialEmbarcador : razaoSocial}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          {/* TRANSFERECIA */}
          <Form.Row>
            <Form.Group
              controlId="formGridTransferencia"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                  color: ' #000',
                }}
              >
                Transferência
              </Form.Label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridTransferenciaLimiteCarga"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '24%',
                  width: '20%',
                }}
              >
                Limite de Carga
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridLimiteCarga"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                }}
              >
                <Form.Label style={{ alignSelf: 'center', marginRight: '3.5%', width: '40%' }}>
                  Valor
                </Form.Label>
                <Form.Control
                  type="text"
                  name="limiteCarga"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      limiteCargaTransferencia: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.limiteCargaTransferencia)}
                  style={{ width: '80%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>

            <Form.Group className="mt-3" as={Col}>
              <Form.Check
                checked={model.criticarMarcasIguaisTransferencia}
                onChange={(e: any) => {
                  setModel({
                    ...model,
                    criticarMarcasIguaisTransferencia: !model.criticarMarcasIguaisTransferencia,
                  });
                }}
                name="criticar"
                type="switch"
                id="custom-switch1"
                label="Criticar marcas iguais"
                style={{ marginLeft: '44%' }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridTransferenciaEscolta"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Escolta
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridEscolta"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoEscoltaTransferencia: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoEscoltaTransferencia)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridTransferenciaIsca"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIsca"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoIscaTransferencia: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoIscaTransferencia)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTeto"
                  placeholder=""
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridTransferenciaIscaDois"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Segunda Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIscaDois"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPisoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoSegIscaTransferencia: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoSegIscaTransferencia)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTetoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridTransferenciaImobilizador"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Imobilizador Eletrônico
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridImobilizador"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="imobilizadorPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoImobilizadorEletrIscaTransferencia: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoImobilizadorEletrIscaTransferencia)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="ImobilizadorTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          {/* DISTRIBUICAO */}
          <Form.Row>
            <Form.Group
              controlId="formGridDistribuicao"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                  color: ' #000',
                }}
              >
                Distribuição
              </Form.Label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDistribuicaoLimiteCarga"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '24%',
                  width: '20%',
                }}
              >
                Limite de Carga
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridLimiteCarga"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Form.Label style={{ alignSelf: 'center', width: '40%', marginRight: '3.5%' }}>
                  Valor
                </Form.Label>
                <Form.Control
                  type="text"
                  name="limiteCarga"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      limiteCargaDistribuicao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.limiteCargaDistribuicao)}
                  style={{ width: '80%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>

            <Form.Group className="mt-3" as={Col}>
              <Form.Check
                checked={model.criticarMarcasIguaisDistribuicao}
                onChange={(e: any) => {
                  setModel({
                    ...model,
                    criticarMarcasIguaisDistribuicao: !model.criticarMarcasIguaisDistribuicao,
                  });
                }}
                name="criticar"
                type="switch"
                id="custom-switch1"
                label="Criticar marcas iguais"
                style={{ marginLeft: '44%' }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDistribuicaoEscolta"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Escolta
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridEscolta"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoEscoltaDistribuicao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoEscoltaDistribuicao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDistribuicaoIsca"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIsca"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoIscaDistribuicao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoIscaDistribuicao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDistribuicaoIscaDois"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Segunda Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIscaDois"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPisoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoSegIscaDistribuicao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoSegIscaDistribuicao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTetoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDistribuicaoImobilizador"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Imobilizador Eletrônico
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridImobilizador"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="imobilizadorPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoImobilizadorEletrIscaDistribuicao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoImobilizadorEletrIscaDistribuicao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="ImobilizadorTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          {/* COLETA */}
          <Form.Row>
            <Form.Group
              controlId="formGridColeta"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                  color: ' #000',
                }}
              >
                Coleta
              </Form.Label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaLimiteCarga"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '24%',
                  width: '20%',
                }}
              >
                Limite de Carga
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridLimiteCarga"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Form.Label style={{ alignSelf: 'center', marginRight: '3.5%', width: '40%' }}>
                  Valor
                </Form.Label>
                <Form.Control
                  type="text"
                  name="limiteCarga"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      limiteCargaColeta: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.limiteCargaColeta)}
                  style={{ width: '80%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>

            <Form.Group className="mt-3" as={Col}>
              <Form.Check
                checked={model.criticarMarcasIguaisColeta}
                onChange={(e: any) => {
                  setModel({
                    ...model,
                    criticarMarcasIguaisColeta: !model.criticarMarcasIguaisColeta,
                  });
                }}
                name="criticar"
                type="switch"
                id="custom-switch1"
                label="Criticar marcas iguais"
                style={{ marginLeft: '44%' }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaEscolta"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Escolta
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridEscolta"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoEscoltaColeta: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoEscoltaColeta)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaIsca"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIsca"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoIscaColeta: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoIscaColeta)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaIscaDois"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Segunda Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIscaDois"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPisoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoSegIscaColeta: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoSegIscaColeta)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTetoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaImobilizador"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Imobilizador Eletrônico
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridImobilizador"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="imobilizadorPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoImobilizadorEletrIscaColeta: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoImobilizadorEletrIscaColeta)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="ImobilizadorTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          {/* EXPORTAÇÃO */}
          <Form.Row>
            <Form.Group
              controlId="formGridColeta"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                  color: ' #000',
                }}
              >
                Exportação
              </Form.Label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaLimiteCarga"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '24%',
                  width: '20%',
                }}
              >
                Limite de Carga
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridLimiteCarga"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Form.Label style={{ alignSelf: 'center', marginRight: '3.5%', width: '40%' }}>
                  Valor
                </Form.Label>
                <Form.Control
                  type="text"
                  name="limiteCarga"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      limiteCargaExportacao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.limiteCargaExportacao)}
                  style={{ width: '80%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>

            <Form.Group className="mt-3" as={Col}>
              <Form.Check
                checked={model.criticarMarcasIguaisExportacao}
                onChange={(e: any) => {
                  setModel({
                    ...model,
                    criticarMarcasIguaisExportacao: !model.criticarMarcasIguaisExportacao,
                  });
                }}
                name="criticar"
                type="switch"
                id="custom-switch1"
                label="Criticar marcas iguais"
                style={{ marginLeft: '44%' }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaEscolta"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Escolta
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridEscolta"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoEscoltaExportacao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoEscoltaExportacao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaIsca"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIsca"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoIscaEsportacao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoIscaEsportacao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaIscaDois"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Segunda Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIscaDois"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPisoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoSegIscaExportacao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoSegIscaExportacao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTetoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridColetaImobilizador"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Imobilizador Eletrônico
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridImobilizador"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="imobilizadorPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoImobilizadorEletrIscaExportacao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoImobilizadorEletrIscaExportacao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="ImobilizadorTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          {/* DEVOLUCAO */}
          <Form.Row>
            <Form.Group
              controlId="formGridDevolucao"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                  color: ' #000',
                }}
              >
                Devolução
              </Form.Label>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDevolucaoLimiteCarga"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '24%',
                  width: '20%',
                }}
              >
                Limite de Carga
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridLimiteCarga"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Form.Label style={{ alignSelf: 'center', marginRight: '3.5%', width: '40%' }}>
                  Valor
                </Form.Label>
                <Form.Control
                  type="text"
                  name="limiteCarga"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      limiteCargaDevolucao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.limiteCargaDevolucao)}
                  style={{ width: '80%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>

            <Form.Group className="mt-3" as={Col}>
              <Form.Check
                checked={model.criticarMarcasIguaisDevolucao}
                onChange={(e: any) => {
                  setModel({
                    ...model,
                    criticarMarcasIguaisDevolucao: !model.criticarMarcasIguaisDevolucao,
                  });
                }}
                name="criticar"
                type="switch"
                id="custom-switch1"
                label="Criticar marcas iguais"
                style={{ marginLeft: '44%' }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDevolucaoEscolta"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Escolta
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridEscolta"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoEscoltaDevolucao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoEscoltaDevolucao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="escoltaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDevolucaoIsca"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIsca"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoIscaDevolucao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoIscaDevolucao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDevolucaoIscaDois"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Segunda Isca
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridIscaDois"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaPisoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoSegIscaDevolucao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoSegIscaDevolucao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="iscaTetoDois"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group
              controlId="formGridDevolucaoImobilizador"
              as={Col}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Form.Label
                style={{
                  alignSelf: 'center',
                  marginRight: '20px',
                  width: '20%',
                }}
              >
                Imobilizador Eletrônico
              </Form.Label>

              <Form.Group
                as={Col}
                controlId="formGridImobilizador"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Label style={{ alignSelf: 'center' }}>Piso</Form.Label>
                <Form.Control
                  type="text"
                  name="imobilizadorPiso"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      pisoImobilizadorEletrIscaDevolucao: Number(toDecimal(e.target.value)),
                    });
                  }}
                  value={toCurrency(model.pisoImobilizadorEletrIscaDevolucao)}
                  style={{ width: '35%', alignSelf: 'center' }}
                />

                <Form.Label style={{ alignSelf: 'center' }}>Teto</Form.Label>
                <Form.Control
                  type="text"
                  name="ImobilizadorTeto"
                  placeholder=""
                  maxLength={21}
                  disabled={isAllowedByRole(['user'])}
                  value=""
                  style={{ width: '35%', alignSelf: 'center' }}
                />
              </Form.Group>
            </Form.Group>
          </Form.Row>

          {isAllowedByRole(['user']) ? (
            <Form.Row className="container-buttons justify-content-end">
              <Form.Group as={Col} md={2}>
                <Button2
                  className="btn-cancelar"
                  onClick={() => {
                    setShowView(false);
                  }}
                >
                  Fechar
                </Button2>
              </Form.Group>
            </Form.Row>
          ) : (
            // <Form.Row className="container-buttons justify-content-end">
            //   <Form.Group as={Col} md={2}>
            //     <Button2
            //       className="btn-cancelar"
            //       onClick={() => {
            //         setShowView(false);
            //       }}
            //     >
            //       Cancelar
            //     </Button2>
            //   </Form.Group>
            //   <Form.Group as={Col} md={2}>
            //     <Button2 className="btn-enviar" type="submit">
            //       Confirmar
            //     </Button2>
            //   </Form.Group>
            // </Form.Row>
            <ButtonsForm canEdit={canEdit || canInsert } />
          )}
        </Form>
      </div>
    </div>
  );
};

export default EmbarcadorView;
