import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import axios from '../../../config/axiosMaquina';

import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';

import Pagination from '../../../components/Pagination/Pagination';
import { getCookieSessionData } from '../../../services/cookieService';
import ModalVelocidadeVeiculo from './modal';
import { useToast } from '../../../hooks/Toast';

type ViolacaoVelocidade = {
  enviaComandoSirene: boolean;
  enviaEmail: boolean;
  enviaMensagemVeiculo: boolean;
  enviaSms: boolean;
  enviaWhatsapp: boolean;
  idCliente: number;
  idViolacao: number;
  listaEmails: string;
  listaNumerosTelefone: string;
  mensagemEmailSmsWapp: string;
  mensagemTeclado: string;
  moduloTipo: string;
  velocidadeMaximaFrota: number;
  velocidadeMaximaIndividual: number;
};

type Props = {
  canEdit: boolean;
  canInsert: boolean;
};

const VelocidadePorVeiculo: React.FC<Props> = ({ canEdit, canInsert }) => {
  const { clienteId } = getCookieSessionData().usuarioVO;
  const { addToast } = useToast();
  const history = useHistory();
  const [veiculos, setVeiculos] = useState<VeiculoTypeGrid[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<VeiculoTypeGrid[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [velocidadeMaxima, setVelocidadeMaxima] = useState('');
  const initialState = {
    enviaComandoSirene: false,
    enviaEmail: false,
    enviaMensagemVeiculo: false,
    enviaSms: false,
    enviaWhatsapp: false,
    idCliente: clienteId,
    idViolacao: 0,
    listaEmails: '',
    listaNumerosTelefone: '',
    mensagemEmailSmsWapp: '',
    mensagemTeclado: '',
    moduloTipo: '',
    velocidadeMaximaFrota: 0,
    velocidadeMaximaIndividual: 0,
  };
  const [violacao, setViolacao] = useState<ViolacaoVelocidade>(initialState);
  const [erroVelocidadeMaxima, setErroVelocidadeMaxima] = useState(false);

  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const currentVeiculos = (searchTerm.trim() !== '' ? searchResults : veiculos).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const getViolacao = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/violacao-velocidade/idCliente?idCliente=${clienteId}`);
      if (!response.data.idViolacao) {
        setViolacao(initialState);
      } else {
        setViolacao(response.data);
        setVelocidadeMaxima(response.data.velocidadeMaximaFrota);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [clienteId]);

  const loadVeiculos = useCallback(async () => {
    setLoading(true);
    if (!clienteId) {
      return;
    }
    try {
      const response = await axios.get(`/veiculo/listarVeiculosPorCliente?cliente=${clienteId}`);
      setVeiculos(response.data.filter((each: VeiculoTypeGrid) => each.ativo));
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, [clienteId]);

  useEffect(() => {
    if (clienteId) {
      getViolacao();
      loadVeiculos();
    }
  }, [clienteId]);

  useEffect(() => {
    const search = async () => {
      if (searchTerm.trim() !== '') {
        const filter = veiculos.filter((each: VeiculoTypeGrid) =>
          each.placa?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filter);
      }
    };
    search();
  }, [searchTerm, veiculos]);

  const handleVelocidadeFrota = async (e: any) => {
    e.preventDefault();
    if (erroVelocidadeMaxima) {
      addToast({
        title: 'Erro!',
        description: 'Velocidade máxima não pode ser menor que 50km/h ou maior que 140km/h.',
        type: 'error',
      });
      return;
    }
    if (velocidadeMaxima === '') {
      addToast({
        title: 'Erro!',
        description: 'Velocidade máxima inválida.',
        type: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      setLoading(true);
      if (violacao.idViolacao === 0) {
        const response = await axios.post('/violacao-velocidade/salvar', violacao);
        addToast({
          title: 'Cadastrado!',
          description: 'Cadastrado com sucesso',
          type: 'success',
        });
      } else {
        const response = await axios.put(
          `/violacao-velocidade/atualizar?idViolacao=${violacao.idViolacao}`,
          violacao
        );
        addToast({
          title: 'Atualizado!',
          description: 'Atualizado com sucesso',
          type: 'success',
        });
      }
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro!',
        description: `${violacao.idViolacao === 0 ? 'Erro ao cadastrar' : 'Erro ao atualizar'}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
      getViolacao();
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Controle de Velocidade</h2>
          <h6 className="subtitulo">{'Controle Logístico > Controle de Velocidade'}</h6>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '100%',
              justifyContent: 'flex-start',
              marginBottom: 24,
              marginLeft: 5,
            }}
          >
            <Form.Row>
              <Form.Label>Velocidade Máxima da Frota (km/h)</Form.Label>
              <Form.Control
                name="proprietarioId"
                placeholder="Km/h"
                value={velocidadeMaxima}
                max={140}
                maxLength={3}
                onChange={(e) => {
                  setVelocidadeMaxima(e.target.value);
                  setViolacao({
                    ...violacao,
                    velocidadeMaximaFrota: parseInt(e.target.value, 10),
                  });
                  if (parseInt(e.target.value, 10) > 140 || parseInt(e.target.value, 10) < 50) {
                    setErroVelocidadeMaxima(true);
                  } else {
                    setErroVelocidadeMaxima(false);
                  }
                }}
                required
              />
            </Form.Row>
          </div>
          <Button
            type="submit"
            onClick={(e) =>
              canEdit || canInsert ? handleVelocidadeFrota(e) : history.push('/acessonegado')
            }
            style={{
              color: '#111',
              fontSize: '1em',
              fontWeight: 500,
              marginLeft: 30,
              maxHeight: 38,
              padding: '5px 20px',
            }}
          >
            Confirmar
          </Button>
          <div className="search-create mb-4" style={{ width: '100%', marginTop: 25 }}>
            <span className="p-input-icon-left">
              <input
                type="text"
                placeholder="Pesquise"
                className="p-inputtext p-component"
                id="search-input"
                style={{ width: '240px' }}
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
              />
              <i className="pi pi-search" />
            </span>
          </div>
        </div>

        <div className="content-container" style={{ width: '100%' }}>
          <div className="painel">
            <div className="table-responsive" style={{ flex: 1 }}>
              {loading ? (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              ) : (
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th>Placa</th>
                      <th>Marca</th>
                      <th>Modelo</th>
                      <th>Tipo</th>
                      <th>Velocidade Máxima</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentVeiculos &&
                      currentVeiculos.map((veiculo: VeiculoTypeGrid) => {
                        return (
                          <tr key={veiculo.id}>
                            <td>{veiculo.placa === null ? '' : veiculo.placa}</td>
                            <td>{veiculo.veiculoMarcaNome}</td>
                            <td>{veiculo.modelo}</td>
                            <td>{veiculo.veiculoTipoNome}</td>
                            <td>
                              {!veiculo.velocidadeIndividualVeiculo
                                ? 'Velocidade Padrão'
                                : `${veiculo.velocidadeIndividualVeiculo} km/h`}
                            </td>

                            <td>
                              <div className="icons">
                                <ModalVelocidadeVeiculo
                                  veiculo={veiculo}
                                  loadVeiculos={loadVeiculos}
                                  clienteId={clienteId}
                                  canEdit={canEdit}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <Pagination
            totalPosts={searchTerm.trim() !== '' ? searchResults.length : veiculos.length}
            paginate={paginate}
            currentPage={currentPage.toString()}
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default VelocidadePorVeiculo;
