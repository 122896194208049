import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import { Tabela } from '../../../components/Tabela';
import { pesquisar } from '../../../util/format';

type FaixaCubagemType = {
  id: number;
  idCliente: number;
  cubagem: number;
  razaoSocialCliente: string;
};

type Props = {
  canInsert?: boolean;
};

const FaixasCubagemList: React.FC<Props> = ({ canInsert }) => {
  const history = useHistory();
  const { page }: any = useParams();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [data, setData] = useState<FaixaCubagemType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const titles = ['+id', 'Cubagem'];
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/listar-faixas-de-cubagem/${pageNumber}`);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/cubagem-faixa/grid`, {
        params: {
          idCliente,
        },
      });

      const array = response.data.map((each: FaixaCubagemType) => {
        return [each.id, each.cubagem];
      });
      setData(array);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar-faixas-de-cubagem/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Tela
      nome="Faixas de Cubagem"
      caminho="Cadastro > Faixas de Cubagem"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/cadastrar-faixas-de-cubagem/"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/editar-faixas-de-cubagem/${id}`)}
        />
      )}
    </Tela>
  );
};

export default FaixasCubagemList;
