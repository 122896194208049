/* eslint-disable */
import axios from '../../../config/axiosMaquina';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Veiculo } from '../../../../src/Types/Veiculos/VeiculoType';
import { useHistory, useParams } from 'react-router-dom';
import { Proprietario } from '../../../../src/Types/Veiculos/ProprietarioType';
import { Cor } from '../../../../src/Types/Veiculos/CorType';
import { VeiculoMarca } from '../../../../src/Types/Veiculos/VeiculoMarcaType';
import { VeiculoTipo } from '../../../../src/Types/Veiculos/VeiculoTipoType';
import { TecnologiaRastreamento } from '../../../../src/Types/Veiculos/TecnologiaRastreamentoType';
import { useToast } from '../../../hooks/Toast';
import './styles.css';
import Switch from 'react-bootstrap/esm/Switch';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import { UfIbgeType } from '../../../api/model/UfIbgeType';
import { MunicipiosType } from '../../../api/model/MunicipiosType';
import { PaisType } from '../../../api/model/PaisType';
import { TipoComunicacaoIdNome } from '../../../api/model/TipoComunicacaoIdNomeType';
import { dataConverter, obterDataHora } from '../../../util/date';

const VeiDetalhes: React.FC = () => {
  const history = useHistory();
  const { id }: any = useParams();
  const { addToast } = useToast();
  const [model, setModel] = useState<Veiculo>({
    id: 0,
    placa: '',
    modelo: '',
    proprietarioId: {
      proCdId: 0,
      proTxTipoPessoa: '',
      proNuCodigo: 0,
      proTxCpfCnpj: 0,
      proTxNomeRazaoSocial: '',
      proBlAtivo: true,
    },
    veiculoMarca: {
      vmaCdId: 0,
      vmaTxNome: '',
      vmaBlAtivo: true,
      vmaTxFipeName: '',
      vmaTxFipeKey: '',
      vmaCdIdFipe: 0,
    },
    renavam: '',
    chassi: '',
    cor: {
      id: 0,
      corNome: '',
      corOrdem: 0,
      statusAtivo: true,
    },
    veiculoTipo: {
      vtpCdId: 0,
      vtpTxNome: '',
      vtpBlAtivo: true,
      vtgCdId: {
        vtgCdId: 0,
        vtgTxNome: '',
        vtgBlAtivo: true,
        vgmCdId: {
          vgmCdId: 0,
          vgmTxNome: '',
          vgmBlAtivo: true,
        },
      },
    },
    tipoFrota: '',
    frota: '',
    carroceriaTipo: 0,
    anttRntrc: '',
    cubagem: 0,
    modeloAno: 0,
    fabricacaoAno: 0,
    tecnologiaRastreamento: {
      terCdId: 0,
      terTxNome: '',
      terTxDescricao: '',
      terTxImagemLogo: '',
      terNuOrdem: 0,
      terBlAtivo: true,
    },
    comunicacao: {
      id: 0,
      nome: '',
    },
    numeroEquipamento: '',
    emplacamentoPais: 0,
    emplacamentoCidade: 0,
    emplacamentoUf: 0,
    ufIbge: 0,
    municipioIbge: 0,
    tipoFaturamento: '',
    bloqueado: false,
    dataUltimaAlteracao: dataConverter(new Date().toISOString()),
    qtdeViagens: 0,
    valorMedioTransportado: 0,
    tempoCadastro: '',
    obs: '',
    cadastro: '',
    veiculoMarcaNome: '',
    veiculoTipoNome: '',
    mapa: false,
    atualizacaoAutomatica: false,
    ativo: true,
  });

  useEffect(() => {
    if (id !== undefined) {
      findVei(id);
    }
    loadProprietarios();
    loadVeiculos();
    loadCor();
    loadVeiculoMarca();
    loadVeiculoTipo();
    loadVeiculoTr();
  }, [id]);

  const [veiculo, setVeiculo] = useState<Veiculo[]>([]);

  async function loadVeiculos() {
    const response = await axios.get('/veiculo');
    setVeiculo(response.data);
  }

  const [proprietario, setProprietario] = useState<Proprietario[]>([]);

  async function loadProprietarios() {
    const response = await axios.get('/proprietario');
    setProprietario(response.data);
  }

  const [cor, setCor] = useState<Cor[]>([]);

  async function loadCor() {
    const response = await axios.get('/cor');
    setCor(response.data);
  }

  const [marca, setMarca] = useState<VeiculoMarca[]>([]);

  async function loadVeiculoMarca() {
    const response = await axios.get('/marca-veiculo');
    setMarca(response.data);
  }

  const [veiTipo, setVeiTipo] = useState<VeiculoTipo[]>([]);

  async function loadVeiculoTipo() {
    const response = await axios.get('/tipo-veiculo');
    setVeiTipo(response.data);
  }

  const [veiTer, setVeiTer] = useState<TecnologiaRastreamento[]>([]);

  const [veiCom, setVeiCom] = useState<TipoComunicacaoIdNome[]>([]);

  async function loadVeiculoTr() {
    const response = await axios.get('/tecnologia-rastreamento');
    setVeiTer(response.data);
  }

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);

  useEffect(() => {
    const loadUf = async () => {
      const response = await axios.get('/uf');
      setUfs(response.data);
    };
    loadUf();
  }, []);

  useEffect(() => {
    const loadMunicipio = async () => {
      const response = await axios.get('/municipio-ibge');
      setMunicipios(response.data);
    };
    loadMunicipio();
  }, []);

  const [pais, setPais] = useState<PaisType[]>([]);

  useEffect(() => {
    const loadPais = async () => {
      const response = await axios.get('/pais');
      setPais(response.data);
    };
    loadPais();
  }, []);

  const [municipioFilter, setMunicipioFilter] = useState<MunicipiosType[] | null>(null);
  useEffect(() => {
    if (!model.emplacamentoUf || !municipios) {
      return;
    }
    const estado = ufs.filter((each) => each.id === model.emplacamentoUf);
    const municipio = municipios.filter((each) => each.idCodigoIbge === estado[0].codigoIbge);
    setMunicipioFilter(municipio);
  }, [model, municipios]);

  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    console.log('update', model);
    setModel({
      ...model,
      [e.target.name]: e.target.value,
    });
  }

  async function deleteFunc(id: number) {
    try {
      await axios.delete(`/veiculo/${id}`);
      // alert('Cadastro Desativado com Sucesso');
      addToast({
        title: 'Sucesso!',
        description: 'Cadastro Desativado com Sucesso.',
        type: 'success',
      });
      back();
    } catch (err: any) {
      // ('Erro ao Desativar Cadastro');
      addToast({
        title: 'Erro!',
        description: 'Erro ao Desativar Cadastro.',
        type: 'error',
      });
    }
  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      if (id !== undefined) {
        const response = await axios.put(`/veiculo/${id}`, model);
      } else {
        const response = await axios.post('/veiculo', model);
      }
      // alert('Cadastro Realizado com Sucesso');
      addToast({
        title: 'Sucesso!',
        description: 'Cadastro Realizado com Sucesso.',
        type: 'success',
      });
      back();
    } catch (alert) {
      ('Erro ao Atualizar Veiculo Realizado com Sucesso');
    }
  }
  async function findVei(id: string) {
    const response = await axios.get(`veiculo/${id}`);
    setModel({
      id: response.data.id,
      placa: response.data.placa,
      modelo: response.data.modelo,
      proprietarioId: response.data.proprietarioId,
      veiculoMarca: response.data.veiculoMarca,
      renavam: response.data.renavam,
      chassi: response.data.chassi,
      cor: response.data.cor,
      veiculoTipo: response.data.veiculoTipo,
      tipoFrota: response.data.tipoFrota,
      frota: response.data.frota,
      carroceriaTipo: response.data.carroceriaTipo,
      anttRntrc: response.data.anttRntrc,
      cubagem: response.data.cubagem,
      modeloAno: response.data.modeloAno,
      fabricacaoAno: response.data.fabricacaoAno,
      tecnologiaRastreamento: response.data.tecnologiaRastreamento,
      comunicacao: response.data.comunicacao,
      numeroEquipamento: response.data.anttRntrc,
      emplacamentoPais: response.data.emplacamentoPais,
      emplacamentoCidade: response.data.emplacamentoCidade,
      emplacamentoUf: response.data.emplacamentoUf,
      ufIbge: response.data.ufIbge,
      municipioIbge: response.data.municipioIbge,
      tipoFaturamento: response.data.tipoFaturamento,
      bloqueado: response.data.bloqueado,
      dataUltimaAlteracao: dataConverter(new Date().toISOString()),
      qtdeViagens: response.data.qtdeViagens,
      valorMedioTransportado: response.data.valorMedioTransportado,
      tempoCadastro: response.data.tempoCadastro,
      obs: response.data.obs,
      cadastro: obterDataHora(response.data.cadastro),
      mapa: response.data.mapa,
      atualizacaoAutomatica: response.data.atualizacaoAutomatica,
      ativo: response.data.ativo,
      veiculoMarcaNome: response.data.veiculoMarcaNome,
      veiculoTipoNome: response.data.veiculoTipoNome,
    });
  }

  console.log(`model`, model);

  const [mapa, setMapa] = useState(true);
  const toggleSwitchMapa = () => {
    setMapa((previousState) => !previousState);
    setModel({
      ...model,
      mapa: !mapa,
    });
  };

  const [ativo, setAtivo] = useState(true);
  const toggleSwitchAtivo = () => {
    setAtivo((previousState) => !previousState);
    setModel({
      ...model,
      ativo: !ativo,
    });
  };

  const [atualizacaoAutomatica, setAtualizacaoAutomatica] = useState(false);
  const toggleSwitchAtualizacaoAutomatica = () => {
    setAtualizacaoAutomatica((previousState) => !previousState);
    setModel({
      ...model,
      atualizacaoAutomatica: !atualizacaoAutomatica,
    });
  };

  function back() {
    history.goBack();
  }

  function veiEditar(id: number) {
    history.push(`/atualizarveiculo/${id}`);
  }

  const nomeProp = model.proprietarioId;

  const nomeProprietarioForm = (idProp: any) => {
    const nomeProp2 = proprietario.find((prop) => prop.proCdId === idProp);
    return nomeProp2?.proTxNomeRazaoSocial;
  };

  const nomeCor = model.cor;

  const nomeCorForm = (idCor: any) => {
    const nomeCor2 = cor.find((cores) => cores.id === idCor);
    return nomeCor2?.corNome;
  };

  const nomeMarca = model.veiculoMarca;

  const nomeMarcaForm = (idMarca: any) => {
    const nomeMarca2 = marca.find((marcas) => marcas.vmaCdId === idMarca);
    return nomeMarca2?.vmaTxNome;
  };

  const nomeTipo = model.veiculoTipo;

  const nomeTipoForm = (idTipo: any) => {
    const nomeTipo2 = veiTipo.find((tipos) => tipos.vtpCdId === idTipo);
    return nomeTipo2?.vtpTxNome;
  };

  const nomeTer = model.tecnologiaRastreamento;

  const nomeTerForm = (idTer: any) => {
    const nomeTer2 = veiTer.find((tr) => tr.terCdId === idTer);
    return nomeTer2?.terTxNome;
  };

  const [tipoComunicacao, setTipoComunicacao] = useState<TipoComunicacaoIdNome[]>();

  useEffect(() => {
    if (!model.tecnologiaRastreamento) {
      return;
    }
    const loadDadosTecnologiaComunicacaoPorTecnologia = async () => {
      try {
        const response = await axios.get(
          `/tipo-comunicacao/listarIdNomePorTecnologia?tecnologia=${model.tecnologiaRastreamento}`
        );
        setTipoComunicacao(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    loadDadosTecnologiaComunicacaoPorTecnologia();
  }, [model.tecnologiaRastreamento]);

  const nomeCom = model.comunicacao;

  const nomeComForm = (idCom: any) => {
    if (!tipoComunicacao) {
      return;
    }
    const teste = tipoComunicacao[0].nome;
    // const nomeCom2 = tipoComunicacao.find((cm) => cm.id === idCom);
    // console.log('nomeCom: ', nomeCom);
    // console.log('nomeCom2: ', nomeCom2);
    // console.log('tipoComunicacao ', tipoComunicacao[0]);

    // return nomeCom2?.nome;
    return teste;
  };

  const nomePais = model.emplacamentoPais;

  const nomePaisForm = (idPai: any) => {
    const nomePais2 = pais.find((pai) => pai.id === idPai);
    return nomePais2?.paisDescricao;
  };

  const nomeEstado = model.emplacamentoUf;

  const nomeEstadoForm = (idUf: any) => {
    const nomeEstado2 = ufs.find((uf) => uf.id === idUf);
    return nomeEstado2?.estado;
  };

  const nomeMunicipio = model.emplacamentoCidade;

  const nomeMunicipioForm = (idMui: any) => {
    const nomeMunicipio2 = municipios.find((mui) => mui.id === idMui);
    return nomeMunicipio2?.nomeMunicipio;
  };

  useEffect(() => {
    console.log('ENTREI NA PAGINA DE EDIÇÃO');
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Veiculos</h2>
          <h6 className="subtitulo">{'Cadastro > Veiculos > Atualizar'}</h6>
        </div>
        <div className="painel">
          <Form className="form-responsive" onSubmit={onSubmit}>
            <Form.Group controlId="formGridProprietario">
              <Form.Label>PROPRIETÁRIO</Form.Label>
              <Form.Control
                value={nomeProprietarioForm(nomeProp)}
                disabled
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                name="proprietario"
                type="text"
                placeholder=""
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridPlaca">
                <Form.Label>PLACA</Form.Label>
                <Form.Control
                  value={model.placa}
                  disabled
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="placa"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridRenavam">
                <Form.Label>RENAVAM</Form.Label>
                <Form.Control
                  value={model.renavam}
                  disabled
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="renavam"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridChassi">
                <Form.Label>CHASSI</Form.Label>
                <Form.Control
                  value={model.chassi}
                  disabled
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="chassi"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridCor">
                <Form.Label>COR</Form.Label>
                <Form.Control
                  value={nomeCorForm(nomeCor)}
                  disabled
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  type="text"
                  placeholder=""
                  name="cor"
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridMarca">
                <Form.Label>MARCA</Form.Label>
                <Form.Control
                  value={nomeMarcaForm(nomeMarca)}
                  disabled
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  type="text"
                  placeholder=""
                  name="veiculoMarca"
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridModelo">
                <Form.Label>MODELO</Form.Label>
                <Form.Control
                  value={model.modelo}
                  disabled
                  name="modelo"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridTipo">
                <Form.Label>TIPO DE VEICULO</Form.Label>
                <Form.Control
                  value={nomeTipoForm(nomeTipo)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  required
                  disabled
                  type="text"
                  placeholder=""
                  name="veiculoTipo"
                ></Form.Control>
              </Form.Group>

              {/* <Form.Group as={Col} controlId="formGridTipoGrupo">
                <Form.Label>VEICULO TIPO GRUPO</Form.Label>
                <Form.Control
                  disabled
                  as="select"
                  placeholder="Tipo Grupo de Veiculo"
                  name="veiculoTipoGrupo"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  required
                >
                  <option value="0">Selecione uma opção</option>
                  {veiTipo.map((veiTipo) => (
                    <option
                      key={veiTipo.vtpCdId}
                      value={veiTipo.vtgCdId ? veiTipo.vtgCdId.vtgCdId : ''}
                    >
                      {veiTipo.vtgCdId ? veiTipo.vtgCdId.vtgTxNome : ''}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridGM">
                <Form.Label>GRUPO MACRO</Form.Label>
                <Form.Control
                  disabled
                  as="select"
                  placeholder="Veículo Grupo Macro"
                  name="veiculoTipoGrupoMacro"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  required
                >
                  <option value="0">Selecione uma opção</option>
                  {veiTipo.map((veiTipo) => (
                    <option
                      key={veiTipo.vtpCdId}
                      value={
                        veiTipo.vtgCdId
                          ? veiTipo.vtgCdId.vtgCdId
                            ? veiTipo.vtgCdId.vgmCdId.vgmCdId
                            : ''
                          : ''
                      }
                    >
                      {veiTipo.vtgCdId
                        ? veiTipo.vtgCdId.vtgCdId
                          ? veiTipo.vtgCdId.vgmCdId.vgmTxNome
                          : ''
                        : ''}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group> */}

              <Form.Group as={Col} controlId="formGridFrota">
                <Form.Label>TIPO FROTA</Form.Label>
                <Form.Control
                  value={model.tipoFrota}
                  disabled
                  name="tipoFrota"
                  type="text"
                  placeholder=""
                />

                {/* <Form.Label>FROTA</Form.Label>
                <Form.Control
                  value={model.frota}
                  disabled
                  name="frota"
                  type="text"
                  placeholder=""
                /> */}

                {/* <Form.Label>TIPO DE CARROCERIA</Form.Label>
                                <Form.Control defaultValue={model.carroceriaTipo}
                                    disabled
                                    name="tipoCarroceria"
                                    type="text"
                                    placeholder="" /> */}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridAntt">
                <Form.Label>ANTT \ RNTRC</Form.Label>
                <Form.Control
                  value={model.anttRntrc}
                  disabled
                  name="anttRntrc"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCubagem">
                <Form.Label>CUBAGEM</Form.Label>
                <Form.Control
                  value={model.cubagem}
                  disabled
                  name="cubagem"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridAno">
                <Form.Label>ANO MODELO</Form.Label>
                <Form.Control
                  value={model.modeloAno}
                  disabled
                  name="modeloAno"
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridFabricacao">
                <Form.Label>ANO FABRICAÇÃO</Form.Label>
                <Form.Control
                  value={model.fabricacaoAno}
                  disabled
                  name="fabricacaoAno"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridTecnologia">
                <Form.Label>TECNOLOGIA RASTREAMENTO</Form.Label>
                <Form.Control
                  value={nomeTerForm(nomeTer)}
                  disabled
                  type="text"
                  name="tecnologiaRastreamento"
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridComunicacao">
                <Form.Label>TIPO COMUNICAÇÃO</Form.Label>
                <Form.Control
                  value={nomeComForm(nomeCom)}
                  type="text"
                  disabled
                  name="comunicacao"
                  required
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEqpto">
                <Form.Label>Nº EQUIPAMENTO</Form.Label>
                <Form.Control
                  value={model.numeroEquipamento}
                  disabled
                  name="numeroEquipamento"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridPais">
                <Form.Label>PAIS EMPLACAMENTO</Form.Label>
                <Form.Control
                  defaultValue={nomePaisForm(nomePais)}
                  disabled
                  name="emplacamentoPais"
                  type="text"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridUF">
                <Form.Label>UF EMPLACAMENTO</Form.Label>
                <Form.Control
                  defaultValue={nomeEstadoForm(nomeEstado)}
                  disabled
                  name="emplacamentoUf"
                  type="text"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCidade">
                <Form.Label>CIDADE EMPLACAMENTO</Form.Label>
                <Form.Control
                  defaultValue={nomeMunicipioForm(nomeMunicipio)}
                  disabled
                  name="emplacamentoCidade"
                  type="text"
                />

                {/* <Form.Label>MUNICIPIO IBGE</Form.Label>
                                <Form.Control defaultValue={model.municipioIbge}
                                    disabled
                                    name="municipioIbge"
                                    type="text"
                                    placeholder="" />

                                <Form.Label>UF IBGE</Form.Label>
                                <Form.Control defaultValue={model.ufIbge}
                                    disabled
                                    name="ufIbge"
                                    type="text"
                                    placeholder="" /> */}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              {/* <Form.Group as={Col} controlId="formGridFaturamento">
                <Form.Label>TIPO DE FATURAMENTO</Form.Label>
                <Form.Control
                  value={model.tipoFaturamento}
                  disabled
                  name="tipoFaturamento"
                  type="text"
                  placeholder="Tipo Faturamento"
                />
              </Form.Group> */}

              <Form.Group as={Col} controlId="formGridObs">
                <Form.Label>OBSERVAÇÕES</Form.Label>
                <Form.Control
                  defaultValue={model.obs}
                  disabled
                  name="obs"
                  as="textarea"
                  placeholder="Observação"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridData">
                <Form.Label>DATA DE CADASTRO</Form.Label>
                <Form.Control
                  defaultValue={model.cadastro}
                  disabled
                  name="dataCadastro"
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            <Form.Group className="group-swicth">
              {/* <Switch
                checked={ativo}
                onChange={toggleSwitchAtivo}
                name="ativo"
                id="ativo-switch"
                type="switch"
                label="Apto para viagens"
              /> */}
              <Switch
                value={Number(atualizacaoAutomatica)}
                checked={model.atualizacaoAutomatica}
                onChange={toggleSwitchAtualizacaoAutomatica}
                name="atualizacaoAutomatica"
                id="pesquisa"
                type="switch"
                label="Atualizar pesquisa automaticamente"
                disabled
              />
              <Switch
                checked={model.mapa}
                onChange={toggleSwitchMapa}
                value={Number(mapa)}
                name="mapa"
                id="mapa"
                type="switch"
                label="Exibir no mapa"
                disabled
              />

              <div style={{ display: 'flex', flex: '.9', flexDirection: 'row' }}>
                <div style={{ display: 'flex', alignSelf: 'end' }}>
                  <Form.Row className="container-buttons justify-content-end">
                    <Form.Group as={Col} md={2}>
                      <Button
                        style={{ width: 175, height: 50 }}
                        className="btn-cancelar"
                        variant="outline-dark"
                        onClick={back}
                      >
                        Cancelar
                      </Button>{' '}
                    </Form.Group>
                  </Form.Row>
                </div>

                <div style={{ display: 'flex', alignSelf: 'right' }}>
                  <Form.Row className="container-buttons justify-content-end">
                    <Form.Group as={Col} md={2}>
                      <Button
                        style={{ width: 175, height: 50 }}
                        className="btn-enviar"
                        variant="outline-warning"
                        onClick={() => veiEditar(model.id)}
                      >
                        Editar
                      </Button>{' '}
                    </Form.Group>
                  </Form.Row>
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default VeiDetalhes;
