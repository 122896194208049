/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useToast } from '../../hooks/Toast';
import { Dialog } from 'primereact/dialog';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { OrgaoEmissorType } from '../../api/model/OrgaoEmissorType';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosTypes } from '../../api/Types/municipiosTypes';
import './styles.css';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import checaCPF from '../../util/checaCpf';
import mascaraCpf from '../../util/mascaraCpf';
import { maskTel, maskCep, soLetras, soNumeros } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { validaNome } from '../../util/Validacoes/Validacoes';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { validaNumero } from '../../util/Validacoes/Validacoes';
import { ProfilePicture } from '../../components/ProfilePicture';
import { dataConverter, obterDataHora, toZoned } from '../../util/date';
import { nowToZoned } from './../../util/date';

type Props = {
  canEdit: boolean;
  canDelete: boolean;
};

function AjudanteEditar({ canEdit, canDelete }: Props) {
  const history = useHistory();
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [rg, setRg] = useState('');
  const [dataEmissao, setDataEmissao] = useState<string | undefined>();
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState('');
  const [logradouroComplemento, setLogradouroComplemento] = useState('');
  const [cidade, setCidade] = useState(0);
  const [dataNascimento, setDataNascimento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidadeNascimento, setCidadeNascimento] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [referenciaNome, setReferenciaNome] = useState('');
  const [referenciaTelefone, setReferenciaTelefone] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefoneIsValid, setTelefoneIsValid] = useState<Boolean>(true);
  const [nextelContato, setNextelContato] = useState('');
  const [grauParentesco, setGrauParentesco] = useState('');
  const [atualizarPesquisa, setAtualizarPesquisa] = useState(false);
  const [ativo, setAtivo] = useState(true);
  const [tipo, setTipo] = useState();
  const [sexo, setSexo] = useState();
  const [orgaoEmissorId, setOrgaoEmissorId] = useState();
  const [idUfEmissor, setIdUfEmissor] = useState<number | undefined>();
  const [idUfEndereco, setIdUfEndereco] = useState<number | undefined>();
  const [idUfNascimento, setIdUfNascimento] = useState<number | undefined>();
  const [foto, setFoto] = useState('');
  const [error, setError] = useState(false);
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [isValid, setIsValid] = useState(true);
  const [erroDataEmissaoRg, setErroDataEmissaoRg] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const [dataCadastro, setDataCadastro] = useState('');
  const [statusApto, setStatusApto] = useState(true);
  const [isGrauParentValid, setIsGrauParentValid] = useState(true);
  const [isNomeValid, setIsNomeValid] = useState(true);
  const [isNomeMaeValid, setIsNomeMaeValid] = useState(true);
  const [isNomePaiValid, setIsNomePaiValid] = useState(true);

  const [municipiosPorEstado, setMunicipiosPorEstado] = useState<MunicipiosTypes[]>([]);
  const [cidadesNascimentoPorEstado, setCidadesNascimentoPorEstado] = useState<MunicipiosTypes[]>(
    []
  );
  const [ufSiglas, setUfSiglas] = useState<UfIbgeType[]>([]);

  const [dataUltimaAlteracao, setDataUltimaAlteracao] = useState('');
  const [idSM, setIdSM] = useState(0);
  const [nomeUfEmissor, setNomeUfEmissor] = useState('');
  const [nomeUfEndereco, setNomeUfEndereco] = useState('');
  const [nomeUfNascimento, setNomeUfNascimento] = useState('');
  const [qtdeViagens, setQtdeViagens] = useState(0);
  const [tempoCadastro, setTempoCadastro] = useState('');
  const [valorMedioTransportado, setValorMedioTransportado] = useState(0);
  const [bloqueado, setBloqueado] = useState(false);

  const [validacaoCpf, setValidacaoCpf] = useState(false);
  const [validacaoRg, setValidacaoRg] = useState(false);
  const [cpfMudou, setCpfMudou] = useState(false);
  const [rgMudou, setRgMudou] = useState(false);
  const { addToast } = useToast();
  const { id }: any = useParams();
  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  //lista de orgaos emissores vinda do banco de dados
  const [orgaoEmissor, setOrgaoEmissor] = useState<OrgaoEmissorType[]>([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validaTelefone = (telefone: string, setter: any) => {
    setter(telefone);
    if (telefone.length < 13) {
      setTelefoneIsValid(false);
    } else {
      setTelefoneIsValid(true);
    }
  };

  useEffect(() => {
    if (cpf.length === 14) {
      const validaCpfExistente = async () => {
        const data = {
          cpfAjudante: cpf,
        };
        try {
          const response = await axios.post(`/ajudante-funcionario/existeCPF/${clienteId}`, data);
          setValidacaoCpf(response.data);
          if (cpfMudou) {
            if (response.data === true) {
              addToast({
                title: 'Erro',
                description: 'O CPF desse ajudante já existe!',
                type: 'error',
              });
              return;
            } else {
              setCpf(cpf);
            }
          } else {
            return;
          }
        } catch (err: any) {
          console.log(err);
        }
      };
      validaCpfExistente();
    }
  }, [cpf]);

  //@ts-ignore
  const ref = useRef<boolean | any>(null);
  //@ts-ignore
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (rgMudou) {
        validaRgExistente();
      }
    }
  };

  useEffect(() => {
    //@ts-ignore
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      //@ts-ignore
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const validaRgExistente = async () => {
    const data = {
      rgAjudante: rg,
    };
    try {
      const response = await axios.post(`/ajudante-funcionario/existeRG/${clienteId}`, data);
      setValidacaoRg(response.data);
      if (rgMudou) {
        if (response.data === true) {
          // const alerta = alert('O RG deste ajudante já está cadastrado');
          addToast({
            title: 'Erro',
            description: 'O RG desse ajudante já existe!',
            type: 'error',
          });
          return;
        } else {
          setRg(rg);
        }
      } else {
        return;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/ajudante-funcionario/${id}`);
      setCpf(response.data.cpf);
      setFoto(response.data.foto);
      setNome(response.data.nome);
      setRg(response.data.rg);
      setDataEmissao(response.data.dataEmissao);
      setCep(response.data.cep);
      setLogradouro(response.data.logradouro);
      setLogradouroNumero(response.data.logradouroNumero);
      setLogradouroComplemento(response.data.logradouroComplemento);
      setCidade(response.data.cidade);
      setDataNascimento(response.data.dataNascimento);
      setBairro(response.data.bairro);
      setCidadeNascimento(response.data.cidadeNascimento);
      setNomeMae(response.data.nomeMae);
      setNomePai(response.data.nomePai);
      setReferenciaNome(response.data.referenciaNome);
      setReferenciaTelefone(maskTel(response.data.referenciaTelefone));
      setTelefone(response.data.telefone);
      setNextelContato(maskTel(response.data.nextelContato));
      setGrauParentesco(response.data.grauParentesco);
      setAtualizarPesquisa(response.data.atualizarPesquisa);
      setAtivo(response.data.ativo);
      setTipo(response.data.tipo);
      setSexo(response.data.sexo);
      setIdUfEmissor(response.data.idUfEmissor);
      setIdUfEndereco(response.data.idUfEndereco);
      loadCidadesNascimentoPorEstado(response.data.idUfNascimento);
      setIdUfNascimento(response.data.idUfNascimento);
      setOrgaoEmissorId(response.data.idOrgaoEmissor);
      {
        response.data.dataCadastro
          ? setDataCadastro(response.data.dataCadastro)
          : setDataCadastro('');
      }
      setDataUltimaAlteracao(response.data.dataUltimaAlteracao);
      setIdSM(response.data.idSM);
      setNomeUfEmissor(response.data.nomeUfEmissor);
      setNomeUfEndereco(response.data.nomeUfEndereco);
      setNomeUfNascimento(response.data.nomeUfNascimento);
      setQtdeViagens(response.data.qtdeViagens);
      setTempoCadastro(response.data.tempoCadastro);
      setValorMedioTransportado(response.data.valorMedioTransportado);
      setBloqueado(response.data.bloqueado);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/orgao-emissor/getIdNome');
      setOrgaoEmissor(response.data);
    };
    fetchData();
  }, []);

  const buscarCep = () => {
    if (cep.length < 9) {
      setCidade(0);
      setLogradouro('');
      setBairro('');
      return;
    }
    setError(false);
    fetch(`https://viacep.com.br/ws/${cep}/json`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          setError(true);
          return;
        }
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
        setCidade(parseInt(data.ibge));
        setViaCepCidade(data.localidade);
        setIdUfEndereco(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
        loadMunicipiosPorEstado(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
        const codIbgeFloat = data.ibge / 100000;
        const codIbgeFormatado = Math.trunc(codIbgeFloat);
        setViaCepIbgeCod(codIbgeFormatado);
      })
      .catch((erro) => {
        console.log('error', erro);
        setError(true);
      });
  };

  useEffect(() => {
    axios.get('/uf/ListarIdSiglaEstadoPais/1').then((response: any) => {
      setUfSiglas(response.data);
    });
    idUfEndereco && loadMunicipiosPorEstado(idUfEndereco);
  }, [idUfEndereco]);

  const loadMunicipiosPorEstado = async (estado?: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadCidadesNascimentoPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setCidadesNascimentoPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const handleEdit = async (event: any) => {
    event.preventDefault();
    if (
      !cpf ||
      !nome ||
      !sexo ||
      !rg ||
      // !orgaoEmissorId ||
      !idUfEmissor ||
      !cep ||
      !logradouro ||
      !bairro ||
      !tipo ||
      !cidade ||
      !telefone ||
      !telefoneIsValid ||
      (nextelContato && nextelContato.length < 13) ||
      (referenciaTelefone && referenciaTelefone.length < 13) ||
      logradouroNumero === null ||
      !idUfEndereco ||
      !dataNascimento ||
      !idUfNascimento ||
      !cidadeNascimento ||
      !nomePai ||
      !nomeMae ||
      !isValid ||
      !isNomePaiValid ||
      !isNomeValid ||
      !isNomeMaeValid ||
      erroDataNascimento
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      // alert('Verifique os dados preenchidos.');
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (validacaoRg === true && rgMudou) {
      // alert('O Rg desse ajudante já existe.');
      addToast({
        title: 'Erro',
        description: 'O RG deste ajudante já existe!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    if (validacaoCpf === true && cpfMudou) {
      // alert('O Cpf desse ajudante já existe.');
      addToast({
        title: 'Erro',
        description: 'O CPF deste ajudante já existe!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }
    console.log('put');

    const data = {
      cpf: cpf,
      nome: nome,
      tipo: tipo,
      sexo: sexo,
      rg: rg,
      idOrgaoEmissor: orgaoEmissorId,
      idUfEmissor: idUfEmissor,
      dataEmissao: dataEmissao,
      cep: cep,
      logradouro: logradouro,
      logradouroNumero: logradouroNumero,
      logradouroComplemento: logradouroComplemento,
      cidade: cidade,
      idUfEndereco: idUfEndereco,
      dataNascimento: dataNascimento,
      bairro: bairro,
      cidadeNascimento: cidadeNascimento,
      nomeMae: nomeMae,
      nomePai: nomePai,
      referenciaNome: referenciaNome,
      referenciaTelefone: soNumeros(referenciaTelefone),
      telefone: soNumeros(telefone),
      nextelContato: soNumeros(nextelContato),
      grauParentesco: grauParentesco,
      atualizarPesquisa: atualizarPesquisa,
      ativo: ativo,
      idUfNascimento: idUfNascimento,
      foto: foto,
      bloqueado: bloqueado,
      dataCadastro: dataCadastro,
      dataUltimaAlteracao: nowToZoned(),
      idSM: idSM,
      nomeUfEmissor: nomeUfEmissor,
      nomeUfEndereco: nomeUfEndereco,
      nomeUfNascimento: nomeUfNascimento,
      qtdeViagens: qtdeViagens,
      tempoCadastro: tempoCadastro,
      valorMedioTransportado: valorMedioTransportado,
      clienteId: {
        id: clienteId,
      },
    };

    try {
      await axios.put(`/ajudante-funcionario/${id}`, data);
      setIsDialogCadOK(true);
    } catch {
      // const alerta = alert('Erro ao editar');
      addToast({
        title: 'Erro',
        description: 'Erro ao editar!',
        type: 'error',
      });
      return;
    }
  };

  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);

  const handleDelete = async (id: number) => {
    const dataAtivo = {
      cpf: cpf,
      nome: nome,
      tipo: tipo,
      sexo: sexo,
      rg: rg,
      idOrgaoEmissor: orgaoEmissorId,
      idUfEmissor: idUfEmissor,
      dataEmissao: dataEmissao,
      cep: cep,
      logradouro: logradouro,
      logradouroNumero: logradouroNumero,
      logradouroComplemento: logradouroComplemento,
      cidade: cidade,
      idUfEndereco: idUfEndereco,
      dataNascimento: dataNascimento,
      bairro: bairro,
      cidadeNascimento: cidadeNascimento,
      nomeMae: nomeMae,
      nomePai: nomePai,
      referenciaNome: referenciaNome,
      referenciaTelefone: referenciaTelefone,
      telefone: telefone,
      nextelContato: nextelContato,
      grauParentesco: grauParentesco,
      atualizarPesquisa,
      ativo: false,
      idUfNascimento: idUfNascimento,
      foto: foto,
      bloqueado: bloqueado,
      clienteId: {
        id: clienteId,
      },
    };

    try {
      await axios.put(`/ajudante-funcionario/${id}`, dataAtivo);
      setExclusaoOK(true);

      setTimeout(function () {
        history.goBack();
      }, 1500);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      setMensagemErro(err.response.data.message);
    }
  };

  const dataLimiteMaiorIdade = () => {
    const current = new Date();
    const maxDate = new Date(
      current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
    );
    return maxDate;
  };

  const maxDateFormatadoMaior18 = () => {
    const current = new Date();
    const maxDate = new Date(
      current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
    );
    const maxDateFormatado =
      maxDate.getFullYear().toString() +
      '-' +
      (maxDate.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      maxDate.getDate().toString().padStart(2, '0');
    return maxDateFormatado;
  };

  const validaDataNascimento = (value: any) => {
    const nasc = new Date(value);
    if (nasc.getTime() < dataLimiteMaiorIdade().getTime()) {
      setDataNascimento(value);
      setErroDataNascimento(false);
    } else {
      setDataNascimento(value);
      setErroDataNascimento(true);
    }
  };

  const validaDataMin = (value: any) => {
    const dataAtual = new Date();
    if (dataAtual.getTime() <= new Date(value).getTime()) {
      setDataEmissao(value);
      setErroDataEmissaoRg(true);
    } else {
      setDataEmissao(value);
      setErroDataEmissaoRg(false);
    }
  };

  const validaDataAtualRegistrado = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime());
    const maxDateFormatado =
      maxDate.getFullYear().toString() +
      '-' +
      (maxDate.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      maxDate.getDate().toString().padStart(2, '0');
    return maxDateFormatado;
  };

  const validaDataMaxRG = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime() - 1000 * 60 * 60 * 24 * 365 * 100);
    const maxDateFormatado =
      maxDate.getFullYear().toString() +
      '-' +
      (maxDate.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      maxDate.getDate().toString().padStart(2, '0');
    return maxDateFormatado;
  };

  // useEffect(() => {
  //   if (foto && newFoto === false) {
  //     axios.get(`/foto/downloadFile/${foto}`).then((response) => {
  //       setLink(response.request.responseURL);
  //     });
  //   }
  // }, [foto]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Ajudante alterado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.goBack();
        }}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro "{`${nome}`}"?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Ajudante "{`${nome}`}" excluído com sucesso.
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{`${mensagemErro}`}</Modal.Body>
        )}

        {!exclusaoErro && !exclusaoErro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Ajudantes</h2>
          <h6 className="subtitulo">{'Cadastro > Ajudantes > Editar'}</h6>
          <button
            className="btn-desativar"
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              position: 'absolute',
              right: '2%',
            }}
            onClick={() => {
              if (canDelete) {
                setExclusaoErro(false);
                setExclusaoOK(false);
                handleShow();
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
          </button>
        </div>
        <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
        <div className="painel">
          <Form className="form-sm" noValidate validated={isFormInvalid} onSubmit={handleEdit}>
            <ProfilePicture setFoto={setFoto} foto={foto} name={cpf}>
              <Form.Row>
                <Form.Group>
                  <Form.Check
                    checked={atualizarPesquisa}
                    onChange={(event) => {
                      setAtualizarPesquisa(!atualizarPesquisa);
                    }}
                    name="atualizarPesquisa"
                    type="switch"
                    id="custom-switch1"
                    label="Atualizar Pesquisa automaticamente"
                  />
                </Form.Group>

                {isAllowedByRole(['admin']) && (
                  <Form.Group style={{ marginLeft: '29%' }}>
                    <Form.Check
                      checked={bloqueado}
                      onChange={(event) => {
                        setBloqueado(!bloqueado);
                      }}
                      name="bloqueado"
                      type="switch"
                      id="custom-switch2"
                      label="Ajudante/Funcionário Bloqueado"
                    />
                  </Form.Group>
                )}
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>DATA CADASTRO</Form.Label>
                  <Form.Control
                    value={obterDataHora(dataCadastro)}
                    id="dataCadastro"
                    name="dataCadastro"
                    disabled
                  />
                </Form.Group>
              </Form.Row>
            </ProfilePicture>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">CPF</Form.Label>
                <Form.Control
                  value={cpf}
                  onChange={(event) => {
                    if (checaCPF(event.target.value) === false) {
                      setCpf(event.target.value);
                      setIsValid(false);
                      setCpfMudou(false);
                    } else {
                      setCpf(mascaraCpf(event.target.value));
                      setCpfMudou(true);
                      setIsValid(true);
                    }
                  }}
                  name="cpf"
                  type="text"
                  placeholder=""
                  required
                  pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
                  title="Cpf inválido"
                  maxLength={14}
                  minLength={14}
                  disabled
                />
                {!isValid && cpf.length > 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Cpf inválido</p>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome</Form.Label>
                <Form.Control
                  value={nome}
                  onChange={(event) => {
                    event.preventDefault();
                    setNome(soLetras(event.target.value));
                    if (validaNome(soLetras(event.target.value))) {
                      setIsNomeValid(true);
                    } else {
                      setIsNomeValid(false);
                    }
                  }}
                  name="nome"
                  maxLength={256}
                  placeholder=""
                  required
                  type="text"
                  title="Preencha com dois nomes"
                />
                {!isNomeValid && nome.length > 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Nome inválido, inserir nome e sobrenome.
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="requiredField">Tipo</Form.Label>
                <Form.Control
                  value={tipo}
                  onChange={(event: any) => {
                    setTipo(event.target.value);
                  }}
                  id="tipo"
                  as="select"
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">Agregado</option>
                  <option value="2">Autônomo</option>
                  <option value="3">Funcionário</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Sexo</Form.Label>
                <Form.Control
                  value={sexo}
                  onChange={(event: any) => {
                    setSexo(event.target.value);
                  }}
                  id="sexo"
                  as="select"
                >
                  <option defaultValue="">Selecione uma opção</option>
                  <option value="F">Feminino</option>
                  <option value="M">Masculino</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">RG</Form.Label>
                <Form.Control
                  value={rg}
                  onChange={(event) => {
                    setRg(event.target.value);
                    setRgMudou(true);
                  }}
                  id="rg"
                  name="rg"
                  placeholder=""
                  required
                  type="text"
                  maxLength={15}
                  ref={ref}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Orgão Emissor</Form.Label>
                <Form.Control
                  value={orgaoEmissorId}
                  onChange={(event: any) => {
                    setOrgaoEmissorId(event.target.value);
                  }}
                  as="select"
                >
                  <option defaultValue="">Selecione uma opção</option>
                  {orgaoEmissor.map((oE, index) => (
                    <option value={oE.id} key={index}>
                      {oE.nome}
                    </option>
                  ))}
                  <option value={orgaoEmissorId}>{orgaoEmissorId}</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">UF Emissor</Form.Label>
                <Form.Control
                  value={idUfEmissor}
                  onChange={(event: any) => {
                    setIdUfEmissor(parseInt(event.target.value));
                  }}
                  as="select"
                  required
                >
                  <option value="">Selecione uma opção</option>
                  {ufSiglas.map((uf, index) => (
                    <option value={uf.id} key={index}>
                      {uf.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label style={{ marginRight: '8px' }}>Data Emissão</Form.Label>
                <Form.Control
                  onChange={(event: any) => validaDataMin(event.target.value)}
                  id="dataEmissao"
                  name="dataEmissao"
                  type="date"
                  min={validaDataMaxRG()}
                  max={validaDataAtualRegistrado()}
                  required
                  value={dataEmissao || undefined}
                />
                {erroDataEmissaoRg && (
                  <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                    Data de Emissão não pode ser maior do que a data atual
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            {/* {foto && newFoto === false ? (
              <>
                <a style={{ textDecoration: 'none' }} href={link}>
                  Clique para fazer o download da foto do(a) Ajudante/Funcionário(a) {nome}
                </a>
                <Button
                  style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                  title="Editar Foto"
                  onClick={() => {
                    setNewFoto(true);
                  }}
                >
                  <FiEdit size={22} color="black" />
                </Button>
              </>
            ) : (
              <Content>
                <FileProvider done={wasEdited} foto={foto} setFoto={setFoto}>
                  <Upload />
                  <FileList />
                </FileProvider>
              </Content>
            )} */}

            <Form.Row>
              <Form.Group as={Col} md={6} className="mt-2 ml-2">
                <Form.Label>Status</Form.Label>
                <Form.Check
                  checked={statusApto}
                  onChange={() => {
                    setStatusApto(!statusApto);
                  }}
                  name="statusApto"
                  type="switch"
                  id="custom-switch2"
                  label="Apto para viagem"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
                <hr />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="requiredField">CEP</Form.Label>
                <Form.Control
                  value={cep}
                  onChange={(event) => {
                    setCep(maskCep(event.target.value));
                  }}
                  onBlur={() => buscarCep()}
                  id="cep"
                  name="cep"
                  type="text"
                  required
                  style={{ width: '40%' }}
                  maxLength={9}
                  minLength={9}
                />
                {error && <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">UF</Form.Label>
                <Form.Control
                  required
                  value={idUfEndereco}
                  onChange={(event: any) => {
                    setIdUfEndereco(parseInt(event.target.value));
                    loadMunicipiosPorEstado(parseInt(event.target.value));
                  }}
                  as="select"
                >
                  <option value="">Selecione uma opção</option>
                  {ufSiglas.map((uf, index) => (
                    <option value={uf.id} key={index}>
                      {uf.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Cidade</Form.Label>
                <Form.Control
                  value={cidade}
                  onChange={(event: any) => {
                    setCidade(event.target.value);
                  }}
                  id="cidade"
                  name="cidade"
                  required
                  as="select"
                  style={{ width: '100%' }}
                >
                  <option value="">Selecione uma opção</option>
                  {municipiosPorEstado.map((filteredMunicipio, index) => (
                    <option key={index} value={filteredMunicipio.id}>
                      {filteredMunicipio.nomeMunicipio}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Bairro</Form.Label>
                <Form.Control
                  value={bairro}
                  onChange={(event) => {
                    setBairro(event.target.value);
                  }}
                  name="bairro"
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Logradouro</Form.Label>
                <Form.Control
                  value={logradouro}
                  onChange={(event) => {
                    setLogradouro(event.target.value);
                  }}
                  name="logradouro"
                  type="text"
                  required
                  maxLength={64}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Número Logradouro</Form.Label>
                <Form.Control
                  value={logradouroNumero}
                  onChange={(e) => {
                    if (validaNumero(e.target.value) || e.target.value.length === 0) {
                      setLogradouroNumero(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  maxLength={8}
                  id="logradouroNumero"
                  name="logradouroNumero"
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  value={logradouroComplemento}
                  onChange={(event) => {
                    setLogradouroComplemento(event.target.value);
                  }}
                  id="logradouroComplemento"
                  name="logradouroComplemento"
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
                <hr />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="requiredField">Data Nascimento</Form.Label>

                <Form.Control
                  onChange={(event: any) => validaDataNascimento(event.target.value)}
                  id="dataNascimento"
                  name="dataNascimento"
                  type="date"
                  required
                  max={maxDateFormatadoMaior18()}
                  min={validaDataMaxRG()}
                  style={{ width: '42%' }}
                  value={dataNascimento}
                />
                {erroDataNascimento && (
                  <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                    Data de Nascimento não pode ser maior do que a data atual
                  </p>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">UF</Form.Label>
                <Form.Control
                  required
                  value={idUfNascimento}
                  onChange={(event: any) => {
                    setIdUfNascimento(parseInt(event.target.value));
                    loadCidadesNascimentoPorEstado(parseInt(event.target.value));
                  }}
                  as="select"
                >
                  {ufSiglas.map((uf, index) => (
                    <option value={uf.id} key={index}>
                      {uf.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Cidade Nascimento</Form.Label>
                <Form.Control
                  as="select"
                  value={cidadeNascimento}
                  name="cidadeNascimento"
                  onChange={(event: any) => setCidadeNascimento(event.target.value)}
                  required
                >
                  <option value="">Selecione uma opção</option>
                  {cidadesNascimentoPorEstado.map((municipio, index) => (
                    <option key={index} value={municipio.id}>
                      {municipio.nomeMunicipio}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome Mãe</Form.Label>
                <Form.Control
                  value={nomeMae}
                  onChange={(event) => {
                    event.preventDefault();
                    setNomeMae(soLetras(event.target.value));
                    if (validaNome(soLetras(event.target.value))) {
                      setIsNomeMaeValid(true);
                    } else {
                      setIsNomeMaeValid(false);
                    }
                  }}
                  id="nomeMae"
                  maxLength={256}
                  name="nomeMae"
                  type="text"
                  required
                  title="Preencha com dois nomes"
                />{' '}
                {!isNomeMaeValid && nomeMae.length > 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Nome inválido, inserir nome e sobrenome.
                  </p>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome Pai</Form.Label>
                <Form.Control
                  value={nomePai}
                  onChange={(event) => {
                    event.preventDefault();
                    setNomePai(soLetras(event.target.value));
                    if (validaNome(soLetras(event.target.value))) {
                      setIsNomePaiValid(true);
                    } else {
                      setIsNomePaiValid(false);
                    }
                  }}
                  id="nomePai"
                  name="nomePai"
                  maxLength={256}
                  type="text"
                  title="Preencha com dois nomes"
                  required
                />
                {!isNomePaiValid && nomePai.length > 1 && (
                  <>
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Nome inválido, inserir nome e sobrenome. Caso não seja declarado, preencher
                      como "Não Declarado".
                    </p>
                    {/* <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Caso não seja declarado, preencher como "Não Declarado".
                    </p> */}
                  </>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
                <hr />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Telefone</Form.Label>
                <Form.Control
                  value={maskTel(telefone)}
                  onChange={(event) => {
                    validaTelefone(maskTel(event.target.value), setTelefone);
                  }}
                  id="telefone"
                  name="telefone"
                  type="text"
                  required
                  isInvalid={telefoneIsValid == false}
                  maxLength={14}
                  minLength={13}
                />
                {telefone.length > 0 && !telefoneIsValid && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Telefone deve ter entre 10 e 11 números.
                  </p>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Segundo Telefone</Form.Label>
                <Form.Control
                  value={maskTel(nextelContato)}
                  onChange={(event) => {
                    setNextelContato(maskTel(event.target.value));
                  }}
                  id="nextelContato"
                  name="nextelContato"
                  type="text"
                  maxLength={14}
                  minLength={13}
                />
                {nextelContato && nextelContato.length < 13 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Telefone deve ter entre 10 e 11 números.
                  </p>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="ml-1">
                <Form.Label>Referência Telefone</Form.Label>
                <Form.Control
                  value={maskTel(referenciaTelefone)}
                  onChange={(event) => {
                    setReferenciaTelefone(maskTel(event.target.value));
                  }}
                  id="referenciaTelefone"
                  name="referenciaTelefone"
                  type="text"
                  title="Telefone inválido"
                  maxLength={14}
                  minLength={13}
                />
                {referenciaTelefone && referenciaTelefone.length < 13 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Telefone deve ter entre 10 e 11 números.
                  </p>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Nome Referência</Form.Label>
                <Form.Control
                  value={referenciaNome}
                  onChange={(event) => {
                    setReferenciaNome(soLetras(event.target.value));
                  }}
                  id="referenciaNome"
                  maxLength={64}
                  name="referenciaNome"
                  type="text"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={6} className="ml-1">
                <Form.Label>Grau Parentesco</Form.Label>
                <Form.Control
                  value={grauParentesco}
                  onChange={(event) => {
                    event.preventDefault();
                    setGrauParentesco(soLetras(event.target.value));
                    if (soLetras(event.target.value)) {
                      setIsGrauParentValid(true);
                    } else {
                      setIsGrauParentValid(false);
                    }
                  }}
                  maxLength={32}
                  id="grauParentesco"
                  name="grauParentesco"
                  type="text"
                />
                {!isGrauParentValid && grauParentesco.length >= 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Grau de Parentesco inválido
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
                <hr />
              </Form.Group>
            </Form.Row>

            <ButtonsForm canEdit={canEdit} />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AjudanteEditar;
