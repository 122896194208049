const getTipoBau = (tipo: string | undefined): string => {
  if (tipo === '1') {
    return 'Próprio';
  }
  if (tipo === '2') {
    return 'Agregado';
  }
  if (tipo === '3') {
    return 'Terceiro';
  }
  return '';
};
export default getTipoBau;
