/* eslint-disable */
import { useState, ChangeEvent, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Switch from 'react-bootstrap/esm/Switch';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import api from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import Input from '../Input';
import Select from '../Select';
import { useToast } from '../../hooks/Toast';
import veiculoValidation from './veiculoValidation';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import { useHistory, useParams } from 'react-router-dom';

type VeiculosPlaca = {
  id: number;
  placa: string;
};

type Ufs = {
  id: number;
  sigla: string;
};

type VeiculosTipos = {
  vtpCdId: number;
  vtpTxNome: string;
};

type Proprietario = {
  id: number;
  razaoSocial: string;
};

type Veiculo = {
  anttRntrc: string;
  atualizacaoAutomatica: boolean;
  chassi: string;
  bloqueado: boolean;
  emplacamentoUf: number;
  id: number;
  placa: string;
  proprietarioId: number;
  renavam: string;
  veiculoTipo: number;
  veiculoTipoNome: string;
  tipoFrota?: string | null;
  modelo: string;
};

type InputErrors = {
  veiculo: string;
  carreta1: string;
  carreta2: string;
  placa: string;
  modelo: string;
  proprietarioId: string;
  renavam: string;
  chassi: string;
  veiculoTipo: string;
  anttRntrc: string;
  emplacamentoUf: string;
  atualizacaoAutomatica: string;
};

export const ContainerPesquisarVeiculos = forwardRef((pros, ref) => {
  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;

  const [listaVeiculos, setListaVeiculos] = useState<VeiculosPlaca[]>([]);
  const [listaCarretas, setListaCarretas] = useState<VeiculosPlaca[]>([]);
  const [listaUfs, setListaUfs] = useState<Ufs[]>([]);
  const [listaVeiculosTipo, setListaVeiculosTipo] = useState<VeiculosTipos[]>([]);
  const [listaCarretasTipo, setListaCarretasTipo] = useState<VeiculosTipos[]>([]);
  const [listaProprietarios, setListaProprietarios] = useState<Proprietario[]>([]);

  const [veiculo, setVeiculo] = useState<Veiculo | undefined>();
  const [carreta1, setCarreta1] = useState<Veiculo | undefined>();
  const [carreta2, setCarreta2] = useState<Veiculo | undefined>();

  const [isDialogCadOKVeiculo, setIsDialogCadOKVeiculo] = useState(false);

  const [veiculoInputErrors, setVeiculoInputErrors] = useState({} as InputErrors);
  const [carreta1InputErrors, setCarreta1InputErrors] = useState({} as InputErrors);
  const [carreta2InputErrors, setCarreta2InputErrors] = useState({} as InputErrors);

  const [particularidades, setParticularidades] = useState<any>([]);

  const { addToast } = useToast();
  const { tabAtual } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    axios
      .all([
        api.get('/tipo-veiculo'),
        api.get('/tipo-veiculo/carreta'),
        api.get('/uf/ListarIdSiglaEstadoPais'),
        api.get(`/proprietario/cliente/${clienteId}`),
        api.get(`/veiculo/listarIdEPlaca`, {
          params: {
            cliente: clienteId,
            grupoMacroVeículo: 1,
          },
        }),
        api.get(`/veiculo/listarIdEPlaca`, {
          params: {
            cliente: clienteId,
            grupoMacroVeículo: 2,
          },
        }),
      ])
      .then(
        axios.spread(
          (
            tipoVeiculoResponse,
            tipoCarretaResponse,
            ufSiglasResponse,
            proprietariosResponse,
            idsEPlacasVeiculosResponse,
            idsEPlacasCarretasResponse
          ) => {
            setListaVeiculosTipo(tipoVeiculoResponse.data);
            setListaCarretasTipo(tipoCarretaResponse.data);
            setListaUfs(ufSiglasResponse.data);
            setListaProprietarios(proprietariosResponse.data);
            setListaVeiculos(idsEPlacasVeiculosResponse.data);
            setListaCarretas(idsEPlacasCarretasResponse.data);
          }
        )
      )
      .catch((err) => {
        console.log(err);
      });
  }, [clienteId, veiculo]);

  const toggleSwitchAtualizacaoAutomatica = (type: string) => {
    switch (type) {
      case 'veiculo':
        if (!veiculo) {
          return;
        }
        setVeiculo({
          ...veiculo,
          atualizacaoAutomatica: !veiculo.atualizacaoAutomatica,
        });
        break;
      case 'carreta1':
        if (!carreta1) {
          return;
        }
        setCarreta1({
          ...carreta1,
          atualizacaoAutomatica: !carreta1.atualizacaoAutomatica,
        });
        break;
      case 'carreta2':
        if (!carreta2) {
          return;
        }
        setCarreta2({
          ...carreta2,
          atualizacaoAutomatica: !carreta2.atualizacaoAutomatica,
        });
        break;
      default:
        break;
    }
  };

  function updateVeiculo(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!veiculo) {
      return;
    }

    setVeiculo({
      ...veiculo,
      [e.target.name]: e.target.value,
    });
  }

  function updateCarreta1(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!carreta1) {
      return;
    }

    setCarreta1({
      ...carreta1,
      [e.target.name]: e.target.value,
    });
    console.log(carreta1);
  }

  function updateCarreta2(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!carreta2) {
      return;
    }

    setCarreta2({
      ...carreta2,
      [e.target.name]: e.target.value,
    });
  }

  const loadDadosVeiculo = async (idVeiculo: number) => {
    if (!idVeiculo) {
      if (tabAtual !== 'condutor-veiculo-carreta') {
        history.replace(`/enviar-pesquisa/veiculo`);
      }
      setVeiculo(undefined);
      return;
    }

    if (tabAtual !== 'condutor-veiculo-carreta') {
      history.replace(`/enviar-pesquisa/veiculo/${idVeiculo}`);
    }

    try {
      const response = await api.get(`/veiculo/${idVeiculo}`);

      setVeiculo({
        ...response.data,
        atualizarPesquisaAutomaticamente:
          response.data.atualizarPesquisaAutomaticamente === null
            ? false
            : response.data.atualizarPesquisaAutomaticamente,
      });
      console.log(response.data);
      if (response.data.statusCritica === 'CRITICA') {
        if (response.data.statusValidadePesquisa === 'SEM PESQUISA') {
          addToast({ title: 'Atencão:', description: 'A Carreta não possui pesquisa' });
        } else if (response.data.statusValidadePesquisa === 'VENCIDO') {
          addToast({ title: 'Atencão:', description: 'A Carreta está pesquisa vencida' });
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const loadDadosCarreta1 = async (idVeiculo: number) => {
    if (!idVeiculo) {
      if (tabAtual !== 'condutor-veiculo-carreta') {
        history.replace(`/enviar-pesquisa/carreta`);
      }
      setCarreta1(undefined);
      setCarreta2(undefined);
      return;
    }

    if (tabAtual !== 'condutor-veiculo-carreta') {
      history.replace(`/enviar-pesquisa/carreta/${idVeiculo}`);
    }

    if (carreta2 && idVeiculo === carreta2.id) {
      setCarreta2(undefined);
      return;
    }
    try {
      const response = await api.get(`/veiculo/${idVeiculo}`);

      setCarreta1(response.data);

      console.log(response.data);

      if (response.data.statusCritica === 'CRITICA') {
        if (response.data.statusValidadePesquisa === 'SEM PESQUISA') {
          addToast({ title: 'Atencão:', description: 'A Carreta não possui pesquisa' });
        } else if (response.data.statusValidadePesquisa === 'VENCIDO') {
          addToast({ title: 'Atencão:', description: 'A Carreta está pesquisa vencida' });
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const loadDadosCarreta2 = async (idVeiculo: number) => {
    if (!idVeiculo) {
      setCarreta2(undefined);
      return;
    }
    try {
      const response = await api.get(`/veiculo/${idVeiculo}`);

      setCarreta2(response.data);

      if (response.data.statusCritica === 'CRITICA') {
        if (response.data.statusValidadePesquisa === 'SEM PESQUISA') {
          addToast({ title: 'Atencão:', description: 'A Carreta não possui pesquisa' });
        } else if (response.data.statusValidadePesquisa === 'VENCIDO') {
          addToast({ title: 'Atencão:', description: 'A Carreta está pesquisa vencida' });
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const formattedDataVeiculo = {
    anttRntrc: veiculo?.anttRntrc,
    atualizacaoAutomatica: veiculo?.atualizacaoAutomatica,
    chassi: veiculo?.chassi,
    bloqueado: veiculo?.bloqueado,
    emplacamentoUf: veiculo?.emplacamentoUf,
    id: veiculo?.id,
    placa: veiculo?.placa,
    proprietarioId: veiculo?.proprietarioId,
    renavam: veiculo?.renavam,
    tipoEnum: veiculo?.tipoFrota && getTipoFrota(veiculo?.tipoFrota),
    veiculoTipo: veiculo?.veiculoTipo,
    modelo: veiculo?.modelo,
  };

  const formattedDataCarreta1 = {
    anttRntrc: carreta1?.anttRntrc,
    atualizacaoAutomatica: carreta1?.atualizacaoAutomatica,
    chassi: carreta1?.chassi,
    emplacamentoUf: carreta1?.emplacamentoUf,
    id: carreta1?.id,
    placa: carreta1?.placa,
    proprietarioId: carreta1?.proprietarioId,
    renavam: carreta1?.renavam,
    tipoEnum: carreta1?.tipoFrota && getTipoFrota(carreta1?.tipoFrota),
    veiculoTipo: carreta1?.veiculoTipo,
  };

  const formattedDataCarreta2 = {
    anttRntrc: carreta2?.anttRntrc,
    atualizacaoAutomatica: carreta2?.atualizacaoAutomatica,
    chassi: carreta2?.chassi,
    emplacamentoUf: carreta2?.emplacamentoUf,
    id: carreta2?.id,
    placa: carreta2?.placa,
    proprietarioId: carreta2?.proprietarioId,
    renavam: carreta2?.renavam,
    tipoEnum: carreta2?.tipoFrota && getTipoFrota(carreta2?.tipoFrota),
    veiculoTipo: carreta2?.veiculoTipo,
  };

  useImperativeHandle(
    ref,
    () => ({
      validation() {
        const isVeiculoValid = veiculoValidation({ veiculo, setVeiculoInputErrors });
        const isCarreta1Valid = veiculoValidation({
          veiculo: carreta1,
          setVeiculoInputErrors: setCarreta1InputErrors,
        });
        const isCarreta2Valid = veiculoValidation({
          veiculo: carreta2,
          setVeiculoInputErrors: setCarreta2InputErrors,
        });
        if (!isVeiculoValid || !isCarreta1Valid || !isCarreta2Valid) {
          return false;
        }
        return true;
      },
      veiculo: veiculo?.id ? formattedDataVeiculo : null,
      carreta1: carreta1 ? formattedDataCarreta1 : null,
      carreta2: carreta2 ? formattedDataCarreta2 : null,
    }),
    [addToast, carreta1, carreta2, veiculo]
  );

  useEffect(() => {
    if (!veiculo) {
      setVeiculoInputErrors({} as InputErrors);
    }
    if (!carreta1) {
      setCarreta1InputErrors({} as InputErrors);
    }
    if (!carreta2) {
      setCarreta2InputErrors({} as InputErrors);
    }
  }, [carreta1, carreta2, veiculo]);

  return (
    <>
      <Form>
        {tabAtual === 'veiculo' ? (
          <>
            <h2 className="titulo">Dados da Pesquisa de Veículos</h2>
            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa:</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      history.replace(`/enviar-pesquisa/veiculo`);
                      setVeiculo(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={veiculo ? veiculo.id : ''}
                  options={listaVeiculos}
                  onChange={(e) => {
                    loadDadosVeiculo(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo,
                      setVeiculoInputErrors,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{ width: '100%' }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={veiculoInputErrors.renavam}
                minLength={8}
                maxLength={11}
              />
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={veiculoInputErrors.chassi}
                maxLength={17}
              />
              <Select
                required
                disabled={!veiculo}
                name="emplacamentoUf"
                value={veiculo ? veiculo.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                textInputTitle="UF Emplacamento:"
                error={veiculoInputErrors.emplacamentoUf}
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!veiculo}
                name="proprietarioId"
                required
                value={veiculo ? veiculo.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                textInputTitle="Proprietário:"
                error={veiculoInputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!veiculo}
                required={particularidades.flagHabilitarPesquisaAnttRntrc ? true : false}
                value={veiculo ? veiculo.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={veiculoInputErrors.anttRntrc}
                maxLength={14}
              />
              <Select
                disabled={!veiculo}
                name="tipoFrota"
                required
                value={veiculo?.tipoFrota ? veiculo.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                textInputTitle="Tipo:"
                error={veiculoInputErrors.veiculoTipo}
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!veiculo}
                  value={veiculo?.atualizacaoAutomatica ? 1 : 0}
                  checked={veiculo ? veiculo?.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('veiculo')}
                  name="atualizacaoAutomaticaVeiculo"
                  id="pesquisaVeiculo"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>
          </>
        ) : null}

        {tabAtual === 'carreta' ? (
          <>
            <h2 className="titulo">Dados da Pesquisa de Carreta</h2>
            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa Carreta (1):</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      history.replace(`/enviar-pesquisa/carreta`);
                      setCarreta1(undefined);
                      setCarreta2(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={carreta1 ? carreta1.id : ''}
                  options={listaCarretas}
                  onChange={(e) => {
                    loadDadosCarreta1(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo: carreta1,
                      setVeiculoInputErrors: setCarreta1InputErrors,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{ width: '100%' }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={carreta1InputErrors.renavam}
                minLength={8}
                maxLength={11}
              />

              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={carreta1InputErrors.chassi}
                maxLength={17}
              />

              <Select
                required
                name="emplacamentoUf"
                disabled={!carreta1}
                value={carreta1 ? carreta1.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  if (carreta1) {
                    setCarreta1({
                      ...carreta1,
                      emplacamentoUf: parseInt(e.target.value, 10),
                    });
                  }
                }}
                textInputTitle="UF Emplacamento:"
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!carreta1}
                name="proprietarioId"
                required
                value={carreta1 ? carreta1.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                textInputTitle="Proprietário:"
                error={carreta1InputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!carreta1}
                required={particularidades.flagHabilitarPesquisaAnttRntrc ? true : false}
                value={carreta1 ? carreta1.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={carreta1InputErrors.anttRntrc}
                maxLength={14}
              />
              <Select
                disabled={!carreta1}
                required
                value={carreta1?.tipoFrota ? carreta1.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                textInputTitle="Tipo:"
                error={carreta1InputErrors.veiculoTipo}
                name="tipoFrota"
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!carreta1}
                  value={carreta1?.atualizacaoAutomatica ? 1 : 0}
                  checked={carreta1 ? carreta1.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('carreta1')}
                  name="atualizacaoAutomatica"
                  id="atualizacaoAutomaticaCarreta1"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>
            {carreta1 && listaCarretas.filter((each) => each.id !== carreta1?.id).length ? (
              <>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Placa Carreta (2):</Form.Label>
                    <Dropdown
                      name="placa"
                      value={carreta2 ? carreta2.id : ''}
                      options={listaCarretas.filter((each) => each.id !== carreta1?.id)}
                      onChange={(e) => {
                        loadDadosCarreta2(parseInt(e.target.value, 10));
                      }}
                      onBlur={() =>
                        veiculoValidation({
                          veiculo: carreta2,
                          setVeiculoInputErrors: setCarreta2InputErrors,
                        })
                      }
                      optionLabel="placa"
                      optionValue="id"
                      filter
                      filterBy="placa"
                      placeholder="Selecione"
                      className="p-dropdown"
                      showClear
                      style={{ width: '100%' }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Input
                    disabled={!carreta2}
                    required
                    value={carreta2 ? carreta2.renavam : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    id="renavam"
                    name="renavam"
                    textInputTitle="Renavam nº:"
                    error={carreta2InputErrors.renavam}
                    maxLength={11}
                  />
                  <Input
                    disabled={!carreta2}
                    required
                    value={carreta2 ? carreta2.chassi : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > 17) {
                        return;
                      }
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    id="chassi"
                    name="chassi"
                    textInputTitle="Chassi nº:"
                    error={carreta2InputErrors.chassi}
                    maxLength={17}
                  />
                  <Select
                    required
                    disabled={!carreta2}
                    value={carreta2 ? carreta2.emplacamentoUf : ''}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      if (carreta2) {
                        setCarreta2({
                          ...carreta2,
                          emplacamentoUf: parseInt(e.target.value, 10),
                        });
                      }
                    }}
                    textInputTitle="UF Emplacamento:"
                  >
                    <option value="0">Selecione</option>
                    {listaUfs.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.sigla}
                      </option>
                    ))}
                  </Select>
                </Form.Row>
                <Form.Row>
                  <Select
                    disabled={!carreta2}
                    name="proprietarioId"
                    required
                    value={carreta2 ? carreta2.proprietarioId : ''}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    textInputTitle="Proprietário:"
                    error={carreta2InputErrors.proprietarioId}
                  >
                    <option value="0">Selecione</option>
                    {listaProprietarios.map((each) => (
                      <option value={each.id} key={each.id}>
                        {each.razaoSocial}
                      </option>
                    ))}
                  </Select>

                  <Input
                    disabled={!carreta2}
                    required={particularidades.flagHabilitarPesquisaAnttRntrc ? true : false}
                    value={carreta2 ? carreta2.anttRntrc : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    id="anttRntrc"
                    name="anttRntrc"
                    textInputTitle="ANTT/RNTRC nº:"
                    error={carreta2InputErrors.anttRntrc}
                    maxLength={14}
                  />
                  <Select
                    disabled={!carreta2}
                    required
                    value={carreta2?.tipoFrota ? carreta2.tipoFrota : '0'}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    textInputTitle="Tipo:"
                    error={carreta2InputErrors.veiculoTipo}
                    name="tipoFrota"
                  >
                    <option value="0">Selecione</option>
                    <option value="1">PRÓPRIO</option>
                    <option value="2">AGREGADO</option>
                    <option value="3">TERCEIRO</option>
                  </Select>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formBasicCheckbox">
                    <Switch
                      disabled={!carreta2}
                      value={carreta2?.atualizacaoAutomatica ? 1 : 0}
                      checked={carreta2 ? carreta2.atualizacaoAutomatica : false}
                      onChange={() => toggleSwitchAtualizacaoAutomatica('carreta2')}
                      name="atualizacaoAutomatica"
                      id="atualizacaoAutomaticaCarreta2"
                      type="switch"
                      label="Atualizar pesquisa automaticamente"
                    />
                  </Form.Group>
                </Form.Row>
              </>
            ) : null}
          </>
        ) : null}

        {tabAtual === 'condutor-veiculo-carreta' ? (
          <>
            <h2 className="titulo">Dados da Pesquisa de Veículos</h2>
            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa:</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      setVeiculo(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={veiculo ? veiculo.id : ''}
                  options={listaVeiculos}
                  onChange={(e) => {
                    loadDadosVeiculo(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo,
                      setVeiculoInputErrors,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{ width: '100%' }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={veiculoInputErrors.renavam}
                minLength={8}
                maxLength={11}
              />
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={veiculoInputErrors.chassi}
                maxLength={17}
              />
              <Select
                required
                disabled={!veiculo}
                name="emplacamentoUf"
                value={veiculo ? veiculo.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                textInputTitle="UF Emplacamento:"
                error={veiculoInputErrors.emplacamentoUf}
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!veiculo}
                name="proprietarioId"
                required
                value={veiculo ? veiculo.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                textInputTitle="Proprietário:"
                error={veiculoInputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!veiculo}
                required={particularidades.flagHabilitarPesquisaAnttRntrc ? true : false}
                value={veiculo ? veiculo.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={veiculoInputErrors.anttRntrc}
                maxLength={14}
              />
              <Select
                disabled={!veiculo}
                name="tipoFrota"
                required
                value={veiculo?.tipoFrota ? veiculo.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() => veiculoValidation({ veiculo, setVeiculoInputErrors })}
                textInputTitle="Tipo:"
                error={veiculoInputErrors.veiculoTipo}
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!veiculo}
                  value={veiculo?.atualizacaoAutomatica ? 1 : 0}
                  checked={veiculo ? veiculo?.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('veiculo')}
                  name="atualizacaoAutomaticaVeiculo"
                  id="pesquisaVeiculo"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa Carreta (1):</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      setCarreta1(undefined);
                      setCarreta2(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={carreta1 ? carreta1.id : ''}
                  options={listaCarretas}
                  onChange={(e) => {
                    loadDadosCarreta1(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo: carreta1,
                      setVeiculoInputErrors: setCarreta1InputErrors,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{ width: '100%' }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={carreta1InputErrors.renavam}
                minLength={8}
                maxLength={11}
              />

              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={carreta1InputErrors.chassi}
                maxLength={17}
              />

              <Select
                required
                name="emplacamentoUf"
                disabled={!carreta1}
                value={carreta1 ? carreta1.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  if (carreta1) {
                    setCarreta1({
                      ...carreta1,
                      emplacamentoUf: parseInt(e.target.value, 10),
                    });
                  }
                }}
                textInputTitle="UF Emplacamento:"
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!carreta1}
                name="proprietarioId"
                required
                value={carreta1 ? carreta1.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                textInputTitle="Proprietário:"
                error={carreta1InputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!carreta1}
                required={particularidades.flagHabilitarPesquisaAnttRntrc ? true : false}
                value={carreta1 ? carreta1.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={carreta1InputErrors.anttRntrc}
                maxLength={14}
              />
              <Select
                disabled={!carreta1}
                required
                value={carreta1?.tipoFrota ? carreta1.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                  })
                }
                textInputTitle="Tipo:"
                error={carreta1InputErrors.veiculoTipo}
                name="tipoFrota"
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!carreta1}
                  value={carreta1?.atualizacaoAutomatica ? 1 : 0}
                  checked={carreta1 ? carreta1.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('carreta1')}
                  name="atualizacaoAutomatica"
                  id="atualizacaoAutomaticaCarreta1"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>
            {carreta1 && listaCarretas.filter((each) => each.id !== carreta1?.id).length ? (
              <>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Placa Carreta (2):</Form.Label>
                    <Dropdown
                      name="placa"
                      value={carreta2 ? carreta2.id : ''}
                      options={listaCarretas.filter((each) => each.id !== carreta1?.id)}
                      onChange={(e) => {
                        loadDadosCarreta2(parseInt(e.target.value, 10));
                      }}
                      onBlur={() =>
                        veiculoValidation({
                          veiculo: carreta2,
                          setVeiculoInputErrors: setCarreta2InputErrors,
                        })
                      }
                      optionLabel="placa"
                      optionValue="id"
                      filter
                      filterBy="placa"
                      placeholder="Selecione"
                      className="p-dropdown"
                      showClear
                      style={{ width: '100%' }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Input
                    disabled={!carreta2}
                    required
                    value={carreta2 ? carreta2.renavam : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    id="renavam"
                    name="renavam"
                    textInputTitle="Renavam nº:"
                    error={carreta2InputErrors.renavam}
                    maxLength={11}
                  />
                  <Input
                    disabled={!carreta2}
                    required
                    value={carreta2 ? carreta2.chassi : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > 17) {
                        return;
                      }
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    id="chassi"
                    name="chassi"
                    textInputTitle="Chassi nº:"
                    error={carreta2InputErrors.chassi}
                    maxLength={17}
                  />
                  <Select
                    required
                    disabled={!carreta2}
                    value={carreta2 ? carreta2.emplacamentoUf : ''}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      if (carreta2) {
                        setCarreta2({
                          ...carreta2,
                          emplacamentoUf: parseInt(e.target.value, 10),
                        });
                      }
                    }}
                    textInputTitle="UF Emplacamento:"
                  >
                    <option value="0">Selecione</option>
                    {listaUfs.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.sigla}
                      </option>
                    ))}
                  </Select>
                </Form.Row>
                <Form.Row>
                  <Select
                    disabled={!carreta2}
                    name="proprietarioId"
                    required
                    value={carreta2 ? carreta2.proprietarioId : ''}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    textInputTitle="Proprietário:"
                    error={carreta2InputErrors.proprietarioId}
                  >
                    <option value="0">Selecione</option>
                    {listaProprietarios.map((each) => (
                      <option value={each.id} key={each.id}>
                        {each.razaoSocial}
                      </option>
                    ))}
                  </Select>

                  <Input
                    disabled={!carreta2}
                    required={particularidades.flagHabilitarPesquisaAnttRntrc ? true : false}
                    value={carreta2 ? carreta2.anttRntrc : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    id="anttRntrc"
                    name="anttRntrc"
                    textInputTitle="ANTT/RNTRC nº:"
                    error={carreta2InputErrors.anttRntrc}
                    maxLength={14}
                  />
                  <Select
                    disabled={!carreta2}
                    required
                    value={carreta2?.tipoFrota ? carreta2.tipoFrota : '0'}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                      })
                    }
                    textInputTitle="Tipo:"
                    error={carreta2InputErrors.veiculoTipo}
                    name="tipoFrota"
                  >
                    <option value="0">Selecione</option>
                    <option value="1">PRÓPRIO</option>
                    <option value="2">AGREGADO</option>
                    <option value="3">TERCEIRO</option>
                  </Select>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formBasicCheckbox">
                    <Switch
                      disabled={!carreta2}
                      value={carreta2?.atualizacaoAutomatica ? 1 : 0}
                      checked={carreta2 ? carreta2.atualizacaoAutomatica : false}
                      onChange={() => toggleSwitchAtualizacaoAutomatica('carreta2')}
                      name="atualizacaoAutomatica"
                      id="atualizacaoAutomaticaCarreta2"
                      type="switch"
                      label="Atualizar pesquisa automaticamente"
                    />
                  </Form.Group>
                </Form.Row>
              </>
            ) : null}
          </>
        ) : null}
      </Form>
    </>
  );
});
