import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import axios from '../../config/axiosMaquina';
import { VeiculoTypeGrid } from '../../Types/Veiculos/VeiculoTypeGrid';
import './styles.css';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';

type Props = {
  canInsert: boolean;
};

const Veiculos: React.FC<Props> = ({ canInsert }) => {
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const history = useHistory();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const anoAtual = new Date().getFullYear();
  const titles = [
    '+id',
    'Placa',
    'Contrato',
    'Tipo de Frota',
    'Tecnologia',
    'N° Equipamento',
    'Mapa Glog',
    'Atualização Automática',
    'Idade',
    'Tempo de Operação',
    'Quantidade de Viagens',
    'Valor Médio Transportado',
  ];
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const handleTipoFrota = (tipoFrota: string) => {
    switch (tipoFrota) {
      case '1':
        return 'Próprio';
      case '2':
        return 'Agregado';
      case '3':
        return 'Terceiro';
      default:
        return '';
    }
  };

  const handleBoolean = (value: boolean) => {
    switch (value) {
      case true:
        return 'Sim';
      case false:
        return 'Não';
      default:
        return 'Não';
    }
  };

  const handleContrato = (value: string) => {
    switch (value) {
      case '':
        return 'NÃO INFORMADO';
      case 'NAO INFORMADO':
        return 'NÃO INFORMADO';
      default:
        return value?.toUpperCase();
    }
  };

  useEffect(() => {
    const loadVeiculos = async () => {
      setLoading(true);
      if (!clienteId) {
        return;
      }
      try {
        const response = await axios.get(`/veiculo/listarVeiculosPorCliente?cliente=${clienteId}`);
        const array = orderBy(response.data, 'placa')
          .filter((each: VeiculoTypeGrid) => each.ativo)
          .map((each: VeiculoTypeGrid) => {
            return [
              each.id,
              each.placa,
              handleContrato(each.tipoFaturamento),
              handleTipoFrota(each.tipoFrota),
              each.tecnologiaRastreamentoNome,
              each.numeroEquipamento,
              handleBoolean(each.mapa),
              handleBoolean(each.atualizacaoAutomatica),
              each.fabricacaoAno ? anoAtual - each.fabricacaoAno : '',
              each.tempoCadastro,
              each.qtdeViagens === null ? 0 : each.qtdeViagens,
              each.valorMedioTransportado
                ? each.valorMedioTransportado.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : '',
            ];
          });
        setData(array);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    loadVeiculos();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Veículo"
      caminho="Cadastro > Veículo"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/veicadastro"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={currentPage}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/atualizarveiculo/${id}`)}
          onClickVisualizar={(id: any) => history.push(`/veidetalhes/${id}`)}
        />
      )}
    </Tela>
  );
};

export default Veiculos;
