import { orderBy } from 'lodash';
import { ExcelGeneration2 } from '../ResumoChecklist/ResumoChecklist';
import { obterData } from '../../util/date';

export const RelatorioXLS = (state: any, pontoOrigem: string, setExcelGenerationUser: any) => {
  const titleAlignment = {
    horizontal: 'center',
  };
  const inicio = orderBy(state, 'dataInclusao')[0];
  const fim = orderBy(state, 'dataInclusao')[state.length - 1];
  const excel: ExcelGeneration2[] = [
    {
      xSteps: 3,
      ySteps: 0,
      columns: [
        {
          title: 'Checklist Veículos',
          widthPx: 3000,
          style: {
            alignment: titleAlignment,
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title:
            inicio && fim
              ? `Período: ${obterData(inicio.dataInclusao.split('T')[0])} a ${obterData(
                  fim.dataInclusao.split('T')[0]
                )}`
              : '',
          widthPx: 3000,
        },
      ],
      data: [],
    },
  ];

  const mergeNomesDispositivos = (checklistComDefeito: any): string => {
    let nomesDispositivo = '';
    checklistComDefeito.forEach((dispositivo: any) => {
      if (dispositivo.situacaoDispositivo === '1') {
        return;
      }
      nomesDispositivo +=
        nomesDispositivo === '' ? dispositivo.nomeDispositivo : `, ${dispositivo.nomeDispositivo}`;
    });
    return nomesDispositivo;
  };

  excel.push({
    xSteps: 0,
    ySteps: 1,
    columns: [
      { title: 'Transportador', width: { wch: 20 } },
      { title: 'CD Origem', width: { wch: 20 } },
      { title: 'Veículo' },
      { title: 'Descrição', width: { wch: 30 } },
      { title: 'Data Realização', width: { wch: 20 } },
      { title: 'Validade', width: { wch: 20 } },
      { title: 'Dispositivos', width: { wch: 30 } },
    ],
    data: state.map((each: any) => {
      console.log(each.placaVeiculo);
      return [
        { value: each.transportador },
        { value: pontoOrigem },
        {
          value: each.placaVeiculo
            ? each.placaVeiculo
            : each.placaCarreta
            ? each.placaCarreta
            : each.numeroBau,
        },
        { value: each.veiculotipo ? each.veiculotipo : each.tipoCarreta ? each.tipoCarreta : '' },
        {
          value: each.dataInclusao ? obterData(each.dataInclusao) : '',
        },
        {
          value: each.dataValidade ? obterData(each.dataValidade) : '',
        },
        { value: mergeNomesDispositivos(each.listaDispositivo) },
      ];
    }),
  });

  console.log(excel);
  setExcelGenerationUser(excel);
};

export default RelatorioXLS;
